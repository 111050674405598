//Constantes for actions

//Study (Cohorts) **********************************************************************
export const STUDY_REQUEST = 'STUDY_REQUEST' //Loading the studies
export const STUDY_FAIL = 'STUDY_FAIL' 
export const STUDY_SUCCESS = 'STUDY_SUCCESS'

export const STUDY_LIST_REQUEST = 'STUDY_LIST_REQUEST'
export const STUDY_LIST_FAIL = 'STUDY_LIST_FAIL' 
export const STUDY_LIST_SUCCESS = 'STUDY_LIST_SUCCESS'

export const SET_COHORT_VISIBILITY_FILTER = 'SET_COHORT_VISIBILITY_FILTER';
export const SET_COHORT_SORTER = 'SET_COHORT_SORTER';

//Session ********************************************************************
export const SESSION_REQUEST = 'SESSION_REQUEST'
export const SESSION_FAIL = 'SESSION_FAIL' 
export const SESSION_SUCCESS = 'SESSION_SUCCESS'

export const SESSION_LIST_REQUEST = 'SESSION_LIST_REQUEST'
export const SESSION_LIST_FAIL = 'SESSION_LIST_FAIL' 
export const SESSION_LIST_SUCCESS = 'SESSION_LIST_SUCCESS'

//Subject ********************************************************************
export const SUBJECT_REQUEST = 'SUBJECT_REQUEST'
export const SUBJECT_FAIL = 'SUBJECT_FAIL' 
export const SUBJECT_SUCCESS = 'SUBJECT_SUCCESS'

export const SUBJECT_LIST_REQUEST = 'SUBJECT_LIST_REQUEST'
export const SUBJECT_LIST_FAIL = 'SUBJECT_LIST_FAIL' 
export const SUBJECT_LIST_SUCCESS = 'SUBJECT_LIST_SUCCESS'


//Image **********************************************************************
export const IMAGE_INFO_REQUEST = 'IMAGE_INFO_REQUEST'
export const IMAGE_INFO_FAIL = 'IMAGE_INFO_FAIL' 
export const IMAGE_INFO_SUCCESS = 'IMAGE_INFO_SUCCESS'

export const IMAGE_RAW_REQUEST = 'IMAGE_RAW_REQUEST'
export const IMAGE_RAW_FAIL = 'IMAGE_RAW_FAIL' 
export const IMAGE_RAW_SUCCESS = 'IMAGE_RAW_SUCCESS'

//Filters ********************************************************************
export const STUDY_FILTER = 'STUDY_FILTER'
export const STUDY_FILTER_PRECISE = 'STUDY_FILTER_PRECISE'
export const STUDY_FILTER_TOGGLE = 'STUDY_FILTER_TOGGLE'
export const STUDY_FILTER_NBRPATIENTS = 'STUDY_FILTER_NBRPATIENTS'

//Variables ********************************************************************
export const VARIABLES_REQUEST = "VARIABLES_REQUEST";
export const VARIABLES_FAIL = "VARIABLES_FAIL";
export const VARIABLES_SUCCESS = "VARIABLES_SUCCESS";


export const CLINICAL_VARIABLES_REQUEST = "CLINICAL_VARIABLES_REQUEST";
export const CLINICAL_VARIABLES_FAIL  = "CLINICAL_VARIABLES_FAIL";
export const CLINICAL_VARIABLES_SUCCESS  = "CLINICAL_VARIABLES_SUCCESS";

export const ANATOMICAL_VARIABLES_REQUEST = "ANATOMICAL_VARIABLES_REQUEST";
export const ANATOMICAL_VARIABLES_FAIL  = "ANATOMICAL_VARIABLES_FAIL";
export const ANATOMICAL_VARIABLES_SUCCESS  = "ANATOMICAL_VARIABLES_SUCCESS";

export const PATHOLOGICAL_VARIABLES_REQUEST = "PATHOLOGICAL_VARIABLES_REQUEST";
export const PATHOLOGICAL_VARIABLES_FAIL  = "PATHOLOGICAL_VARIABLES_FAIL";
export const PATHOLOGICAL_VARIABLES_SUCCESS  = "PATHOLOGICAL_VARIABLES_SUCCESS";

export const META_VARIABLES_REQUEST = "META_VARIABLES_REQUEST";
export const META_VARIABLES_FAIL  = "META_VARIABLES_FAIL";
export const META_VARIABLES_SUCCESS  = "META_VARIABLES_SUCCESS";

export const MEASUREMENT_VARIABLES_REQUEST = "MEASUREMENT_VARIABLES_REQUEST";
export const MEASUREMENT_VARIABLES_FAIL  = "MEASUREMENT_VARIABLES_FAIL";
export const MEASUREMENT_VARIABLES_SUCCESS  = "MEASUREMENT_VARIABLES_SUCCESS";

export const VARIABLE_REQUEST = "VARIABLE_REQUEST";
export const VARIABLE_FAIL = "VARIABLE_FAIL";
export const VARIABLE_SUCCESS = "VARIABLE_SUCCESS";


//Models ***********************************************************************
export const MODELS_REQUEST = "MODELS_REQUEST";
export const MODELS_FAIL = "MODELS_FAIL";
export const MODELS_SUCCESS = "MODELS_SUCCESS";

export const SET_MODELS_VISIBILITY_FILTER = 'SET_MODELS_VISIBILITY_FILTER';
export const SET_MODELS_SORTER = 'SET_MODELS_SORTER';

export const MODEL_REQUEST = "MODEL_REQUEST";
export const MODEL_FAIL = "MODEL_FAIL";
export const MODEL_SUCCESS = "MODEL_SUCCESS";

//Operators ***********************************************************************
export const OPERATORS_REQUEST = "OPERATORS_REQUEST";
export const OPERATORS_FAIL = "OPERATORS_FAIL";
export const OPERATORS_SUCCESS = "OPERATORS_SUCCESS";



//Annotations******************************************************************
export const ANNOTATION_LIST_UPDATE = "ANNOTATION_LIST_UPDATE";
export const ANNOTATION_LIST_REQUEST = "ANNOTATION_LIST_REQUEST";
export const ANNOTATION_LIST_FAIL = "ANNOTATION_LIST_FAIL";
export const ANNOTATION_LIST_SUCCESS = "ANNOTATION_LIST_SUCCESS";
export const ANNOTATION_DATA_UPDATE = "ANNOTATION_DATA_UPDATE";

export const ANNOTATION_IMAGE_SUCCESS ="ANNOTATION_IMAGE_SUCCESS";
export const ANNOTATION_IMAGE_FAIL ="ANNOTATION_IMAGE_FAIL";
export const ANNOTATION_IMAGE_REQUESTED="ANNOTATION_IMAGE_REQUESTED";


export const LABELMAP_IMAGE_SUCCESS ="LABELMAP_IMAGE_SUCCESS";
export const LABELMAP_IMAGE_REQUESTED ="LABELMAP_IMAGE_REQUESTED";
export const LABELMAP_IMAGE_FAIL ="LABELMAP_IMAGE_FAIL";
export const CLEAR_ANNOTATOR_STATE="CLEAR_ANNOTATOR_STATE";


export const UPDATE_VIEWERS_STATE="UPDATE_VIEWERS_STATE";
export const UPDATE_SINGLE_VIEWER_STATE="UPDATE_SINGLE_VIEWER_STATE";
export const UPDATE_SINGLE_VIEWER_PROPERTY="UPDATE_SINGLE_VIEWER_PROPERTY";

export const UPDATE_IMAGE_OPTIONS = "UPDATE_IMAGE_OPTIONS";
import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import {ShowHide} from "../../dashboard/components/ShowHide";


export default class ProjectTable extends React.Component {

    constructor() {
        super();
        this.state={filterCompleted: false};
        this.handleClick = this.handleClick.bind(this);
        this.onShowHideCompleted = this.onShowHideCompleted.bind(this);
    }

    handleClick() {

    }
    onShowHideCompleted(){
        this.setState({filterCompleted:!this.state.filterCompleted});
    }

    componentWillMount() {
        const {getProjects} = this.props;
        getProjects();
    }

    render() {

        const {projects} = this.props;

        const projectNodes = projects.map((p) =>
            <div className='spineRowStyle' key={p.uuid}>
                <Link to={`/project/` + p.uuid }>
                    <div className="spineColStyle">{p.name}</div>
                </Link>
                <div className="spineColStyle">Co-investigator</div>
                <div className="spineColStyle">Active</div>
            </div>);

        return (
            <div style={{width: '96%', margin: '2%'}}>
                <span className="reg-headtitle">My Projects</span>
                <div className="spineTableStyle">
                    <div className='spineRowHeaderStyle'>
                        <div className="spineColStyle">Project</div>
                        <div className="spineColStyle">Role</div>
                        <div className="spineColStyle">Status</div>
                    </div>
                    {projectNodes}
                </div>
                <ShowHide clb={this.onShowHideCompleted} value={this.state.filterCompleted}/>
            </div>
        );
    }
};

ProjectTable.propTypes = {
    getProjects: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
};




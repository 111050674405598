    import {select, selectAll} from "d3-selection";

    /** Function for displaying context menu. It is rewritten d3-context plugin.
     * {@link https://www.npmjs.com/package/d3-context-menu}
     *
     * Examples:
     *  - only menu
     * <code>.on('contextmenu',(menu!=null)?contextMenu(menu):null);</code>
     * - menu and callback before opening
     * <code> .on('contextmenu',contextMenu(menu,clb));</code>
     *
     * @param menu - should contain array of objects with {title, action} attributes.
     * Example:
     * <code> let menu = [
     *{   title: 'Item #1',
     *    action: function(elm, d, i) { console.log('The data for this circle is: ' + d);}
     *},
     *{   title: 'Item #2',
     *   action: function(elm, d, i) {console.log('The data for this circle is: ' + d);}
     * }
     * ];
     * </code>
     * @param openCallback - a callback executed before opening window
     * @returns {Function}
     */
    export var contextMenu = function (menu, openCallback) {

        // create the div element that will hold the context menu
        selectAll('.d3-context-menu').data([1])
            .enter()
            .append('div')
            .attr('class', 'd3-context-menu');

        // close menu
        select('body').on('click.d3-context-menu', function() {
            select('.d3-context-menu').style('display', 'none');
        });

        // this gets executed when a contextmenu event occurs
        return function(data, index) {
            let elm = this;

            selectAll('.d3-context-menu').html('');
            let list = selectAll('.d3-context-menu').append('ul');
            list.selectAll('li').data(menu).enter()
                .append('li')
                .html(function(d) {
                    return d.title;
                })
                .on('click', function(d, i) {
                    d.action(elm, data, index);
                    select('.d3-context-menu').style('display', 'none');
                });

            // the openCallback allows an action to fire before the menu is displayed
            // an example usage would be closing a tooltip
            if (openCallback) openCallback(data, index);

            // display context menu
            select('.d3-context-menu')
                .style('left', (event.pageX - 2) + 'px')
                .style('top', (event.pageY - 2) + 'px')
                .style('display', 'block');

            event.preventDefault();
        };
    };
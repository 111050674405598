import {createSelector} from 'reselect';
import {datePropertyComparator, numberPropertyComparator, stringPropertyComparator} from "../../helpers/comparators";

export const getVisibilityFilter = (state) => state.skill.skills.visibilityFilter;
export const getSorter = (state) => state.skill.skills.sorter;
const getSkills = (state) => state.skill.skills.skills;

export const getVisibleSkills = createSelector(
    [ getVisibilityFilter, getSorter, getSkills ],
    (visibilityFilter, sorter, skills) => {
        return getSortedSkills(sorter,skills);
    }
);

/**
 * Helper  for filtering activities.
 * @param filter
 * @param skills
 * @returns {*}
 */
const getFilteredSkills = (filter, skills) => {
    switch (filter) {
        case 'SHOW_ALL':
            return skills;
        case 'HIDE_COMPLETED':
            return skills.filter(t => t.dueDate!=='Completed');
        default:
            return skills;
    }
};


/** Helper used for sorting Activities.
 *
 * @param sorter
 * @param skills
 * @returns {void | * | Uint8Array | Uint16Array | Int16Array | Float32Array} sorted activities
 */
const getSortedSkills = (sorter, skills) => {
    switch (sorter) {
        case 'SORT_BY_NAME_DESC':
            return skills.sort(stringPropertyComparator('name'));
        case 'SORT_BY_NAME_ASC':
            return skills.sort(stringPropertyComparator('name')).reverse();
        case 'SORT_BY_EXPERTISE_DESC':
            return skills.sort(stringPropertyComparator('expertise'));
        case 'SORT_BY_EXPERTISE_ASC':
            return skills.sort(stringPropertyComparator('expertise')).reverse();
        case 'SORT_BY_CONTRIBUTION_DESC':
            return skills.sort(numberPropertyComparator('contribution'));
        case 'SORT_BY_CONTRIBUTION_ASC':
            return skills.sort(numberPropertyComparator('contribution')).reverse();

    }
};

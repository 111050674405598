import React from "react";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Spinner} from "primereact/components/spinner/Spinner";
import PropTypes from 'prop-types';
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {ColorDropdown} from "./ColorDropdown";


/**
 * Dumb component for displaying toolbar for Brush Tool.
 * @returns {*}
 */
export const BrushToolbar = ({toolProperties,changeToolProperties,colorOptions}) => {

    let changeProperties =(arg,value)=>{
        let pp = Object.assign({},toolProperties);
        pp[arg] = value;
        changeToolProperties(pp);
    };

    return (
        <Toolbar style={{display:"inline-block"}}>
            <label style={{marginLeft:"1em"}}>Brush Size:</label>
            <Spinner value={toolProperties.size}
                     min={0} max={100} step={1} size={3}
                     onChange={(e)=>changeProperties('size',e.value)}
            />
            <label style={{marginLeft:"1em"}}>Label:</label>
            <ColorDropdown options={colorOptions} value={toolProperties.colorValue}
                           onChange={(e) => changeProperties('colorValue', e)}
            />
            <label>Border:</label>
            <Checkbox onChange={()=>{}}  checked={false}/>
            {/*<label style={{marginLeft:"1em"}}>Intensities from:</label>*/}
            {/*<Spinner value={toolProperties.thresholdLower}*/}
                     {/*min={0} max={100} step={1} size={3}*/}
                     {/*onChange={(e)=>changeProperties('thresholdLower',e.value)}*/}
            {/*/>*/}
            {/*<Button icon="fa fa-probe" iconPos="right" title="Probe"/>*/}
            {/*<label style={{marginLeft:"1em"}}> to:</label>*/}
            {/*<Spinner value={toolProperties.thresholdUpper}*/}
                     {/*min={0} max={100} step={1} size={3}*/}
                     {/*onChange={(e)=>changeProperties('thresholdUpper',e.value)}*/}
            {/*/>*/}
            {/*<Button icon="fa fa-eyedropper" iconPos="right" title="Probe"/>*/}
        </Toolbar>
    );
};

BrushToolbar.propTypes={
    toolProperties:PropTypes.object.isRequired,
    changeToolProperties:PropTypes.func.isRequired,
    colorOptions:PropTypes.array.isRequired
};

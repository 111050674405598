import React from 'react';

/**
 * Icon for experiment page
 * @returns {*}
 */
export default () =>

    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11">
        <path fill="#97ACBD" fillRule="evenodd" d="M4.373 1.563C4.373.726 3.647 0 2.81 0c-.836 0-1.563.726-1.563 1.563 0 .836.727 1.563 1.563 1.563.837 0 1.563-.727 1.563-1.563zm1.094 5.94c0-2.501-1.298-3.752-2.735-3.752-1.437 0-2.735 1.25-2.735 3.751 0 1.713 5.47 1.713 5.47 0zm1.876-3.161c.836 0 1.563-.727 1.563-1.563 0-.837-.727-1.563-1.563-1.563-.837 0-1.563.726-1.563 1.563 0 .836.726 1.563 1.563 1.563zm-.078.625c-.432 0-.846.127-1.221.353.227.61.361 1.334.361 2.182 0 .842-.561 1.498-1.525 1.873 1.195 1.012 5.12.796 5.12-.657 0-2.5-1.298-3.75-2.735-3.75z"/>
    </svg>
import axios from 'axios'
import {HOST_URL, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {
    TGAME_EXPERIMENT_RESULTS_FAIL, TGAME_EXPERIMENT_RESULTS_REQUEST,
    TGAME_EXPERIMENT_RESULTS_SUCCESS, TGAME_IMAGE_CLEAR, TGAME_IMAGE_FAIL, TGAME_IMAGE_REQUEST, TGAME_IMAGE_SUCCESS
} from "./actionType";
import {loggingDataset} from "../../vtk/MRIBrowserLogger";
import ReaderFactory from "../../vtk/ReaderFactory";
import vtkITKImageReader from "vtk.js/Sources/IO/Misc/ITKImageReader/index";
import readImageArrayBuffer from "../../vtk/readImageArrayBuffer";
import extensionToImageIO from "itk/extensionToImageIO";


//================getting experiment results

const requestTabletGameResults = (tabletExperimentId) => ({
    type: TGAME_EXPERIMENT_RESULTS_REQUEST,
    tabletExperimentId
});

const errorTabletGameResults = (err,tabletExperimentId) => ({
    type: TGAME_EXPERIMENT_RESULTS_FAIL,
    err,
    tabletExperimentId
});

const successTabletGameResults = (results,tabletExperimentId) => ({
    type: TGAME_EXPERIMENT_RESULTS_SUCCESS,
    results,
    tabletExperimentId
});


/**
 * ActionCreator for getting results for Tablet Game Experiment.
 * @param tabletExperimentId - id of "special-type experiment"
 * @returns {function(*, *)}
 */
export const getTabletGameExperimentResults = (tabletExperimentId) => {
    console.log('TabletGameExperimentAction::getTabletGameExperimentResults:request');
    return (dispatch,getState) => {
        axios.defaults.headers.common['Authorization'] = getState().auth.token_bearer;
        dispatch(requestTabletGameResults(tabletExperimentId));
        axios.get(HOST_URL + '/game/result?tabletExperimentId='+tabletExperimentId)
            .then(response => {
                console.log('TabletGameExperimentAction.js :: getTabletGameExperimentResults :: response ::', response)
                if (response.status !== 200) {
                    dispatch(errorTabletGameResults(error,tabletExperimentId))
                } else {
                    dispatch(successTabletGameResults(response.data));
                }
            }).catch(error => {
            console.log('TabletGameExperimentAction.js :: getTabletGameExperimentResults :: error ::', error);
            dispatch(errorTabletGameResults(error,tabletExperimentId))
        });
    }
};

const extensions = Array.from(
    new Set(Object.keys(extensionToImageIO).map((ext) => ext.toLowerCase()))
);
vtkITKImageReader.setReadImageArrayBufferFromITK(readImageArrayBuffer); //required by external library
extensions.forEach((extension) => {
    ReaderFactory.registerReader({
        extension,
        name: `${extension.toUpperCase()} Reader`,
        vtkReader: vtkITKImageReader,
        binary: true,
        fileNameMethod: 'setFileName',
    });
});


const requestTabletGameImage = (propertyName) => ({
    type: TGAME_IMAGE_REQUEST,
    propertyName
});

const errorTabletGameImage = (error,propertyName) => ({
    type: TGAME_IMAGE_FAIL,
    error,
    propertyName
});

const successTabletGameImage = (image,propertyName) => ({
    type: TGAME_IMAGE_SUCCESS,
    image,
    propertyName
});

const clearTabletGameImage = (propertyName) => ({
    type: TGAME_IMAGE_CLEAR,
    propertyName
});

/**
 * ActionCreator for reading ALL images and variables.
 * @param imageProperties - object {fid:"0052bfbd-774b-4ae4-ad1a-e5bf79034288", propertyName:"mriImage",isOverlay:false}
 * @returns {function(*,*)}
 */
export const getImageActionCreator = (imageProperties) => {
    console.log('TabletGameExperimentAction::getTabletGameExperimentResults:request');
    return dispatch => {
        const {fid,propertyName,roomShortId,tabletExperimentId} = imageProperties;
        console.log("Reading image");
        dispatch(requestTabletGameImage(propertyName));
        let uri = null;
        let imgFormat = null;
        switch(propertyName){
            case 'mriImage':{
                imgFormat = 'or.nii.gz';
                // uri = `/overlay/${fid}/original`;//MOCK
                uri = `/game/tabletExperiment/${tabletExperimentId}/input`; //UNCOMMENT
            break;}
            case "leftOverlay":
            case "rightOverlay":{
                imgFormat = 'or.nii.gz';
                // uri = `/overlay/${fid}`;//MOCK
                uri = `/game/image/${fid}`;
                break;
            }
            case "leftFreq":
            case "rightFreq":{
               imgFormat = 'or.nii';
               // uri = `/overlay/${fid}`;//MOCK
               uri =  `/game/room/${roomShortId}/frequency-map?tabletExperimentId=${tabletExperimentId}`; //UNCOMMENT
               break;
            }
        }

        ReaderFactory.downloadDataset(imgFormat,uri, null)
            .then((result) => {
                const reader = result.reader;
                const results = {};
                results['data'] = reader.getOutputData();
                if (!propertyName.includes('mriImage'))   //only mriImage does not require port
                    results['port'] = reader.getOutputPort();
                dispatch(successTabletGameImage(results, propertyName));
            }).catch((error) => {
            dispatch(errorTabletGameImage(error, propertyName));
        })
    }
};
/**
 * Clearing image variables for a given experiment. Id of image is not needed,
 * since this clears data bound to viewer only.
 * @param propertyName -  just name of store property to clear (eg. leftOverlay)
 * @returns {function(*)}
 */
export const clearOverlayImageActionCreator = (propertyName) => {
    console.log('TabletGameExperimentAction::getTabletGameExperimentResults:request');
    return dispatch => {
        dispatch(clearTabletGameImage(propertyName));
    }
};



import React from "react";
import {Card} from "primereact/components/card/Card";
import PropTypes from "prop-types";
import {
    EXPERIMENT_PROPERTY_VALUE_PRIVACY__PRIVATE,
    EXPERIMENT_PROPERTY_VALUE_PRIVACY__PUBLIC,
    EXPERIMENT_PROPERTY_VALUE_STATUS__COMPLETED, EXPERIMENT_PROPERTY_VALUE_STATUS__DRAFT,
    EXPERIMENT_PROPERTY_VALUE_STATUS__REVIEW, EXPERIMENT_PROPERTY_VALUE_STATUS__TRASHED
} from "../../../Constants";
import {withRouter} from "react-router";

/**Renderer for "Cards" mode for Experiments.
 *
 * @param item - mandatory component if used with ItemList container
 * @param history - provided by withRouter HOC wrapper
 * @returns {*}
 * @constructor
 */
const ExperimentItemCardElement = ({item,history,onRemoveClick}) => {

    let dateToDisplay =  new Date(item.modificationDate).toLocaleString();
    let people = (item['co-investigators']!=null)?item['co-investigators'].length:0;
    let workflows = (item['workflows']!=null)?item['workflows'].length:0;
    const styleOnMover ={padding: '5%', margin: '5%', background:'##F8FBFD'};

    let statusIcon = function () {
        switch (item.status) {
            case EXPERIMENT_PROPERTY_VALUE_STATUS__DRAFT:
                return <div style={{height: '80px'}}>
                    <div title="Draft" className="expStatusDraft"/>
                </div>;
            case EXPERIMENT_PROPERTY_VALUE_STATUS__TRASHED:
                return <div style={{height: '80px'}}>
                    <div title="Trashed" className="expStatusCompleted"/>
                </div>;
            case EXPERIMENT_PROPERTY_VALUE_STATUS__REVIEW:
                return <div style={{height: '80px'}}>
                    <div title="In review" className="expStatusInReview"/>
                </div>;
            case EXPERIMENT_PROPERTY_VALUE_STATUS__COMPLETED:
                return <div style={{height: '80px'}}>
                    <div title="Completed" className="expStatusCompleted"/>
                </div>;
            default:
                return <div style={{display: 'flex', alignItems: 'center', height: '80px'}}>
                    <div id="progress" className="graph">
                        <div id="bar" style={{width: item.status}}>
                            <p>{item.status}</p>
                        </div>
                    </div>
                </div>;
        }
    };

    let move = ()=>{history.push('/project/'+item.projectId+'/experiment/'+item.uuid)};

    return (
        <Card title={item.question} className="experimentCard" >
            <div style={{display:'flex'}} onClick={move}>
                <div style={{padding: '1em', lineHeight: '1.5', width: 'calc(100% - 40px)'}}>
                {item.description}
                </div>
                {item.privacy === EXPERIMENT_PROPERTY_VALUE_PRIVACY__PUBLIC &&  <div className="expGlobe" title="Public"/>}
                {item.privacy === EXPERIMENT_PROPERTY_VALUE_PRIVACY__PRIVATE && <div className="expLock" title="Private"/>}
            </div>
            <hr/>
            <div className="ui-g" onClick={move}>
                <div className="ui-g-6">
                    <div style={{
                        fontSize: '1.5em',
                        fontWeight: 'bold',
                        color: '#6A797F',
                        height: '80px'
                    }}>{item.question}</div>
                    <div style={{display: 'flex', alignItems: 'center', height: '80px', justifyContent: 'center'}}>
                        <div className="expContributors"/>
                        <div style={{fontSize: '1.5em', fontWeight: 'bold', color: '#00D2D0'}}>{people}</div>
                    </div>
                </div>
                <div className="ui-g-6">
                    {statusIcon()}
                    <div style={{display: 'flex', alignItems: 'center', height: '80px', justifyContent: 'center'}}>
                        <div className="expTasks"/>
                        <div style={{fontSize: '1.5em', fontWeight: 'bold', color: '#00D2D0'}}>{workflows}</div>
                    </div>
                </div>
            </div>
            <div className="ui-g" style={{alignItems: 'center'}}>
                <div className="ui-g-6">
                    <div className="expModified"/>
                    <div style={{color: '#6A797F'}}> Modified by {item.modifiedBy} on {dateToDisplay}</div>
                </div>
                <div className="ui-g-6" style={{alignItems: 'center',display:'flex',justifyContent:'center'}}>
                    <i className="fa fa-trash" style={{fontSize:'xx-large'}} title="Remove experiment"
                       onClick={()=>{onRemoveClick(item)}}/>
                </div>
            </div>

        </Card>

    )
};

export default withRouter(ExperimentItemCardElement);

ExperimentItemCardElement.propTypes = {
    item: PropTypes.object.isRequired
};

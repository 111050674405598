import React from 'react'
import SpineLogo from './SpineLogo.js'
import ContainerRolePicker from '../authentication/containers/ContainerRolePicker'
import ContainerLogout from '../authentication/containers/ContainerLogout'
import PropTypes from "prop-types";
import {Breadcrumb} from "./Breadcrumb";

export default class LayoutTopbar extends React.Component {

    // props are not passed

    constructor() {
        super();
        this.state = {
            role: 'contributor',
        };
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        this.setState({role: 'contributor'});
    }


    render() {
        console.log("LayoutTopbar ... render");
        const {pageTitle,match,location, history,appState} = this.props;
        return (
            <div className="headerHome" >
                <div style={{display:'inline-block',marginTop:'18px',marginLeft:'10px'}}>
                <SpineLogo />
                </div>
                <Breadcrumb location={location} appState={appState}/>
                <ContainerLogout />
                {/*<ContainerRolePicker />*/}

            </div>
        )
    }
}

LayoutTopbar.propTypes = {
    pageTitle: PropTypes.string,
    match:PropTypes.object,
    location: PropTypes.object,
    history:PropTypes.object
};
import {connect} from "react-redux";
import NumberVariableStatistics from "../component/NumberVariableStatistics";
import {
    getBoxplot, getGGPlotActionCreator, getHistogram, getKernel,
    getStatisticsActionCreator
} from "../action/DataSelectionAction";
import {MultiValuedVariableStatistics} from "../component/MultiValuedVariableStatistics";

/**
 *
 * @param state - Redux state
 * @param ownProps - properties to be passed in container call
 * @returns {{dataSelection: *, variableId: *}}
 */
const mapStateToProps = (state,ownProps) => {
    return{
        dataSelection: state.expDesign.dataSelection,
        variable: ownProps.variable,
        experiment: state.expDesign.experiment
    }
};

const mapDispatchToProps = dispatch => {
    return{
        getHistogram:(plotId, dataToProcess) => {
            dispatch(getHistogram(plotId,dataToProcess))
        },
        getKernel:(plotId, dataToProcess) => {
            dispatch(getKernel(plotId,dataToProcess))
        },
        getBoxPlot:(plotId, dataToProcess) => {
            dispatch(getBoxplot(plotId,dataToProcess))
        },
        getGGPlot:(plotId) => {
            dispatch(getGGPlotActionCreator(plotId))
        },
        getStatistics:(statsId,dataToProcess) =>{
            dispatch(getStatisticsActionCreator(statsId,dataToProcess))
        }
    }
};

const variableContainerCreator = connect(mapStateToProps, mapDispatchToProps);
// export default connect(
//     mapStateToProps,mapDispatchToProps)(NumberVariableStatistics)
export const ContainerNumericVariableStatistics = variableContainerCreator(NumberVariableStatistics);
export const ContainerMultiValuedVariableStatistics = variableContainerCreator(MultiValuedVariableStatistics);


/**
 * Actions and Action Creators for Overview Page.
 */
import {
    EXPDESIGN_EXPERIMENT_SAVE_FAIL, EXPDESIGN_EXPERIMENT_SAVE_REQUEST, EXPDESIGN_EXPERIMENT_SAVE_SUCCESS,
    EXPDESIGN_GLOBAL_DEFINITION
} from "./actionType";
import store from "../../Store";
import {
    EXPERIMENT_PROPERTY_NAME__COHORTS,
    EXPERIMENT_PROPERTY_NAME__DESCRIPTION, EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA,
    EXPERIMENT_PROPERTY_NAME__HYPOTHESIS,
    EXPERIMENT_PROPERTY_NAME__INCLUSION_CRITERIA,
    EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID, EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES,
    EXPERIMENT_PROPERTY_NAME__NAME,
    EXPERIMENT_PROPERTY_NAME__PRIVACY,
    EXPERIMENT_PROPERTY_NAME__PROJECT_ID, EXPERIMENT_PROPERTY_NAME__QUESTION, EXPERIMENT_PROPERTY_NAME__REVISION_ID,
    EXPERIMENT_PROPERTY_NAME__UUID, EXPERIMENT_PROPERTY_VALUE_PRIVACY__PUBLIC,
    HOST_URL
} from "../../../Constants";
import axios from "axios/index";
import {setStep} from "./WizardStepAction";
import {expDesignPagesEnum} from "../ExpDesign";
import {history} from "../../Root"





//-------------UPDATE STORE-------------------------
/**
 * Updates global information in Store
 * @param def -
 * @returns {{type, def: *}}
 */
export const updateGlobalDefinition = (def) => ({
    type: EXPDESIGN_GLOBAL_DEFINITION,
    def
});

//--------------------SAVE EXPERIMENT IN DB---------------------------------
const requestSaveExperiment = (experiment) => ({
    type: EXPDESIGN_EXPERIMENT_SAVE_REQUEST,
    experiment
});

const successSaveExperiment = (experiment) => ({
    type: EXPDESIGN_EXPERIMENT_SAVE_SUCCESS,
    experiment
});

const failSaveExperiment = (err,experiment) => ({
    type: EXPDESIGN_EXPERIMENT_SAVE_FAIL,
    experiment,
    err
});

/**
 * Check whether Experiment already exists in DB (has uuid given by DB)
 * @param experiment
 * @returns {boolean}
 */
const checkIfAlreadyCreated=(experiment)=> {return experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__UUID)};

/**
 * Action
 * @param exp
 * @param dispatch
 */
const goToNextPage = (exp,dispatch) =>{
    history.push('/project/'+exp[EXPERIMENT_PROPERTY_NAME__PROJECT_ID]+'/experiment/'+exp[EXPERIMENT_PROPERTY_NAME__UUID]);
    dispatch(setStep(expDesignPagesEnum.Model));
};


/**
 * Action Creator for Saving Global Definition.
 * @param hasUpdated indicator showing whether we need to save experiment
 * @returns {function(*)}
 */
export const overviewNextActionCreator = (hasUpdated) => {
    return dispatch => {
        const experiment = store.getState().expDesign.experiment;
        let payload = Object.assign({},experiment); //clone to keep store safe

        if(hasUpdated)
            goToNextPage(experiment,dispatch);

        let sendingMethod=axios.post;
        let path=HOST_URL + '/experiment/'+experiment[EXPERIMENT_PROPERTY_NAME__UUID]+'/description';
        if (!checkIfAlreadyCreated(experiment)) {
            payload = preparePayloadToCreateNewExperiment(payload);
            sendingMethod=axios.post;
            path=HOST_URL + '/experiment';
        }
        else{
            payload = preparePayloadToUpdate(payload);//check if changed
            sendingMethod=axios.put;
        }
        console.log(store);
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        dispatch(requestSaveExperiment(experiment));
        sendingMethod(path,payload)
            .then(response => {
                console.log('StatisticalModelAction.js :: saveExperimentActionCreator :: response ::', response);
                if (response.status !== 200) {
                    dispatch(failSaveExperiment(err,experiment));
                } else {
                    dispatch(successSaveExperiment(response.data));
                    goToNextPage(response.data,dispatch);
                }
            }).catch(error => {
            console.log('Experiment Action.js :: getExperimentList :: error ::', error);
            dispatch(failSaveExperiment(error,experiment));
        });
    }
};


/**
 * Function for preparing payload for Creating Experiment as defined in API/Schema
 * @see experiment.schema.js experimentJoi_create
 * @param experiment
 * @returns {{}}
 *
 */
function preparePayloadToCreateNewExperiment(experiment) {
    let payload = {};
    // payload[EXPERIMENT_PROPERTY_NAME__NAME] = experiment[EXPERIMENT_PROPERTY_NAME__NAME];
    payload[EXPERIMENT_PROPERTY_NAME__HYPOTHESIS] = experiment[EXPERIMENT_PROPERTY_NAME__HYPOTHESIS];
    payload[EXPERIMENT_PROPERTY_NAME__DESCRIPTION] = experiment[EXPERIMENT_PROPERTY_NAME__DESCRIPTION];
    payload[EXPERIMENT_PROPERTY_NAME__QUESTION] = experiment[EXPERIMENT_PROPERTY_NAME__QUESTION];
    payload[EXPERIMENT_PROPERTY_NAME__PRIVACY] = EXPERIMENT_PROPERTY_VALUE_PRIVACY__PUBLIC;
    payload[EXPERIMENT_PROPERTY_NAME__PROJECT_ID] = experiment[EXPERIMENT_PROPERTY_NAME__PROJECT_ID];
    return payload;
}

/**
 * Function for preparing payload for Creating Experiment as defined in API/Schema
 * @see experiment.schema.js experimentJoi_create
 * @param experiment
 * @returns {{}}
 *
 */
function preparePayloadToUpdate(experiment) {
    let payload = {};
    if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__REVISION_ID)) payload[EXPERIMENT_PROPERTY_NAME__REVISION_ID] = experiment[EXPERIMENT_PROPERTY_NAME__REVISION_ID];
    // if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__NAME)) payload[EXPERIMENT_PROPERTY_NAME__NAME] = experiment[EXPERIMENT_PROPERTY_NAME__NAME];
    if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__DESCRIPTION)) payload[EXPERIMENT_PROPERTY_NAME__DESCRIPTION] = experiment[EXPERIMENT_PROPERTY_NAME__DESCRIPTION];
    if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__QUESTION)) payload[EXPERIMENT_PROPERTY_NAME__QUESTION] = experiment[EXPERIMENT_PROPERTY_NAME__QUESTION];
    // if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__PRIVACY)) payload[EXPERIMENT_PROPERTY_NAME__PRIVACY] = EXPERIMENT_PROPERTY_VALUE_PRIVACY__PUBLIC;
    if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__HYPOTHESIS)) payload[EXPERIMENT_PROPERTY_NAME__HYPOTHESIS] = experiment[EXPERIMENT_PROPERTY_NAME__HYPOTHESIS];
    // if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID)) payload[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID] = experiment[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID];
    // if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES)) payload[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES] = experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES];
    // if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__COHORTS)) payload[EXPERIMENT_PROPERTY_NAME__COHORTS] = experiment[EXPERIMENT_PROPERTY_NAME__COHORTS];
    // if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__INCLUSION_CRITERIA)) payload[EXPERIMENT_PROPERTY_NAME__INCLUSION_CRITERIA] = experiment[EXPERIMENT_PROPERTY_NAME__INCLUSION_CRITERIA];
    // if (experiment.hasOwnProperty(EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA)) payload[EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA] = experiment[EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA];
    return payload;
}
import {connect} from "react-redux";
import {TimeFrameSettingPanel} from "../component/TimeFrameSettingPanel";
import {updateTimeFrameActionCreator} from "../action/TimeFrameAction";
import {EXPERIMENT_PROPERTY_NAME__TIMEFRAME} from "../../../Constants";
import {updateExperimentActionCreator} from "../action/StatisticalModelAction";

const mapStateToProps = (state,ownProps) => {
    return {
        variablesList: state.visu.variables.variables,
        experiment: state.expDesign.experiment,
        timeframe: state.expDesign.experiment[EXPERIMENT_PROPERTY_NAME__TIMEFRAME]
    }
};

const mapDispatchToProps = (dispatch,ownProps) => {
    return {
        updateTimeFrame: (timeframe) => {dispatch(updateTimeFrameActionCreator(timeframe))},
        updateExperiment: (experiment) =>{dispatch(updateExperimentActionCreator(experiment))}
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps)(TimeFrameSettingPanel)
import React from "react";
import PropTypes from "prop-types";
import Link from "react-router-dom/es/Link";
import {withRouter} from "react-router";
import {
    COHORT_PROPERTY_NAME__COINVESTIGATORS, COHORT_PROPERTY_NAME__CREATION_DATE,
    COHORT_PROPERTY_NAME__NAME, COHORT_PROPERTY_NAME__PI, COHORT_PROPERTY_NAME__SUBJECTS
} from "../../../Constants";

/**"Dumb" renderer for "List" mode for Cohorts.
 * Required since experiment attributes need to be processed.
 *
 * @param item - mandatory component if used with ItemList container
 * @returns {*}
 * @constructor
 */
const CohortItemListElement = ({item,history}) => {

    let dateToDisplay =  new Date(item[COHORT_PROPERTY_NAME__CREATION_DATE]).toLocaleString();
    let people = (item[COHORT_PROPERTY_NAME__COINVESTIGATORS]!=null)?item[COHORT_PROPERTY_NAME__COINVESTIGATORS].length:0;

    return (
        <div className="experimentListElement"
             // onClick={()=>{history.push('/project/'+item.cohortId+'/experiment/'+item.uuid)}}
        >
            <div className="ui-g-4" >
                {item[COHORT_PROPERTY_NAME__NAME]}
            </div>
            <div className="ui-g-4" >{dateToDisplay}</div>
            {/*<div className="ui-g-3" >{item[COHORT_PROPERTY_NAME__PI]}</div>*/}
            <div className="ui-g-4" >{item[COHORT_PROPERTY_NAME__SUBJECTS].count}</div>
        </div>
    )
};
export default withRouter(CohortItemListElement);

CohortItemListElement.propTypes = {
    item: PropTypes.object.isRequired
};

import React from 'react'
import PropTypes from 'prop-types'
import ProjectItem from './ProjectItem'
import {Button} from "primereact/components/button/Button";


export default class ProjectList extends React.Component {

    constructor(){
        super();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick(){

    // const newProject= {name:'New Project'};
    // const {addProject} = this.props;
    // addProject(newProject);
    }
    componentWillMount() {
        const {getProjects} = this.props;
        getProjects();
    }

    render() {

        const {projects} = this.props;

        const projectNodes = projects.map((p) => <ProjectItem item={p} key={p.uuid}/>);

        // TODO Finish add window dialog
        return (
            <div className="studies">
                <Button icon='fa-plus' title="New project" onClick={this.handleClick}/>
                <ul className="studies-wrapper">
                    {projectNodes}
                </ul>


            </div>
        );
    }

};

ProjectList.propTypes = {
    getProjects: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
};




import React from 'react'
import {Sidebar} from 'primereact/components/sidebar/Sidebar'
import {Button} from 'primereact/components/button/Button'
import WorkflowItem from './WorkflowItem'
import WorkflowPanelMenu from "./WorkflowPanelMenu";
import PropTypes from "prop-types";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {ItemList} from "../../project/component/ItemList";
import WorkflowItemCardElement from "./WorkflowItemCardElement";
import WorkflowItemListElement from "./WorkflowItemListElement";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Graph} from "../../directedgraph/Graph";
import {WorkflowToGraphAdapter} from "../../directedgraph/WorkflowAdapter";


/**
 *
 */
export default class WorkflowDialog extends React.Component {
    constructor(){
        super();
        this.state ={
            isList:false,
            isWorkflowItemVisible:false,
            viewedWorkflow:{}
        };
        this.onDialogHide=this.onDialogHide.bind(this);
        this.onDialogShow=this.onDialogShow.bind(this);
    }

    onDialogShow(e){
        this.props.getTaskList(e.uuid);
        this.setState(
            {isWorkflowItemVisible:true,
                viewedWorkflow:e});


    }
    onDialogHide(){
        this.setState({isWorkflowItemVisible:false});
    }

    render() {
        const { workflows, visible, hideMe,setWorkflow,experiment,variableIndex,variable } = this.props;
        return (
            <Sidebar visible={visible} fullScreen={true} baseZIndex={1} onHide={hideMe}  >
                <WorkflowPanelMenu variable={variable} isList={this.state.isList} listModeClbk={(val)=>this.setState({isList:val})}/>
                {workflows.workflowListState ===REQUEST_STATUS_SUCCESS && workflows.workflows.length>0 &&
                <ItemList model={workflows.workflows}
                          isList={this.state.isList}
                          classNames={["ui-g-4","ui-g-4"]}
                          headers={["Name","Description"]}
                          properties={["name","description"]}
                          cardRenderer={<WorkflowItemCardElement variableIndex={variableIndex} experiment={experiment} variable={workflows.workflowVariable} dialogShowClbk={this.onDialogShow} dialogHideClbk={this.onDialogHide} selectClbk={setWorkflow}/>}
                          listRenderer={<WorkflowItemListElement variableIndex={variableIndex} experiment={experiment} variable={workflows.workflowVariable} dialogShowClbk={this.onDialogShow} dialogHideClbk={this.onDialogHide} selectClbk={setWorkflow}/>}
                          columnStyle="ui-g-4  ui-sm-12"
                />
                }
                <Button type="button" onClick={hideMe} label="Close" className="ui-button-success"/>
                <Dialog
                    position="right"
                    header={"Workflow view/edit"}
                        visible={this.state.isWorkflowItemVisible}
                        modal={false}
                        onHide={this.onDialogHide}
                        style={{width:'80vw',height:'640px'}}
                >


                    {this.state.isWorkflowItemVisible && this.state.viewedWorkflow!=null&&
                  <Graph workflow={WorkflowToGraphAdapter(this.state.viewedWorkflow)} workflowDescription={this.state.viewedWorkflow}/>}
                </Dialog>
            </Sidebar>

        )
    }

}

WorkflowDialog.propTypes = {
    workflows: PropTypes.object.isRequired, // full branch from Redux
    visible: PropTypes.bool.isRequired,
    hideMe: PropTypes.func.isRequired
    };


import {
    SKILL_FAIL, SKILL_LIST_FAIL, SKILL_LIST_REQUEST, SKILL_LIST_SUCCESS,
    SKILL_REQUEST, SKILL_SUCCESS, SET_SKILL_SORTER, SET_SKILL_VISIBILITY_FILTER
} from "./actionType";


const mockSkills = [
    {
        "uuid": "3802b284-da5b-4283-50bb-f338bdb77a77",
        "name": "Segmentation of Thalamus",
        "contribution": 15,
        "expertise": "Gold",
        "project": {uuid:"22cd2ab5-c136-490b-9fca-406c431ebd1b", name:"Fatigue"}
    },
    {
        "uuid": "3802b284-da5b-4283-50bb-f338bdb77a78",
        "name": "Identification of MRI Artifacts",
        "contribution": 1,
        "expertise": "Gold",
        "project": {uuid:"5f293422-2e83-4db8-8af3-a52726bb1175", name:"IPMSA"}
    },
    {
        "uuid": "3802b284-da5b-4283-50bb-f338bdb77a79",
        "name": "Segmentation of MS lesions",
        "contribution": 15,
        "expertise": "Silver",
        "project": {uuid:"1557f770-6295-47ea-af49-eca1e1729702", name:"WOLFSON"}
    },
    {
        "uuid": "3802b284-da5b-4283-50bb-f338bdb77a80",
        "name": "Low-level tissue remodeling",
        "contribution": -1,
        "expertise": "Bronze",
        "project": {uuid:"5f293422-2e83-4db8-8af3-a52726bb1175", name:"IPMSA"}
    }

];

//****************************************************************************
//
//                                Actions
//
//****************************************************************************



// Skill actions *************************************************************

const requestSkillList = () => ({
    type: SKILL_LIST_REQUEST
});



const errorSkillList = err => ({
    type: SKILL_LIST_FAIL,
    err
});

const successSkillList = (skills) => ({
    type: SKILL_LIST_SUCCESS,
    skills
});


/** TODO Bind with real data instead mockup*/
export const getSkillList = () => {
    return dispatch => {
        dispatch (successSkillList(mockSkills));
    };

};

export function setVisibilityFilter(filter) {
    return { type: SET_SKILL_VISIBILITY_FILTER, filter }
}

export function setSorter(sorter) {
    return { type: SET_SKILL_SORTER, sorter }
}



// One skill *****************************************************************
const requestSkill = (idSkill) => ({
    type: SKILL_REQUEST,
    idSkill
});



const errorSkill = err => ({
    type: SKILL_FAIL,
    err
});

const successSkill = (skill) => ({
    type: SKILL_SUCCESS,
    skill
});


/** TODO Bind with real data instead mockup*/
export const getSkill = () => {
    return dispatch => {
        dispatch (successSkill(mockSkills[0]));
    };
};


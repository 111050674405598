import React from 'react';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import {InputText} from 'primereact/components/inputtext/InputText';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {CUSTOM_ELEMENT} from "./AnnotationCell";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import {HierarchicalDropdown} from "./HierarchicalDropdown";
import PropTypes from "prop-types";
import {Button} from "primereact/components/button/Button";
import {
    ANNOTATION_PROPERTY_NAME__ID, ANNOTATION_PROPERTY_NAME__ROI,
    ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB
} from "../../../../Constants";
// import {ContextMenu} from "primereact/components/contextmenu/ContextMenu";

/**
 * "Dumb" component representing Annotation Table and Active Annotation component.
 * State of table parameters is kept inside component.
 * @param data - data array
 * @param columns - columns
 * @param viewCallback - callback to handle click on annotation button
 * @param rmCallback - callback to handle click on remove (trash) button
 * @param updateAnnotationData - callback to update data after change in row
 * @param setSubAnnotation - callback for setting subannotations
 * @param activeRowOnly - flag indicating whether only active row should be visible (true- active row, false - active row + full table)
 */
export const  AnnotationTable =({columns,data,viewCallback,updateAnnotationData,rmCallback,setSubAnnotation,activeRowOnly})=> {

    /** Universal method for changing state of data. It can be used by all editors.
     * Since data can be sorted or filtered it performs looking for data using index id.
     * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
     * @param value - value decoded by "native" editor (listening to change in radio, dropdown and other components)
     */
   const  onEditorValueChange = (props, value)=> {
        let updatedData = data.slice(0);//Important!!!
        let index = updatedData.findIndex((el)=>el["id"]===props.rowData["id"]);
        updatedData[index][props.field] = value;
        updateAnnotationData(updatedData);
    };

    /** Editor for InputText columns.
     * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
     * @returns {*} - component in JSX code
     */
    const inputTextEditor = (props)=> {
        return <InputText type="text"
                          value={props.rowData[props.field]}
                          onChange={(e) => onEditorValueChange(props, e.target.value)}
        />;
    };

    /** Editor for radio (React Native) columns.
     * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
     * @param options - options to select
     * @returns {*} - component in JSX code
     */
    const radioEditor= (props,options)=>{
        return <div>{options.map((el)=> {
            return<div  style={{width:"100%"}}>
                    <input type="radio"
                           value={el}
                           checked={props.rowData[props.field]===el}
                           style={{width:"1em"}}
                           onChange={(e) => onEditorValueChange(props, e.target.value)} />
                    <span> {el}</span>
                </div>
        })}</div>;
    };

    /** Editor for checkbox (React Native) columns.
     * @param props - properties passed by PF DataTable model (contains rowData, rowIndex, field)
     * @param options - options to choose
     * @returns {*} - component in JSX code
     */
    const checkboxEditor = (props,options) =>{
        let editorValueChangeAdapter = (props,e)=>{
            let result = props.rowData[props.field];
            if (!(result!=null))
                result=[];
            if (e.checked){
                result.push(e.value);
            }else{
                let indexToRemove = result.indexOf(e.value);
                if (indexToRemove > -1) {
                    result.splice(indexToRemove, 1);
                }
            }
            onEditorValueChange(props, result);
        };

        return <div>{options.map((el)=> {
            return<div  style={{width:"100%"}}>
                <Checkbox value={el}
                          onChange={(e) => editorValueChangeAdapter(props,e)}
                          checked={props.rowData[props.field]!=null && props.rowData[props.field].includes(el)}/>
                <span> {el}</span>
            </div>
        })}</div>;
    };


    const dropdownEditor = (props,options)=> {
        let pfOptions = options.map((el)=>{return {label:el,value:el}});  //convert to PF format for dropdown options (SelectItem objects)

        return (
            <Dropdown value={props.rowData[props.field]}
                      options={pfOptions}
                      onChange={(e) => onEditorValueChange(props, e.value)} style={{width:'100%'}}/>
        );
    };

    const hierarchicalEditor = (props,options)=> {
        return (
            <HierarchicalDropdown value={props.rowData[props.field]}
                      parentNode={options}
                      updateValue={(val)=>onEditorValueChange(props, val)}/>
        );
    };

    const requiredValidator = (props) =>{
        let value = props.rowData[props.field];
        return value && value.length > 0;
    };

    /**
     *  Needed to provide callback to viewer for id.
     * @param rowData
     * @param column
     * @returns {*}
     */
    const idTemplate = (rowData, column) =>{
        let className = (rowData[ANNOTATION_PROPERTY_NAME__ID].includes('.'))?' subROI':'';

        //   {/*{...(indented ? {style: {fontSize:'xxx-small'}} : {})}*/}
        return <div  className={className}>
            <Button type="button"
                    title={"Image position [IJK]: " + rowData[ANNOTATION_PROPERTY_NAME__ROI]["roiCellIJK"].toString()}
                    label={rowData[ANNOTATION_PROPERTY_NAME__ID]}
                    onClick = {()=>viewCallback(rowData)}
                    {...(rowData['roi']['roiStatus']==='active' ? {style: {backgroundColor:'red'}} : {})}
            />
        </div>;
    };

    /**
     *  Needed to provide callback to viewer for id.
     * @param rowData
     * @param column
     * @returns {*}
     */
    const trashTemplate = (rowData, column) =>{
        return <div>
            <Button icon="fa fa-trash"  onClick = {()=>rmCallback(rowData)}  />
        </div>;
    };

    const subTemplate = (rowData, column) =>{
        return <div>
            { !rowData['id'].includes(".") &&
            <input type="checkbox"
                   checked={rowData['roi']['roiSub']===true}
                   onClick = {()=>setSubAnnotation(rowData)}/>}
        </div>;
    };

    const getEditor = (props)=>{
        let column = columns.find((el)=>el.name===props.field);
        if (column!=null)
            switch(column.type){
                case CUSTOM_ELEMENT.LIST.type:
                    return dropdownEditor(props,column.options);
                case CUSTOM_ELEMENT.TEXT.type:
                    return inputTextEditor(props);
                case CUSTOM_ELEMENT.CHOICE.type:
                    return radioEditor(props,column.options);
                case CUSTOM_ELEMENT.MULTIPLE_CHOICE.type:
                    return checkboxEditor(props,column.options);
                case CUSTOM_ELEMENT.HIERARCHICAL.type:
                    return hierarchicalEditor(props,column.options);
                default: return null;
            }
        else return null;
    };

    // render() {
        // let contextMenuModel =[
        // {label: 'View', icon: 'fa fa-search' },
        // {label: 'Delete', icon: 'fa fa-close'}
        // ];
        let dynamicColumns = columns.map((col,i) => {
            return <Column key={col.name} field={col.name} header={col.name} editor={getEditor}
                           {...(!activeRowOnly ? {'filter':true} : {})}
                           {...(!activeRowOnly ? {'sortable':true} : {})}
            />;
        });

    let referenceToDT = null;
    let setRef = element => {
        referenceToDT = element;
    };

    const sortIds = (e)=>{
            console.log('your_sortFunction_algorithm');
    };

    const activeElement = (data!=null && data.length>0)?data.find((el)=>{return el["roi"]["roiStatus"]==="active"}):null;
    let tableData = [];
    if(activeElement!=null)
        tableData.push(activeElement);
    let header = <div style={{textAlign:'left'}}>
        <Button type="button" icon="fa fa-external-link" iconPos="left" label="CSV" onClick={()=>referenceToDT.exportCSV()}/></div>;
        return (
            <React.Fragment>
                {activeRowOnly &&
                <DataTable ref={setRef}
                           value={tableData}
                           editable={true}
                           reorderableColumns={true}
                           emptyMessage="No selected annotation"
                >
                    <Column key="id" body={idTemplate} field="id" header="Id" style={{width:"3em"}} />
                    {dynamicColumns}
                    <Column body={trashTemplate} style={{width:"3em"}} />
                    <Column body={subTemplate} style={{width:"3em"}} />
                </DataTable>}
                {!activeRowOnly &&
                <DataTable ref={setRef}
                               value={data}
                               editable={true}
                               scrollable={true}
                               scrollHeight="200px"
                               reorderableColumns={true}
                               onValueChange={sortedData => console.log(sortedData)}
                               sortField="id"
                               sortOrder={-1}
                               emptyMessage="No annotations"
                               footer={header}
                               // onContextMenu={(e) => this.cm.show(e.originalEvent)}
                               // onContextMenuSelectionChange={(e) => this.setState({selected: e.value})}
                               // contextMenuSelection={this.state.selected}
                    >

                        <Column key="id" body={idTemplate} field="id" header="Id" style={{width:"4em"}} sortable={true}
                                sortFunction={()=>sortIds()}  />
                        {dynamicColumns}
                        <Column body={trashTemplate} style={{width:"3em"}} />
                        <Column body={subTemplate} style={{width:"3em"}} />
                    </DataTable>}
            </React.Fragment>

        );
    // }
};

AnnotationTable.propTypes = {
    columns: PropTypes.array.isRequired,
    data:PropTypes.array.isRequired,
    viewCallback:PropTypes.func,
    rmCallback:PropTypes.func,
    setSubAnnotation:PropTypes.func,
    activeRowOnly:PropTypes.bool.isRequired
};
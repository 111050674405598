import React from 'react'
import {SHAPES} from "./Shapes";

export const GraphConfig =  {
    NodeTypes: {
        Input:{
            // typeText: "Input",
            shapeId: "#ellipse",
            shape: SHAPES.ELLIPSE_SHAPE},
        Task: {
            // typeText: "Output",
            shapeId: "#empty",
            shape: SHAPES.EMPTY_SHAPE
        },
        Choice: {
            // typeText: "None",
            shapeId: "#choice",
            shape: SHAPES.CHOICE_SHAPE
        },
        Terminator: {
            shape: SHAPES.TERMINATOR_SHAPE,
            shapeId: '#terminator',
            // typeText: 'Output',
        },
        Output: {
            shape: SHAPES.TASK_SHAPE,
            shapeId: '#emptyTask',
            // typeText: 'Task',
        },
        Pass: {
            shape: SHAPES.PASS_SHAPE,
            shapeId: '#pass',
            // typeText: 'Pass',
        }
    },

    NodeSubtypes: {},
    EdgeTypes: {
        EmptyEdge: {
            shapeId: "#emptyEdge",
            shape: SHAPES.EMPTY_EDGE_SHAPE
        }
    }
};

import React from "react";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {InputText} from "primereact/components/inputtext/InputText";
import {Button} from "primereact/components/button/Button";
import {Tooltip} from "primereact/components/tooltip/Tooltip";

/**
 * Stateful component  for calculating  Sample size or Confidence interval.
 * Can be placed in any container (Panel, Overlay Panel, Dialog Window)
 * @returns {*}
 */
class SampleSizeCalculator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            type: 'SS',
            ciLevel: '95',
            ciInterval:5,
            ss:0,
            population: 0,
            percentage:50,
            result: 0,
            rse:0,
            se:0
        };
        this.onChangePopulation = this.onChangePopulation.bind(this);
        this.onChangeCIInterval = this.onChangeCIInterval.bind(this);
        this.onChangePercentage = this.onChangePercentage.bind(this);
        this.onCalculate = this.onCalculate.bind(this);
        this.onChangeSS = this.onChangeSS.bind(this);
    }

    onChangePopulation(e) {
        if (!isNaN(Number(e.target.value))&& !(Number(e.target.value)<0))
            this.setState({population: e.target.value})
    }
    onChangePercentage(e) {
        if (!isNaN(Number(e.target.value)) && Number(e.target.value)<=100 && Number(e.target.value)>0)
            this.setState({percentage: e.target.value})
    }

    onChangeCIInterval(e) {
        if (!isNaN(Number(e.target.value))&& Number(e.target.value)>0 )
            this.setState({ciInterval: e.target.value})
    }
    onChangeSS(e) {
        if (!isNaN(Number(e.target.value)))
            this.setState({ss: e.target.value})
    }

    /**
     *
     * @param c Confidence Interval
     * @param z Z-score for given CI Level
     * @param proportion - expected proportion of the population to have the attribute that you are estimating from your survey
     * by default 0.5 - 'pessimistic' case
     * @param population - size of full population if known (otherwise Infinity)
     * @returns {number} size of required sample set
     */
    static calcSS(c,z,proportion,population){
        let std_err = c/(2*z);
        let ss = (proportion*(1-proportion))/(std_err*std_err);
        let rse = std_err/proportion*100; //percentage
        if (population != 0)
        {
            let f = (ss)/(population + ss - 1);
            ss =ss * (1-f);
        }
        return {ss:ss,se:std_err,rse:rse};

    }

    /**
     *
     * @param ss Sample set size
     * @param z Z-score for given CI Level
     * @param proportion - expected proportion of the population to have the attribute that you are estimating from your survey
     * by default 0.5 - 'pessimistic' case
     * @param population - size of full population if known (otherwise Infinity)
     * @returns {number} CI in +/- n% (half of range)
     */
    static calcCI(ss,z,proportion,population){
        let std_err=0;
        let f =0;
        if (population < ss && population != 0)
        {
            alert("The sample size cannot be greater than the population size");
            return 0;
        }
        if (population != 1)
        {
            f= (ss-1)/(population-1);
        }
        else f = 1;
        if (population==0)
        {
            std_err = Math.sqrt((proportion*(1-proportion))/(ss));
        }
        else
        {
            //remember that the n here is already adjusted for population correction
            std_err = Math.sqrt((1-f)*(proportion*(1-proportion))/(ss-1));
        }
        //now we have the standard error
        let rse = std_err/proportion*100;

        return {ci:100*z*std_err,se:std_err,rse:rse} ;
    }

    onCalculate(){
        const z= (this.state.ciLevel==='95')?1.96:2.575;
        const population = Number(this.state.population);
        const proportion= Number(this.state.percentage)/100;
        const c = 2*Number(this.state.ciInterval)/100;
        let ss =  Number(this.state.ss);
        let ci=0;
        let result={};
        if(this.state.type==='SS'){
           result=SampleSizeCalculator.calcSS(c,z,proportion,population);
            this.setState({result:Math.ceil(result.ss),rse:result.rse,se:result.se})
        }

        else{
            result=SampleSizeCalculator.calcCI(ss,z,proportion,population);
            this.setState({result:result.ci,rse:result.rse,se:result.se})
        };



    }
    render() {
        const {numberOfSamples}=this.props;
        const header = (this.state.type === 'SS') ? 'Determine sample size' : 'Determine confidence interval';

        const resultTitle = (this.state.type === 'SS') ? 'Required sample size' : 'Determined confidence interval';
        const resultUnit = (this.state.type === 'SS') ? '' : '%';
        const ciOptions = [{label: '95%', value: '95'}, {label: '99%', value: '99'}];
        const typeOptions = [{label: 'sample size', value: 'SS'}, {label: 'confidence interval', value: 'CI'}];
        return (

            <React.Fragment>
                <div>
                    <table id="statisticalCalculatorTable" >
                        <tbody>
                        <tr>
                            <th>Required sample size </th>
                            <th>Filtered samples size</th>
                            <th>Selected sample size</th>
                        </tr>
                        <tr>
                            <td>384</td>
                            <td>NA</td>
                            <td>{(typeof numberOfSamples==='number')?numberOfSamples:'#'}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            <div style={{marginTop:'20px'}}>
                <span className="reg-plaintext">{header}</span>
                <div className="ui-g-12">
                    <div className="ui-g-6">Choose calculator</div>
                    <div className="ui-g-6">
                        <Dropdown options={typeOptions} value={this.state.type} name="calc_type" style={{width:'10em'}}
                                  onChange={(e) => this.setState({type: e.value, result: ''})}/>
                    </div>
                    <div className="ui-g-6">Confidence level</div>
                    <div className="ui-g-6">
                        <Dropdown id="confidenceLevel" options={ciOptions} value={this.state.ciLevel} name="ci"  style={{width:'4em'}}
                                  onChange={(e) => this.setState({ciLevel: e.value})}/>
                    </div>
                    <div className="ui-g-6">Proportion</div>
                    <div className="ui-g-6">
                        <InputText id="percentage" value={this.state.percentage} onChange={this.onChangePercentage}/>%
                    </div>

                    <div className="ui-g-6">Population size</div>
                    <div className="ui-g-6">
                        <InputText id="populationSize"
                                   value={this.state.population} onChange={this.onChangePopulation}/>
                    </div>
                    {this.state.type==='SS' &&
                    <div className="ui-g-6">Confidence interval</div>}
                    {this.state.type==='SS' &&
                    <div className="ui-g-6">
                       <InputText value={this.state.ciInterval} onChange={this.onChangeCIInterval}/>%
                    </div>}
                    {this.state.type==='CI' &&
                    <div className="ui-g-6">Sample set size</div>}
                    {this.state.type==='CI' &&
                    <div className="ui-g-6">
                        <InputText value={this.state.ss} onChange={this.onChangeSS}/>
                    </div>}


                    <Tooltip for="#populationSize" title="Total number of individuals or groups about which information is required- leave it blank if unknown"
                             tooltipPosition="right" tooltipEvent="hover"/>
                    <Tooltip for="#percentage" title="The expected proportion of the population to have
                    the attribute that you are estimating from your survey. If unknown, it should be set to 50%,
                    as this produces a conservative (pessimistic) estimate of variance."
                             tooltipPosition="right" tooltipEvent="hover"/>
                    <Tooltip for="#confidenceLevel" title="The level of certainty with which you will estimate
                    the true population value. You can choose 95% or 99%."
                             tooltipPosition="right" tooltipEvent="hover"/>


                </div>
                <div>
                <Button label='Calculate' onClick={this.onCalculate}/>
                <Button label='Clear'/>
                </div>
                {this.state.result != '' &&
                <div className="reg-plaintext">
                    <div> {resultTitle} : {this.state.result.toFixed(2)}</div>
                    <div> Relative Standard Error :<span id='rse'> {this.state.rse.toFixed(2)}</span></div>
                </div>}
                <Tooltip for="#SS" title="The number of samples required to achieve the required level of accuracy"
                         tooltipPosition="right" tooltipEvent="hover" />
                <Tooltip for="#CI" title="The number of samples required to achieve the required level of accuracy"
                         tooltipPosition="right" tooltipEvent="hover" />
                <Tooltip for="#rse" title="The measure of sampling error that indicates
                the degree to which an estimate may vary from the true value expressed as a percentage of the estimate."
                         tooltipPosition="right" tooltipEvent="hover" />
            </div>
            </React.Fragment>

        );
    }
}

export default SampleSizeCalculator
import React from 'react'
import PropTypes from "prop-types";


export class ShowHide extends React.Component {

   constructor(){
       super();
       this.onShowHide = this.onShowHide.bind(this);
   }

   onShowHide(){
       const {clb} = this.props;
       //this.setState({showCompleted:!this.state.showCompleted});
       clb();
   }
    render() {
       let text =  (this.props.value)?"Show completed":"Hide completed";

        return (
            <a style={{float:'right', marginTop:"5px"}} onClick={this.onShowHide}>
                {text}
            </a>
        );
    }
}

ShowHide.propTypes = {
    clb: PropTypes.func.isRequired, //callback to pass up state of the component
    value: PropTypes.bool
};




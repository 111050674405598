import {
    EXPDESIGN_DATA_EXCLUSION_CRITERIA_UPDATE,
    EXPDESIGN_DATA_INCLUSION_CRITERIA_UPDATE, EXPDESIGN_EXPERIMENT_SAVE_SUCCESS, EXPDESIGN_EXPERIMENT_SUCCESS,
    EXPDESIGN_GLOBAL_DEFINITION, EXPDESIGN_SET_EXPERIMENT, EXPDESIGN_SET_TIMEFRAME,
    EXPDESIGN_STATMODEL_HYPOTHESIS,
    EXPDESIGN_STATMODEL_SELECT,
    EXPDESIGN_STATMODEL_VARIABLES, EXPDESIGN_WORKFLOW_SET_SUCCESS,
} from '../action/actionType'
import {
    EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID,
    EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID, EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES,EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__WORKFLOW_ID,
    EXPERIMENT_PROPERTY_VALUE_PRIVACY__PUBLIC
} from "../../../Constants";

/**
 * Experiment Reducer initializes only required data (by DB).
 * The remaining properties are added dynamically during session with Experiment design templates.
 * @param state
 * @param action
 * @returns {*}
 * @constructor
 */
export const ExperimentReducer = (state = {}, action) => {
	switch (action.type) {

	    case EXPDESIGN_EXPERIMENT_SAVE_SUCCESS:
            return Object.assign({}, action.experiment); //refreshing full experiment in store

        case EXPDESIGN_DATA_INCLUSION_CRITERIA_UPDATE:{
            let temp = state['selectionCriteria'];
            temp['inclusion'] = Object.assign({},action.criteria);
            return Object.assign({},state, {
                selectionCriteria:temp
            });}
        case EXPDESIGN_DATA_EXCLUSION_CRITERIA_UPDATE:{
            let temp = state['selectionCriteria'];
            temp['exclusion'] = Object.assign({},action.criteria);
            return Object.assign({},state, {
                selectionCriteria:temp
            });}
		case EXPDESIGN_STATMODEL_SELECT:{
			let tmp={};
			tmp[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID]=action.model;
		    return Object.assign({},state,tmp);
		}
		case EXPDESIGN_STATMODEL_VARIABLES:{
		    let tmp={};
            tmp[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES]=action.variables;
			return Object.assign({},state, tmp);
		}
        case EXPDESIGN_STATMODEL_HYPOTHESIS:
            return Object.assign({},state, {
            	hypothesis: action.hypothesis,
            });
        case EXPDESIGN_SET_EXPERIMENT:
            return Object.assign({},action.experiment); //INITIALIZE with loaded data

        case EXPDESIGN_EXPERIMENT_SUCCESS:
            return Object.assign({},action.experiment); //INITIALIZE with loaded data

        case EXPDESIGN_WORKFLOW_SET_SUCCESS:{
            state['systemVariablesForStatisticalModel'][action.variable['symbol']][EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__WORKFLOW_ID] = action.workflow['uuid'];
            return Object.assign({},state);
        }
        case EXPDESIGN_SET_TIMEFRAME:
            return Object.assign({},state,{timeFrame:action.timeframe}); //replace time frame in experiment

         //TODO FAIL and REQUEST FOR the  above
		default:
			return state;
	}
};
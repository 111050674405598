import {
    ACTIVITY_FAIL,
    ACTIVITY_LIST_FAIL,
    ACTIVITY_LIST_REQUEST,
    ACTIVITY_LIST_SUCCESS,
    ACTIVITY_REQUEST,
    ACTIVITY_SUCCESS,
    SET_ACTIVITY_SORTER,
    SET_ACTIVITY_VISIBILITY_FILTER
} from "./actionType";
import {mockActivities} from "../tests/Activities.test";

//****************************************************************************
//
//                                Actions
//
//****************************************************************************



// Activity actions *************************************************************

const requestActivityList = () => ({
    type: ACTIVITY_LIST_REQUEST
});



const errorActivityList = err => ({
    type: ACTIVITY_LIST_FAIL,
    err
});

const successActivityList = (activities) => ({
    type: ACTIVITY_LIST_SUCCESS,
    activities
});


/** TODO Bind with real data instead mockup*/
export const getActivityList = () => {
    return dispatch => {
        dispatch (successActivityList(mockActivities));
    };

};

export function setVisibilityFilter(filter) {
    return { type: SET_ACTIVITY_VISIBILITY_FILTER, filter }
}

export function setSorter(sorter) {
    return { type: SET_ACTIVITY_SORTER, sorter }
}
//
// export const getActivityList = () => {
//     return dispatch => {
//         dispatch (requestActivityList())
//         console.log('ActivityAction.js :: getStudyList :: Before axios requres');
//         return axios.get(HOST_URL+'/study'
//         ).then(response => {
//             console.log('StudyAction.js :: getStudyList :: response', response);
//             if(response.status !== 200) {
//                 dispatch (errorStudyList(response.data));
//                 return Promise.reject(response.data);
//             } else {
//                 console.log('StudyAction.js :: getStudyList :: Before successStudyList');
//                 dispatch (successStudyList(response.data));
//             }
//         }).catch(err => dispatch (errorStudyList(err)));
//     };
// };




// One activity *****************************************************************
const requestActivity = (idActivity) => ({
    type: ACTIVITY_REQUEST,
    idActivity
});



const errorActivity = err => ({
    type: ACTIVITY_FAIL,
    err
});

const successActivity = (activity) => ({
    type: ACTIVITY_SUCCESS,
    activity
});


/** TODO Bind with real data instead mockup*/
export const getActivity = () => {
    return dispatch => {
        dispatch (successActivity(mockActivities[0]));
    };
};

//Get study (ActivityList)
// export const getActivity = (idActivity) => {
// 	return dispatch => {
// 		dispatch (requestActivity(idActivity))
// 		return axios.get(HOST_URL+'/activity',
// 			{params: idActivity}
// 		).then(response => {
// 			if(response.statusCode !== 200) {
// 				dispatch (errorActivity(response.data));
// 				return Promise.reject(response.data);
// 			} else {
// 				dispatch (successActivity(response.data));
// 			}
// 		}).catch(err => dispatch (errorActivity(err)));
// 	};
// };



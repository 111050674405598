import {createSelector} from 'reselect';
import {datePropertyComparator, stringPropertyComparator} from "../../helpers/comparators";

export const getVisibilityFilter = (state) => state.activity.activities.visibilityFilter;
export const getSorter = (state) => state.activity.activities.sorter;
const getActivities = (state) => state.activity.activities.activities;

export const getVisibleActivities = createSelector(
    [ getVisibilityFilter, getSorter, getActivities ],
    (visibilityFilter, sorter, activities) => {
         return getSortedActivities(sorter,getFilteredActivities(visibilityFilter,activities));
    }
);

/**
 * Helper  for filtering activities.
 * @param filter
 * @param activities
 * @returns {*}
 */
const getFilteredActivities = (filter, activities) => {
    switch (filter) {
        case 'SHOW_ALL':
            return activities;
        case 'HIDE_COMPLETED':
            return activities.filter(t => t.dueDate!=='Completed');
        default:
            return activities;
    }
};


/** Helper used for sorting Activities.
 *
 * @param sorter
 * @param activities
 * @returns {void | * | Uint8Array | Uint16Array | Int16Array | Float32Array} sorted activities
 */
const getSortedActivities = (sorter, activities) => {
        switch (sorter) {
            case 'SORT_BY_NAME_DESC':
                return activities.sort(stringPropertyComparator('task.name'));
            case 'SORT_BY_NAME_ASC':
                return activities.sort(stringPropertyComparator('task.name')).reverse();
            case 'SORT_BY_DUE_DATE_DESC':
                return activities.sort(datePropertyComparator('dueDate'));
            case 'SORT_BY_DUE_DATE_ASC':
                return activities.sort(datePropertyComparator('dueDate')).reverse();
            case 'SORT_BY_PROJECT_NAME_DESC':
                return activities.sort(stringPropertyComparator('project.name'));
            case 'SORT_BY_PROJECT_NAME_ASC':
                return activities.sort(stringPropertyComparator('project.name')).reverse();
            case 'SORT_BY_EXP_NAME_DESC':
                return activities.sort(stringPropertyComparator('experiment.name'));
            case 'SORT_BY_EXP_NAME_ASC':
                return activities.sort(stringPropertyComparator('experiment.name')).reverse();
        }
    };

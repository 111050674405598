import React from 'react'


export default class Tour extends React.Component {


    render() {
        console.log("Tour ... render");
        return (
            <div  style={{margin: '16px'}}>
                <div>
                    <div id="whw">
                        <div className="index-content"><h1>What, how and who</h1><h2>What?</h2><p>SPINE is a virtual
                            laboratory designed to <strong>accelerate</strong> scientific discovery,
                            while <strong>educating</strong> and actively involving curious minds. SPINE stands for
                            Structured Planning and Implementation of New Explorations. Right now we are focusing on
                            neurological explorations that make use of advanced neuroimaging techniques. The basis of
                            many experiments consists in measuring brain structures by outlining them on images. In
                            fact, identifying and measuring anatomical structures on brain MRI images is the centerpiece
                            of many neurological, psychiatric, and neurosurgical studies. Today this task is performed
                            by MDs, PhDs and college students. We believe that anybody can help with this.</p>
                            <h2>How?</h2><p><strong>You don't need to be a Neuroscientist to draw a brain</strong>. We
                                are intent on opening the ivory tower of clinical research to the general public. We are
                                harnessing the power of the web to educate and accelerate research at the same time.
                                Through engaging tutorials you will learn about the mysterious world of the brain one
                                structure at a time. SPINE will teach you the anatomy and function of different parts of
                                the brain, as well as the skill to measure each of the structures involved. Each skill
                                you learn will give you access to participate in the analysis of real data in ongoing
                                exciting scientific projects.</p></div>
                        <h2>Who?</h2>
                        <div className="full-container">
                            <div className="container-tooltip">
                                {/*<div className="abs" name="Contributor"*/}
                                {/*style={{top: "1px", right: "80px", width: "140px", height: "50px"}}>*/}
                                {/*<span className="tooltip"                                                                                                     data-tooltip-custom="I want to help study neurological diseases. Here I can make real contributions to real scientific projects. I can learn about the brain and its diseases. I can learn skills and become an expert analyst. Maybe I could even make a career of it!">&nbsp;</span>*/}
                                {/*</div>*/}
                                {/*<div className="abs" name="Educator"*/}
                                {/*style={{top: "80px", right:" 0", width: "50px", height: "110px"}}>*/}
                                {/*<span className="tooltip"*/}
                                {/*data-tooltip-custom="I can teach neuroscience to the truly motivated. I can provide online tutorials and assess their effectiveness in the context of ongoing scientific projects. The effect of different teaching strategies can be compared">&nbsp;</span>*/}
                                {/*</div>*/}
                                {/*<div className="abs" name="Physician"*/}
                                {/*style={{top: "164px", left: "6px", width: "38px", height: "120px"}}*/}
                                {/*><span*/}
                                {/*className="tooltip"  data-tooltip-custom="I can keep my fiduciary role towards the patient, and enhance my chances to be involved in exciting research and clinical trials. I ensure the high quality of my clinical assessments">&nbsp;</span>*/}
                                {/*</div>*/}
                                {/*<div className="abs" name="Patient"*/}
                                {/*style={{bottom: "20px", left: "65px", width: "80px", height: "60px"}}>*/}
                                {/*<span*/}
                                {/*className="tooltip"*/}
                                {/*data-tooltip-custom="I can donate my data to science while retaining ownership. Many measurements will be performed on my brain, without additional cost to me or my insurer. In the future, I might even benefit from the accessibility of these measurements. Treatments might be tailored to my situation">&nbsp;</span>*/}
                                {/*</div>*/}
                                {/*<div className="abs" name="Computer-Scientist"*/}
                                {/*style={{bottom: "10px", left: "200px", width: "110px", height: "50px"}}><span*/}
                                {/*className="tooltip"*/}
                                {/*data-tooltip-custom="I would love to write new programs that help advance neuroscience. SPINE puts at my fingertip what I have been waiting for: real data, 'gold-standards', and, most of all, real challenges">&nbsp;</span>*/}
                                {/*</div>*/}
                                {/*<div className="abs" name="Computer-Scientist"*/}
                                {/*style={{bottom: "30px", right: "13px", width:" 80px", height: "130px"}}>*/}
                                {/*<span*/}
                                {/*className="tooltip"*/}
                                {/*data-tooltip-custom="I would love to write new programs that help advance neuroscience. SPINE puts at my fingertip what I have been waiting for: real data, 'gold-standards', and, most of all, real challenges">&nbsp;</span>*/}
                                {/*</div>*/}
                                {/*<div className="abs" name="Scientist"*/}
                                {/*style={{top: "40px", left: "40px", width: "60px", height: "60px"}}>*/}
                                {/*<span className="tooltip"*/}
                                {/*data-tooltip-custom="I want to accelerate discovery. There's so much more I could do with the data I collect, if only I had more resources. SPINE allows me to reach and involve trained analysts. I can access large data repositories and advanced technologies from around the world, and design complex experiments on the fly">&nbsp;</span>*/}
                                {/*</div>*/}
                                {/*<div className="abs" name="Scientist"*/}
                                {/*style={{top: "20px", left: "80px", width: "60px", height: "60px"}}>*/}
                                {/*<span className="tooltip"*/}
                                {/*data-tooltip-custom="I  want to accelerate discovery. There's so much more I could do with the data I collect, if only I had more resources. SPINE allows me to reach and involve trained analysts. I can access large data repositories and advanced technologies from around the world, and design complex experiments on the fly">&nbsp;</span>*/}
                                {/*</div>*/}
                                <p className="p-content">
                                    <img id="wheel" src="https://spinevirtuallab.org/public/img/wheel.png"
                                         useMap="wheelmap"/>
                                </p>
                                <map name="wheelmap">
                                    <area shape="rect" coords="0,0,82,126" href="sun.htm" alt="Sun"/>
                                    <area shape="circle" coords="90,58,3" href="mercur.htm" alt="Mercury"/>
                                    <area shape="circle" coords="124,58,8" href="venus.htm" alt="Venus"/>
                                </map>
                            </div>
                            <div className="container-info">
                                <div className="show-role" style={{display: "block"}}><span
                                    className="role">Physician</span><p className="role-descr">I can keep my fiduciary
                                    role towards the patient, and enhance my chances to be involved in exciting research
                                    and clinical trials. I ensure the high quality of my clinical assessments</p></div>
                                <div className="show-info hidden" style={{display: " none"}}>
                                    <span>Move the mouse over each role to see additional information</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
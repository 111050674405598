import React from "react";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Spinner} from "primereact/components/spinner/Spinner";
import {Button} from "primereact/components/button/Button";

/**
 * Represents relative criterion for Timeframe.
 *
 * @param variables - array of variables to choose
 * @param item - criterion
 * @param index - index in Store-based table (used to remove and change)
 * @param onChangeCriterion - clbk function receiving 2 params: (index,item)
 * @param onRemoveCriterion - clbk function receiving 1 param: index
 * @returns {*}
 * @constructor
 */
export const  RelativeTimeFrameCriterion=({ variables,item,index,onChangeCriterion,onRemoveCriterion})=>{

    const options = [{label:"days",value:"DAY"},{label:"months",value:"MONTH"},{label:"years",value:"YEAR"}];
    const optionsVariables = Object.keys(variables).map((el)=>{return {label:variables[el].symbol, value:variables[el].symbol}});

    const canOptions = [{label:"Can be",value:"CAN_BE"},{label:"Cannot be",value:"CANNOT_BE"},];

    /** Unwrapping nested properties structure   */
    const onChange = (group,property,value)=>{
        item[group][property]=value;
        onChangeCriterion(index,item);
    };
    /** Unwrapping properties structure   */
    const onChangeProp = (property,value)=>{
        item[property]=value;
        onChangeCriterion(index,item);
    };

    if (item !=null)
        return  <div className="ui-g-12">
            <div style={{display:'flex',alignItems:'center',justifyContent:'space-around'}}>
                <React.Fragment>
                    <Dropdown options={optionsVariables}
                              placeholder="Select Variable"
                              value={item['variableRelativeFrom']}
                              onChange={(e)=>{onChangeProp('variableRelativeFrom',e.value)}}
                              style={{width:'20em'}}
                              title="Time reference"
                    />
                    <Dropdown options={canOptions}
                              placeholder="Select Time Relation"
                              value={item['relationType']}
                              onChange={(e)=>{onChangeProp('relationType',e.value)}}
                              style={{width:'7em'}}
                              title="Select whether referenced variable should be within or outside a given interval"
                    />

                    <Spinner   min={0}
                               step={1}
                               maxlength={3}
                               size={3}
                               // className="tfCriteriaInput"
                               title="Lower tolerance (before)"
                               value={item['toleranceWindow']['leftLimit']}
                               onChange={(e)=>{onChange('toleranceWindow', 'leftLimit',e.value)}}/>
                    < Dropdown style={{width:'80px'}}
                               placeholder="Select unit"
                               value={item['toleranceWindow']['leftUnits']}
                               onChange={(e)=>{onChange('toleranceWindow', 'leftUnits',e.value)}}
                               options={options}/>

                    before or

                    <Spinner   min={0}
                               step={1}
                               maxlength={3}
                               size={3}
                               // className="tfCriteriaInput"
                               title="Upper tolerance (after)"
                               value={item['toleranceWindow']['rightLimit']}
                               onChange={(e)=>{onChange('toleranceWindow', 'rightLimit',e.value)}}/>
                    < Dropdown style={{width:'80px'}}
                               placeholder="Select unit"
                               value={item['toleranceWindow']['rightUnits']}
                               onChange={(e)=>{onChange('toleranceWindow', 'rightUnits',e.value)}}
                               options={options}/>

                    after

                    <Dropdown options={optionsVariables}
                              placeholder="Select Variable"
                              value={item['variableRelativeTo']}
                              onChange={(e)=>{onChangeProp('variableRelativeTo',e.value)}}
                              style={{width:'20em'}}
                              title="Time reference"
                    />
                    <Button onClick={()=>onRemoveCriterion(index)} icon="fa fa-trash"/>
                </React.Fragment>
            </div>
        </div>;
    else return null;
};

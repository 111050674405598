import React from 'react'
import PropTypes from 'prop-types'
import {Card} from 'primereact/components/card/Card'

class WorkflowItem extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            selected: false,
            color: 'white'
        }

        this.handleSelection = this.handleSelection.bind(this);
    }

    handleSelection(){
        var newCol = this.state.selected? '':'blue';
        this.setState({
            selected: !this.state.selected,
            color: newCol
        });
        console.log("useritem state ", this.state);

    }

    render() {
        const { item } = this.props
        return (
            <div className="ui-g-4" onClick={this.handleSelection}>
                <Card title="Workflow" className="ui-state-hover" style={{backgroundColor: this.state.color}} >
                    <p>{item.firstName}</p>
                    <p>{item.lastName}</p>
                </Card>
            </div>
        )
    }
}

export default WorkflowItem
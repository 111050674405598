import React from "react";
import PropTypes from "prop-types";
import Link from "react-router-dom/es/Link";
import {withRouter} from "react-router";

const LpItemCardElementSlides = ({item,history}) => {

    console.log(item);

    function delete_slide_click(){
        alert("delete");
    }

    return (
        <React.Fragment>
            <div className="experimentListElement">
                <div className = "ui-g-2">{item.pos}</div>
                <div className = "ui-g-2">{item.type}</div>
                <div className = "ui-g-3">Name</div>
                <div className = "ui-g-3">Time</div>
                <div className = "ui-g-2" onClick={delete_slide_click}>
                    Delete
                </div>
            </div>
        </React.Fragment>
    )
};
export default withRouter(LpItemCardElementSlides);

LpItemCardElementSlides.propTypes = {
    item: PropTypes.object.isRequired
};

import React from 'react';

/**
 * Icon for experiment page
 * @returns {*}
 */
export default () =>
<svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13">
    <path fill="#97ACBD" fillRule="evenodd" d="M.8 0C.358 0 0 .364 0 .813v11.374c0 .449.358.813.8.813h10.4c.442 0 .8-.364.8-.813V.813A.807.807 0 0 0 11.2 0H.8zm9.6 4.423v6.952H8V4.423h2.4zM7.2 2.437v8.938H4.8V2.437h2.4zM4 7.404v3.972H1.6V7.403H4z"/>
</svg>

import React from 'react'
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import 'brace/mode/r';
import 'brace/theme/github';
import {Button} from "primereact/components/button/Button";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Column} from "primereact/components/column/Column";
import {EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES, REQUEST_STATUS_REQUESTED} from "../../../Constants";


const placeholder = "" +
    "#Extended Generalized Linear Model\n" +
    "result<-glm(data$y~data$x1+data$x2+data$x1:data$x2)\n" +
    "plot(result, which = c(1,2,3,4,5,6),ask=FALSE)\n" +
    "summary(result)\n"+
    "\n"+
    "##Poisson Regression \n" +
    "#poissonResult<-glm(data$y~data$x1+data$x2+data$x1:data$x2,family=poisson())\n" +
    "#plot(poissonResult, which = c(1,2,3,4,5,6),ask=FALSE)\n" +
    "#summary(poissonResult)\n"+
    "#confint(poissonResult) # 95% CI for the coefficients\n"+
    "#exp(coef(poissonResult)) # exponentiated coefficients\n"+
    "#exp(confint(poissonResult)) # 95% CI for exponentiated coefficients\n"+
    "#predict(poissonResult, type=\"response\") # predicted values\n"+
    "#residuals(poissonResult, type=\"deviance\") # residuals\n";
;


/**
 * Temporarily used as testing page for various components.
 *
 */

export class AuditScriptPanel extends React.Component {

    constructor(){
        super();
        this.state={
            scriptContent:placeholder,
            plotIndex:0
        };
        ["executeScript","changePlotIndex","clearState","columnCopy"]
            .forEach(name => {
                this[name] = this[name].bind(this);
            }); //Binding methods
    }

    executeScript(){

        //TODO Prepare data!!!! Unmock it!
        let y = this.props.data.map((obj)=> {
            return obj.variablesValues[0].variableValue;
        });
        let x1 = this.props.data.map((obj)=> {
            return obj.variablesValues[1].variableValue;
        });
        let x2 = this.props.data.map((obj)=> {
            return obj.variablesValues[2].variableValue;
        });

        let data={"x1":x1.slice(0,1000),"y":y.slice(0,1000),"x2":x2.slice(0,1000)};
        // this.props.data.forEach(((s)=>{
        //     let k=[];
        //     s.variablesValues.forEach((n,i)=>{
        //         if (i>0) //remove y from data
        //             k.push(n.variableValue)});//add x vector value
        //     data.push(k)}));

        this.setState({plotIndex:0});
        this.props.executeScript(this.state.scriptContent,data);
    }
    changePlotIndex(step){
        let t = this.state.plotIndex + step;
        let lastIndex =this.props.plots.length-1;
        t=(t<0)?0:t;
        t=(t>lastIndex)?lastIndex:t;
        this.setState({plotIndex:t});
    }
    clearState(){
        this.setState({plotIndex:0});
        this.props.clearOutput();
    }
    columnCopy(data){
        return <div>
            <i className="fa fa-clipboard" title="Copy to clipboard"  style={{color:'#00D2D0',fontSize:'medium'}}
               onClick={()=>{navigator.clipboard.writeText(data['symbol'])}}/>
        </div>
    }


    render() {
        const{clearOutput,plots,data,scriptExecutorState,experiment}=this.props;
        const{plotIndex}=this.state;
        console.log("News ...render");
        let disabledLeft = (plotIndex===0)?"ui-state-disabled":"";
        let disabledRight = (plots!=null && plotIndex===(plots.length-1))?"ui-state-disabled":"";

        const variablesData = (data!=null && data.length>0)
            ?data[0].variablesValues.map((el,index)=>{
                const variable = experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][el.variableName];
                let varName =null;
                if (variable["entityType"]==="SUBJECT")
                    varName = variable['clinicalVariableId']['name'];
                else if(variable["entityType"]==="ANATOMICAL")
                    varName = variable['metricAnnotation']['preferredName'] +" of "+
                              variable['anatomicalAnnotation']['preferredName']  ;
                else if(variable["entityType"]==="PATHOLOGICAL")
                    varName = variable['metricAnnotation']['preferredName'] +" of " +
                        variable['pathologicalAnnotation']['preferredName'] + " in "+
                        variable['anatomicalAnnotation']['preferredName'];
                return {
                symbol:(index===0)?"data$y":"data$x"+index,
                name:varName,
                size:data.length}})
            :[{name:"No data",size:0,symbol:""}];

        return (
            <div  style={{margin: '16px'}}>
                <div className="ui-g-5">
                    <div style={{textAlign:"left"}}>Script</div>
                    <AceEditor
                        mode="r"
                        theme="github"
                        onChange={(value)=>{this.setState({scriptContent:value})}}
                        name="EDITOR"
                        editorProps={{$blockScrolling: true}}
                        highlightActiveLine={true}
                        value={this.state.scriptContent}
                        height="300px"
                    />
                    <div style={{textAlign:"left"}}>Console Output</div>
                    <AceEditor
                        mode="r"
                        theme="github"
                        onChange={(value)=>{console.log('change',value);}}
                        name="OUTPUT"
                        readOnly={true}
                        editorProps={{$blockScrolling: true}}
                        highlightActiveLine={false}
                        value={this.props.stdOut}
                        showGutter={false}
                        height="300px"
                    />
                </div>
                <div className="ui-g-2">
                    <Button  onClick={this.props.handleSubmitPrevious} label="Previous" id="previous"/>
                    <Button icon={(scriptExecutorState===REQUEST_STATUS_REQUESTED)?"fa fa-spinner fa-spin":"fa fa-play-circle-o "}
                            label={"Run"} onClick={this.executeScript} />
                    <Button icon="fa fa-trash" label={"Clear"} onClick={()=>this.clearState()}/>
                </div>
                <div className="ui-g-5">
                    <div>
                        <div style={{textAlign:"left"}}>Variables</div>
                        <DataTable value={variablesData}>
                            <Column body={this.columnCopy} style={{width:"2em"}} />
                            <Column field="symbol" header="Symbol" />
                            <Column field="name" header="Name" />
                            <Column field="size" header="Size" />
                        </DataTable>
                    </div>
                    <div>
                        {
                            this.props.plots != null && this.props.plots.length > 0 &&
                        <div style={{textAlign:"left"}}>Plots</div>}
                    {
                        this.props.plots != null && this.props.plots.length > 0 &&
                        <div style={{marginTop:"5px"}}>
                            {this.props.plots.length > 1 &&
                            <div>
                                <Button icon="fa fa-arrow-left" onClick={()=>{this.changePlotIndex(-1)}} className={disabledLeft}/>
                                <Button icon="fa fa-arrow-right" onClick={()=>{this.changePlotIndex(1)}}  className={disabledRight}/>
                            </div>
                            }
                            <img src={`${this.props.plots[this.state.plotIndex]}`}/>
                        </div>
                    }
                    </div>
                </div>
            </div>
        )
    }
}

AuditScriptPanel.propTypes = {
    clearOutput: PropTypes.func,
    executeScript:PropTypes.func,
    scriptExecutorState:PropTypes.string,
    data:PropTypes.array
};

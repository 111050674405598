import {connect} from "react-redux";
import {TaskPropertiesTable} from "../component/TaskPropertiesTable";
import {getTaskList} from "../action/TaskAction";

/**
 *
 * @param state - Redux store state
 * @param ownProps - call parameters
 * @returns {{workflows: *|Array}}
 */
const mapStateToProps = (state, ownProps) => {
    console.log("tasks", state.expDesign.tasks.tasks);
    return {
        tasks: state.expDesign.tasks
        // experiment:state.expDesign.experiment
    }
};

/**
 *
 * @param dispatch
 * @param ownProps - call params functions
 * @returns {{getWorkflows: function()}}
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
         getTaskList:(workflowId)=>{dispatch(getTaskList(workflowId))}
    }
};

const containerTask = connect( mapStateToProps, mapDispatchToProps);
export const ContainerTaskPropertiesTable = containerTask(TaskPropertiesTable);
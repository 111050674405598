import {
    EXPERIMENT_LIST_FAIL,
    EXPERIMENT_LIST_REQUEST, EXPERIMENT_LIST_SUCCESS, PROJECT_LIST_FAIL, PROJECT_LIST_REQUEST,
    PROJECT_LIST_SUCCESS, SET_EXPERIMENT_SORTER, SET_EXPERIMENT_VISIBILITY_FILTER
} from '../action/actionType'
import {combineReducers} from "redux";


const projectListReducer = (state = {projectsState: '', projects: []}, action) => {
    switch (action.type) {
        case PROJECT_LIST_REQUEST:
            return Object.assign({}, state, {
                projectsState: 'requested',
            });
        case PROJECT_LIST_SUCCESS:
            return Object.assign({}, state, {
                projectsState: 'success',
                projects: action.projects
            });

        case PROJECT_LIST_FAIL:
            return Object.assign({}, state, {
                projectsState: action.err,
            });

        default:
            return state;
    }
};

const projectReducer = (state = {projectState: '', project: {}}, action) => {
    switch (action.type) {
        case 'PROJECT_REQUEST':
            return Object.assign({}, state, {
                projectState: 'requested'
            });
        case 'PROJECT_FAIL':
            console.log('Project request failed');
            return Object.assign({}, state, {
                projectState: 'failure',
                project: null
            });
        case 'PROJECT_SUCCESS':
            console.log('Project request succeeded');
            return Object.assign({}, state, {
                projectState: 'success',
                project: action.project
            });
        default:
            return state;
    }
};

const experimentsReducer = (state = {experimentsState: '', experiments: [],visibilityFilter:new Set(), sorter:'SORT_BY_STATUS_DESC'}, action) => {
    switch (action.type) {
        case EXPERIMENT_LIST_REQUEST:
            return Object.assign({}, state, {
                experimentsState: 'requested'
            });
        case EXPERIMENT_LIST_FAIL:
            return Object.assign({}, state, {
                experimentsState: 'failure',
            });
        case EXPERIMENT_LIST_SUCCESS:
            return Object.assign({}, state, {
                experimentsState: 'success',
                experiments: action.experimentList
            });
        case SET_EXPERIMENT_VISIBILITY_FILTER:
            return Object.assign({}, state, {
                visibilityFilter: action.filter,
            });

        case SET_EXPERIMENT_SORTER:
            return Object.assign({}, state, {
                sorter: action.sorter,
            });
        default:
            return state;
    }
};

export const ProjectReducer = combineReducers({
    projects: projectListReducer,
    project: projectReducer,
    experiments:experimentsReducer
});
import { combineReducers } from 'redux'

import { cohortListReducer, cohortReducer } from './reducer/CohortReducer'
import { subjectListReducer, subjectReducer } from './reducer/SubjectReducer'
import { sessionListReducer, sessionReducer } from './reducer/SessionReducer'
import {variableListReducer, variableReducer} from "./reducer/VariableReducer";
import {modelListReducer, modelReducer} from "./reducer/ModelReducer";
import {annotationsReducer} from "./reducer/AnnotationReducer";

const VisuReducer = combineReducers({
	studies: cohortListReducer,
	study: cohortReducer,
	subjects: subjectListReducer,
	subject: subjectReducer,
	sessions: sessionListReducer,
	session: sessionReducer,
	models: modelListReducer,
	model:modelReducer,
	variables:variableListReducer,
	variable:variableReducer,
	annotations:annotationsReducer
});

export default VisuReducer;
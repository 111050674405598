import React from 'react'
import {withRouter} from "react-router-dom";


/**
 * "Dumb" component for starting creation new Experiment.
 * How would you like to start your Experiment?
 * Comment souhaitez-vous commencer votre Expérience ?
 *
 */

 export const ExperimentChoiceView = ({initExperiment})=> {

        return (
            <div>
                <div className="expChoiceClose"/>
                <h1 align="center" style={{margin: '20px auto'}}>
                    How would you like to start your Experiment?
                </h1>
                <div className="rectExpContainer">
                    <div className="rectExp"  onClick={initExperiment}>
                        <div className="rectExpIntNew"/>
                        <div className="rectExpInt">
                            A brand new Experiment
                        </div>
                    </div>
                    <div className="rectExp">
                        <div className="rectExpIntTemp"/>
                        <div className="rectExpInt">
                            From template
                        </div>
                    </div>
                    <div className="rectExp">
                        <div className="rectExpIntDup"/>
                        <div className="rectExpInt">
                            <div>
                                <span> From existing Experiment </span>
                                <br/>
                                <span style={{fontSize:'small',marginLeft:'2em'}}>45 experiments available </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    // }
};



export default withRouter(ExperimentChoiceView);


import {
    EXPDESIGN_DATA_DATASET_FAIL,
    EXPDESIGN_DATA_DATASET_REQUEST,
    EXPDESIGN_DATA_DATASET_SUCCESS, EXPDESIGN_DATA_EXCLUSION_CRITERIA, EXPDESIGN_DATA_INCLUSION_CRITERIA,
    EXPDESIGN_DATA_SELECTED_STUDIES,
    EXPDESIGN_DATA_STUDIES,
    EXPDESIGN_DATA_SUBJECTS, EXPDESIGN_DATASET_CLEAR, EXPDESIGN_EXPERIMENT_SAVE_SUCCESS, EXPDESIGN_VARIABLE_PLOT_FAIL,
    EXPDESIGN_VARIABLE_PLOT_REQUEST,
    EXPDESIGN_VARIABLE_PLOT_SUCCESS, EXPDESIGN_VARIABLE_STATS_FAIL, EXPDESIGN_VARIABLE_STATS_REQUEST,
    EXPDESIGN_VARIABLE_STATS_SUCCESS
} from '../action/actionType'
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";

export const dataSelectionReducer = (state = {
    dataset:[],
    datasetState:'',
    selectedStudies:[],
    plotStates:new Map(), //map for plot states where key is composite of {S(elected),A(ll),D(iff)} + variable.uuid + {H(istogram),K(de),B(oxplot)}
    plotData:new Map(), //map for data with keys as above
    statisticsStates:new Map(),//map for statistics states where key is composite of {S(elected),A(ll),D(iff)} + variable.uuid
    statisticsData:new Map() //map for statistics data with keys as above
    }  //map for histograms where key is variable.uuid
    , action) => {
	switch (action.type) {

	    case EXPDESIGN_DATASET_CLEAR:
        return Object.assign({},state, {
            datasetState: "CLEARED",
        });

		case EXPDESIGN_DATA_STUDIES:
			return Object.assign({},state, {
        		studies: action.studies
      		});
		case EXPDESIGN_DATA_SUBJECTS:
			return Object.assign({},state, {
        		subjects: action.subjects
      		});
        case EXPDESIGN_DATA_DATASET_SUCCESS:
            return Object.assign({},state, {
                datasetState: REQUEST_STATUS_SUCCESS,
                dataset: action.data
            });
        case EXPDESIGN_DATA_DATASET_REQUEST:
        return Object.assign({},state, {
            datasetState: REQUEST_STATUS_REQUESTED,
        });
        case EXPDESIGN_DATA_DATASET_FAIL:
            return Object.assign({},state, {
                datasetState: REQUEST_STATUS_FAIL,
            });

        case EXPDESIGN_DATA_SELECTED_STUDIES:
            return Object.assign({},state, {
                selectedStudies: action.selected
            });

        case EXPDESIGN_VARIABLE_PLOT_REQUEST:{
            return Object.assign({},state, {
                plotStates: state.plotStates.set(action.plotId,REQUEST_STATUS_REQUESTED),
                plotData: state.plotData.set(action.plotId,{})
                            });}
        case EXPDESIGN_VARIABLE_PLOT_FAIL:
            return Object.assign({},state, {
                plotStates: state.plotStates.set(action.plotId,REQUEST_STATUS_FAIL),
                plotData: state.plotData.set(action.plotId,action.err)
            });
        case EXPDESIGN_VARIABLE_PLOT_SUCCESS:
            return Object.assign({},state, {
                plotStates: state.plotStates.set(action.plotId,REQUEST_STATUS_SUCCESS),
                plotData: state.plotData.set(action.plotId,action.data)
            });
        case EXPDESIGN_VARIABLE_STATS_REQUEST:{
            return Object.assign({},state, {
                statisticsStates: state.statisticsStates.set(action.statsId,REQUEST_STATUS_REQUESTED),
                statisticsData: state.statisticsData.set(action.statsId,{})
            });}
        case EXPDESIGN_VARIABLE_STATS_FAIL:
            return Object.assign({},state, {
                statisticsStates: state.statisticsStates.set(action.statsId,REQUEST_STATUS_FAIL),
                statisticsData: state.statisticsData.set(action.statsId,action.err)
            });
        case EXPDESIGN_VARIABLE_STATS_SUCCESS:
            return Object.assign({},state, {
                statisticsStates: state.statisticsStates.set(action.statsId,REQUEST_STATUS_SUCCESS),
                statisticsData: state.statisticsData.set(action.statsId,action.data)
            });
		default:
			return state;
	}
};
import React from 'react'
import GraphView from 'react-digraph'
import {GraphConfig} from "./GraphConfig";
import {SelectButton} from "primereact/components/selectbutton/SelectButton";
import PropTypes from "prop-types";
import {ContainerTaskPropertiesTable} from "../expDesign/containers/ContainerTasks";
import {WorkflowPropertiesTable} from "../expDesign/component/WorkflowPropertiesTable";


const EMPTY_TYPE = "emptyTask";  // Text on empty nodes is positioned differently
const NODE_KEY = "id";       // Allows D3 to correctly update DOM

export class Graph extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            layout: 'VerticalTree',
            graph: this.props.workflow,
            selected: null,
            selectedEdge:null
        };
        this.onSelectEdge = this.onSelectEdge.bind(this);
        this.onSelectNode = this.onSelectNode.bind(this);
        this.onCreateNode = this.onCreateNode.bind(this);
        this.onUpdateNode = this.onUpdateNode.bind(this);
        this.onDeleteNode = this.onDeleteNode.bind(this);
        this.onCreateEdge = this.onCreateEdge.bind(this);
        this.onSwapEdge = this.onSwapEdge.bind(this);
        this.onDeleteEdge = this.onDeleteEdge.bind(this);
    }


    onCreateNode() {
    }

    onUpdateNode() {
    }

    onDeleteNode() {
    }

    onCreateEdge() {
    }

    onSwapEdge() {
    }

    onDeleteEdge() {
    }


    onSelectEdge(e) {
        console.log('Selected edge', e);
        this.setState({selectedEdge: e});
    }

    onSelectNode(e) {
        console.log('Selected node', e);
        this.setState({selected: e});
    }

    /* Define custom graph editing methods here */

    render() {
        const nodes = this.props.workflow.nodes;
        const edges = this.props.workflow.edges;
        const selected = this.state.selected;

        const NodeTypes = GraphConfig.NodeTypes;
        const NodeSubtypes = GraphConfig.NodeSubtypes;
        const EdgeTypes = GraphConfig.EdgeTypes;
        const task = this.state.selected;
        const edge = this.state.selectedEdge;
        return (
            <div>
                <div style={{width: '50%', height: '540px', float: 'left'}}>

                    <GraphView
                        ref='GraphView'
                        nodeKey={NODE_KEY}
                        // emptyType={EMPTY_TYPE}
                        nodes={nodes}
                        edges={edges}
                        selected={selected}
                        nodeTypes={NodeTypes}
                        nodeSubtypes={NodeSubtypes}
                        edgeTypes={EdgeTypes}
                        onSelectNode={this.onSelectNode}
                        onSelectEdge={this.onSelectEdge}
                        // getViewNode={this.getViewNode}
                        onCreateNode={this.onCreateNode}
                        onUpdateNode={this.onUpdateNode}
                        onDeleteNode={this.onDeleteNode}
                        onCreateEdge={this.onCreateEdge}
                        onSwapEdge={this.onSwapEdge}
                        onDeleteEdge={this.onDeleteEdge}
                        layoutEngineType={this.state.layout}
                        showGraphControls={false}
                    />

                    <SelectButton value={this.state.layout}
                                  options={[{value: 'None', label: 'none'},
                                      {value: 'SnapToGrid', label: 'SnapToGrid'},
                                      {value: 'VerticalTree', label: 'VerticalTree'}]}
                                  onChange={(e) => this.setState({layout: e.value})}/>
                </div>
                <div style={{width: '50%', float: 'left'}}>


                    <div style={{margin: "2em", height: '250px', overflowY: 'auto'}}>
                        <WorkflowPropertiesTable selectedWorkflow={this.props.workflowDescription}/>
                    </div>

                    {(task != null) && (task.type === "Task") &&
                    <div style={{margin: "2em", height: '250px', overflowY: 'auto'}}>
                        <ContainerTaskPropertiesTable selectedTask={task}/>
                    </div>
                    }
                    {/*{edge != null &&*/}
                    {/*<table style={{width: '100%', margin: "2em"}}>*/}
                        {/*<tbody>*/}
                        {/*<tr>*/}
                            {/*<th colSpan="2">Connection:</th>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                            {/*<td>Source:</td>*/}
                            {/*<td>{edge.source}</td>*/}
                        {/*</tr>*/}
                        {/*<tr>*/}
                            {/*<td>Target:</td>*/}
                            {/*<td>{edge.target}</td>*/}
                        {/*</tr>*/}
                        {/*</tbody>*/}
                    {/*</table>}*/}

                </div>
            </div>
        );
    }

}

Graph.propTypes = {
    workflow: PropTypes.object
};


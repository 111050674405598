import React from 'react'
import PropTypes from 'prop-types'



export default class Events extends React.Component {

    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
    }

    componentWillMount() {
        const {getProjects} = this.props;
        getProjects();
    }

    render() {
        let {projects} = this.props;
        projects = [];
        const projectNodes = projects.map((p) =>
            <div className='spineRowStyle' key={p.uuid}>
                <div className="spineColStyle" onClick={this.handleClick}>{p.name}</div>
                <div className="spineColStyle">{p.name}</div>
                <div className="spineColStyle">{p.name}</div>
            </div>);
        return (
            <div style={{width:'96%',margin:'2%'}}>
                <span className="reg-headtitle">My events</span>
                <div className="spineTableStyle" style={{minHeight:"200px"}}>
                    {/*<div className='spineRowHeaderStyle'>*/}
                        {/*<div className="spineColStyle">Skills</div>*/}
                        {/*<div className="spineColStyle">Expertise</div>*/}
                        {/*<div className="spineColStyle">Contribution</div>*/}
                    {/*</div>*/}
                    {/*{projectNodes}*/}
                </div>
            </div>
        );
    }
};
Events.propTypes = {
    getProjects: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
};
import React from "react";
import PropTypes from "prop-types";
import {ExperimentItemCardElement} from "./ExperimentItemCardElement";

/** Class responsible for displaying Items in List.
 *  Can work in {list, cards} modes.
 *  The props are crucial: see Proptypes
 *  @see ItemList
 *  @see ExperimentItemCardElement
 */

export class ItemListElement extends React.Component {


    constructor() {
        super();
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(e) {
        this.setState({sortCriterion: e.value});
    }

    render() {
        const {item, isList,classNames,properties,cardRenderer,listRenderer} = this.props;

        return (
            <React.Fragment>
                {!isList &&
                cardRenderer
                }
                {isList && listRenderer==null&&
                <div style={{width: '80%', marginLeft: '10%', marginRight: '10%', display: 'inline-flex', backgroundColor:'white'
                    , padding:'10px', marginBottom:'1px'}}>
                    {classNames.map((header, i) => <div className={classNames[i]} key={i} >{item[properties[i]]}</div>)}
                </div>
                }
                {isList &&listRenderer!=null&&
                    listRenderer
                }
            </React.Fragment>
        )
    }
}

ItemListElement.propTypes = {
    item: PropTypes.object.isRequired, //item to display
    isList: PropTypes.bool.isRequired, // indicator of display mode
    classNames: PropTypes.array.isRequired, // array of CSS grid classes used for content layout (eg. ["ui-g-6","ui-g-6"])
    properties: PropTypes.array.isRequired, // array of properties - size must be equal to size of classnames
    cardRenderer: PropTypes.object, //see ItemList class
    listRenderer: PropTypes.object
};
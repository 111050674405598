import {
    SET_COHORT_SORTER,
    SET_COHORT_VISIBILITY_FILTER, STUDY_LIST_FAIL, STUDY_LIST_REQUEST,
    STUDY_LIST_SUCCESS
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";


const setVisibilityFilter = (state, action) => {

    return Object.assign({}, state, {
        visibilityFilter: action.filter,
    });

};

const setSorter = (state, action) => {

    return Object.assign({}, state, {
        sorter: action.sorter,
    });

};

export const cohortListReducer = (state = { studiesState: '', studies: [], visibilityFilter:'', sorter:'SORT_BY_NAME_DESC' }, action) => {
	switch (action.type) {
		case STUDY_LIST_REQUEST:
			return Object.assign({},state, {
        		studiesState: REQUEST_STATUS_REQUESTED
      });
		case STUDY_LIST_FAIL:
			return Object.assign({},state, {
				studiesState: REQUEST_STATUS_FAIL
			});
		case STUDY_LIST_SUCCESS:
			return Object.assign({}, state, {
				studiesState: REQUEST_STATUS_SUCCESS,
        		studies: action.studies
      });
        case SET_COHORT_VISIBILITY_FILTER:
            return setVisibilityFilter(state, action);
        case SET_COHORT_SORTER:
            return setSorter(state, action);
		default:
			return state;
	}
};

export const cohortReducer = (state = { studyState: '', study: {} }, action) => {
	switch (action.type) {
		case 'STUDY_REQUEST':
			return Object.assign({},state, {
        		studiesState: 'requested'
      });
		case 'STUDY_FAIL':
			return ;
		case 'STUDY_SUCCESS':
			return Object.assign({}, state, {
				studyState: 'success',
				study: action.study
			});
		default:
			return state;
	}
};
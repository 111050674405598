import React from 'react'
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Route, Switch, withRouter} from 'react-router-dom'
import {Redirect} from 'react-router-dom'
import LayoutTopbar from '../root/LayoutTopbar'
import {LeftNavigationBar} from "../root/LeftNavigationBar";
import News from "../root/News";
import Tour from "../root/Tour";
import Project from "../project/Project";
import ContainerExpDesign from "../expDesign/containers/ContainerExpDesign";
import Dashboard from "../dashboard/Dashboard";
import ExperimentChoiceView from "../project/component/ExperimentChoiceView";
import BrainBrowserDialog from "../vtk/BrainBrowserDialog";
import LivePresenter from "../root/LivePresenter";
import {
    ContainerPlayAutomatically, ContainerPlayManually,
    ContainerSlides
} from "../livePresenter/container/ContainerSlides";
import playSlidesAuto from "../livePresenter/component/playSlidesAuto";
import playSlidesManual from "../livePresenter/component/playSlidesManual";
import {ContainerTaskView} from "../visualization/containers/ContainerTaskView";

/**
 *  Main panel of application. It contains conditionally rendered (or disabled) Tabs.
 *  Navigation between Tabs does not require Router!!!
 *  {@link FIXME here link to documentation}
 */
class Home extends React.Component {

    constructor() {
        super();

    }
    /** Checks whether any of the roles assigned to User is in a given set of Roles
     * @param assignedRoles  roles already assigned to User
     * @param allowedRoles   roles required to have access to a given resource
     * @returns {boolean} true if
     */
    hasUserARole(assignedRoles, allowedRoles) {
        if (!(allowedRoles && allowedRoles.length))
            return false;
        return !!allowedRoles.some(r => assignedRoles.indexOf(r) >= 0);
    }



    render() {
        const activeRole = [this.props.auth.role_active];
        const loginStatus = [this.props.auth.login_status];

        const {match,location,history, appState} = this.props;
        let pageTitle="";

        if (loginStatus != 'logged') {
            return <Redirect to='/'/>
        }
        const allRoles = ['scientific', 'contributor', 'administrator', 'admin'];
        const onlyScientist = ['scientific'];
        // let fgrowl = function (d) { alert("on click" + d.x);};
        // let fgrowl =  this.showMessage;

        return (
            <div>
                {/*<Growl ref={(el) => { this.growl = el; }}/>*/}
                <LayoutTopbar match={match} location={location} history={history} appState={appState}/>
                <LeftNavigationBar />
                <div className="mainContents">
                    {/*<div style={{margin: '16px'}}>*/}
                        <Switch>
                            <Route path='/home' component={Dashboard}/>
                            <Route path='/news' component={News}/>
                            {/*<Route path='/expdesign' component={ContainerExpDesign}/>*/}
                            <Route path='/tour' component={Tour}/>
                            <Route path='/project' component={Project} match={match}/>
                            <Route path='/task/:id' component={ContainerTaskView}/>
                            <Route path='/expchoice' component={ExperimentChoiceView} match={match}/>
                            <Route path='/skills' component={BrainBrowserDialog} match={match}/>
                            <Route exact path='/livePresenter' component={LivePresenter}/>
                            <Route exact path='/livePresenter/:id' component={ContainerSlides} />
                            <Route exact path='/livePresenter/playManual/:id' component={ContainerPlayManually} />
                            <Route exact path='/livePresenter/playAuto/:id' component={ContainerPlayAutomatically} />
                        </Switch>
                        {/*<TabView>*/}
                        {/*<TabPanel header="News" leftIcon="fa-lightbulb-o">*/}
                        {/*<BrainBrowserDialog />*/}
                        {/*</TabPanel>*/}
                        {/*<TabPanel header="Scatter Sandbox" leftIcon="fa-lightbulb-o">*/}
                        {/*/!*<ScatterPlotPanel />*!/*/}
                        {/*</TabPanel>*/}
                         {/*<TabPanel header="Projects" leftIcon="fa-th-list"*/}
                         {/*disabled={!this.hasUserARole(activeRole, onlyScientist)}><Project/></TabPanel>*/}
                         {/*<TabPanel header="Experiments" leftIcon="fa-th-list"*/}
                         {/*disabled={!this.hasUserARole(activeRole, allRoles)}><ExpDesign/></TabPanel>*/}
                         {/*<TabPanel header="Studies" leftIcon="fa-th-list"*/}
                         {/*disabled={!this.hasUserARole(activeRole, allRoles)}><Study/></TabPanel>*/}
                         {/*</TabView>*/}
                         {/*<Study/>*/}
                    {/*</div>*/}
                </div>
                {/*<Footer/>*/}
            </div>

        )
    }
}

Home.propTypes = {
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
};

// function mapStateToProps(state) {
//     console.log('App map: ', state)
//     return state.auth;
// }
const mapStateToProps = state => {
    return{
        auth : state.auth,
        appState: state
    }
};
export default withRouter(connect(mapStateToProps)(Home));
import React, {Component} from 'react'
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import PropTypes from "prop-types";
import {minLength, mustMatch, required} from "../../helpers/validation/rules";
import {ruleRunner, run} from "../../helpers/validation/ruleRunner";
import DropdownWithValidation from "../../helpers/validation/DropdownWithValidation";
import AutocompleteWithValidation from "../../helpers/validation/AutocompleteWithValidation";
import {CLINICAL_FEATURES} from "./MockData";
import {Tooltip} from "primereact/components/tooltip/Tooltip";
import {Button} from "primereact/components/button/Button";
import {
    CATEGORICAL_OPERATOR,

    EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR, EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE,
    EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VARIABLE_ID,
    EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID, ORDINAL_OPERATOR, REQUEST_STATUS_SUCCESS, TEXTUAL_OPERATOR,
    VARIABLE_PROPERTIES_NAMES,
    VARIABLE_PROPERTY_VALUE_VARIABLE_TYPE
} from "../../../Constants";
import {Calendar} from "primereact/components/calendar/Calendar";
import {Spinner} from "primereact/components/spinner/Spinner";
import SelectionCriteriaGroup from "./SelectionCriteriaGroup";
import {InputText} from "primereact/components/inputtext/InputText";

const unitOptions = [{label:"days",value:"DAY"},{label:"months",value:"MONTH"},{label:"years",value:"YEAR"}];
const optionsRef = [{label:"None",value:"NONE"},
    {label:"Date Of Birth",value:"DOB"},
    {label:"First Attack Date",value:"First Attack"}
];
/**
 * Component representing single inclusion/exclusion criterion.
 * Operators and values are set dynamically based on variable's type.
 *
 */
class SelectionCriteriaItem extends React.Component {

    constructor(){
        super();
        ["wrapTimeFrameForCallback"
        ].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }

    wrapTimeFrameForCallback(group,property,val,tf){
        const {clbToCriterion,value,index}=this.props;
        if (group!=="referenceTimeFrame")
            tf[group][property] = val;
        else
            tf[group] = val;
        clbToCriterion( index, 'timeFrame',tf);
    }


    render() {
        const {visu,clbToCriterion,clbToRemoveCriterion,value,index}=this.props;
        let allOperatorOptions = null;
        try {
           if (visu.variables.operatorVariablesState===REQUEST_STATUS_SUCCESS){
               allOperatorOptions = visu.variables.operatorVariables;
           }
        }
        catch(err) {
            return <div/>
        };
        const valValues = [{label: "0", value:0},{label: "5", value:5}, {label: "10", value: 10}];
        let valOptions=valValues;
        const variableId=value[EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID];
        const variable=visu.variables.variables.find((el)=>{return el.uuid===variableId});

        let operatorOptions = null;
        let valueInputComponent = null;

        let variableType = (variable!=null)?variable[VARIABLE_PROPERTIES_NAMES.VARIABLE_TYPE]:null;

        if (variableType===VARIABLE_PROPERTY_VALUE_VARIABLE_TYPE.MULTIPLE_CHOICE && variable.hasOwnProperty('options')){
            valOptions = variable['options'].map((s)=>{return {label:s,value:s}});
        }

        // let timeframe = (value['timeFrame']!=null)?value['timeFrame']:;
        let timeframe = value['timeFrame'];

        switch (variableType) {
            case VARIABLE_PROPERTY_VALUE_VARIABLE_TYPE.MULTIPLE_CHOICE:
                operatorOptions = allOperatorOptions.filter((el)=>{return el.type === CATEGORICAL_OPERATOR});
                valueInputComponent =  <Dropdown value={value[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE]}
                                                 options={valOptions}
                                                 placeholder="Select Value"
                                                 editable={true}
                                                 style={{margin: '5px', width:'7em'}}
                                                 disabled={!(value[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR]!=null)}
                                                 onChange={(e) => {clbToCriterion(index, EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE,e.value)}}
                />;
                break;
            case VARIABLE_PROPERTY_VALUE_VARIABLE_TYPE.NUMBER:
                operatorOptions = allOperatorOptions.filter((el)=>{return el.type === ORDINAL_OPERATOR});
                valueInputComponent =  <Spinner value={value[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE]}
                                                disabled={!(value[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR]!=null)}
                                                size={8} onChange={(e) => {clbToCriterion(index, EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE,e.value)}} />;
                break;
            case VARIABLE_PROPERTY_VALUE_VARIABLE_TYPE.DATE:
                operatorOptions = allOperatorOptions.filter((el)=>{return el.type === ORDINAL_OPERATOR});
                valueInputComponent =  <Calendar value={new Date(value[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE])}
                                                 disabled={!(value[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR]!=null)}
                                                 onChange={(e) => {clbToCriterion(index, EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE,e.value)}}
                />;
                break;
            case VARIABLE_PROPERTY_VALUE_VARIABLE_TYPE.TEXT:
                operatorOptions = allOperatorOptions.filter((el)=>{return el.type === TEXTUAL_OPERATOR});
                valueInputComponent = <Dropdown value={value[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE]}
                          options={valValues}
                          placeholder="Select Value"
                          editable={true}
                          style={{margin: '5px', width:'7em'}}
                          disabled={!(value[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR]!=null)}
                          onChange={(e) => {clbToCriterion(index, EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE,e.value)}}
                />;
                break;
            default: {
                operatorOptions = allOperatorOptions.filter((el)=>{return el.type === TEXTUAL_OPERATOR});
                valueInputComponent = <Dropdown value={value[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE]}
                          options={valValues}
                          placeholder="Value"
                          editable={true}
                          style={{margin: '5px', width:'5em'}}
                          disabled={!(value[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR]!=null)}
                          onChange={(e) => {clbToCriterion(index, EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE,e.value)}}
                />;
                break;

            }
        }

        return (
            <div>
                {/*<p style={{display:'inline-block'}}>{this.props.index+1}.</p>*/}

                <Dropdown value={variable}
                          options={visu.variables.clinicalVariables}
                          optionLabel="name"
                          filter={true} filterPlaceholder="Select Variable"
                          filterBy="label,value" placeholder="Select Variable"
                          style={{margin: '5px', width:'250px'}}
                          showClear={true}
                          onChange={(e) => {clbToCriterion(index, EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VARIABLE_ID,e.value.uuid)}}
                />

                <Dropdown value={value[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR]}
                    options={operatorOptions} placeholder="Select Operator" disabled={!(value[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VARIABLE_ID]!=null)}
                          style={{margin: '5px', width:'5em'}}
                          optionLabel={'label'}
                          onChange={(e) => {clbToCriterion( index, EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR,e.value)}}
                />
                {valueInputComponent}
                        <i className="fa fa-clock-o" style={{marginLeft:"1em",marginRight:"1em"}} title="Specify  time window for a variable"/>

                <Dropdown options={optionsRef}
                          placeholder="Select a Time Reference"
                          value={timeframe['referenceTimeFrame']}
                          onChange={(e)=>{ this.wrapTimeFrameForCallback("referenceTimeFrame",null,e.value,timeframe)}}
                          style={{width:'150px'}}
                          title="Time reference"
                />
                <i className="fa fa-at" style={{marginLeft:"1em",marginRight:"1em"}}  title="At a given time" />

                <Spinner   step={1}
                           maxlength={3}
                           size={3}
                    // className="tfCriteriaInput"
                           title="Target date"
                           value={timeframe['targetDate']['distance']}
                           onChange={(e)=>{ this.wrapTimeFrameForCallback('targetDate','distance',e.value,timeframe)}}/>
                < Dropdown style={{width:'80px'}}
                           placeholder="Select unit"
                           value={timeframe['targetDate']['units']}
                           onChange={(e)=>{this.wrapTimeFrameForCallback('targetDate','distance',e.value,timeframe)}}
                           options={unitOptions}/>
                <i className="fa fa-minus-square-o" style={{marginLeft:"1em",marginRight:"1em"}} title="With tolerance before"/>

                        <Spinner   min={0}
                                   step={1}
                                   maxlength={3}
                                   size={3}
                            // className="tfCriteriaInput"
                                   title="Lower tolerance (before)"
                                   value={timeframe['toleranceWindow']['leftLimit']}//item['toleranceWindow']['leftLimit']}
                                   onChange={(e)=>{this.wrapTimeFrameForCallback('toleranceWindow', 'leftLimit',e.value,timeframe)}}/>
                        < Dropdown style={{width:'80px'}}
                                   placeholder="Select unit"
                                   value={timeframe['toleranceWindow']['leftUnits']}
                                   onChange={(e)=>{ this.wrapTimeFrameForCallback('toleranceWindow','leftUnits',e.value,timeframe)}}
                                   options={unitOptions}/>

                <i className="fa fa-plus-square-o" style={{marginLeft:"1em",marginRight:"1em"}} title="With tolerance after"/>

                        <Spinner   min={0}
                                   step={1}
                                   maxlength={3}
                                   size={3}
                            // keyfilter="pnum"
                            // className="tfCriteriaInput"
                                   title="Upper tolerance (after)"
                                   value={timeframe['toleranceWindow']['rightLimit']}
                                   onChange={(e)=>{this.wrapTimeFrameForCallback('toleranceWindow', 'rightLimit',e.value,timeframe)}}/>
                        < Dropdown style={{width:'80px'}}
                                   placeholder="Select unit"
                                   value={timeframe['toleranceWindow']['rightUnits']}
                                   onChange={(e)=>{this.wrapTimeFrameForCallback('toleranceWindow', 'rightUnits',e.value,timeframe)}}
                                   options={unitOptions}/>
                <Button id="removeCriteria" icon="fa fa-minus" onClick={() => {clbToRemoveCriterion(value.uuid)}}/>

                </div>


        )
    }
}

SelectionCriteriaItem.propTypes = {
    index: PropTypes.number.isRequired,
    value: PropTypes.object.isRequired, //
    visu:  PropTypes.object.isRequired, //reference to Redux store
    clbToCriterion:PropTypes.func.isRequired, //callback to parent component to update list of criteria
    clbToRemoveCriterion:PropTypes.func.isRequired // callback to parent component to remove - needed for button here
};

export default SelectionCriteriaItem;



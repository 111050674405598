import React from 'react'
import { connect } from 'react-redux'
import { rolePick } from '../action/RoleAction.jsx'
import RolePicker from '../component/RolePicker'

const mapStateToProps = state => {
    return{
        auth : state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return{
        onRolePick : auth => {
            dispatch(rolePick(auth))
        }
    }
};


const ContainerRolePicker = connect(
    mapStateToProps,
    mapDispatchToProps)(RolePicker)

export default ContainerRolePicker
import {connect} from "react-redux";
import {LP_landing_page} from "../component/landingPage";
import {getPresentationList} from "../action/PresentationAction";

// Used by connect()
const mapStateToProps = state => {
    return {
        presentations: state.presentation.presentations.presentation_list
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getPresentations: () => {
            dispatch(getPresentationList())
        }
    }
};

const presentationContainerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerPresentations = presentationContainerCreator(LP_landing_page);

import React from 'react'
import {expDesignPagesEnum} from "../ExpDesign";
import {Button} from "primereact/components/button/Button";
import {MultiScatterPlotPanel} from "./d3components/MultiScatterPlotPanel";
import {
    EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID, EXPERIMENT_PROPERTY_NAME__COHORTS,
    EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID,
    EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES, EXPERIMENT_PROPERTY_NAME__UUID
} from "../../../Constants";
import {ContainerAuditScriptPanel, ContainerScriptPanel} from "../containers/ContainerScriptPanel";



/** Class for representing results of experiment
 */
export default class Audit extends React.Component {
	constructor() {
	  super();
        this.handleSubmitNext =   this.handleSubmitNext.bind(this);
        this.handleSubmitPrevious =   this.handleSubmitPrevious.bind(this);
	  };
    handleSubmitNext(){
        // this.props.setStep(expDesignPagesEnum.Statistical);
    }
    handleSubmitPrevious(){
        this.props.setStep(expDesignPagesEnum.Model);
    }

    componentDidMount () {
        //TODO Unmock me
        const {experiment,getDataset,dataSelection} =this.props;
        // const arrayOfVars = Object.keys(experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES])
        //     .map((s) => experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][s]['clinicalVariableId']['uuid']);
        if (dataSelection.datasetState!=='success')
        getDataset(experiment);
    }
	render() {
        const {dataSelection, experiment, variablesList,appState} = this.props;
        const dataset = dataSelection.dataset;
        const modelVariables = Object.keys(experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES]).map((el) => {
            return experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][el]
        });
        const datasetState = dataSelection.datasetState;
        const isUserModel = (experiment[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID] === "GLRUSER");

        const arrayOfVariableDescriptions = [];
        if (!isUserModel) {
            modelVariables.forEach((s, i) => {
                let descr = {};
                if (s["entityType"]==="SUBJECT")
                    descr = {name:s['clinicalVariableId']['name'] ,units: ' [' + s['clinicalVariableId']['units'] + ']'};
                else if(s["entityType"]==="ANATOMICAL")
                    descr ={name: s['metricAnnotation']['preferredName'] +" of "+ s['anatomicalAnnotation']['preferredName'], units:"[mm3]"};
                else if(s["entityType"]==="PATHOLOGICAL")
                    descr ={name: s['metricAnnotation']['preferredName'] +" of " +
                        s['pathologicalAnnotation']['preferredName'] + " in "+
                        s['anatomicalAnnotation']['preferredName'],units:"[mm3]"};
                arrayOfVariableDescriptions.push(descr);
            });
        }

		return(
		    <React.Fragment>
                {!isUserModel &&
			<div>
                { datasetState!=='success' &&
                <div> Loading dataset: <i className="fa fa-spinner fa-spin"/></div>
                }
                {modelVariables.length>=2 &&
                datasetState==='success' &&
                dataset.length>0 &&
                <MultiScatterPlotPanel
                                  data = {dataset}
                                  vars = {modelVariables}
                                  descriptions={arrayOfVariableDescriptions}
                                  calculateParameters = {this.props.calculateParameters}
                                  modelStatus = {appState.expDesign.audit.modelParametersState}
                                  modelParameters = {appState.expDesign.audit.modelParameters}
                />}
                {!(dataset.length>0) &&
                   datasetState==='success' &&
                <div>No dataset </div> }
                <div>
                    <Button  onClick={this.handleSubmitPrevious} label="Previous" id="previous" style={{float:'left'}}/>
                </div>
			</div>}

                {isUserModel &&

                    <div>
                        { datasetState!=='success' &&
                        <div> Loading dataset: <i className="fa fa-spinner fa-spin"/></div>
                        }
                        { datasetState==='success' &&
                        <div>
                            <ContainerAuditScriptPanel data = {dataset}  handleSubmitPrevious = {this.handleSubmitPrevious} />
                        </div>
                        }

                    </div>
                }
            </React.Fragment>
			)
	}


}

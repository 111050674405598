
import {
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS
} from './actionType.jsx';



//LOGOUT PROCESS **************************************************************

// function requestLogout() {
//   return {
//     type: LOGOUT_REQUEST,
//     isFetching: true,
//     isAuthenticated: true
//   }
// }

// function receiveLogout() {
//   return {
//     type: LOGOUT_SUCCESS,
//     isFetching: false,
//     isAuthenticated: false
//   }
// }

export function logout(auth){
    // console.log('Executing logging out. Credentials:', auth);
    return dispatch=>{
        dispatch(logoutUser(auth))
    }
}

function logoutUser(auth) {
    return {
        type: LOGOUT_SUCCESS,
        auth
    }
}
/*
 * Main action:
 * - Remove token
 * - dispatch to reducer
 * @param {Object} creds - email and password
 * @return {Object} type, creds or err
 *
 */
// export function logoutUser() {
//   return dispatch => {
//     dispatch(requestLogout());
//     localStorage.removeItem('user');
//     localStorage.removeItem('access_token');
//     dispatch(receiveLogout())
//   }
// }
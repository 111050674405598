import PropTypes from 'prop-types';
import React from "react";


export const TaskInputsOutputsTable = ({inputs})=> {

    let keys = Object.keys(inputs);


    let template =(el)=>{
        return   (
        <React.Fragment>
            <tr>
                <th colSpan="2">{el}</th>
            </tr>
            <tr>
                <th>Type</th>
                <td>{inputs[el].type}</td>
            </tr>
            <tr>
                <th>Format</th>
                <td>{inputs[el].fileType}</td>
            </tr>
            <tr>
                <th>Type of Image</th>
                <td>{inputs[el].typeImageFile}</td>
            </tr>
            <tr>
                <th>Input descriptor id</th>
                <td>{inputs[el].idInDescriptor}</td>
            </tr>
        </React.Fragment>)
    } ;

    let result = [];

    keys.forEach((el)=>{result.push (template(el))});

    return (
        <React.Fragment>
            {result}
        </React.Fragment>
       )

};
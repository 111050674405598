import store from "../../Store";

import axios from "axios/index";
import {CLINICAL_FEATURES} from "../../expDesign/component/MockData";
import {HOST_URL} from "../../../Constants";
import {
    ANATOMICAL_VARIABLES_FAIL,
    ANATOMICAL_VARIABLES_REQUEST, ANATOMICAL_VARIABLES_SUCCESS,
    CLINICAL_VARIABLES_FAIL, CLINICAL_VARIABLES_REQUEST, CLINICAL_VARIABLES_SUCCESS, MEASUREMENT_VARIABLES_SUCCESS,
    META_VARIABLES_REQUEST,
    META_VARIABLES_SUCCESS, OPERATORS_SUCCESS,
    PATHOLOGICAL_VARIABLES_FAIL,
    PATHOLOGICAL_VARIABLES_REQUEST, PATHOLOGICAL_VARIABLES_SUCCESS,
    VARIABLES_FAIL, VARIABLES_REQUEST,
    VARIABLES_SUCCESS
} from "./actionType";

/**
 * An action informing the reducers that the request began.
 * @param type - action type
 * @returns {{type}}
 */
const requestVariableList = (type) => ({
    type: type
});

/**
 * An action informing the reducers that the request failed.
 * @param err
 * @param type - action type
 * @returns {{type}}
 */
const errorVariableList = (type,err) => ({
    type: type,
    err
});

/**
 * An action informing the reducers that the request finished successfully.
 * @param variables
 * @param type - action type
 * @returns {{type, variablesList: *}}
 */

const successVariableList = (type,variables) => ({
    type: type,
    variables
});



const successMockVariableList = () => ({
    type: VARIABLES_SUCCESS,
    variablesList:CLINICAL_FEATURES
});


export const getVariableListActionCreator = () => {
    return dispatch => {
        dispatch(requestVariableList(VARIABLES_REQUEST));
        console.log('VariablesAction.js :: getVariableList :: request');
        let config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };
        return axios.get(HOST_URL + '/variable',config
        ).then(response => {
            console.log('VariablesAction.js :: getVariableList :: response',response);
            if (response.status !== 200) {
                dispatch(errorVariableList(VARIABLES_FAIL, response.data));
                return Promise.reject(response.data);
            } else {
                console.log('VariablesAction.js :: getVariableList :: success');
                dispatch(successVariableList(VARIABLES_SUCCESS, response.data));
            }
        })
            .catch(err => dispatch(errorVariableList(VARIABLES_FAIL, err)));
    };
};

export const getClinicalVariableListActionCreator = () => {
    return dispatch => {
        dispatch(requestVariableList(CLINICAL_VARIABLES_REQUEST));
        console.log('VariablesAction.js :: getClinicalVariableList :: request');
        let config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };
        //return axios.get(HOST_URL + ' /measurement/clinical',config    TODO uncomment when ready
        return axios.get(HOST_URL + '/clinicalVariable',config
        ).then(response => {
            console.log('VariablesAction.js :: getClinicalVariableList :: response',response);
            if (response.status !== 200) {
                dispatch(errorVariableList(CLINICAL_VARIABLES_FAIL, response.data));
                return Promise.reject(response.data);
            } else {
                console.log('VariablesAction.js :: getClinicalVariableList :: success');
                dispatch(successVariableList(CLINICAL_VARIABLES_SUCCESS, response.data));
            }
        })
            .catch(err => dispatch(errorVariableList(CLINICAL_VARIABLES_FAIL, err)));
    };
};

const MOCK_ANATOMY = [{
    "preferredName":"Brain",
    "ontologyId": "https://bioportal.bioontology.org/ontologies/FMA",
    "ontologyClassId": "http://purl.org/sig/ont/fma/fma83930"
},
    {
        "preferredName":"Corpus callosum",
        "ontologyId": "https://bioportal.bioontology.org/ontologies/FMA",
        "ontologyClassId": "http://purl.org/sig/ont/fma/fma86464"
    }
];

export const getAnatomicalVariableListActionCreator = () => {
    return dispatch => {
        dispatch(requestVariableList(ANATOMICAL_VARIABLES_REQUEST));
        console.log('VariablesAction.js :: getAnatomicalVariableList :: request');
        let config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };
        dispatch(successVariableList(ANATOMICAL_VARIABLES_SUCCESS, MOCK_ANATOMY));
    //TODO uncomment when ready
    //     return axios.get(HOST_URL + '/measurement/anatomical',config)
    //         .then(response => {
    //         console.log('VariablesAction.js :: getAnatomicalVariableList :: response',response);
    //         if (response.status !== 200) {
    //             dispatch(errorVariableList(ANATOMICAL_VARIABLES_FAIL, response.data));
    //             return Promise.reject(response.data);
    //         } else {
    //             console.log('VariablesAction.js :: getAnatomicalVariableList :: success');
    //             dispatch(successVariableList(ANATOMICAL_VARIABLES_SUCCESS, response.data));
    //         }
    //     })
    //         .catch(err => dispatch(errorVariableList(ANATOMICAL_VARIABLES_FAIL, err)));
    };
};


const MOCK_PATHOLOGY = [
    {
    "preferredName":"Lesion",
    "ontologyId": "https://bioportal.bioontology.org/ontologies/SNOMEDCT",
    "ontologyClassId": "http://purl.bioontology.org/ontology/SNOMEDCT/52988006"
}
];

// ,
// {
//     "preferredName":"Pseudolesion",
//     "ontologyId": "http://bioportal.bioontology.org/ontologies/RADLEX",
//     "ontologyClassId": "http://radlex.org/RID/RID34749"
// }

export const getPathologicalVariableListActionCreator = () => {
    return dispatch => {
        dispatch(requestVariableList(PATHOLOGICAL_VARIABLES_REQUEST));
        console.log('VariablesAction.js :: getPathologicalVariableList :: request');
        let config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };
        dispatch(successVariableList(PATHOLOGICAL_VARIABLES_SUCCESS, MOCK_PATHOLOGY));
        // return axios.get(HOST_URL + ' /measurement/pathological',config
        // ).then(response => {
        //     console.log('VariablesAction.js :: getPathologicalVariableList :: response',response);
        //     if (response.status !== 200) {
        //         dispatch(errorVariableList(PATHOLOGICAL_VARIABLES_FAIL, response.data));
        //         return Promise.reject(response.data);
        //     } else {
        //         console.log('VariablesAction.js :: getPathologicalVariableList :: success');
        //         dispatch(successVariableList(PATHOLOGICAL_VARIABLES_SUCCESS, response.data));
        //     }
        // })
        //     .catch(err => dispatch(errorVariableList(PATHOLOGICAL_VARIABLES_FAIL, err)));
    };
};


/** Mocked  action for
 *
 * @returns {function(*)}
 */
export const getMetaVariableListActionCreator = () => {
    return dispatch => {
        const mockedData = [{label:"Clinical",value:"SUBJECT"},
                            {value:"ANATOMICAL",label:"Anatomical"},
                            {value:"PATHOLOGICAL",label:"Pathological"}];
        dispatch(successVariableList(META_VARIABLES_SUCCESS,mockedData));
    }
};


/**
 * Mocked measurements TODO Unmock me!
 * @returns {function(*)}
 */

export const getMeasurementsListActionCreator = ()=>{
    return dispatch => {
        const mockedData = [
            {
            "preferredName":"Volume",
            "ontologyId": "http://bioportal.bioontology.org/ontologies/RADLEX",
            "ontologyClassId": "http://www.radlex.org/RID/RID28668"
        },
            {
                "preferredName":"Mean ADC",
                "ontologyId": "http://bioportal.bioontology.org/ontologies/RADLEX",
                "ontologyClassId": "http://radlex.org/RID/RID49526"
            }];
        dispatch(successVariableList(MEASUREMENT_VARIABLES_SUCCESS,mockedData));
    }


};

/**
 * Mocked operators TODO Unmock me!
 * @returns {function(*)}
 */

export const getVariableOperatorsActionCreator = ()=>{
    return dispatch => {
        const mockedData = [
            {
                "type":"ORDINAL_OPERATOR",
                "label":"<",
                "value": "less than"
            },
            {
                "type":"ORDINAL_OPERATOR",
                "label":"<=",
                "value": "less than or equal to"
            },
            {
                "type":"ORDINAL_OPERATOR",
                "label":"=",
                "value": "equals"
            },
            {
                "type":"ORDINAL_OPERATOR",
                "label":"!=",
                "value": "not equals"
            },
            {
                "type":"ORDINAL_OPERATOR",
                "label":">=",
                "value": "greater than or equal to"
            },
            {
                "type":"ORDINAL_OPERATOR",
                "label":">",
                "value": "greater than"
            },
            {
                "type":"TEXTUAL_OPERATOR",
                "label":"contains",
                "value": "contains"
            },
            {
                "type":"TEXTUAL_OPERATOR",
                "label":"does not contain",
                "value": "does not contain"
            },
            {
                "type":"LOGICAL_OPERATOR",
                "label":"AND",
                "value": "AND"
            },
            {
                "type":"LOGICAL_OPERATOR",
                "label":"OR",
                "value": "OR"
            },

            {
                "type":"CATEGORICAL_OPERATOR",
                "label":"=",
                "value": "equals"
            },
            {
                "type":"CATEGORICAL_OPERATOR",
                "label":"!=",
                "value": "not equals"
            }
        ];
        dispatch(successVariableList(OPERATORS_SUCCESS,mockedData));
    }


};

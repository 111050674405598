import React from 'react'
import PropTypes from 'prop-types'
import {Dropdown} from 'primereact/components/dropdown/Dropdown'

class RolePicker extends React.Component {

    // props are not passed

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleLayout = this.handleLayout.bind(this);
    }


    handleLayout(event) {
        this.op.toggle(event);
    }

    handleChange(e) {
        this.props.auth.role_active=e.value;
        this.props.onRolePick(this.props.auth);
    }



    /**Adapter function for Prime React Dropdown component
     *
     *  @param item role to convert
     * @returns {Object} object with required attributes
     */
    static adaptSelectableOption(item) {
        let option = new Object();
        option.label = item.charAt(0).toUpperCase() + item.slice(1);
        option.value = item;
        return option;
    }


    render() {
        console.log("RolePicker::render");
        const {auth} = this.props;
        //const  = this.props.auth;
        let roles = auth.roles.map(item => RolePicker.adaptSelectableOption(item));

        return (

                <Dropdown
                    options={roles}
                    value={auth.role_active}
                    onChange={this.handleChange}
                    disabled={roles.length<2}
                    style={{float:'right',marginTop:'16px'}}
                />

        )
    }
}

RolePicker.propTypes = {
    auth: PropTypes.object.isRequired,
    onRolePick: PropTypes.func.isRequired,
};
export default RolePicker
import React from "react";
import {Button} from "primereact/components/button/Button";
import {
    EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID,
    EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES, EXPERIMENT_PROPERTY_NAME__TIMEFRAME,
    VARIABLE_PROPERTIES_NAMES
} from "../../../Constants";
import GanttChart from "./d3components/GanttChart";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {AbsoluteTimeFrameCriterion} from "./AbsoluteTimeframeCriterion";
import {RelativeTimeFrameCriterion} from "./RelativeTimeframeCriterion";
import {getUniqueId} from "../../helpers/strings";


const dataset1 = {
    'primary-time-frame': {value: 10, unit: 'years', plus: 1, minus: 1},
    BLCVA: {
        value: 0, unit: 'months', plus: 4, minus: 4,
        relative_measures: [
            {'measure': 'First Attack Date1', value: '20', unit: 'days', 'plus': 1, minus: 1},
            {'measure': 'First Attack Date2', value: '10', unit: 'months', 'plus': 4, minus: 4},
            {'measure': 'First Attack Date3', value: '5', unit: 'months', 'plus': 6, minus: 6},
            {'measure': 'First Attack Date4', value: '3', unit: 'years', 'plus': 2, minus: 2},
        ]
    },
    EDSS: {value: 9, unit: 'years', plus: 1, minus: 1},
    // EDSS2: {value: 5, unit: 'months', plus: 2, minus: 2},
    // EDSS3: {value: 7, unit: 'years', plus: 3, minus: 3}
};

const EMPTY_ABSOLUTE_TIMEFRAME = {
    "targetDate": {"distance": 0, "units": "YEAR"  },
    "toleranceWindow": {"leftLimit": 0,"rightLimit": 0 ,  "leftUnits":"YEAR", "rightUnits":"YEAR"},
};


const EMPTY_RELATIVE_TIMEFRAME =(relativeFrom,relativeTo,uuid)=> {
    return {
        "toleranceWindow": {"leftLimit": 0, "rightLimit": 0, "leftUnits": "YEAR", "rightUnits": "YEAR"},
        "variableRelativeFrom":relativeFrom['symbol'],
        "variableRelativeTo": relativeTo['symbol'],
        "relationType": "CAN_BE",
        "uuid":uuid
}
};

const NONE = "NONE";

const optionsRef = [{label:"None",value:NONE},
    {label:"Date Of Birth",value:"DOB"},
    {label:"First Attack Date",value:"First Attack"}
    ];
const PROPERTY_ABSOLUTE_CONSTRAINTS ='absoluteConstraints';
const PROPERTY_RELATIVE_CONSTRAINTS ='relativeConstraints';



/**
 * Dumb component with template for displaying Betas table.
 * @returns {*}
 */
export const TimeFrameSettingPanel = ({variablesList, experiment, timeframe, onClose, updateTimeFrame, updateExperiment}) => {

    const variables = experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES];
        // .map((s) => {
        //     return variablesList.find((el) => el.uuid === s[EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID]);
        // }).filter((s) => {
        //     return s != null
        // });

    const cleanAbsoluteTimeFrames=()=>{
        timeframe[PROPERTY_ABSOLUTE_CONSTRAINTS]={};
    };
    const initAbsoluteTimeFrames=()=>{

        Object.keys(variables).forEach((el)=>{
            timeframe[PROPERTY_ABSOLUTE_CONSTRAINTS][el] = Object.assign({},EMPTY_ABSOLUTE_TIMEFRAME);
        });
    };


    const onChangePrimaryTimeFrame = (value)=>{
        if (value===NONE){
            timeframe['primary-time-frame'] = NONE;
            cleanAbsoluteTimeFrames();
            updateTimeFrame(timeframe);
        }
        else
            if (timeframe['primary-time-frame']!==NONE && value!==NONE){
                timeframe['primary-time-frame'] = value;
                updateTimeFrame(timeframe);
        }
        else
            if (timeframe['primary-time-frame']===NONE && value!==NONE){
                timeframe['primary-time-frame'] = value;
                initAbsoluteTimeFrames();
                updateTimeFrame(timeframe);
            }
    };

    const onChangeAbsoluteCriterion = (variable, item)=>{
        timeframe[PROPERTY_ABSOLUTE_CONSTRAINTS][variable] = item;
        updateTimeFrame(timeframe);
    };

    const onChangeRelativeCriterion = (index,item)=>{
        timeframe[PROPERTY_RELATIVE_CONSTRAINTS][index] = item;
        updateTimeFrame(timeframe);
    };

    //Adding always relation between first and second variable
    const addRelative = () => {
        if (Object.keys(variables).length > 1) {
                timeframe[PROPERTY_RELATIVE_CONSTRAINTS]
                    .push(EMPTY_RELATIVE_TIMEFRAME(variables[Object.keys(variables)[0]],variables[Object.keys(variables)[1]],getUniqueId()));
                updateTimeFrame(timeframe);

        }

    };
    const onRemoveRelativeCriterion = (index) => {
        let tfCopy = timeframe[PROPERTY_RELATIVE_CONSTRAINTS].slice();
        tfCopy.splice(index, 1);
        timeframe[PROPERTY_RELATIVE_CONSTRAINTS] = tfCopy;
        updateTimeFrame(timeframe);
    };

    let initTimeFrame=() =>{
        let tf = {
            'primary-time-frame': NONE,
            'absoluteConstraints':{},
            'relativeConstraints':[]
        };
       Object.keys(variables).forEach((el)=>{
            tf['absoluteConstraints'][el] = {};
        });
        updateTimeFrame(tf);
        return tf;
    };

    let data = (experiment[EXPERIMENT_PROPERTY_NAME__TIMEFRAME] != null) ? experiment[EXPERIMENT_PROPERTY_NAME__TIMEFRAME] : initTimeFrame();

    const conditionalPropsToRelative = {
        className: (Object.keys(experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES]).length < 2) ? "ui-state-disabled" : "",
        title: (Object.keys(experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES]).length < 2) ? "You don't have enough variables in model" : "Add relative parameter"
    };

    const getGanttData = ()=>{
        let dataset = {
            'primary-time-frame': {value: 30, unit: 'years', plus: 10, minus: 10}
        };

        Object.keys(data[PROPERTY_ABSOLUTE_CONSTRAINTS]).reverse()
            .forEach((el)=>{
                if (el==='primary-time-frame') return;
                let value = data['absoluteConstraints'][el]['targetDate']['distance'];
                let unit = 'years';
                let plus =  data['absoluteConstraints'][el]['toleranceWindow']['rightLimit'];
                let minus = data['absoluteConstraints'][el]['toleranceWindow']['leftLimit'];
                dataset[el] ={value:value,plus:plus,minus:minus,unit:unit};
        });

        // data[PROPERTY_RELATIVE_CONSTRAINTS]
        //     .forEach((el,index)=>{
        //         let newItem = {'measure': '', value: '20', unit: 'days', 'plus': 1, minus: 1};
        //
        //
        //         // let value = data['relativeConstraints'][el]['targetDate']['distance'];
        //         // let unit =  data['relativeConstraints'][el]['targetDate']['units'].toLowerCase();
        //         // let plus =  data['relativeConstraints'][el]['toleranceWindow']['rightLimit'];
        //         // let minus = data['relativeConstraints'][el]['toleranceWindow']['leftLimit'];
        //
        //         console.log(el);
        //     });


        return dataset;
    };


    return (
        <React.Fragment>
            <div className="ui-g-12">
                <Button label="Close" icon="fa fa-close" onClick={onClose}/>
                <Dropdown options={optionsRef}
                          placeholder="Select a Time Reference"
                          value={data['primary-time-frame']}
                          onChange={(e)=>{ onChangePrimaryTimeFrame(e.value)}}
                          style={{width:'200px'}}
                          title="Time reference"
                />
            </div>
            { timeframe!=null && timeframe.hasOwnProperty('primary-time-frame') && timeframe['primary-time-frame']!==NONE &&
            <React.Fragment>
                <div className="ui-g-12 criteriaPanel" style={{overflowY: 'auto'}}>
                    <div className="ui-g-7" style={{marginBottom: "1em",    paddingTop: "25px",  paddingBottom: "75px"}}>
                        <div className="ui-g-3 ">
                            Variable
                        </div>
                        <div className="ui-g-3 ">
                            Lower Tolerance
                        </div>
                        <div className="ui-g-3 ">
                            Measurement Target Time
                        </div>
                        <div className="ui-g-3 ">
                            Upper Tolerance
                        </div>
                        {Object.keys(data[PROPERTY_ABSOLUTE_CONSTRAINTS])
                                .map((variable, index) => {
                                    let absCrit = (data[PROPERTY_ABSOLUTE_CONSTRAINTS][variable]!=null)?
                                    data[PROPERTY_ABSOLUTE_CONSTRAINTS][variable]:null;
                                    if (absCrit!=null)
                                        return <AbsoluteTimeFrameCriterion
                                                                   key = {variable+'_absolute_'+index}
                                                                   index={index}
                                                                   variable={variable}
                                                                   item={absCrit}
                                                                   onChangeCriterion={onChangeAbsoluteCriterion}
                                                                   />;
                                    else return null;
                                })}
                    </div>
                    <div className="ui-g-5">
                        <div className="ui-g-12">
                            <div className="ui-g-12 tfPlotPanelPlaceholder">
                                <GanttChart dataset={getGanttData()} />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>}
            <div className="ui-g-12 criteriaPanel">
                {data[PROPERTY_RELATIVE_CONSTRAINTS]
                    .map((item, index) => {
                        if (item!=null)
                            return <RelativeTimeFrameCriterion
                                key = {item.uuid}
                                variables = {variables}
                                index={index}
                                item={item}
                                onRemoveCriterion={onRemoveRelativeCriterion}
                                onChangeCriterion={onChangeRelativeCriterion}
                            />;
                        else return null;
                    })}
                <Button label="Relative criterion" icon="fa fa-plus"
                        onClick={() => addRelative()}
                        {...conditionalPropsToRelative}
                />
            </div>
        </React.Fragment>
    );
};
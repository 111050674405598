import React from "react";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Spinner} from "primereact/components/spinner/Spinner";
import PropTypes from 'prop-types';


/**
 * Dumb component with template for Eraser Tool.
 * @returns {*}
 */
export const EraserToolbar = ({toolProperties, changeToolProperties}) => {
    let changeProperties = (arg, value) => {
        let pp = Object.assign({}, toolProperties);
        pp[arg] = value;
        changeToolProperties(pp);
    };

    return (
        <Toolbar style={{display: "inline-block"}}>
            <label style={{marginLeft: "1em"}}>Eraser Size:</label>
            <Spinner value={toolProperties.size}
                     min={0} max={100} step={1} size={3}
                     onChange={(e) => changeProperties('size', e.value)}
            />
        </Toolbar>
    );
};

EraserToolbar.propTypes = {
    toolProperties: PropTypes.object.isRequired,
    changeToolProperties: PropTypes.func.isRequired
};

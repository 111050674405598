import React from 'react'
import { connect } from 'react-redux'
import { login,register } from '../action/LoginAction.jsx'
import Login from '../component/Login'

const mapStateToProps = state => {
  return{
  	loginStatus : state.auth.login_status
  }
};

const mapDispatchToProps = dispatch => ({

  	onClickAde : creds => {
  	  dispatch(login(creds))
  	} ,
    onRegister : () => {
        dispatch(register())
    }

});

const ContainerLogin = connect(
 mapStateToProps,
 mapDispatchToProps)(Login);

export default ContainerLogin
import React from 'react'
import {Button} from 'primereact/components/button/Button';
import {Column} from 'primereact/components/column/Column';
import {DataTable} from 'primereact/components/datatable/DataTable';
import UserSelection from './UserSelection'
import {expDesignPagesEnum} from "../ExpDesign";
import {VariableDescription} from "./VariableDescription";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {
    ContainerMultiValuedVariableStatistics,
    ContainerNumericVariableStatistics
} from "../containers/ContainerVariableStatistics";
import {
    EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__SYMBOL,
    EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID, EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__WORKFLOW_ID,
    EXPERIMENT_PROPERTY_NAME__COHORTS, EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID,
    EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES, EXPERIMENT_PROPERTY_NAME__UUID,
    REQUEST_STATUS_FAIL,
    REQUEST_STATUS_REQUESTED,
    REQUEST_STATUS_SUCCESS, VARIABLE_PROPERTIES_NAMES, VARIABLE_PROPERTY_VALUE_META_TYPE__IMAGING,
    VARIABLE_PROPERTY_VALUE_VARIABLE_TYPE
} from "../../../Constants";
import {ContainerWorkflowDialog} from "../containers/ContainerWorkflowDialog";
import {Dialog} from "primereact/components/dialog/Dialog";
import ContainerTimeFrameSettingPanel from "../containers/ContainerTimeFrameSettingPanel";
import {InputText} from "primereact/components/inputtext/InputText";
import {MOCK_LINK_TO_COHORT} from "../../visualization/tests/Mocks";
import SingleChart from "./d3components/SingleChart";

/** Variables table with workflow buttons.
 *
 */
export  default  class WorkflowSelection extends React.Component {
    constructor() {
        super();
        this.state={
            workflowVisible:false,//show sidebar?
            variable:{},//variable presented in sidebar
            variableIndex:0, //index of variable presented in sidebar
            timeFrameVisible:false
        };
        ["hideWorkflowSelectDialog","revealWorkflowSelectDialog","columnROI",
        "rowExpansionTemplate","showSymbol","measurementColumn",
         "columnWorkflow","columnTimeframe","columnMetaType","onVariableInstanceChange"
        ].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }


    onVariableInstanceChange(symbol,property,value){
        let state = this.props.experiment;
        if (property==='entityType'){
            state[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][symbol] = {};
            state[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][symbol][property] = value;
            state[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][symbol]['symbol'] = symbol;
        } else
        if (property==='pathologicalAnnotation'){
            state[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][symbol]['anatomicalAnnotation'] = value.value['ana'];
            state[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][symbol]['pathologicalAnnotation'] = value.value['pat'];
        }
        else
            state[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][symbol][property] = value;
        this.props.updateVariables(state);
    }

    hideWorkflowSelectDialog(){
        this.setState({
            workflowVisible: false
        });
    }
    /**
     * Method triggering getting workflows (for a given variable)
     * and displaying of workflows.
     * */
    revealWorkflowSelectDialog(variable,index){
        this.props.getWorkflows(variable);
        this.setState({
            workflowVisible: true,
            variable:variable,
            variableIndex:index
        });
    }


     showSymbol(rowData){
        const{experiment,modelsList} = this.props;
        const model = modelsList.find((el)=>{return el.uuid===experiment[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID]});

         // if (model['spineType']!=="USER_DEFINED"){
             const symbol = rowData.symbol.charAt(0).toUpperCase();
             const ind = rowData.symbol.slice(1);
             return <p>{symbol}<sub>{ind}</sub></p>
         // }
         // else {
         //     return <InputText
         //         value={rowData['symbol']}
         //         style={{width:"2em"}}
         //         onChange={(e)=>this.onVariableInstanceChange(rowData['symbol'],'symbol',e.target.value )}/>
         // };
    }

    columnMetaType(data,column){
        const {variablesList} = this.props;
        if (variablesList!=null && variablesList.metaVariablesState===REQUEST_STATUS_SUCCESS)
        return  (<div>
            <Dropdown
                filter={true}
                showClear={true}
                style={{float:'left', border:'none'}}
                value={data['entityType']}
                options={variablesList.metaVariables}
                onChange={(e)=>this.onVariableInstanceChange(data['symbol'],'entityType',e.value )}
                placeholder="Select a Variable"/>
        </div>);
                else return null;
    }
    measurementColumn(data,column){
        const {variablesList} = this.props;

        let options = [];
        let disabled = true;
        let propertyName=null;
        if (data !=null && data['entityType']!=null && data['entityType']!=="") {
            if (data['entityType'] === 'SUBJECT' && variablesList.clinicalVariablesState === REQUEST_STATUS_SUCCESS) {
                options = variablesList.clinicalVariables;
                disabled = false;
                propertyName = "clinicalVariableId";
                return <div>
                    <Dropdown
                        filter={true}
                        showClear={true}
                        style={{width: '20em',float:'left', border:'none'}}
                        disabled={disabled}
                        value={data[propertyName]}
                        options={options}
                        optionLabel="name"
                        onChange={(e)=>this.onVariableInstanceChange(data['symbol'],propertyName,e.value)}
                        placeholder="Select a Measurement"/>
                </div>
            }
            if (data['entityType'] !== 'SUBJECT' && variablesList.measurementVariablesState === REQUEST_STATUS_SUCCESS) {
                options =  variablesList.measurementVariables;
                disabled = false;
                propertyName = "metricAnnotation";
                return <div>
                    <Dropdown
                        filter={true}
                        showClear={true}
                        style={{width: '20em',float:'left', border:'none'}}
                        disabled={disabled}
                        value={data[propertyName]}
                        options={options}
                        optionLabel="preferredName"
                        onChange={(e)=>this.onVariableInstanceChange(data['symbol'],propertyName,e.value)}
                        placeholder="Select a Measurement"/>
                    {data[propertyName] !=null && data[propertyName]['ontologyClassId'] !=null &&
                    <a target="_blank" href={data[propertyName]['ontologyClassId']}>
                        <i className="fa fa-external-link" title="External link to definition"/>
                    </a>}
                </div> ;

            }
            else return <div/>;

        }
      }
    columnROI(data,column){
        const {variablesList} = this.props;
        if (data['entityType'] === 'SUBJECT')
            return  <div> Subject </div>;
            else {
            let options = [];//variablesList.pathologicalVariables;
            let propertyName = "";//'pathologicalAnnotation';
            if (data['entityType'] === 'ANATOMICAL'){
                options = variablesList['anatomicalVariables'];
                propertyName = 'anatomicalAnnotation';

            return <div>
                <Dropdown
                    filter={true}
                    showClear={true}
                    style={{width: '20em', float: 'left', border: 'none'}}
                    value={data[propertyName]}
                    options={options}
                    optionLabel="preferredName"
                    onChange={(e) => this.onVariableInstanceChange(data['symbol'],propertyName,e.value)}
                    placeholder="Select a Region"/>
                {data[propertyName] !=null && data[propertyName]['ontologyClassId'] !=null &&
                <a target="_blank" href={data[propertyName]['ontologyClassId']}>
                     <i className="fa fa-external-link" title="External link to definition"/>
                </a>}
            </div>;
                    }
            if (data['entityType'] === 'PATHOLOGICAL'){ //if pathological then combine both, eg. Lesion of Brain
                options=[];
                variablesList['pathologicalVariables'].forEach((pat)=>{
                    variablesList['anatomicalVariables'].forEach((ana)=>{
                        options.push({preferredName:pat.preferredName + ' in '+ ana.preferredName,value:{pat,ana}});
                    })
                });
                let pat = data['pathologicalAnnotation'];
                let ana = data['anatomicalAnnotation'];

                let val = (!pat||!ana)?null:{
                    preferredName:data['pathologicalAnnotation'].preferredName + ' in ' + data['anatomicalAnnotation'].preferredName,
                    value:{pat,ana}
                };
                propertyName = 'pathologicalAnnotation';
                return <div>
                    <Dropdown
                        filter={true}
                        showClear={true}
                        style={{width: '20em', float: 'left', border: 'none'}}
                        value={val}
                        options={options}
                        optionLabel="preferredName"
                        onChange={(e) => this.onVariableInstanceChange(data['symbol'],propertyName,e.value)}
                        placeholder="Select a Region"/>
                    {data[propertyName] !=null && data[propertyName]['ontologyClassId'] !=null &&
                    <a target="_blank" href={data[propertyName]['ontologyClassId']}>
                        <i className="fa fa-external-link" title="External link to definition"/>
                    </a>}
                </div>;
            }


            }

    }


    columnWorkflow(data,column){
        const {variablesList} = this.props;

        let propertyName = (data['entityType'] === 'ANATOMICAL')?'anatomicalAnnotation':'pathologicalAnnotation';
        let variablesListProperty = (data['entityType'] === 'ANATOMICAL')?'anatomicalVariables':'pathologicalVariables';
        if (data['entityType'] === 'ANATOMICAL' || data['entityType'] === 'PATHOLOGICAL') {

            if(data['metricAnnotation']!=null && data['metricAnnotation']['preferredName']!=null) {
                const isAlreadySet = data[EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__WORKFLOW_ID] != null;
                const currentVariable = variablesList[variablesListProperty];//.find((el) => el.uuid === data[EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID]);
                const iconColor = (isAlreadySet) ? {color: 'green', fontSize: 'large'} : {
                    color: 'red',
                    fontSize: 'large'
                };
                const title = (isAlreadySet) ? "The workflow has been configured." : "Workflow configuration is required.";

                return <div>
                    <i className="fa fa-cogs" style={iconColor} title={title}
                       onClick={() => this.revealWorkflowSelectDialog(data, data['symbol'])}/>
                </div>;
            }
            else
                return <div>
                    <i className="fa fa-cogs" style={{color: 'grey'}}  title="You need to select measurement first." />
                </div>;
        }
        // if not yet returned than its empty workflow icon
        return <div>
            <i className="fa fa-cogs"
               title="Workflow configuration is not required."
               style={{color:'grey',fontSize:'large'}}/>;
        </div>;
    }


    columnTimeframe(dataRow){
        const {experiment} = this.props;

        try {
            if (experiment["timeFrame"]["absoluteConstraints"].hasOwnProperty(dataRow["symbol"])){

                // let mthsToDays = (mth)=>{return mth*30};
                // let yrsToDays = (days)=>{return years*365};
                // let calcYears = (value,unit)=>{
                //     if (unit==="DAY")
                //         return yrsToDays(value);
                //     else if (unit==="MONTH")
                //         return mthsToDays(value);
                //     else return value;
                // };
                const pTFLabels = new Map();
                pTFLabels.set("DOB","Date Of Birth");
                pTFLabels.set("First Attack","First Attack Date");

                const unitLabels = new Map();
                unitLabels.set("DAY","[days]");
                unitLabels.set("MONTH","[months]");
                unitLabels.set("YEAR","[years]");

                const plusUnit = experiment["timeFrame"]["absoluteConstraints"][dataRow["symbol"]]["toleranceWindow"]["rightUnits"];
                let plus = experiment["timeFrame"]["absoluteConstraints"][dataRow["symbol"]]["toleranceWindow"]["rightLimit"];
                const  minusUnit = experiment["timeFrame"]["absoluteConstraints"][dataRow["symbol"]]["toleranceWindow"]["leftUnits"];
                let minus = experiment["timeFrame"]["absoluteConstraints"][dataRow["symbol"]]["toleranceWindow"]["leftLimit"];
                const targetUnit = experiment["timeFrame"]["absoluteConstraints"][dataRow["symbol"]]["targetDate"]["units"];
                let target = experiment["timeFrame"]["absoluteConstraints"][dataRow["symbol"]]["targetDate"]["distance"];

                const label = pTFLabels.get(experiment["timeFrame"]["primary-time-frame"]);
                const unitLab =   unitLabels.get(targetUnit);


           let dataset2 =  {'primary-time-frame':
                    {   value:target,
                        unit:'years',
                        plus:plus+1,
                        minus:minus+1
                    },
                       EDSS:{
                           value:target,
                           unit: 'years',
                           plus: plus,
                           minus:minus
                           }
           };
            return <div onClick={()=>this.setState({timeFrameVisible:true})}>
                <div style={{position:"relative"}}>{label}{unitLab}</div>
                   <SingleChart dataset={dataset2}/>
            </div>;}
        }catch(err){}

        return <div>
                <i className="fa fa-clock-o" title="Time frame"  style={{color:'grey',fontSize:'large'}}
                   onClick={()=>this.setState({timeFrameVisible:true})}/>
            </div>


    }




    rowExpansionTemplate(dataRow,column) {
        const {variablesList,dataSelection,experiment,getDataset} = this.props;
        const variableId= (dataRow['clinicalVariableId']!=null)?dataRow['clinicalVariableId']['uuid']:null;
        const data = variablesList.clinicalVariables.find((el)=>el.uuid === variableId);

        if (!data) return null;
        return  <div className="ui-g ui-fluid">
            <div className="ui-g-12 ui-md-3">
           <VariableDescription metaType={data.metaType}
                                description={data.description}
                                variableType={data.variableType}
                                statisticalType={data.statisticalType}
                                units={data.units}
                                minValue={data.minValue}
                                maxValue={data.maxValue}
                                options={data.options}
                                format={data.format}
                                measurement={data.measurement}
                                roi={data.roi} />
            </div>
            <div className="ui-g-12 ui-md-9" style={{textAlign:'center', borderRight: '1px solid #cccccc'}}>
                {dataSelection.datasetState === REQUEST_STATUS_SUCCESS
                && dataSelection.dataset.length>0 &&
                 data.variableType=== VARIABLE_PROPERTY_VALUE_VARIABLE_TYPE.NUMBER &&
                <ContainerNumericVariableStatistics variable={data} symbol={dataRow['symbol']}/>
                }
                {dataSelection.datasetState === REQUEST_STATUS_SUCCESS
                && dataSelection.dataset.length>0 &&
                data.variableType===VARIABLE_PROPERTY_VALUE_VARIABLE_TYPE.MULTIPLE_CHOICE &&
                <ContainerMultiValuedVariableStatistics variable={data} symbol={dataRow['symbol']}/>
                }

                {dataSelection.datasetState === REQUEST_STATUS_SUCCESS
                && !(dataSelection.dataset.length>0) &&
                <div>
                    No data for selected set of variables
                </div>
                }

                {dataSelection.datasetState !== REQUEST_STATUS_SUCCESS
                && dataSelection.datasetState!==REQUEST_STATUS_REQUESTED
                &&
                    <div style={{height: '100%', display: 'grid'}}>
                        <Button label="Calculate statistics"
                                style={{margin:'auto', width:'15em'}}
                                onClick={() =>{
                            // const arrayOfVars = this.props.experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES]
                            //     .map((s)=>s[EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID]);
                           getDataset(experiment)}}/>
                    </div>
                }
                {dataSelection.datasetState === REQUEST_STATUS_REQUESTED
                 &&
                <div> Loading data <i className="fa fa-spinner fa-spin"/></div>
                }
                {dataSelection.datasetState=== REQUEST_STATUS_FAIL
                &&
                <div> The data are not available now. The administrators will take care of it. Please try again later.
                    <div style={{height: '100%', display: 'grid'}}>
                        <Button label="Calculate statistics"
                                style={{margin:'auto', width:'15em'}}
                                onClick={() =>{
                                    // const arrayOfVars = experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES]
                                    //     .map((s)=>s[EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID]);
                                    getDataset(experiment)}}/>
                    </div>
                </div>
                }

            </div>
        </div>;
    }



    render(){
        const {experiment} = this.props;
        //Getting variables from list nad binding them with keys in

        let vars = Object.keys(experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES])
            .map((el)=>{return experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][el]});

        return(
            <div>
                <DataTable value={vars} editable={true}
                           expandedRows={this.state.expandedRows}
                           onRowToggle={(e) => this.setState({expandedRows: e.data})}
                           rowExpansionTemplate={this.rowExpansionTemplate}
                           // rowGroupMode="rowspan"
                           // groupField="statisticalPower"
                           // sortField="statisticalPower"
                           // sortOrder={1}
                >
                    <Column body={this.showSymbol} style={{width: '4em'}} />
                    <Column body={this.columnMetaType}    />
                    <Column body={this.measurementColumn} />
                    <Column body={this.columnROI} />
                    <Column body={this.columnTimeframe}/>
                    <Column body={this.columnWorkflow} style={{width: '4em'}}/>
                    <Column expander={true} style={{width: '3em'}} visible={false}  />
                </DataTable>
                <ContainerWorkflowDialog
                                visible={this.state.workflowVisible}
                                hideMe={this.hideWorkflowSelectDialog}
                                variableIndex={this.state.variableIndex}
                                variable={this.state.variable}
                />
                <Dialog header={'Time Constraints'}
                        width="80%"
                        height="600px"
                        resizable={false}
                        visible={this.state.timeFrameVisible}
                        modal={true}
                        onHide={() => {
                            this.setState({timeFrameVisible: false})
                        }}>
                    {this.state.timeFrameVisible &&
                    <ContainerTimeFrameSettingPanel
                        onClose={() => {
                            this.setState({timeFrameVisible: false})
                        }}
                    />
                    }
                </Dialog>
            </div>
        )
    }
}




// Import (from) libraries
import React from 'react'
import 'font-awesome/css/font-awesome.css';
import PropTypes from 'prop-types'
import {Redirect} from 'react-router-dom'
import YouTube from 'react-youtube'
// Import components/containers/classes
import LayoutTopbar from './component/LayoutTopbar.js'
import ContainerRegister from './containers/ContainerRegister.js'
import Footer from "../root/Footer"
import {ContainerCurrentProjectsList} from "../project/containers/ContainerProjectList"
import {HowTab} from "./component/HowTab"
import {WhoTab} from "./component/WhoTab"
import {WhyTab} from "./component/WhyTab"



class App extends React.Component {

    constructor() {
        super();
        this.state = {
            activeTab:0
        };
        this.handleTab = this.handleTab.bind(this);
    }

    handleTab(page,e){
        this.setState({activeTab:page});
    }


    render() {
        const playerOpts = {
            playerVars: { // https://developers.google.com/youtube/player_parameters
                rel:0,
                autoplay: 0
            }
        };
        const activeTab = this.state.activeTab;
        let slickTabStyle = function (index) {
            if (activeTab === index)
                return {
                    fontWeight: 'bold',
                    width: '25%',
                    padding: '5px 5px 1px 10px',
                    borderTop: '1px solid #707070',
                    borderBottom: 'none',
                    borderRight: '1px solid #707070'
                };
            else
                return {
                    width: '25%',
                    padding: '5px 5px 1px 10px',
                    borderTop: '1px solid #707070',
                    borderBottom: '1px solid #707070',
                    borderRight: '1px solid #707070'
                };
        };

        const {loginStatus} = this.props;
        console.log("App:: this.propsAA:", this.props);

        if (loginStatus == 'logged') {
            return <Redirect to='/home'/>
        }

        return (
            <div>
                <div>
                    <LayoutTopbar/>
                </div>
                <div style={{
                    margin: 'auto',
                    marginTop: '20px',
                    marginBottom: '20px',
                    width: '60%',
                    textAlign: 'center',
                    fontSize: '1.8em',
                    fontWeight: 'bold',
                    color: '#707070'
                }}>
                    A virtual laboratory designed to accelerate
                    scientific discovery
                    and stimulate collaboration, while educating and actively involving curious minds.
                </div>

                <div className="ui-g-12 ui-g-nopad">
                    <div id="entry_slider" style={{width: '60%', float: 'left', margin: 'auto'}}>

                        <div style={{
                            width: '640px', height: '80%', margin: 'auto', borderLeft: '1px solid #707070',
                            borderBottom: '1px solid #707070', borderRight: '1px solid #707070'
                        }}>
                            <div className="reg-headtitle" style={{display: 'flex', marginTop: '10px'}}>
                                <span onClick={this.handleTab.bind(this, 0)} style={slickTabStyle(0)}>News </span>
                                <span onClick={this.handleTab.bind(this, 1)} style={slickTabStyle(1)}>Why </span>
                                <span onClick={this.handleTab.bind(this, 2)} style={slickTabStyle(2)}>How </span>
                                <span onClick={this.handleTab.bind(this, 3)} style={slickTabStyle(3)}>Who </span>
                            </div>
                            <div style={{padding: '10px', width: 'calc(100% - 20px)', height:'250px'}}>
                            {this.state.activeTab===0 && <ContainerCurrentProjectsList/>}
                            {this.state.activeTab===1 && <WhyTab/>}
                            {this.state.activeTab===2 && <HowTab/>}
                            {this.state.activeTab===3 && <WhoTab/>}
                            </div>


                        </div>
                    </div>
                    <div id="entry_register" style={{width: '40%', float: 'left', margin: 'auto'}}>
                        <div style={{margin: 'auto', width: '80%', marginTop: "10px"}}>
                            <ContainerRegister/>
                        </div>
                    </div>
                    <div id="entry_video" style={{width: '60%'}}>
                        <div style={{margin: 'auto', width: '80%', textAlign: 'center'}}>
                            <div className="reg-headtitle"
                                 style={{textAlign: 'left', margin: 'auto', width: '640px', marginBottom: '10px'}}>
                                SPINE Tablet Application
                            </div>
                            <YouTube
                                videoId="a7qNsmHc3sU" opts={playerOpts}/>
                        </div>
                    </div>
                    <div id="entry_quick_access" style={{width: '40%', float: 'left', margin: 'auto'}}>

                    </div>
                </div>
                <div className="ui-g-12">
                    <Footer/>
                </div>
            </div>
        );
    }

}

App.propTypes = {
    loginStatus: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
};

export default App;
import axios from 'axios';

import {login} from './LoginAction.jsx'

import {
	REGISTER_REQUEST,
	REGISTER_SUCCESS
} from './actionType.jsx';
import {HOST_URL} from "../../../Constants";

//REGISTER PROCESS ************************************************************

function requestRegister(creds) {
  return {
    type: REGISTER_REQUEST,
    isFetching: true,
    isAuthenticated: false,
    creds
  }
}

function receiveRegister(user) {
  return {
    type: REGISTER_SUCCESS,
    isFetching: false,
    isAuthenticated: true
  }
}

function errorRegister(message) {
  return {
    type: REGISTER_FAIL,
    isFetching: false,
    isAuthenticated: false,
    message
  }
}


/**
 * Dispatch to reducer the request and it's output
 * 
 * @param creds {Object}
 * @return error or success {Object}
 */
export function registerUser(creds) {
  console.log('Executing registerUser. Creds:', creds)
	return dispatch => {
		// Dispatch action request (if the process takes time we can
    // display the rolling ball animation)
		dispatch (requestRegister(creds))
    // Request to the backend
		return axios.post(HOST_URL+'/user', creds)
		.then(response => {
	  	//Check status code
	  	console.log('response ', response)
	  	if (response.status != 200) {
	  		//Display error message if creds are invalid 
	  		dispatch(errorRegister(response.data))
	  		return Promise.reject(response.data)
	  	} else {
	  		//Store the token locally
	  		console.log('create user ', response.data)
        console.log('RegisterAction:: response', response)
        // Dispath action of registration received
        dispatch(receiveRegister(creds))
        // Login the user
        const { email, password } = creds
        dispatch( login({ email, password }) )		
	  	}
	  }).catch(err => console.log (err))
	}

}
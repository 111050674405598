import React from "react";
import {Checkbox} from "primereact/components/checkbox/Checkbox";

/**
 * Size of titles, values and callback must be the same.
 * @param titles
 * @param excludingGroups  - which checkboxes should work in radio mode
 * @param values
 * @param clbks
 * @returns {*}
 * @constructor
 */
export  const CheckboxGroup = ({titles,excludingGroups, values,clbks,filter}) => {

    return(
        <div style={{display:'inline-flex'}}>{
           titles.map((n,i)=>{ return <div key={i} style={{marginRight:'10px'}}>

               <Checkbox onChange={ (e)=>clbks[i](e,i) }
                         checked={values[i]}/>

               <label >{titles[i]}</label>
               </div>
           })
        }
        </div>
    )
};


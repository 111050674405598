import React from 'react'

const EMPTY_SHAPE = (
    <symbol viewBox="0 0 100 100" id="empty">
        <circle cx="50" cy="50" r="45" />
    </symbol>
);
//
const ELLIPSE_SHAPE=(
    <symbol viewBox="0 0 100 100" id="ellipse">
        <ellipse cx="50" cy="50" rx="40" ry="20"  style={{fill:'aquamarine',stroke:'blue',strokeWidth:'1'}}/>
    </symbol>
);


const CHOICE_SHAPE = (
    <symbol viewBox="0 0 100 100" id="choice">
        <rect transform="translate(50, 5) rotate(45)" width="65" height="65" />
    </symbol>
);

const TASK_SHAPE = (
    <symbol viewBox="0 0 100 100" id="emptyTask">
        <rect width="80" height="80" rx="15" ry="15" transform="translate(10, 10)" />
    </symbol>
);

const PASS_SHAPE = (
    <symbol viewBox="0 0 100 100" id="pass">
        <rect transform="translate(7.5, 10)" width="85" height="85" />
    </symbol>
);

const WAIT_SHAPE = (
    <symbol viewBox="0 0 100 100" id="wait">
        <circle cx="50" cy="50" r="45" transform="translate(0, 2)" />
    </symbol>
);

const TERMINATOR_SHAPE = (
    <symbol viewBox="0 0 100 100" id="terminator">
        <rect width="80" height="80" rx="15" ry="15" transform="translate(10, 10)"  fill="currentColor" />
    </symbol>
);

const SPECIAL_CHILD_SHAPE = (
    <symbol viewBox="0 0 100 100" id="specialChild">
        <rect x="2.5" y="0" width="95" height="97.5" />
    </symbol>
);

const EMPTY_EDGE_SHAPE = (
    <symbol viewBox="0 0 50 50" id="emptyEdge">
        {/*<circle cx="25" cy="25" r="8" fill="currentColor" />*/}
    </symbol>
);

const SPECIAL_EDGE_SHAPE = (
    <symbol viewBox="0 0 50 50" id="specialEdge">
        <rect transform="rotate(45)" x="27.5" y="-7.5" width="15" height="15" fill="currentColor" />
    </symbol>
);

export const SHAPES=
{
    EMPTY_SHAPE: EMPTY_SHAPE,
    CHOICE_SHAPE: CHOICE_SHAPE,
    TASK_SHAPE: TASK_SHAPE,
    PASS_SHAPE:PASS_SHAPE,
    WAIT_SHAPE:WAIT_SHAPE,
    TERMINATOR_SHAPE:TERMINATOR_SHAPE,
    EMPTY_EDGE_SHAPE:EMPTY_EDGE_SHAPE,
    SPECIAL_CHILD_SHAPE:SPECIAL_CHILD_SHAPE,
    SPECIAL_EDGE_SHAPE:SPECIAL_EDGE_SHAPE,
    ELLIPSE_SHAPE:ELLIPSE_SHAPE
};

import React from 'react';

/**
 * Icon for experiment page
 * @returns {*}
 */
export default () =>

<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 11 11">
    <path fill="#97ACBD" fillRule="evenodd" d="M.302 9.242a1.03 1.03 0 1 0 1.457 1.456l3.383-3.382-1.457-1.457L.302 9.242zm8.454-6.27l1.472-.762L11 .72 10.28 0 8.79.772l-.762 1.472-1.794 1.794.728.728 1.794-1.794zm.322 3.91c-.046-.002-.091-.013-.139-.013-.25 0-.49.051-.712.133l-4.23-4.23c.083-.222.135-.46.135-.711 0-.048-.011-.092-.014-.14A2.055 2.055 0 0 0 2.07 0c-.317 0-.615.078-.883.206l1.369 1.369a.672.672 0 0 1 .154.247.685.685 0 0 1-.64.925.681.681 0 0 1-.238-.046.681.681 0 0 1-.247-.155L.216 1.177a2.04 2.04 0 0 0-.205.884c0 1.09.85 1.974 1.921 2.046.047.003.092.014.14.014.25 0 .489-.051.711-.134l4.23 4.23c-.082.222-.134.46-.134.712 0 .048.011.092.014.139a2.055 2.055 0 0 0 2.046 1.922c.318 0 .615-.078.884-.206l-1.37-1.37a.672.672 0 0 1-.154-.247.685.685 0 0 1 .64-.925c.082 0 .162.018.239.047.09.034.175.082.247.155l1.369 1.368A2.04 2.04 0 0 0 11 8.93c0-1.09-.85-1.974-1.922-2.047z"/>
</svg>

import PropTypes from 'prop-types';
import React from "react";
import {TaskInputsOutputsTable} from "./TaskInputsOutputsTable";


export const TaskPropertiesTable = ({tasks, selectedTask}) => {


    // Object.keys(workflow.tasks).forEach(convertTask)
    // const id = (selectedTask != null && selectedTask.data != null) ? selectedTask.data.workflowTaskId : null;
    // const task = (tasks != null && tasks.tasks!=null) ? Object.keys(tasks.tasks).find((el) => {
    //     return el === selectedTask['title'];
    // }) : null;

    const task = tasks.tasks[selectedTask['title']];

    return (
        <React.Fragment>
            {
                task != null &&

                <table className='taskTable' style={{width: '100%'}}>
                    <tbody>
                    <tr>
                        <th colSpan="2">Task properties</th>
                    </tr>
                    <tr>
                        <th>Name</th>
                        <td>{task.name}</td>
                    </tr>
                    <tr>
                        <th>Version</th>
                        <td>{task.description}</td>
                    </tr>
                    <tr>
                        <th>Created</th>
                        <td>{task.creationDate}</td>
                    </tr>
                    <tr>
                        <th>Privacy</th>
                        <td>{task.privacy}</td>
                    </tr>
                    {task.process != null &&
                    <React.Fragment>
                        <tr>
                            <th colSpan="2">Process</th>
                        </tr>
                        <tr>
                            <th> Type</th>
                            <td>{task.process.type}</td>
                        </tr>
                        <tr>
                            <th colSpan="2">Execution</th>
                        </tr>
                        <tr>
                            <th>Executor</th>
                            <td>{task.process.execution.executor}</td>
                        </tr>
                        <tr>
                            <th>Descriptor Id</th>
                            <td>{task.process.execution.descriptorId}</td>
                        </tr>
                    </React.Fragment>
                    }
                    {task.inputs != null &&
                    <React.Fragment>
                        <tr>
                            <th colSpan="2">Inputs</th>
                        </tr>
                        <TaskInputsOutputsTable inputs={task.inputs}/>
                    </React.Fragment>
                    }
                    {task.outputs != null &&
                    <React.Fragment>
                        <tr>
                            <th colSpan="2">Outputs</th>
                        </tr>
                        <TaskInputsOutputsTable inputs={task.outputs}/>
                    </React.Fragment>}
                    </tbody>
                </table>

            }
        </React.Fragment>)

};
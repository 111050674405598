/**
 * Helper for handling network requests with fetch.
 */
class RequestService {

    static getErrorMessage (f, res){
        return  'Network error:: RequestService::'+f+' for url:'+res.url+' Status: ' + res.status +' ' +res.statusText;
    }
    /** async function for getting data in JSON
     *
     * @param url
     * @param options
     * @returns {Promise<*>}
     */
    async getRequest(url,options){
        return await (await (fetch(url,options)
                .then(res => {
                    if (res.ok===true)
                        return res.json();
                    else
                        throw RequestService.getErrorMessage('getRequest',res);
                })
                .catch(err => {
                    throw err
                })
        ));
    }

    /** async function for getting binary data (arrayBuffer)
     *
     * @param url
     * @param options - can be empty object ie.{}
     * @returns {Promise<*>}
     */
    async getBinaryRequest(url,options){
     return await (await (fetch(url,options)
                .then(res => {
                    if (res.ok===true)
                        return res.arrayBuffer();
                    else
                        throw RequestService.getErrorMessage('getBinaryRequest',res);
                })
                .catch(err => {
                    throw err;
                })
        ));

    }
}

export default new RequestService()
/**
 * Helper function for creating fake numerical data
 * Designed for testing scatterplot, boxplot, etc..
 * @param nsamples number of samples
 * @returns {Array}
 */

export function create_data(nsamples) {
    let x = [];
    let y = [];
    let n = nsamples;
    let x_mean = 0;
    let y_mean = 0;
    let term1 = 0;
    let term2 = 0;
    let noise_factor = 10;
    let noise = 0;
    // create x and y values
    for (let i = 0; i < n; i++) {
        noise = noise_factor * Math.random();
        noise *= Math.round(Math.random()) == 1 ? 1 : -1;
        y.push(i / 5 + noise);
        x.push(i / 10 + 1);
        x_mean += x[i]
        y_mean += y[i]
    }
    // calculate mean x and y
    x_mean /= n;
    y_mean /= n;

    // calculate coefficients
    let xr = 0;
    let yr = 0;
    for (let i = 0; i < x.length; i++) {
        xr = x[i] - x_mean;
        yr = y[i] - y_mean;
        term1 += xr * yr;
        term2 += xr * xr;

    }
    let b1 = term1 / term2;
    let b0 = y_mean - (b1 * x_mean);
    // perform regression

    let yhat = [];
    // fit line using coeffs
    for (let i = 0; i < x.length; i++) {
        yhat.push(b0 + (x[i] * b1));
    }

    let authors=[
        {name:"Freesurfer", version:"1.2", contributor:"na"},
        {name:"SpliceMaster", version:"2.0", contributor:"na"},
        {name:"VTK Slicer",     version:"na",  contributor:"John BigFoot"},
        {name:"LesionFinder",     version:"na",  contributor:"Rosie O'Donell"},
        {name:"manual",     version:"na",  contributor:"na"}
    ];


    let data = [];
    for (let i = 0; i < y.length; i++) {
        data.push({
            "yhat": yhat[i],
            "y": y[i],
            "x": x[i],
            "author":authors[i%authors.length],
            "groups":authors[i%authors.length].name,
            "groups2":authors[i%authors.length].version,
            "groups3":authors[i%authors.length].contributor,
            "orFilename":"or.nrrd",
            "ovFilename":"ov200.nrrd",
        })
    }
    return (data);
}

/**
 * Helper for generating random unsigned integers in range min-max
 * @param nsamples - number of samples
 * @param max - positive integer
 * @param min - positive integer
 * @returns {Array}
 */
export function create_data2(nsamples,min, max) {
    let x = [];
    let y = [];
    let n = nsamples;
    for (let i = 0; i < n; i++) {
        y.push(Math.floor(Math.random() * max) + min);
        x.push(i);
    }

   let authors=[
        {name:"Freesurfer", version:"1.2", contributor:"na"},
        {name:"SpliceMaster", version:"2.0", contributor:"na"},
        {name:"VTK Slicer",     version:"na",  contributor:"John BigFoot"},
        {name:"LesionFinder",     version:"na",  contributor:"Rosie O'Donell"},
        {name:"manual",     version:"na",  contributor:"na"}
    ];


    let data = [];
    for (let i = 0; i < y.length; i++) {
        data.push({
            "y": y[i],
            "x": x[i],
            "author":authors[i%authors.length],
            "groups":authors[i%authors.length].name,
            "groups2":authors[i%authors.length].version,
            "groups3":authors[i%authors.length].contributor,
            "orFilename":"or.nrrd",
            "ovFilename":"ov200.nrrd",
        })
    }
    return (data);
}
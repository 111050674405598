import {HOST_URL, VARIABLE_PROPERTIES_NAMES} from "../../../Constants";

import {
    EXPDESIGN_CALCULATE_SS_FAIL, EXPDESIGN_CALCULATE_SS_REQUEST, EXPDESIGN_CALCULATE_SS_SUCCESS, EXPDESIGN_PLOT_SS_FAIL,
    EXPDESIGN_PLOT_SS_REQUEST, EXPDESIGN_PLOT_SS_SUCCESS,
    EXPDESIGN_SET_CALCULATOR_PARAMS
} from "./actionType";
import NetworkService from "../../helpers/io/NetworkService";
import {POWER_CALCULATOR} from "../models/PowerCalculator";

//*************Actions******************************************
const requestCalculateSampleSize = ()=> ({
    type:EXPDESIGN_CALCULATE_SS_REQUEST
});

const failCalculateSampleSize = (err)=> ({
    type:EXPDESIGN_CALCULATE_SS_FAIL,
    err
});

const successCalculateSampleSize = (ss)=> ({
    type:EXPDESIGN_CALCULATE_SS_SUCCESS,
    ss
});

export const setCalculatorParameters = (parameters) => ({
    type: EXPDESIGN_SET_CALCULATOR_PARAMS,
    parameters
});

const requestStatisticalPowerPlot = ()=> ({
    type:EXPDESIGN_PLOT_SS_REQUEST,
});

const failStatisticalPowerPlot = (err)=> ({
    type:EXPDESIGN_PLOT_SS_FAIL,
    err
});

const successStatisticalPowerPlot = (plot)=> ({
    type:EXPDESIGN_PLOT_SS_SUCCESS,
    plot
});



//*************Action Creators******************************************

export const getStatisticalPowerPlot = (u) => {
    console.log('CalculatorAction::getStatisticalPowerPlot::request parameters:',u);
return dispatch => {
    dispatch(requestStatisticalPowerPlot());

    NetworkService.getGLRPowerPlot({u:u})
        .then((plot)=>{
            dispatch(successStatisticalPowerPlot(plot));
        }).catch((err)=>{
        dispatch(failStatisticalPowerPlot(err));
        alert('R server is not responding.');
        console.log('UNsuccessful power calc',err)
        });
}
};
//helper function for setting any given Parameter (non-dispatching)
export const setCalculatorParameter= (params, name, value)=>{
    if (!(params["data"]!=null))
        params["data"]={};
    params["data"][name]=value;
    return params;
};


/** Calculates the required power based on other parameters.
 *
 * This is also example of middleware use - getState is used to have access to store.
 *
 * @returns {function(*, *)}
 */
export const calculateSampleSizeActionCreator = () => {
    console.log('CalculatorAction::calculateSampleSizeActionCreator::request parameters:');
    return (dispatch,getState) => {

        let args = getState().expDesign.calculator.parameters;
        //preparing payload to R server
        if (!(args["data"]!=null))   args["data"]={};

        if (!(args["data"][POWER_CALCULATOR.GLR.ARGS.F2.NAME]!=null))
            args["data"][POWER_CALCULATOR.GLR.ARGS.F2.NAME]= POWER_CALCULATOR.GLR.ARGS.F2.DEFAULT;

        if (!(args["data"][POWER_CALCULATOR.GLR.ARGS.SIG_LEVEL.NAME]!=null))
            args["data"][POWER_CALCULATOR.GLR.ARGS.SIG_LEVEL.NAME] = POWER_CALCULATOR.GLR.ARGS.SIG_LEVEL.DEFAULT;
        if (!(args["data"][POWER_CALCULATOR.GLR.ARGS.POWER.NAME]!=null))
            args["data"][POWER_CALCULATOR.GLR.ARGS.POWER.NAME] = POWER_CALCULATOR.GLR.ARGS.POWER.DEFAULT;

        if (!(args["data"][POWER_CALCULATOR.GLR.ARGS.U.NAME]!=null))
            args["data"][POWER_CALCULATOR.GLR.ARGS.U.NAME]= 2;

        if (!(args["f"]!=null))
            args["f"] = POWER_CALCULATOR.GLR.NAME;


        delete args["data"][POWER_CALCULATOR.GLR.ARGS.V.NAME];

        let parameters = args;

        dispatch(requestCalculateSampleSize);

        NetworkService.getCall(parameters)
            .then((response)=>{
                let ss = Math.ceil(POWER_CALCULATOR.GLR.DESERIALIZE('v',response)+parameters.data.u+1);
                dispatch(successCalculateSampleSize(ss));
            }).catch((err)=>{
                dispatch(failCalculateSampleSize(err));
                alert('R server is not responding.')
            });
      }
};

import {connect} from "react-redux";
import {withRouter} from "react-router";
import {DynamicHistogramPanel} from "../component/d3components/DynamicHistogramPanel";
import {
    clearOverlayImageActionCreator,
    getImageActionCreator,
    getTabletGameExperimentResults
} from "../action/TabletGameExperimentAction";




const mapStateToProps = (state,ownProps) => {
    return {
        results:state.expDesign.tabletGame.results,
        resultsState: state.expDesign.tabletGame.resultsState,
        mriImage:state.expDesign.tabletGame.mriImage,
        leftOverlay:state.expDesign.tabletGame.leftOverlay,
        leftFreq:state.expDesign.tabletGame.leftFreq,
        rightOverlay:state.expDesign.tabletGame.rightOverlay,
        rightFreq:state.expDesign.tabletGame.rightFreq
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getTabletGameExperimentResults: (tabletExperimentId)=>{
          dispatch (getTabletGameExperimentResults(tabletExperimentId))
        },
        getImage : (imageProperties) => {
            dispatch (getImageActionCreator(imageProperties))
        },
        clearOverlayImage : (propName) => {
            dispatch (clearOverlayImageActionCreator(propName))
        }
    }
};

export const ContainerTabletGameExperiment =withRouter(connect(
    mapStateToProps,
    mapDispatchToProps)(DynamicHistogramPanel));

import React from 'react'
import {withRouter} from "react-router-dom";
import {LinearModelAssumptionsPanel} from "./LinearModelAssumptionsPanel";


/**
 * "Dumb" component for displaying Model Asumptions Panel.
 * @param results should contain model type.
 *
 */

export const ModelAssumptionsPanel = ({results})=> {

    const model=(results!=null && results.model!=null)?results.model:'GLR';

    let component=<LinearModelAssumptionsPanel/>;
    switch (model) {
        case 'GLR':
            component=<LinearModelAssumptionsPanel/>;
            break;
        default:
            component=<LinearModelAssumptionsPanel/>;
            break;
    }



    return (
        <div>
            {component}
        </div>
    );
};



export default ModelAssumptionsPanel;


import React from "react";

export  const ProjectDetailsOverviewTab = ({project}) => {

    return(
        <div>
            {/*TODO implement it according to design*/}
            <div>Summary: {project.summary}</div>
            <div>Created on:  {project.dateOfCreation}</div>
            <div>For me: {project.forMe}</div>
            <div>I can help with: {project.howCanIHelp}</div>
        </div>
    )
};
import PropTypes from 'prop-types';
import React from "react";
import {WorkflowInputsTable} from "./WorkflowInputsTable";
import {WorkflowOutputsTable} from "./WorkflowOutputsTable";

/**
 *
 * @param selectedWorkflow  - workflow to display
 * @returns {*}
 * @constructor
 */
export const WorkflowPropertiesTable = ({selectedWorkflow}) => {
    return (
        <React.Fragment>
            {
                selectedWorkflow != null &&
                <table className='taskTable' style={{width: '100%'}}>
                    <tbody>

                    <tr>
                        <th colSpan="2">Workflow properties</th>
                    </tr>

                    <tr>
                        <th>Name</th>
                        <td>{selectedWorkflow.name}</td>
                    </tr>
                    <tr>
                        <th>Description</th>
                        <td>{selectedWorkflow.description}</td>
                    </tr>
                    <tr>
                        <th>Version</th>
                        <td>{selectedWorkflow.version}</td>
                    </tr>
                    <tr>
                        <th>Type</th>
                        <td>{(selectedWorkflow.type==="workflowExternal")?"External Workflow":"SPINE Workflow"}</td>
                    </tr>
                    <tr>
                        <th>Created</th>
                        <td>{selectedWorkflow.creationDate}</td>
                    </tr>
                    <tr>
                        <th>Privacy</th>
                        <td>{selectedWorkflow.privacy}</td>
                    </tr>
                    {selectedWorkflow.inputs != null &&
                    <React.Fragment>
                        <tr>
                            <th colSpan="2">Inputs</th>
                        </tr>
                        <WorkflowInputsTable inputs={selectedWorkflow.inputs}/>
                    </React.Fragment>
                    }
                    {selectedWorkflow.outputs != null &&
                    <React.Fragment>
                        <tr>
                            <th colSpan="2">Outputs</th>
                        </tr>
                        <WorkflowOutputsTable outputs={selectedWorkflow.outputs}/>
                    </React.Fragment>}
                    </tbody>
                </table>

            }
        </React.Fragment>)
};
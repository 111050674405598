import React from "react";

/**
 * Dumb component with template for displaying Betas table.
 * @returns {*}
 */
export const BetasTable = ({betas,vars}) => {

 const width = 100/(betas.length+1);
    const coStyle={width:''+width+'%'};
    const printVars = vars.map((value, index) =>{
        if (index>0)
            return <div key={value.symbol} className="spineColStyle" style={coStyle}>{value.variableId}</div>});

    const printBetas = betas.map((value,index) =>{
            const val = (!isNaN(parseFloat(value)) && isFinite(value))? value.toFixed(2):value;
            return <div key={index} className="spineColStyle" style={coStyle}>{val}</div>});

    return (
        <div className="spineTableStyle">
            <div className="spineRowHeaderStyle">
                <div className="spineColStyle" style={coStyle}>Dependant variable</div>
                <div className="spineColStyle" style={coStyle}>Intercept</div>
                {printVars}
            </div>
            <div className="spineRowStyle">
                <div className="spineColStyle" style={coStyle}>{vars[0].variableId}</div>
                {printBetas}
            </div>
        </div>
    );
};
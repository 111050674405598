import React from 'react';
import PropTypes from 'prop-types';
import vtkRenderWindow from 'vtk.js/Sources/Rendering/Core/RenderWindow';
import vtkRenderer from 'vtk.js/Sources/Rendering/Core/Renderer';
import vtkImageMapper from 'vtk.js/Sources/Rendering/Core/ImageMapper';
import vtkImageSlice from 'vtk.js/Sources/Rendering/Core/ImageSlice';
import vtkOpenGLRenderWindow from 'vtk.js/Sources/Rendering/OpenGL/RenderWindow';
import * as SpineInteractorStyleImage from "./SpineInteractorStyleImage";
import vtkCellPicker from 'vtk.js/Sources/Rendering/Core/CellPicker';
import vtkPointPicker from 'vtk.js/Sources/Rendering/Core/PointPicker';
import * as SpineRenderWindowInteractor from "./SpineRenderWindowInteractor";

import {loggingDataset} from "./MRIBrowserLogger";
import {REQUEST_STATUS_SUCCESS} from "../../Constants";
import {addPaintBrush} from "./paintbrush/PaintTool";


const PLANES = {SAGITTAL: 0, CORONAL: 1, AXIAL: 2};

/**
 * Single component for annotating 3 D slices based on:
 *  - VTK.js renderWindow generated on canvas and handling interactions (observer)
 *  - own canvas editor for rendering only.
 * This is version based on additional canvas (glass pane).
 * The 'resolution' canvases is 300x300 px (VTK renderWindow default size),
 * however canvases viewports size are calculated dynamically.
 * Note that VTK canvas is generated dynamically by VTK.js.
 * In order to modify canvas to dynamic resolution, window resize handler must be implemented.
 *
 * This is version for PRECISION testing.
 * It works in the way that saves many points to roi record according to what has been picked by:
 * 1. CellPicker
 * 2. PointPicker
 * 3. ImageMapper's getSliceAtPosition(...) method
 *
 *
 * There is a hook for a PaintBrush tool, but only for testing.
 *
 *  See props at the bottom.
 */
class AnnotationBrowser2 extends React.Component {

    constructor() {
        super();
        this.state = {
            renderWindow: vtkRenderWindow.newInstance(),
            renderer: vtkRenderer.newInstance(),
            mappers: [vtkImageMapper.newInstance(), vtkImageMapper.newInstance(), vtkImageMapper.newInstance()],
            originalDirection:null
        };
        ["changeSlice", "renderROI", "stopPropagateEvents"]
            .forEach(name => {
                this[name] = this[name].bind(this);
            }); //Binding methods
    }

    /**
     * Function returning prop linkViewers.
     * Required since right click handler is set during initialisation.
     * @returns {Boolean}
     */
    getLinkViewers(){
        return  this.props.linkViewers;
    }

    componentDidMount() {
        const {imageData, viewportMode, annotationData, cis, addRow, onCisChange,smoothing,aligned} = this.props;
        const {renderer, renderWindow, mappers} = this.state;

        //-----------setting Paintbrush-------------------------
        addPaintBrush(this.glass);


        this.setState({originalDirection:this.props.imageData.getDirection().slice(0)});
        const imageActor = vtkImageSlice.newInstance();
        let initialSlice = 0;
        renderer.setViewport([0, 0, 1, 1]);// full window mode
        const interactorStyle2D = SpineInteractorStyleImage.newInstance();
        interactorStyle2D.setInteractionMode('IMAGE_SLICING');
        const openglRenderWindow = vtkOpenGLRenderWindow.newInstance();
        renderWindow.addView(openglRenderWindow);
        openglRenderWindow.setContainer(this.node);
        const interactor = SpineRenderWindowInteractor.newInstance();
        interactor.setInteractorStyle(interactorStyle2D); // in case using predefined styles - SpineIneractorStyleImage
        interactor.setView(openglRenderWindow);
        interactor.initialize();
        interactor.bindEvents(this.glass);
        const imageMapper = mappers[viewportMode];

        const cellPicker = vtkCellPicker.newInstance();
        renderWindow.getInteractor().setPicker(cellPicker);
        cellPicker.setPickFromList(1);
        cellPicker.initializePickList();
        cellPicker.addPickList(imageActor);


        const pointPicker = vtkPointPicker.newInstance();
        renderWindow.getInteractor().setPicker(pointPicker);
        pointPicker.setPickFromList(1);
        pointPicker.initializePickList();
        pointPicker.addPickList(imageActor);

        if(aligned)  imageData.setDirection(1,0,0,0,1,0,0,0,1);

        const dims = imageData.getDimensions();
        const inputData = imageData;


        let getIndex = (ijk)=>{
            return ijk[0] + ijk[1] * dims[0] + ijk[2] * dims[0] * dims[1];
        };

        renderWindow.getInteractor().onRightButtonPress((callData) => {
            if (renderer !== callData.pokedRenderer) {
                return;
            }
            const pos = callData.position;
            const point = [pos.x, pos.y, 0.0];
            cellPicker.pick(point, renderer);
            pointPicker.pick(point, renderer);
            // const view = callData.pokedRenderer.getRenderWindow().getViews()[0];
            // worldPos = view.displayToWorld(...point, callData.pokedRenderer);

            let worldPos = cellPicker.getPickedPositions()[0]; //only one Actor
            let worldPos2 = pointPicker.getPickedPositions()[0]; //only one Actor
            let slices = [0,0,0];

            if (worldPos == null){
                alert("You cannot pick a point outside MRI volume");
                return;
            }
            let slices3 = null;
            try {
                slices3 = mappers.map((el) => {
                    return el.getSliceAtPosition(worldPos)
                });
            }
            catch (err) {
                alert("You cannot pick a point outside MRI volume");
                return;
            }
            let slices4 = null;
            try {
                slices4 = mappers.map((el) => {
                    return el.getSliceAtPosition(worldPos2)
                });
            }
            catch (err) {
                alert("You cannot pick a point outside MRI volume");
                return;
            }

            slices = cellPicker.getCellIJK();
            let slices2 = pointPicker.getPointIJK();
            const pointIndex = getIndex(slices);
            // const pointIndex2 = getIndex(pointPicker.getPointIJK());
            inputData.getPointData().getScalars().getData()[pointIndex] = 1983;

            addRow({
                "roiType": "Point",
                "roiStatus": "normal",
                "roiCellIJK": slices,
                "roiPointIJK": slices2,
                "roiMapperIJKCell": slices3,
                "roiMapperIJKPoint": slices4,
                "roiPosition": worldPos,
                "roiPositionPointPicker": worldPos2,
                "roiPointId": pointIndex
            });


            if (this.getLinkViewers()) onCisChange(slices);
            // renderWindow.modified();
            inputData.modified();
            renderWindow.render();
        });

        renderWindow.addRenderer(renderer);
        renderer.setBackground(0, 0, 0);
        renderer.getActiveCamera().setParallelProjection(true);
        renderer.addActor(imageActor);

        console.log('Original image');
        loggingDataset(imageData);
        const dataRange = imageData.getPointData().getScalars().getRange();

        // this.changeWL(dataRange[1] - dataRange[0]);
        imageActor.getProperty().setColorWindow(dataRange[1] - dataRange[0]);
        // this.changeCL(dataRange[0] + (dataRange[1] - dataRange[0]) * .5);
        imageActor.getProperty().setColorLevel(dataRange[0] + (dataRange[1] - dataRange[0]) * .5);
        // this.setState({dataRange: dataRange});
        mappers.forEach((el) => {
            el.setInputData(imageData)
        });

        if (cis != null && cis[viewportMode] != null) initialSlice = cis[viewportMode];

        let z = cis.slice(0);
        z[viewportMode] = initialSlice;
        onCisChange(z);

        imageActor.setMapper(imageMapper);
        imageActor.getProperty().setOpacity(1.0);//!!!!!!
        imageActor.getProperty().setInterpolationType(smoothing?1:0);

        switch (viewportMode) {
            case PLANES.SAGITTAL:
                mappers[0].setISlice(initialSlice);
                mappers[1].setJSlice(0);
                mappers[2].setKSlice(0);
                renderer.getActiveCamera().set({position: [1, 0, 0], viewUp: [0, 0, 1]});
                break;
            case PLANES.CORONAL:
                mappers[0].setISlice(0);
                mappers[1].setJSlice(initialSlice);
                mappers[2].setKSlice(0);
                renderer.getActiveCamera().set({position: [0, -1, 0], viewUp: [0, 1, 0]});
                break;
            case PLANES.AXIAL:
                mappers[0].setISlice(0);
                mappers[1].setJSlice(0);
                mappers[2].setKSlice(initialSlice);
                renderer.getActiveCamera().set({position: [0, 0, -1], viewUp: [0, -1, 0]});
                break;
        }
        renderer.resetCamera();
        annotationData.forEach((el) => {
            this.renderROI(el)
        });
        // this.setState({colorLevel: (dataRange[0] + dataRange[1]) / 2});
        this.refreshRenderWindow();
    }


    /** This is function responsible for rendering spheres for ROI's.
     *
     * @param element
     */
    renderROI(element) {
        const {imageData, viewportMode, annotationData, markerSize, fontSize, cis, addRow, onCisChange} = this.props;
        let roi = element["roi"];
        let z = this.state.renderWindow.getViews()[0].worldToDisplay(roi.roiPosition[0], roi.roiPosition[1], roi.roiPosition[2], this.state.renderer);

        if (roi["roiCellIJK"][viewportMode] !== cis[viewportMode]) return;
        console.log("Restored Point:", z);
        let ctx = this.glass.getContext("2d");

        switch (roi.roiStatus) {
            case "active": {
                ctx.fillStyle = "red";
                break;
            }
            case "normal": {
                ctx.fillStyle = "blue";
                break;
            }
            default : {
                ctx.fillStyle = "green";
                break;
            }
        }
        let size = (markerSize != null) ? markerSize : 4;
        // ctx.fillRect(z[0], ctx.canvas.height - z[1], size, size);
        ctx.beginPath();
        ctx.arc(z[0], ctx.canvas.height - z[1], size, 0, 2 * Math.PI, false);
        ctx.closePath();
        ctx.fill();
        ctx.font = fontSize + "px Helvetica";
        ctx.fillText(element["id"], z[0], ctx.canvas.height - z[1]);
    }

    /**
     *
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps, prevState) {
        const {imageData,overlayOpacity, annotationData, cis, viewportMode,smoothing,aligned} = this.props;
        const {mappers} = this.state;
        let ctx = this.glass.getContext("2d");
        if (prevProps !== this.props) {

            if (prevProps.smoothing!==smoothing){
                this.state.renderer.getActors()[0].getProperty().setInterpolationType(smoothing?1:0);
                this.refreshRenderWindow();
                return;
            }
            if (prevProps.aligned!==aligned){
                if (aligned)
                    imageData.setDirection(1,0,0,0,1,0,0,0,1);
                else
                    imageData.setDirection(...this.state.originalDirection);
                imageData.modified();
                this.refreshRenderWindow();
                return;
            }

            console.log("Updated Props: ", prevProps);
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            ctx.globalAlpha = overlayOpacity / 100.0;
            annotationData.forEach((el, index) => {
                this.renderROI(el)
            });
            if (prevProps.cis !== cis && prevProps.cis[prevProps.viewportMode] !== cis[viewportMode]) {
                mappers[viewportMode].setSlice(cis[viewportMode]);
                this.refreshRenderWindow();
            }
        }
        else   if (prevState !== this.state) {
            console.log("Updated State: ");
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            annotationData.forEach((el, index) => {
                this.renderROI(el)
            });
        }
    }

    /**
     * Handler for slice change [k=2]
     * @param event
     */
    changeSlice(event) {
        const {viewportMode, cis, onCisChange} = this.props;
        let val = parseInt(event.target.value); //redundant in PF version
        // this.setState({currentImageSlice: val});
        let z = cis.slice(0);
        z[viewportMode] = val;
        onCisChange(z);
        this.state.mappers[viewportMode].setSlice(val);
        this.refreshRenderWindow();
    }

    refreshRenderWindow() {
        this.state.renderWindow.render();
    }

    stopPropagateEvents(e) {
        if (e.type==='wheel' || ( e.shiftKey && e.type==='mousemove') )
            this.setState(this.state);//refresh state with ComponentDidUpdate
        e.stopPropagation(); //to avoid accidentally handled events in parents (cuts bubbling)
    }

    render() {
        const {viewportMode, cis, imageData, imageState} = this.props;
        let spinner = null;
        if (!this.props.imageState === REQUEST_STATUS_SUCCESS) {
            spinner = <div> Loading data: <i className="fa fa-spinner fa-spin"/></div>;
        }
        return (
            <div className={"ui-g-12 ui-g-nopad"}>
                <div className={"ui-g-12 ui-g-nopad"}>
                    <div className={"ui-g-11"}>
                        <input type="range" onChange={this.changeSlice}
                               style={{width: "100%"}} min="0" step="1"
                               max={imageData.getExtent()[viewportMode * 2 + 1]}
                               value={cis[viewportMode]}
                        />
                    </div>
                    <div className={"ui-g-1"}>
                        {cis[viewportMode]}
                    </div>
                </div>
                <div className={"ui-g-12 ui-g-nopad"} id="container_canvas">
                    <div style={{position: "relative", width: "100%"}} id="relative_parent"
                         onWheel={(e) => this.stopPropagateEvents(e)}
                         onMouseDown={(e) => this.stopPropagateEvents(e)}
                         onMouseUp={(e) => this.stopPropagateEvents(e)}
                         onMouseMove={(e) => this.stopPropagateEvents(e)}
                    >
                        <div ref={node => this.node = node}
                             style={{position: "absolute", left: "0", top: "0", zIndex: "1", width: "inherit"}}/>
                        <div style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                            width: "100%",
                            zIndex: 2,
                            // pointerEvents: "none"
                        }}>
                            <canvas ref={glass => this.glass = glass} id="canvas_2"
                                    width="300" height="300" //constant canvas resolution - according to VTK
                                    style={{zIndex: "2", width: "100%"}}
                                    onMouseDown={(e) => console.log("On Mouse Down Glass>>>>>>>>>>>")}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );

    }
}

AnnotationBrowser2.defaultProps = {
    viewportMode: PLANES.SAGITTAL, //
    overlayOpacity: 100
};
AnnotationBrowser2.propTypes = {
    viewportMode: PropTypes.number,
    imageData: PropTypes.object.isRequired, // object of vtkData class (VTK.js)
    imageState: PropTypes.string,
    annotationData: PropTypes.array,
    overlayOpacity: PropTypes.number,
    cis: PropTypes.array.isRequired, // current image slice
    onCisChange: PropTypes.func.isRequired,
    linkViewers:PropTypes.bool,
    smoothing:PropTypes.bool.isRequired
};

export default AnnotationBrowser2;
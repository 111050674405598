import React from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import {ContainerStatisticalModel} from "./containers/ContainerStatisticalModel";
import WizardStepMonitor from "./component/WizardStepMonitor";
import ContainerAudit from "./containers/ContainerAudit";
import {REQUEST_STATUS_SUCCESS} from "../../Constants";
import {ContainerOverview} from "./containers/ContainerOverview";



export const expDesignPagesEnum = Object.freeze({"Global": 1, "Model": 2,  "Audit": 3, "Discussion": 3});

/** Responsible for rendering the experiment design wizard.
 *  Refactored in comparison to v.1.0.0 - the state of wizard is kept in Redux now.
 *  The wizard controller is not needed - the transitions are maintained by validation rules and wizardStepAction.
 */

class ExpDesign extends React.Component {

    constructor(props) {
        super(props);
        this.initializeStore = this.initializeStore.bind(this);
    }

    componentWillMount() {
        const {getVariables,getModels,getCohorts,variablesList,getClinicalVariables,getMetaVariables,
            getAnatomicalVariables,getPathologicalVariables, modelsList, getMeasurements,getOperators} = this.props;

        if (variablesList.variablesState !== REQUEST_STATUS_SUCCESS)
            getVariables();
        if (variablesList.clinicalVariablesState !== REQUEST_STATUS_SUCCESS)
            getClinicalVariables();
        if (variablesList.anatomicalVariablesState !== REQUEST_STATUS_SUCCESS)
            getAnatomicalVariables();
        if (variablesList.pathologicalVariablesState !== REQUEST_STATUS_SUCCESS)
            getPathologicalVariables();
        if (variablesList.metaVariablesState !== REQUEST_STATUS_SUCCESS)
            getMetaVariables();
        if (variablesList.measurementVariablesState !== REQUEST_STATUS_SUCCESS)
            getMeasurements();
        if (variablesList.operatorVariablesState !== REQUEST_STATUS_SUCCESS)
            getOperators();
        if (modelsList.modelsState !== REQUEST_STATUS_SUCCESS)
            getModels();

        if (this.props.studies.studiesState !== REQUEST_STATUS_SUCCESS)
            getCohorts();
        this.initializeStore();
    }
    /**
     * initialize expDesign variable in Redux Store with proper value (new or copied object experiment)
     * If experiment does not exist, it is handled by different path.
     * If experiment does exist AND
     *     - if it does not match the link, it needs to be retrieved from DB, page set to 1, and changed state of experiment Id
     *     - if it does match the link, do nothing - just display the previous settings
     */
    initializeStore(){
        const {match,experiment,loadExperiment} = this.props;
        if (match.params.experimentid!=null && match.params.experimentid!==experiment.uuid){
            loadExperiment(match.params.experimentid);
        }
    }


    render() {
        const {sessionState,match} =this.props;
        let currentPage;
        let currentPageIndex = sessionState.currentPage;
        const inlineBlockCenter = {textAlign: 'center'};

        switch (currentPageIndex) {
            case expDesignPagesEnum.Global:
                currentPage = <ContainerOverview match={match}/>;//passed to verify whether a new experiment has been loaded
                break;
            case expDesignPagesEnum.Model:
                currentPage = <ContainerStatisticalModel />;
                break;
            case expDesignPagesEnum.Audit:
                currentPage = <ContainerAudit/>;
                break;
            case expDesignPagesEnum.Discussion:
                currentPage = <ContainerOverview/>;
                break;
            default:{
                currentPage = <ContainerOverview match={match}/>;
                currentPageIndex=1;
                break;}
        }

        return (
            <div style={{margin: '16px'}}>
                <div style={inlineBlockCenter}>
                    <WizardStepMonitor highlightIndex={currentPageIndex}
                                       elements={['Description', 'Methods', 'Results', 'Discussion']}/>
                    <div style={{marginLeft: '25px', marginRight: '25px'}}>
                        {currentPage}
                    </div>
                </div>
            </div>
        )
    };

}

ExpDesign.propTypes={
    getModels:PropTypes.func.isRequired,
    getVariables:PropTypes.func.isRequired,
    getAnatomicalVariables:PropTypes.func.isRequired,
    getClinicalVariables:PropTypes.func.isRequired,
    getPathologicalVariables:PropTypes.func.isRequired,
    getMetaVariables:PropTypes.func.isRequired,
    getMeasurements:PropTypes.func.isRequired,
    getOperators:PropTypes.func.isRequired,
    getCohorts:PropTypes.func.isRequired,
    loadExperiment:PropTypes.func.isRequired,
    variablesList:PropTypes.array.isRequired,
    modelsList: PropTypes.array.isRequired
};

export default withRouter(ExpDesign)
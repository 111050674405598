import React from "react";
import {Prompt, withRouter} from "react-router";
import {Button} from "primereact/components/button/Button";
import AnnotationBrowser5 from "../../../vtk/AnnotationBrowser5";
import AnnotationToolPortal from "./AnnotationToolPortal";
import {AnnotationTable} from "./AnnotationTable";
import PropTypes from "prop-types";
import DownloadLink from "react-download-link";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import SpinePaintFilter from "../../../vtk/paintbrush/SpinePaintFilter";
import {LEFT_BUTTON_MODE} from "../../../vtk/SpineInteractorStyleImage";
import {ToggleButton} from "primereact/components/togglebutton/ToggleButton";
import {BrushToolbar} from "./BrushToolbar";
import {EraserToolbar} from "./EraserToolbar";
import {PinToolbar} from "./PinToolbar";
import {FillingToolbar} from "./FillingToolbar";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {changeOrientation} from "../../action/AnnotationAction";
import {REQUEST_STATUS_SUCCESS} from "../../../../Constants";

/**
 * Component providing template for annotation Tool (Christian's project).
 *
 * @version 2 - valid for Christian's project
 *
 */
class AnnotationTool4 extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            activeViewerIndex:-1,
            sidebarVisible:false,
            tracker:false,
            pinOpacity:100,
            pinProperties:{
                markerSize:2,
                shape:"circle",//yet not working
                fontSize:12,
            },
            imageTypes:[0,0,0],
            showWindowPortal: false,
            linkViewers:true,
            smoothing:[false,false,false],
            leftButtonMode:LEFT_BUTTON_MODE.NONE
        };
        ["viewCallback","dragStart","dragStop","setButtonLeftMode","pickerCallback","keyListener"].forEach(name => { this[name] = this[name].bind(this);});
    }
    componentDidMount(){
        const {initialize,overlays,images,imageOptions}=this.props;
        initialize();
        document.body.addEventListener("keydown", this.keyListener, true);
    }

    pickerCallback(viewerIndex, ijk){
        const {viewersState,updateViewerProperty} =this.props;
        let copyCis = [] ;
        viewersState.forEach((el,index)=>{copyCis.push(Object.assign({},viewersState[index]))});

        const id = copyCis[viewerIndex].imageId;
        let hasChanged = false;
        copyCis.forEach((el,i)=>{
            if (el.imageId===id){
                hasChanged=true;
                updateViewerProperty(i,'slice', ijk[el.slicingMode]);
            }
        });
    }

    componentWillUnmount(){
        const {clearAnnotatorState}=this.props;
        clearAnnotatorState();
        document.body.removeEventListener("keydown", this.keyListener, true);
    }

    setButtonLeftMode(e){
        const {leftButtonMode} = this.state;
        if (e!==leftButtonMode){
            this.setState({leftButtonMode:e});
        }
        else{
            this.setState({leftButtonMode:LEFT_BUTTON_MODE.NONE});
        }
    }


    keyListener(event) {
        const {activeViewerIndex} = this.state;
        const {updateViewerProperty, viewersState, images} = this.props;
        event.stopPropagation();
        event.preventDefault();
        if (activeViewerIndex > -1) {
            let slice = viewersState[activeViewerIndex].slice;
            const image= images.find((el)=>el.uuid===viewersState[activeViewerIndex].imageId);
            const maxSlice = image.data.getDimensions()[viewersState[activeViewerIndex].slicingMode] - 1;
            if (event.code === "ArrowDown" || event.code === "PageDown") {
                slice += 1;
                updateViewerProperty(activeViewerIndex, 'slice', (slice <= maxSlice) ? slice : maxSlice);
                return;
            }
            if (event.code === "ArrowUp" || event.code === "PageUp") {
                slice -= 1;
                updateViewerProperty(activeViewerIndex, 'slice', (slice >= 0) ? slice : 0);
                return;
            }
            if (event.code === "ArrowLeft" || event.code === "Home") {
                updateViewerProperty(activeViewerIndex, 'slice', 0);
                return;
            }
            if (event.code === "ArrowRight" || event.code === "End") {
                updateViewerProperty(activeViewerIndex, 'slice', maxSlice);
            }
        }
    }


    viewCallback(row){
        this.props.setActiveAnnotation(row);
    };

    dragStart(event){
        console.log("Dragging")
    }

    dragStop(event){
        console.log("Stopping")
    }

    itemTemplate(option){
        if(!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="ui-helper-clearfix">
                    <span style={{float:'left',margin:'.5em .25em 0 0'}}>{option.label}</span>
                    {option.status==="done" &&
                    <i className="fa fa-check"  title="Done"
                       style={{color:'green',margin:'.5em .25em 0 0',float:'right',verticalAlign:'middle'}}/>
                    }
                    {option.status==="inProgress" &&
                    <i className="fa fa-spinner"  title="In progress"
                       style={{color:'orange',margin:'.5em .25em 0 0',float:'right',verticalAlign:'middle'}}/>
                    }
                    {option.status==="" &&
                    <i className="fa fa-square-o"  title="To do"
                       style={{color:'red',margin:'.5em .25em 0 0',float:'right',verticalAlign:'middle'}}/>
                    }
                </div>
            );
        }};
    render(){
        const {activeViewerIndex, showWindowPortal,pinProperties,linkViewers,smoothing,sidebarVisible,tracker,
            leftButtonMode,colorOptions,opacity,pinOpacity,imageTypes}=this.state;
        const {addAnnotation, clearAll,saveOverlayData, data, match, viewersState,
            updateViewerProperty,updateViewer,imageOptions,changeImageType,images,overlays,changeOrientation} =this.props;

        let setSmoothing = (viewport)=>{
            let smooth = smoothing;
            smooth[viewport]= ! smooth[viewport];
            this.setState({smoothing:smooth});
        };

        let setLinking = (viewport)=>{
            let viewLink = !linkViewers;
            this.setState({linkViewers:viewLink});
        };

        let getImage =(index)=>{
            return images.find((el)=>viewersState[index].imageId === el.uuid);
        };
        let getOverlay =(index)=>{
            let iT = imageOptions.find((el)=>viewersState[index].imageId === el.value); // find option associated with imageId of viewer
            if (iT!=null && iT['hasOverlay'] && iT['overlayId']!=null)
                return overlays.find((el)=>iT['overlayId'] === el.uuid);
            else
                return null;
        };

        let calcRulers = (index)=>{
            if (activeViewerIndex>-1){
                let rulers = [];
                let thisView =  viewersState[index];
                let activeView =  viewersState[activeViewerIndex];
                if (thisView.imageId !== activeView.imageId)
                    return [];
                viewersState.forEach((el,i)=>{
                    if(thisView.imageId === el.imageId){
                        if (thisView.slicingMode!==el.slicingMode){


                            rulers.push({slice:el.slice,slicingMode:el.slicingMode,orientation:el.orientation});
                        }
                    }
                });
                return rulers;
            }
            else return [];
        };


        let browserPanel = (viewersState)?viewersState.map((el,index)=>
            <div className="ui-g-4" style={{padding:"0.1em"}}>
                <AnnotationBrowser5 key={`viewer${index}`}
                                    isActive={this.state.activeViewerIndex===index}
                                    setActive={()=>this.setState({activeViewerIndex:index})}
                                    annotationData={data}
                                    addRow={(roi)=>addAnnotation(roi,index)}
                                    pinProperties={pinProperties}
                                    tracker={tracker}
                                    linkViewers={linkViewers}
                                    leftButtonMode={leftButtonMode}
                                    opacity={opacity}
                                    pinOpacity={pinOpacity}
                                    colorOptions={colorOptions}
                                    imageOptions={imageOptions}
                                    setLinkViewers={() => this.setState({linkViewers: !linkViewers})}
                                    viewerState={el}
                                    updateViewer={(viewerState) => this.updateViewer(index, viewerState)}
                                    pickerCallback={(ijk) => this.pickerCallback(index, ijk)}
                                    smoothing={smoothing[index]}
                                    changeSmoothing={() => setSmoothing(index)}
                                    changeOrientation={(v) => changeOrientation(index, v)}
                                    imageType={imageTypes[index]}
                                    changeImageType={(e)=>changeImageType(index,e)}
                                    updateViewerProperty={(property,value)=>updateViewerProperty(index,property,value)}
                                    image={getImage(index)}
                                    overlay={getOverlay(index)}
                                    rulers={(tracker)?calcRulers(index):null}
                />
            </div>
        ): <div className="ui-g-12" style={{padding:"0.1em"}}/>;
        const options = [{label:"1", value:"123",status:"done"},{label:"2", value:"234",status:"inProgress"},{label:"3", value:"345",status:""}];

        return (
            <div onDragEnd={(e)=>this.dragStop(e)} >
                <Prompt
                    when={true}
                    message='You have unsaved changes, are you sure you want to leave?'
                />
                <div className="ui-g-12">
                    <Dropdown value={"123"}
                              options={options}
                              onChange={(e)=>onChange(e.value)}
                              itemTemplate={this.itemTemplate}/>

                    <div style={{float:"right"}}>

                        <Button onClick={()=>this.setState({showWindowPortal:!showWindowPortal})}
                                icon={showWindowPortal?"fa fa-window-maximize":"fa fa-window-restore"}/>
                        {/*<Button onClick={()=>{saveOverlayData(painter.getLabelMap(),match.params.id)}}*/}
                                {/*icon="fa fa-save" title="Save image"/>*/}
                        <DownloadLink
                            filename="Annotations.json"
                            exportFile={() => JSON.stringify(this.props.data)}  >
                            Save annotations
                        </DownloadLink>


                    </div>
                    <Toolbar style={{display:"inline-block"}}>
                        <ToggleButton checked= {leftButtonMode===LEFT_BUTTON_MODE.PICKER}
                                      onIcon="fa fa-hand-o-up"
                                      onLabel="" offLabel=""
                                      offIcon="fa fa-hand-o-up"
                                      iconPos="right"
                                      tooltip="Picker"
                                      onChange={()=>{this.setButtonLeftMode(LEFT_BUTTON_MODE.PICKER)}}/>
                        <ToggleButton checked= {leftButtonMode===LEFT_BUTTON_MODE.PIN}
                                      onIcon="fa fa-map-pin" offIcon="fa fa-map-pin"
                                      onLabel="" offLabel=""
                                      iconPos="right" tooltip="Annotator"
                                      onChange={()=>{this.setButtonLeftMode(LEFT_BUTTON_MODE.PIN)}}/>
                        <ToggleButton checked={tracker}  onLabel="" offLabel="" toolTip="Crosshairs On/Off"
                                      onChange={()=>{this.setState({tracker:!tracker})}} onIcon="fa fa-crosshairs" offIcon="fa fa-crosshairs"/>
                        <label style={{marginLeft: "1em"}}>Pin opacity:</label>
                        <input type="range" onChange={(e)=>this.setState({pinOpacity:e.target.value})}
                               min={0} step={10} max={100}
                               style={{
                                   width: "4em",
                                   verticalAlign: "middle",
                                   marginRight: "5px",
                                   marginLeft: "5px"
                               }}
                               value={pinOpacity}
                        />
                    </Toolbar>
                    {leftButtonMode===LEFT_BUTTON_MODE.BRUSH &&
                    <BrushToolbar toolProperties={this.state.brushProperties}
                                  changeToolProperties={(e)=>this.setState({brushProperties:e})}
                                  colorOptions={colorOptions}
                    />
                    }
                    {leftButtonMode===LEFT_BUTTON_MODE.PIN &&
                    <PinToolbar toolProperties={this.state.pinProperties}
                                changeToolProperties={(e)=>this.setState({pinProperties:e})}
                                clearAll={clearAll}/>
                    }
                </div>
                {showWindowPortal && (
                    <AnnotationToolPortal>
                        <Button onClick={() => this.setState({ showWindowPortal: false })} label = "Close me !"/>
                        <div className="ui-g-12">
                            {browserPanel}
                        </div>
                    </AnnotationToolPortal>)}
                {!showWindowPortal && (browserPanel)}

                <div style={{position:"fixed",bottom:"0px",zIndex:"1004", backgroundColor:"#e8e8e8"}}>
                    <AnnotationTable {...this.props} activeRowOnly={true} viewCallback={this.viewCallback} />
                    <i className="fa fa-bars"  style={{width:"100%",textAlign:"center"}}
                       onClick={()=>this.setState({sidebarVisible:!this.state.sidebarVisible})}
                       onDragStart={(e)=>this.dragStart(e)}
                       onDrop={(e)=>this.dragStop(e)}
                    />
                    { this.state.sidebarVisible &&
                    <AnnotationTable {...this.props} viewCallback={this.viewCallback} activeRowOnly={false}/>
                    }
                </div>
            </div>
        )
    }

}
export default withRouter(AnnotationTool4);

AnnotationTool4.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    getAnnotations: PropTypes.func.isRequired,
    updateAnnotationData:PropTypes.func.isRequired,
    rmCallback:PropTypes.func.isRequired,
    saveOverlayData:PropTypes.func,
    clearAll : PropTypes.func.isRequired,
    addAnnotation : PropTypes.func.isRequired,
    setActiveAnnotation:PropTypes.func.isRequired,
    clearAnnotatorState:PropTypes.func.isRequired,
    initialize:PropTypes.func.isRequired,
    viewersState:PropTypes.array.isRequired,  //array containing state of all viewers
    updateViewerProperty:PropTypes.func.isRequired,
    updateViewer:PropTypes.func,
    imageOptions:PropTypes.array.isRequired,
    changeImageType:PropTypes.func.isRequired,
    images:PropTypes.array.isRequired,
    overlays:PropTypes.array.isRequired,
    changeOrientation:PropTypes.func.isRequired
};



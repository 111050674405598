import {connect} from "react-redux";
import {
    changeOrientation,
    clearAnnotatorState, getOverlayData, initializeViewers,
    updateSingleViewer, updateViewerProperty
} from "../action/AnnotationAction";
import MRIBrowserPanel from "../../expDesign/component/MRIBrowserPanel";

/**
 *
 * @param state
 * @param ownProps - property
 * @returns {{images, overlays, imageOptions: *|Array, viewersState: *|Array}}
 */
const mapStateToProps = (state,ownProps) => {
    return {
        images:state.visu.annotations.images,
        overlays:state.visu.annotations.overlays,
        viewersState: state.visu.annotations.viewersState
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getOverlayData : (urlToFile)=>{
            dispatch(getOverlayData(urlToFile))
        },
        clearAnnotatorState:()=>{
            dispatch(clearAnnotatorState())
        },
        initializeViewers:(imageId)=>{
            dispatch(initializeViewers(imageId))
        },
        updateViewer:(viewer,index)=>{
            dispatch(updateSingleViewer(viewer,index))
        },
        updateViewerProperty:(index,property,value)=>{
            dispatch(updateViewerProperty(index,property,value))
        },
        changeOrientation:(index,value)=>{
            dispatch(changeOrientation(index,value))
        }
    }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerViewer = containerCreator(MRIBrowserPanel);

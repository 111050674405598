import React from "react";
import {withRouter} from "react-router";
import {HierarchicalDropdownGenerator} from "./annotationTool/HierarchicalDropdownGenerator";
import {HierarchicalDropdown} from "./annotationTool/HierarchicalDropdown";
import {AnnotationCell} from "./annotationTool/AnnotationCell";
import {AnnotationTable} from "./annotationTool/AnnotationTable";
import {ContainerAnnotations2} from "../containers/ContainerAnnotations2";
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import {Button} from "primereact/components/button/Button";
import SpineVtkMRIBrowser from "../../vtk/SpineVtkMRIBrowser";
import AnnotationBrowser from "../../vtk/AnnotationBrowser";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";
import AnnotationBrowser2 from "../../vtk/AnnotationBrowser2";
import {Slider} from "primereact/components/slider/Slider";
import {Spinner} from "primereact/components/spinner/Spinner";
import {SelectButton} from "primereact/components/selectbutton/SelectButton";
import AnnotationTool from "./annotationTool/AnnotationTool";
import {ContainerAnnotations3} from "../containers/ContainerAnnotations3";


class TaskView extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            data:treeData,
            value:undefined,
            sidebarVisible:false,
            overlayOpacity:100,
            fontSize:8,
            markerSize:4
        };
        ["updateData", "addNode", "removeNode"].forEach(name => {
            this[name] = this[name].bind(this);
        });
    }

    updateData(node,value){
        // let data = this.state.data;
        // let element = searchTree(data,node.key);
        node.value = value;
        node.title = value;
        let data =  Object.assign({}, this.state.data);
        this.setState({data:data});
    }

    /**
     * Adding the node to the tree.
     * Key is generated dynamically, and its value is incremented based on the key of the last child of the node
     * (not the number of elements in the list). If any sibling is removed, it does not cause
     * that node can have the same key.
     * @param node
     */
    addNode(node){
        if (!node.children)
            node.children=[];
        let key = node.key;
        let index = node.children.length+1;
        if(index>1){
            let str = node.children[index-2].key.split(SEPARATOR);
            index = Number.parseInt(str[str.length-1])+1;
        };
        let keyNew = (key==='0')? ''+index: ''+key+SEPARATOR+index;
        let title = 'Node'+keyNew;
        node.children.push({
            title: title,
            value: title,
            key: keyNew,
        });
        let data =  Object.assign({}, this.state.data);
        this.setState({data:data});
    }
    removeNode(node){
        let data = this.state.data;
        let element = searchParent(data,node.key);
        let indexToRemove = element.children.findIndex((el)=>el.key===node.key);
        if (indexToRemove!=null)
            element.children.splice(indexToRemove,1);
        data =  Object.assign({}, this.state.data);
        this.setState({data:data});
    }


    render(){
        const {match} = this.props;
        return (
            <div >
              {/*<HierarchicalDropdownGenerator parentNode={this.state.data}*/}
                                             {/*updateData={this.updateData}*/}
                                             {/*addNode={this.addNode}*/}
                                             {/*removeNode={this.removeNode}*/}
              {/*/>*/}
              {/*<HierarchicalDropdown parentNode={this.state.data}*/}
                                    {/*value={this.state.value}*/}
                                    {/*updateValue={(val)=>this.setState({value:val})}*/}
              {/*/>*/}
                {/*<AnnotationCell/>*/}
                {match.params.id === "9a2510f8daeb1c8b38bf5db8a4000f38"  &&
                <ContainerAnnotations3 />
                }

                {match.params.id === "9a2510f8daeb1c8b38bf5db8a4002707"&&
                <ContainerAnnotations2 />}
            </div>
        )
    }

}
export default withRouter(TaskView);

const SEPARATOR='-';
var treeData = {
    title: 'Root',
    value: 'Root',
    key: '0',
    iri: 'http://fhfhgfhg/fjhfjhfjhfh',
    children: [{
        title: 'Node1',
        value: 'Node1',
        key: '1',
        children: [{
            title: 'Node1-1',
            value: 'Node1-1',
            key: '1-1',
        },
            {
                title: 'Node1-2',
                value: 'Node1-2',
                key: '1-2',
            }]
    },

        {
            title: 'Node2',
            value: 'Node2',
            key: '2',
        }]
};

function searchTree(element, matchingKey){
    if(element.key === matchingKey){
        return element;
    }else if (element.children != null){
        let i;
        let result = null;
        for(i=0; result == null && i < element.children.length; i++){
            result = searchTree(element.children[i], matchingKey);
        }
        return result;
    }
    return null;
}
function searchParent(element, matchingKey){
    if(element.children!=null && element.children.find((el)=> el.key === matchingKey)){
        return element;
    }else if (element.children != null){
        let i;
        let result = null;
        for(i=0; result == null && i < element.children.length; i++){
            result = searchParent(element.children[i], matchingKey);
        }
        return result;
    }
    return null;
}


//Study Item that populate StudyList

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Button} from 'primereact/components/button/Button';
import {Card} from 'primereact/components/card/Card'
import {ProgressBar} from 'primereact/components/progressbar/ProgressBar';
import {ScrollPanel} from 'primereact/components/scrollpanel/ScrollPanel';
import {Link} from "react-router-dom";


class ProjectItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    onClick() {
        this.setState({visible: true});
    }

    onHide() {
        this.setState({visible: false});
    }

    render() {
        const {item} = this.props;
        return (
            <div className="ui-grid-col-4 studies">

                <Card title={item.name} style={{padding: '3px', margin: '2px'}}>
                    <ScrollPanel style={{width: '100%', height: '200px', padding: '3px'}}>
                        <div style={{padding: '1em', lineHeight: '1.5'}}>
                            Summary: {item.summary}
                        </div>
                    </ScrollPanel>
                    <div>Progress: <ProgressBar value={item.progress}/></div>
                    {/*<Dialog header={item.name} visible={this.state.visible} width="auto" modal={true}*/}
                    {/*onHide={this.onHide}>*/}
                    {/*<p>About: {item.about}</p>*/}
                    {/*<p>What is for me: {item.forMe}</p>*/}

                    {/*</Dialog>*/}
                    <Link to={`/project/` + item.uuid }>
                        <Button label="View Details" icon="fa-external-link-square"/>
                    </Link>
                </Card>

            </div>
        )
    }

}

ProjectItem.propTypes = {
    item: PropTypes.shape({
        summary: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        about: PropTypes.string,
        forMe: PropTypes.string,
        scientists: PropTypes.array,
        difficulty: PropTypes.number,
        progress: PropTypes.number

    }).isRequired,
};


export default ProjectItem
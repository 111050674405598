import React from 'react';
import OptionallyDisplayed from './OptionallyDisplayed.js';
import PropTypes from 'prop-types';
import {InputText} from "primereact/components/inputtext/InputText";
import {Dropdown} from "primereact/components/dropdown/Dropdown";

export default class DropdownWithValidation extends React.Component {

    constructor(props) {
        super(props);
        this.shouldDisplayError = this.shouldDisplayError.bind(this);
    }

    shouldDisplayError() {
        return this.props.showError && this.props.errorText !== "";
    }

    render() {
        let uiError =  this.shouldDisplayError()?"ui-state-error":"";
        return (
            <React.Fragment>
                {/*<InputText id={this.props.id} type={this.props.type || "text"} placeholder={this.props.placeholder}*/}
                           {/*value={this.props.value} onChange={this.props.onFieldChanged} style={this.props.style} />*/}

                <Dropdown style={this.props.style} name={this.props.name}  options={this.props.options} value={this.props.value}
                          filter={this.props.filter} onChange={this.props.onFieldChanged} className={uiError} optionLabel={this.props.optionLabel}
                          showClear={this.props.showClear}/>
                <OptionallyDisplayed display={this.shouldDisplayError()}>
                    <div className="validation-error">
                        <span className="text">{this.props.errorText}</span>
                    </div>
                </OptionallyDisplayed>
            </React.Fragment>
        );
    }
}
DropdownWithValidation.propTypes = {
    name:PropTypes.string.isRequired,
    options:PropTypes.array,
    showError: PropTypes.bool.isRequired,
    showClear: PropTypes.bool,
    onFieldChanged: PropTypes.func.isRequired,
    //value:PropTypes.object,
    style:PropTypes.object,
    placeholder:PropTypes.string,
    optionLabel:PropTypes.string,
    filter:PropTypes.bool
};
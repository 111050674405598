import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { registerUser } from '../action/RegisterAction.jsx'
import Register from '../component/Register'

const mapStateToProps = state => {
  return{
  	loginStatus : state.auth.login_status
  }
}

const mapDispatchToProps = dispatch => {
  return{
  	registerUser : creds => {
  	  dispatch(registerUser(creds))
  	} 
  }
}

const ContainerLogin = connect(
 mapStateToProps,
 mapDispatchToProps)(Register)

export default ContainerLogin
import React from "react";
import ReactDOM from 'react-dom';

function copyStyles(sourceDoc, targetDoc) {
    Array.from(sourceDoc.styleSheets).forEach(styleSheet => {
        if (styleSheet.cssRules) { // for <style> elements
            const newStyleEl = sourceDoc.createElement('style');

            Array.from(styleSheet.cssRules).forEach(cssRule => {
                // write the text of each rule into the body of the style element
                newStyleEl.appendChild(sourceDoc.createTextNode(cssRule.cssText));
            });

            targetDoc.head.appendChild(newStyleEl);
        } else if (styleSheet.href) { // for <link> elements loading CSS from a URL
            const newLinkEl = sourceDoc.createElement('link');

            newLinkEl.rel = 'stylesheet';
            newLinkEl.href = styleSheet.href;
            targetDoc.head.appendChild(newLinkEl);
        }
    });
}


/** Uncontrolled component for opening annotation tool in separate window.
 * It is based on React Portal and  fully inspired by:
 * @link https://hackernoon.com/using-a-react-16-portal-to-do-something-cool-2a2d627b0202
 *
 */
class AnnotationToolPortal extends React.PureComponent {
    constructor(props) {
        super(props);
        // STEP 1: create a container <div>
        this.containerEl = document.createElement('div');
        this.externalWindow = null;
        // this.loadHandler =  (ev) =>{
        //     ev.preventDefault();
        //     return ev.returnValue = 'Are you sure you want to close?';
        // };
    }

    render() {
        // STEP 2: append props.children to the container <div> that isn't mounted anywhere yet
        return ReactDOM.createPortal(this.props.children, this.containerEl);
    }

    componentDidMount() {
        // STEP 3: open a new browser window and store a reference to it

        this.externalWindow = window.open('', '_blank','height='
            + screen.height + ',width=' + screen.width
            + ',resizable=yes,scrollbars=yes,toolbar=no,menubar=no,titlebar=no,status=no,location=no');
        this.externalWindow.document.title = "Annotation Tool";

        // this.externalWindow.addEventListener("beforeunload", this.loadHandler);


        copyStyles(document, this.externalWindow.document);
        // STEP 4: append the container <div> (that has props.children appended to it) to the body of the new window
        this.externalWindow.document.body.appendChild(this.containerEl);
    }

    componentWillUnmount() {
        // STEP 5: This will fire when this.state.showWindowPortal in the parent component becomes false
        // So we tidy up by closing the window
        this.externalWindow.close();

        // this.externalWindow.removeEventListener("beforeunload", this.loadHandler);

    }
}
export default  AnnotationToolPortal;
import React from "react";
import {withRouter} from "react-router";
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import {Button} from "primereact/components/button/Button";
import {Spinner} from "primereact/components/spinner/Spinner";
import {REQUEST_STATUS_SUCCESS} from "../../../../Constants";
import AnnotationBrowser2 from "../../../vtk/AnnotationBrowser2";
import AnnotationToolPortal from "./AnnotationToolPortal";
import {AnnotationTable} from "./AnnotationTable";
import PropTypes from "prop-types";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import DownloadLink from "react-download-link";

/**
 * Component providing template.
 *
 */
class AnnotationTool extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            sidebarVisible:false,
            overlayOpacity:100,
            fontSize:12,
            markerSize:2,
            shape:"circle",
            cis:null,
            showWindowPortal: false,
            linkViewers:true,
            smoothing:false,
            aligned:false // 0 or 1
        };
        ["viewCallback"].forEach(name => { this[name] = this[name].bind(this);});
    }
    componentWillMount(){
        const {getAnnotations,getMRIData}=this.props;
        this.setState({cis:[0,0,0]});
        getAnnotations("some id here "); //TODO finish
        getMRIData("whatever");//MOCK
    }

    componentDidUpdate(prevProps,prevState){
    const {imageState,imageData} = this.props;

        if (prevProps.imageState!==imageState && imageState===REQUEST_STATUS_SUCCESS) {
            let cis = [0, 0, 0];
            for (let i = 0; i < 3; i++)
                cis[i] = Math.floor((imageData.getExtent()[i * 2 + 1] - imageData.getExtent()[i * 2]) / 2);
            this.setState({cis:cis});
        }
    }

    viewCallback(row){
    if (this.state.linkViewers) this.setState({cis:row["roi"]["roiCellIJK"]});
    this.props.setActiveAnnotation(row);
    };

    render(){
        const {cis,showWindowPortal,overlayOpacity,fontSize,markerSize,linkViewers,smoothing,sidebarVisible,aligned}=this.state;
        const {clearAll,imageState,imageData} =this.props;

        return (
            <div >
                <div className="ui-g-12">
                    <Button icon="fa fa-table" onClick={() => this.setState({sidebarVisible: true})}/>
                    <Button icon="fa fa-trash" label="Clear all" onClick={()=>clearAll()}/>
                    <Button onClick={()=>this.setState({showWindowPortal:!showWindowPortal})}
                            icon={showWindowPortal?"fa fa-window-maximize":"fa fa-window-restore"}/>
                    <label style={{marginLeft:"1em"}}>Shape</label>
                    <label style={{marginLeft:"1em"}}>Opacity:</label>
                    <Spinner value={overlayOpacity}
                             min={0} max={100} step={1} size={3}
                             onChange={(e)=>this.setState({overlayOpacity:e.value})}
                    />
                    <label style={{marginLeft:"1em"}}>Font size:</label>
                    <Spinner value={fontSize}
                             min={0} max={36} step={1} size={3}
                             onChange={(e)=>this.setState({fontSize:e.value})}
                    />
                    <label style={{marginLeft:"1em"}}>Marker size:</label>
                    <Spinner value={markerSize}
                             min={1} max={20} step={1} size={3}
                             onChange={(e)=>this.setState({markerSize:e.value})}
                    />
                    <label style={{marginLeft:"1em"}}>Link viewers:</label>
                    <Checkbox onChange={(e)=>this.setState({linkViewers: e.checked})} checked={linkViewers}/>
                    <label style={{marginLeft:"1em"}}>Smoothing:</label>
                    <Checkbox onChange={(e)=>this.setState({smoothing: e.checked})} checked={smoothing}/>
                    <label style={{marginLeft:"1em"}}>Aligned:</label>
                    <Checkbox onChange={(e)=>this.setState({aligned: e.checked})} checked={aligned}/>
                    <DownloadLink
                        filename="Annotations.json"
                        exportFile={() => JSON.stringify(this.props.data)}
                        
                    >
                        Save to disk
                    </DownloadLink>
                </div>
                {showWindowPortal && (
                    <AnnotationToolPortal>
                        <Button onClick={() => this.setState({ showWindowPortal: false })} label = "Close me !"/>
                        <div className="ui-g-12">
                            <div className="ui-g-4" style={{padding:"0.1em"}}>
                                {imageState ===REQUEST_STATUS_SUCCESS &&
                                <AnnotationBrowser2  viewportMode={0}
                                                     imageState={imageState}
                                                     imageData={imageData}
                                                     annotationData={this.props.data}
                                                     addRow = {this.props.addAnnotation}
                                                     overlayOpacity = {overlayOpacity}
                                                     fontSize = {fontSize}
                                                     markerSize = {markerSize}
                                                     cis = {cis}
                                                     onCisChange = {(cis)=>this.setState({cis:cis})}
                                                     linkViewers = {linkViewers}
                                                     smoothing = {smoothing}
                                                     aligned={aligned}
                                    // initialSlice={20}
                                />}

                            </div>
                            <div className="ui-g-4" style={{padding:"0.1em"}}>
                                {imageState ===REQUEST_STATUS_SUCCESS &&
                                <AnnotationBrowser2  viewportMode={1}
                                                     imageState={imageState}
                                                     imageData={imageData}
                                                     annotationData={this.props.data}
                                                     addRow = {this.props.addAnnotation}
                                                     overlayOpacity = {overlayOpacity}
                                                     fontSize = {fontSize}
                                                     markerSize = {markerSize}
                                                     cis = {cis}
                                                     onCisChange = {(cis)=>this.setState({cis:cis})}
                                                     linkViewers = {linkViewers}
                                                     smoothing = {smoothing}
                                                     aligned={aligned}
                                    // initialSlice={20}
                                />}
                            </div>
                            <div className="ui-g-4" style={{padding:"0.1em"}}>
                                {imageState ===REQUEST_STATUS_SUCCESS &&
                                <AnnotationBrowser2  viewportMode={2}
                                                     imageState={imageState}
                                                     imageData={imageData}
                                                     annotationData={this.props.data}
                                                     addRow = {this.props.addAnnotation}
                                                     overlayOpacity = {overlayOpacity}
                                                     fontSize = {fontSize}
                                                     markerSize = {markerSize}
                                                     cis = {cis}
                                                     onCisChange = {(cis)=>this.setState({cis:cis})}
                                                     linkViewers = {linkViewers}
                                                     smoothing = {smoothing}
                                                     aligned={aligned}
                                    // initialSlice={20}
                                />}
                            </div>
                        </div>
                    </AnnotationToolPortal>)}
                {!showWindowPortal && (
                    <div className="ui-g-12">
                <div className="ui-g-4" style={{padding:"0.1em"}}>
                    {imageState ===REQUEST_STATUS_SUCCESS &&
                    <AnnotationBrowser2  viewportMode={0}
                                         imageState={imageState}
                                         imageData={imageData}
                                         annotationData={this.props.data}
                                         addRow = {this.props.addAnnotation}
                                         overlayOpacity = {overlayOpacity}
                                         fontSize = {fontSize}
                                         markerSize = {markerSize}
                                         cis = {cis}
                                         onCisChange = {(cis)=>this.setState({cis:cis})}
                                         linkViewers = {linkViewers}
                                         smoothing = {smoothing}
                                         aligned={aligned}
                        // initialSlice={20}
                    />}

                </div>
                <div className="ui-g-4" style={{padding:"0.1em"}}>
                    {imageState ===REQUEST_STATUS_SUCCESS &&
                    <AnnotationBrowser2  viewportMode={1}
                                         imageState={imageState}
                                         imageData={imageData}
                                         annotationData={this.props.data}
                                         addRow = {this.props.addAnnotation}
                                         overlayOpacity = {overlayOpacity}
                                         fontSize = {fontSize}
                                         markerSize = {markerSize}
                                         cis = {cis}
                                         onCisChange = {(cis)=>this.setState({cis:cis})}
                                         linkViewers = {linkViewers}
                                         smoothing = {smoothing}
                                         aligned={aligned}
                        // initialSlice={20}
                    />}
                </div>
                <div className="ui-g-4" style={{padding:"0.1em"}}>
                    {imageState ===REQUEST_STATUS_SUCCESS &&
                    <AnnotationBrowser2  viewportMode={2}
                                         imageState={imageState}
                                         imageData={imageData}
                                         annotationData={this.props.data}
                                         addRow = {this.props.addAnnotation}
                                         overlayOpacity = {overlayOpacity}
                                         fontSize = {fontSize}
                                         markerSize = {markerSize}
                                         cis = {cis}
                                         onCisChange = {(cis)=>this.setState({cis:cis})}
                                         linkViewers = {linkViewers}
                                         smoothing = {smoothing}
                                         aligned={aligned}
                        // initialSlice={20}
                    />}
                </div>
                    </div>)}
                <Sidebar visible={sidebarVisible}
                         style={{height:"250px"}}
                         position="bottom"
                         baseZIndex={1}
                         modal={false}
                         dismissable={false}
                         onHide={() => this.setState({sidebarVisible: false})}>
                    <AnnotationTable {...this.props}
                        viewCallback={this.viewCallback}/>
                </Sidebar>
            </div>
        )
    }

}
export default withRouter(AnnotationTool);

AnnotationTool.propTypes = {
    columns: PropTypes.array.isRequired,
    imageData:PropTypes.object,
    imageState:PropTypes.string,
    data: PropTypes.array.isRequired,
    getAnnotations: PropTypes.func.isRequired,
    updateAnnotationData:PropTypes.func.isRequired,
    rmCallback:PropTypes.func.isRequired,
    getMRIData : PropTypes.func.isRequired,
    clearAll : PropTypes.func.isRequired,
    addAnnotation : PropTypes.func.isRequired,
    setActiveAnnotation:PropTypes.func.isRequired
};



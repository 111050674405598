import {
    SKILL_LIST_FAIL,
    SKILL_LIST_REQUEST, SKILL_LIST_SUCCESS, SET_SKILL_SORTER,
    SET_SKILL_VISIBILITY_FILTER
} from '../action/actionType'
import {combineReducers} from "redux";



const setVisibilityFilter = (state, action) => {

    return Object.assign({}, state, {
        visibilityFilter: action.filter,
    });

};

const setSorter = (state, action) => {

    return Object.assign({}, state, {
        sorter: action.sorter,
    });

};


const skillListReducer = (state = {skillsState: '', skills: [], visibilityFilter:'SHOW_ALL', sorter:'SORT_BY_NAME_DESC'}, action) => {
    switch (action.type) {
        case SKILL_LIST_REQUEST:
            return Object.assign({}, state, {
                skillsState: 'requested',
            });
        case SKILL_LIST_SUCCESS:
            return Object.assign({}, state, {
                skillsState: 'success',
                skills: action.skills
            });

        case SKILL_LIST_FAIL:
            return Object.assign({}, state, {
                skillsState: action.err,
            });

        case SET_SKILL_VISIBILITY_FILTER:
            return setVisibilityFilter(state, action);

        case SET_SKILL_SORTER:
            return setSorter(state, action);

        default:
            return state;
    }
};

const skillReducer = (state = {skillState: '', skill: {}}, action) => {
    switch (action.type) {
        case 'SKILL_REQUEST':
            return Object.assign({}, state, {
                skillState: 'requested'
            });
        case 'SKILL_FAIL':
            console.log('Project request failed');
            return Object.assign({}, state, {
                skillState: 'failure',
                skill: null
            });
        case 'SKILL_SUCCESS':
            console.log('Project request succeeded');
            return Object.assign({}, state, {
                skillState: 'success',
                skill: action.skill
            });
        default:
            return state;
    }
};

export const SkillReducer = combineReducers({
    skills: skillListReducer,
    skill: skillReducer
});
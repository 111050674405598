import React from "react";
import PropTypes from "prop-types";
import Link from "react-router-dom/es/Link";
import {withRouter} from "react-router";

/**"Dumb" renderer for "List" mode for Experiments.
 * Required since experiment attributes need to be processed.
 *
 * @param item - mandatory component if used with ItemList container
 * @param history - provided by withRouter HOC wrapper
 * @returns {*}
 * @constructor
 */
const ExperimentItemListElement = ({item,history,onRemoveClick}) => {

    let dateToDisplay =  new Date(item.modificationDate).toLocaleString();
    let people = (item['co-investigators']!=null)?item['co-investigators'].length:0;

    let move = ()=>{history.push('/project/'+item.projectId+'/experiment/'+item.uuid)};
    return (
            <div className="experimentListElement">
                <div className="ui-g-5" onClick={move}>
                        {item.question}
                </div>
                <div className="ui-g-2" onClick={move}>{people}</div>
                <div className="ui-g-2" onClick={move}>{item.status}</div>
                <div className="ui-g-2" onClick={move}>{dateToDisplay}</div>
                <div className="ui-g-1" ><i className="fa fa-trash" title="Remove experiment" style={{fontSize:"large"}}
                                            onClick={()=>{onRemoveClick(item)}}/></div>
            </div>
    )
};
export default withRouter(ExperimentItemListElement);

ExperimentItemListElement.propTypes = {
    item: PropTypes.object.isRequired
};

import React from 'react'
import {withRouter} from "react-router-dom";


/**
 * "Dumb" component for starting creation new Workflow.
 *
 */

export const WorkflowChoiceView = ({initExperiment})=> {

    return (
        <div>
            <div className="expChoiceClose"/>
            <h1 align="center" style={{margin: '20px auto'}}>
                How would you like to create your Workflow?
            </h1>
            <div className="rectExpContainer">
                <div className="rectExp"  onClick={initExperiment}>
                    <div className="rectExpIntNew"/>
                    <div className="rectExpInt">
                        A brand new Workflow
                    </div>
                </div>
                <div className="rectExp">
                    <div className="rectExpIntTemp"/>
                    <div className="rectExpInt">
                        From template
                    </div>
                </div>
                <div className="rectExp">
                    <div className="rectExpIntDup"/>
                    <div className="rectExpInt">
                        <div>
                            <span> From existing Workflow </span>
                            <br/>
                            <span style={{fontSize:'small',marginLeft:'2em'}}>45 workflows available </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
    // }
};



export default withRouter(WorkflowChoiceView);


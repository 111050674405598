import { combineReducers } from 'redux'

import AuthReducer from './authentication/reducers/AuthReducer.jsx'
import VisuReducer from './visualization/VisuReducer'
import ExpDesignReducer from './expDesign/ExpDesignReducer'
import { ProjectReducer } from './project/reducer/ProjectReducer'
import {ActivityReducer} from "./dashboard/reducer/ActivityReducer";
import {SkillReducer} from "./dashboard/reducer/SkillReducer";
import {PresentationReducer} from "./livePresenter/reducer/PresentationReducer";

const RootReducer = combineReducers({
	auth: AuthReducer,
	visu: VisuReducer,
	expDesign: ExpDesignReducer,
	project: ProjectReducer,
    activity:ActivityReducer,
	skill:SkillReducer,
	presentation: PresentationReducer
});

export default RootReducer;
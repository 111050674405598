import {createProject, getProjectList} from "../action/ProjectAction";
import {connect} from "react-redux";
import ProjectList from "../component/ProjectList";
import ProjectNamesList from "../component/ProjectNamesList";
import ProjectTable from "../component/ProjectTable";
import CurrentProjectsList from "../../authentication/component/CurrentProjectsList";


const mapStateToProps = state => {
    return {
         projects: state.project.projects.projects
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getProjects: () => {
            dispatch(getProjectList())
        },
        addProject:(proj)=>{
            dispatch(createProject(proj));
        }
    }
};

const projectContainerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerProjectList = projectContainerCreator(ProjectList);
export const ContainerProjectNamesList = projectContainerCreator(ProjectNamesList);
export const ContainerCurrentProjectsList = projectContainerCreator(CurrentProjectsList);
export const ContainerProjectTable = projectContainerCreator(ProjectTable);

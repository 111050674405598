import React from 'react'
import {connect} from 'react-redux'
import {Sidebar} from 'primereact/components/sidebar/Sidebar'
import {Button} from 'primereact/components/button/Button'
import {InputText} from 'primereact/components/inputtext/InputText'

import {getUserList} from '../action/UserAction'
import UserItem from './UserItem'
import SkillSelector from './SkillSelector'


class UserSelection extends React.Component {
    constructor(){
        super();
        this.state = {
            inputSkillList: [<SkillSelector key={0}/>],
        };

    }


    componentDidMount(){
        console.log("UserSelection component will ComponentWillMount");
        const { getUsers } = this.props;
        getUsers();

    }
    // componentWillUnmount(){
    //     this.props.onChange;
    // }

    addFilterSkill (){
        this.setState({
            inputSkillList: this.state.inputSkillList.concat(<SkillSelector key={this.state.inputSkillList.length}/>)
        })

    }



    render() {
        const { users, visible, hideMe, showMe } = this.props;
        console.log('userselection visible ', this.props.visible);
        const userNodes = users.map((user) => <UserItem key={user.lastName} item={user}/>);
        return (

            <Sidebar visible={this.props.visible} fullScreen={true} baseZIndex={1000000} onHide={this.props.hideMe} onShow={this.props.showMe} >
                <h1 style={{fontWeight:'normal'}}>Select contributors</h1>
                <div className="ui-g">
                    <div className="ui-inputgroup">
                        <span className="ui-inputgroup-addon"><i className="fa fa-user"></i></span>
                        <InputText placeholder="Username"/>
                        <Button icon="fa-search" cornerStyleClass="ui-button-secondary"/>
                    </div>

                </div>
                <br/>

                <Button type="button" onClick={this.addFilterSkill.bind(this)} label="Add skill filter" className="ui-button-primary"/>
                <br/>
                { this.state.inputSkillList }

                <div className="ui-g">
                    { userNodes }
                </div>
                <Button type="button" onClick={this.props.hideMe} label="Save" className="ui-button-success"/>
                <Button type="button" onClick={this.props.hideMe} label="Cancel" className="ui-button-secondary"/>
            </Sidebar>

        )
    }

}


const mapStateToProps = state => {
    console.log("state users in workflowSelection ", state.expDesign.users.users);
    return {
        users: state.expDesign.users.users
    }
}

const mapDispatchToProps = dispatch => {
    return {
        getUsers: () => {dispatch(getUserList())}
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps)(UserSelection);      



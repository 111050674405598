import {HOST_URL} from "../../../Constants";
import {
    PRESENTATION_LIST_FAIL, PRESENTATION_LIST_REQUEST, PRESENTATION_LIST_SUCCESS, PRESENTATION_CREATE, PRESENTATION_UPDATE, SLIDE_LIST_FAIL, 
    SLIDE_LIST_REQUEST, SLIDE_LIST_SUCCESS, SLIDE_CREATE, SLIDE_UPDATE
} from "./actionType";
import store from '../.././Store';
import axios from "axios/index";


const presentation_list = {
    data: [
        {
            "name": "U Bordeaux",
            "status": "Public",
            "id": "abc",
            "time_min": 1,
            "time_sec": 2
        },
        {
            "name": "Brigham",
            "status": "Private",
            "id": "def",
            "time_min": 1,
            "time_sec": 2
        },
        {
            "name": "Fenway Park",
            "status": "Public",
            "id": "pqr",
            "time_min": 1,
            "time_sec": 2
        }
    ]
}

const id_details = {
    "slide_contents": [
        {
            "pos": 1,
            "type": "Video",
            "show": true,
            "title": "Introduction to SPINE",
            "desc": "CNI - SPINE",
            "vid_id": "a7qNsmHc3sU",
            "time_min": 0,
            "time_sec": 10
        },
        {
            "pos": 2,
            "type": "Image",
            "show": true,
            "title": "Experiment 24 - Volume of lesions",
            "desc": "Brain lesions are a type of damage to any part of brain",
            "link": "https://www.medpagetoday.com/upload/2013/2/19/37424.jpg",
            "time_min": 0,
            "time_sec": 2
        },
        {
            "pos": 3,
            "type": "Website",
            "show": true,
            "title": "CNI",
            "link": "https://cni.bwh.harvard.edu/",
            "time_min": 0,
            "time_sec": 10
        },
        {
            "pos": 4,
            "type": "Experiment",
            "show": true,
            "title": "Experiment 25 - Corpus Calossum",
            "time_min": 0,
            "time_sec": 10
        },
        {
            "pos": 5,
            "type": "Website",
            "show": true,
            "title": "SPINE in classrooms",
            "link": "https://www.microsoft.com",
            "time_min": 0,
            "time_sec": 10
        }
    ]
}


//****************************************************************************
//
//                                Actions
//
//****************************************************************************


// Presentation actions *************************************************************

const requestPresentationList = () => ({
    type: PRESENTATION_LIST_REQUEST
});


const errorPresentationList = err => ({
    type: PRESENTATION_LIST_FAIL,
    err
});

const successPresentationList = (presentation_list) => ({
    type: PRESENTATION_LIST_SUCCESS,
    presentations: presentation_list
});


/* TODO Bind with real data instead mockup */
// This is an action creator
export const getPresentationList = () => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        axios.get(HOST_URL + '/presentation')
            .then(response => {

                console.log('PresentationAction.js :: getPresentationList :: response ::', response)
                if (response.status !== 200) {
                    dispatch(errorPresentationList(err))
                } else {
                    dispatch(successPresentationList(response.data))
                }
            }).catch(error => {
            console.log('project err:', error);
            dispatch(errorPresentationList(error))
        });
    }
};


// Slide actions *************************************************************

const requestSlideList = () => ({
    type: SLIDE_LIST_REQUEST
});


const errorSlideList = err => ({
    type: SLIDE_LIST_FAIL,
    err
});

const successSlideList = (slide_list) => ({
    type: SLIDE_LIST_SUCCESS,
    slides: slide_list
});

const createSlide = (slides) => ({
    type: SLIDE_CREATE,
    slides: slides
});

/* TODO Bind with real data instead mockup */
// This is an action creator
export const getSlideList = (id) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        axios.get(HOST_URL + '/presentation/'+ id+'/slide')
            .then(response => {
                console.log('PresentationAction.js :: getSlideList :: response ::', response)
                if (response.status !== 200) {
                    dispatch(errorSlideList(err))
                } else {
                    dispatch(successSlideList(response.data))
                }
            }).catch(error => {
            console.log('project err:', error);
            dispatch(errorPresentationList(error))
        });
    }
};

export const addSlide = (slide) => {
    return dispatch => {
        let temp = store.getState().presentation.slides.slide_list.slice(0);
        temp.push(slide);
        dispatch (createSlide(temp))
    }
};
import {createSelector} from 'reselect';
import {datePropertyComparator, numberPropertyComparator, stringPropertyComparator} from "../../helpers/comparators";
import {matchRule} from "../../helpers/regulars";

export const getVisibilityFilter = (state) => state.visu.studies.visibilityFilter;
export const getSorter = (state) => state.visu.studies.sorter;
const getCohorts = (state) => state.visu.studies.studies;

export const getVisibleCohorts = createSelector(
    [ getVisibilityFilter, getSorter, getCohorts ],
    (visibilityFilter, sorter, cohorts) => {
        return getSortedCohorts(sorter,getFilteredCohorts(visibilityFilter,cohorts));
    }
);

/**
 * Helper  for filtering cohorts.
 * @param filter
 * @param cohorts
 * @returns {*}
 */
const getFilteredCohorts = (filter, cohorts) => {
    if ( filter==null || filter ==='' ) return cohorts;
    // return cohorts.filter(t => t.name.toLowerCase().includes(filter.toLowerCase()));
    return cohorts.filter(t => matchRule(t.name.toLowerCase(),filter.toLowerCase()));

};



/** Helper used for sorting cohorts.
 *
 * @param sorter
 * @param cohorts
 * @returns {void | * | Uint8Array | Uint16Array | Int16Array | Float32Array} sorted cohorts
 */
const getSortedCohorts = (sorter, cohorts) => {
    switch (sorter) {
        case 'SORT_BY_NAME_DESC':
            return cohorts.sort(stringPropertyComparator('name'));
        case 'SORT_BY_NAME_ASC':
            return cohorts.sort(stringPropertyComparator('name')).reverse();
        case 'SORT_BY_SUBJECT_NUMBER_DESC':
            return cohorts.sort(numberPropertyComparator('subjects.count')).reverse();
        case 'SORT_BY_SUBJECT_NUMBER_ASC':
            return cohorts.sort(numberPropertyComparator('subjects.count'));
    }
};

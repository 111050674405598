import {
    EXPDESIGN_DATA_SUBJECTS,
    EXPDESIGN_DATA_STUDIES, EXPDESIGN_DATA_DATASET_SUCCESS, EXPDESIGN_DATA_DATASET_FAIL,
    EXPDESIGN_DATA_SELECTED_STUDIES, EXPDESIGN_DATA_DATASET_REQUEST, EXPDESIGN_VARIABLE_PLOT_REQUEST,
    EXPDESIGN_VARIABLE_PLOT_FAIL, EXPDESIGN_VARIABLE_PLOT_SUCCESS, EXPDESIGN_VARIABLE_STATS_REQUEST,
    EXPDESIGN_VARIABLE_STATS_FAIL, EXPDESIGN_VARIABLE_STATS_SUCCESS,
    EXPDESIGN_DATA_EXCLUSION_CRITERIA_UPDATE, EXPDESIGN_DATA_INCLUSION_CRITERIA_UPDATE
} from './actionType'
import store from "../../Store";
import NetworkService from "../../helpers/io/NetworkService";
import {
    EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA,
    EXPERIMENT_PROPERTY_NAME__INCLUSION_CRITERIA, EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA,
    EXPERIMENT_PROPERTY_NAME__UUID
} from "../../../Constants";
import {clearDataset, saveExperimentActionCreator} from "./StatisticalModelAction";

export const selectSubjects = selectedSubjects => ({
	type: EXPDESIGN_DATA_SUBJECTS,
	selectedSubjects
});

export const selectStudies = selectedStudies => ({
	type: EXPDESIGN_DATA_STUDIES,
	selectedStudies
});


export const updateSelectedStudies = selected => ({
    type: EXPDESIGN_DATA_SELECTED_STUDIES,
    selected
});


/**
 * @param data {Object} containing 2 arrays (selected studies ans selected subjects)
 * @return action {Object} the above functions
 */
export const saveDataSelection = data => (
	dispatch => {
	    console.log('will dispatch',dispatch);
		// dispatch(selectStudies(data.selectedStudies));
		dispatch(selectSubjects(data.selectedSubjects));
        dispatch(updateSelectedStudies(data.selectedStudies));
        dispatch(clearDataset());
        // console.log('state after dispatch',getState());
	}
);

const requestDataset = () => ({
    type: EXPDESIGN_DATA_DATASET_REQUEST,
});

const failDataset = (err) => ({
    type: EXPDESIGN_DATA_DATASET_FAIL,
    err
});

const successDataset = (data) => ({
    type: EXPDESIGN_DATA_DATASET_SUCCESS,
    data
});

// /**
//  * Getting values of variables (dataset).
//  * This is the place to replace real data with fake ones.
//  * @deprecated - it does not take cohorts into account TO REMOVE in next iteration
//  * @return {[type]}
//  */
// export const getDataset = (variables) => {
//     return dispatch => {
//         dispatch(requestDataset());
//         NetworkService.getNVariables(variables,store.getState().auth.token_bearer)
//             .then(data => {
//                 data.map((s) => {
//                     // s['orFilename']="9a2510f8daeb1c8b38bf5db8a4003d57";
//                     // s['ovFilename']="9a2510f8daeb1c8b38bf5db8a4003d58";
//                     s.variablesValues.forEach((v)=>v.variableValue= Number(v.variableValue));
//                     // s.groups=s.visitLabel;
//                     // s.groups2=s.subjectId;
//                     // s.groups3=s.visitDate;
//                 });
//                 dispatch(successDataset(data))
//             })
//             .catch(error => {
//                 console.log('DataSelectionAction :: getDataset :: error:', error);
//                 dispatch(failDataset(error))
//             });
//     }
// };

/**
 * Getting values of variables (dataset).
 * This is the place to replace real data with fake ones.
 * @return {function(*)}
 */
export const getDataset = (experiment) => {
    return dispatch => {
        dispatch(requestDataset());

        NetworkService.getDataset(experiment,store.getState().auth.token_bearer)
            .then(data => {
                data.map((s) => {
                    s.variablesValues.forEach((v)=>v.variableValue= Number(v.variableValue));
                });
                dispatch(successDataset(data))
            })
            .catch(error => {
                console.log('DataSelectionAction :: getDatasetFromCohorts :: error:', error);
                alert('There was an error in dataset.'+ error);
                dispatch(failDataset(error))
            });
    }
};


/**
 *
 * @param plotId  variable index for which plot is prepared
 * @returns {{type: *, variableIndex: *}}
 */
const requestPlot = (plotId) => ({
    type: EXPDESIGN_VARIABLE_PLOT_REQUEST,
    plotId
});

/**
 * @param err error to handle
 * @param plotId variable index for which histogram is prepared
 * @returns {{type: *, variableIndex: *}}
 */
const failPlot = (err,plotId) => ({
    type: EXPDESIGN_VARIABLE_PLOT_FAIL,
    err,
    plotId
});

/**
 * @param data - binary data of histogram image (PNG) to assign to data
 * @param plotId variable index for which histogram is prepared
 * @returns {{type: *, variableId: *}}
 */
const successPlot = (data,plotId) => ({
    type: EXPDESIGN_VARIABLE_PLOT_SUCCESS,
    data,
    plotId
});

/**
 * Getting histogram image (binary data).
 *
 */
export const getHistogram = (plotId, dataToProcess) => {
    return dispatch => {
        //axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        dispatch(requestPlot(plotId));
        NetworkService.getHistogram(dataToProcess)
            .then(binaryData => {
                dispatch(successPlot(binaryData,plotId))
            })
            .catch(error => {
                console.log('Histogram error:', error);
                dispatch(failPlot(error,plotId))
            });
    }
};

/**
 * Getting histogram image (binary data).
 *
 */
export const getKernel = (plotId, dataToProcess) => {
    return dispatch => {
        //axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        dispatch(requestPlot(plotId));
        NetworkService.getKernelPlot(dataToProcess)
            .then(binaryData => {
                dispatch(successPlot(binaryData,plotId))
            })
            .catch(error => {
                console.log('Kernel plot error:', error);
                dispatch(failPlot(error,plotId))
            });
    }
};

/**
 * Getting histogram image (binary data).
 *
 */
export const getBoxplot = (plotId, dataToProcess) => {
    return dispatch => {
        //axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        dispatch(requestPlot(plotId));
        NetworkService.getBoxPlot(dataToProcess)
            .then(binaryData => {
                dispatch(successPlot(binaryData,plotId))
            })
            .catch(error => {
                console.log('Kernel plot error:', error);
                dispatch(failPlot(error,plotId))
            });
    }
};


/**
 * Getting histogram image (binary data).
 *
 */
export const getGGPlotActionCreator = (plotId) => {
    return dispatch => {
        //axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        dispatch(requestPlot(plotId));
        NetworkService.getGGPlot()
            .then(binaryData => {
                dispatch(successPlot(binaryData,plotId))
            })
            .catch(error => {
                console.log('Kernel plot error:', error);
                dispatch(failPlot(error,plotId))
            });
    }
};

/**
 *
 * @param statsId  variable composite index for which plot is prepared
 * @returns {{type: *, variableIndex: *}}
 */
const requestStats = (statsId) => ({
    type: EXPDESIGN_VARIABLE_STATS_REQUEST,
    statsId
});

/**
 * @param err error to handle
 * @param statsId variable composite index for which histogram is prepared
 * @returns {{type: *, variableIndex: *}}
 */
const failStats = (err,statsId) => ({
    type: EXPDESIGN_VARIABLE_STATS_FAIL,
    err,
    statsId
});

/**
 * @param data - JSON data
 * @param statsId variable index for which histogram is prepared
 * @returns {{type: *, variableId: *}}
 */
const successStats = (data,statsId) => ({
    type: EXPDESIGN_VARIABLE_STATS_SUCCESS,
    data,
    statsId
});

/**
 * Getting histogram image (binary data).
 *
 */
export const getStatisticsActionCreator = (statsId, dataToProcess) => {
    return dispatch => {
        //axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        dispatch(requestStats(statsId));
        NetworkService.getStats(dataToProcess)
            .then(data => {
                dispatch(successStats(data,statsId))
            })
            .catch(error => {
                console.log('Kernel plot error:', error);
                dispatch(failStats(error,statsId))
            });
    }
};


//==================SELECTION CRITERIA===========================

const updateInclusionCriteria = criteria => ({
    type: EXPDESIGN_DATA_INCLUSION_CRITERIA_UPDATE,
    criteria
});

const updateExclusionCriteria = criteria => ({
    type: EXPDESIGN_DATA_EXCLUSION_CRITERIA_UPDATE,
    criteria
});


/**
 * @param data {Object} containing 2 arrays (selected studies ans selected subjects)
 * @return action {Object} the above functions
 */
export const updateSelectionCriteriaActionCreator = (experiment, kind) => (
    dispatch => {
        if (kind==='inc')
            dispatch(updateInclusionCriteria(experiment[EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA][EXPERIMENT_PROPERTY_NAME__INCLUSION_CRITERIA]));
        else
            dispatch(updateExclusionCriteria(experiment[EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA][EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA]));
    }
);


import {connect} from "react-redux";
import Activities from "../components/Activities";
import {getActivityList, setVisibilityFilter, setSorter} from "../action/ActivityAction";
import {
    getSorter, getVisibilityFilter,
    getVisibleActivities
} from "../selectors/ActivitiesSelectors";


const mapStateToProps = state => {
    return {
        activities: getVisibleActivities(state),
        filter: getVisibilityFilter(state),
        sorter: getSorter(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getActivities: () => {
            dispatch(getActivityList())
        },
        onFilterChange: (id) => {
            dispatch(setVisibilityFilter(id))
        },
        onSorterChange: (id)=>{
            dispatch(setSorter(id))
        }
    }
};

const activityContainerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerActivities = activityContainerCreator(Activities);

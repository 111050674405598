import React from "react";
import {
    // ALL_OPERATORS,
    EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR, EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE,
    EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VARIABLE_ID, VARIABLE_PROPERTIES_NAMES
} from "../../../Constants";



const singleCriterionOutputText = (criterion,variables) =>{
    if (criterion[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR]==null || criterion[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VARIABLE_ID] == null || criterion[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE]== null)
    return ''; //if not fully described, does not count

    const variableId = criterion[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VARIABLE_ID];
    const variable = variables.find((v)=>v[VARIABLE_PROPERTIES_NAMES.UUID]===variableId);
    let variableType = variable[VARIABLE_PROPERTIES_NAMES.VARIABLE_TYPE];

    const operator = [].find((c) => c.value === criterion[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR]).label; //looking for the label of operator
    const val = criterion[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE];
    return variable[VARIABLE_PROPERTIES_NAMES.NAME] +' '+ operator + ' '+ val;
};


const criteriaOutputText = (expression,variables)=>{
    if (typeof expression.criteria !== 'undefined' && expression.criteria.length > 0)
    return expression.criteria.map((s)=>singleCriterionOutputText(s,variables)).reduce((lf,rg)=>{return lf + " "+expression.logicalOperator +" " + rg});
    else return "";
};

export const SingleExpressionOutputText = (expression,variables) =>{
    const crits = criteriaOutputText(expression,variables);
    let expressions = '';
    if (expression.expressions != null && expression.expressions.length>0)
    expressions =" "+expression.logicalOperator +" (" +  expression.expressions.map((e)=>SingleExpressionOutputText(e,variables)).reduce((lf,rg)=>{return lf + " "+expression.logicalOperator +" " + rg}) + ") ";
    return crits + expressions;
};


/**
 * Dumb component with template for displaying Selection criteria.
 * @returns {*}
 */
export const SelectionCriteriaOutputText = ({inc,exc,visu}) => {
    const variables = visu.variables.variables;
    const inclusions = (inc ==null || inc.criteria.length===0)?'':' Inclusion criteria:'+ SingleExpressionOutputText(inc,variables);
    const exclusions = (exc ==null || exc.criteria.length===0)?'':' Exclusion criteria:'+ SingleExpressionOutputText(exc,variables);
    return (
        <div>
            <div>
                {inclusions}
            </div>
            <div>
                {exclusions}
            </div>
        </div>
    );
};



var mockUsers = [{
	firstName: "Charles",
	lastName: "Guttmann"
	},{
	firstName: "Miklos",
	lastName: "Palotai"
	},
];

import axios from 'axios'

import {
	USER_LIST_REQUEST,
	USER_LIST_FAIL,
	USER_LIST_SUCCESS
} from './actionType'


const requestUserList = () => ({
	type: USER_LIST_REQUEST
});

const errorUserList = (err) => ({
	type: USER_LIST_FAIL,
	err
});

const successUserList = (users) => ({
	type: USER_LIST_SUCCESS,
	users
});

//TODOOOOOOOOOOOOOOOOOOOOOOOOOOOOO CONNECT TO DB
// Get user list (local version)
export const getUserList = () => {
	console.log('userList');
	return dispatch => {
		dispatch(successUserList(mockUsers));
	}
}

//Get users
// export const getUserList = () => {
// 	return dispatch => {
// 		dispatch (requestUserList())
// 		return axios.get('http://localhost:3000/users'
// 		).then(response => {
// 			if(response.statusCode !== 200) {
// 				dispatch (errorUserList(response.data));
// 				return Promise.reject(response.data);
// 			} else {
// 				dispatch (successUserList(response.data));
// 			}
// 		}).catch(err => dispatch (errorUserList(err)));
// 	};
// };
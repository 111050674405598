import React from "react";
import {Button} from "primereact/components/button/Button";
import SelectionCriteriaItem from "./SelectionCriteriaItem";
import PropTypes from "prop-types";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {
    CATEGORICAL_OPERATOR,
    EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR,
    EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE,
    EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VARIABLE_ID, LOGICAL_OPERATOR, LOGICAL_OPERATOR_INIT,
    REQUEST_STATUS_SUCCESS,
} from "../../../Constants";
import {getUniqueId} from "../../helpers/strings";


/**
 * Component for representing group of criteria (either Inclusion or Exclusion criteria).
 * This is universal container for both subgroups (branches) and individual criteria (leafs).
 */
export default class SelectionCriteriaGroup extends React.Component {

    constructor(props) {
        super(props);
        this.onAdd = this.onAdd.bind(this);
        this.onAddGroup = this.onAddGroup.bind(this);
        this.updateCriterion = this.updateCriterion.bind(this);
        this.removeCriterion = this.removeCriterion.bind(this);
        this.removeGroup = this.removeGroup.bind(this);
        this.updateLogicalOperator = this.updateLogicalOperator.bind(this);
    }

    onAdd() {
        const {type,value,updateCriteria,experiment} = this.props;
        let criterion={};
        criterion['uuid']=getUniqueId();
        criterion[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE]=null;
        criterion[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR]=null;
        criterion[EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VARIABLE_ID]=null;
        criterion['timeFrame'] =
        {
            "targetDate": {
            "distance": 0,
                "units": "YEAR"
        },
            "toleranceWindow": {
            "leftLimit": 0,
                "rightLimit": 0,
                "leftUnits":"YEAR",
                "rightUnits":"YEAR"
        },
            "referenceTimeFrame":"NONE"
        };
        value.criteria.push(criterion);
        updateCriteria(experiment,type);
    }

    onAddGroup() {
        const {type,visu,value,updateCriteria,experiment} = this.props;
        let logicalOperatorOptions = null;
        // try {
        //     if (visu.variables.operatorVariablesState===REQUEST_STATUS_SUCCESS){
        //         logicalOperatorOptions = visu.variables.operatorVariables.filter((el)=>{return el.type === LOGICAL_OPERATOR});
        //     }
        // }
        // catch(err){
        //     return <div/>;
        // }

        value.expressions.push({uuid:getUniqueId(), criteria: [], logicalOperator:LOGICAL_OPERATOR_INIT, expressions: []});
        updateCriteria(experiment,type);
    }

    removeGroup(uuid) {
        const {type,updateCriteria,experiment,parentValue} = this.props;
        let index =   parentValue.expressions.findIndex((el)=>{return el.uuid===uuid});
        parentValue.expressions.splice(index, 1);
        updateCriteria(experiment,type);
    }

    removeCriterion(uuid) {
        const {type,updateCriteria,experiment,value} = this.props;
        let index =  value.criteria.findIndex((el)=>{return el.uuid===uuid});
        value.criteria.splice(index, 1);
        updateCriteria(experiment,type);
    };

    updateCriterion(index, field, val) {
        const {type,updateCriteria,experiment,value} = this.props;
        value.criteria[index][field] = val;
        if (field===EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VARIABLE_ID) {
            value.criteria[index][EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR] = null;
            value.criteria[index][EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE] = null;
        }
        if (field===EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR) {
            value.criteria[index][EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE] = null;
        }
        updateCriteria(experiment,type);
    };

    updateLogicalOperator(val) {
        const {value,experiment,type,updateCriteria} = this.props;
        value.logicalOperator = val;
        updateCriteria(experiment,type);
    };





    render() {
        const {expDesign, visu,type,value,experiment,removeGroupButton} = this.props;
        let logicalOperatorOptions = null;
        try {
            if (visu.variables.operatorVariablesState===REQUEST_STATUS_SUCCESS){
                logicalOperatorOptions = visu.variables.operatorVariables.filter((el)=>{return el.type === LOGICAL_OPERATOR});
            }
        }
        catch(err){
            return <div/>;
        }


        // const ref= (type === 'inc')?this.props.state.inclusionCriteria:this.props.state.exclusionCriteria;
        const leaves = value.criteria.map((el, index) =>
            <SelectionCriteriaItem key={el['uuid']}
                                   index={index}
                                   value={el}
                                   visu={visu}
                                   clbToCriterion={this.updateCriterion}
                                   clbToRemoveCriterion={this.removeCriterion}/>);
        const branches = value.expressions.map((el, index) =>
            <SelectionCriteriaGroup
                                   key={el['uuid']}
                                   index={index}
                                   value={el}
                                   visu={visu}
                                   type={type}
                                   experiment={experiment}
                                   updateCriteria={this.props.updateCriteria}
                                   removeGroupButton={true}
                                   parentValue={this.props.value}
            />);



            return (
                    <div className='criteriaPanel ui-g-12'>
                        <div style={{float: 'right', padding: '.5em'}} className="ui-g-12">
                            <Dropdown value={value.logicalOperator}
                                      options={logicalOperatorOptions}
                                      optionLabel={'label'}
                                      onChange={(e)=>this.updateLogicalOperator(e.value)}
                                      style={{float:"left",width:"4em"}}
                            />
                            <div style={{float:'right'}}>
                            <Button icon="fa fa-plus" onClick={this.onAdd} tooltip="Add criterion"/>
                            <Button icon="fa fa-plus" label="( )" onClick={this.onAddGroup} tooltip="Add group of criteria"/>
                            { removeGroupButton ===true && //can't remove group on the highest level
                            <Button icon="fa fa-minus" label="( )"
                                    onClick={()=>this.removeGroup(value.uuid)} tooltip="Remove group of criteria"/>}
                            </div>
                        </div>
                        {leaves}
                        {branches}
                    </div>

            );

            }

            }

SelectionCriteriaGroup.propTypes = {
    value: PropTypes.object.isRequired,
    type:PropTypes.string.isRequired,
    updateCriteria:PropTypes.func.isRequired
};
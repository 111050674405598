import {connect} from "react-redux";
import Skills from "../components/Skills";
import {getSkillList} from "../action/SkillAction";
import {getSorter, getVisibilityFilter} from "../selectors/SkillsSelectors";
import {getVisibleSkills} from "../selectors/SkillsSelectors";
import {setSorter, setVisibilityFilter} from "../action/SkillAction";


const mapStateToProps = state => {
    return {
        skills: getVisibleSkills(state),
        filter: getVisibilityFilter(state),
        sorter: getSorter(state)
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getSkills: () => {
            dispatch(getSkillList())
        },
        onFilterChange: (id) => {
            dispatch(setVisibilityFilter(id))
        },
        onSorterChange: (id)=>{
            dispatch(setSorter(id))
        }
    }
};

const projectContainerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerSkills = projectContainerCreator(Skills);

import React from "react";
import {VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL} from "../../../Constants";
import {ScrollPanel} from "primereact/components/scrollpanel/ScrollPanel";


export const VariableDescription = ({metaType,description,variableType, statisticalType, units, minValue,  maxValue, options,
                                    format, measurement, roi}) => {

    const title= "ui-md-4";
    const desc= "ui-md-8";
    return (

            <div className="ui-g" style={{textAlign:'left'}}>
                <div className={title}>Type: </div>
                <div className={desc} style={{fontWeight:'bold'}}>{metaType===VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL?"Clinical":"Imaging"}</div>

                <div className={title}>Representation: </div>
                <div className={desc} style={{fontWeight:'bold'}}>{variableType}, {statisticalType}</div>
                {/*<div className={title}>Scale of measurement  </div>*/}
                {/*<div className={desc} style={{fontWeight:'bold'}}></div>*/}
                {/*<div className={title}>Unit  </div>*/}
                {/*<div className={desc} style={{fontWeight:'bold'}}>{units}</div>*/}

                <div className={title}>Range </div>
                <div className={desc} style={{fontWeight:'bold'}}>[{minValue}, {maxValue?maxValue:" - "}] [{units}] </div>

                { options&&
                    <React.Fragment>
                        <div className={title}>Options</div>
                        <div className={desc} style={{fontWeight: 'bold'}}>{options}</div>
                    </React.Fragment>
                }
                { format&&
                    <React.Fragment>
                        <div className={title}>Format</div>
                        <div className={desc} style={{fontWeight: 'bold'}}>{format}</div>
                    </React.Fragment>
                }
                { measurement&&
                <React.Fragment>
                    <div className={title}>Measurement</div>
                    <div className={desc} style={{fontWeight: 'bold'}}>{measurement}</div>
                </React.Fragment>
                }
                { roi&&
                <React.Fragment>
                    <div className={title}>Region Of Interest</div>
                    <div className={desc} style={{fontWeight: 'bold'}}>{roi}</div>
                </React.Fragment>
                }
                <div className="ui-md-12" >
                <ScrollPanel style={{width: '100%', height: '4em'}}>
                {description}
                </ScrollPanel>
                </div>
            </div>

    );
};
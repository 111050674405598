import React from "react";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {InputText} from "primereact/components/inputtext/InputText";
import {Spinner} from "primereact/components/spinner/Spinner";

/**
 * Represents absolute criterion for Timeframe.
 * @param variable
 * @param item
 * @param onChangeCriterion
 * @returns {*}
 * @constructor
 */
export const  AbsoluteTimeFrameCriterion=({variable, item, onChangeCriterion})=>{

    const options = [{label:"days",value:"DAY"},{label:"months",value:"MONTH"},{label:"years",value:"YEAR"}];

    /** Unwrapping nested properties structure   */
    const onChange = (group,property,value)=>{

        let cpItem={
            "targetDate":Object.assign({},item.targetDate),
            "toleranceWindow":Object.assign({},item.toleranceWindow),
        };

        cpItem[group][property]=value;
        onChangeCriterion(variable, cpItem);
    };

    if (item !=null)
        return  <div className="ui-g-12 tfRow">
            <div className="ui-g-3 ">
                    <InputText className="tfCriteriaInput"
                               title="Variable name"
                               readOnly={true}
                               disabled={true}
                               value={variable}
                               style={{width:'20em'}}/>
            </div>
            <div className="ui-g-3 ">

                    <Spinner   min={0}
                               step={1}
                               maxlength={3}
                               size={3}
                               // className="tfCriteriaInput"
                               title="Lower tolerance (before)"
                               value={item['toleranceWindow']['leftLimit']}
                               onChange={(e)=>{onChange('toleranceWindow', 'leftLimit',e.value)}}/>
                    < Dropdown style={{width:'80px'}}
                               placeholder="Select unit"
                               value={item['toleranceWindow']['leftUnits']}
                               onChange={(e)=>{onChange('toleranceWindow', 'leftUnits',e.value)}}
                               options={options}/>
            </div>
            <div className="ui-g-3 ">

                    <Spinner   step={1}
                               maxlength={3}
                               size={3}
                               // className="tfCriteriaInput"
                               title="Target date"
                               value={item['targetDate']['distance']}
                               onChange={(e)=>{onChange('targetDate', 'distance',e.value)}}/>
                    < Dropdown style={{width:'80px'}}
                               placeholder="Select unit"
                               value={item['targetDate']['units']}
                               onChange={(e)=>{onChange('targetDate', 'units',e.value)}}
                               options={options}/>
            </div>
            <div className="ui-g-3 ">

                    <Spinner   min={0}
                               step={1}
                               maxlength={3}
                               size={3}
                               // keyfilter="pnum"
                               // className="tfCriteriaInput"
                               title="Upper tolerance (after)"
                               value={item['toleranceWindow']['rightLimit']}
                               onChange={(e)=>{onChange('toleranceWindow', 'rightLimit',e.value)}}/>
                    < Dropdown style={{width:'80px'}}
                               placeholder="Select unit"
                               value={item['toleranceWindow']['rightUnits']}
                               onChange={(e)=>{onChange('toleranceWindow', 'rightUnits',e.value)}}
                               options={options}/>
            </div>
        </div>;
    else return null;
};

import { EXPDESIGN_WIZARD_UPDATE_STEP} from './actionType'

export const setStep = setStep => ({
    type: EXPDESIGN_WIZARD_UPDATE_STEP,
    setStep
});

export const updateWizardSession = experimentId => ({
    type: EXPDESIGN_WIZARD_UPDATE_STEP,
    experimentId
});



import React from "react";
import {ListSwitchType} from "../../root/ListTypeSwitch";
import {SplitButton} from "primereact/components/splitbutton/SplitButton";
import {withRouter} from 'react-router-dom'
import {Dialog} from "primereact/components/dialog/Dialog";
import WorkflowChoiceView from "./WorkflowChoiceView";
import PropTypes from "prop-types";


/** Stateful component for menu in Workflow Selection view.
 * @param clbList
 * @param isList
 * @returns {*}
 * @constructor
 */
class WorkflowPanelMenu extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            workflowDialogVisibility:false
        }
        ;
        this.onDialogShow = this.onDialogShow.bind(this);
        this.onDialogHide = this.onDialogHide.bind(this);
    }

    /**
     * show new dialog window
     */
    onDialogShow(){
        this.setState({workflowDialogVisibility:true});
    }
    onDialogHide(){
        this.setState({workflowDialogVisibility:false});
    }

    render(){
        const {listModeClbk,isList,variable} = this.props;

        let items =  [
            {label: 'From scratch', icon: 'fa fa-plus', command: null},
            {label: 'From template', icon: 'fa fa-book', command: () => { console.warn("New workflow from template not yet implemented")}},
            {label: 'From duplicate', icon: 'fa fa-copy', command:() => {  console.warn("New workflow from duplicate not yet implemented")}}
        ];
        return(
            <div className="projectDetailsMenu">
                <div style={{padding:"16px"}}>
                    <div className="selectCohortsTitle" style={{float:'left'}}> {variable.name} > Select workflow </div>
                    <SplitButton icon="fa fa-plus" label="New workflow" id="newWorkflow"
                                 model={items}  style={{float: 'right'}} onClick={this.onDialogShow}/>
                    <ListSwitchType clb={listModeClbk} isList={isList}/>
                </div>
                <Dialog header={"New"}
                        visible={this.state.workflowDialogVisibility}
                        modal={true}
                        onHide={this.onDialogHide}
                        style={{width:'60vw'}}>
                    <WorkflowChoiceView {...this.props} />

                </Dialog>
            </div>
        )}
}

WorkflowPanelMenu.propTypes={
    listModeClbk:PropTypes.func.isRequired, //callback to list switch
    isList:PropTypes.bool.isRequired, // whether grid or list
    variable:PropTypes.object //variable for which workflw is set
};
export default withRouter(WorkflowPanelMenu);
import React from 'react'
import {withRouter} from "react-router-dom";
import {Tooltip} from "primereact/components/tooltip/Tooltip";


/**
 * "Dumb" component for model linear model assumptions.
 *
 * Multivariate normality
 * No or little multicollinearity
 * No auto-correlation
 * Homoscedasticity
 */

export const LinearModelAssumptionsPanel = ({results})=> {
    const NORMALITY_DESCRIPTION="The linear regression analysis requires all variables to " +
        "be multivariate normal. This assumption can be checked with a histogram or a Q-Q-Plot.  \n" +
        "                Normality here is checked with the Kolmogorov-Smirnov test";


    const COLINEARITY_DESCRIPTION="Multicollinearity occurs when the independent variables are too highly " +
        "correlated with each other. Linear regression assumes that there is little or no multicollinearity in the data. ";

    const AUTOCORRELATION_DESCRIPTION="Autocorrelation occurs when the residuals are not independent from each other. Linear regression analysis requires that there is little or no autocorrelation in the data. ";
    const HOMOSCEDASTICITY_DESCRIPTION="In  linear regression model data  must be homoscedastic meaning the residuals are equal across the regression line." +
        " Homoscedasticity here is checked with Goldfeld-Quandt Test.";
    return (
        <div>
            <table id="statisticalCalculatorTable" >
                <tbody>
                <tr>
                    <th id="multivariateNormalityHeader">Multivariate normality</th>
                    <th id="colinearityHeader">Multicollinearity</th>
                    <th id="autocorrelationHeader">Auto-correlations</th>
                    <th id="homoscedasticityHeader">Homoscedasticity</th>
                </tr>
                <tr>
                    <td>passed</td>
                    <td>passed</td>
                    <td>not passed</td>
                    <td>passed</td>
                </tr>
                </tbody>
                <Tooltip for="#multivariateNormalityHeader" title={NORMALITY_DESCRIPTION} tooltipPosition="right" tooltipEvent="hover"/>
                <Tooltip for="#colinearityHeader" title={COLINEARITY_DESCRIPTION} tooltipPosition="right" tooltipEvent="hover"/>
                <Tooltip for="#autocorrelationHeader" title={AUTOCORRELATION_DESCRIPTION} tooltipPosition="right" tooltipEvent="hover"/>
                <Tooltip for="#homoscedasticityHeader" title={HOMOSCEDASTICITY_DESCRIPTION} tooltipPosition="right" tooltipEvent="hover"/>
            </table>
        </div>
    );
    // }
};


export default LinearModelAssumptionsPanel;


export const ACTIVITY_LIST_REQUEST = "ACTIVITY_LIST_REQUEST";
export const ACTIVITY_LIST_SUCCESS = "ACTIVITY_LIST_SUCCESS";
export const ACTIVITY_LIST_FAIL = "ACTIVITY_LIST_FAIL";

export const ACTIVITY_REQUEST = "ACTIVITY_REQUEST";
export const ACTIVITY_SUCCESS = "ACTIVITY_SUCCESS";
export const ACTIVITY_FAIL = "ACTIVITY_FAIL";

export const ACTIVITY_CREATE = "ACTIVITY_CREATE";

export const SET_ACTIVITY_VISIBILITY_FILTER = 'SET_ACTIVITY_VISIBILITY_FILTER';

export const SET_ACTIVITY_SORTER = 'SET_ACTIVITY_SORTER';

export const SKILL_LIST_REQUEST = "SKILL_LIST_REQUEST";
export const SKILL_LIST_SUCCESS = "SKILL_LIST_SUCCESS";
export const SKILL_LIST_FAIL = "SKILL_LIST_FAIL";

export const SKILL_REQUEST = "SKILL_REQUEST";
export const SKILL_SUCCESS = "SKILL_SUCCESS";
export const SKILL_FAIL = "SKILL_FAIL";
export const SET_SKILL_VISIBILITY_FILTER = 'SET_SKILL_VISIBILITY_FILTER';
export const SET_SKILL_SORTER = 'SET_SKILL_SORTER';

export const SKILL_CREATE = "SKILL_CREATE";
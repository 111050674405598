import React from 'react'
import {Button} from 'primereact/components/button/Button'
import InputTextWithValidation from "../../helpers/validation/InputTextWithValidation";
import {ruleRunner, run} from "../../helpers/validation/ruleRunner";
import {required} from "../../helpers/validation/rules";
import InputTextareaWithValidation from "../../helpers/validation/InputTextareaWithValidation";
import {expDesignPagesEnum} from "../ExpDesign";
import PropTypes from "prop-types";
import { EXPERIMENT_PROPERTY_NAME__PROJECT_ID} from "../../../Constants";


const fieldValidations = [
    ruleRunner("hypothesis", "Hypothesis", required),
    ruleRunner("question", "Question", required),
    ruleRunner("description", "Background and Significance", required),
];

export default class Overview extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showErrors: false,
            validationErrors: {},
            hasUpdated:false
        };

        this.errorFor = this.errorFor.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFieldChanged = this.handleFieldChanged.bind(this);
        this.handleSubmitNext = this.handleSubmitNext.bind(this);
    }

    componentDidMount() {
        // Run validations on initial state
        let val = run(this.props.experiment, fieldValidations);
        this.setState({validationErrors: val});
    }

     handleChange(event) {
        let state = this.props.experiment;
        state[event.target.id] = event.target.value;
        this.setState({hasUpdated:true});
        this.props.saveGlobalDefinition(state);
        console.log('state Overview: ', state);
        return state;
    }

    errorFor(field) {
        return this.state.validationErrors[field] || "";
    }

    /*wrapper for change handler */
    handleFieldChanged() {
        return (e) => {
            let newState = this.handleChange(e);
            newState.validationErrors = run(newState, fieldValidations);
            this.setState(newState);
        };
    }

    handleSubmitNext() {
        const {nextAndSave} = this.props;

        let val = run(this.props.experiment, fieldValidations);//now this cannot be done through state
        if (!(Object.keys(val).length > 0)) {
            nextAndSave(this.state.hasUpdated);

        } else  this.setState({showErrors: true});
    }

    render() {
        const formItem = {marginTop: "20px"};
        console.log("Overview.js::render::experiment State", this.props.experiment);
        return (
            <div>
               <span className="ui-float-label" style={formItem}>
                 <InputTextWithValidation id="question"
                                             showError={this.state.showErrors}
                                             onFieldChanged={this.handleFieldChanged()}
                                             style={{width: '100%'}}
                                             errorText={this.errorFor("question")}
                                             value={this.props.experiment.question}/>
				 <label htmlFor="question">Question</label>
				</span>
                <span className="ui-float-label" style={formItem}>
				 <InputTextWithValidation id="hypothesis"
                                          showError={this.state.showErrors}
                                          onFieldChanged={this.handleFieldChanged()}
                                          style={{width: '100%'}}
                                          readOnly="true"
                                          errorText={this.errorFor("hypothesis")}
                                          value={this.props.experiment.hypothesis}/>
                     <label htmlFor="hypothesis">Hypothesis</label>
                </span>
                <span className="ui-float-label" style={formItem}>
                <InputTextareaWithValidation id="description"
                                             showError={this.state.showErrors}
                                             onFieldChanged={this.handleFieldChanged()}
                                             style={{width: '100%'}}
                                             errorText={this.errorFor("description")}
                                             value={this.props.experiment.description}/>
                <label htmlFor="description">Background and Significance</label>
                </span>
                <Button onClick={this.handleSubmitNext} label="Next" id="next" style={{float: 'right'}}/>
            </div>
        )
    }
}

Overview.propTypes = {
    experiment: PropTypes.object.isRequired, // Type defined in JOI
    nextAndSave:PropTypes.func.isRequired
};

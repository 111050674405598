import vtkMath from "vtk.js/Sources/Common/Core/Math/index";


/**
 * Calculates difference between Points of Origin of original and overlay images.
 * @param origin1 - origin point of original image
 * @param origin2 - origin point of overlay image
 * @param spacing - spacing      of original image
 * @param overlayDirection - direction of overlayImage
 */
export function calculateOriginBiasIJK(origin1, origin2 , spacing, overlayDirection ) {

    const mat3 = [[overlayDirection[0], overlayDirection[1], overlayDirection[2]],
                  [overlayDirection[3], overlayDirection[4], overlayDirection[5]],
                  [overlayDirection[6], overlayDirection[7], overlayDirection[8]]];

    const sdz = [0, 0, 0];
    // widthXYZ[idx]=0; ///????????????????????
    vtkMath.multiply3x3_vect3(mat3, [spacing[0], spacing[1], spacing[2]], sdz);
    return origin1.map((el, i) => {
        return Math.round((el - origin2[i]) / (spacing[i] * overlayDirection[8]))
    });
}


/**
 *
 * @param data - overlay data
 * @param index
 * @returns {{dO: number[], dP1: number[], dP2: number[]}}
 */
function calculateDirectedOverlayPoints( data,index) {
    let overlayDirection = data.getDirection();
    console.log('OverlayDirection:');
    console.log('index:', index);
    let indexRounded = Math.round(index);
    let indexCeil = Math.ceil(index);//floor
    let indexUsed = indexCeil;
    console.log('indexRounded:', indexRounded);
    const mat3 = [[overlayDirection[0], overlayDirection[1], overlayDirection[2]],
        [overlayDirection[3], overlayDirection[4], overlayDirection[5]],
        [overlayDirection[6], overlayDirection[7], overlayDirection[8]]];
    const spc = data.getSpacing();
    const dims = data.getDimensions();
    const sdz = [0, 0, 0];
    const sdx = [0, 0, 0];
    const sdy = [0, 0, 0];
    vtkMath.multiply3x3_vect3(mat3, [-1, 0, 0], sdx);
    vtkMath.multiply3x3_vect3(mat3, [0, -1, 0], sdy);
    vtkMath.multiply3x3_vect3(mat3, [0, 0, 1], sdz);

    console.log('sdx');
    console.log(sdx);
    console.log('sdy');
    console.log(sdy);
    console.log('sdz');
    console.log(sdz);

    let dOVec = [0, 0, 0];
    let dP1Vec = [0, 0, 0];
    let dP2Vec = [0, 0, 0];
    data.indexToWorldVec3([0, 0, indexUsed], dOVec);
    data.indexToWorldVec3([dims[0], 0, indexUsed], dP1Vec);
    data.indexToWorldVec3([0, dims[1], indexUsed], dP2Vec);

    // Move the plane to the front to the slice
    // 1. Calculate the unitary translation vector
    let dOVec_delta = [0, 0, 0];
    data.indexToWorldVec3([0, 0, indexUsed + 1], dOVec_delta);
    let transVec = [0, 0, 0];
    transVec[0] = dOVec_delta[0] - dOVec[0];
    transVec[1] = dOVec_delta[1] - dOVec[1];
    transVec[2] = dOVec_delta[2] - dOVec[2];
    let norm = Math.sqrt(transVec[0] * transVec[0] + transVec[1] * transVec[1] + transVec[2] * transVec[2])
    transVec[0] = transVec[0] / norm;
    transVec[1] = transVec[1] / norm;
    transVec[2] = transVec[2] / norm;

    // 2. Move the points a defined small distance in mm
    let distance = -spc[2] * 1.1;
    let dOVec_trans = [0, 0, 0];
    let dP1Vec_trans = [0, 0, 0];
    let dP2Vec_trans = [0, 0, 0];
    dOVec_trans[0] = dOVec[0] + distance * transVec[0];
    dOVec_trans[1] = dOVec[1] + distance * transVec[1];
    dOVec_trans[2] = dOVec[2] + distance * transVec[2];

    dP1Vec_trans[0] = dP1Vec[0] + distance * transVec[0];
    dP1Vec_trans[1] = dP1Vec[1] + distance * transVec[1];
    dP1Vec_trans[2] = dP1Vec[2] + distance * transVec[2];

    dP2Vec_trans[0] = dP2Vec[0] + distance * transVec[0];
    dP2Vec_trans[1] = dP2Vec[1] + distance * transVec[1];
    dP2Vec_trans[2] = dP2Vec[2] + distance * transVec[2];

    console.log('worldVec3:', dOVec);

    let originData = data.getOrigin();
    let dO = [0, 0, 0];
    let dP1 = [0, 0, 0];
    let dP2 = [0, 0, 0];

    dO[0] = originData[0] + sdz[0] * index * spc[2];
    dO[1] = originData[1] + sdz[1] * index * spc[2];
    dO[2] = originData[2] + sdz[2] * index * spc[2];

    dP1[0] = originData[0] + sdz[0] * index * spc[2] - sdx[0] * dims[0] * spc[0];
    dP1[1] = originData[1] + sdz[1] * index * spc[2] - sdx[1] * dims[0] * spc[0];
    dP1[2] = originData[2] + sdz[2] * index * spc[2] + sdx[2] * dims[0] * spc[0];

    dP2[0] = originData[0] + sdz[0] * index * spc[2] - sdy[0] * dims[1] * spc[1];
    dP2[1] = originData[1] + sdz[1] * index * spc[2] - sdy[1] * dims[1] * spc[1];
    dP2[2] = originData[2] + sdz[2] * index * spc[2] + sdy[2] * dims[1] * spc[1];

    console.log('Directed origin');
    console.log(dO);
    console.log('Directed P1');
    console.log(dP1);
    console.log('Directed P2');
    console.log(dP2);

    return {dO:dOVec_trans, dP1:dP1Vec_trans, dP2:dP2Vec_trans};
}

/** Sticking out the overlay plane source, in order to not be covered by imageslice of original image
 * @param idx - index of renderer
 * @param data - overlay data
 * @param osi - overlay slice index
 * @param planeSources -  table of  vtkPlaneSource objects
 * Beware, the point 1 and point 2 should take into account direction of dataset.*/
export function positionPlaneSource(idx, data,osi,planeSources) {
    let {dO, dP1, dP2} = calculateDirectedOverlayPoints(data,osi);
    planeSources[idx].setOrigin(dO);
    planeSources[idx].setPoint1(dP1);
    planeSources[idx].setPoint2(dP2);
    return planeSources;
}

/** Calculate the slice for overlay.
 *
 * @param idx
 * @param currentSliceOriginalImage
 * @param imageData
 * @param overlayData
 *
 * @param sliceFilters
 * @param planeSources
 * @param originTranslation
 * @param overlayDirection
 */
export function setOverlaySlice (idx,currentSliceOriginalImage, imageData, overlayData,sliceFilters,planeSources,originTranslation, overlayDirection){
    // Calculate the slice for the overlay
    // Distance between origins in the k direction
    // (k is the z direction in the image space).

    // First calculate the vector between the origins of the images
    let q = [0,0,0];
    const originOverlay = overlayData.getOrigin();
    let originOriginal =imageData.getOrigin();
    q[0] = originOverlay[0] - originOriginal[0];
    q[1] = originOverlay[1] - originOriginal[1];
    q[2] = originOverlay[2] - originOriginal[2];

    // Get the k-vector
    const rotMatOverlay = overlayDirection;
    const mat3 = [[rotMatOverlay[0], rotMatOverlay[1], rotMatOverlay[2]], [rotMatOverlay[3], rotMatOverlay[4], rotMatOverlay[5]], [rotMatOverlay[6], rotMatOverlay[7], rotMatOverlay[8]]];
    let k_vec = [0,0,0];

    vtkMath.multiply3x3_vect3(mat3, [0, 0, 1], k_vec);

    // This is the tricky part that we have to figure out!
    k_vec[0]=-k_vec[0];
    k_vec[1]=-k_vec[1];

    // Projection q vector onto the k-direction of the overlay image.
    // As k_vec is an unitary vector, the projection is the result of the
    // dot product
    let projection = q[0]*k_vec[0] + q[1]*k_vec[1] + q[2]*k_vec[2];

    // Calculate the difference in number of slices
    let spc = originOriginal = imageData.getSpacing();
    let diffSlices_k_dir = projection / spc[2];
    console.log('diffSlices_k_dir:',diffSlices_k_dir);

    let translation = originTranslation;
    //let overlaySliceIndex = currentSliceOriginalImage[idx]+translation[idx];
    let overlaySliceIndexRaw = currentSliceOriginalImage[idx]-diffSlices_k_dir;
    let overlaySliceIndex = currentSliceOriginalImage[idx]-Math.round(diffSlices_k_dir);
    overlaySliceIndex =(overlaySliceIndex<0 || overlaySliceIndex>1000)?-1:overlaySliceIndex;
    console.log('overlaySliceIndex:',overlaySliceIndex);
    console.log('overlaySliceIndexRaw:',overlaySliceIndexRaw);

    let sF = sliceFilters;
    sF[idx].setSliceIndex(overlaySliceIndex);
    // this.setState({sliceFilters:sF});
    const pS = positionPlaneSource(idx,overlayData,overlaySliceIndexRaw, planeSources);
    // this.setState({planeSources:pS});

    return {sF,pS};
};
import React from "react";

/**
 * Dumb component with template for Why Tab (first page).
 * @returns {*}
 * @constructor
 */
export const WhyTab = () => {

    return (

        <div>
            <h3>Why SPINE?</h3>
            SPINE is a virtual laboratory designed to accelerate scientific discovery,
            while educating and actively involving curious minds. SPINE stands for
            Structured Planning
            and Implementation of New Explorations. Right now we are focusing on
            neurological explorations
            that make use of advanced neuroimaging techniques. The basis of many experiments
            consists
            in measuring brain structures by outlining them on images. In fact, identifying
            and measuring
            anatomical structures on brain MRI images is the centerpiece of many
            neurological, psychiatric,
            and neurosurgical studies. Today this task is performed by MDs, PhDs and college
            students.
            We believe that anybody can help with this.
        </div>
    );
};
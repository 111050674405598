import ImageType from "itk/ImageType";
import * as IntTypes from "itk/IntTypes";
import Image from "itk/Image";


/**This method clones and converts VTK image to ITK image structure.
 * It is required for saving images to files with ITK.js.
 * IMPORTANT! there is transposition in data dircetion!
 * Function is inversion of VTK's convertItkToVtkImage:
 * https://github.com/Kitware/vtk-js/blob/master/Sources/Common/DataModel/ITKHelper/index.js
 * @version 1.0
 * @see https://github.com/Kitware/paraview-glance/issues/180#issuecomment-408659132
 * @param vtkImage - vtkImageData object
 * @returns {*} - itkImage object
 */
export function convertVtkToItkImage(vtkImage) {
    const scalars = vtkImage.getPointData().getScalars();
    const direction = vtkImage.getDirection().slice(0);

    const type = new ImageType(3, IntTypes.UInt8, 1, scalars.getNumberOfComponents());
    let itkImage = new Image(type);

    // transposition
    for (let i = 0; i < 3; i++) {
        for (let j = 0; j < 3; j++) {
            itkImage.direction.data[i + j * 3] = direction[j + i * 3]
        }
    }
    itkImage.origin = vtkImage.getOrigin().slice(0);
    itkImage.spacing = vtkImage.getSpacing().slice(0);
    itkImage.size = vtkImage.getDimensions().slice(0);
    //below is the most important to clone -  otherwise buffer won't work properly
    itkImage.data = scalars.getData().slice(0);
    return itkImage;
}

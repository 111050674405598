import React from "react";
import SelectionCriteriaGroup from "./SelectionCriteriaGroup";


/**
 * Component for representing Panel of Inclusion and Exclusion criteria.
 */
export default class SelectionCriteria extends React.Component {

    render() {
        const {experiment} = this.props;
        return (
            <div className="ui-g">
                <div id="inclusion" className="ui-g-12">
                    <div className="ui-g-12">Inclusion Criteria:</div>
                        <SelectionCriteriaGroup {...this.props}  value={experiment['selectionCriteria']['inclusion']} type="inc" />
                </div>
                <div id="exclusion" className="ui-g-12">
                    <div style={{float: 'left', margin: '1em', width: '100%'}}>Exclusion Criteria:</div>
                        <SelectionCriteriaGroup {...this.props}  value={experiment['selectionCriteria']['exclusion']} type="exc"/>
                </div>
            </div>
        );

    }
}
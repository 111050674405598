import {createSelector} from 'reselect';
import {numberPropertyComparator, stringPropertyComparator} from "../../helpers/comparators";
import {matchRule} from "../../helpers/regulars";

export const getVisibilityFilter = (state) => state.visu.models.visibilityFilter;
export const getSorter = (state) => state.visu.models.sorter;
const getModels = (state) => state.visu.models.models;

export const getVisibleModels = createSelector(
    [ getVisibilityFilter, getSorter, getModels ],
    (visibilityFilter, sorter, models) => {
        return getSortedModels(sorter,getFilteredModels(visibilityFilter,models));
    }
);

/**
 * Helper  for filtering models.
 * @param filter
 * @param models
 * @returns {*}
 */
const getFilteredModels = (filter, models) => {
    if ( filter==null || filter ==='' ) return models;
    // return models.filter(t => t.name.toLowerCase().includes(filter.toLowerCase()));
    return models.filter(t => matchRule(t.name.toLowerCase(),filter.toLowerCase()));

};



/** Helper used for sorting models.
 *
 * @param sorter
 * @param models
 * @returns {void | * | Uint8Array | Uint16Array | Int16Array | Float32Array} sorted models
 */
const getSortedModels = (sorter, models) => {
    switch (sorter) {
        case 'SORT_BY_NAME_DESC':
            return models.sort(stringPropertyComparator('name'));
        case 'SORT_BY_NAME_ASC':
            return models.sort(stringPropertyComparator('name')).reverse();
        case 'SORT_BY_SPINE_TYPE_DESC':
            return models.sort(stringPropertyComparator('spineType')).reverse();
        case 'SORT_BY_SPINE_TYPE_ASC':
            return models.sort(stringPropertyComparator('spineType'));
        case 'SORT_BY_STATUS_DESC':
            return models.sort(stringPropertyComparator('status')).reverse();
        case 'SORT_BY_STATUS_ASC':
            return models.sort(stringPropertyComparator('status'));
    }
};

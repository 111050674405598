import {createProject, getProjectList} from "../../project/action/ProjectAction";
import {connect} from "react-redux";
import Activities from "../components/Activities";
import Rewards from "../components/Rewards";


const mapStateToProps = state => {
    return {
        projects: state.project.projects.projects
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getProjects: () => {
            dispatch(getProjectList())
        },
        addProject:(proj)=>{
            dispatch(createProject(proj));
        }
    }
};

const projectContainerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerRewards = projectContainerCreator(Rewards);

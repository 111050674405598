import { 
	SUBJECT_REQUEST,
	SUBJECT_FAIL,
	SUBJECT_SUCCESS,
	SUBJECT_LIST_REQUEST,
	SUBJECT_LIST_FAIL,
	SUBJECT_LIST_SUCCESS,
} from '../action/actionType'

export const subjectReducer = (state = { subjectState: '', subject: [] }, action) => {
	switch (action.type) {
		case 'SUBJECT_REQUEST':
			return Object.assign({},state, {
        		studiesState: 'requested'
      	});
		case 'SUBJECT_FAIL':
			return ;
		case 'SUBJECT_SUCCESS':
			return Object.assign({}, state, {
				subjectState: 'success',
				subject: action.subject
			});
		default:
			return state;
	}
};

export const subjectListReducer = (state = { subjectsState: '', subjects: [] }, action) => {
	// console.log("Subject reducer action: ", action);
	switch (action.type) {
		case SUBJECT_LIST_REQUEST:
			return Object.assign({},state, {
        		studiesState: 'requested'
      	});
		case SUBJECT_LIST_FAIL:
			return ;
		case SUBJECT_LIST_SUCCESS:
			return Object.assign({}, state, {
				subjectsState: 'success',
				subjects: action.subjects
			});
		default:
			return state;
	}
};

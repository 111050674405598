import React from 'react';
import PropTypes from 'prop-types';
import vtkRenderWindow from 'vtk.js/Sources/Rendering/Core/RenderWindow';
import vtkRenderer from 'vtk.js/Sources/Rendering/Core/Renderer';
import vtkHttpDataSetReader from 'vtk.js/Sources/IO/Core/HttpDataSetReader';
import vtkImageMapper from 'vtk.js/Sources/Rendering/Core/ImageMapper';
import vtkImageSlice from 'vtk.js/Sources/Rendering/Core/ImageSlice';
import vtkOpenGLRenderWindow from 'vtk.js/Sources/Rendering/OpenGL/RenderWindow';
import vtkRenderWindowInteractor from 'vtk.js/Sources/Rendering/Core/RenderWindowInteractor';
import * as SpineInteractorStyleImage from "../SpineInteractorStyleImage";
import vtkITKImageReader from "vtk.js/Sources/IO/Misc/ITKImageReader";
import readImageArrayBuffer from 'itk/readImageArrayBuffer';
import ReaderFactory from "../ReaderFactory";
import extensionToImageIO from 'itk/extensionToImageIO';
import vtkCellPicker from 'vtk.js/Sources/Rendering/Core/CellPicker';
import {Slider} from "primereact/components/slider/Slider";
import {Button} from "primereact/components/button/Button";
import {InputText} from "primereact/components/inputtext/InputText";
import {ProgressBar} from "primereact/components/progressbar/ProgressBar";
import vtkColorMaps from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction/ColorMaps';
import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';
import vtkLookupTable from 'vtk.js/Sources/Common/Core/LookupTable';
import vtkActor from 'vtk.js/Sources/Rendering/Core/Actor';
import vtkPiecewiseFunction from 'vtk.js/Sources/Common/DataModel/PiecewiseFunction';
import Meters from "../Meters";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import vtkImageSliceFilter from 'vtk.js/Sources/Filters/General/ImageSliceFilter';
import vtkScalarToRGBA from 'vtk.js/Sources/Filters/General/ScalarToRGBA';
import vtkPlaneSource from 'vtk.js/Sources/Filters/Sources/PlaneSource';
import vtkTexture from 'vtk.js/Sources/Rendering/Core/Texture'
import vtkMapper from 'vtk.js/Sources/Rendering/Core/Mapper';
import vtkRTAnalyticSource from 'vtk.js/Sources/Filters/Sources/RTAnalyticSource';



const extensions = Array.from(
    new Set(Object.keys(extensionToImageIO).map((ext) => ext.toLowerCase()))
);
const colorMapOptions = vtkColorMaps.rgbPresetNames.map((s)=>{return {'name':s} });

/** BrainBrowser-like component for displaying 3 D slices.
 *  Based on:
 *  {@link http://kitware.github.io/vtk-js/examples/MultiSliceImageMapper.html}
 *  {@link http://kitware.github.io/vtk-js/examples/ImageCroppingRegionsWidget.html}
 *
 */
class BrainBrowser extends React.Component {

    constructor() {
        super();
        this.state = {renderWindow:vtkRenderWindow.newInstance(),
                      windowLevel: [10, 245],
                      progressPercent:0,
                      progressPercent2:0,
                      showProgress:false,
                      extent:[0, 10000, 0, 10000, 0, 10000], //range of slice indices for original image
                      overlayExtent:[0, 10000, 0, 10000, 0, 10000],//range of slice indices for overlay image
                      currentImageSlice:[0,0,0],
                      currentExtentSlice:[0,0,0],
                      currentImageSliceXYZ:[0,0,0],
                      currentExtentSliceXYZ:[0,0,0],
                      direction:[1, 0, 0, 0, 1, 0, 0, 0, 1],
                      overlayDirection:[1, 0, 0, 0, 1, 0, 0, 0, 1],
                      overlayOpacity:1.0,
                      colorLevel:0,
                      colorWindow:0,
                      ambient:1.0,
                      alphaRange:[90,100],
                      currentColorMap:{name:'Haze'},
                      overlayActors:[vtkActor.newInstance(), vtkActor.newInstance(), vtkActor.newInstance()],
                      lookupTable: vtkColorTransferFunction.newInstance(),
                      lookupTable_test:vtkLookupTable.newInstance()
        };
        this.onWLRangeChange = this.onWLRangeChange.bind(this);
        this.onCLRangeChange = this.onCLRangeChange.bind(this);
        this.onColorMapChange = this.onColorMapChange.bind(this);
        this.onAlphaChange = this.onAlphaChange.bind(this);
    }

    componentWillMount() {
        console.log(extensions);
        extensions.forEach((extension) => {
            ReaderFactory.registerReader({
                extension,
                name: `${extension.toUpperCase()} Reader`,
                vtkReader: vtkITKImageReader,
                binary: true,
                fileNameMethod: 'setFileName',
            });
        })
    }

    componentDidMount() {
        this.createBrowser();
    }

    componentDidUpdate() {
        // this.createBrowser();
    }

    /**
     * Window Level Range onChange handler.
     * @param event
     */
    onWLRangeChange(event){

    }

    /**
     * Color Level Range onChange handler.
     * @param event
     */
    onCLRangeChange(event){

    }

    onColorMapChange(event){
        this.setState({currentColorMap: event.value});
        this.state.lookupTable.applyColorMap(vtkColorMaps.getPresetByName(event.value['name']));
        this.state.renderWindow.render();
    }
    onAlphaChange(event){
        this.setState({alphaRange: event.value});
        this.state.lookupTable_test.setAlphaRange([event.value[0]/100,event.value[1]/100] );
        this.state.renderWindow.render();
    }
    createBrowser() {
        let thisView = this;  // this autoreference is needed for closures
        let initialSlices = [0, 0, 0];
        let initialOverSlices = [0, 0, 0];
        const sliceFilter = vtkImageSliceFilter.newInstance({ sliceIndex: 0 });

        const size = (this.props.viewportMode === 'horizontal') ?
            [this.props.viewportSize * 3, this.props.viewportSize] : [this.props.viewportSize * 2, this.props.viewportSize * 2];

        const interactorStyle2D = SpineInteractorStyleImage.newInstance();
        interactorStyle2D.setInteractionMode('IMAGE_SLICING');

        const renderWindow = thisView.state.renderWindow;
        const openglRenderWindow = vtkOpenGLRenderWindow.newInstance(
            {size: size});
        renderWindow.addView(openglRenderWindow);

        openglRenderWindow.setContainer(this.node);

        const interactor = vtkRenderWindowInteractor.newInstance();
       // interactor.setInteractorStyle(interactorStyle2D); // in case using predefined styles - SpineIneractorStyleImage
        interactor.setView(openglRenderWindow);
        interactor.initialize();
        interactor.bindEvents(this.node);
        // interactorStyle2D.setCurrentImageNumber(0);
        // const interactorStyle = vtkInteractorStyleManipulator.newInstance();

        const picker = vtkCellPicker.newInstance();
        renderWindow.getInteractor().setPicker(picker);

        const leftPosition = [0, 0, 0.33, 1];
        const middlePosition = [0.33, 0, 0.66, 1];
        const rightPosition = [0.66, 0, 1, 1];
        const leftUpper = [0, 0.5, 0.5, 1];
        const rightUpper = [0.5, 0.5, 1, 1];
        const leftLower = [0, 0, 0.5, 0.5];
        const iRenderer = vtkRenderer.newInstance({
            viewport: (this.props.viewportMode === 'square') ? leftUpper : leftPosition
        });
        const jRenderer = vtkRenderer.newInstance({
            viewport: (this.props.viewportMode === 'square') ? rightUpper : rightPosition
        });
        const kRenderer = vtkRenderer.newInstance({
            viewport: (this.props.viewportMode === 'square') ? leftLower : middlePosition
        });


        const imageActors = [vtkImageSlice.newInstance(), vtkImageSlice.newInstance(), vtkImageSlice.newInstance()];
        const imageMappers = [vtkImageMapper.newInstance(), vtkImageMapper.newInstance(), vtkImageMapper.newInstance()];


        const overlayActors = thisView.state.overlayActors;

        [iRenderer, jRenderer, kRenderer].forEach((selector, idx) => {
            renderWindow.addRenderer(selector);
            selector.setBackground(0, 0, 0);
            selector.getActiveCamera().setParallelProjection(true); // keeps constant distance between camera and slice
            // selector.addActor(imageActors[idx]);
            // selector.addActor(overlayActors[idx]);
        });

        [iRenderer, jRenderer].forEach((selector, idx) => {
                selector.addActor(imageActors[idx]);
        }
        );
        // const kInput = this.kInput;
        const inputs = [this.iInput, this.jInput, this.kInput];
        const minusButtons = [this.iButtonMinus, this.jButtonMinus, this.kButtonMinus];
        const plusButtons = [this.iButtonPlus, this.jButtonPlus, this.kButtonPlus];

        const overInputs = [this.iOverInput, this.jOverInput, this.kOverInput];
        const minusOverButtons = [this.iOverButtonMinus, this.jOverButtonMinus, this.kOverButtonMinus];
        const plusOverButtons = [this.iOverButtonPlus, this.jOverButtonPlus, this.kOverButtonPlus];

        const colorLevel = this.colorLevel;
        const colorWindow = this.colorWindow;
        const opacity = this.opacity;
        const ambient = this.ambient;


        function updateAmbient(e) {
            const cl = Number(
                (e ? e.target : ambient).value);
            imageActors.forEach((s) => {
                s.getProperty().setAmbient(cl/100)
            });
            thisView.setState({ambient:cl/100});
            renderWindow.render();
        }

        function updateOpacity(e) {
            const cl = Number(
                (e ? e.target : opacity).value);
            overlayActors.forEach((s) => {
                s.getProperty().setOpacity(cl/100)
            });
            thisView.setState({overlayOpacity:cl/100});
            renderWindow.render();
        }

        function updateColorLevel(e) {
            const cl = Number(
                (e ? e.target : colorLevel).value);
            imageActors.forEach((s) => {
                s.getProperty().setColorLevel(cl)
            });
            // overlayActors.forEach((s) => {
            //     s.getProperty().setColorLevel(cl)
            // });

            thisView.setState({colorLevel:cl});
            renderWindow.render();
        }

        function updateColorWindow(e) {
            const cl = Number(
                (e ? e.target : colorWindow).value
            );
            imageActors.forEach((s) => {
                s.getProperty().setColorWindow(cl)
            });
            // overlayActors.forEach((s) => {
            //     s.getProperty().setColorWindow(cl)
            // });
            thisView.setState({colorWindow:cl});
            renderWindow.render();
        }

        const reader = vtkHttpDataSetReader.newInstance({
            fetchGzip: true,
        });

        vtkITKImageReader.setReadImageArrayBufferFromITK(readImageArrayBuffer); //required by external library

        function progressCallback(event) {
            const progressPercent = Math.round(100 * event.loaded / event.total);
            console.log("BrainBrowser:progressCallback");
            thisView.setState({progressPercent:progressPercent});
        }
        function progressCallback2(event) {
            const progressPercent = Math.round(100 * event.loaded / event.total);
            console.log("BrainBrowser:progressCallback2");
            thisView.setState({progressPercent2:progressPercent});
        }
        const filename = this.props.filename;
        const filename2 = this.props.overlayFileNames[0];
        const url = this.props.url;
        const listReaders = ReaderFactory.listReaders();

        thisView.setState({ showProgress: true });
        ReaderFactory.downloadDataset(filename, url, progressCallback)
            .then((result) => {
                const reader = result.reader;
                const data = reader.getOutputData();
                data.setDirection(1, 0, 0, 0, 1, 0, 0, 0, 1);
                thisView.setState({direction:data.getDirection()});
                const dataRange = data
                    .getPointData()
                    .getScalars()
                    .getRange();

                thisView.setState({extent:data.getExtent()});
                imageMappers.forEach((s, idx) => {
                    s.setInputData(data);
                    initialSlices[idx] = Math.floor((thisView.state.extent[idx * 2 + 1] - thisView.state.extent[idx * 2]) / 2);
                    //initialSlices[idx] = 0;
                    if (idx === 0)
                        s.setISlice(initialSlices[idx]);
                    if (idx === 1)
                        s.setJSlice(initialSlices[idx]);
                    if (idx === 2)
                        s.setKSlice(initialSlices[idx]);
                });
                thisView.setState({currentImageSlice:initialSlices});
                imageActors.forEach((s, idx) => {
                    s.setMapper(imageMappers[idx]);
                });


                // [iRenderer, jRenderer, kRenderer].forEach((selector,idx)=>{
                //     const camPosition = selector.getActiveCamera().getFocalPoint()
                //         .map((v,index) => (index=== idx ? v+1:v));
                //     const viewUp = [0,0,0];
                //     viewUp [(idx+1)%3] =1;
                //     selector.getActiveCamera().set({position:camPosition,viewUp});
                //     selector.resetCamera();
                // } );
                iRenderer.getActiveCamera().set({position:[-1,0,0]});
                iRenderer.getActiveCamera().set({viewUp:[0,1,0]});
                iRenderer.resetCamera();

                jRenderer.getActiveCamera().set({position:[0,1,0]});
                jRenderer.getActiveCamera().set({viewUp:[0,-1,0]});
                jRenderer.resetCamera();

                kRenderer.getActiveCamera().set({position:[0,0,1]});
                kRenderer.getActiveCamera().set({viewUp:[0,1,0]});
                kRenderer.resetCamera();

                // renderWindow.render();

                inputs.forEach((selector, idx) => {
                    selector.value = initialSlices[idx];
                });



                [colorLevel, colorWindow].forEach((selector) => {
                    selector.setAttribute('max', dataRange[1]);
                    selector.setAttribute('value', dataRange[1]);
                });


                colorLevel.setAttribute('value', (dataRange[0] + dataRange[1]) / 2);
                // colorLevel.setAttribute('value', 150);//TOREMOVE
                // colorWindow.setAttribute('value', 100);//TOREMOVE
                opacity.setAttribute('max',100);
                opacity.setAttribute('min',0);
                opacity.setAttribute('value', 50);
                ambient.setAttribute('max',100);
                ambient.setAttribute('min',0);
                ambient.setAttribute('value',50);
                //
                // updateColorLevel();
                // updateColorWindow();
                // updateOpacity();
                // updateAmbient();

                ReaderFactory.downloadDataset(filename2, '/nifti/'+filename2, progressCallback2)
                    .then((result) => {
                        const reader = result.reader;
                        const data = reader.getOutputData();
                        // data.setDirection(1, 0, 0, 0, 1, 0, 0, 0, 1);
                        // thisView.setState({overlayDirection:data.getDirection()});
                        // let dataRange = data
                        //     .getPointData()
                        //     .getScalars()
                        //     .getRange();
                        //
                        // const scalars = data.getPointData().getScalars();
                        // const dataRange2 = [].concat(scalars ? scalars.getRange() : [0, 1]);

                        //-----------Lookup table && piecewise function-----------------------------------
                       // const lookupTable = thisView.state.lookupTable;
                        const lookupTable = vtkColorTransferFunction.newInstance();

                        const dataRange2=[0,200];
                        const preset = vtkColorMaps.getPresetByName('erdc_rainbow_bright');
                        lookupTable.applyColorMap(preset);
                        lookupTable.setMappingRange(...dataRange2);
                        lookupTable.updateRange();

                        const piecewiseFunction = vtkPiecewiseFunction.newInstance();
                        piecewiseFunction.removeAllPoints();
                        piecewiseFunction.addPoint(dataRange2[0], 0);
                        piecewiseFunction.addPoint((dataRange2[0] + dataRange2[1]) * 0.5, 0.1);
                        piecewiseFunction.addPoint(dataRange2[1], 1);


                        // //thisView.setState({overlayExtent:data.getExtent()});
                        // const overlayMappers = [
                        //     vtkMapper.newInstance(),
                        //     vtkMapper.newInstance(),
                        //     vtkMapper.newInstance()
                        // ];
                        //
                        // const overlaySliceFilters = [
                        //     vtkImageSliceFilter.newInstance({ sliceIndex: 10 }),
                        //     vtkImageSliceFilter.newInstance({ sliceIndex: 10 }),
                        //     vtkImageSliceFilter.newInstance({ sliceIndex: 10 })
                        // ];

                        //REMOVE it after switching to real data
                const wavelet = vtkRTAnalyticSource.newInstance();
                const actor = vtkActor.newInstance();
                const mapper = vtkMapper.newInstance();

                //const sliceFilter = vtkImageSliceFilter.newInstance({ sliceIndex: 17, orientation: 2 });
             //   const sliceFilter = vtkImageSliceFilter.newInstance({ sliceIndex: 20, orientation: 1 });

                //const sliceFilter = vtkImageSliceFilter.newInstance();
                sliceFilter.setInputConnection(reader.getOutputPort());

                const rgbaFilter = vtkScalarToRGBA.newInstance();
                rgbaFilter.setLookupTable(lookupTable);
                rgbaFilter.setPiecewiseFunction(piecewiseFunction);
                rgbaFilter.setInputConnection(sliceFilter.getOutputPort());

                const texture = vtkTexture.newInstance();
                texture.setInputConnection(rgbaFilter.getOutputPort());

                const planeSource = vtkPlaneSource.newInstance();
                mapper.setInputConnection(planeSource.getOutputPort());
                actor.setMapper(mapper);
                actor.addTexture(texture);

                       //  overlaySliceFilters.forEach((s) => {
                       //           // s.setInputData(data)
                       //      s.setInputConnection(wavelet.getOutputPort());
                       //
                       //  });
                       //
                       //
                       // const rgbaFilters = [vtkScalarToRGBA.newInstance(), vtkScalarToRGBA.newInstance(),vtkScalarToRGBA.newInstance()];
                       // rgbaFilters.forEach((s,i) => {
                       //     s.setLookupTable(lookupTable);
                       //     s.setPiecewiseFunction(piecewiseFunction);
                       //     s.setInputConnection(overlaySliceFilters[i].getOutputPort());
                       // });
                       //
                       //  const textures = [vtkTexture.newInstance(), vtkTexture.newInstance(), vtkTexture.newInstance()];
                       //  textures.forEach((s,i) => {
                       //      s.setInputConnection(rgbaFilters[i].getOutputPort());
                       //  });
                       //
                       //  const planeSources = [vtkPlaneSource.newInstance(),vtkPlaneSource.newInstance(),vtkPlaneSource.newInstance()];
                       //  overlayMappers.forEach((s,i) => {
                       //      s.setInputConnection(planeSources[i].getOutputPort());
                       //  });
                       //  overlayActors.forEach((s,i) => {
                       //      s.setMapper(i);
                       //      s.addTexture(textures[i]);
                       //  });

                        [iRenderer, jRenderer, kRenderer].forEach((selector, idx) => {
                           selector.addActor(actor);
                         //  selector.resetCamera();
                        });

                        // function myFunction()
                        // {
                        //     // your code
                        //
                        //     // stop for sometime if needed
                        //     setTimeout(myFunction, 5000);
                        // }
                        // myFunction();


                        // overlayMappers.forEach((s, idx) => {
                        //     s.setInputData(data);
                        //     initialOverSlices[idx] = Math.floor((thisView.state.overlayExtent[idx * 2 + 1] - thisView.state.overlayExtent[idx * 2]) / 2);
                        //     if (idx === 0)
                        //         s.setISlice(initialOverSlices[idx]);
                        //     if (idx === 1)
                        //         s.setJSlice(initialOverSlices[idx]);
                        //     if (idx === 2)
                        //         s.setKSlice(initialOverSlices[idx]);
                        // });
                        // thisView.setState({currentExtentSlice:initialOverSlices});
                        // overInputs.forEach((selector, idx) => {
                        //     selector.value = initialOverSlices[idx];
                        // });


                        // overlayActors.forEach((s, idx) => {
                        //     // s.setOpacity(0.9);
                        //
                        //     s.setMapper(overlayMappers[idx]);
                        //     s.getProperty().setRGBTransferFunction(lookupTable);//WORKING color function
                        //
                        // });
                        renderWindow.render();
                        thisView.setState({ showProgress: false });
                    });
                // thisView.setState({ showProgress: false });
           });


        inputs.forEach((selector, idx) => {
            selector.addEventListener('input', (e) => {
                let v = Number(e.target.value);
                v = (v > thisView.state.extent[idx * 2 + 1]) ? thisView.state.extent[idx * 2 + 1] : v;
                v = (v < thisView.state.extent[idx * 2]) ? thisView.state.extent[idx * 2] : v;
                imageActors[idx].getMapper().setSlice(v);
                renderWindow.render();

            });
        });
        overInputs.forEach((selector, idx) => {
            selector.addEventListener('input', (e) => {
                let v = Number(e.target.value);
                v = (v > thisView.state.overlayExtent[idx * 2 + 1]) ? thisView.state.overlayExtent[idx * 2 + 1] : v;
                v = (v < thisView.state.overlayExtent[idx * 2]) ? thisView.state.overlayExtent[idx * 2] : v;
                overlayActors[idx].getMapper().setSlice(v);
                renderWindow.render();

            });
        });


        minusButtons.forEach((s, idx) => {
            s.addEventListener('click', (e) => {
                    let previous = imageActors[idx].getMapper().getSlice();
                    imageActors[idx].getMapper().setSlice(previous === 0 ? 0 : previous - 1);
                    // overlayActors[idx].getMapper().setSlice(previous === 0 ? 0 : previous - 1);
                    inputs[idx].value = imageActors[idx].getMapper().getSlice();
                    let cis = thisView.state.currentImageSlice;
                    cis[idx] = imageActors[idx].getMapper().getSlice();
                    thisView.setState({currentImageSlice:cis});
                    renderWindow.render();
                }
            );
        });

        minusOverButtons.forEach((s, idx) => {
            s.addEventListener('click', (e) => {
                    //let previous = overlayActors[idx].getMapper().getSlice();
                    let prev=sliceFilter.getSliceIndex();
                    sliceFilter.setSliceIndex(prev-1);

                    // overlayActors[idx].getMapper().setSlice(previous === 0 ? 0 : previous - 1);
                    // overInputs[idx].value = overlayActors[idx].getMapper().getSlice();
                    // let cis = thisView.state.currentExtentSlice;
                    // //cis[idx] = overlayActors[idx].getMapper().getSlice();
                    // thisView.setState({currentExtentSlice:cis});

                    renderWindow.render();
                }
            );
        });

        plusButtons.forEach((s, idx) => {
            s.addEventListener('click', (e) => {
                let previous = imageActors[idx].getMapper().getSlice();
                imageActors[idx].getMapper().setSlice(previous === thisView.state.extent[idx * 2 + 1] ? thisView.state.extent[idx * 2 + 1] : previous + 1);
                // overlayActors[idx].getMapper().setSlice(previous === thisView.state.extent[idx * 2 + 1] ? thisView.state.extent[idx * 2 + 1] : previous + 1);
                inputs[idx].value = imageActors[idx].getMapper().getSlice();
                let cis = thisView.state.currentImageSlice;
                cis[idx] = imageActors[idx].getMapper().getSlice();
                thisView.setState({currentImageSlice:cis});
                renderWindow.render();
            });
        });
        plusOverButtons.forEach((s, idx) => {
            s.addEventListener('click', (e) => {
                // let previous = overlayActors[idx].getMapper().getSlice();
                // overlayActors[idx].getMapper().setSlice(previous === thisView.state.overlayExtent[idx * 2 + 1] ? thisView.state.overlayExtent[idx * 2 + 1] : previous + 1);
                // overInputs[idx].value = overlayActors[idx].getMapper().getSlice();
                // let cis = thisView.state.currentExtentSlice;
                // cis[idx] = overlayActors[idx].getMapper().getSlice();
                // thisView.setState({currentExtentSlice:cis});
                let prev=sliceFilter.getSliceIndex();
                sliceFilter.setSliceIndex(prev+1);

                renderWindow.render();
            });
        });

        colorLevel.addEventListener('input', updateColorLevel);
        colorWindow.addEventListener('input', updateColorWindow);
        opacity.addEventListener('input', updateOpacity);
        ambient.addEventListener('input', updateAmbient);


    }

    render() {

        let progress = null;

        if (this.state.showProgress) {
            progress = (
                <div>Placing the patient on the MRI table...
                    <ProgressBar value={this.state.progressPercent}/>
                    Scanning and processing image ...
                    <ProgressBar value={this.state.progressPercent2}/>
                </div>
            );
        }

        let monitorStyle = {
            fontSize:'x-small',backgroundColor: '#ededed', textAlign: 'left',  border: '1px solid #fafafa'
        };
        return (
            <div>
                {progress}
                <div ref={node => this.node = node}/>
                <div className={"ui-g-4"}>
                    <input type="button" value="-" ref={iButtonMinus => this.iButtonMinus = iButtonMinus}/>
                    <input type="text"
                           ref={iInput => this.iInput = iInput}
                    />
                    <input type="button" value="+" ref={iButtonPlus => this.iButtonPlus = iButtonPlus}/>
                </div>
                <div className={"ui-g-4"}>
                    <input type="button" value="-" ref={kButtonMinus => this.kButtonMinus = kButtonMinus}/>
                    <input type="text"
                           ref={kInput => this.kInput = kInput}
                    />
                    <input type="button" value="+" ref={kButtonPlus => this.kButtonPlus = kButtonPlus}/>
                </div>
                <div className={"ui-g-4"}>
                    <input type="button" value="-" ref={jButtonMinus => this.jButtonMinus = jButtonMinus}/>
                    <input type="text"
                           ref={jInput => this.jInput = jInput}
                    />
                    <input type="button" value="+" ref={jButtonPlus => this.jButtonPlus = jButtonPlus}/>
                </div>
                <div className={"ui-g-4"}>
                    <input type="button" value="-" ref={iOverButtonMinus => this.iOverButtonMinus = iOverButtonMinus}/>
                    <input type="text"
                           ref={iOverInput => this.iOverInput = iOverInput}
                    />
                    <input type="button" value="+" ref={iOverButtonPlus => this.iOverButtonPlus = iOverButtonPlus}/>
                </div>
                <div className={"ui-g-4"}>
                    <input type="button" value="-" ref={kOverButtonMinus => this.kOverButtonMinus = kOverButtonMinus}/>
                    <input type="text"
                           ref={kOverInput => this.kOverInput = kOverInput}
                    />
                    <input type="button" value="+" ref={kOverButtonPlus => this.kOverButtonPlus = kOverButtonPlus}/>
                </div>
                <div className={"ui-g-4"}>
                    <input type="button" value="-" ref={jOverButtonMinus => this.jOverButtonMinus = jOverButtonMinus}/>
                    <input type="text"
                           ref={jOverInput => this.jOverInput = jOverInput}
                    />
                    <input type="button" value="+" ref={jOverButtonPlus => this.jOverButtonPlus = jOverButtonPlus}/>
                </div>
                <div className={"ui-g-3"}>
                    <input type="range"
                           ref={colorLevel => this.colorLevel = colorLevel}
                    />
                </div>
                <div className={"ui-g-3"}>
                    <input type="range"
                           ref={colorWindow => this.colorWindow = colorWindow}
                    />
                </div>
                <div className={"ui-g-3"}>
                    <input type="range"
                           ref={opacity => this.opacity = opacity}
                    />
                </div>
                <div className={"ui-g-3"}>
                    <input type="range"
                           ref={ambient => this.ambient = ambient}
                    />
                </div>
                <div className="ui-g-12 ui-md-4">
                    <div className="ui-inputgroup">
                        <span className="ui-inputgroup-addon"><Button  checked={true} /></span>
                        <InputText placeholder="Website"/>
                        <span className="ui-inputgroup-addon"><Button  checked={false}/></span>
                    </div>
                </div>
                <div className="ui-g-12 ui-md-4">
                       <Slider style={{ width: '200px' }}  value={this.state.alphaRange}
                               onChange={this.onAlphaChange}
                               range={true}/>
                </div>
                <div className="ui-g-12 ui-md-4">
                  <Dropdown options={colorMapOptions} value={this.state.currentColorMap}
                            optionLabel="name" onChange={this.onColorMapChange}  style={{width:'400px'}} />
                </div>

                {/*<Meters {...this.state}/>*/}
            </div>
        );

    }
}

BrainBrowser.defaultProps = {
    viewportSize: 250,
    viewportMode: 'horizontal' //'horizontal' or 'square'
};
BrainBrowser.propTypes = {
    viewportSize: PropTypes.number,
    viewportMode: PropTypes.string, //'horizontal' or 'square'
    fileName:PropTypes.string,
    overlayFileNames:PropTypes.array
};

export default BrainBrowser;
import React from "react";
import PropTypes from "prop-types";
import Link from "react-router-dom/es/Link";
import {withRouter} from "react-router";

const LpItemListElementSlides = ({item,history}) => {

    let  delete_slide_click = ()=>{
        alert("delete");
    };
    let  edit_slide_click = ()=>{
        alert("edit");
    };

    return (
        <React.Fragment>
            <div className="experimentListElement">
                <div className = "ui-g-2">{item.orderNumber}</div>
                <div className = "ui-g-2">{item.slideType}</div>
                <div className = "ui-g-4">{item.title}</div>
                <div className = "ui-g-2">{item.timeMinutes}:{item.timeSeconds}</div>
                <div className = "ui-g-1" onClick={delete_slide_click}>Edit</div>
                <div className = "ui-g-1 delete-slide" onClick={edit_slide_click}>Delete</div>
            </div>
        </React.Fragment>
    )
};
export default withRouter(LpItemListElementSlides);

LpItemListElementSlides.propTypes = {
    item: PropTypes.object.isRequired
};

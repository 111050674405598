import {connect} from "react-redux";
import {getAnnotations, getMRIData, updateAnnotationData} from "../action/AnnotationAction";
import TaskView from "../component/TaskView";
import {withRouter} from "react-router";



const mapStateToProps = state => {
    return {
        data: state.visu.annotations.annotations.data,
        imageData:state.visu.annotations.imageData,
        imageState:state.visu.annotations.imageState
    }
};

const mapDispatchToProps = dispatch => {
    return {
        getAnnotations: (id) => {
            dispatch(getAnnotations(id))
        },
        updateAnnotationData:(data)=>{
            dispatch(updateAnnotationData(data))
        },
        getMRIData : (urlToFile)=>{
            dispatch(getMRIData(urlToFile))
        }
    }
};

export const ContainerTaskView =withRouter(connect(
    mapStateToProps,
    mapDispatchToProps)(TaskView));

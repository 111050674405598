import React from 'react';
import PropTypes from 'prop-types';
import vtkRenderWindow from 'vtk.js/Sources/Rendering/Core/RenderWindow';
import vtkRenderer from 'vtk.js/Sources/Rendering/Core/Renderer';
import vtkImageMapper from 'vtk.js/Sources/Rendering/Core/ImageMapper';
import vtkImageSlice from 'vtk.js/Sources/Rendering/Core/ImageSlice';
import vtkOpenGLRenderWindow from 'vtk.js/Sources/Rendering/OpenGL/RenderWindow';
import vtkRenderWindowInteractor from 'vtk.js/Sources/Rendering/Core/RenderWindowInteractor';
import * as SpineInteractorStyleImage from "../SpineInteractorStyleImage";
import vtkITKImageReader from "vtk.js/Sources/IO/Misc/ITKImageReader";//original
// import readImageArrayBuffer from 'itk/readImageArrayBuffer';//TODO restore original after authors release fix in new version
import readImageArrayBuffer from '../readImageArrayBuffer'; //TODO delete as above
import ReaderFactory from "../ReaderFactory";
import extensionToImageIO from 'itk/extensionToImageIO';
import vtkCellPicker from 'vtk.js/Sources/Rendering/Core/CellPicker';
import {Slider} from "primereact/components/slider/Slider";
import {ProgressBar} from "primereact/components/progressbar/ProgressBar";
import vtkColorMaps from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction/ColorMaps';
import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';
import vtkLookupTable from 'vtk.js/Sources/Common/Core/LookupTable';
import vtkPiecewiseFunction from 'vtk.js/Sources/Common/DataModel/PiecewiseFunction';
import Meters from "../Meters";
import vtkSpineImageSliceFilter from "../SpineImageSliceFilter";
import vtkScalarToRGBA from "../SpineScalarToRGBA";
import vtkTexture from "vtk.js/Sources/Rendering/Core/Texture/index";
import vtkPlaneSource from "vtk.js/Sources/Filters/Sources/PlaneSource/index";
import vtkActor from "vtk.js/Sources/Rendering/Core/Actor/index";
import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper/index";
import vtkMath from "vtk.js/Sources/Common/Core/Math/index";
import vtkSphereSource from 'vtk.js/Sources/Filters/Sources/SphereSource';
import {loggingDataset} from "../MRIBrowserLogger";
import vtkRTAnalyticSource from "vtk.js/Sources/Filters/Sources/RTAnalyticSource/index";


const extensions = Array.from(
    new Set(Object.keys(extensionToImageIO).map((ext) => ext.toLowerCase()))
);


/** BrainBrowser component based on 2 images slices.
 *  This solution is based on two image slices.
 *  There is still problem with transparency of background images.
 *  This version is not working properly !!!!!
 *
 *  Based on:
 *  {@link http://kitware.github.io/vtk-js/examples/MultiSliceImageMapper.html}
 *  {@link http://kitware.github.io/vtk-js/examples/ImageCroppingRegionsWidget.html}
 *
 */
class SpineVtkBrowser2 extends React.Component {

    constructor() {
        super();
        this.state = {
            renderWindow:vtkRenderWindow.newInstance(),
            progressPercent:0,
            progressPercent2:0,
            showProgressFlag:true,
            showProgressFlag2:true,
            overlayData:{},
            imageData:{},
            imageData2:{},
            originTranslation:[],
            dataRange:[0,128],
            dataRange2:[0,1], //binary
            extent:[0, 10000, 0, 10000, 0, 10000], //range of slice indices for original image
            currentImageSlice:[0,0,0],
            rgbaFilters:[vtkScalarToRGBA.newInstance(),vtkScalarToRGBA.newInstance(),vtkScalarToRGBA.newInstance()],
            direction:[1, 0, 0, 0, 1, 0, 0, 0, 1], //original data direction
            overlayDirection:[1, 0, 0, 0, 1, 0, 0, 0, 1],//original data direction
            overlayOpacity:1.0,
            originalOpacity:1.0,
            colorLevel:128,
            colorWindow:100,
            lookupTable: vtkColorTransferFunction.newInstance(),
            lookupTable_test:vtkLookupTable.newInstance()

        };
        this.onWLRangeChange = this.onWLRangeChange.bind(this);
        this.onCLRangeChange = this.onCLRangeChange.bind(this);
        this.onOverlayOpacityChange = this.onOverlayOpacityChange.bind(this);
        this.onOriginalOpacityChange = this.onOriginalOpacityChange.bind(this);
    }

    componentWillMount() {
        console.log(extensions);
        vtkITKImageReader.setReadImageArrayBufferFromITK(readImageArrayBuffer); //required by external library
        extensions.forEach((extension) => {
            ReaderFactory.registerReader({
                extension,
                name: `${extension.toUpperCase()} Reader`,
                vtkReader: vtkITKImageReader,
                binary: true,
                fileNameMethod: 'setFileName',
            });
        })
    }

    componentDidMount() {
        this.createBrowser();
    }


    getImageActors(){
        return this.state.renderWindow.getRenderers().map((s)=>{return s.getActors()[0]});
    }

    /**
     * Window Level Range onChange handler.
     * Controls the window in a window level mapping of the input image. Window
     * level mapping is a technique to map the raw data values of an image
     * into screen intensities in a manner akin to
     *
     * pixelIntensity = (inputValue - level)/window;
     *
     * @param event
     */
    onWLRangeChange(event){
        const cl = Number( (event ? event : this.state.colorWindow).value);
        this.changeWL(cl);
        this.refreshRenderWindow();
    }

    changeWL(cl) {
        this.getImageActors().forEach((s) => {
            s.getProperty().setColorWindow(cl)
        });
        this.setState({colorWindow: cl});
    }

    /**
     * Color Level Range onChange handler.
     * @see above
     * @param event
     */
    onCLRangeChange(event){
        const cl = Number( (event ? event : this.state.colorLevel).value);
        this.changeCL(cl);
        this.refreshRenderWindow();
    }

    changeCL(cl) {
        this.getImageActors().forEach((s) => {
            s.getProperty().setColorLevel(cl)
        });
        this.setState({colorLevel: cl});
    }

    /**
     * Handler for overlay opacity slider
     * @param event
     */
     onOverlayOpacityChange(event){
        this.setState({overlayOpacity:event.value/100});
        this.state.renderWindow.getRenderers()[0].getActors()[1].getProperty().setOpacity(event.value/100);
        this.refreshRenderWindow();
    }

    /**
     * Handler for original opacity slider
     * @param event
     */
     onOriginalOpacityChange(event){
        this.setState({originalOpacity:event.value/100});
        this.state.renderWindow.getRenderers()[0].getActors()[0].getProperty().setOpacity(event.value/100);
        this.refreshRenderWindow();
    }

    refreshRenderWindow(){
        this.state.renderWindow.render();
    }

   async createBrowser() {
        let thisView = this;  // this autoreference is needed for closures
        let initialSlices = [0, 0, 0];
        const actors = this.state.overlayActors;
        const {iRenderer, jRenderer, kRenderer,size} = this.setViewports();//initialising renderers with setting viewports

        const interactorStyle2D = SpineInteractorStyleImage.newInstance();
        interactorStyle2D.setInteractionMode('IMAGE_SLICING');

        const renderWindow = thisView.state.renderWindow;
        const openglRenderWindow = vtkOpenGLRenderWindow.newInstance({size: size});
        renderWindow.addView(openglRenderWindow);

        openglRenderWindow.setContainer(this.node);


        const interactor = vtkRenderWindowInteractor.newInstance();
        interactor.setInteractorStyle(interactorStyle2D); // in case using predefined styles - SpineIneractorStyleImage
        interactor.setView(openglRenderWindow);
        interactor.initialize();
        interactor.bindEvents(this.node);

        const picker = vtkCellPicker.newInstance();
        renderWindow.getInteractor().setPicker(picker);


        const imageActors = [vtkImageSlice.newInstance(), vtkImageSlice.newInstance(), vtkImageSlice.newInstance()];
        const imageMappers = [vtkImageMapper.newInstance(), vtkImageMapper.newInstance(), vtkImageMapper.newInstance()];
        const imageActors2 = [vtkImageSlice.newInstance(), vtkImageSlice.newInstance(), vtkImageSlice.newInstance()];
        const imageMappers2 = [vtkImageMapper.newInstance(), vtkImageMapper.newInstance(), vtkImageMapper.newInstance()];


        //set Renderers - register actors
        [iRenderer, jRenderer, kRenderer].forEach((selector, idx) => {
            if(thisView.props.viewportMode!=='axial' || idx===2) {
                renderWindow.addRenderer(selector);
                selector.setBackground(0, 0, 0);
                selector.getActiveCamera().setParallelProjection(true); // if true keeps constant distance between camera and slice
                selector.addActor(imageActors[idx]);
                selector.addActor(imageActors2[idx]);
                selector.resetCamera();
            }
        });

        const inputs = [this.iInput, this.jInput, this.kInput];
        const minusButtons = [this.iButtonMinus, this.jButtonMinus, this.kButtonMinus];
        const plusButtons = [this.iButtonPlus, this.jButtonPlus, this.kButtonPlus];

        function progressCallback(event) {
            const progressPercent = Math.round(100 * event.loaded / event.total);
            console.log("BrainBrowser:progressCallback");
            thisView.setState({progressPercent:progressPercent});
        }
        function progressCallback2(event) {
            const progressPercent = Math.round(100 * event.loaded / event.total);
            console.log("BrainBrowser:progressCallback2");
            thisView.setState({progressPercent2:progressPercent});
        }
        const fileId = this.props.fileId;
        let urlContext = this.props.urlContext;

        thisView.setState({ showProgressFlag: true });

       var loadOriginalImage = (result) => {
                const reader = result.reader;
                const data = reader.getOutputData();
                thisView.setState({direction:data.getDirection().slice()});
                thisView.setState({imageData:data});
                // data.setDirection(1, 0, 0, 0, 1, 0, 0, 0, 1);

                console.log('Original image');
                loggingDataset(data);
                const dataRange = data
                    .getPointData()
                    .getScalars()
                    .getRange();

                thisView.changeWL(dataRange[1]-dataRange[0]);
                thisView.changeCL(dataRange[0]+ (dataRange[1]-dataRange[0])*.5);
                thisView.setState({dataRange:dataRange});
                thisView.setState({extent:data.getExtent()});

                //  if initial slices is prop, then use it, otherwise set in the middle of volume
                if (this.props.initialSlices!=null){
                    imageMappers.forEach((s, idx) => {
                        s.setInputData(data);
                        if (idx === 0)
                            s.setISlice(this.props.initialSlices[idx]);
                        if (idx === 1)
                            s.setJSlice(this.props.initialSlices[idx]);
                        if (idx === 2)
                            s.setKSlice(this.props.initialSlices[idx]);
                    });}
                else{
                    imageMappers.forEach((s, idx) => {
                        s.setInputData(data);
                        initialSlices[idx] = Math.floor((thisView.state.extent[idx * 2 + 1] - thisView.state.extent[idx * 2]) / 2);
                        // initialSlices[idx] = 0;
                        if (idx === 0)
                            s.setISlice(initialSlices[idx]);
                        if (idx === 1)
                            s.setJSlice(initialSlices[idx]);
                        if (idx === 2)
                            s.setKSlice(initialSlices[idx]);
                    });
                }
                thisView.setState({currentImageSlice:initialSlices});
                imageActors.forEach((s, idx) => {
                    s.setMapper(imageMappers[idx]);
                    s.getProperty().setOpacity(0.9);
                });


                iRenderer.getActiveCamera().set({position:[1,0,0]});
                iRenderer.getActiveCamera().set({viewUp:[0,0,1]});
                iRenderer.resetCamera();

                jRenderer.getActiveCamera().set({position:[0,1,0]});
                jRenderer.getActiveCamera().set({viewUp:[0,1,0]});
                jRenderer.resetCamera();

                kRenderer.getActiveCamera().set({position:[0,0,-1]});
                kRenderer.getActiveCamera().set({viewUp:[0,-1,0]});
                kRenderer.resetCamera();

                inputs.forEach((selector, idx) => {
                    if(thisView.props.viewportMode!=='axial' || idx===2) //not register i,j if there is only one viewport
                        selector.value = initialSlices[idx];
                });
                // colorLevel.setAttribute('value', (dataRange[0] + dataRange[1]) / 2);
                thisView.setState({colorLevel:(dataRange[0] + dataRange[1]) / 2});
                thisView.setState({ showProgressFlag: false });
            };

        var loadOverlayImage = (result) => {
            const reader = result.reader;
            const data = reader.getOutputData();
            thisView.setState({imageData2:data});
            // data.setDirection(1, 0, 0, 0, 1, 0, 0, 0, 1);

            let dataRange = data
                .getPointData()
                .getScalars()
                .getRange();

            //-----------Lookup table && piecewise function-----------------------------------
            // const lookupTable = thisView.state.lookupTable;
            const lookupTable = vtkColorTransferFunction.newInstance();

            const dataRange2=[0,200];
            const preset = vtkColorMaps.getPresetByName('erdc_rainbow_bright');
            // lookupTable.applyColorMap(preset);
            // lookupTable.setMappingRange(...dataRange2);
            // lookupTable.updateRange();
            lookupTable.addRGBPoint(dataRange[0], 0.0, 0.0, 0.0);
            // lookupTable.addRGBPoint(dataRange[0]+0.1, 1.0, 0.0, 0.0);
            lookupTable.addRGBPoint(dataRange[1], 1.0, 0.0, 0.0);


            //  if initial slices is prop, then use it, otherwise set in the middle of volume
            if (this.props.initialSlices!=null){
                imageMappers2.forEach((s, idx) => {
                    s.setInputData(data);
                    if (idx === 0)
                        s.setISlice(this.props.initialSlices[idx]);
                    if (idx === 1)
                        s.setJSlice(this.props.initialSlices[idx]);
                    if (idx === 2)
                        s.setKSlice(this.props.initialSlices[idx]);
                });}
            else{
                imageMappers2.forEach((s, idx) => {
                    s.setInputData(data);
                    initialSlices[idx] = Math.floor((thisView.state.extent[idx * 2 + 1] - thisView.state.extent[idx * 2]) / 2);
                    // initialSlices[idx] = 0;
                    if (idx === 0)
                        s.setISlice(initialSlices[idx]);
                    if (idx === 1)
                        s.setJSlice(initialSlices[idx]);
                    if (idx === 2)
                        s.setKSlice(initialSlices[idx]);
                });
            }
            imageActors2.forEach((s, idx) => {
                s.setMapper(imageMappers2[idx]);
                s.getProperty().setOpacity(1.0);
                s.getProperty().setRGBTransferFunction(lookupTable);
            });
            thisView.setState({ showProgressFlag2: false });
        }

       const [result1, result2] = await Promise.all(
           [ReaderFactory.downloadDataset("or.nii", '/overlay/'+fileId+'/original', null).then(loadOriginalImage),
               ReaderFactory.downloadDataset('ov.nii.gz' ,'/overlay/'+fileId, null).then(loadOverlayImage)]);
       renderWindow.render();
       // await  Promise.all([loadOriginalImage(result1), loadOverlayImage(result2)]);

       console.log("Result 1",result1);
       console.log("Result 2", result2);
        let setOverlaySlice = function(idx,sliceOriginalImage){
            // Calculate the slice for the overlay
            // Distance between origins in the k direction
            // (k is the z direction in the image space).

            // First calculate the vector between the origins of the images
            let q = [0,0,0];
            let originOverlay = thisView.state.imageData2.getOrigin();
            let originOriginal = thisView.state.imageData.getOrigin();
            q[0] = originOverlay[0] - originOriginal[0];
            q[1] = originOverlay[1] - originOriginal[1];
            q[2] = originOverlay[2] - originOriginal[2];

            // Get the k-vector
            const rotMatOverlay = thisView.state.overlayDirection;
            const mat3 = [[rotMatOverlay[0], rotMatOverlay[1], rotMatOverlay[2]], [rotMatOverlay[3], rotMatOverlay[4], rotMatOverlay[5]], [rotMatOverlay[6], rotMatOverlay[7], rotMatOverlay[8]]];
            let k_vec = [0,0,0];

            vtkMath.multiply3x3_vect3(mat3, [0, 0, 1], k_vec);

            // This is the tricky part that we have to figure out!
            k_vec[0]=-k_vec[0];
            k_vec[1]=-k_vec[1];

            // Projection q vector onto the k-direction of the overlay image.
            // As k_vec is an unitary vector, the projection is the result of the
            // dot product
            let projection = q[0]*k_vec[0] + q[1]*k_vec[1] + q[2]*k_vec[2];

            // Calculate the difference in number of slices
            let spc = originOriginal = thisView.state.imageData.getSpacing();
            let diffSlices_k_dir = projection / spc[2];
            console.log('diffSlices_k_dir:',diffSlices_k_dir);


            let translation = thisView.state.originTranslation;
            //let overlaySliceIndex = sliceOriginalImage[idx]+translation[idx];
            let overlaySliceIndexRaw = sliceOriginalImage[idx]-diffSlices_k_dir;
            let overlaySliceIndex = sliceOriginalImage[idx]-Math.round(diffSlices_k_dir);
            overlaySliceIndex =(overlaySliceIndex<0 || overlaySliceIndex>1000)?-1:overlaySliceIndex;
            console.log('overlaySliceIndex:',overlaySliceIndex);
            console.log('overlaySliceIndexRaw:',overlaySliceIndexRaw);
            imageActors2[idx].getMapper().setSlice(overlaySliceIndex);

        };

        function setSlices(idx) {
            if (thisView.props.viewportMode !== 'axial' || idx === 2) {//not register i,j if there is only one viewport{
                inputs[idx].value = imageActors[idx].getMapper().getSlice();
                let cis = thisView.state.currentImageSlice;
                cis[idx] = imageActors[idx].getMapper().getSlice();
                setOverlaySlice(idx, cis);
                thisView.setState({currentImageSlice: cis});
            }
            // thisView.refreshRenderWindow();
        }

        inputs.forEach((selector, idx) => {
            if(thisView.props.viewportMode!=='axial' || idx===2) //not register i,j if there is only one viewport
                selector.addEventListener('input', (e) => {
                    let v = Number(e.target.value);
                    v = (v > thisView.state.extent[idx * 2 + 1]) ? thisView.state.extent[idx * 2 + 1] : v;
                    v = (v < thisView.state.extent[idx * 2]) ? thisView.state.extent[idx * 2] : v;
                    // selector.value=v;
                    imageActors[idx].getMapper().setSlice(v);
                    setSlices(idx);
                    thisView.refreshRenderWindow();

                });
        });
        minusButtons.forEach((s, idx) => {
            if(thisView.props.viewportMode!=='axial' || idx===2) //not register i,j if there is only one viewport
                s.addEventListener('click', (e) => {
                        let previous = imageActors[idx].getMapper().getSlice();
                        imageActors[idx].getMapper().setSlice(previous === 0 ? 0 : previous - 1);
                        setSlices(idx);
                        thisView.refreshRenderWindow();
                    }
                );
        });



        plusButtons.forEach((s, idx) => {
            if(thisView.props.viewportMode!=='axial' || idx===2) //not register i,j if there is only one viewport
                s.addEventListener('click', (e) => {
                    let previous = imageActors[idx].getMapper().getSlice();
                    imageActors[idx].getMapper().setSlice(previous === thisView.state.extent[idx * 2 + 1] ? thisView.state.extent[idx * 2 + 1] : previous + 1);
                    setSlices(idx);
                    thisView.refreshRenderWindow();
                });
        });

    }

    setViewports() {
        const leftPosition = [0, 0, 0.33, 1];
        const middlePosition = [0.33, 0, 0.66, 1];
        const rightPosition = [0.66, 0, 1, 1];
        const leftUpper = [0, 0.5, 0.5, 1];
        const rightUpper = [0.5, 0.5, 1, 1];
        const leftLower = [0, 0, 0.5, 0.5];
        let size =[];
        const iRenderer = vtkRenderer.newInstance();
        const jRenderer = vtkRenderer.newInstance();
        const kRenderer = vtkRenderer.newInstance();

        switch (this.props.viewportMode){
            case 'square': iRenderer.setViewport(leftUpper);
                jRenderer.setViewport(rightUpper);
                kRenderer.setViewport(leftLower);
                size = [this.props.viewportSize * 2, this.props.viewportSize * 2];
                break;
            case 'horizontal':
                iRenderer.setViewport(leftPosition);
                jRenderer.setViewport(rightPosition);
                kRenderer.setViewport(middlePosition);
                size= [this.props.viewportSize * 3, this.props.viewportSize];
                break;
            case 'axial':  kRenderer.setViewport([0,0,1,1]);
                size= [this.props.viewportSize,this.props.viewportSize];
                break;

        }


        return {iRenderer, jRenderer, kRenderer,size};
    }


    render() {

        const axialMode=(this.props.viewportMode==='axial');
        const axialClass=(this.props.viewportMode==='axial')?"ui-g-12":"ui-g-4";
        // let progress = null;
        // if (this.state.showProgressFlag || this.state.showProgressFlag2) {
        //     progress = (
        //         <div>Loading MRI Volume Image...
        //             <ProgressBar value={this.state.progressPercent}/>
        //             Loading Label Map
        //             <ProgressBar value={this.state.progressPercent2}/>
        //         </div>
        //     );
        // }



        return (
            <div className={"ui-g-12"}>
                {/*{progress}*/}

                {(this.state.showProgressFlag || this.state.showProgressFlag2)&&     <i className="fa fa-spinner fa-spin"/>}
                <div ref={node => this.node = node}/>
                {!axialMode &&
                <div className={"ui-g-4"}>
                    <input type="button" value="-" ref={iButtonMinus => this.iButtonMinus = iButtonMinus}/>
                    <input type="text"
                           ref={iInput => this.iInput = iInput}
                    />
                    <input type="button" value="+" ref={iButtonPlus => this.iButtonPlus = iButtonPlus}/>
                </div>
                }
                <div className={axialClass}>
                    <input type="button" value="-" ref={kButtonMinus => this.kButtonMinus = kButtonMinus}/>
                    <input type="text"
                           ref={kInput => this.kInput = kInput}
                    />
                    <input type="button" value="+" ref={kButtonPlus => this.kButtonPlus = kButtonPlus}/>
                </div>
                {!axialMode &&
                <div className={"ui-g-4"}>
                    <input type="button" value="-" ref={jButtonMinus => this.jButtonMinus = jButtonMinus}/>
                    <input type="text"
                           ref={jInput => this.jInput = jInput}
                    />
                    <input type="button" value="+" ref={jButtonPlus => this.jButtonPlus = jButtonPlus}/>
                </div>
                }
                <div className={"ui-g-3"}>
                    <Slider value={this.state.colorLevel} min={this.state.dataRange[0]} max={this.state.dataRange[1]} onChange={this.onCLRangeChange}/>
                </div>
                <div className={"ui-g-3"}>
                    <Slider value={this.state.colorWindow} min={1} max={this.state.dataRange[1]} onChange={this.onWLRangeChange}/>
                </div>
                <div className={"ui-g-3"}>
                    <Slider value={this.state.overlayOpacity*100} max={100} min={0} onChange={this.onOverlayOpacityChange}/>
                </div>
                <div className={"ui-g-3"}>
                    <Slider value={this.state.originalOpacity*100} max={100} min={0} onChange={this.onOriginalOpacityChange}/>
                </div>
                {/*<Meters {...this.state} {...this.props}/>*/}
            </div>
        );

    }
}


export default SpineVtkBrowser2;
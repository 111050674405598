// import React from 'react';
// import ReactDOM from 'react-dom';
// import AuthRoutes from './routes';
// import './spine.css'
// import 'primereact/resources/primereact.min.css';
// import { BrowserRouter } from 'react-router-dom';
// import { Provider } from 'react-redux';
// import store from './authentication/AuthStore.jsx';
// import Study from './authentication/study.js'
// import { Link, Route, Switch } from 'react-router-dom'
// import {App} from './authentication/App.js';
// import ContainerApp from './authentication/containers/ContainerApp.js'
// import Root from './Root.js'

import React from 'react';
import ReactDOM from 'react-dom';
import Root from './Root.js';
import '@babel/polyfill';

ReactDOM.render((
    <Root/>
  ),
  document.getElementById('root')
);

// ReactDOM.render((
//   <Provider store={store}>
//     <BrowserRouter>
//       <div>
//         <Link to='/studies'>Studies</Link>
//         <Link to='/'>Home</Link>
//         <Route exact path='/' render={ (props) => {
//         	  console.log('store.getState():',store.getState())
//         	  if (store.getState().login_status == 'logout')
// 			    return <ContainerApp/>
// 			  else
// 			    return <Study/>
//             }
//           }
//         />
//       </div>
//     </BrowserRouter>
//   </Provider>
//   ),
//   document.getElementById('root')
// );
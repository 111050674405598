import { combineReducers } from 'redux'

import {userListReducer} from './reducer/UserReducer'
import {globalDefReducer} from './reducer/GlobalDefinitionReducer'
import {workflowListReducer} from './reducer/WorkflowReducer'
import {taskListReducer} from './reducer/TaskListReducer'
import {dataSelectionReducer} from './reducer/DataSelectionReducer'
import {expSessionStateReducer} from "./reducer/ExpSessionStateReducer";
import {auditReducer} from "./reducer/AuditReducer";
import {ExperimentReducer} from "./reducer/ExperimentReducer";
import {calculatorReducer} from "./reducer/CalculatorReducer";
import {tabletGameReducer} from "./reducer/TabletGameReducer";
import {scriptExecutionReducer} from "./reducer/ScriptExecutionReducer";

const ExpDesignReducer = combineReducers({
	users: userListReducer,
	workflows: workflowListReducer,
	experiment: ExperimentReducer,
	globalDef: globalDefReducer,
	dataSelection: dataSelectionReducer,
	sessionState: expSessionStateReducer,
	audit: auditReducer,
    tasks: taskListReducer,
	calculator: calculatorReducer,
	tabletGame: tabletGameReducer,
	script: scriptExecutionReducer
});

export default ExpDesignReducer;
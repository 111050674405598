import PropTypes from 'prop-types';
import React from "react";


export const WorkflowOutputsTable = ({outputs})=> {

    let keys = Object.keys(outputs);


    let template =(el)=>{
        return   (
            <React.Fragment>
                <tr>
                    <th colSpan="2">{el}</th>
                </tr>
                <tr>
                    <th>Type</th>
                    <td>{outputs[el].type}</td>
                </tr>
                <tr>
                    <th>Format</th>
                    <td>{outputs[el].measurementType}</td>
                </tr>
                <tr>
                    <th>Type of Image</th>
                    <td>{outputs[el].lookupTableId}</td>
                </tr>
            </React.Fragment>)
    } ;

    let result = [];

    keys.forEach((el)=>{result.push (template(el))});

    return (
        <React.Fragment>
            {result}
        </React.Fragment>
    )

};
import React from 'react';
import OptionallyDisplayed from './OptionallyDisplayed.js';
import PropTypes from 'prop-types';
import {InputText} from "primereact/components/inputtext/InputText";

export default class InputTextWithValidation extends React.Component {

    constructor(props) {
        super(props);
        this.shouldDisplayError = this.shouldDisplayError.bind(this);
    }

    shouldDisplayError() {
        return this.props.showError && this.props.errorText !== "";
    }

    render() {
        let uiError =  this.shouldDisplayError()?"ui-state-error ui-state-filled":"";
        return (
            <React.Fragment>
                <InputText id={this.props.id} type={this.props.type || "text"} placeholder={this.props.placeholder}
                       value={this.props.value} onChange={this.props.onFieldChanged} style={this.props.style} className={uiError} />
                <OptionallyDisplayed display={this.shouldDisplayError()}>
                    <div className="validation-error">
                        <span className="text">{this.props.errorText}</span>
                    </div>
                </OptionallyDisplayed>
            </React.Fragment>
        );
    }
}
InputTextWithValidation.propTypes = {
    id:PropTypes.string.isRequired,
    showError: PropTypes.bool.isRequired,
    onFieldChanged: PropTypes.func.isRequired,
    value:PropTypes.string,
    style:PropTypes.object
};
/**
 * logging data from
 * @param data  vtkImageData
 */
export function loggingDataset(data){
    console.log('Spacing:');
    console.log(data.getSpacing());
    console.log('Origin:');
    console.log(data.getOrigin());
    console.log('Dimension:');
    console.log(data.getDimensions());
    console.log('Data range');
    console.log( data.getPointData().getScalars().getRange());
    console.log('Extent:');
    console.log(data.getExtent());
    console.log('Direction:');
    console.log(data.getDirection());
}

/**
 * Use: this.state.renderWindow.getRenderers()[idx]
 * Logging camera state
 * @param renderer
 */
export function loggingCameraSettings(renderer) {
    console.log('Clipping Range:');
    console.log(renderer.getActiveCamera().getClippingRange());
    console.log('position:');
    console.log(renderer.getActiveCamera().getPosition());
    console.log('foca');
    console.log(renderer.getActiveCamera().getFocalPoint());
    console.log('distance');
    console.log(renderer.getActiveCamera().getDistance());
    console.log('direction of projection');
    console.log(renderer.getActiveCamera().getDirectionOfProjection());
}
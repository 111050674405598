import React from "react";
import PropTypes from "prop-types";
import Link from "react-router-dom/es/Link";
import {withRouter} from "react-router";

const LpItemListElement = ({item,history}) => {

    function delete_presentation(){
        alert("delete");
    }

    return (
        <React.Fragment>
            <div className="experimentListElement">
                <div className = "ui-g-5">{item.name}</div>
                <div className = "ui-g-2">{item.status}</div>
                <div className = "ui-g-1">Time</div>
                <div className = "ui-g-1">
                    <Link to={'/livePresenter/' + item.id}>Edit</Link>
                </div>
                <div className = "ui-g-1">
                    <Link to={'/livePresenter/playManual/' + item.id}>Play Manual</Link>
                </div>
                <div className = "ui-g-1">
                    <Link to={'/livePresenter/playAuto/' + item.id}>Play Auto</Link>
                </div>
                <div className = "ui-g-1" onClick={delete_presentation}>
                    Delete
                </div>
            </div>
        </React.Fragment>
    )
};
export default withRouter(LpItemListElement);

LpItemListElement.propTypes = {
    item: PropTypes.object.isRequired
};

import {connect} from "react-redux";
import {
    saveStatModel,
    updateExperimentActionCreator, retrieveExperimentActionCreator, saveExperimentActionCreator,
    defineVariablesActionCreator
} from "../action/StatisticalModelAction";
import StatisticalModel from "../component/StatisticalModel";
import {setStep, updateWizardSession} from "../action/WizardStepAction";
// import GlobalDefinition from "../component/Overview";
import {getDataset} from "../action/DataSelectionAction";
import {
    calculateSampleSizeActionCreator, getStatisticalPowerPlot,
    setCalculatorParameters
} from "../action/CalculatorAction";

const mapStateToProps = state => {
    return {
        experiment: state.expDesign.experiment,
        modelsList: state.visu.models.models,
        dataSelection: state.expDesign.dataSelection,
        calculator: state.expDesign.calculator

    }
};

const mapDispatchToProps = dispatch => {
    return {
        storeModel: (model) => {dispatch(saveStatModel(model))},
        updateVariables: (model) => {dispatch(defineVariablesActionCreator(model))},
        getDataset: (experiment) => {
            dispatch(getDataset(experiment))
        },
        saveExperiment:(experiment)=>{dispatch(saveExperimentActionCreator(experiment))},
        updateExperiment: (experiment) =>{dispatch(updateExperimentActionCreator(experiment))},
        setStep:   (def) =>{dispatch(setStep(def))},
        //Calculator methods
        getStatisticalPowerPlot: (u) =>{dispatch(getStatisticalPowerPlot(u))},
        calculateSampleSizeActionCreator:   () =>{dispatch(calculateSampleSizeActionCreator())},
        setCalculatorParameters: (parameters)=>{dispatch(setCalculatorParameters(parameters))}
    }
};


const statContainerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerStatisticalModel = statContainerCreator(StatisticalModel);
//export const ContainerGlobalDefinition = statContainerCreator(Overview);
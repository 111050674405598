import React from 'react';
import OptionallyDisplayed from './OptionallyDisplayed.js';
import PropTypes from 'prop-types';
import {InputText} from "primereact/components/inputtext/InputText";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {AutoComplete} from "primereact/components/autocomplete/AutoComplete";

export default class AutocompleteWithValidation extends React.Component {

    constructor(props) {
        super(props);
        this.shouldDisplayError = this.shouldDisplayError.bind(this);
    }

    shouldDisplayError() {
        return this.props.showError && this.props.errorText !== "";
    }

    render() {
        let uiError =  this.shouldDisplayError()?"ui-state-error":"";
        return (
            <React.Fragment>
                <AutoComplete {...this.props} className={uiError}/>;
                <OptionallyDisplayed display={this.shouldDisplayError()}>
                    <div className="validation-error">
                        <span className="text">{this.props.errorText}</span>
                    </div>
                </OptionallyDisplayed>
            </React.Fragment>
        );
    }
}
AutocompleteWithValidation.propTypes = {
    name:PropTypes.string.isRequired,
    options:PropTypes.array,
    showError: PropTypes.bool.isRequired,
    onFieldChanged: PropTypes.func.isRequired,
    value:PropTypes.string,
    style:PropTypes.object,
    placeholder:PropTypes.string
};
import React from 'react';
// import {Link} from "react-router-dom";
import {ContainerPresentations} from "../livePresenter/container/ContainerPresentations"

export default class LivePresenter extends React.Component{
    render() {
        console.log("Live presenter ... render");
        return (
            <ContainerPresentations/>
        )
    }
}
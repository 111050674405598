
//======================= ENVIRONMENT ==============================================================
export  const HOST_URL =HOST_URL_ENV;

//======================= DATA CONSTANTS=============================================================

// --------------------REQUEST STATUS VALUES---------------------------------------------------------------
export const REQUEST_STATUS_REQUESTED='requested';
export const REQUEST_STATUS_FAIL='failure';
export const REQUEST_STATUS_SUCCESS='success';



//----------------------EXPERIMENT STATUS CONSTANTS------------------------------------------------------------
export const EXPERIMENT_PROPERTY_VALUE_STATUS__COMPLETED = 'COMPLETED';
export const EXPERIMENT_PROPERTY_VALUE_STATUS__DRAFT = 'DRAFT';
export const EXPERIMENT_PROPERTY_VALUE_STATUS__REVIEW = 'REVIEW';
export const EXPERIMENT_PROPERTY_VALUE_STATUS__TRASHED = 'TRASHED';
export const EXPERIMENT_PROPERTY_VALUE_STATUS__EXECUTION = 'EXECUTION';




//------------------------VARIABLE VALUES------------------------------------------------------------
export const VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL = 'clinicalVariable';
export const VARIABLE_PROPERTY_VALUE_META_TYPE__IMAGING  = 'imagingVariable';

export const VARIABLE_PROPERTIES_NAMES = {
    VARIABLE_TYPE : 'variableType',
    DESCRIPTION : 'description',
    META_TYPE : 'metaType',
    MIN_VALUE : 'minValue',
    NAME : 'name',
    STATISTICAL_TYPE : 'statisticalType',
    UNITS : 'units',
    UUID : 'uuid'
};


export const VARIABLE_PROPERTY_VALUE_VARIABLE_TYPE = {
    NUMBER : 'Number',
    MULTIPLE_CHOICE : 'MultipleChoice',
    DATE : 'Date',
    TEXT : 'Text',


};


// -------------------- USER ROLES ---------------------------------------------
export const USER = {
  ROLES: {
    ADMIN: "admin",
    CONTRIBUTOR: "contributor",
    SCIENTIFIC: "scientific",
    DOCTOR: "doctor"
  }
}



//========================TYPE (OBJECT) PROPERTIES======================================================

//------------------------EXPERIMENT PROPERTIES-------------------------------------------------------------------
export const EXPERIMENT_PROPERTY_NAME__INCLUSION_CRITERIA = 'inclusion';
export const EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA = 'exclusion';
export const EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA = 'selectionCriteria';


export const EXPERIMENT_PROPERTY_NAME__COHORTS = 'cohortIds';  //AM scheme changed here ...
export const EXPERIMENT_PROPERTY_NAME__HYPOTHESIS='hypothesis';
export const EXPERIMENT_PROPERTY_NAME__NAME='name';
export const EXPERIMENT_PROPERTY_NAME__QUESTION='question';
export const EXPERIMENT_PROPERTY_NAME__DESCRIPTION='description';
export const EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES = 'systemVariablesForStatisticalModel';
export const EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID = 'statisticalModelId';
export const EXPERIMENT_PROPERTY_NAME__PROJECT_ID = 'projectId';
export const EXPERIMENT_PROPERTY_NAME__PRIVACY = 'privacy';
export const EXPERIMENT_PROPERTY_NAME__UUID = 'uuid';
export const EXPERIMENT_PROPERTY_NAME__REVISION_ID = 'revisionId';
export const EXPERIMENT_PROPERTY_NAME__TIMEFRAME = 'timeFrame';

//-----------------EXPERIMENT PROPERTIES VALUES----------------------


export const EXPERIMENT_PROPERTY_VALUE_PRIVACY__PUBLIC = 'PUBLIC';
export const EXPERIMENT_PROPERTY_VALUE_PRIVACY__PRIVATE = 'PRIVATE';


//----------------EXPERIMENT INSTANCE VARIABLE PROPERTIES- (according to JOI)-------
export const EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID = "variableId";
export const EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__SYMBOL = 'symbol';
export const EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__WORKFLOW_ID= 'workflowId';


//----------------CRITERIA INSTANCE VARIABLE PROPERTIES- (according to JOI)-------
export const EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VARIABLE_ID = "variableId";
export const EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__OPERATOR = "operator";
export const EXPERIMENT_INSTANCE_CRITERIA_PROPERTY_NAME__VALUE = "value";



// -----------------------TIMEFRAME------------------
export const TIMEFRAME_PROPERTIES_NAMES = {
    VARIABLE_ID : "variableId",
    REFERENCE_TIME_FRAME : "referenceTimeFrame",
    RELATIVE_REFERENCE_VARIABLE_ID : "relativeReferenceVariableId",
    PRIMARY_REFERENCE_TIME_POINT : "primaryReferenceTimePoint",
    TARGET_DATE : "targetDate",
    LOWER_DEVIATION_TIME_WINDOW : "lowerDeviationTimeWindow",
    UPPER_DEVIATION_TIME_WINDOW : "upperDeviationTimeWindow",
    UNIT : "unit"
};

export const TIMEFRAME_PROPERTY_VALUES__REFERENCE_TIME_FRAME = {
    RELATIVE:"relative",
    ABSOLUTE:"absolute"
};


//----------------COHORT PROPERTIES VALUES----------------------------------------
export const COHORT_PROPERTY_VALUE_PRIVACY__PUBLIC = 'public';
export const COHORT_PROPERTY_VALUE_PRIVACY__PRIVATE = 'private';


//----------------COHORT PROPERTIES-------------------------------------------------------------------
export const COHORT_PROPERTY_NAME__PRIVACY = 'privacy';
export const COHORT_PROPERTY_NAME__NAME='name';
export const COHORT_PROPERTY_NAME__UUID='uuid';
export const COHORT_PROPERTY_NAME__CREATION_DATE='creationDate';
export const COHORT_PROPERTY_NAME__COINVESTIGATORS='co-investigators';
export const COHORT_PROPERTY_NAME__SUBJECTS='subjects';
export const COHORT_PROPERTY_NAME__DESCRIPTION='description';
export const COHORT_PROPERTY_NAME__PI='pi';
export const COHORT_PROPERTY_NAME__SUMMARY_CLINICAL_TRIALS='summaryClinicalTrials';
export const COHORT_PROPERTY_NAME__SERVER_NAME='serverName';
export const COHORT_PROPERTY_NAME__REMOTE_ID='remoteId';
export const COHORT_PROPERTY_NAME__BIDS_STANDARD='bidsStandard';
export const COHORT_PROPERTY_NAME__SOURCE_SYSTEM='sourceSystem';
export const COHORT_PROPERTY_NAME__SOURCE_OBJECT='sourceObject';
export const COHORT_PROPERTY_NAME__SOURCE_ETAG='sourceEtag';


//----------------ANNOTATION PROPERTIES-------------------------------------------------------------------
export const ANNOTATION_PROPERTY_NAME__ID = 'id';
export const ANNOTATION_PROPERTY_NAME__ROI = 'roi';
export const ANNOTATION_PROPERTY_NAME__ROI_ROI_STATUS = 'roiStatus';
export const ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB = 'roiSub';
export const ANNOTATION_PROPERTY_VALUES__ROI_ROI_STATUS = [ "normal", "active"];

//----------------OPERATORS--------------------------------------------------------

export const ORDINAL_OPERATOR = "ORDINAL_OPERATOR";
export const LOGICAL_OPERATOR ="LOGICAL_OPERATOR";
export const CATEGORICAL_OPERATOR = "CATEGORICAL_OPERATOR";
export const TEXTUAL_OPERATOR ="TEXTUAL_OPERATOR";


export const LOGICAL_OPERATOR_INIT = {
    "type":"LOGICAL_OPERATOR",
    "label":"AND",
    "value": "AND"
};




// export const ALL_OPERATORS = ORDINAL_OPERATORS.concat(CATEGORICAL_OPERATORS,TEXTUAL_OPERATORS);

// constant for representation of +, - dropdowns
export const NUMBER_SIGN={
    NAME:"NUMBER_SIGN",
    VALUES:{
        PLUS:'PLUS',
        MINUS:'MINUS'
    },
    DROPDOWN_OPTIONS : [{label:"+",value:"PLUS"},{label:"-",value:"MINUS"}]
};

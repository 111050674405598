import React from 'react';


export default () =>
<div >
	<div className="footer">
		<a href="mailto:guttmann@bwh.harvard.edu" style={{marginRight:'16px'}}>Contact Us</a>
		<a href="" style={{marginRight:'16px'}}>Partners &amp; Contributors</a>
		<a href="" style={{marginRight:'16px'}}>FAQ</a>
	</div>
	<p className="footer">Copyright © 2012 - 2018 Charles R.G. Guttmann</p>
</div>
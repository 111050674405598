/* eslint-disable no-bitwise */
import { vec3 } from 'gl-matrix';
// import WebworkerPromise from 'webworker-promise';

import macro from 'vtk.js/Sources/macro';
import vtkImageData from 'vtk.js/Sources/Common/DataModel/ImageData';
import vtkDataArray from 'vtk.js/Sources/Common/Core/DataArray';

// import PaintFilterWorker from 'vtk.js/Sources/Filters/General/PaintFilter/PaintFilter.worker';

const { vtkErrorMacro } = macro;

// ----------------------------------------------------------------------------
// vtkPaintFilter methods
// ----------------------------------------------------------------------------


function SpinePaintFilter(publicAPI, model) {
    // Set our className
    model.classHierarchy.push('SpinePaintFilter');

    const globals = {
        // single-component labelmap
        buffer: null,
        dimensions: [0, 0, 0],
        prevPoint: null,
    };

    let renderers = [];
    let worker = null;
    let workerPromise = null;
    const history = {
        buffer: [],
        // current painted layer index
        cindex: -1,
        // colors: [],
    };
    const connectedOffset = []; //neighborhood matrix
    connectedOffset[0] = [[0, -1, 0],[0, 0, -1],[0, 1, 0],[0, 0, 1]];
    connectedOffset[1] = [[-1, 0, 0],[0, 0, -1],[1, 0, 0],[0, 0, 1]];
    connectedOffset[2] = [[-1, 0, 0],[0, -1, 0],[1, 0, 0],[0, 1, 0]];


    function getIndex(point,dims){
        return point[0] + point[1] * dims[0] + point[2] * dims[0] * dims[1];
    }

    function updateOffsets(){
        let offsets = {};
        offsets[0] = [];
        offsets[1] = [];
        offsets[2] = [];


        let amBrush = (model.label>0)?model.brushRadius - 1:model.eraserRadius-1;   // check which radius should be used

        _.map(offsets, function(val, key){
            var extent = [];
            if(key === "0"){
                extent = [0, 0, -amBrush, amBrush, -amBrush, amBrush];
            }else if(key === "1"){
                extent = [-amBrush, amBrush, 0, 0, -amBrush, amBrush];
            }else{
                extent = [-amBrush, amBrush, -amBrush, amBrush, 0, 0];
            }

            for(let i = extent[0]; i <= extent[1]; i++){
                for(let j = extent[2]; j <= extent[3]; j++){
                    for(let k = extent[4]; k <= extent[5]; k++){
                        val.push([i, j, k]);
                    }
                }
            }
        });
        return offsets;
    }

    // --------------------------------------------------------------------------
    function handlePaint(point) {
        const scalars = model.labelMap.getPointData().getScalars();
        const data = scalars.getData();

        const dims = model.labelMap.getDimensions();
        const jStride = dims[0];
        const kStride = dims[0] * dims[1];
        const index = point[0] + point[1] * jStride + point[2] * kStride;

        let offsets = updateOffsets()[model.orientation];

        let getValueAtPixel = (index)=>{
            return  data[getIndex(index,dims)];
        };

        let setValueAtPixel = (index,value)=>{
            const ind = getIndex(index,dims);
            data[ind]=value;
            // globals.buffer[ind]=value;
        };
        if(getValueAtPixel(index)===model.label)
            return;

        for(let i = 0; i < offsets.length; i++){
            let offset = offsets[i];
            let vox = [point[0] + offset[0], point[1] + offset[1], point[2] + offset[2]];
            setValueAtPixel(vox,model.label);
        }
        scalars.modified();
        model.labelMap.modified();
        publicAPI.modified();
    }

    publicAPI.renderAll = ()=>{
        renderers.forEach((el)=>{el()});
    };


    publicAPI.fill = (indexPt)=>{
        if (model.labelMap) {
            const scalars = model.labelMap.getPointData().getScalars();
            const data = scalars.getData();
            const dims = model.labelMap.getDimensions();
            // const worldPt = [point[0], point[1], point[2]];
            // const indexPt = [0, 0, 0];  //seed ijk coordinates
            // vec3.transformMat4(indexPt, worldPt, model.maskWorldToIndex);
            // indexPt[0] = Math.round(indexPt[0]);
            // indexPt[1] = Math.round(indexPt[1]);
            // indexPt[2] = Math.round(indexPt[2]);

            let getValueAtPixel = (index) => {
                return data[getIndex(index, dims)];
            };

            if (getValueAtPixel(indexPt) > 0) {  //check if point is already filled
                return;
            }
            let setValueAtPixel = (index, value) => {
                const ind = getIndex(index, dims);
                data[ind] = value;
                // globals.buffer[ind] = value;
            };

            // globals.buffer = new Uint8Array(dims[0] * dims[1] * dims[2]);

            let voxelCoords = indexPt.slice();
            let offset = connectedOffset[model.orientation];
            let frontpix = [voxelCoords];

            while (frontpix.length > 0) {
                let tempfront = [];
                frontpix.forEach(function (front) {
                    offset.forEach(function (offs) {
                        let off = [];
                        off[0] = offs[0] + front[0];
                        off[1] = offs[1] + front[1];
                        off[2] = offs[2] + front[2];
                        let currentLabel = getValueAtPixel(off);
                        if (currentLabel === 0) {
                            setValueAtPixel(off, model.label);
                            tempfront.push(off);
                        }
                    });
                });
                frontpix = tempfront;
            }
            setValueAtPixel(indexPt, model.label);//add initial Point at the end of processing
            saveToHistory();
            scalars.modified();
            model.labelMap.modified();
            publicAPI.modified();
            publicAPI.renderAll();
        }
    };

    publicAPI.startStroke = () => {
        if (model.labelMap) {
            const dims = model.labelMap.getDimensions();
            model.drawingState = true;
            // globals.buffer = new Uint8Array(dims[0]*dims[1]*dims[2]);

            // worker = new PaintFilterWorker();
            // workerPromise = new WebworkerPromise(worker);
            // workerPromise.exec('start', {
            //     bufferType: 'Uint8Array',
            //     dimensions: model.labelMap.getDimensions(),
            // });
        }
    };

    // --------------------------------------------------------------------------

    function saveToHistory (){
        const scalars = model.labelMap.getPointData().getScalars();
        const data = scalars.getData();

        // const strokeLabelMap = globals.buffer;

        if (history.cindex === 5) {
            history.buffer.shift(); //remove first element if 5
        } else {
            history.cindex++;
        }
        // history.colors.splice(history.cindex, history.colors.length);
        history.buffer.length = history.cindex;
        history.buffer.push(data.slice());
        //
        //         const bgScalars = model.backgroundImage.getPointData().getScalars();
        //         if (model.voxelFunc) {
        //             for (let i = 0; i < strokeLabelMap.length; i++) {
        //                 if (strokeLabelMap[i]) {
        //                     const voxel = bgScalars.getTuple(i);
        //                     const out = model.voxelFunc(voxel, strokeLabelMap[i], i);
        //                     if (out !== null) {
        //                         data[i] = out;
        //                     }
        //                 }
        //             }
        //         } else {
        // for (let i = 0; i < strokeLabelMap.length; i++) {
        //     if (history.cindex === 5) {
        //         // last bit will be shifted off
        //         const lastBit = history.buffer[i] & 0x1;
        //         history.buffer[i] = (history.buffer[i] >> 1) | lastBit;
        //     }
        //
        //     if (strokeLabelMap[i]) {
        //         data[i] = model.label;
        //         history.buffer[i] |= 1 << history.cindex;
        //     } else {
        //         history.buffer[i] &= ~(1 << history.cindex);
        //     }
        // }

    }

    publicAPI.endStroke = () => {
        if (model.labelMap) {
            model.drawingState = false;
            model.orientation = -1;
            // if (workerPromise) {
            //     workerPromise.exec('end').then((strokeBuffer) => {
            const scalars = model.labelMap.getPointData().getScalars();
            const data = scalars.getData();

            saveToHistory();

            //
            //         worker.terminate();
            //         worker = null;
            //         workerPromise = null;
            //
            //         scalars.setData(data);
            scalars.modified();
            model.labelMap.modified();
            publicAPI.modified();
            publicAPI.renderAll();
            //     });
            // }
        }
        ;
    }
    // --------------------------------------------------------------------------

    publicAPI.addPoint = (point) => {  //point- world position
        if (model.labelMap) {
            // if (workerPromise) {
            //     const worldPt = [point[0], point[1], point[2]];
            //     const indexPt = [0, 0, 0];
            //     vec3.transformMat4(indexPt, worldPt, model.maskWorldToIndex);
            //     indexPt[0] = Math.round(indexPt[0]);
            //     indexPt[1] = Math.round(indexPt[1]);
            //     indexPt[2] = Math.round(indexPt[2]);
            handlePaint(point);
            // }
        }
    };

    // --------------------------------------------------------------------------

    publicAPI.undo = () => {
        if (history.cindex > 0) {
            const scalars = model.labelMap.getPointData().getScalars();
            const data = scalars.getData();

            // for (let i = 0; i < history.buffer.length; i++) {
            //     let labeli = history.cindex - 1;
            //     while (labeli > -1 && !(history.buffer[i] & (1 << labeli))) {
            //         labeli--;
            //     }
            //     const label = history.colors[labeli] || 0;
            //     data[i] = label;
            // }


            history.cindex--;

            scalars.setData(history.buffer[history.cindex].slice());
            scalars.modified();
            model.labelMap.modified();
            publicAPI.modified();
            publicAPI.renderAll();
        }
    };

    // --------------------------------------------------------------------------

    publicAPI.redo = () => {
        if (history.cindex < 5) {
            const scalars = model.labelMap.getPointData().getScalars();
            const data = scalars.getData();
            // const labeli = history.cindex + 1;
            // const label = history.colors[labeli];

            // for (let i = 0; i < history.buffer.length; i++) {
            //     if (history.buffer[i] & (1 << labeli)) {
            //         data[i] = label;
            //     }
            // }


            if (history.buffer[history.cindex+1]){
                history.cindex++;
                scalars.setData(history.buffer[history.cindex].slice());
                scalars.modified();
                model.labelMap.modified();
                publicAPI.modified();
                publicAPI.renderAll();
            }

        }
    };

    // --------------------------------------------------------------------------

    const superSetLabelMap = publicAPI.setLabelMap;
    publicAPI.setLabelMap = (lm) => {
        if (superSetLabelMap(lm)) {
            // reset history layer
            // history.buffer = new Uint8Array(lm.getNumberOfPoints());
            history.buffer.push(new Uint8Array(lm.getNumberOfPoints()));
            history.cindex = 0;
        }
    };

    // This function is used to force re-render
    publicAPI.registerRender=(renderFunction)=>{
        renderers.push(renderFunction);
    };


    //This function is used to retrieve data from existing image.
    //Watch out! Computationally expensive since data are coregistered.
    publicAPI.setOverlayImageData = (overlayImageData)=>{
        const dims = model.labelMap.getDimensions();
        if(overlayImageData.getPointData().getScalars().getData().length!== model.labelMap.getPointData().getScalars().getData().length){
            overlayImageData.getPointData().getScalars().getData().forEach((el,index)=>{
                if(el>0){
                    const worldPt = overlayImageData.getPoint(index);
                    const indexPt = [0, 0, 0];
                    vec3.transformMat4(indexPt, worldPt, model.maskWorldToIndex);
                    indexPt[0] = Math.round(indexPt[0]);
                    indexPt[1] = Math.round(indexPt[1]);
                    indexPt[2] = Math.round(indexPt[2]);
                    const index2 =  getIndex(indexPt,dims);
                    model.labelMap.getPointData().getScalars().getData()[index2]=el;
                }
            });
            history.buffer.shift();
            history.buffer.push(model.labelMap.getPointData().getScalars().getData().slice());
        }
        else{
            history.buffer.shift();
            model.labelMap.getPointData().getScalars().setData(overlayImageData.getPointData().getScalars().getData());
            history.buffer.push(model.labelMap.getPointData().getScalars().getData().slice());
        }
    };

    // --------------------------------------------------------------------------

    publicAPI.requestData = (inData, outData) => {
        if (!model.backgroundImage) {
            vtkErrorMacro('No background image');
            return;
        }

        if (!model.backgroundImage.getPointData().getScalars()) {
            vtkErrorMacro('Background image has no scalars');
            return;
        }

        if (!model.labelMap) {
            // clone background image properties
            const labelMap = vtkImageData.newInstance(
                model.backgroundImage.get('spacing', 'origin', 'direction')
            );
            labelMap.setDimensions(model.backgroundImage.getDimensions());
            labelMap.computeTransforms();

            // right now only support 256 labels
            const values = new Uint8Array(model.backgroundImage.getNumberOfPoints());
            const dataArray = vtkDataArray.newInstance({
                numberOfComponents: 1, // labelmap with single component
                values,
            });
            labelMap.getPointData().setScalars(dataArray);

            publicAPI.setLabelMap(labelMap);
        }

        if (!model.maskWorldToIndex) {
            model.maskWorldToIndex = model.labelMap.getWorldToIndex();
        }

        const scalars = model.labelMap.getPointData().getScalars();

        if (!scalars) {
            vtkErrorMacro('Mask image has no scalars');
            return;
        }

        model.labelMap.modified();

        outData[0] = model.labelMap;
    };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
    backgroundImage: null,
    labelMap: null,
    maskWorldToIndex: null,
    voxelFunc: null,
    brushRadius: 1,
    eraserRadius: 1,
    label: 0,
    orientation:-1,
    drawingState:false,
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
    Object.assign(model, DEFAULT_VALUES, initialValues);

    // Make this a VTK object
    macro.obj(publicAPI, model);

    // Also make it an algorithm with no input and one output
    macro.algo(publicAPI, model, 0, 1);

    macro.setGet(publicAPI, model, [
        'backgroundImage',
        'labelMap',
        'labelWorldToIndex',
        'voxelFunc',
        'label',
        'brushRadius',
        'eraserRadius',
        'orientation',
        'drawingState',  //this is used for handling interactions:  leftpressed & mousemove
        'forceRender'
    ]);

    // Object specific methods
    SpinePaintFilter(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'SpinePaintFilter');

// ----------------------------------------------------------------------------

export default { newInstance, extend };

import {
    EXPDESIGN_DATASET_CLEAR,
    EXPDESIGN_R_CALC_FAIL, EXPDESIGN_R_CALC_REQUEST, EXPDESIGN_R_CALC_SUCCESS
} from '../action/actionType'
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";


export const auditReducer = (state = { modelParameters:{}, modelParametersState:""}, action) => {
    switch (action.type) {
        case EXPDESIGN_DATASET_CLEAR:
            return Object.assign({}, {
                modelParametersState: "CLEARED",
            });
        case EXPDESIGN_R_CALC_REQUEST:
            return Object.assign({},state, {
                modelParametersState:'requested'
            });
        case EXPDESIGN_R_CALC_FAIL:
            return Object.assign({},state, {
                modelParametersState: 'failure'
            });
        case EXPDESIGN_R_CALC_SUCCESS:
            return Object.assign({},state, {
                modelParametersState: 'success',
                modelParameters: action.model
            });

        default:
            return state;
    }
};
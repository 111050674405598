import macro from 'vtk.js/Sources/macro';
import vtkImageData from 'vtk.js/Sources/Common/DataModel/ImageData';
import vtkDataArray from 'vtk.js/Sources/Common/Core/DataArray';
import Constants from 'vtk.js/Sources/Rendering/Core/ImageMapper/Constants';
const { vtkErrorMacro } = macro;

const { SlicingMode } = Constants;//AM
// ----------------------------------------------------------------------------
// vtkImageSliceFilter methods
// ----------------------------------------------------------------------------

function vtkSpineImageSliceFilter(publicAPI, model) {
    // Set our className
    model.classHierarchy.push('vtkSpineImageSliceFilter');

    publicAPI.requestData = (inData, outData) => {
        // implement requestData
        const input = inData[0];

        if (!input) {
            vtkErrorMacro('Invalid or missing input');
            return;
        }

        const scalars = input.getPointData().getScalars();

        if (!scalars) {
            vtkErrorMacro('No scalars from input');
            return;
        }

        const datasetDefinition = input.get('extent', 'spacing', 'origin');
        // datasetDefinition.origin=[41.430,43.183,-90.6];//TO REMOVE
        datasetDefinition.extent[4] = model.sliceIndex;
        datasetDefinition.extent[5] = datasetDefinition.extent[4];

        const numberOfComponents = scalars.getNumberOfComponents();
        const sliceSize =
            (datasetDefinition.extent[1] - datasetDefinition.extent[0] + 1) *
            (datasetDefinition.extent[3] - datasetDefinition.extent[2] + 1) *
            numberOfComponents;
        const offset = sliceSize * model.sliceIndex;
        let sliceRawArray =[];
        if (model.sliceIndex<0  || (offset + sliceSize)>scalars.getData().length)
             sliceRawArray = new Array(sliceSize).fill(0);
            else
             sliceRawArray = scalars.getData().slice(offset, offset + sliceSize);
        const sliceArray = vtkDataArray.newInstance({
            name: scalars.getName(),
            numberOfComponents,
            values: sliceRawArray,
        });

        const output = vtkImageData.newInstance(datasetDefinition);
        output.getPointData().setScalars(sliceArray);
        // console.log('filter extent:');
        // console.log(datasetDefinition.extent);
        // console.log('filter spacing:');
        // console.log(datasetDefinition.spacing);
        // console.log('filter origin:');
        // console.log(datasetDefinition.origin);

        outData[0] = output;
    };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
    sliceIndex: 0,
    slicingMode:SlicingMode.K   //AM
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
    Object.assign(model, DEFAULT_VALUES, initialValues);

    // Make this a VTK object
    macro.obj(publicAPI, model);

    // Also make it an algorithm with one input and one output
    macro.algo(publicAPI, model, 1, 1);

    macro.setGet(publicAPI, model, ['sliceIndex', 'orientation']);

    // Object specific methods
    vtkSpineImageSliceFilter(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'vtkSpineImageSliceFilter');

// ----------------------------------------------------------------------------

export default { newInstance, extend };

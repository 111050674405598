/**
 * Helper functions for searching strings with RegExp and wildcards.
 */


/**
 * Helper for searching with asterisk.
 * Case sensitive - to call it in insensitive mode, first use toLowerCase (upper)
 * @param str - original string
 * @param rule - filtering value
 * @returns {boolean} - true if match
 */
export function matchRule(str, rule) {

    rule=(rule.charAt(0)==='*')?rule:'*'+rule;
    rule=(rule[rule.length-1]==='*')?rule:rule+'*';
    // "."  => Find a single character, except newline or line terminator
    // ".*" => Matches any string that contains zero or more characters
    rule = rule.split("*").join(".*");

    // "^"  => Matches any string with the following at the beginning of it
    // "$"  => Matches any string with that in front at the end of it
    rule = "^" + rule + "$"

    //Create a regular expression object for matching string
    const regex = new RegExp(rule);

    //Returns true if it finds a match, otherwise it returns false
    return regex.test(str);
}
import React from 'react';
import PropTypes from 'prop-types';
import vtkRenderWindow from 'vtk.js/Sources/Rendering/Core/RenderWindow';
import vtkRenderer from 'vtk.js/Sources/Rendering/Core/Renderer';
import vtkImageMapper from 'vtk.js/Sources/Rendering/Core/ImageMapper';
import vtkImageSlice from 'vtk.js/Sources/Rendering/Core/ImageSlice';
import vtkOpenGLRenderWindow from 'vtk.js/Sources/Rendering/OpenGL/RenderWindow';
import vtkRenderWindowInteractor from 'vtk.js/Sources/Rendering/Core/RenderWindowInteractor';
import * as SpineInteractorStyleImage from "./SpineInteractorStyleImage";
import vtkCellPicker from 'vtk.js/Sources/Rendering/Core/CellPicker';
import {Slider} from "primereact/components/slider/Slider";
import vtkActor from "vtk.js/Sources/Rendering/Core/Actor/index";
import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper/index";
import vtkSphereSource from 'vtk.js/Sources/Filters/Sources/SphereSource';
import vtkLabelWidget from 'vtk.js/Sources/Interaction/Widgets/LabelWidget';

import {loggingDataset} from "./MRIBrowserLogger";
import {REQUEST_STATUS_SUCCESS} from "../../Constants";



const PLANES = {SAGITTAL: 0, CORONAL: 1, AXIAL: 2};

/** BrainBrowser-like component for annotating 3 D slices.
 * This is unfinished version
 *
 *  See props at the bottom.
 */
class AnnotationBrowser extends React.Component {

    constructor() {
        super();
        this.state = {
            renderWindow: vtkRenderWindow.newInstance(),
            renderer: vtkRenderer.newInstance(),
            originTranslation: [],
            isError: false,
            dataRange: [0, 128],
            currentImageSlice: 0,
            direction: [1, 0, 0, 0, 1, 0, 0, 0, 1], //original data direction
            colorLevel: 128,
            colorWindow: 100,
            imageActor: vtkImageSlice.newInstance(),
        };
        this.onWLRangeChange = this.onWLRangeChange.bind(this);
        this.onCLRangeChange = this.onCLRangeChange.bind(this);
        this.changeSlice = this.changeSlice.bind(this);
        this.renderROI = this.renderROI.bind(this);
    }

    componentDidMount() {
        this.createBrowser();
    }

    /** This is function responsible for rendering spheres for ROI's.
     *
     * @param roi
     */
    renderROI(roi){
        const sphere = vtkSphereSource.newInstance();
        sphere.setCenter(roi.roiPosition);
        sphere.setRadius(2.0);
        const sphereMapper = vtkMapper.newInstance();
        sphereMapper.setInputData(sphere.getOutputData());
        const sphereActor = vtkActor.newInstance();
        sphereActor.setMapper(sphereMapper);
        switch (roi.roiStatus){
            case "active":{
                sphereActor.getProperty().setColor(1.0, 105.0/255, 180.0/255);
                break;}
            case "normal":{
                sphereActor.getProperty().setColor(0.0, 1.0, 0.0);
                break;
            }
            default :{
                sphereActor.getProperty().setColor(1.0, 0.0, 0.0);
                break;
            }

        }

        this.state.renderer.addActor(sphereActor);
    }

    /**
     * TODO Unfinished:
     *  - no clipping (spheres are visible all the time)
     *  -
     *
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps,prevState) {

        if (prevProps!=this.props){
        console.log("Updated Props: ",prevProps);
        this.props.annotationData.map((el)=>{return el.roi}).forEach((el,index)=>{
            // if (!(prevProps.annotationData[index]!=null))

            this.renderROI(el)
        });
        }
        else {
            // console.log("Updated State: ", prevState);
            let actors = this.state.renderer.getActors();
            let bb = this.state.imageActor.getMapper().getBoundsForSlice();
            for(let i=1;i<actors.length;i++){
                let bba = actors[i].getBounds();
                if (bba[this.props.viewportMode*2]>bb[this.props.viewportMode*2])
                    actors[i].setVisibility(true);
                else
                    actors[i].setVisibility(false);
            };


        }

    }

    /**
     * Window Level Range onChange handler.
     * Controls the window in a window level mapping of the input image. Window
     * level mapping is a technique to map the raw data values of an image
     * into screen intensities in a manner akin to
     *
     * pixelIntensity = (inputValue - level)/window;
     *
     * @param event
     */
    onWLRangeChange(event) {
        const cl = Number((event ? event : this.state.colorWindow).value);
        this.changeWL(cl);
        this.refreshRenderWindow();
    }

    /**
     * Change Window Level
     * @param wl - value
     */
    changeWL(wl) {
        this.state.imageActor.getProperty().setColorWindow(wl);
        this.setState({colorWindow: wl});
    }

    /**
     * Color Level Range onChange handler.
     * @see above
     * @param event
     */
    onCLRangeChange(event) {
        const cl = Number((event ? event : this.state.colorLevel).value);
        this.changeCL(cl);
        this.refreshRenderWindow();
    }

    /**
     * Change Color Level
     * @param cl - value
     */
    changeCL(cl) {
        this.state.imageActor.getProperty().setColorLevel(cl);
        this.setState({colorLevel: cl});
    }

    /**
     * Handler for slice change [k=2]
     * @param event
     */
    changeSlice(event) {
        this.setState({currentImageSlice: event.value});
        this.state.imageActor.getMapper().setSlice(event.value);
        this.refreshRenderWindow();
    }



    refreshRenderWindow() {
        this.state.renderWindow.render();
    }


    async createBrowser() {
        let thisView = this;  // this autoreference is needed for closures
        let initialSlice = 0;

        const kRenderer = this.state.renderer;
        kRenderer.setViewport([0, 0, 1, 1]);

        const interactorStyle2D = SpineInteractorStyleImage.newInstance();
        interactorStyle2D.setInteractionMode('IMAGE_SLICING');
        const renderWindow = thisView.state.renderWindow;
        const openglRenderWindow = vtkOpenGLRenderWindow.newInstance();
        renderWindow.addView(openglRenderWindow);
        openglRenderWindow.setContainer(this.node);

        const interactor = vtkRenderWindowInteractor.newInstance();
        interactor.setInteractorStyle(interactorStyle2D); // in case using predefined styles - SpineIneractorStyleImage
        interactor.setView(openglRenderWindow);
        interactor.initialize();
        interactor.bindEvents(this.node);

        const imageActor = this.state.imageActor;
        const imageMapper = vtkImageMapper.newInstance();

        const picker = vtkCellPicker.newInstance();
        renderWindow.getInteractor().setPicker(picker);
        picker.setPickFromList(1);
        picker.initializePickList();
        picker.addPickList(imageActor);


        this.props.annotationData.map((el)=>{return el.roi}).forEach((el)=>{this.renderROI(el)});


        renderWindow.getInteractor().onRightButtonPress((callData) => {
            if (kRenderer !== callData.pokedRenderer) {
                return;
            }

            const pos = callData.position;
            const point = [pos.x, pos.y, 0.0];
            console.log(`Pick at: ${point}`);
            picker.pick(point, kRenderer);

            if (picker.getActors().length === 0) {
                const pickedPoint = picker.getPickPosition();
                console.log(`No cells picked, default: ${pickedPoint}`);
                // addRoi(pickedPoint);
                this.props.addRow({ "roiType":"Point",
                    "roiCellIJK":[130,122,24],
                    "roiPosition":pickedPoint});

            } else {
                const pickedCellId = picker.getCellId();
                console.log('Picked cell: ', pickedCellId);

                const pickedPoints = picker.getPickedPositions();
                for (let i = 0; i < pickedPoints.length; i++) {
                    const pickedPoint = pickedPoints[i];
                    console.log(`Picked: ${pickedPoint}`);
                    // addRoi(pickedPoint);
                    this.props.addRow({
                        "roiType":"Point",
                        "roiStatus":"active",
                        "roiCellIJK":[130,122,24],
                        "roiPosition":pickedPoint});
                }
            }

            renderWindow.render();
        });


        renderWindow.addRenderer(kRenderer);
        kRenderer.setBackground(0, 0, 0);
        kRenderer.getActiveCamera().setParallelProjection(true);
        kRenderer.addActor(imageActor);


        const loadOriginalImage = (data) => {
            console.log('Original image');
            loggingDataset(data);
            const dataRange = data
                .getPointData()
                .getScalars()
                .getRange();

            thisView.changeWL(dataRange[1] - dataRange[0]);
            thisView.changeCL(dataRange[0] + (dataRange[1] - dataRange[0]) * .5);
            thisView.setState({dataRange: dataRange});


            //  if initial slices is prop, then use it, otherwise set in the middle of volume
            if (this.props.initialSlice != null)
                initialSlice = this.props.initialSlice;
            else {
                initialSlice = Math.floor((data.getExtent()[this.props.viewportMode * 2 + 1] - data.getExtent()[this.props.viewportMode * 2]) / 2);
            }

            imageMapper.setInputData(data);
            thisView.setState({currentImageSlice: initialSlice});
            imageActor.setMapper(imageMapper);
            imageActor.getProperty().setOpacity(1.0);//!!!!!!

            switch(this.props.viewportMode) {
                case PLANES.SAGITTAL:
                     imageMapper.setISlice(initialSlice);
                     kRenderer.getActiveCamera().set({position: [1, 0, 0]});
                     kRenderer.getActiveCamera().set({viewUp: [0, 0, 1]});
                     break;
                case PLANES.CORONAL:
                    imageMapper.setJSlice(initialSlice);
                    kRenderer.getActiveCamera().set({position: [0, -1, 0]});
                    kRenderer.getActiveCamera().set({viewUp: [0, 1, 0]});
                    break;
                case PLANES.AXIAL:
                    imageMapper.setKSlice(initialSlice);
                    kRenderer.getActiveCamera().set({position: [0, 0, -1]});
                    kRenderer.getActiveCamera().set({viewUp: [0, -1, 0]});
                    break;
            }

            kRenderer.resetCamera();

            thisView.setState({colorLevel: (dataRange[0] + dataRange[1]) / 2});
            this.refreshRenderWindow();

        };
        loadOriginalImage(this.props.imageData);
        }



    render() {
        let spinner = null;

        if (!this.props.imageState===REQUEST_STATUS_SUCCESS ) {
            spinner = <div> Loading data: <i className="fa fa-spinner fa-spin"/></div>;
        }
        return (
            <div className={"ui-g-12"}>
                <div className={"ui-g-12"}>
                    <div className={"ui-g-11"}>
                        <Slider value={this.state.currentImageSlice}
                                min={0}
                                max={this.props.imageData.getExtent()[this.props.viewportMode * 2 + 1]}
                                onChange={this.changeSlice}/>
                    </div>
                    <div className={"ui-g-1"}>
                        {this.state.currentImageSlice}
                    </div>
                </div>
                {/*<div className={"ui-g-12"}>*/}
                <div ref={node => this.node = node}/>
                {/*</div>*/}
                {/*<div className={"ui-g-6"}>*/}
                    {/*<div>Color level</div>*/}
                    {/*<Slider value={this.state.colorLevel}*/}
                            {/*min={this.state.dataRange[0]}*/}
                            {/*max={this.state.dataRange[1]}*/}
                            {/*onChange={this.onCLRangeChange}/>*/}
                {/*</div>*/}
                {/*<div className={"ui-g-6"}>*/}
                    {/*<div>Color window</div>*/}
                    {/*<Slider value={this.state.colorWindow}*/}
                            {/*min={1}*/}
                            {/*max={this.state.dataRange[1]}*/}
                            {/*onChange={this.onWLRangeChange}/>*/}
                {/*</div>*/}

            </div>
        );

    }
}

AnnotationBrowser.defaultProps = {
    viewportMode: PLANES.SAGITTAL, //
    // initialSlice:0,
};
AnnotationBrowser.propTypes = {
    viewportMode: PropTypes.number,
    initialSlice: PropTypes.number,
    imageData:PropTypes.object.isRequired, // object of vtkData class (VTK.js)
    annotationData: PropTypes.array
};

export default AnnotationBrowser;
import React from 'react'
import { connect } from 'react-redux'
import Logout from "../component/Logout"
import {logout} from "../action/LogoutAction.jsx"

const mapStateToProps = state => {
    return{
        auth : state.auth
    }
};

const mapDispatchToProps = dispatch => {
    return{
          onLogout : auth =>{
            dispatch(logout(auth))
        }
    }
};

const ContainerLogout = connect(
    mapStateToProps,
    mapDispatchToProps)(Logout);

export default ContainerLogout
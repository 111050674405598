import GlobalDefinition from "../component/Overview";
import {connect} from "react-redux";
import {overviewNextActionCreator, updateGlobalDefinition} from "../action/OverviewAction";

const mapStateToProps = (state,ownProps) => {
    return {
        experiment: state.expDesign.experiment
    }
};

const mapDispatchToProps = dispatch => {
    return {
        saveGlobalDefinition: (def) => {dispatch(updateGlobalDefinition(def))},
        nextAndSave:(hasUpdated)=>{dispatch(overviewNextActionCreator(hasUpdated))}
    }
};

export const ContainerOverview = connect( mapStateToProps, mapDispatchToProps)(GlobalDefinition);
import React from "react";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import PropTypes from 'prop-types';
import {ColorDropdown} from "./ColorDropdown";

/**
 * Dumb component for displaying toolbar for Filling Tool.
 * @returns {*}
 */
export const FillingToolbar = ({toolProperties, changeToolProperties,colorOptions}) => {

    let changeProperties = (arg, value) => {
        let pp = Object.assign({}, toolProperties);
        pp[arg] = value;
        changeToolProperties(pp);
    };

    return (
        <Toolbar style={{display: "inline-block"}}>
            <label style={{marginLeft: "1em"}}>Label:</label>
            <ColorDropdown options={colorOptions} value={toolProperties.colorValue}
                           onChange={(e) => changeProperties('colorValue', e)}
            />
        </Toolbar>
    );
};
FillingToolbar.propTypes = {
    toolProperties: PropTypes.object.isRequired,
    changeToolProperties: PropTypes.func.isRequired,
    colorOptions:PropTypes.array.isRequired
};

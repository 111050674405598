import React from 'react'
import { connect } from 'react-redux'
import ExpDesign from "../ExpDesign";
import {setStep, updateWizardSession} from "../action/WizardStepAction";
import {withRouter} from "react-router";
import {retrieveExperimentActionCreator} from "../action/StatisticalModelAction";
import {getStudyList} from "../../visualization/action/CohortAction";
import {
    getAnatomicalVariableListActionCreator,
    getClinicalVariableListActionCreator, getMeasurementsListActionCreator, getMetaVariableListActionCreator,
    getPathologicalVariableListActionCreator,
    getVariableListActionCreator, getVariableOperatorsActionCreator
} from "../../visualization/action/VariablesAction";
import {getModelsListActionCreator} from "../../visualization/action/ModelAction";



const mapStateToProps = (state) => {
    return {
        sessionState: state.expDesign.sessionState,
        dataSelection: state.expDesign.dataSelection,
        studies:state.visu.studies,
        modelsList: state.visu.models,
        variablesList: state.visu.variables,
        experiment: state.expDesign.experiment
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setStep: (def) => {dispatch(setStep(def))},
        getVariables: ()=>{dispatch(getVariableListActionCreator())},
        getClinicalVariables: ()=>{dispatch(getClinicalVariableListActionCreator())},
        getAnatomicalVariables: ()=>{dispatch(getAnatomicalVariableListActionCreator())},
        getPathologicalVariables: ()=>{dispatch(getPathologicalVariableListActionCreator())},
        getMetaVariables:()=>{dispatch(getMetaVariableListActionCreator())},
        getMeasurements:()=>{dispatch(getMeasurementsListActionCreator())},
        getModels: ()=>{dispatch(getModelsListActionCreator())},
        getOperators: ()=>{dispatch (getVariableOperatorsActionCreator())},
        getCohorts: () => {dispatch(getStudyList())},
        loadExperiment: (experiment) =>{
            dispatch(retrieveExperimentActionCreator(experiment));
            dispatch(setStep(1));
            dispatch(updateWizardSession(experiment));
        }
    }
};

const ContainerExpDesign =withRouter(connect(
    mapStateToProps,
    mapDispatchToProps)(ExpDesign));

export default ContainerExpDesign
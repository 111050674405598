import {connect} from "react-redux";
import WorkflowSelection from "../component/WorkflowSelection";
import {setStep} from "../action/WizardStepAction";
import {defineVariablesActionCreator, saveStatModel} from "../action/StatisticalModelAction";
import {getDataset} from "../action/DataSelectionAction";
import {getWorkflowList} from "../action/WorkflowAction";

const mapStateToProps = state => {
    return {
        // variables: state.expDesign.experiment.modelVariables,  //variable model instances
        dataSelection: state.expDesign.dataSelection,
        variablesList: state.visu.variables,   //variable definitions (all)
        modelsList:state.visu.models.models,
        experiment: state.expDesign.experiment
    }
};

const mapDispatchToProps = dispatch => {
    return {
        setStep:   (def) =>{dispatch(setStep(def))},
        storeModel: (model) => {dispatch(saveStatModel(model))},
        updateVariables: (model) => {dispatch(defineVariablesActionCreator(model))},
        getDataset: (experiment) => { dispatch(getDataset(experiment))},
        getWorkflows: (variable) => {dispatch(getWorkflowList(variable))}
    }
};

export default connect(
    mapStateToProps,
    mapDispatchToProps)(WorkflowSelection)
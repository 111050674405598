import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {
    MODEL_FAIL,
    MODEL_REQUEST, MODEL_SUCCESS,
    MODELS_FAIL, MODELS_REQUEST,
    MODELS_SUCCESS, SET_MODELS_SORTER, SET_MODELS_VISIBILITY_FILTER
} from "../action/actionType";


const setVisibilityFilter = (state, action) => {
    return Object.assign({}, state, {
        visibilityFilter: action.filter,
    });

};

const setSorter = (state, action) => {
    return Object.assign({}, state, {
        sorter: action.sorter,
    });

};

export const modelListReducer = (state = {
    modelsState: '',
    models: [],
    visibilityFilter:'',
    sorter:'SORT_BY_NAME_DESC'
}, action) => {
    switch (action.type) {
        case MODELS_REQUEST:
            return Object.assign({},state, {
                modelsState:REQUEST_STATUS_REQUESTED
            });
        case MODELS_FAIL:
            return Object.assign({},state, {
                modelsState: REQUEST_STATUS_FAIL
            });
        case MODELS_SUCCESS:
            return Object.assign({},state, {
                modelsState: REQUEST_STATUS_SUCCESS,
                models: action.models
            });
        case SET_MODELS_VISIBILITY_FILTER:
            return setVisibilityFilter(state, action);
        case SET_MODELS_SORTER:
            return setSorter(state, action);
        default:
            return state;
    }
};


export const modelReducer = (state = { modelState: '', model: {} }, action) => {
    switch (action.type) {
        case MODEL_REQUEST:
            return Object.assign({},state, {
                modelState: REQUEST_STATUS_REQUESTED
            });
        case MODEL_FAIL:
            return Object.assign({},state, {
                modelState: REQUEST_STATUS_FAIL
            });
        case MODEL_SUCCESS:
            return Object.assign({}, state, {
                modelState: REQUEST_STATUS_SUCCESS,
                model: action.model
            });
        default:
            return state;
    }
};
import {connect} from "react-redux";
import {
    addAnnotation, changeImageType, changeOrientation,
    clearAllAnnotations, clearAnnotatorState, getAnnotations, getMRIData, getOverlayData, initializeAnnotationTool,
    removeAnnotation,
    saveOverlayData,
    setActiveAnnotation,
    setSubAnnotation,
    updateAnnotationData, updateSingleViewer, updateViewerProperty
} from "../action/AnnotationAction";
import AnnotationTool3 from "../component/annotationTool/AnnotationTool3";



const mapStateToProps = state => {
    return {
        columns: state.visu.annotations.annotations.columns,
        data: state.visu.annotations.annotations.data,
        images:state.visu.annotations.images,
        overlays:state.visu.annotations.overlays,
        imageOptions: state.visu.annotations.imageOptions,
        viewersState: state.visu.annotations.viewersState
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getAnnotations: (id) => {
            dispatch(getAnnotations(id))
        },
        updateAnnotationData:(data)=>{
            dispatch(updateAnnotationData(data))
        },
        rmCallback:(data)=>{
            dispatch(removeAnnotation(data))
        },
        getOverlayData : (urlToFile)=>{
            dispatch(getOverlayData(urlToFile))
        },
        saveOverlayData : (imageData,id)=>{
            dispatch(saveOverlayData(imageData,id))
        },
        clearAll : ()=>{
            dispatch(clearAllAnnotations())
        },
        addAnnotation : (roi,index)=>{
            dispatch(addAnnotation(roi,index))
        },
        setActiveAnnotation: (annotation)=>{
            dispatch(setActiveAnnotation(annotation))
        },
        setSubAnnotation: (annotation)=>{
            dispatch(setSubAnnotation(annotation))
        },
        clearAnnotatorState:()=>{
            dispatch(clearAnnotatorState())
        },
        initialize:()=>{
            dispatch(initializeAnnotationTool())
        },
        updateViewer:(viewer,index)=>{
            dispatch(updateSingleViewer(viewer,index))
        },
        updateViewerProperty:(index,property,value)=>{
            dispatch(updateViewerProperty(index,property,value))
        },
        changeImageType:(index,value)=>{
            dispatch(changeImageType(index,value))
        },
        changeOrientation:(index,value)=>{
            dispatch(changeOrientation(index,value))
        }
    }
};

const containerCreator = connect( mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerAnnotations2 = containerCreator(AnnotationTool3);


import React from 'react'
import PropTypes from 'prop-types';
import {Panel} from "primereact/components/panel/Panel";
import {Card} from "primereact/components/card/Card";
import {Rating} from "primereact/components/rating/Rating";



/**
 * Panel displaying significance of estimated parameters.
 *
 */

export class TestSignificancePanel extends React.Component {

    constructor(){
        super();
        this.state={
            panelCollapsed:true
        };
        ["togglePanel"]
            .forEach(name => {
                this[name] = this[name].bind(this);
            }); //Binding methods
    }

    togglePanel(e) {
        this.setState({panelCollapsed: !this.state.panelCollapsed})
    }



    render() {
        const {modelStatus,vars,modelParameters} = this.props;
        const {panelCollapsed} = this.props;

        let significance = function () {
            if (v==null) return null;
            let value =0;
            if (v<0.1) value= 1;
            if (v<0.05) value= 2;
            if (v<0.01) value= 3;
            if (v<0.001) value= 4;
            return    <Rating value={value} stars={4} readonly={true}/> ;
        };
        let v = (modelStatus==='success')?modelParameters.betasPValues[1]:null;
        return (
            <Panel header={"Test significance"} toggleable={true}
                   collapsed={panelCollapsed}
                   onCollapse={() => this.togglePanel(false)}
                   onExpand={() => this.togglePanel(true)}>
                {modelStatus==='success'  && vars.length<3 &&

                <Card>
                    <i>p</i>-Value intercept: { Number.parseFloat(modelParameters.betasPValues[0]).toPrecision(4)}<br/>
                    <i>p</i>-Value slope: {Number.parseFloat(modelParameters.betasPValues[1]).toPrecision(4)}<br/>
                    <i>t</i>-Value intercept: {Number.parseFloat(modelParameters.betasTValues[0]).toPrecision(4)}<br/>
                    <i>t</i>-Value slope: {Number.parseFloat(modelParameters.betasTValues[1]).toPrecision(4)}<br/>
                    Test significance class is {(v!=null)?significance():" "}
                </Card>
                }
            </Panel>
        )
    }
}

TestSignificancePanel.propTypes = {
    modelStatus:PropTypes.string,
    vars:PropTypes.array,
    modelParameters:PropTypes.array
};

import React from "react";
import {ListSwitchType} from "../../root/ListTypeSwitch";
import {ItemList} from "../../project/component/ItemList";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import CohortOptionsItemCardElement from "./CohortOptionsItemCardElement";
import PropTypes from "prop-types";
import CohortOptionsItemListElement from "./CohortOptionsItemListElement";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {InputText} from "primereact/components/inputtext/InputText";
import {trimEllip} from "../../helpers/strings";
import ModelOptionsItemCardElement from "./ModelOptionsItemCardElement";
import ModelOptionsItemListElement from "./ModelOptionsItemListElement";
import {
    EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID,
    EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID, EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES, LINEAR_MODEL_INIT,
    USER_MODEL_INIT
} from "../../../Constants";
import {ScrollPanel} from "primereact/components/scrollpanel/ScrollPanel";

/**
 * Component used for SELECTION of models.
 * Visualization of selected models is implemented in different place!
 */

export default class ModelOptionsPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            areModelsInListMode: false
        };
        this.selectModel = this.selectModel.bind(this);
    }


    // Changes model and initialize variable set TODO Remove logic from here
    selectModel(id){
        let exp = this.props.experiment;
        exp[EXPERIMENT_PROPERTY_NAME__MODEL_STRUCTURE_ID]=id;

        switch(id) {
            case 'SLR':
            case 'GLR': {
                exp[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES] = Object.assign({}, {
                    "Y": {
                        "entityType": "SUBJECT",
                        "symbol":"Y"
                    },
                    "X1": {
                        "entityType": "SUBJECT",
                        "symbol":"X1"
                    }
                });
                break;}
            default : {
                    exp[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES] = Object.assign({},{
                        "Y": {
                               "entityType": "SUBJECT",
                               "symbol":"Y"
                        },
                        "X1": {
                            "entityType": "SUBJECT",
                            "symbol":"X1"
                        },
                        "X2": {
                            "entityType": "SUBJECT",
                            "symbol":"X2"
                        }
                    });
                break;
        }}

        this.props.onCloseModelSidebar();
        this.props.updateExperiment(exp);
    }

    render() {
        const {filteredModels,modelFilter,modelSorter,onFilterChange,onSorterChange,onCloseSidebar,experiment,updateExperiment} = this.props;
        const {areModelsInListMode} = this.state;

        let sorterOptions=[
            {value: 'SORT_BY_NAME_DESC', label: 'Name Descending'},
            {value: 'SORT_BY_NAME_ASC', label: 'Name Ascending'},
            {value: 'SORT_BY_SPINE_TYPE_DESC', label: 'Type Descending'},
            {value: 'SORT_BY_SPINE_TYPE_ASC', label: 'Type Ascending'},
            {value: 'SORT_BY_STATUS_DESC', label: 'Status Descending'},
            {value: 'SORT_BY_STATUS_ASC', label: 'Status Ascending'}
        ];
        return (
            <div style={{marginLeft:"10%",marginRight:"10%"}}>
                <div className="cohortDetailsMenu" >
                    <div style={{padding: "2em"}}>
                        <div className="selectCohortsTitle" style={{float:'left'}}>
                            {trimEllip(experiment.question,20)} > Select Statistical Model  </div>
                        <div style={{float: 'right'}}>
                            <InputText value={modelFilter} onChange={(e) => onFilterChange(e.target.value)}/>
                            <i className="fa fa-search" style={{marginRight:"1em"}}/>
                            <Dropdown options={sorterOptions}
                                      value={modelSorter}
                                      onChange={(e) => onSorterChange(e.value)}
                                      style={{border: 'none', background: '#f0f4f7',marginRight:"1em",width:"auto"}}/>
                            {/*<ListSwitchType clb={() => {*/}
                                {/*this.setState({areModelsInListMode: !areModelsInListMode})*/}
                            {/*}}*/}
                                            {/*isList={areModelsInListMode}/>*/}
                        </div>
                    </div>
                </div>
                <div style={{overflowY:'scroll',height:'calc(50vh - 96px)'}}>
                    {filteredModels.map((el)=>{return <ModelOptionsItemCardElement item={el} onSelect={this.selectModel} />})}

                {/*<ItemList model={filteredModels}*/}
                          {/*isList={areModelsInListMode}*/}
                          {/*classNames={["ui-g-3", "ui-g-3","ui-g-3","ui-g-3"]}*/}
                          {/*headers={["Name", "Author","Subjects","Select"]}*/}
                          {/*properties={["name", "author","whatever"]}*/}
                          {/*cardRenderer={<ModelOptionsItemCardElement {...this.props} onSelect={this.selectModel} />}*/}
                          {/*listRenderer={<ModelOptionsItemListElement  {...this.props}  onSelect={this.selectModel} />}*/}
                          {/*columnStyle="ui-g-12  ui-md-6 ui-lg-4"*/}
                          {/*optionalListStyle={{display:'inline-flex',width:"100%",*/}
                              {/*marginBottom:'1px', padding:'10px'}}*/}
                {/*/>*/}
                </div>
            </div>
        )
    }
}
ModelOptionsPanel.propTypes = {
    onCloseModelSidebar:PropTypes.func.isRequired, //closes sidebar - required for parent
    filteredModels: PropTypes.array.isRequired,//filtered models
    modelFilter:PropTypes.object,
    modelSorter:PropTypes.object,
    onFilterChange:PropTypes.func.isRequired,
    onSorterChange:PropTypes.func.isRequired,
    onSelect: PropTypes.func.isRequired,//callback to select model
    handleChange: PropTypes.func.isRequired,//does not apply here, just passed with others
    isList: PropTypes.bool,//does not apply here
    listModeClb: PropTypes.func,//does not apply here
    experiment:PropTypes.object,
    updateExperiment:PropTypes.func
};
import React from 'react'
/**
 * Helper functions for comparing, sorting and accessing JS objects.
 */


/**
 *  Helper func for sorting strings by name.
 * @param path - property path eg. "project.name", "name","project.experiments[0].name"
 * @returns {Function} negative, positive or 0 (as required in JS comparator interface for sort function)
 */
export function stringPropertyComparator(path) {
    return function(a,b) {
        const x = getNested(a, path, '.').toLowerCase();
        const y = getNested(b, path, '.').toLowerCase();
        return x < y ? -1 : x > y ? 1 : 0;
    };
}

/**
 * Helper func for sorting strings by date.
 * @param path - property path eg. "project.dueDate"
 * @returns {Function} negative, positive or 0 (as required in JS comparator interface for sort function)
 * TODO test it with timestamp, non-date etc.
 */
export function datePropertyComparator(path) {
    return  function(a,b){
        const x = new Date(getNested(a, path, '.'));
        const y = new Date(getNested(b, path, '.'));
        let result = y - x;
        if (isNaN(result)){
            if (isNaN(x.getTime()))   {             // date is not valid, eg. 'Completed'
            result = Number.POSITIVE_INFINITY;
        } else
            result = Number.NEGATIVE_INFINITY;
        }
        return result;
    }
}

/**
 * Helper func for accessing nested object from JSON with path
 * @param theObject
 * @param path - property path eg. "project.name", "name","project.experiments[0].name"
 * @param separator - default '.' but can be different,
 * @returns {*} - object
 */
export function getNested (theObject, path, separator) {
    try {
        separator = separator || '.';

        return path.
        replace('[', separator).replace(']','').
        split(separator).
        reduce(
            function (obj, property) {
                return obj[property];
            }, theObject
        );

    } catch (err) {
        return undefined;
    }
}

/**
 * Helper function for sorting
 * @param sortByProperty - name of property in format used by sorter variables (eg.'NAME', 'PROJECT_NAME', 'EXP_NAME' etc.)
 * @param sorter - sorter variable from Redux state
 * @param onSorterChange - callback to Redux function called after change of state
 */
export function sortByProperty(sortByProperty,sorter,onSorterChange) {
    if (sorter !== 'SORT_BY_'+sortByProperty+'_DESC') onSorterChange('SORT_BY_'+sortByProperty+'_DESC');
    else onSorterChange('SORT_BY_'+sortByProperty+'_ASC')
};

/**
 * Renderer of icon element for active sorter. Function checks whether the given property is used as active sorter in
 * Redux store, and if so, renders proper icon.
 * @param sortByProperty
 * @param sorter
 * @returns {*}
 */
export function renderSortIcon (sortByProperty,sorter) {
    if ('SORT_BY_'+sortByProperty+'_DESC' === sorter)
        return <div className="sortDown"/>;
    if ('SORT_BY_'+sortByProperty+'_ASC' === sorter)
        return <div className="sortDown" style={{transform: 'rotate(180deg)'}}/>;
};

/**
 *  Helper func for sorting elements by number value.
 * @param path - property path eg. "project.name", "name","project.experiments[0].name"
 * @returns {Function} negative, positive or 0 (as required in JS comparator interface for sort function)
 */
export function numberPropertyComparator(path) {
    return function(a,b) {
        const x = getNested(a, path, '.');
        const y = getNested(b, path, '.');
        return x < y ? -1 : x > y ? 1 : 0;
    };
}
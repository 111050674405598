import PropTypes from 'prop-types';
import React from "react";
import {Column} from "primereact/components/column/Column";
import {Row} from "primereact/components/row/Row";
import {ColumnGroup} from "primereact/components/columngroup/ColumnGroup";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Button} from "primereact/components/button/Button";
import {Dialog} from "primereact/components/dialog/Dialog";
import {
    EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID,
    EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES, REQUEST_STATUS_FAIL, REQUEST_STATUS_SUCCESS
} from "../../../Constants";
import {Tooltip} from "primereact/components/tooltip/Tooltip";


/**Set of keys for keeping data and statuses in Redux store.
 * Prefixes: S - selected, A- all, D - diff,
 * Postfixes: H - histogram, K - Kernel plot, B - boxplot, S- statistics
 *
 * @param uuid - variable uuid (eg. 'CLINICAL_9HT_...')
 * @returns {string}
 * @constructor
 */
const HISTOGRAM_SELECTED_KEY=(uuid)=>'S_'+uuid+'_H';  //S_ - selected,  _H-histogram
const BOXPLOT_SELECTED_KEY=(uuid)=>'S_'+uuid+'_B';
const KDE_SELECTED_KEY=(uuid)=>'S_'+uuid+'_K';
const STATS_SELECTED_KEY=(uuid)=>'S_'+uuid+'_S';

const HISTOGRAM_ALL_KEY=(uuid)=>'A_'+uuid+'_H';
const BOXPLOT_ALL_KEY=(uuid)=>'A_'+uuid+'_B';
const KDE_ALL_KEY=(uuid)=>'A_'+uuid+'_K';
const STATS_ALL_KEY=(uuid)=>'A_'+uuid+'_S';

const HISTOGRAM_DIFF_KEY=(uuid)=>'D_'+uuid+'_H';
const BOXPLOT_DIFF_KEY=(uuid)=>'D_'+uuid+'_B';
const KDE_DIFF_KEY=(uuid)=>'D_'+uuid+'_K';
const STATS_DIFF_KEY=(uuid)=>'D_'+uuid+'_S';



/**
 * Component for displaying table of descriptive statistics.
 * Can be extended to different types regarding type of variable (cntinuous, ordinal, nominal, etc.)
 * Must be stateful, since it is used for dialog windows toggling.
 *
 * @param dataSelection -  part of Store
 * @param variable - object
 * @param index - index needed for getting data from experimental dataset
 * @param getHistogram - clb to Action Creator
 * @returns {*}
 * @constructor
 */
    //export  const  = ({dataSelection,variable,index,getHistogram}) => {
export default class NumberVariableStatistics extends React.Component {

    constructor(props){
        super(props);
        this.state={
         showHistS:false,
         showBoxS:false,
         showKernelS:false,
         showKernelD:false
        };
        this.onHideHistS = this.onHideHistS.bind(this);
        this.onHideKernelS = this.onHideKernelS.bind(this);
        this.onHideBoxS = this.onHideBoxS.bind(this);
        this.onHideKernelD = this.onHideKernelD.bind(this);
    }
    componentDidMount(){
        const {dataSelection,variable, getStatistics,  experiment,symbol} = this.props;
        // const modelVars = experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES];
        // let index = modelVars.findIndex((el)=>{return el[EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID]===variable.uuid});

        let index = dataSelection.dataset[0]['variablesValues']
            .findIndex((el)=>{return el['variableName']===symbol})
        const x = dataSelection.dataset.map((el) => el.variablesValues[index].variableValue);
        getStatistics(STATS_SELECTED_KEY(variable.uuid),{x});
    }

    onHideHistS(event) {
        this.setState({showHistS: false});
    }

    onHideKernelS(event) {
        this.setState({showKernelS: false});
    }
    onHideBoxS(event) {
        this.setState({showBoxS: false});
    }
    onHideKernelD(event) {
        this.setState({showKernelD: false});
    }

    render() {
        const {dataSelection, variable,  experiment, getHistogram, getKernel, getBoxPlot, getGGPlot,symbol} = this.props;
        console.log('NumberVariableStatistics::render::', variable.uuid);

        // const modelVars = experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES];
        // let index = modelVars.findIndex((el)=>{return el[EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__VARIABLE_ID]===variable.uuid});

        if (!dataSelection.dataset[0])
            return(<div/>);

        let index = dataSelection.dataset[0]['variablesValues']
            .findIndex((el)=>{return el['variableName']===symbol})

        const x = dataSelection.dataset.map((el) => el.variablesValues[index].variableValue);
        const dataToProcess = {x, title: 'Histogram of ' + variable.name, freq: false, xlab: variable.name, breaks: 30};
        const dataToProcessKernel = {x, title: 'KDE of ' + variable.name};
        const dataToProcessBox = {x, title: 'Boxplot of ' + variable.name};





        let statData = [
            {dset: "Selected"},
            {dset: "All"},
            {dset: "Difference"}
        ];
        if (dataSelection.statisticsStates.get(STATS_SELECTED_KEY(variable.uuid)) === REQUEST_STATUS_SUCCESS) {
            let formattedStats= dataSelection.statisticsData.get(STATS_SELECTED_KEY(variable.uuid))[0];
            //     formattedStats["pValue"]=formattedStats["pValue"].toPrecision(2);
            // formattedStats["trimmed"]=formattedStats["trimmed"].toPrecision(2);

            statData = [
                Object.assign(statData[0],formattedStats ),
                {dset: "All"},
                {dset: "Difference"}
            ];
        }

        let headerGroup = <ColumnGroup>
            <Row>
                <Column header="Dataset"/>
                <Column header="Sample size"/>
                <Column header="Mean"/>
                <Column header="Trimmed"/>
                <Column header="Median"/>
                <Column header="Min"/>
                <Column header="Max"/>
                <Column header="SD"/>
                <Column header="IRQ"/>
                <Column header="MAD"/>
                <Column header="SE"/>
                <Column header="Skew"/>
                <Column header="Kurtosis"/>
                <Column header="KS p-Value" id="p-value"/>
                <Column header="Histo- gram"/>
                <Column header="Boxplot"/>
                <Column header="Kernel plot"/>

            </Row>
        </ColumnGroup>;

        const showHistogram = (rowData, column) => {
            return <div>
                <Button type="button" icon="fa fa-bar-chart" onClick={(e) =>
                {getHistogram(HISTOGRAM_SELECTED_KEY(variable.uuid), dataToProcess);
                this.setState({showHistS:true})}}/>
            </div>;
        };

        const showKernel = (rowData, column) => {
            if(column.rowIndex<2)
            return <div>
                <Button type="button" icon="fa fa-area-chart" onClick={(e) =>
                { getKernel(KDE_SELECTED_KEY(variable.uuid), dataToProcessKernel);
                this.setState({showKernelS:true})}
                }/>
            </div>;
            else
                return <div>
                    <Button type="button" icon="fa fa-area-chart" onClick={(e) =>
                    { getGGPlot(KDE_DIFF_KEY(variable.uuid));
                        this.setState({showKernelD:true})}
                    }/>
                </div>;
        };

        const showBoxplot = (rowData, column) => {
            return <div>
                <Button type="button" icon="fa fa-line-chart" onClick={(e) => {getBoxPlot(BOXPLOT_SELECTED_KEY(variable.uuid), dataToProcessBox);
                    this.setState({showBoxS:true})
                } }/>
            </div>;
        };
        return (
            <div>
                <Tooltip for="#p-value" title="The p-Value of the Kolmogorov-Smirnov one-sample test (normality test)." tooltipPosition="top" tooltipEvent="focus"  />

                <Dialog header='Selected sample set - Histogram'
                        visible={this.state.showHistS} width="600px" modal={false} onHide={this.onHideHistS}>
                {dataSelection.plotStates.get(HISTOGRAM_SELECTED_KEY(variable.uuid)) === REQUEST_STATUS_SUCCESS &&
                <img src={`data:image/png;base64,${dataSelection.plotData.get(HISTOGRAM_SELECTED_KEY(variable.uuid))}`}/>
                }
                </Dialog>

                <Dialog header='Selected sample set - Kernel Plot'
                        visible={this.state.showKernelS} width="600px" modal={false} onHide={this.onHideKernelS}>
                    {dataSelection.plotStates.get(KDE_SELECTED_KEY(variable.uuid)) === REQUEST_STATUS_SUCCESS &&
                    <img src={`data:image/png;base64,${dataSelection.plotData.get(KDE_SELECTED_KEY(variable.uuid))}`}/>
                    }
                </Dialog>
                <Dialog header='Selected sample set - Boxplot'
                        visible={this.state.showBoxS} width="600px" modal={false} onHide={this.onHideBoxS}>
                    {dataSelection.plotStates.get(BOXPLOT_SELECTED_KEY(variable.uuid)) === REQUEST_STATUS_SUCCESS &&
                    <img src={`data:image/png;base64,${dataSelection.plotData.get(BOXPLOT_SELECTED_KEY(variable.uuid))}`}/>
                    }
                </Dialog>
                <Dialog header='Comparison of KDE - only test mockup'
                        visible={this.state.showKernelD} width="600px" modal={false} onHide={this.onHideKernelD}>
                    {dataSelection.plotStates.get(KDE_DIFF_KEY(variable.uuid)) === REQUEST_STATUS_SUCCESS &&
                    <img src={`data:image/png;base64,${dataSelection.plotData.get(KDE_DIFF_KEY(variable.uuid))}`}/>
                    }
                </Dialog>
                {/*{dataSelection.datasetState !== REQUEST_STATUS_SUCCESS &&*/}
                {/*<div> Loading data <i className="fa fa-spinner fa-spin"/></div>}*/}
                {dataSelection.datasetState === REQUEST_STATUS_SUCCESS &&
                dataSelection.statisticsStates.get(STATS_SELECTED_KEY(variable.uuid)) === REQUEST_STATUS_SUCCESS &&

                <React.Fragment>
                    <DataTable value={statData} headerColumnGroup={headerGroup}>
                        <Column field="dset"/>
                        <Column field="n"/>
                        <Column field="mean"/>
                        <Column field="trimmed"/>
                        <Column field="median"/>
                        <Column field="min"/>
                        <Column field="max"/>
                        <Column field="sd"/>
                        <Column field="IQR"/>
                        <Column field="mad"/>
                        <Column field="se"/>
                        <Column field="skew"/>
                        <Column field="kurtosis"/>
                        <Column field="pValue" />
                        <Column body={showHistogram}/>
                        <Column body={showBoxplot}/>
                        <Column body={showKernel}/>

                    </DataTable>
                </React.Fragment>
                }
                {
                dataSelection.statisticsStates.get(STATS_SELECTED_KEY(variable.uuid)) === REQUEST_STATUS_FAIL &&
                <div>  The statistics are not available now. The administrators will take care of it. Please try again later.</div>
                }
            </div>
        );
    };
}
NumberVariableStatistics.propTypes = {
    dataSelection: PropTypes.object.isRequired,
    symbol: PropTypes.string.isRequired
};





import {createSelector} from 'reselect';
import {datePropertyComparator, stringPropertyComparator} from "../../helpers/comparators";

export const getVisibilityFilter = (state) => state.project.experiments.visibilityFilter;
export const getSorter = (state) => state.project.experiments.sorter;
const getExperiments = (state) => state.project.experiments.experiments;

/**
 * Selector.
 * Wrapper over list of experiments used for providing filtered and sorted list without changing state of Redux Store.
 */
export const getVisibleExperiments = createSelector(
    [ getVisibilityFilter, getSorter, getExperiments ],
    (visibilityFilter, sorter, experiments) => {
        if(experiments.length>0) {
            visibilityFilter.forEach((vf)=>{experiments=experiments.filter(t=>t.status!==vf)});
            experiments = getSortedExperiments(sorter, experiments);
            return experiments;
        }
        else return experiments;
    }
);


/** Helper used for sorting Experiments.
 *
 * @param sorter
 * @param data  - experiments list
 * @returns {void | * | Uint8Array | Uint16Array | Int16Array | Float32Array} sorted activities
 */
const getSortedExperiments = (sorter, data) => {
    switch (sorter) {
        case 'SORT_BY_STATUS_DESC':
            return data.sort(stringPropertyComparator('status'));
        case 'SORT_BY_STATUS_ASC':
            return data.sort(stringPropertyComparator('status')).reverse();
        case 'SORT_BY_LAST_MODIFIED_DESC':
            return data.sort(datePropertyComparator('modificationDate'));
        case 'SORT_BY_LAST_MODIFIED_ASC':
            return data.sort(datePropertyComparator('modificationDate')).reverse();
        case 'SORT_BY_NAME_DESC':
            return data.sort(stringPropertyComparator('question'));
        case 'SORT_BY_NAME_ASC':
            return data.sort(stringPropertyComparator('question')).reverse();
    }
};

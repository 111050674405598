// Import libraries
import React from 'react'
import { Provider } from 'react-redux'
import { Router, Route, Switch  } from 'react-router'
import createBrowserHistory from 'history/createBrowserHistory'
// Import styles
import 'primereact/resources/primereact.min.css'
import './sass/index.scss'
// Import components/containers/classes
import store from './Store.js'
import ContainerApp from './authentication/containers/ContainerApp.js'
import Home from './home/Home'



/**
 * Reference to Router history.
 * Can be injected into the business logic code in the same way as 'store' object.
 */
export const history = createBrowserHistory();

/**
 * Class responsible for initializing and providing references to:
 * 1. Redux Store
 * 2. React-Router history
 *
 */

class Root extends React.Component{
  render(){
    return(
      <Provider store={store}>
        <Router history={history}>
          <div>
            {/*<Link to='/home'>Home</Link>*/}

            <Switch>
              <Route exact path='/' component={ContainerApp}/>
              <Route path='/*' component={Home}/>
                {/*<Route path='/studies' component={Study}/>*/}
            </Switch>
            
          </div>
        </Router>
      </Provider>
    );
  }
}

export default Root;
import React from 'react'
import PropTypes from 'prop-types'
import {renderSortIcon, sortByProperty} from "../../helpers/comparators";
import {Link} from "react-router-dom";


export default class Skills extends React.Component {

    constructor() {
        super();
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
    }

    componentWillMount() {
        const {getSkills} = this.props;
        getSkills();
    }

    render() {

        const {skills, onSorterChange,sorter} = this.props;
        let displaySingleColumn = (s)=>{
            if (s.contribution !==-1){
                return <React.Fragment>
                <div className="spineColStyle col3">{s.expertise}</div>
                    <div className="spineColStyle col3">{s.contribution}</div>
                </React.Fragment>;
            }
            else return <div className="spineColStyle" style={{width:"66%",textAlign:"center",backgroundColor:'powderblue'}}><Link to="/home">Try it!</Link></div>;
        };

        const skillNodes = skills.map((s) =>
            <div className='spineRowStyle' key={s.uuid}>
                <div className="spineColStyle col3" onClick={this.handleClick}>{s.name}</div>
                {displaySingleColumn(s)}
            </div>);
        return (
            <div style={{width: '96%', margin: '2%'}}>
                <span className="reg-headtitle">My skills</span>
                <div className="spineTableStyle">
                    <div className='spineRowHeaderStyle'>
                        <div className="spineColStyle col3" onClick={()=>{sortByProperty("NAME",sorter,onSorterChange); }}>Skills {renderSortIcon('NAME',sorter)}</div>
                        <div className="spineColStyle col3" onClick={()=>{sortByProperty("EXPERTISE",sorter,onSorterChange); }}>Expertise  {renderSortIcon('EXPERTISE',sorter)}</div>
                        <div className="spineColStyle col3" onClick={()=>{sortByProperty("CONTRIBUTION",sorter,onSorterChange); }}>Contribution {renderSortIcon('CONTRIBUTION',sorter)}</div>
                    </div>
                    {skillNodes}
                </div>
            </div>
        );
    }
};
Skills.propTypes = {
    getSkills: PropTypes.func.isRequired,
    skills: PropTypes.array.isRequired,
    onFilterChange:PropTypes.func,
    onSorterChange:PropTypes.func
};
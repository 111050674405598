import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ProjectDetailsMenu from "./ProjectDetailsMenu";
import {ProjectDetailsOverviewTab} from "./ProjectDetailsOverviewTab";
import {ProjectDetailsExperimentsTab} from "./ProjectDetailsExperimentsTab";
import {ProjectDetailsCITab} from "./ProjectDetailsCITab";


export default class ProjectDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            isList:true,
            activeTab:2
        };
        this.onClick = this.onClick.bind(this);
        this.onHide = this.onHide.bind(this);
    }

    componentWillMount() {
        const {getProjectItem,getExperimentList} = this.props;
        getProjectItem();
        // if (this.props.experiments.experimentsState!=='success')
        getExperimentList();
    }

    onClick() {
        this.setState({visible: true});
    }

    onHide() {
        this.setState({visible: false});
    }

    render() {
        const {project} = this.props;

        //TODO To bind with real Logo - needs creating in model
        let projectLogo =(project.logo!=null)?null:
            <div className="projectDefaultLogo"/>;

        return (
            <div style={{position:'relative'}}>
                <div className="projectDetailsTitle">{project.name}</div>
                {projectLogo}
                <div className="projectTopBar"/>
                <ProjectDetailsMenu {...this.props}
                                    isList={this.state.isList}
                                    activeTab={this.state.activeTab}
                                    clbList={(isList) => this.setState({isList})}
                                    clbTab={(activeTab) => this.setState({activeTab})}
                />

                <div className="projectDetailsContents">
                    {(this.state.activeTab===0) && <ProjectDetailsOverviewTab project={project}/> }
                    {(this.state.activeTab===1) && <ProjectDetailsCITab project={project}/> }
                    {(this.state.activeTab===2) && <ProjectDetailsExperimentsTab project={project}
                                                                                 isList={this.state.isList}
                                                                                 experiments={this.props.experiments}
                                                                                 experimentsState={this.props.experimentsState}
                                                                                 onSorterChange={this.props.onSorterChange}
                                                                                 onFilterChange={this.props.onFilterChange}
                                                                                 filter={this.props.filter}
                                                                                 sorter={this.props.sorter}
                                                                                 onDeleteExperiment={this.props.deleteExperiment}
                                                                                 /> }
                </div>
            </div>
        )
    }

}

ProjectDetails.propTypes = {
    project: PropTypes.shape({
        summary: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        about: PropTypes.string,
        forMe: PropTypes.string,
        scientists: PropTypes.array,
        difficulty: PropTypes.number,
        progress: PropTypes.number
    }).isRequired,
    getExperimentList: PropTypes.func,
    onDeleteExperiment:PropTypes.func
};



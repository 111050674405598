import {EXPDESIGN_WIZARD_UPDATE_SESSION, EXPDESIGN_WIZARD_UPDATE_STEP} from '../action/actionType'

export const expSessionStateReducer = (state = { currentPage: 1 ,lastSessionId:"" }, action) => {
    switch(action.type) {
        case EXPDESIGN_WIZARD_UPDATE_STEP:
            return Object.assign({}, state, {
                currentPage: action.setStep
            });
        case EXPDESIGN_WIZARD_UPDATE_SESSION:
            return Object.assign({}, state, {
                lastSessionId: action.experimentId
            });
        default:
            return state;
    }
};
/**
 * Function for getting attributes from R transfero objects
 */
const getAttributeValue = (attr, to) => {
    const index = to.attributes.names.value.indexOf(attr);
    const value = to.value[index].value;
    if (value.length > 1)
        return value;
    else return value[0];
};

const argsGLR = {
    U: {
        NAME: 'u',
        LABEL: 'Model degrees of freedom',
        DESCRIPTION: 'Degrees of freedom for F test (coefficients you’ll have in your model).'
    },
    V: {NAME: 'v',
        LABEL: 'Error degrees of freedom',
        DESCRIPTION: 'The number of error degrees of freedom.'},
    F2: {
        NAME: 'f2',
        LABEL: 'Effect size',
        DESCRIPTION: 'Effect size to detect.  Cohen suggests f2 values of 0.02, 0.15, and 0.35 represent small, medium, and large effect sizes',
        DEFAULT:0.15
    },
    SIG_LEVEL: {
        NAME: 'sig.level',
        LABEL: 'Significance level',
        DESCRIPTION: 'Significance level (Type I error probability).',
        DEFAULT:0.05
    },
    POWER: {
        NAME: 'power',
        LABEL: 'Statistical power',
        DESCRIPTION: 'Power of test (1 minus Type II error probability)',
        DEFAULT:0.8
    }
};


export const POWER_CALCULATOR = {
    GLR: {NAME: "pwr.f2.test", CODE: "GLR", ARGS: argsGLR, DESERIALIZE: getAttributeValue},
};







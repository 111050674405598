const AuthReducer = (state = {
                         email: '',
                         login_status: 'logout',
                         roles: {},
                         role_active: '',
                         token_bearer: '',
                         name: '',
                         lastname: '',
                         nickname: '',
                         eduLevel: '',
                         experience: '',
                         profession: '',
                         specialty: ''
                     },
                     action) => {

    console.log('action is ' + action.type);
    console.log('state is ' + state);

    switch (action.type) {
        case 'LOGIN_REQUEST':
            return Object.assign({}, state, {
                login_status: 'requested'
            });
        case 'LOGIN_SUCCESS':
            return Object.assign({}, state, {
                    login_status: 'logged',
                    token_bearer: action.creds.token_bearer,
                    roles: action.creds.roles,
                    role_active: action.creds.role_active,
                    email: action.creds.email,
                    name: action.creds.name,
                    lastname: action.creds.lastname,
                    nickname: action.creds.nickname,
                    eduLevel: action.creds.eduLevel,
                    experience: action.creds.experience,
                    profession: action.creds.profession,
                    specialty: action.creds.specialty
                }
            );
        case 'LOGOUT_SUCCESS':
            return Object.assign({}, state, {
                    login_status: 'logout',
                    token_bearer: '',
                    roles: {},
                    role_active: '',
                    email: '',
                    name: '',
                    lastname: '',
                    nickname: '',
                    eduLevel: '',
                    experience: '',
                    profession: '',
                    specialty: ''
                }
            );
        case 'ROLE_PICK_SUCCESS':
            return Object.assign({}, state, {
                role_active: action.auth.role_active,
                token_bearer: action.auth.token_bearer,
                roles: action.auth.roles

            });
        case 'REGISTER_REQUEST':
            return Object.assign({}, state, {
                login_status: 'toRegister'
            });
        default:
            return state
    }
};

export default AuthReducer;
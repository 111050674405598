import React from 'react'
import PropTypes from 'prop-types'
import {Card} from 'primereact/components/card/Card'

export default  class WizardStepMonitor extends React.Component {

    render() {
        const { highlightIndex, elements } = this.props;
        const hi=highlightIndex-1;
        let stepItems=elements.map((el,index)=>{

            //if last element set width to 10%, for others spread equally
            const width= (index===(elements.length-1))?10: (90/(elements.length.toString()-1));

            const style={width:''+width+'%',float:'left'};
            const dotStyle = (hi===index)?"stepDot highlightStepDot":"stepDot";
            const stepLine =  (index===(elements.length-1))?"": <div className='stepLine'><hr/></div>;


            return <div key={index.toString()} style={style} className="stepItem">
                <div className="stepItemBox">
                      <div>
                            <div className={dotStyle}> {index+1}</div>
                      </div>
                      <div id="stepElement" className="stepElement">{el}</div>
                    {stepLine}
                </div>
            </div>;
        });

        return (
            <div className="wizardMonitor">
                <div style={{marginLeft:'16px',marginRight:'16px',marginTop:'12px'}}>
                        {stepItems}
                </div>
            </div>
        )
    }
}

import React from 'react'
import ExperimentIcon from "./ExperimentIcon";
import WrenchIcon from "./WrenchIcon";
import {Tooltip} from "primereact/components/tooltip/Tooltip";
import {Link} from "react-router-dom";
import PeopleIcon from "./PeopleIcon";

export  const LeftNavigationBar = ()=>{


        console.log("LeftNavigationBar ... render");

        return (
            <div className="sidenav">
                <Link to={`/home`}>
                    <div id="dashboard" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}
                    >
                        <i className="fa fa-dashboard" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                    </div>
                </Link>
                <Link to={`/project`}>
                    <div id="projects" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                        <i className="fa fa-briefcase" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                    </div>
                </Link>
                <div id="skills" style={{width: '100%', marginLeft: '15px', marginTop: '10px', marginBottom: '10px'}}>
                    <Link to={`/skills`}>
                    <WrenchIcon/>
                    </Link>
                </div>
                <Link to={`/home`}>
                    <div id="community"
                         style={{width: '100%', marginLeft: '15px', marginTop: '10px', marginBottom: '10px'}}>
                        <PeopleIcon/>
                    </div>
                </Link>
                <Link to={`/news`}>
                    {/*TODO change it*/}
                    <div id="news" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                        <i className="fa fa-newspaper-o" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                    </div>
                </Link>
                <Link to="/livePresenter">
                    <div id="livePresenterIconDiv" style={{width: '100%', marginLeft: '14px', marginBottom: '10px'}}>
                        <i className="fa fa-television" style={{color: 'rgb(151, 172, 189)', fontSize: '20px'}}/>
                        {/* <div className="lp_logo"></div> */}
                    </div>
                </Link>
                {/*<Link to={`/expdesign`}>*/}
                    {/*<div id="expdesign" style={{width: '100%', marginLeft: '15px'}}>*/}
                        {/*<ExperimentIcon/>*/}
                    {/*</div>*/}
                {/*</Link>*/}

                {/*<Link to={`/tour`}>*/}
                    {/*<div id="tour" style={{width: '100%', marginLeft: '12px', marginTop: '10px'}}>*/}
                        {/*<ExperienceIcon/>*/}
                    {/*</div>*/}
                {/*</Link>*/}



                <Tooltip for="#dashboard" title="Dashboard" tooltipPosition="right" tooltipEvent="hover"/>
                <Tooltip for="#news" title="News" tooltipPosition="right" tooltipEvent="hover"/>
                {/*<Tooltip for="#expdesign" title="Experiment design" tooltipPosition="right" tooltipEvent="hover"/>*/}
                {/*<Tooltip for="#tour" title="What how and who" tooltipPosition="right" tooltipEvent="hover"/>*/}
                <Tooltip for="#skills" title="Skills" tooltipPosition="right" tooltipEvent="hover"/>
                <Tooltip for="#projects" title="Projects" tooltipPosition="right" tooltipEvent="hover"/>
                <Tooltip for="#community" title="Community" tooltipPosition="right" tooltipEvent="hover"/>
                <Tooltip for="#livePresenterIconDiv" title="Live Presenter" tooltipPosition="right" tooltipEvent="hover"/>
            </div>
        )

}

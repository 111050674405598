import React from "react";
import {Accordion, AccordionTab} from 'primereact/components/accordion/Accordion';
import Slider from "react-slick";

export default class CurrentProjectsList extends React.Component {

    constructor() {
        super();
        this.state = {
            activeSlide: 0
        };
    }

    componentWillMount() {
        const {getProjects} = this.props;
        getProjects();
    }

    render() {
        let generateTabs = this.props.projects.map((item) =>
            <div className="slider-contents-text" key={item.uuid}>
                <div className="reg-headtitle">Project {item.name}</div>
                <div className="reg-plaintext">{item.summary}</div>
            </div>);
        let settings = {
            infinite: true,
            autoplay: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: true,
            afterChange: current => this.setState({activeSlide: current})
        };

        return (
            <div style={{fontSize:'1.3em', margin: 'auto', width: '90%'}}>
                {this.props.projects !== null &&
                <Slider ref={slider => (this.slider = slider)} {...settings}>
                    {generateTabs}
                </Slider>
                }
            </div>
        )
    }
}

import React from "react";
import {Button} from "primereact/components/button/Button";
import PropTypes from "prop-types";
import {InputText} from "primereact/components/inputtext/InputText";



// function iterate(current, depth) {
//     var children = current.childNodes;
//     for (var i = 0, len = children.length; i < len; i++) {
//         iterate(children[i], depth + 1);
//     }
// }
// iterate(parentElement, 0);


export const HierarchicalDropdownGenerator  = ({parentNode,updateData,addNode,removeNode})=>{

        return   <div>
            {parentNode['key'] !== "0"&&
            <React.Fragment>
                <InputText placeholder="Something" value={parentNode.value} onChange={(e)=>updateData(parentNode,e.target.value)}/>
                <Button icon="fa fa-plus" onClick={()=>addNode(parentNode)}/>
                <Button icon="fa fa-trash" onClick={()=>removeNode(parentNode)}/>
            </React.Fragment>}
            {
                (parentNode.children !=null) && parentNode.children.map((el, index) =>
                    <HierarchicalDropdownGenerator parentNode={el}
                                                   updateData={updateData}
                                                   key={el.key}
                                                   addNode={addNode}
                                                   removeNode={removeNode}
                    />)
            }
            {parentNode['key'] === "0"&&
            <React.Fragment>
                <Button icon="fa fa-plus" onClick={()=>addNode(parentNode)}/>
            </React.Fragment>}
            </div>;
};

HierarchicalDropdownGenerator.propTypes = {
    parentNode: PropTypes.object.isRequired,
    updateData:PropTypes.func, //callback for updating values
    addNode:PropTypes.func,//callback for adding node
    removeNode:PropTypes.func //callback for removing node

};
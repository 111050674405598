import React from 'react';
import PropTypes from 'prop-types';
import vtkRenderWindow from 'vtk.js/Sources/Rendering/Core/RenderWindow';
import vtkRenderer from 'vtk.js/Sources/Rendering/Core/Renderer';
import vtkImageMapper from 'vtk.js/Sources/Rendering/Core/ImageMapper';
import vtkImageSlice from 'vtk.js/Sources/Rendering/Core/ImageSlice';
import vtkOpenGLRenderWindow from 'vtk.js/Sources/Rendering/OpenGL/RenderWindow';
import * as SpineInteractorStyleImage from "./SpineInteractorStyleImage";
import vtkPointPicker from 'vtk.js/Sources/Rendering/Core/PointPicker';
import * as SpineRenderWindowInteractor from "./SpineRenderWindowInteractor";
import {loggingDataset} from "./MRIBrowserLogger";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../Constants";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Button} from "primereact/components/button/Button";
import {Panel} from "primereact/components/panel/Panel";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import math from  "vtk.js/Sources/Common/Core/Math/index"
import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';
import vtkPiecewiseFunction from 'vtk.js/Sources/Common/DataModel/PiecewiseFunction';
import {LEFT_BUTTON_MODE} from "./SpineInteractorStyleImage";
import {updateViewerProperty} from "../visualization/action/AnnotationAction";


const PLANES = {SAGITTAL: 0, CORONAL: 1, AXIAL: 2};


/**
 * Single component for annotating 3 D slices based on:
 *  - VTK.js renderWindow generated on canvas and handling interactions (observer)
 *  - own canvas editor for rendering annotations only.
 * This is version based on additional canvas (glass pane).
 * The 'resolution' canvases is 300x300 px (VTK renderWindow default size),
 * however canvases viewports size are calculated dynamically.
 * Note that VTK canvas is generated dynamically by VTK.js.
 * In order to modify canvas to dynamic resolution, window resize handler must be implemented.
 *
 * @version Version using PaintFilter, Paintwidget etc.
 *
 * imageState -> request, success, error, loaded
 *
 *
 *    ijk[0] = index % dims[0];
 *    ijk[1] = (index / dims[0]) % dims[1];
 *    ijk[2] = index / (dims[0] * dims[1]);
 *
 *  See props at the bottom.
 */
class AnnotationBrowser5 extends React.Component {

    constructor() {
        super();
        this.state = {
            renderWindow: vtkRenderWindow.newInstance(),
            renderer: vtkRenderer.newInstance(),
            originalImage:{
                mapper: vtkImageMapper.newInstance(), //keeps important SlicingMode (i,j,k might be switched)
                actor : vtkImageSlice.newInstance()
            },
            overlayImage: {
                mapper: vtkImageMapper.newInstance(),
                actor: vtkImageSlice.newInstance(),
                cfun: vtkColorTransferFunction.newInstance(),
                ofun: vtkPiecewiseFunction.newInstance(),
            },
            controlPanelVisible:false, //expand/fold controls
            slicingDirection:1,  //parameter used for switing orientations ARS, SRA etc. -> RAI (ITK format)
            directionTitle:'', //parameter used for showing direction of slicing, eg. A<--->P
            interactorStyle2D : SpineInteractorStyleImage.newInstance()
        };
        ["calcHeight","changeSlice","clearActors", "drawCrossHair", "renderROI", "stopPropagateEvents","setMapper","setSlice","initializeViewports",
            "initializeOriginalImage","initializeOverlayImage","registerPainter"]
            .forEach(name => {
                this[name] = this[name].bind(this);
            }); //Binding methods
    }

    /**
     * Function returning prop linkViewers.
     * Required since right click handler is set during initialisation.
     * @returns {Boolean}
     */
    getLinkViewers(){
        return  this.props.linkViewers;
    }

    calcHeight() {
        const rect = this.glass.getBoundingClientRect();
        console.log("Size: ", rect.height);
        return rect.height;
    }
    setSlice(i){        //setting mappers in different orientations (i,j,k)
        const {originalImage} = this.state;
        if (i===0) return originalImage.mapper.setISlice;
        if (i===1) return originalImage.mapper.setJSlice;
        if (i===2) return originalImage.mapper.setKSlice;
    };


    /** Sets Mappers based on original image only.
     *  Resolves problem with shifted orientations (eg. ARS instead of RAS).
     *
     * @param initialSlice
     */
    setMapper(initialSlice){
        const {updateViewer,viewerState,image} = this.props;
        const {renderer, originalImage} = this.state;
        const imageData = (image!=null && image.hasOwnProperty('data')) ?image['data']:null;

        const viewportMode = viewerState['orientation'];
        const a = imageData.getDirection();
        const camera = renderer.getActiveCamera();
        const cols3 = [                      //Transposed since columns are used to find proper slicing mode when IJK are flipped, eg. RAS =IJK, but ASR=KIJ
            [a[0], a[3], a[6]],
            [a[1], a[4], a[7]],
            [a[2], a[5], a[8]]
        ];
        const rows3 = [                             //Rows are used to find position and viewup
            [a[0], a[1], a[2]],
            [a[3], a[4], a[5]],
            [a[6], a[7], a[8]]
        ];

        let maxCols = cols3.map((el,index)=>{  //find absolute maximas in columns to set proper ImageMapper Slicing Mode
            let temp = el.map((eli)=>{return Math.abs(eli);});
            return temp.indexOf(Math.max(...temp));
        });
        let maxRows = rows3.map((el,index)=>{  //find absolute maximas in rowss to set viewups on [0,-1,0] and [0,0,1]
            let temp = el.map((eli)=>{return Math.abs(eli);});
            return temp.indexOf(Math.max(...temp));
        });


        const mapperIndex= maxCols[viewerState['orientation']];   /// this can be  confusing - I,J,K might be switched
        let position,viewUp,slicingDirection=1,directionTitle='';

        camera.setFocalPoint(0,0,0);

        if (viewportMode===PLANES.CORONAL){
            this.setSlice(mapperIndex)(initialSlice);
            position = rows3[mapperIndex];
            viewUp = rows3[maxRows.indexOf(2)]; //check where is S to set viewUp [0,0,1]
            if (position[1]>0){  //if this is Anterior then use opposite direction
                math.multiplyScalar(position,-1);
                slicingDirection = -1;
            }
            if (viewUp[2]<0){
                math.multiplyScalar(viewUp,-1);
            }
            if (slicingDirection <0)
                directionTitle="A<----->P";
            else
                directionTitle="P<----->A";
        };
        if (viewportMode===PLANES.SAGITTAL){
            this.setSlice(mapperIndex)(initialSlice);
            position = rows3[mapperIndex];
            viewUp = rows3[maxRows.indexOf(2)]; //check where is S to set viewUp [0,0,1]
            if (position[0]<0){  //if Left  then use opposite direction
                math.multiplyScalar(position,-1);
            }
            else slicingDirection = -1;  //if Right then use opposite slicing direction
            if (viewUp[2]<0){
                math.multiplyScalar(viewUp,-1);
            }
            if (slicingDirection <0)
                directionTitle="R<----->L";
            else
                directionTitle="L<----->R";
        }
        if (viewportMode===PLANES.AXIAL){
            this.setSlice(mapperIndex)(initialSlice);
            position = rows3[mapperIndex];
            viewUp = rows3[maxRows.indexOf(1)]; //check where is S to set viewUp [0,0,1]
            if (position[2]>0){  //if Inferior  then use opposite direction
                math.multiplyScalar(position,-1);
            } else slicingDirection = -1;  //if Superior then use opposite slicing direction
            if (viewUp[1]>0){
                math.multiplyScalar(viewUp,-1);
            }
            if (slicingDirection <0)
                directionTitle="S<----->I";
            else
                directionTitle="I<----->S";
        };
        this.setState({slicingDirection:slicingDirection,directionTitle:directionTitle});

        // offset along the slicing axis
        camera.setPosition(...position);
        camera.setViewUp(...viewUp);
        originalImage.actor.setMapper(originalImage.mapper);
        camera.setParallelProjection(true);
        renderer.resetCamera();
        this.refreshRenderWindow();
    }

    registerPainter(){
        const {painter} = this.props;
        const {overlayImage,interactorStyle2D} = this.state;
        interactorStyle2D.setPainter(painter);
        painter.registerRender(() => this.refreshRenderWindow());
        overlayImage.mapper.setInputData(painter.getOutputData());
    }

    initializeOverlayImage(){
        const {image,  annotationData,  updateViewer,smoothing,painter,opacity,colorOptions} = this.props;
        const {renderer, renderWindow, originalImage,overlayImage,interactorStyle2D} = this.state;

        renderer.addActor(overlayImage.actor);
        this.registerPainter();

        // labelmap pipeline
        overlayImage.actor.setMapper(overlayImage.mapper);
        overlayImage.actor.getProperty().setInterpolationType(smoothing?1:0);

        // set up labelMap color and opacity mapping
        colorOptions.forEach((el)=>{
            let r=parseInt(el.color.charAt(1),16);
            let g=parseInt(el.color.charAt(2),16);
            let b=parseInt(el.color.charAt(3),16);
            overlayImage.cfun.addRGBPoint(el.value, r/16., g/16., b/16.); // label "1" will be blue

        });

        overlayImage.cfun.addRGBPoint(0.5,0,0,0); // label "1" will be blue
        overlayImage.ofun.addPoint(0, 0); // our background value, 0, will be invisible
        overlayImage.ofun.addPoint(0.5,1);
        overlayImage.ofun.addPoint(1, 1); // all values above 1 will be fully opaque
        overlayImage.actor.getProperty().setRGBTransferFunction(overlayImage.cfun);
        overlayImage.actor.getProperty().setScalarOpacity(overlayImage.ofun);
        // opacity is applied to entire labelmap
        overlayImage.actor.getProperty().setOpacity(opacity/100.0);
        const update = () => {
            const slicingMode = originalImage.mapper.getSlicingMode() % 3;

            if (slicingMode > -1) {
                const ijk = [0, 0, 0];
                const position = [0, 0, 0];
                const normal = [0, 0, 0];
                // position
                ijk[slicingMode] = originalImage.mapper.getSlice();
                image['data'].indexToWorldVec3(ijk, position);
                // circle/slice normal
                ijk[slicingMode] = 1;
                image['data'].indexToWorldVec3(ijk, normal);
                math.subtract(normal, image['data'].getOrigin(), normal);
                math.normalize(normal);
                overlayImage.mapper.set(originalImage.mapper.get('slice', 'slicingMode'));
            }
        };
        originalImage.mapper.onModified(update);
        // trigger initial update
        update();
    }

    clearActors(){
        const {renderer, renderWindow, originalImage,interactorStyle2D} = this.state;

        let actors = renderer.getActors();
        actors.forEach((el)=>{ renderer.removeActor(el);});
        interactorStyle2D.setPainter(null);    //clear Painter
        this.refreshRenderWindow();
    }

        /**
     * Function creating and setting render window, etc.
     */
    initializeOriginalImage(){
        const { annotationData, smoothing,image, updateViewerProperty} = this.props;
        const {renderer, renderWindow, originalImage} = this.state;
        const imageData = (image!=null && image.hasOwnProperty('data')) ?image['data']:null;
        // const imageState= (image!=null && image.hasOwnProperty('state'))?image['state']:null;


        const pointPicker = vtkPointPicker.newInstance();
        renderWindow.getInteractor().setPicker(pointPicker);
        pointPicker.setPickFromList(1);
        pointPicker.initializePickList();
        pointPicker.addPickList(originalImage.actor); //picker works for original image

        renderer.addActor(originalImage.actor);

        console.log('Original image');
        if (imageData)
            loggingDataset(imageData);
        const dataRange = imageData.getPointData().getScalars().getRange();
        originalImage.actor.getProperty().setColorWindow(dataRange[1] - dataRange[0]);
        originalImage.actor.getProperty().setColorLevel(dataRange[0] + (dataRange[1] - dataRange[0]) * .5);
        originalImage.mapper.setInputData(imageData);

        this.setMapper(0); //change here
        renderer.resetCamera();
        renderer.getActiveCamera().setParallelProjection(true);
        originalImage.actor.setMapper(originalImage.mapper);
        originalImage.actor.getProperty().setOpacity(1.0);//!!!!!!
        originalImage.actor.getProperty().setInterpolationType(smoothing?1:0);

        // renderer.resetCamera();
        if (annotationData!=null) {
            annotationData.forEach((el) => {
                this.renderROI(el)
            });
        }
    }



    initializeViewports(){
        const {leftButtonMode,addRow,pickerCallback} = this.props;
        const {renderer, renderWindow, interactorStyle2D} = this.state;
        const openglRenderWindow = vtkOpenGLRenderWindow.newInstance();
        const interactor = SpineRenderWindowInteractor.newInstance();

        renderer.setViewport([0, 0, 1, 1]);// full window mode
        //setting style for interaction
        interactorStyle2D.setInteractionMode('IMAGE_SLICING'); //always
        interactorStyle2D.setLeftButtonMode(leftButtonMode); //"Pin", "Picker", etc.
        interactorStyle2D.setPinCallback(addRow);  // sets callback for adding annotation

        if (pickerCallback)
             interactorStyle2D.setPickCallback(pickerCallback); // sets callback for changing annotation

        renderWindow.addView(openglRenderWindow);
        openglRenderWindow.setContainer(this.node);
        // openglRenderWindow.setCursor('crosshair'); //Not working when interactor bound to glass
        interactor.setInteractorStyle(interactorStyle2D); // in case using predefined styles - SpineIneractorStyleImage
        interactor.setView(openglRenderWindow);
        interactor.initialize();
        interactor.bindEvents(this.glass);

        renderWindow.addRenderer(renderer);
        renderer.setBackground(0, 0, 0);
    }


    componentDidMount() {
        const {viewerState,image,painter} = this.props;
        const {renderer,originalImage} = this.state;

        this.glass.style.cursor = 'crosshair';
        let imageData = (image!=null && image.hasOwnProperty('data'))?image['data']:null;
        let imageState= (image!=null && image.hasOwnProperty('state'))?image['state']:null;

        //---setting window size-BEGIN--
        this.calcHeight();
        window.addEventListener("resize",this.calcHeight);
        //---setting window size-END--

        this.initializeViewports(); //this is called only once!!!
        //initialize original image with setting slice in the middle
        if (imageData!=null && imageState===REQUEST_STATUS_SUCCESS) {
            this.initializeOriginalImage();
        }
        if ( painter!=null && painter.getBackgroundImage()!=null && renderer.getActors().length>0 && renderer.getActors().length<2)
            this.initializeOverlayImage();

        this.refreshRenderWindow();
    }


    /** This is function responsible for rendering spheres for ROI's.
     *
     * @param element
     */
    renderROI(element) {
        const {pinProperties, viewerState} = this.props;
        const {renderWindow,renderer} = this.state;
        let roi = element["roi"];
        //Don't do anything if this is not the same image
        if (roi['imageId'] !== viewerState['imageId']) return;
        //Don't do anything if you're on improper slice
        if (roi["roiCellIJK"][viewerState['slicingMode']] !== viewerState['slice']) return;

        let z = renderWindow.getViews()[0].worldToDisplay(roi.roiPosition[0], roi.roiPosition[1], roi.roiPosition[2], renderer);
        const fontSize = (pinProperties!=null && pinProperties.fontSize!=null)?pinProperties.fontSize:10;
        // console.log("Restored Point:", z);
        let ctx = this.glass.getContext("2d");

        switch (roi.roiStatus) {
            case "active": {
                ctx.fillStyle = "red";
                break;
            }
            case "normal": {
                ctx.fillStyle = "blue";
                break;
            }
            default : {
                ctx.fillStyle = "green";
                break;
            }
        }
        let size = (pinProperties!=null && pinProperties.markerSize != null) ? pinProperties.markerSize : 4;
        ctx.beginPath();
        ctx.arc(z[0], ctx.canvas.height - z[1], size, 0, 2 * Math.PI, false);
        ctx.closePath();
        ctx.fill();
        ctx.font = fontSize + "px Helvetica";
        ctx.fillText(element["id"], z[0], ctx.canvas.height - z[1]);

    }

    /** draw rulers based on ijk value and  ijk orientation (slicing) mode*/
    drawCrossHair() {
        const {viewerState, image, rulers } = this.props;
        const {renderWindow,renderer} = this.state;

        let imageData = (image!=null && image.hasOwnProperty('data'))?image['data']:null;

        if (!(imageData!=null))
            return; //if no original data, no point in drawing


        rulers.forEach((el)=>{
            let temp = [0,0,0];
            let tracker = [0,0,0];
            temp[el.slicingMode] = el.slice;
            imageData.indexToWorldVec3(temp, tracker);
            let z = renderWindow.getViews()[0].worldToDisplay(tracker[0], tracker[1], tracker[2], renderer);
            let ctx = this.glass.getContext("2d");
            const mousePosX = z[0];
            const  mousePosY = ctx.canvas.height - z[1];

            // ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            ctx.strokeStyle = "#fff967";

            if ((viewerState['orientation'] ===2 &&  el['orientation'] ===1) ||
                (viewerState['orientation'] ===1 &&  el['orientation'] ===2) ||
                (viewerState['orientation'] ===0 &&  el['orientation'] ===2)
            ) {//K
                // H
                ctx.beginPath();
                ctx.moveTo(0, mousePosY - 1);
                ctx.lineTo(ctx.canvas.width, mousePosY + 1);
                ctx.stroke();
            }
            else {
                // V
                ctx.beginPath();
                ctx.moveTo(mousePosX - 1, 0);
                ctx.lineTo(mousePosX + 1, ctx.canvas.height);
                ctx.stroke();
            }


        });
    }

    /**
     *
     * @param prevProps
     * @param prevState
     */
    componentDidUpdate(prevProps, prevState) {
        const {pinProperties, annotationData, viewerState, smoothing,tracker,leftButtonMode,opacity,pinOpacity, painter,
            updateViewerProperty,image, rulers} = this.props;
        const {originalImage,interactorStyle2D,renderer} = this.state;

        let imageData = (image!=null && image.hasOwnProperty('data'))?image['data']:null;
        let imageState= (image!=null && image.hasOwnProperty('state'))?image['state']:null;

        // const viewportMode = viewerState['orientation'];

        const pinOpacity2=(pinOpacity!=null)?pinOpacity:100;

        let ctx = this.glass.getContext("2d");
        if (prevProps !== this.props) {

            if(prevProps.image !== image  &&  imageState===REQUEST_STATUS_REQUESTED){
                this.clearActors();
            }

            if(prevProps.image !== image  &&  imageState===REQUEST_STATUS_SUCCESS){
                this.initializeOriginalImage();
                // updating state in scene, so the other viewers (if any) can update rulers, pickers etc.
                updateViewerProperty('slicingMode',originalImage.mapper.getSlicingMode());
                //updating state in scene, so the other viewers (if any) can update rulers, pickers etc.
                updateViewerProperty('slice',Math.floor((imageData.getExtent()[originalImage.mapper.getSlicingMode() * 2 + 1]
                    - imageData.getExtent()[originalImage.mapper.getSlicingMode() * 2]) / 2));
            }

            if ( painter!=null && painter.getBackgroundImage()!=null  && renderer.getActors().length>0 && renderer.getActors().length<2 )
                this.initializeOverlayImage();

            if(prevProps.viewerState['orientation']!==viewerState['orientation']){
                this.setMapper(0);
                updateViewerProperty('slicingMode',originalImage.mapper.getSlicingMode());
                updateViewerProperty('slice',Math.floor((imageData.getExtent()[originalImage.mapper.getSlicingMode() * 2 + 1]
                    - imageData.getExtent()[originalImage.mapper.getSlicingMode() * 2]) / 2));
            }

            if(prevProps.leftButtonMode!==leftButtonMode){
                interactorStyle2D.setLeftButtonMode(leftButtonMode);
            }

            if (prevProps.smoothing!==smoothing){
                if (renderer.getActors()[0]){
                    renderer.getActors()[0].getProperty().setInterpolationType(smoothing?1:0);
                }
                if (renderer.getActors()[1]){
                    renderer.getActors()[1].getProperty().setInterpolationType(smoothing?1:0);
                }
                this.refreshRenderWindow();
                return;
            }
            if (prevProps.opacity!==opacity) {
                if (renderer.getActors()[1]){
                    renderer.getActors()[1].getProperty().setOpacity(opacity / 100.0);
                }
                this.refreshRenderWindow();
                return;
            }

            console.log("Updated Props: ", prevProps);
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            ctx.globalAlpha = pinOpacity2 / 100.0;
            if (annotationData!=null) {
                annotationData.forEach((el, index) => {
                    this.renderROI(el)
                });
            }
            if (tracker && rulers!=null && rulers.length>0 )
                this.drawCrossHair();

            if (prevProps.viewerState['slice'] !== viewerState['slice']) {
                originalImage.mapper.setSlice(viewerState['slice']);  //SlicingMode.I=0,J=1,K=2
                this.refreshRenderWindow();
            }
            if (prevProps.viewerState['imageId'] !== viewerState['imageId']) {
                this.refreshRenderWindow();
            }
            // if (prevProps.cis !== cis && prevProps.cis[originalImage.mapper.getSlicingMode()] !== cis[originalImage.mapper.getSlicingMode()]) {
            //     originalImage.mapper.setSlice(cis[originalImage.mapper.getSlicingMode()]);  //SlicingMode.I=0,J=1,K=2
            //
            // }


        }
        else   if (prevState !== this.state) {
            console.log("Updated State: ");
            ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
            ctx.globalAlpha = pinOpacity2 / 100.0;
            if (annotationData!=null) {
                annotationData.forEach((el, index) => {
                    this.renderROI(el)
                });
            }
            if (tracker && rulers!=null && rulers.length>0)
                this.drawCrossHair();
        }
    }

    /**
     * Handler for slice change [k=2]
     * @param event
     */
    changeSlice(event) {
        const {updateViewerProperty} = this.props;
        let val = parseInt(event.target.value);
        updateViewerProperty('slice',val);
    }

    refreshRenderWindow() {
        this.state.renderWindow.render();
    }

    stopPropagateEvents(e) {
        if (e.type==='wheel' || ( e.shiftKey && e.type==='mousemove') )
            this.setState(this.state);//refresh state with ComponentDidUpdate
        e.stopPropagation(); //to avoid accidentally handled events in parents (cuts bubbling)
    }


    render() {
        const {viewerState,imageOptions , imageType, image, isActive, setActive,
            smoothing,changeSmoothing, linkViewers,setLinkViewers,changeImageType,changeOrientation} = this.props;
        const {originalImage} = this.state;
        const viewportMode = viewerState['orientation'];
        let imageData = (image!=null && image.hasOwnProperty('data'))?image['data']:null;
        let imageState= (image!=null && image.hasOwnProperty('state'))?image['state']:null;

        let spinner = null;
        if (!imageState === REQUEST_STATUS_SUCCESS) {
            spinner = <div> Loading data: <i className="fa fa-spinner fa-spin"/></div>;
        }
        let labels = [];
        if(viewportMode===PLANES.SAGITTAL){
            labels = ["A","P","S","I"];
        } else if(viewportMode===PLANES.CORONAL){
            labels = ["R","L","S","I"];
        } else labels = ["R","L","A","P"];

        let activeStyle = (isActive)?{border:"solid 4px red"}:{};

        return (
            <div className={"ui-g-12 ui-g-nopad"}>
                {this.props.toolbarVisible &&
                <div className={"ui-g-12 ui-g-nopad"}>
                    <Toolbar>
                        <div className={"ui-g-1"}>
                            <Button icon={this.state.controlPanelVisible?"fa fa-minus-square-o":"fa fa-plus-square-o"}
                                    onClick={() => this.setState({controlPanelVisible:!this.state.controlPanelVisible})}/>
                        </div>
                        <div className={"ui-g-10"}>
                            <input type="range"
                                   onChange={this.changeSlice}
                                   style={{width: "100%"}} min="0" step="1"
                                   max={(imageData!=null)?imageData.getExtent()[originalImage.mapper.getSlicingMode() * 2 + 1]:0}
                                // value={cis[originalImage.mapper.getSlicingMode()]}
                                   value={viewerState['slice']}
                                   title={this.state.directionTitle}
                            />
                        </div>
                        <div className={"ui-g-1"}>
                            {imageState===REQUEST_STATUS_REQUESTED && <i className="fa fa-spinner fa-spin"/>}
                            {imageData!=null && imageState===REQUEST_STATUS_SUCCESS && viewerState.slice}
                            {imageState===REQUEST_STATUS_FAIL && <i className="fa fa-exclamation-triangle" title="Reading error"/>}
                        </div>
                    </Toolbar>
                </div>}
                <div className={"ui-g-12 ui-g-nopad"} id="container_canvas">
                    <div style={{position: "relative", width: "100%"}} id="relative_parent"
                         onWheel={(e) => this.stopPropagateEvents(e)}
                         onMouseDown={(e) => this.stopPropagateEvents(e)}
                         onMouseUp={(e) => this.stopPropagateEvents(e)}
                         onMouseMove={(e) => this.stopPropagateEvents(e)}
                    >
                        <div ref={node => this.node = node}
                             style={{position: "absolute", left: "0", top: "0", zIndex: "1", width: "inherit"}} />
                        <div style={{
                            position: "absolute",
                            left: "0",
                            top: "0",
                            width: "100%",
                            zIndex: 2,
                            // pointerEvents: "none"
                        }}>
                            <canvas ref={glass => this.glass = glass} id="canvas_2"
                                    width="300" height="300" //constant canvas resolution - according to VTK
                                    style={{zIndex: "2", width: "100%", ...activeStyle}}
                                    // onMouseDown={(e) => console.log("On Mouse Down Glass>>>>>>>>>>>")}
                                    onClick={setActive}
                            />
                            <div style={{position: "absolute", color:"white", left: "1%",top:"50%"}}>{labels[0]}</div>
                            <div style={{position: "absolute", color:"white", right: "1%",top:"50%"}}>{labels[1]}</div>
                            <div style={{position: "absolute", color:"white", left: "50%",top:"1%"}}>{labels[2]}</div>
                            <div style={{position: "absolute", color:"white", left: "50%",bottom:"1%"}}>{labels[3]}</div>
                        </div>
                        {this.state.controlPanelVisible &&
                        <Panel  style={{position: "absolute",zIndex: "1000", width: "inherit", borderRadius:"0px",backgroundColor:"#eeeeee"}}>
                            <div className={"ui-g-12 ui-g-nopad"} style={{marginBottom: "6px"}}>
                                <Dropdown value={viewportMode}
                                          options={[
                                              {label:"Sagittal", value:PLANES.SAGITTAL},
                                              {label:"Coronal", value:PLANES.CORONAL},
                                              {label:"Axial", value:PLANES.AXIAL}
                                              // {label:"X", value:PLANES.X}
                                          ]}
                                          onChange={(e)=>changeOrientation(e.value)}
                                          style={{width:'auto'}}
                                />
                                {imageOptions &&
                                    <Dropdown value={viewerState['imageId']}
                                              options={imageOptions}
                                              onChange={(e) => {
                                                  this.clearActors();
                                                  changeImageType(e.value);
                                              }
                                              }
                                        // onChange = {(e)=>updateViewerProperty('imageId',e.value)}
                                              style={{width: 'auto'}}
                                    />
                                }
                                <label style={{marginLeft:"1em"}}>Smoothing:</label>
                                <Checkbox onChange={()=>{changeSmoothing()}}
                                          checked={smoothing}/>
                                <i className="fa fa-link" style={{marginLeft:"1em"}}/>
                                <Checkbox onChange={()=>setLinkViewers()} checked={linkViewers}/>
                            </div>
                        </Panel>}
                    </div>
                </div>
            </div>
        );

    }
}

AnnotationBrowser5.defaultProps = {
    toolbarVisible:true,
    leftButtonMode:LEFT_BUTTON_MODE.NONE,
    isActive:false
};
AnnotationBrowser5.propTypes = {
    index:PropTypes.number.required,
    isActive:PropTypes.bool,  // whether display solid
    setActive:PropTypes.func, // callback to set as active
    opacity:PropTypes.number, //opacity of overlay only (Pin opacity is in Pin Properties)
    pinOpacity:PropTypes.number,
    annotationData: PropTypes.array,
    pinProperties:PropTypes.object,
    viewerState: PropTypes.array.isRequired, // current image slice
    updateViewer: PropTypes.func.isRequired,
    updateViewerProperty:PropTypes.func.isRequired,
    linkViewers:PropTypes.bool,
    setLinkViewers:PropTypes.func,
    smoothing:PropTypes.bool.isRequired,
    changeSmoothing:PropTypes.func.isRequired,
    leftButtonMode:PropTypes.number, //parameter for interactor
    tracker:PropTypes.bool,
    colorOptions:PropTypes.array.isRequired,  //immutable options for drawing
    imageOptions:PropTypes.array.isRequired,   //  options for Image Types
    imageType:PropTypes.number.isRequired,
    changeImageType:PropTypes.func.isRequired,
    toolbarVisible:PropTypes.bool.isRequired, // should toolbar with slider be visible at all?
    pickerCallback:PropTypes.func,
    image:PropTypes.array.isRequired,
    changeOrientation:PropTypes.func.isRequired,
    rulers:PropTypes.array // the slicingModes of remaining viewers
};

export default AnnotationBrowser5;
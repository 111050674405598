import {
    EXPERIMENT_PROPERTY_NAME__DESCRIPTION, EXPERIMENT_PROPERTY_NAME__NAME, EXPERIMENT_PROPERTY_NAME__PRIVACY,
    EXPERIMENT_PROPERTY_NAME__PROJECT_ID, EXPERIMENT_PROPERTY_NAME__QUESTION,
    EXPERIMENT_PROPERTY_VALUE_PRIVACY__PUBLIC
} from "../../../Constants";
import {setStep} from "./WizardStepAction";
import {expDesignPagesEnum} from "../ExpDesign";
import {EXPDESIGN_SET_EXPERIMENT} from "./actionType";
import {history} from '../../Root'


//--------------------UPDATE SINGLE EXPERIMENT------------------------------
/**
 * Overwrite the experiment in store with the one given in parameter.
 * @param experiment - new value for experiment
 * @returns {{type, experiment: *}}
 */
const updateExperiment = experiment => ({
    type: EXPDESIGN_SET_EXPERIMENT,
    experiment
});



/** Action Creator for creating new Experiment Design object in store.
 *
 *   Experiment is stored only in Redux yet!
 *
 * @param projectId
 * @returns {function(*)}
 */
export const initNewExperimentActionCreator = (projectId) => (
    dispatch => {
        const experiment={};
        experiment[EXPERIMENT_PROPERTY_NAME__NAME]='';
        experiment[EXPERIMENT_PROPERTY_NAME__QUESTION]='';
        experiment[EXPERIMENT_PROPERTY_NAME__PRIVACY]=EXPERIMENT_PROPERTY_VALUE_PRIVACY__PUBLIC;
        experiment[EXPERIMENT_PROPERTY_NAME__DESCRIPTION]='';
        experiment[EXPERIMENT_PROPERTY_NAME__PROJECT_ID]=projectId;
        dispatch(updateExperiment(experiment));
        dispatch(setStep(expDesignPagesEnum.Global));
        history.push('/project/'+projectId+'/expdesign')
    }
);
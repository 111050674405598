import {
    WORKFLOW_LIST_REQUEST,
    WORKFLOW_LIST_FAIL,
    WORKFLOW_LIST_SUCCESS
} from '../action/actionType'
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";

/**
 *
 * @param state - state of workflows, ListState:status for the list of optional workflows,
 * workflows: optional workflows, workflowVariable: measurement for which the list of matching workflows is created
 * @param action
 * @returns {*}
 */
export const workflowListReducer = (state = { workflowListState: '', workflows: [],workflowVariable:{} }, action) => {
    console.log("workflowListreducer", action);
    switch (action.type) {
        case WORKFLOW_LIST_REQUEST:
            return Object.assign({},state, {
                workflowListState: REQUEST_STATUS_REQUESTED,
                workflowVariable:action.variable
            });
        case WORKFLOW_LIST_FAIL:
            return Object.assign({},state, {
                workflowListState: REQUEST_STATUS_FAIL,
                workflowVariable:action.variable
            });
        case WORKFLOW_LIST_SUCCESS:
            return Object.assign({}, state, {
                workflowListState: REQUEST_STATUS_SUCCESS,
                workflows: action.workflows,
                workflowVariable:action.variable
            });
        default:
            return state;
    }
};
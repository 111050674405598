import React from "react";
import {Button} from "primereact/components/button/Button";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {Spinner} from "primereact/components/spinner/Spinner";
import PropTypes from 'prop-types';
import {Dialog} from "primereact/components/dialog/Dialog";


/**
 * Stateful component with template for Pin Tool.
 * @returns {*}
 */
export class PinToolbar extends React.Component {

    constructor() {
        super();
        this.state = {
            dialogVisible:false
        };
            ["changeProperties"].forEach(name => {
                this[name] = this[name].bind(this);
            }); //Binding methods
        }

// export const  = ({toolProperties, changeToolProperties, clearAll}) => {

    changeProperties  (arg, value) {
    const {toolProperties,changeToolProperties}=this.props;
        let pp = Object.assign({}, toolProperties);
        pp[arg] = value;
        changeToolProperties(pp);
    };
    render(){
        const {toolProperties,clearAll}=this.props;
    return (
        <Toolbar style={{display: "inline-block"}}>
            <label style={{marginLeft: "1em"}}>Font size:</label>
            <Spinner value={toolProperties.fontSize}
                     min={0} max={36} step={1} size={3}
                     onChange={(e) => this.changeProperties('fontSize', e.value)}
            />
            <label style={{marginLeft: "1em"}}>Marker size:</label>
            <Spinner value={toolProperties.markerSize}
                     min={1} max={20} step={1} size={3}
                     onChange={(e) => this.changeProperties('markerSize', e.value)}
            />
            <Button icon="fa fa-trash" label="Clear all" onClick={() => this.setState({dialogVisible:true})}/>
            <Dialog header="Confirmation Dialog"
                    visible={this.state.dialogVisible}
                    width="350px"
                    modal={true}
                    onHide={()=>this.setState({dialogVisible:false})}
                    style={{textAlign:"center"}}
            >
                Are you sure that you want to remove all annotations?<br/>
                This change is irrevertible!<br/>
                <Button icon="fa fa-check" label="OK" onClick={() => {
                    this.setState({dialogVisible:false});
                    clearAll();}
                }/>
                <Button icon="fa fa-close" label="Cancel" onClick={()=>this.setState({dialogVisible:false})}/>
            </Dialog>
        </Toolbar>
    );
    }

};

PinToolbar.propTypes = {
    toolProperties: PropTypes.object.isRequired,
    changeToolProperties: PropTypes.func.isRequired,
    clearAll: PropTypes.func.isRequired
};

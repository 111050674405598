import React from 'react'
import {Dialog} from 'primereact/components/dialog/Dialog';
import {Button} from "primereact/components/button/Button";
import {InputText} from "primereact/components/inputtext/InputText";
import SpineVtkMRIBrowser from "./SpineVtkMRIBrowser";
import SpineVtkBrowser2 from "./alternatives/SpineVtkBrowser2";
import MultiSliceMapper from "./alternatives/MultiSliceMapper";
import BrainBrowser from "./alternatives/BrainBrowser";

/** Dialog Window container  for BrainBrowser component.
 *
 */
class BrainBrowserDialog extends React.Component {

    constructor() {
        super();
        this.state = {
            visible: false,
            visible2: false,
            visible3:false,
            fileName: '9a2510f8daeb1c8b38bf5db8a4003d57'
            // overlayFileNames: ['9a2510f8daeb1c8b38bf5db8a4003d58']
        };
        this.onClick = this.onClick.bind(this);
        this.onClick2 = this.onClick2.bind(this);
        this.onClick3 = this.onClick3.bind(this);
        this.onHide = this.onHide.bind(this);
        this.onHide2 = this.onHide2.bind(this);
        this.onHide3 = this.onHide3.bind(this);
        this.onChange = this.onChange.bind(this);
        // this.onOverlayChange = this.onOverlayChange.bind(this);
    }

    onChange(event) {
        this.setState({fileName: event.target.value});
    }
    // onOverlayChange(event) {
    //     this.setState({overlayFileNames: [event.target.value]});
    // }
    onClick() {
        this.setState({visible: true});
    }
    onClick2() {
        this.setState({visible2: true});
    }
    onClick3() {
        this.setState({visible3: true});
    }
    onHide() {
        this.setState({visible: false});
    }
    onHide2() {
        this.setState({visible2: false});
    }

    onHide3() {
        this.setState({visible3: false});
    }
    render() {

        let footer = <div>
            <Button label="Close" icon="fa-check" onClick={this.onHide}/>
        </div>;
        let filename = this.state.fileName;

        return (
            <div>
                <div>
                    <span className="reg-headtitle">MRI Browser</span>
                </div>
                <div>
                    <label>Image ID:</label>
                    <InputText value={this.state.fileName} size={80} onChange={this.onChange}/>
                </div>
                {/*<div>*/}
                    {/*<label>Overlay image:</label>*/}
                    {/*<InputText value={this.state.overlayFileNames[0]} size={80} onChange={this.onOverlayChange}*/}
                               {/*style={{leftMargin: '1em'}}/>*/}
                {/*</div>*/}
                <Dialog id="imgwithtexture" header={'Axial T2w'} footer={footer}
                        style={{width:'600px'}}
                        visible={this.state.visible} modal={false} onHide={this.onHide} resizable={false}>
                    {filename && this.state.visible && <SpineVtkMRIBrowser viewportSize={500}
                                                                viewportMode={'axial'}
                                                                originalImageUrl={'/overlay/'+this.state.fileName+'/original'}
                                                                overlayImageUrl={'/overlay/'+this.state.fileName}
                                                                originalImageFilename='or.nii'
                                                                overlayImageFilename='ov.nii.gz'
                                                                initialSlices={[0,0,20]}
                                                                progressBars={false}
                                                                onLoadingError={(err)=>{alert(err)}} //change here for growl or sth
                    />

                    }
                </Dialog>
                <Dialog id="imgwithtwoimageslices" header={'Axial T2w'} footer={footer}
                        style={{width:'600px'}}
                        visible={this.state.visible3} modal={false} onHide={this.onHide} resizable={false}>
                    {filename && this.state.visible3 &&

                    <SpineVtkBrowser2 viewportSize={500}
                                      viewportMode={'horizontal'}
                                      filename={'or.nrrd'}
                                      overlayFileNames={['ov200.nrrd']}
                                      url={'nifti/or.nrrd'}
                                      initialSlices={[0,0,20]}
                                      fileId='9a2510f8daeb1c8b38bf5db8a4003d57'
                                      progressBars={false}
                                      onLoadingError={(err)=>{alert(err)}} //change here for growl or sth
                    />

                    }

                </Dialog>
                <Dialog id="imgwithslices" header={'Axial T2w'} footer={footer} width='600px'
                        visible={this.state.visible2} modal={false} onHide={this.onHide2}>
                    {filename && this.state.visible2 && <MultiSliceMapper  originalImageUrl={'/overlay/'+this.state.fileName+'/original'}
                                                                           originalImageFilename='or.nii' />}

                </Dialog>
                <Button label="Show Slices with Textures" icon="fa-external-link-square" onClick={this.onClick}/>
                <Button label="Show 2 imageslice solution" icon="fa-external-link-square" onClick={this.onClick3}/>
                <Button label="Show Slices with ImageSlices" icon="fa-external-link-square" onClick={this.onClick2}/>
            </div>

        )
    }
}


export default BrainBrowserDialog;
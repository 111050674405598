import React from "react";
import {CheckboxGroup} from "../../root/CheckboxGroup";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import PropTypes from "prop-types";
import {ItemList} from "./ItemList";
import ExperimentItemCardElement from "./ExperimentItemCardElement";
import ExperimentItemListElement from "./ExperimentItemListElement";
import {
    EXPERIMENT_PROPERTY_NAME__QUESTION,
    EXPERIMENT_PROPERTY_VALUE_STATUS__COMPLETED, EXPERIMENT_PROPERTY_VALUE_STATUS__DRAFT,
    EXPERIMENT_PROPERTY_VALUE_STATUS__REVIEW
} from "../../../Constants";
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";



export class ProjectDetailsExperimentsTab extends React.Component {

    constructor(props){
        super(props);
        this.state={
            rmDialogVisible: false,
            selected:{}
        };
        this.onRmDialogHide=this.onRmDialogHide.bind(this);
        this.onRmDialogShow=this.onRmDialogShow.bind(this);
        this.onDelete=this.onDelete.bind(this);
    }

    /**
     *
     */
    onDelete(){
        this.props.onDeleteExperiment(this.state.selected);
        this.onRmDialogHide();
    }

    onRmDialogHide(){
        this.setState({
            rmDialogVisible:false,
            selected:{}
        });
    }
    onRmDialogShow(item){
        this.setState({
            rmDialogVisible:true,
            selected:item
        });
    }

    render() {
        const {project, isList, filter} = this.props;
        const onFilter = this.props.onFilterChange;

        console.log('IsList', isList);
        /**  filter params. should be taken from schema*/
        const titles = ["All", 'Draft', 'In review', 'Active', 'Completed', 'Published'];
        const filterValues= ["ALL", EXPERIMENT_PROPERTY_VALUE_STATUS__DRAFT,EXPERIMENT_PROPERTY_VALUE_STATUS__REVIEW,
            'ACTIVE', EXPERIMENT_PROPERTY_VALUE_STATUS__COMPLETED, 'PUBLISHED'];
        const hc = (e,i)=>{
            if (e.checked) {
                filter.delete(filterValues[i]);
                if (filter.size===1) onFilter(new Set());
                else onFilter(new Set(filter));}
            else  {
                filter.add(filterValues[i]);
                filter.add(filterValues[0]);//always add All to inactive filters
                onFilter(new Set(filter))}
        };

        // if active ise on -> draft and inreview are on too
        const ac = (e)=>{
            if (e.checked) {
                filter.delete(filterValues[3]);
                filter.delete(filterValues[2]);
                filter.delete(filterValues[1]);
                if (filter.size===1) onFilter(new Set());
                else onFilter(new Set(filter));}
            else  {
                filter.add(filterValues[3]);
                filter.add(filterValues[1]);
                filter.add(filterValues[2]);
                filter.add(filterValues[0]);//always add All to inactive filters
                onFilter(new Set(filter))}
        };
        const allChecked = (e,i)=>{
            if (e.checked)
                onFilter(new Set());}
        ;

        let clbks = [allChecked,hc,hc,ac,hc,hc];

        let values = [
            !filter.has('ALL'),
            !filter.has(EXPERIMENT_PROPERTY_VALUE_STATUS__DRAFT),
            !filter.has(EXPERIMENT_PROPERTY_VALUE_STATUS__REVIEW),
            !filter.has('ACTIVE'),
            !filter.has(EXPERIMENT_PROPERTY_VALUE_STATUS__COMPLETED),
            !filter.has('PUBLISHED')];
        let sorterOptions=[{value: 'SORT_BY_STATUS_DESC', label: 'Status Descending'},
            {value: 'SORT_BY_STATUS_ASC', label: 'Status Ascending'},
            {value: 'SORT_BY_LAST_MODIFIED_DESC', label: 'Last Modified Descending'},
            {value: 'SORT_BY_LAST_MODIFIED_ASC', label: 'Last Modified Ascending'},
            {value: 'SORT_BY_NAME_DESC', label: 'Question Descending'},
            {value: 'SORT_BY_NAME_ASC', label: 'Question Ascending'}
        ];

        const rmDialogFooter = (
            <div>
                <Button label="Yes" icon="fa fa-check" onClick={this.onDelete} />
                <Button label="No" icon="fa fa-times" onClick={this.onRmDialogHide} />
            </div>
        );

        return (
            <div>
                <div id="filters">
                    <CheckboxGroup titles={titles} clbks={clbks} values={values} filter={filter}/>
                    <Dropdown options={sorterOptions}
                              value={this.props.sorter}
                              onChange={(e)=>this.props.onSorterChange(e.value)}
                              style={{border:'none',background:'#f0f4f7'}}/>
                </div>
                {this.props.experimentsState ==='success' && this.props.experiments.length>0 &&
                <ItemList model={this.props.experiments}
                                isList={isList}
                                classNames={["ui-g-5","ui-g-2","ui-g-2","ui-g-2","ui-g-1"]}
                                headers={["Question","People","Status","Last modified","Remove"]}
                                properties={["question","co-investigators","status","modificationDate"]}
                                cardRenderer={<ExperimentItemCardElement onRemoveClick={this.onRmDialogShow} />}
                                listRenderer={<ExperimentItemListElement onRemoveClick={this.onRmDialogShow}/>}
                />
                }
                <Dialog header="Confirmation Dialog"
                        visible={this.state.rmDialogVisible}
                        width="350px"
                        modal={true}
                        footer={rmDialogFooter}
                        onHide={this.onRmDialogHide}>
                    Are you sure that you want to remove experiment answering the following question:
                    <div style={{fontWeight:'bold'}}>
                    {this.state.selected[EXPERIMENT_PROPERTY_NAME__QUESTION]}

                        ?</div>
                </Dialog>
            </div>
        )
    }
};
ProjectDetailsExperimentsTab.propTypes = {
    project: PropTypes.array.isRequired, //project
    isList:PropTypes.bool.isRequired, // display mode (true ->list in 1 column, false-> cards in 2 columns)
    filter:PropTypes.object
};
import React from "react";
import PropTypes from "prop-types";
import {Toolbar} from "primereact/components/toolbar/Toolbar";
import {ToggleButton} from "primereact/components/togglebutton/ToggleButton";
import {LEFT_BUTTON_MODE} from "../../vtk/SpineInteractorStyleImage";
import AnnotationBrowser5 from "../../vtk/AnnotationBrowser5";
import SpinePaintFilter from "../../vtk/paintbrush/SpinePaintFilter";
import {REQUEST_STATUS_SUCCESS} from "../../../Constants";


/**
 * Template for MRI browser attached to scatter plot.
 */
class MRIBrowserPanel extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            sidebarVisible:false,
            tracker:false,
            opacity:100,
            imageTypes:[0,0,0],
            showWindowPortal: false,
            linkViewers:true,
            smoothing:[false,false,false],
            leftButtonMode:LEFT_BUTTON_MODE.NONE,
            painter:SpinePaintFilter.newInstance(),
            colorOptions:
                [
                    {value:1,label:"Lesion",color:'#f00'},
                    {value:2,label:"Plaque",color:'#0f0'},
                    {value:3,label:"Toxin",color:'#00f'},
                    {value:4,label:"Tumor",color:'#f8f'},
                    {value:5,label:"Aging",color:'#8ff'}
                ]
        };
        ["viewCallback","setButtonLeftMode","pickerCallback","keyListener"]
            .forEach(name => { this[name] = this[name].bind(this);});
    }


    componentDidMount(){
        const {initializeViewers,imageId}=this.props;
        initializeViewers(imageId);
        document.body.addEventListener("keydown", this.keyListener, true);
    }

    componentDidUpdate(prevProps,prevState){
        const {overlays, images,imageId,initializeViewers } = this.props;
        const {painter}=this.state;
        const orig = (images!=null)?images.find((el)=>{return el.uuid === imageId}):null;
        const ov = (overlays!=null)?overlays.find((el)=>{return el.uuid === imageId}):null;

        //if painter not set then resample original image
        if (!painter.getBackgroundImage() && orig!=null && orig['state']===REQUEST_STATUS_SUCCESS
            && ov !=null &&  ov['state']===REQUEST_STATUS_SUCCESS){
                painter.setBackgroundImage(orig['data']);
                painter.update();
                painter.setOverlayImageData(ov['data']);
                painter.modified();
        }
        //if workflowResultId changes then  inititialize viewer for new set of images
        if (prevProps.imageId!==imageId){
            initializeViewers(imageId);
            this.setState({ painter:SpinePaintFilter.newInstance()});
        }
    }

    pickerCallback(viewerIndex, ijk){
        const {viewersState,updateViewerProperty} =this.props;
        let copyCis = [] ;
        viewersState.forEach((el,index)=>{copyCis.push(Object.assign({},viewersState[index]))});

        const id = copyCis[viewerIndex].imageId;
        let hasChanged = false;
        copyCis.forEach((el,i)=>{
            if (el.imageId===id){
                hasChanged=true;
                updateViewerProperty(i,'slice', ijk[el.slicingMode]);
            }
        });
    }

    componentWillUnmount(){
        const {clearAnnotatorState}=this.props;
        clearAnnotatorState();
        document.body.removeEventListener("keydown", this.keyListener, true);
    }

    setButtonLeftMode(e){
        const {leftButtonMode} = this.state;
        if (e!==leftButtonMode){
            this.setState({leftButtonMode:e});
        }
        else{
            this.setState({leftButtonMode:LEFT_BUTTON_MODE.NONE});
        }
    }


    keyListener(event) {
        const {activeViewerIndex} = this.state;
        const {updateViewerProperty, viewersState, images} = this.props;
        event.stopPropagation();
        event.preventDefault();
        if (activeViewerIndex > -1) {
            let slice = viewersState[activeViewerIndex].slice;
            const image= images.find((el)=>el.uuid===viewersState[activeViewerIndex].imageId);
            const maxSlice = image.data.getDimensions()[viewersState[activeViewerIndex].slicingMode] - 1;
            if (event.code === "ArrowDown" || event.code === "PageDown") {
                slice += 1;
                updateViewerProperty(activeViewerIndex, 'slice', (slice <= maxSlice) ? slice : maxSlice);
                return;
            }
            if (event.code === "ArrowUp" || event.code === "PageUp") {
                slice -= 1;
                updateViewerProperty(activeViewerIndex, 'slice', (slice >= 0) ? slice : 0);
                return;
            }
            if (event.code === "ArrowLeft" || event.code === "Home") {
                updateViewerProperty(activeViewerIndex, 'slice', 0);
                return;
            }
            if (event.code === "ArrowRight" || event.code === "End") {
                updateViewerProperty(activeViewerIndex, 'slice', maxSlice);
            }
        }
    }


    viewCallback(row){
        this.props.setActiveAnnotation(row);
    };

    render(){
        const {activeViewerIndex, pinProperties,linkViewers,smoothing,tracker,painter,
            leftButtonMode,colorOptions,opacity,imageTypes}=this.state;
        const {addAnnotation, data,  viewersState,
            updateViewerProperty,changeImageType,images,changeOrientation} =this.props;

        let setSmoothing = (viewport)=>{
            let smooth = smoothing;
            smooth[viewport]= ! smooth[viewport];
            this.setState({smoothing:smooth});
        };


        let getImage =(index)=>{
            return images.find((el)=>viewersState[index].imageId === el.uuid);
        };

        let calcRulers = (index)=>{
            if (activeViewerIndex>-1){
                let rulers = [];
                let thisView =  viewersState[index];
                let activeView =  viewersState[activeViewerIndex];
                if (thisView.imageId !== activeView.imageId)
                    return [];
                viewersState.forEach((el,i)=>{
                    if(thisView.imageId === el.imageId){
                        if (thisView.slicingMode!==el.slicingMode){


                            rulers.push({slice:el.slice,slicingMode:el.slicingMode,orientation:el.orientation});
                        }
                    }
                });
                return rulers;
            }
            else return [];
        };


        let browserPanel = (viewersState)?viewersState.map((el,index)=>
            <div className="ui-g-4" style={{padding:"0.1em"}}>
                <AnnotationBrowser5 key={`viewer${index}`}
                                    isActive={this.state.activeViewerIndex===index}
                                    setActive={()=>this.setState({activeViewerIndex:index})}
                                    annotationData={data}
                                    addRow={(roi)=>addAnnotation(roi,index)}
                                    pinProperties={pinProperties}
                                    tracker={tracker}
                                    linkViewers={linkViewers}
                                    leftButtonMode={leftButtonMode}
                                    opacity={opacity}
                                    pinOpacity={100}
                                    colorOptions={colorOptions}
                                    imageOptions={null}
                                    setLinkViewers={() => this.setState({linkViewers: !linkViewers})}
                                    viewerState={el}
                                    updateViewer={(viewerState) => this.updateViewer(index, viewerState)}
                                    pickerCallback={(ijk) => this.pickerCallback(index, ijk)}
                                    smoothing={smoothing[index]}
                                    changeSmoothing={() => setSmoothing(index)}
                                    changeOrientation={(v) => changeOrientation(index, v)}
                                    imageType={imageTypes[index]}
                                    changeImageType={(e)=>changeImageType(index,e)}
                                    updateViewerProperty={(property,value)=>updateViewerProperty(index,property,value)}
                                    image={getImage(index)}
                                    rulers={(tracker)?calcRulers(index):null}
                                    painter={painter}
                />
            </div>
        ): <div className="ui-g-12" style={{padding:"0.1em"}}/>;

        return (
            <div className="ui-g-12 ui-g-nopad">
                <div className="ui-g-12 ui-g-nopad">
                    <Toolbar style={{display:"inline-block"}}>
                        <ToggleButton checked= {leftButtonMode===LEFT_BUTTON_MODE.PICKER}
                                      onIcon="fa fa-hand-o-up"
                                      onLabel="" offLabel=""
                                      offIcon="fa fa-hand-o-up"
                                      iconPos="right"
                                      tooltip="Picker"
                                      onChange={()=>{this.setButtonLeftMode(LEFT_BUTTON_MODE.PICKER)}}/>
                        <ToggleButton checked={tracker}  onLabel="" offLabel="" toolTip="Crosshairs On/Off"
                                      onChange={()=>{this.setState({tracker:!tracker})}} onIcon="fa fa-crosshairs" offIcon="fa fa-crosshairs"/>
                        <label style={{marginLeft:"1em"}}>Opacity:</label>
                        <input type="range" onChange={(e)=>this.setState({opacity:e.target.value})}
                               min={0} step={10}  max={100}
                               style={{width:"4em",
                                   verticalAlign: "middle",
                                   marginRight: "5px",
                                   marginLeft: "5px"
                               }}
                               value={opacity}
                        />
                    </Toolbar>
                </div>

                {browserPanel}

            </div>
        )
    }

}



export default MRIBrowserPanel ;


MRIBrowserPanel.propTypes = {
    imageId:PropTypes.string.isRequired,
    updateAnnotationData:PropTypes.func.isRequired,
    saveOverlayData:PropTypes.func,
    clearAll : PropTypes.func.isRequired,
    setActiveAnnotation:PropTypes.func.isRequired,
    clearAnnotatorState:PropTypes.func.isRequired,
    initializeViewers:PropTypes.func.isRequired,
    viewersState:PropTypes.array.isRequired,  //array containing state of all viewers
    updateViewerProperty:PropTypes.func.isRequired,
    updateViewer:PropTypes.func,
    imageOptions:PropTypes.array.isRequired,
    images:PropTypes.array.isRequired,        //pool [array] of images in memory
    overlays:PropTypes.array.isRequired,      //pool [array] of overlays in memory
    changeOrientation:PropTypes.func.isRequired
};



import React from "react";
import PropTypes from "prop-types";
import {
    COHORT_PROPERTY_NAME__COINVESTIGATORS, COHORT_PROPERTY_NAME__CREATION_DATE, COHORT_PROPERTY_NAME__DESCRIPTION,
    COHORT_PROPERTY_NAME__NAME, COHORT_PROPERTY_NAME__PI, COHORT_PROPERTY_NAME__SUBJECTS, COHORT_PROPERTY_NAME__UUID,
    EXPERIMENT_PROPERTY_NAME__COHORTS
} from "../../../Constants";
import {Checkbox} from "primereact/components/checkbox/Checkbox";

/**"Dumb" renderer for "List" mode for Cohorts.
 * Used in Cohort Options Panel.
 * @param item - mandatory component if used with ItemList container
 * @param cohorts- selected cohorts
 * @param onSelect - callback to provide handler for checkbox onSelect
 * @returns {*}
 * @constructor
 */
const CohortOptionsItemListElement = ({item,experiment,onSelect}) => {


    return (
        <div className="cohortListElement"
            // onClick={()=>{history.push('/project/'+item.cohortId+'/experiment/'+item.uuid)}}
        >
            <div className="ui-g-3">
                {item[COHORT_PROPERTY_NAME__NAME]}
            </div>
            <div className="ui-g-3">
                {/*{item[COHORT_PROPERTY_NAME__DESCRIPTION]}*/}
                {/*{item[COHORT_PROPERTY_NAME__PI]}*/}
                Charles Guttmann
            </div>
            <div className="ui-g-3">
                {item[COHORT_PROPERTY_NAME__SUBJECTS].count}
            </div>
            <div className="ui-g-3">
                <Checkbox checked={experiment[EXPERIMENT_PROPERTY_NAME__COHORTS].includes(item[COHORT_PROPERTY_NAME__UUID])}
                          onChange={()=>{onSelect(item)}}/>
            </div>
        </div>
    )
};
export default CohortOptionsItemListElement;

CohortOptionsItemListElement.propTypes = {
    item: PropTypes.object.isRequired,
    experiment : PropTypes.object.isRequired, //selected cohorts
    cohortList: PropTypes.array.isRequired,//all cohorts
    onSelect :  PropTypes.func.isRequired,//callback to select cohort
    selectCheckboxState : PropTypes.func,// flag indicating whether all cohorts are selected
    handleChange : PropTypes.func.isRequired,//does not apply here, just passed with others
    isList : PropTypes.bool,//does not apply here
    listModeClb: PropTypes.func//does not apply here
};
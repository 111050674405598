import React from 'react'
import PropTypes from 'prop-types';
import {Panel} from "primereact/components/panel/Panel";
import {Card} from "primereact/components/card/Card";
import {Rating} from "primereact/components/rating/Rating";



/**
 * Panel displaying conclusion for test.
 *
 */

export class TestConclusionPanel extends React.Component {

    constructor(){
        super();
        this.state={
            panelCollapsed:true
        };
        ["togglePanel"]
            .forEach(name => {
                this[name] = this[name].bind(this);
            }); //Binding methods
    }

    togglePanel(e) {
        this.setState({panelCollapsed: !this.state.panelCollapsed})
    }



    render() {
        const {modelStatus,vars,modelParameters} = this.props;

        return (
            <Panel header={"Final Conclusion"}
                   toggleable={true}
                   collapsed={this.state.panelCollapsed}
                   onCollapse={() => this.togglePanel(false)}
                   onExpand={() => this.togglePanel(true)}>
                {modelStatus==='success'  && vars.length<3 &&
                    <Card>
                        The confidence interval for slope parameter
                        {(this.props.modelParameters.betasCIDataTableModel.lwr<0 && this.props.modelParameters.betasCIDataTableModel.upr>0)?' contains ':' does not contain '} 0,
                        so it can be concluded there is {(this.props.modelParameters.betasCIDataTableModel.lwr<0  && this.props.modelParameters.betasCIDataTableModel.upr>0)?' no ':' '}
                        significant evidence of a linear relationship between predictor x and response y in the population.
                    </Card>
                    }
                </Panel>
        )
    }
}

TestConclusionPanel.propTypes = {
    modelStatus:PropTypes.string,
    vars:PropTypes.array,
    modelParameters:PropTypes.array
};

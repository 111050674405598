import {
    ANNOTATION_PROPERTY_NAME__ID,
    ANNOTATION_PROPERTY_NAME__ROI,
    ANNOTATION_PROPERTY_NAME__ROI_ROI_STATUS,
    ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB,
    HOST_URL
} from "../../../Constants";
import axios from 'axios'
import store from "../../Store";
import {
    ANNOTATION_IMAGE_FAIL, ANNOTATION_IMAGE_REQUESTED,
    ANNOTATION_IMAGE_SUCCESS,
    ANNOTATION_LIST_FAIL,
    ANNOTATION_LIST_REQUEST,
    ANNOTATION_LIST_SUCCESS,
    ANNOTATION_LIST_UPDATE, CLEAR_ANNOTATOR_STATE, LABELMAP_IMAGE_REQUESTED,
    LABELMAP_IMAGE_SUCCESS, UPDATE_IMAGE_OPTIONS, UPDATE_SINGLE_VIEWER_PROPERTY, UPDATE_SINGLE_VIEWER_STATE,
    UPDATE_VIEWERS_STATE,
} from "./actionType";
import extensionToImageIO from "itk/extensionToImageIO";
import ReaderFactory from "../../vtk/ReaderFactory";
import vtkITKImageReader from "vtk.js/Sources/IO/Misc/ITKImageReader/index";
import readImageArrayBuffer from "../../vtk/readImageArrayBuffer";
import {loggingDataset} from "../../vtk/MRIBrowserLogger";
import {spineFinalLabels4} from "../tests/finalLabels";
import writeImageArrayBuffer from "itk/writeImageArrayBuffer";
import {convertVtkToItkImage} from "../../vtk/convertVtkToItk";
import itkreadImageDICOMFileSeries from 'itk/readImageDICOMFileSeries';
import vtkHttpDataAccessHelper from 'vtk.js/Sources/IO/Core/DataAccessHelper/HttpDataAccessHelper';
import vtkITKHelper from "vtk.js/Sources/Common/DataModel/ITKHelper/index";
import untar from "js-untar"



//****************************************************************************
//
//                                Actions
//
//****************************************************************************

// Annotation actions *************************************************************

const updateAnnotations = (annotations) => ({
    type: ANNOTATION_LIST_UPDATE,
    annotations
});

/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestAnnotations = () => ({
    type: ANNOTATION_LIST_REQUEST
});

/**
 * An action informing the reducers that the request failed.
 * @param err
 * @returns {{type}}
 */
const errorAnnotations = err => ({
    type: ANNOTATION_LIST_FAIL,
    err
});

/**
 * An action informing the reducers that the request finished successfully.
 * @param annotations
 * @returns {{type, variablesList: *}}
 */

const successAnnotations = (annotations) => ({
    type: ANNOTATION_LIST_SUCCESS,
    annotations
});


/**
 * Helper for setting active element.
 * @param data
 * @param id - property of element to be active
 * @returns modified data
 */
const setActive = (data,id)=>{
    for(let i=0;i<data.length;i++){
        if (data[i][ANNOTATION_PROPERTY_NAME__ID]===id)
            data[i][ANNOTATION_PROPERTY_NAME__ROI][ANNOTATION_PROPERTY_NAME__ROI_ROI_STATUS]='active';
        else
            data[i][ANNOTATION_PROPERTY_NAME__ROI][ANNOTATION_PROPERTY_NAME__ROI_ROI_STATUS]='normal';
    };
    return data;
};

/**
 * Helper for setting sub - annotation element.
 * @param data
 * @param id - property of element to be active
 * @returns modified data
 */
const setSub = (data,id)=>{
    for(let i=0;i<data.length;i++){
        if (data[i][ANNOTATION_PROPERTY_NAME__ID]===id && data[i][ANNOTATION_PROPERTY_NAME__ROI][ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB]==null)
            data[i][ANNOTATION_PROPERTY_NAME__ROI][ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB]=true;
        else{
           if (data[i][ANNOTATION_PROPERTY_NAME__ROI][ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB]===true)
                delete data[i][ANNOTATION_PROPERTY_NAME__ROI][ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB];}
    };
    return data;
};

/**
 * ActionCreator for setting active annotation.
 * @param row - annotation to display
 * @returns {function(*,*)}
 */
export const setActiveAnnotation = (row) => {
    return (dispatch,getState) => {
        let anns  = getState().visu.annotations.annotations;
        let viewersState=getState().visu.annotations.viewersState;
        let data = setActive(anns.data,row[ANNOTATION_PROPERTY_NAME__ID]);
        dispatch (updateAnnotationData(data));

        viewersState.forEach((el,index)=>{
          if (el['imageId']===row.roi.viewersState[index]['imageId'])
              dispatch(updateViewerProperty(index,'slice',row[ANNOTATION_PROPERTY_NAME__ROI]['roiCellIJK'][el['slicingMode']]))
        });
        // dispatch(updateViewersState(row.roi['viewersState']));
    };
};

// export const restoreSnapshot = (viewer,index) => {
//     return (dispatch,getState) => {
//         let anns  = getState().visu.annotations.annotations;
//         dispatch (updateAnnotationData(data));
//     };
// };


/**
 * ActionCreator for setting sub annotation selection.
 * @param row -
 * @returns {function(*,*)}
 */
export const setSubAnnotation = (row) => {
    return (dispatch,getState) => {
        let anns  = getState().visu.annotations.annotations;
        let data = setSub(anns.data,row[ANNOTATION_PROPERTY_NAME__ID]);
        dispatch (updateAnnotationData(data));
    };
};

/**
 * ActionCreator for container.
 * @param id - task id, skill id, etc.
 * @returns {function(*)}
 */
export const getAnnotations = (id) => {
    return dispatch => {
        // dispatch (successAnnotations({columns:ANNOTATION_TABLE_MOCK,data:ANNOTATION_TABLE_DATA_MOCK}));
        dispatch (successAnnotations({columns:spineFinalLabels4,data:[]}));
    };
};

/**
 * ActionCreator for updating data.
 * @param data
 * @returns {function(*, *)}
 */
export const updateAnnotationData = (data) => {
    return (dispatch, getState) =>{
        console.log("Setting annotation data",data);
        let anns  = getState().visu.annotations.annotations;
        anns.data=data.slice(0);
        dispatch (updateAnnotations(anns));
    };
};


/**
 * ActionCreator for removing annotation.
 * @param data
 * @returns {function(*, *)}
 */
export const removeAnnotation = (data) => {
    return (dispatch, getState) =>{
        console.log("Remove annotation data",data);
        let anns  = getState().visu.annotations.annotations;
        let index = anns.data.findIndex((el)=>{return el[ANNOTATION_PROPERTY_NAME__ID]===data[ANNOTATION_PROPERTY_NAME__ID]});
        if (index > -1) {
            let characteristic = data[ANNOTATION_PROPERTY_NAME__ID].split(".")[0];
            let mantissa = data[ANNOTATION_PROPERTY_NAME__ID].split(".")[1];
            if (mantissa!=null)
                anns.data.splice(index,1);
            else
                anns.data = anns.data.filter((el)=>{return el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0]!==characteristic}) ; //remove full branch

            anns.data.forEach((el,index,object)=>{
                if (mantissa!=null  && characteristic === el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0]) {
                    if (el[ANNOTATION_PROPERTY_NAME__ID].split(".")[1] > mantissa) {
                        el[ANNOTATION_PROPERTY_NAME__ID] = el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0] + '.' + String(Number(el[ANNOTATION_PROPERTY_NAME__ID].split(".")[1])-1);
                    }
                }
                if (!mantissa){
                    if (el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0] > characteristic) { //decrease before decimal
                        let charact = String(Number(el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0])-1) ;
                        let mantis =  (el[ANNOTATION_PROPERTY_NAME__ID].split(".")[1]!=null)?'.'+el[ANNOTATION_PROPERTY_NAME__ID].split(".")[1]:'';
                        el[ANNOTATION_PROPERTY_NAME__ID] = charact + mantis;
                    }
                    // if (el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0] === characteristic) { //remove full branch
                    // object.splice(index,1);
                    // }

                }
        });
        }
        dispatch (updateAnnotationData(anns.data));
    }
};


/**
 * ActionCreator for clearing all annotations.
 * @returns {function(*)}
 */
export const clearAllAnnotations = () => {
     return (dispatch) =>
         dispatch(updateAnnotationData([]));
};

/**
 * ActionCreator for adding annotation.
 * @param roi Region of Interest object
 * @param index of viewer
 * @returns {function(*,*)}
 */
export const addAnnotation = (roi,index) => {
    return (dispatch,getState) =>{
        let data = getState().visu.annotations.annotations.data;
        let viewersState = getState().visu.annotations.viewersState;
        let sub = data.find((el)=>{return el[ANNOTATION_PROPERTY_NAME__ROI].hasOwnProperty(ANNOTATION_PROPERTY_NAME__ROI_ROI_SUB)});
        let element = {};
        roi['imageId']=viewersState[index]['imageId'];
        roi['viewersState']=viewersState.slice(0);
        if (sub != null){
            let characteristic = sub[ANNOTATION_PROPERTY_NAME__ID].split(".")[0];
            let mantissa =(data.length>0)?Math.max.apply(Math, data
                .filter((el)=>{ return el[ANNOTATION_PROPERTY_NAME__ID].split(".")[0] ===characteristic;})
                .map((el)=>{ return (el[ANNOTATION_PROPERTY_NAME__ID].split(".")[1]!=null)?Number(el[ANNOTATION_PROPERTY_NAME__ID].split(".")[1]):0})):0;
            mantissa += 1;
            element[ANNOTATION_PROPERTY_NAME__ID] = characteristic + '.'+mantissa.toString();
            element[ANNOTATION_PROPERTY_NAME__ROI]= roi;
        }

        else {
            let maxIndex =(data.length>0)?Math.max.apply(Math, data.map((el)=>{ return parseInt(el[ANNOTATION_PROPERTY_NAME__ID]);})):0;
            element[ANNOTATION_PROPERTY_NAME__ID] = ""+(maxIndex + 1);
            element[ANNOTATION_PROPERTY_NAME__ROI]= roi;
        }
        data.push(element);
        data = setActive(data,element[ANNOTATION_PROPERTY_NAME__ID]);
        dispatch(updateAnnotationData(data))};
};

//***********************Reading images*****************************************************
/**
 * An action informing the reducers that the request finished successfully.
 * @param imageData
 * @param index
 * @param imageId
 * @returns {{type, imageData: *}}
 */

const successAnnotationImage = (imageData,imageId) => ({
    type: ANNOTATION_IMAGE_SUCCESS,
    imageData,
    imageId
});


const errorAnnotationImage = (error,imageId) => ({
    type: ANNOTATION_IMAGE_FAIL,
    error,
    imageId
});

const requestAnnotationImage = (imageId) => ({
    type: ANNOTATION_IMAGE_REQUESTED,
    imageId
});

const successLabelMapImage = (imageData,imageId) => ({
    type: LABELMAP_IMAGE_SUCCESS,
    imageData,
    imageId
});

const requestLabelMapImage = (imageId) => ({
    type: LABELMAP_IMAGE_REQUESTED,
    imageId
});

const errorLabelMapImage = (error,imageId) => ({
    type: LABELMAP_IMAGE_REQUESTED,
    imageId,
    error
});

const extensions = Array.from(
    new Set(Object.keys(extensionToImageIO).map((ext) => ext.toLowerCase()))
);
vtkITKImageReader.setReadImageArrayBufferFromITK(readImageArrayBuffer); //required by external library
extensions.forEach((extension) => {
    ReaderFactory.registerReader({
        extension,
        name: `${extension.toUpperCase()} Reader`,
        vtkReader: vtkITKImageReader,
        binary: true,
        fileNameMethod: 'setFileName',
    });
});
/**
 * ActionCreator for reading MRI image. Requires format of file to read.
 * @param imageId - to load
 * @param imageId
 * @returns {function(*,*)}
 */
export const getMRIData = (imageId) => {
    return (dispatch,getState) =>{
        console.log("Reading image",);
        let imOp = getState().visu.annotations.imageOptions;
        let images = getState().visu.annotations.images;
        let format = null;
        try {
            format = imOp.find((el) => {
                return el.value === imageId
            }).format;
        } catch(err){
            format = "or.nii"
        };

        if (images.findIndex((el)=>el.uuid===imageId)>-1)
            return;

        dispatch (requestAnnotationImage(imageId));
        if (format===".tar.gz"){
            vtkHttpDataAccessHelper.fetchBinary('/overlay/'+imageId+'/original').then((arrayBuffer) => {
                let files=null;
                untar(arrayBuffer).then(
                    function(extractedFiles) {
                        files= extractedFiles.map((el)=>{return new File([el.buffer],el.name)});
                        itkreadImageDICOMFileSeries(null, files)
                            .then(({ image: itkImage, webWorker }) => {
                                const data = vtkITKHelper.convertItkToVtkImage(itkImage);
                                if (data!=null)
                                    dispatch (successAnnotationImage(data, imageId));
                                else
                                    dispatch(errorAnnotationImage("Empty",imageId));
                            })
                    },
                    function(err) { // onError
                        dispatch(errorAnnotationImage(err,imageId));
                    },
                );
            })
        }
        else {
            ReaderFactory.downloadDataset('file'+format, '/overlay/'+imageId+'/original', null)
            .then((result) => {
                const reader = result.reader;
                const data = reader.getOutputData();
                if (data!=null)
                   dispatch (successAnnotationImage(data,imageId));
                else
                   dispatch(errorAnnotationImage(data,imageId));
            })
        }
    };
};

/**
 * ActionCreator for reading overlay image.
 * @param overlayId - url to file to load
 * @returns {function(*,*)}
 */
export const getOverlayData = (overlayId) => {
    return (dispatch,getState) =>{
        console.log("Reading image",);
        let overlays = getState().visu.annotations.overlays;
        if (overlays.findIndex((el)=>el.uuid===overlayId)>-1)
            return;

        dispatch (requestLabelMapImage(overlayId));
        ReaderFactory.downloadDataset('or.nii.gz', '/overlay/'+overlayId, null)
            .then((result) => {
                const reader = result.reader;
                const data = reader.getOutputData();
                if (data!=null)
                    dispatch (successLabelMapImage(data,overlayId));
                else
                    dispatch (errorLabelMapImage(data,overlayId));
            })
            .catch(error => {
                    dispatch(errorLabelMapImage(error,overlayId));
        })
    };
};


/**
 * ActionCreator for saving overlay image.
 * @param vtkImageData - data to save
 * @param id - task id (?)
 * @returns {function(*)}
 */
export const saveOverlayData = (vtkImageData,id) => {
    return (dispatch) => {
        console.log("Saving image", vtkImageData);
        let itkImage = convertVtkToItkImage(vtkImageData);
        writeImageArrayBuffer(null, true, itkImage, 'image.nii').then(
            ({arrayBuffer,webWorker})=>{
            const config = {
               headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
             };
                let blobbed = new Blob([arrayBuffer]);
                const data = new FormData();
                data.append('file', blobbed, id+'.nii');
                axios.post(HOST_URL + '/upload', data,config)
                    .then(response => {
                        console.log('ProjectAction.js :: getProjectList :: response ::', response)
                        if (response.status !== 200) {
                            // dispatch(failProjectList(err))
                            console.log(err);
                        } else {
                            //dispatch(successProjectList(response.data))
                        }
                    }).catch(error => {
                    console.log('project err:', error);
                    //  dispatch(failProjectList(error))
                });
            }
        )
    }

};

const clearAnnotator = () => ({
    type: CLEAR_ANNOTATOR_STATE,
});
export const clearAnnotatorState = ()=>{
    return (dispatch) => {
        dispatch(clearAnnotator());
    }
};

const updateViewersState = (viewers) => ({
    type: UPDATE_VIEWERS_STATE,
    viewers
});

const updateImageOptions = (options) => ({
    type: UPDATE_IMAGE_OPTIONS,
    options
});

/**
 * ActionCreator for initializing viewers state.
 *
 * @returns {function(*,*)}
 */
export const initializeAnnotationTool = () => {
    return (dispatch, getState) => {
        // AMP - Fix for the annotaition tool
        const imageOptions = [
            {label:"T2", value:"666343ee0204518548f7d98d814c78c3", format:".nii", hasOverlay:true, overlayId:"2096d5f917a58a0d0f69ff272d0b1bac"},
            {label:"T1", value:"666343ee0204518548f7d98d814c33b4", format:".nii" },
            {label:"Flair", value:"666343ee0204518548f7d98d814c3e73", format:".nii"},
            {label:"PDW", value:"666343ee0204518548f7d98d814c6a18", format:".nii.gz"}
            //,{label:"Diffusion", value:"9a2510f8daeb1c8b38bf5db8a4003d61",format:".tar.gz"},
            //{label:"T1 sx25", value:"9a2510f8daeb1c8b38bf5db8a4003d62",format:".nii.gz"},
            //{label:"T1 damaged", value:"9a2510f8daeb1c8b38bf5db8a4003d63",format:".nii.gz"}
        ];
	// PMA
        dispatch(updateImageOptions(imageOptions));

        const images = getState().visu.annotations.images;
        const overlays = getState().visu.annotations.overlays;

        dispatch (successAnnotations({columns:spineFinalLabels4,data:[]}));


        //initial State of viewers
        const initialState = [//slice number, orientation (eg. axial), slicing mode (i,j,k),  is linked?
            {slice:0,  orientation:0, slicingMode:null, linked:false,
               imageId:"666343ee0204518548f7d98d814c78c3"},
            {slice:0,  orientation:1, slicingMode:null, linked:false,
                imageId:"666343ee0204518548f7d98d814c78c3"},
            {slice:0,  orientation:2, slicingMode:null, linked:false,
                imageId:"666343ee0204518548f7d98d814c78c3"}
        ];

        //load images if they're not in the memory
        initialState.forEach((element,index)=>{
            const image= images.find((el)=>el.uuid===element.imageId); //look for already loaded
            if (element.imageId!=null &&  element.imageId!=="" && !(image!=null)){
                dispatch(getMRIData(element.imageId))
            };
            const optionImage= getState().visu.annotations.imageOptions.find((el)=>el.value===element.imageId);
            const overlay= overlays.find((el)=>el.uuid===optionImage.overlayId); //look for already loaded
            if(optionImage.hasOverlay && optionImage.overlayId!=null && !(overlay!=null)){
                dispatch(getOverlayData(optionImage.overlayId))
            } ;
        });

        dispatch(updateViewersState(initialState));



    };
};

const updateSingleViewerState = (viewer,index) => ({
    type: UPDATE_SINGLE_VIEWER_STATE,
    viewer,
    index
});
/**
 * ActionCreator for updating only one row in viewersState.
 * @param index
 * @param viewer
 * @returns {function(*)}
 */
export const updateSingleViewer  = (viewer,index) => {
    return (dispatch) => {
        dispatch(updateSingleViewerState(viewer,index));
    };
};

const updateViewerStateProperty = (index,property,value) => ({
    type: UPDATE_SINGLE_VIEWER_PROPERTY,
    index,
    property,
    value
});

/**
 * ActionCreator for updating only one property in viewersState.
 * @param index
 * @param property
 * @param value
 * @returns {function(*)}
 */
export const updateViewerProperty  = (index,property,value) => {
    return (dispatch) => {
        dispatch(updateViewerStateProperty(index,property,value));
    };
};

/**
 * ActionCreator for changing the image type.
 * @param index
 * @param value
 * @returns {function(*,*)}
 */
export const changeImageType  = (index,value) => {
    return (dispatch) => {
        dispatch(updateViewerProperty(index,'slice',0));
        dispatch(updateViewerProperty(index,'imageId',value));
        dispatch(getMRIData(value));
    };
};

/**
 * ActionCreator for changing orientation.
 * @param index
 * @param value
 * @returns {function(*,*)}
 */
export const changeOrientation  = (index,value) => {
    return (dispatch,getState) => {
        let viewerState = Object.assign({}, getState().visu.annotations.viewersState[index]);
        viewerState['orientation'] = value;
        viewerState['slice'] = 0;
        dispatch(updateSingleViewer(viewerState,index));
    };
};


/** This function is used for simple viewing (scatter plot).
 *
 *
 * @param imageId - workflowResultId (the same for original image and overlay)
 * @returns {function(*, *)}
 */
export const initializeViewers = (imageId) => {
    return (dispatch, getState) => {


        const images = getState().visu.annotations.images;
        const overlays = getState().visu.annotations.overlays;

        //initial State of viewers
        const initialState = [//slice number, orientation (eg. axial), slicing mode (i,j,k),  is linked?
            {slice:0,  orientation:0, slicingMode:null, linked:false,
                imageId:imageId},
            {slice:0,  orientation:1, slicingMode:null, linked:false,
                imageId:imageId},
            {slice:0,  orientation:2, slicingMode:null, linked:false,
                imageId:imageId}
        ];

        //load images if they're not in the memory
        initialState.forEach((element)=>{
            const image= images.find((el)=>el.uuid===element.imageId); //look for already loaded
            if (element.imageId!=null &&  element.imageId!=="" && !(image!=null)){
                dispatch(getMRIData(element.imageId))
            };
            const overlay= overlays.find((el)=>el.uuid===element.imageId); //look for already loaded
            if(element.imageId!=null &&  element.imageId!=="" && !(overlay!=null)){
                 dispatch(getOverlayData(element.imageId))
            } ;
        });

        dispatch(updateViewersState(initialState));



    };
};

import React from 'react'
import {Dropdown} from 'primereact/components/dropdown/Dropdown'
import PropTypes from 'prop-types'

/**
 * This is Placeholder for presentation purposes only.
 * @deprecated
 */
class SkillSelector extends React.Component {
	constructor() {
		super();

		this.state = {
			skill: null,
			level: 'Gold'
		};

		this.onSkillChange = this.onSkillChange.bind(this);
		this.onLevelChange = this.onLevelChange.bind(this);
	}

	onSkillChange(event){
		this.setState({
		  skill: event.value
		});
	}
	onLevelChange(event){
		this.setState({
		  level: event.value
		});
	}

	render() {

		var levels = [
			{label: 'bronze', value: 'Bronze'},
			{label: 'silver', value: 'Silver'},
			{label: 'gold', value: 'Gold'},
		];

		var skills = [
			{label: 'segmentation of the corpus callosum', value: 'segmentation of the corpus callosum'},
			{label: 'segmentation of the hippocampus', value: 'segmentation of the hippocampus'},

		];

		return(
			<div className="ui-g">
        <Dropdown value={this.state.skill} options={skills} onChange={this.onSkillChange} style={{width:'400px'}} editable={true} placeholder="Select a skill"/>
        <Dropdown value={this.state.level} options={levels} onChange={this.onLevelChange} style={{width:'150px'}} placeholder="Select a level"/>
        <br/>
      </div>

			)
	}
}

export default SkillSelector
import store from "../../Store";
import NetworkService from "../../helpers/io/NetworkService";
import {EXPDESIGN_CLEAR_SCRIPT_SUCCESS, EXPDESIGN_EXECUTE_SCRIPT_REQUEST} from "./actionType";
import {EXPDESIGN_EXECUTE_SCRIPT_FAIL} from "./actionType";
import {EXPDESIGN_EXECUTE_SCRIPT_SUCCESS} from "./actionType";



/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestScriptExecution = () => ({
    type: EXPDESIGN_EXECUTE_SCRIPT_REQUEST
});

/**
 * An action informing the reducers that the request failed.
 * @param error
 * @returns {{type}}
 */
const errorScriptExecution = error => ({
    type: EXPDESIGN_EXECUTE_SCRIPT_FAIL,
    error
});

/**
 * An action informing the reducers that the request finished successfully.
 * @param plots - array of plots
 * @param stdOut- parsed text
 * @param rawData - full response from evaluate function
 * @returns {{type, model: *}}
 */

const successScriptExecution = (plots,stdOut,rawData) => ({
    type: EXPDESIGN_EXECUTE_SCRIPT_SUCCESS,
    plots,
    stdOut,
    rawData
});

/**
 * Executes script in server, parses the response and changes Store.
 * @param script - text of script (string)
 * @param data - data from state
 * @returns {function(*,*)} change state of Store
 */
export const executeScript = (script,data) => {
    return (dispatch,getState) => {

        dispatch(requestScriptExecution());
        NetworkService.executeScript({txtScript:script,data:data})
                .then(outData => {
                    let stdOut = "";
                    let plots = [];
                    console.log('StatisticalModelAction.js :: executeScript :: response', outData);
                    if(outData!=null && outData['value']!=null && outData['value'].length>0){

                        outData['value'].forEach((el)=>{
                            if (el['type']==='character'){
                                stdOut+=el['value'];
                            }
                            if(el['type']==='list'){
                                if(el['value']!=null && el['value'].length>0 ){
                                    if(el['value'][0]!=null && el['value'][0]['value']!=null && el['value'][0]['value'][0]==="img"){
                                        plots.push(el['value'][1]['value'][1]['value']);
                                    };
                                    el['value'].forEach((val)=>{
                                        // if (val['type']==="raw"){
                                        //     plots.push(hexToBase64(val['value']));
                                        // }


                                        if (val['type']==="character"){
                                            stdOut+=val['value'];
                                        }
                                        if (val['type']==="language"){
                                            stdOut+=val['value'];
                                        }
                                    })
                                }

                            }
                        });

                        dispatch(successScriptExecution(plots,stdOut,outData));
                    }
                    else
                        dispatch(successScriptExecution([],"Empty response",{}));
                })
                .catch(err => dispatch(errorScriptExecution(err)));

    }
};

const clearScriptOutput = () => ({
    type: EXPDESIGN_CLEAR_SCRIPT_SUCCESS,
});

/**
 * Clear script output in store
 * @returns {function(*,*)} change state of Store
 */
export const clearScriptOutputAC = () => {
    return (dispatch,getState) => {
        dispatch(clearScriptOutput());
    }
};


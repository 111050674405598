import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'primereact/components/button/Button';
import {InputText} from 'primereact/components/inputtext/InputText';

class Login extends React.Component {

    constructor(props) {
        console.log("Login ... constructor");
        super(props);
        this.state = {
            email: '',
            password: ''
        };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

  render(){
    const { onClickAde, onRegister } = this.props;
    console.log('Login render');
    return(
        <div style={{marginTop:'10px'}}>
            <div style={{float:'left'}}>
                <div className="nav__text">Email</div>
                <div>
                <InputText
                    name="email"
                    type={'email'}
                    placeholder="john.doe@nasa.gov"
                    value={this.state.value}
                    onChange={this.handleChange}
                />
                </div>
            </div>
            <div style={{float:'left', paddingLeft:"1%"}}>
                <div className="nav__text">Password</div>
                <div>
                <input
                    type="password"
                    name="password"
                    value={this.state.value}
                    onChange={this.handleChange}
                    className="ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-filled"
                />
                </div>
                <div className="nav__text_opaque">Forgot Password?</div>

            </div>
            <div style={{float:'left',paddingLeft:"1%",marginTop:"16px"}}>
                <Button
                    label="Login"
                    onClick={e => {
                        e.preventDefault();
                        onClickAde(this.state)
                    }
                    }
                />
            </div>
        </div>
        );
    }
}

Login.propTypes = {
  loginStatus: PropTypes.string.isRequired,
  onClickAde: PropTypes.func.isRequired,
  onRegister :PropTypes.func.isRequired
};

export default Login;
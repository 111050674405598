import React from "react";
import {ListSwitchType} from "../../root/ListTypeSwitch";
import {withRouter} from 'react-router-dom'
import {Dialog} from "primereact/components/dialog/Dialog";
import {Button} from "primereact/components/button/Button";
import {Tooltip} from "primereact/components/tooltip/Tooltip";
import SelectionCriteria from "../../expDesign/component/SelectionCriteria";
import {ScrollPanel} from "primereact/components/scrollpanel/ScrollPanel";
import {SelectionCriteriaOutputText} from "./SelectionCriteriaOutputText";
import {Sidebar} from "primereact/components/sidebar/Sidebar";
import CohortOptionsPanel from "./CohortOptionsPanel";
import PropTypes from "prop-types";
import {
    EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA,
    EXPERIMENT_PROPERTY_NAME__INCLUSION_CRITERIA,
    EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA
} from "../../../Constants";

/** Stateful component responsible for presenting options for displaying project (new experiment button,
 * list displaying mode).
 * @param clbList
 * @param clbTab
 * @param activeTab
 * @param isList
 * @param cohorts
 * @param cohortsList
 * @returns {*}
 * @constructor
 */
class CohortSelectionMenu extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            cohortsDialogVisible:false,
            criteriaDialogVisible:false
        };
        this.handleButton = this.handleButton.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onDialogShow = this.onDialogShow.bind(this);
        this.onDialogHide = this.onDialogHide.bind(this);
        this.handleFilterButtonClick = this.handleFilterButtonClick.bind(this);
        this.onCohortsDialogHide = this.onCohortsDialogHide.bind(this);
        this.onCriteriaDialogHide = this.onCriteriaDialogHide.bind(this);
    }

    onCohortsDialogHide(){
        this.setState({cohortsDialogVisible:false});
    }

    onCriteriaDialogHide(){
        this.setState({criteriaDialogVisible:false});
    }

    /* invoking manually a Link of React Router */
    handleButton (){
        this.props.initExperiment();
    };

    handleFilterButtonClick (){

    }
    handleClick (){
        this.onDialogShow();
    };
    onDialogShow(){
        this.setState({cohortsDialogVisible:true});
    }
    onDialogHide(){
        this.setState({cohortsDialogVisible:false});
    }
    render(){
        const {experiment,visu,isList,listModeClb}=this.props;

        return(
            <React.Fragment>
            <div className="cohortFlexMenuBar" style={{justifyContent:"flex-start" ,marginBottom:'8px'}}>

                    <div style={{marginLeft:"16px", fontSize:'1em',    marginRight: "2px",
                        fontWeight: "600",     color: "#00D2D0"}} className="reg-headtitle">

                        SELECTION CRITERIA
                    </div>
                    <i id="filterMark" className="fa fa-filter iconBackgroundColor"
                       style={{fontSize: 'x-large',float: 'left'}}
                       onClick={()=>this.setState({criteriaDialogVisible:true})}
                    />

                    {/* TODO Update and uncomment
                    <SelectionCriteriaOutputText inc={experiment[EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA][EXPERIMENT_PROPERTY_NAME__INCLUSION_CRITERIA]}*/}
                                                 {/*exc={experiment[EXPERIMENT_PROPERTY_NAME__SELECTION_CRITERIA][EXPERIMENT_PROPERTY_NAME__EXCLUSION_CRITERIA]}*/}
                                                 {/*visu ={visu}/>*/}

                    <Tooltip for="#filterMark"
                             title="Select inclusion/exclusion criteria"
                             tooltipPosition="top"
                             tooltipEvent="hover"/>
                <Sidebar
                        visible={this.state.cohortsDialogVisible}
                        // modal={true}
                        fullScreen={true}
                        onHide={this.onDialogHide}
                        showCloseIcon={true}
                        // style={{backgroundColor: '#f0f4f7'}}
                >

                    <CohortOptionsPanel
                      {...this.props}  onCloseSidebar={()=>{this.setState({cohortsDialogVisible:false})}}/>

                    {/*<Button type="button" onClick={()=>{this.setState({cohortsDialogVisible:false})}}*/}
                            {/*label="Close" className="ui-button-success"/>*/}

                </Sidebar>
                <Dialog id="criteriaDialog" header={'Selection criteria'}
                        visible={this.state.criteriaDialogVisible}
                        modal={true}
                        onHide={this.onCriteriaDialogHide}
                        width="100%"
                        height="600px">
                    <ScrollPanel style={{width: '100%',height:'540px'}}>
                          <SelectionCriteria {...this.props} />
                    </ScrollPanel>
                </Dialog>
            </div>
                <div className="cohortFlexMenuBar" >
                    <Button icon="fa fa-plus" label="Select cohort" id="addCohort"
                            style={{float: 'right'}}
                            onClick={this.handleClick}/>
                    <ListSwitchType clb={listModeClb} isList={isList}/>
                </div>
            </React.Fragment>
        )}
};

CohortSelectionMenu.propTypes= {
    cohorts : PropTypes.array.isRequired, //all cohorts
    filteredCohorts:PropTypes.array.isRequired,
    // cohortList: PropTypes.array.isRequired,//all cohorts
    onSelect :  PropTypes.func.isRequired,//callback to select cohort
    selectCheckboxState : PropTypes.func.isRequired,// flag indicating whether all cohorts are selected
    handleChange : PropTypes.func.isRequired,//callback to select all cohorts
    isList : PropTypes.bool.isRequired,//flag indicating mode of displaying items (cards/list)
    listModeClb: PropTypes.func.isRequired//callback to change mode of displaying  items (used by ListSwitchType)
};

export default withRouter(CohortSelectionMenu);
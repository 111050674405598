import React from 'react'
import {ItemList} from "../../project/component/ItemList";
import CohortItemCardElement from "../../visualization/component/CohortItemCardElement";
import CohortSelectionMenu from "../../visualization/component/CohortSelectionMenu";
import CohortItemListElement from "../../visualization/component/CohortItemListElement";

import {
    COHORT_PROPERTY_NAME__COINVESTIGATORS, COHORT_PROPERTY_NAME__CREATION_DATE,
    COHORT_PROPERTY_NAME__NAME, COHORT_PROPERTY_NAME__PI, COHORT_PROPERTY_NAME__UUID, EXPERIMENT_PROPERTY_NAME__COHORTS
} from "../../../Constants";

export default class DataSelection extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            areCohortsInListMode:false
        };
        this.handleChange = this.handleChange.bind(this);
        this.onSelect = this.onSelect.bind(this);
    }

    /**
     * Checkbox selection handler.
     */
    handleChange() {
        let { cohorts,experiment,saveSelection } = this.props;
        experiment[EXPERIMENT_PROPERTY_NAME__COHORTS] = cohorts.map((study) => study.uuid);
        saveSelection(experiment);
    }

    /**
     * Selection of cohort. Callback passed to child components.
     * */
    onSelect(cohort){
        let {experiment,saveSelection} = this.props;
        let cohortSet = new Set(experiment[EXPERIMENT_PROPERTY_NAME__COHORTS]);
        if (cohortSet.has(cohort[COHORT_PROPERTY_NAME__UUID]))
            cohortSet.delete(cohort[COHORT_PROPERTY_NAME__UUID]);
        else cohortSet.add(cohort[COHORT_PROPERTY_NAME__UUID]);
        experiment[EXPERIMENT_PROPERTY_NAME__COHORTS] =[...cohortSet];  //conversion from Set to Array
        saveSelection(experiment);
    }

    render() {
        const {cohorts,experiment} = this.props;

        const selectCheckboxState = () => {
            return cohorts.length === experiment[EXPERIMENT_PROPERTY_NAME__COHORTS].length;
        };

        const model = cohorts.filter((study) => experiment[EXPERIMENT_PROPERTY_NAME__COHORTS].includes(study.uuid));

        console.log("DataSelection state", this.state);
        return (
            <div className="ui-g">
                <div className="ui-g-12">
                        <CohortSelectionMenu
                            {...this.props}
                            onSelect={this.onSelect}
                            selectCheckboxState = {selectCheckboxState}
                            handleChange = {this.handleChange}
                            isList={this.state.areCohortsInListMode}
                            listModeClb = {(mode) => this.setState({areCohortsInListMode:mode})}
                        />
                        <ItemList model={model}
                                  isList={this.state.areCohortsInListMode}
                                  classNames={["ui-g-4","ui-g-4","ui-g-4"]}
                                  headers={["Name","Creation date","Subjects"]}
                                  columnStyle="ui-g-3 ui-sm-12"
                                  properties={[
                                      COHORT_PROPERTY_NAME__NAME,
                                      COHORT_PROPERTY_NAME__CREATION_DATE,
                                      // COHORT_PROPERTY_NAME__PI,
                                      COHORT_PROPERTY_NAME__COINVESTIGATORS]}
                                  cardRenderer={<CohortItemCardElement />}
                                  listRenderer={<CohortItemListElement />}
                        />
                </div>
            </div>

        )
    }


}

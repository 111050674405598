import { 
	SESSION_REQUEST,
	SESSION_FAIL,
	SESSION_SUCCESS,
	SESSION_LIST_REQUEST,
	SESSION_LIST_FAIL,
	SESSION_LIST_SUCCESS,
} from '../action/actionType'

export const sessionReducer = (state = { sessionState: '', session: {} }, action) => {
	switch (action.type) {
		case SESSION_REQUEST:
			return Object.assign({},state, {
        		sessionState: 'requested'
            });
		case SESSION_FAIL:
			return ;
		case SESSION_SUCCESS:
			return Object.assign({}, state, {
				sessionState: 'success',
				session: action.session
			});
		default:
			return state;
	}
};


export const sessionListReducer = (state = { sessionsState: '', sessions: [] }, action) => {
	// console.log('Session reducer: ', action);
	switch (action.type) {
		case SESSION_LIST_REQUEST:
			return Object.assign({},state, {
        		sessionsState: 'requested'
            });
		case SESSION_LIST_FAIL:
			return Object.assign({},state, {
        		sessionsState: 'fail'
            });
		case SESSION_LIST_SUCCESS:
			return Object.assign({}, state, {
				sessionsState: 'success',
				sessions: action.sessions
			});
		default:
			return state;
	}
};


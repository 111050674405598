import axios from 'axios';

import {LOGIN_FAIL,  LOGIN_SUCCESS,REGISTER_REQUEST} from './actionType.jsx';
import {HOST_URL} from "../../../Constants";

//LOGIN PROCESS ***************************************************************
//FIXME Below is unused
// function requestLogin(creds) {
//     console.log('Creds inside request login', creds)
//     return {
//         type: LOGIN_REQUEST,
//         isFetching: true,
//         isAuthenticated: false,
//         credentials: creds
//     }
// }

function receiveLogin(creds) {
    return {
        type: LOGIN_SUCCESS,
        creds
    }
}

function errorLogin(message) {
    return {
        type: LOGIN_FAIL,
        message
    }
}
// FIXME Below is unused
// export const loginUser = creds => {
//     return {
//         type: 'LOGIN_REQUEST',
//         creds
//     }
// }

/*
 * Main action:
 * - dispatch the login request to the reducer
 * - get the token and credentials from the db
 * - dispatch the success and token to the reducer
 * OR
 * - dispatch the fail and error to the reducer
 *
 * @param {Object} creds - email and password
 * @return {Object} type, creds or err
 *
 */
export function login(creds) {
    // console.log('Executing login. Creds:', creds);
    return dispatch => {
        console.log('Before post by axios');
        axios.defaults.headers.post['Content-Type'] = 'application/json';
        axios.post(HOST_URL+'/user/login-with-password',
            creds)
            .then(response => {
                    // console.log('login res:', response);
                    // Get the token and it to the credentials
                    creds.token_bearer = response.headers.authorization;
                    // Get the roles
                    let roles = response.data.roles;
                    // Check and set the active role as contributor
                    if (roles.indexOf('contributor') > -1) {
                        creds.roles = roles;
                        creds.role_active = 'contributor';
                        creds.email = response.data.email;
                        creds.name = response.data.name;
                        creds.lastname = response.data.lastname;
                        creds.nickname = response.data.nickname;
                        creds.eduLevel = response.data.eduLevel;
                        creds.experience = response.data.experience;
                        creds.profession = response.data.profession;
                        creds.specialty = response.data.specialty;
                        dispatch(receiveLogin(creds))
                    } else {
                        dispatch(errorLogin('User does not have contributor role.'))
                    }
                }
            )
            .catch(error => {
                    console.log('login err:', error);
                    dispatch(errorLogin(error))
                }
            )
    }
}

/**
 * Request to redirect to register
 * @returns {type} just signal to change view, no data
 */
export function register() {
    console.log('Executing LoginAction::register');

        return {
            type: REGISTER_REQUEST
};

}
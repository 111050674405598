/**
 * Trimmer for String
 * Use: stringObject.trimEllip(25)
 * @param length
 * @returns {string}
 */
export const trimEllip = function (str,length) {
    return str.length > length ? str.substring(0, length) + " ..." : str;
};


/**
 * Simple uuid generator for dynamic lists.
 * @returns {string}
 */
export const getUniqueId = ()=> {return Math.random().toString(36).substring(2)
    + (new Date()).getTime().toString(36)};


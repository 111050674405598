import {
    ANNOTATION_IMAGE_FAIL, ANNOTATION_IMAGE_REQUESTED,
    ANNOTATION_IMAGE_SUCCESS,
    ANNOTATION_LIST_FAIL, ANNOTATION_LIST_REQUEST,
    ANNOTATION_LIST_SUCCESS, ANNOTATION_LIST_UPDATE, CLEAR_ANNOTATOR_STATE, LABELMAP_IMAGE_FAIL,
    LABELMAP_IMAGE_REQUESTED,
    LABELMAP_IMAGE_SUCCESS,
    UPDATE_IMAGE_OPTIONS,
    UPDATE_SINGLE_VIEWER_PROPERTY,
    UPDATE_SINGLE_VIEWER_STATE, UPDATE_VIEWERS_STATE
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";


export const annotationsReducer = (state = {
    annotationsState: '',
    annotations: {columns:[],data:[]},
    overlays:[], // overlays requested or loaded
    images:[],// images requested or loaded
    imageOptions: [], //options for image loading
    viewersState:[]

}, action) => {
    switch (action.type) {
        case ANNOTATION_LIST_REQUEST:
            return Object.assign({}, state, {
                annotationsState: REQUEST_STATUS_REQUESTED,
            });
        case ANNOTATION_LIST_SUCCESS:
            return Object.assign({}, state, {
                annotationsState: REQUEST_STATUS_SUCCESS,
                annotations: action.annotations
            });

        case ANNOTATION_LIST_FAIL:
            return Object.assign({}, state, {
                annotationsState: REQUEST_STATUS_FAIL,
            });
        case ANNOTATION_LIST_UPDATE: {
            console.log("Annotations state>>>", action.annotations);
            return Object.assign({}, state, {
                annotationsState: REQUEST_STATUS_SUCCESS,
                annotations: action.annotations
            });
        }
        case ANNOTATION_IMAGE_SUCCESS:{
            let stateCopy = state.images.slice(0);
            let reqIndex = stateCopy.findIndex((el)=>el['uuid']===action.imageId);
            if (reqIndex===-1)
                return state;
            let requestedImage = stateCopy[reqIndex];
            requestedImage['state'] =  REQUEST_STATUS_SUCCESS;
            requestedImage['data'] =  action.imageData;
            let temp = [
                ...state.images.slice(0, reqIndex),
                Object.assign({},requestedImage),
                ...state.images.slice(reqIndex + 1)
            ];
            return Object.assign({}, state, {
                images:temp
            });
        }
        case ANNOTATION_IMAGE_FAIL:{
            let stateCopy = state.images.slice(0);
            let reqIndex = stateCopy.findIndex((el)=>el['uuid']===action.imageId);
            if (reqIndex===-1)
                return state;
            let requestedImage = stateCopy[reqIndex];
            requestedImage['state'] =  REQUEST_STATUS_FAIL;
            requestedImage['data'] =  action.imageData;
            let temp = [
                ...state.images.slice(0, reqIndex),
                Object.assign({},requestedImage),
                ...state.images.slice(reqIndex + 1)
            ];
            return Object.assign({}, state, {
                images:temp
            });
        }
        case ANNOTATION_IMAGE_REQUESTED:{
            let stateCopy = state.images.slice(0);
            let isImageAlreadyInArray = stateCopy.find((el)=>el['uuid']===action.imageId);
            if (isImageAlreadyInArray!=null)
                return state;
            let newImage = {};
            newImage['state'] =  REQUEST_STATUS_REQUESTED;
            newImage['uuid'] =  action.imageId;
            stateCopy.push(newImage);
            return Object.assign({}, state, {
                images:stateCopy
            });
        }
        case LABELMAP_IMAGE_FAIL:{
            let stateCopy = state.overlays.slice(0);
            let reqIndex = stateCopy.findIndex((el)=>el['uuid']===action.imageId);
            if (reqIndex===-1)
                return state;
            let requestedImage = stateCopy[reqIndex];
            requestedImage['state'] =  REQUEST_STATUS_FAIL;
            requestedImage['data'] =  action.imageData;
            let temp = [
                ...state.overlays.slice(0, reqIndex),
                Object.assign({},requestedImage),
                ...state.overlays.slice(reqIndex + 1)
            ];
            return Object.assign({}, state, {
                overlays:temp
            });
        }


        case LABELMAP_IMAGE_SUCCESS:{
            let stateCopy = state.overlays.slice(0);
            let reqIndex = stateCopy.findIndex((el)=>el['uuid']===action.imageId);
            if (reqIndex===-1)
                return state;
            let requestedImage = stateCopy[reqIndex];
            requestedImage['state'] =  REQUEST_STATUS_SUCCESS;
            requestedImage['data'] =  action.imageData;
            let temp = [
                ...state.overlays.slice(0, reqIndex),
                Object.assign({},requestedImage),
                ...state.overlays.slice(reqIndex + 1)
            ];
            return Object.assign({}, state, {
                overlays:temp
            });
        }

        case LABELMAP_IMAGE_REQUESTED:{
            let stateCopy = state.overlays.slice(0);
            let isImageAlreadyInArray = stateCopy.find((el)=>el['uuid']===action.imageId);
            if (isImageAlreadyInArray!=null)
                return state;
            let newImage = {};
            newImage['state'] =  REQUEST_STATUS_REQUESTED;
            newImage['uuid'] =  action.imageId;
            stateCopy.push(newImage);
            return Object.assign({}, state, {
                overlays:stateCopy
            });
        }
        case CLEAR_ANNOTATOR_STATE:{
            return Object.assign({}, state, {
                annotationsState: '',
                annotations: {columns:[],data:[]},
                overlays:[], // overlays requested or loaded
                images:[],// images requested or loaded
                imageOptions: [], //options for image loading
                viewersState:[]
            });
        }
        case UPDATE_SINGLE_VIEWER_STATE:{
            let temp = [
                ...state.viewersState.slice(0, action.index),
                Object.assign({},action.viewer),
                ...state.viewersState.slice(action.index + 1)
            ];
            return Object.assign({}, state, {
                viewersState:temp
            });
        }
        case UPDATE_VIEWERS_STATE:{
            let result = [];
            action.viewers.forEach((el)=>result.push( Object.assign({},el)))
            return Object.assign({}, state, {
                viewersState:result
            });
        }
        case UPDATE_SINGLE_VIEWER_PROPERTY:{
           let tempArray = state.viewersState.slice();
           let tempRow = Object.assign({},tempArray[action.index]);
           tempRow[action.property]=action.value;
           tempArray[action.index]=tempRow;
           // temp[action.index][action.property]=action.value;
           // let temp2 = [
           //      ...state.viewersState.slice(0, action.index),
           //      Object.assign({},temp[action.index]),
           //      ...state.viewersState.slice(action.index + 1)
           //  ];
           return Object.assign({}, state, {
                viewersState:tempArray
            });
        }
        case UPDATE_IMAGE_OPTIONS:{
            return Object.assign({}, state, {
                imageOptions:action.options.slice(0)
            });
        }

        default:
            return state;
    }
};


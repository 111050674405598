import {getProject} from "../action/ProjectAction";
import {connect} from "react-redux";
import ProjectDetails from "../component/ProjectDetails";
import {deleteExperiment, getExperimentList, setSorter, setVisibilityFilter} from "../action/ExperimentAction";
import {getSorter, getVisibilityFilter, getVisibleExperiments} from "../selectors/ExperimentsSelectors";
import {initNewExperimentActionCreator} from "../../expDesign/action/ExpDesignAction";



const mapStateToProps = state => {
    return {
        project: state.project.project.project,
        experiments: getVisibleExperiments(state),
        experimentsState: state.project.experiments.experimentsState,
        filter: getVisibilityFilter(state),
        sorter: getSorter(state)
    }
};

/**
 *
 * @param dispatch dispatcher
 * @param ownProps - props generated by component calling the Container and passed by react-redux.
 * Since ContainerProjectDetails is called by Router, props are wrapped in match variable.
 * @returns {{getProjectItem: function()}} ProjectAction getProject call result
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getProjectItem: () => {
            dispatch(getProject(ownProps.match.params.projectid))
        },
        getExperimentList:()=>{
            dispatch(getExperimentList(ownProps.match.params.projectid))
        },
        initExperiment:()=>{
            dispatch(initNewExperimentActionCreator(ownProps.match.params.projectid));
        },
        deleteExperiment:(experiment)=>{
            dispatch(deleteExperiment(experiment));
        },
        onFilterChange: (id) => {
            dispatch(setVisibilityFilter(id))
        },
        onSorterChange: (id)=>{
            dispatch(setSorter(id))
        }
    }
};


const projectContainerCreator = connect(mapStateToProps, mapDispatchToProps);

/** The container can bind many components if they use the same reducer.*/
export const ContainerProjectDetails = projectContainerCreator(ProjectDetails);


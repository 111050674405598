import React from 'react';
import YouTube from 'react-youtube';
import {ContainerTabletGameExperiment} from "../../expDesign/containers/ContainerTabletGameExperiment";
// this will be moved to Constants (after we know the final version of Slides and Presentation schema)
export const SLIDE_PROPERTY_NAME__SLIDE_TYPE= "slideType";
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__IMAGE = 'IMAGE';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__VIDEO = 'VIDEO';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__WEBSITE = 'WEBSITE';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT = 'EXPERIMENT';
export const SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES = 'slideProperties';

class playSlidesManual extends React.Component{

    constructor() {
        super();
        this.state = {
            slideIndex: 0,
        };
        ["plusSlides","currentSlide","keyListener"].forEach(name => {this[name] = this[name].bind(this);});
    };

    plusSlides(n) {
        let newValue = this.state.slideIndex + n;
        if ((this.state.slideIndex + n)>=this.props.slides.length) newValue=0;
        if ((this.state.slideIndex + n)<0) newValue=this.props.slides.length-1;
        this.setState({slideIndex: newValue});
    }

    currentSlide(n) {
        this.setState({slideIndex: n});
    }

    keyListener(event) {
        if (event.code === "Escape") {
            this.props.history.push("/livePresenter");
            return;
        }
        if (event.code === "ArrowDown" || event.code === "PageDown") {
            this.plusSlides(1);
            return;
        }
        if (event.code === "ArrowUp" || event.code === "PageUp") {
            this.plusSlides(-1);
            return;
        }
        if (event.code === "ArrowLeft" || event.code === "Home") {
            this.currentSlide(0);
            return;
        }
        if (event.code === "ArrowRight" || event.code === "End") {
            this.currentSlide(0);
            this.plusSlides(-1);
        }
    }

    componentDidMount() {
        const script = document.createElement("script");
        document.body.addEventListener("keydown", this.keyListener, false);
        script.src = "https://unpkg.com/x-frame-bypass";
        document.body.appendChild(script);
        const {getSlides} = this.props;
        getSlides(this.props.match.params['id']);
    }
    /** Needed to remove listeners. */
    componentWillUnmount() {
        document.body.removeEventListener("keydown", this.keyListener, false);
    }

    render() {

        const playerOpts = {
            height: '100%',
            width: '100%',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                rel:0,
                autoplay: 1,
                mute:1
            }
        };

        const items = (this.props.slides != null && this.props.slides.length > 0)
         ? this.props.slides.sort((a,b) => {return parseInt(a.orderNumber) - parseInt(b.orderNumber)}).map((item,index) => {
            const title_style = {position: 'fixed', left: "60px", top:"2px", color: "white", zIndex:"1001"};
            const slide_style = (index===this.state.slideIndex)?
                {width: '100%', height: '100%', position: 'relative',  display:"block"}:
                {width: '100%', height: '100%', position: 'relative',  display:"none"};

            switch (item[SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase()) {

                case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__WEBSITE:
                return (<React.Fragment key = {item.orderNumber}>
                    <div style={slide_style} className="slide_div fade" >
                        <h2 style={title_style}>{item.title}</h2>
                        <iframe is="x-frame-bypass" src={item.slideProperties.url} height="100%" width="100%"/>
                    </div>
                </React.Fragment>);

                case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__VIDEO:
                    {
                    let url = new URL(item[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['url']); // i am parsing url here, since i need 1 param only
                    return (<React.Fragment key={item.orderNumber}>
                        <div style={slide_style} className="slide_div fade">
                            <h2 style={title_style}>{item.title}</h2>
                            <YouTube videoId={url.searchParams.get('v')} opts={playerOpts} height="100%" width="100%"/>
                        </div>
                    </React.Fragment>);
                    }
                case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT:
                return (<React.Fragment key = {item.orderNumber}>
                    <div style={slide_style} className="slide_div fade">
                        <h2 style={title_style}>{item.title}</h2>
                        <ContainerTabletGameExperiment slideProperties={item['slideProperties']} active={index===this.state.slideIndex}/>
                    </div>
                </React.Fragment>);

                case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__IMAGE:
                return (<React.Fragment key = {item.orderNumber}>
                    <div style={slide_style} className="slide_div fade">
                        <h2 style={title_style}>{item.title}</h2>
                        <img src={item[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['url']} height="100%" width="100%"/>
                        {/* <p>{item.desc}</p> */}
                    </div>
                </React.Fragment>)
            }
        }):null;

        // const slide_manualdots = (this.props.slides != null && this.props.slides.length > 0)
        // ? this.props.slides.map((item,index) =>
        //     {
        //      const whichClass = (index===this.state.slideIndex)?"manualdot active-dot":"manualdot";
        //       return (
        //     <React.Fragment key = {item.title}>
        //         <span className = {whichClass} onClick = {()=>this.currentSlide(index)}/>
        //     </React.Fragment>
        // )}):null;

        console.log('Play slides... render');
        return(
            <React.Fragment>
                {this.props.slides && this.props.slides.length>0 &&
                <div className = "slideshow-div">
                    {items}
                <a className="prev" onClick={()=>this.plusSlides(-1)}>&#10094;</a>
                <a className="next" onClick={()=>this.plusSlides(1)}>&#10095;</a>
                {/*<div style={{textAlign: "center", position: "absolute", top: "96%", width: "100%", zIndex:"1001"}}>*/}
                    {/*{slide_manualdots}*/}
                {/*</div>*/}
            </div>}
            </React.Fragment>
        )
    }
};

export default playSlidesManual;
import React from "react";

/**
 * Dumb container-component for Icon Buttons.
 * @see  Statistical Power Component, Model Evaluation component etc.
 * @returns {*}
 * @constructor
 */

export class IconButtonComponent extends React.Component {

render() {
    const {iconClassName,onClick} = this.props;
    const width = (this.props.width!=null )?this.props.width:'140px';
    const height = (this.props.height!=null )?this.props.height:'120px';
    const color = (this.props.color!=null)?this.props.color:null;


    return  <div className="rectangle" style={{width: width, height: height, display: 'block', float:'right'}}
                 onClick={onClick}>
        <div className={iconClassName}/>
               {this.props.children}
        {color &&
            <span className="warningDot" style={{backgroundColor:color}}/>}
            </div>;
    }
}
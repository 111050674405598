import React from 'react'
import {Route, withRouter} from 'react-router'
import {ContainerProjectList} from "./containers/ContainerProjectList";
import {ContainerProjectDetails} from "./containers/ContainerProject";
import ContainerExpDesign from "../expDesign/containers/ContainerExpDesign";


class Project extends React.Component {

    render() {
        const {match} = this.props;
        console.log("project state", this.state);
        return (
            <div>
                <Route exact path={`${match.path}/`} component={ContainerProjectList}/>
                <Route exact path={`${match.path}/:projectid`} component={ContainerProjectDetails}/>
                <Route exact path={`${match.path}/:projectid/expdesign`} component={ContainerExpDesign}/>
                <Route exact path={`${match.path}/:projectid/experiment/:experimentid`} component={ContainerExpDesign}/>
            </div>

        )
    }

}

export default withRouter(Project)
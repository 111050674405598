import {
    PRESENTATION_LIST_FAIL, PRESENTATION_LIST_REQUEST, PRESENTATION_LIST_SUCCESS, SLIDE_LIST_FAIL, 
    SLIDE_LIST_REQUEST, SLIDE_LIST_SUCCESS, SLIDE_CREATE
} from '../action/actionType'
import {combineReducers} from "redux";


const presentationListReducer = (state = {presentationsState: '', presentation_list: []}, action) => {
    switch (action.type) {
        case PRESENTATION_LIST_REQUEST:
            return Object.assign({}, state, {
                presentationsState: 'requested',
            });
        case PRESENTATION_LIST_SUCCESS:
            return Object.assign({}, state, {
                presentationsState: 'success',
                presentation_list: action.presentations
            });

        case PRESENTATION_LIST_FAIL:
            return Object.assign({}, state, {
                presentationsState: action.err,
            });

        default:
            return state;
    }
};


const slideListReducer = (state = {slidesState: '', slide_list: []}, action) => {
    switch (action.type) {
        case SLIDE_LIST_REQUEST:
            return Object.assign({}, state, {
                slidesState: 'requested',
            });
        case SLIDE_LIST_SUCCESS:
        {   let slides = action.slides.sort((a,b) => {return parseInt(a.orderNumber) - parseInt(b.orderNumber)})
            return Object.assign({}, state, {
                slidesState: 'success',
                slide_list: slides
            });
        }

        case SLIDE_LIST_FAIL:
            return Object.assign({}, state, {
                slidesState: action.err,
            });

        case SLIDE_CREATE:
            return Object.assign({}, state, {
                slide_list: action.slides
            })

        default:
            return state;
    }
};


export const PresentationReducer = combineReducers({
    presentations: presentationListReducer,
    slides: slideListReducer
});
import {connect} from "react-redux";
import {calculateSampleSizeActionCreator, setCalculatorParameters} from "../action/CalculatorAction";
import GLRCalculator from "../component/GLRCalculator";


const mapStateToProps = state => {
    return{
        experiment: state.expDesign.experiment,
        calculator: state.expDesign.calculator,
    }
};

const mapDispatchToProps = dispatch => {
    return{
        calculateSampleSizeActionCreator:   (parameters) =>{dispatch(calculateSampleSizeActionCreator(parameters))},
        setCalculatorParameters: (parameters)=>{dispatch(setCalculatorParameters(parameters))}
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps)(GLRCalculator)
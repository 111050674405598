import {
    TASK_LIST_FAIL,
    TASK_LIST_REQUEST, TASK_LIST_SUCCESS
} from "./actionType";

const mockTasks= [{
    "name": "Freesurfer recon-all step 1 & 2",
    "version": "6.0.1",
    "description": "Performs the first two steps of recon-all with one single T1 image",
    "privacy": "PUBLIC",
    "process": {
        "type": "auto",
        "execution": {
            "executor": "bosh",
            "descriptorId": "d9281ecaddc881bc3179292150023935"
        }
    },
    "inputs": {
        "t1w": {
            "type": "imageFile",
            "fileType": "nifti",
            "typeImageFile": "T1w",
            "idInDescriptor": "t1w"
        }

    },

    "outputs": {

        "orig": {

            "type": "imageFile",

            "fileType": "nifti",

            "typeImageFile": "T1w",

            "idInDescriptor": "orig"

        },

        "nu": {

            "type": "imageFile",

            "fileType": "nifti",

            "typeImageFile": "T1w",

            "idInDescriptor": "nu"

        },

        "T1": {

            "type": "imageFile",

            "fileType": "nifti",

            "typeImageFile": "T1w",

            "idInDescriptor": "T1"

        },

        "brainmask": {

            "type": "imageFile",

            "fileType": "nifti",

            "typeImageFile": "T1w",

            "idInDescriptor": "brainmask"

        },

        "aseg": {

            "type": "imageFile",

            "fileType": "nifti",

            "typeImageFile": "DiscreteLabelMap",

            "idInDescriptor": "aseg"

        }

    },

    "creationDate": "Thu Nov 15 2018 09:46:07 GMT-0500 (EST)",

    "uuid": "1ce1d8f7e9ded99ad2014467cf00575b"

},
    {

        "name": "Multilabel Volume Measurement",

        "version": "0.2",

        "description": "Measure the volume of ROIs",

        "privacy": "PUBLIC",

        "process": {

            "type": "auto",

            "execution": {

                "executor": "bosh",

                "descriptorId": "1ce1d8f7e9ded99ad2014467cf00280a"

            }

        },

        "inputs": {

            "labelmap": {

                "type": "imageFile",

                "fileType": "nifti",

                "typeImageFile": "DiscreteLabelMap",

                "idInDescriptor": "labelmap"

            },

            "lookuptable": {

                "type": "lookupTable",

                "idInDescriptor": "lookuptable"

            }

        },

        "outputs": {

            "measurement": {

                "type": "imagingVariableGroup",

                "typeImagingVariable": "Volume",

                "reference": {

                    "discreteLabelMapId": "labelmap",

                    "subjectId": "labelmap"

                },

                "idInDescriptor": "measurement"

            }

        },

        "creationDate": "Fri Nov 16 2018 08:34:44 GMT-0500 (EST)",

        "uuid": "1ce1d8f7e9ded99ad2014467cf0103aa"

    }

];
import axios from 'axios'
import store from "../../Store";
import {HOST_URL} from "../../../Constants";


const requestTaskList = (workflowId) => ({
    type: TASK_LIST_REQUEST,
    workflowId
});

const errorTaskList = (err,workflowId) => ({
    type: TASK_LIST_FAIL,
    err,
    workflowId
});

const successTaskList = (tasks,workflowId) => ({
    type: TASK_LIST_SUCCESS,
    tasks,
    workflowId
});


// TODOOOOOO CONNECT TO DB and Add dispatching request and error handling
// Get user list (local version)
/**
 * ActionCreator for getting Tasks.
 * @param workflowId
 * @returns {function(*)}
 */
export const getTaskList = (workflowId) => {
    console.log('tasks for workflowId',mockTasks);
    return dispatch => {
        // axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        dispatch(requestTaskList(workflowId));
        // dispatch(successTaskList(mockTasks,workflowId));

         axios.get(HOST_URL + '/workflow/'+workflowId+'/workflowtask')
            .then(response => {
                console.log('TaskAction.js :: getTaskList :: response ::', response)
                if (response.status !== 200) {
                    dispatch(errorTaskList(error,workflowId))
                } else {
                    dispatch(successTaskList(response.data,workflowId));
                }
            }).catch(error => {
            console.log('Task Action.js :: getTaskList :: error ::', error);
            dispatch(errorTaskList(error,workflowId))
        });

    }
};


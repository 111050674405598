import React from "react";

export  const ListSwitchType = ({clb, isList}) => {
    console.log("List Mode ",isList);
    return(
            <div className="listTypeSwitchElement">
                <i className="fa fa-th-large listTypeSwitchIcon" style={{borderRight: "solid 1px"}}
                   onClick={()=>clb(false)}/>
                <i className="fa fa-th-list listTypeSwitchIcon" onClick={()=>clb(true)}/>
            </div>
    )
};


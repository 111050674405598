import React from "react";

/**
 * Dumb component with template for Who Tab (first page).
 * @returns {*}
 * @constructor
 */
export const WhoTab = () => {

    return (
        <div >
            <h3>Who can use SPINE?</h3>
            <img style={{
                display: 'block',
                marginLeft: 'auto',
                marginRight: 'auto',
                maxWidth: '200px',
                maxHeight: '200px'
            }} src="https://spinevirtuallab.org/public/img/wheel.png"/>
        </div>);
};
export const modelMockList = [
    {   "uuid":"GLRUSER",
        "name": "General Linear Model",
        "spineType": "USER_DEFINED",
        "author": "Alfredo Morales Pinzon",
        "status":"ready",
        "variables": {
            "Y": {
                "name": "Y",
                "isList": false,
                "variableTypeInModel": "OUTCOME",
                "type":"Number",
                "statisticalType":"CONTINUOUS"
            },
            "X": {
                "name": "X",
                "isList": true,
                "variableTypeInModel": "EXPLANATORY",
                "statisticalType": ["CONTINUOUS"]
            }
        },
        "formula":"Y=\\sum_{i} X_{i}",
        "scriptId": "id of the author script in SPINE"
    },
    {
        "uuid":"GLR",
        "name": "General Linear Model",
        "spineType": "SPINE",
        "status":"ready",
        "variables": {
            "Y": {
                "name": "Y",
                "isList": false,
                "variableTypeInModel": "OUTCOME",
                "type":"Number",
                "statisticalType":"CONTINUOUS"
            },
            "X": {
                "name": "X",
                "isList": true,
                "variableTypeInModel": "EXPLANATORY",
                "statisticalType": ["CONTINUOUS"]
            }
        },
        "formula":"Y=\\sum_{i} X_{i}",
        "scriptId": "id of the script in SPINE"
    },
    {
        "uuid":"SLR",
        "name": "Simple Linear Regression",
        "spineType": "SPINE",
        "status":"ready",
        "variables": {
            "Y": {
                "name": "Y",
                "isList": false,
                "variableTypeInModel": "OUTCOME",
                "type":"Number",
                "statisticalType":"CONTINUOUS"
            },
            "X": {
                "name": "X",
                "isList": true,
                "variableTypeInModel": "EXPLANATORY",
                "statisticalType": ["CONTINUOUS"]
            }
        },
        "formula":"Y=\\sum_{i} X_{i}",
        "scriptId": "id of the script in SPINE"
    },
    {   "uuid":"GEE",
        "name": "Generalized Estimation Equation",
        "spineType": "SPINE",
        "status":"InReview",
        "variables": {
            "Y": {
                "name": "Y",
                "isList": false,
                "variableTypeInModel": "OUTCOME",
                "type":"Number",
                "statisticalType":"CONTINUOUS"
            },
            "X": {
                "name": "X",
                "isList": true,
                "variableTypeInModel": "EXPLANATORY",
                "statisticalType": ["CONTINUOUS"]
            }
        },
        "formula":"Y=\\sum_{i} X_{i}",
        "scriptId": "id of the script in SPINE"
    },
      {   "uuid":"LME",
        "name": "Linear Mixed Effects Model",
        "spineType": "SPINE",
        "status":"InReview",
        "variables": {
            "Y": {
                "name": "Y",
                "isList": false,
                "variableTypeInModel": "OUTCOME",
                "type":"Number",
                "statisticalType":"CONTINUOUS"
            },
            "X": {
                "name": "X",
                "isList": true,
                "variableTypeInModel": "EXPLANATORY",
                "statisticalType": ["CONTINUOUS"]
            }
        },
        "formula":"Y=\\sum_{i} X_{i}",
        "scriptId": "id of the script in SPINE"
    },

    {   "uuid":"NLME",
        "name": "NonLinear Mixed Effects Model",
        "spineType": "SPINE",
        "status":"InReview",
        "variables": {
            "Y": {
                "name": "Y",
                "isList": false,
                "variableTypeInModel": "OUTCOME",
                "type":"Number",
                "statisticalType":"CONTINUOUS"
            },
            "X": {
                "name": "X",
                "isList": true,
                "variableTypeInModel": "EXPLANATORY",
                "statisticalType": ["CONTINUOUS"]
            }
        },
        "formula":"Y=\\sum_{i} X_{i}",
        "scriptId": "id of the script in SPINE"

    },
    {   "uuid":"GEE",
        "name": "Generalized Estimation Equation",
        "spineType": "USER_DEFINED",
        "author": "Miklos Palotai",
        "status":"InReview",
        "variables": {
            "Y": {
                "name": "Y",
                "isList": false,
                "variableTypeInModel": "OUTCOME",
                "type":"Number",
                "statisticalType":"CONTINUOUS"
            },
            "X": {
                "name": "X",
                "isList": true,
                "variableTypeInModel": "EXPLANATORY",
                "statisticalType": ["CONTINUOUS"]
            }
        },
        "formula":"Y=\\sum_{i} X_{i}",
        "scriptId": "id of the script in SPINE"
    },

];
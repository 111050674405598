/**
 * Converter from workflows to graph nodes and edges.
 * @link
 * @param workflow
 * @constructor
 */
export const  WorkflowToGraphAdapter = (workflow)=>{

    let nodes=[];
    let counter=1;

    const convertInput = (el,index)=>{nodes.push({title:el,id:counter++,type:"Input",data:workflow.inputs[el]})};
    const convertTask = (el,index)=>{nodes.push({title:el,id:counter++,type:"Task",data:workflow.tasks[el]})};
    const convertOutput = (el,index)=>{nodes.push({title:el,id:counter++,type:"Input",data:workflow.outputs[el]})};

    Object.keys(workflow.inputs).forEach(convertInput);

    if (workflow['type']!=null && workflow['type']==="workflowSPINE")
        Object.keys(workflow.tasks).forEach(convertTask);
    Object.keys(workflow.outputs).forEach(convertOutput);

    let edges=[];
    let counterEdges=1;


    for (let i =0;i<nodes.length-1;i++){
        edges.push({
            "source": i+1,
            "target": i+2,
            "type": "EmptyEdge"
        });
    }

    return {nodes,edges};
};


/**
 * Converter from workflows to graph nodes and edges.
 * @link
 * @param workflow
 * @deprecated
 * @constructor
 */
export const  WorkflowToGraphAdapter_Old = (workflow)=>{

   let nodes=[];
   let counter=1;

   const convertInput = (el,index)=>{nodes.push({title:el,id:counter++,type:"Input"})};
   const convertTask = (el,index)=>{nodes.push({title:el.name,id:counter++,type:"Task"})};
   const convertOutput = (el,index)=>{nodes.push({title:el,id:counter++,type:"Output"})};

   Object.keys(workflow.inputs).forEach(convertInput);
   workflow.tasks.forEach(convertTask);
   Object.keys(workflow.inputs).forEach(convertOutput);


   let edges=[];
   let counterEdges=1;

   for (let i =0;i<nodes.length-1;i++){
       edges.push({
           "source": i+1,
           "target": i+2,
           "type": "EmptyEdge"
       });
   }

   return {nodes,edges};
};

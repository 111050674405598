import React from 'react'
import PropTypes from 'prop-types';
import AceEditor from 'react-ace';
import 'brace/mode/r';
import 'brace/theme/github';
import {Button} from "primereact/components/button/Button";


const placeholder = "" +
  "#Please refer to data with data prefix, eg. data$x1 \n" +
    "#or lm(x1~x2,data=data)\n" +
    "# x<-c(1,2,3,4,5,9,11,12,90)\n" +
    "# y<-c(12,22,13,43,15,92,121,112,190)\n" +
    "# summary(lm(y~x))\n" +
    "# confint(lm(y~x))\n" +
    "# plot(y)\n" +
    "# print(y)\n" +
    "# print(y)\n" +
    "# print(y)\n" +
    "# print(y)\n" +
    "# print(y)\n" +
    "# print(y)\n" +
    "# summary(lm(x~y))"


/**
 * Temporarily used as testing page for various components.
 *
 */

export class ScriptPanel extends React.Component {

    constructor(){
        super();
        this.state={
            scriptContent:placeholder,
            plotIndex:0
        };
        ["executeScript","changePlotIndex","clearState"]
            .forEach(name => {
                this[name] = this[name].bind(this);
            }); //Binding methods
    }

    executeScript(){
        this.setState({plotIndex:0});
        this.props.executeScript(this.state.scriptContent,null);
    }
    changePlotIndex(step){
        let t = this.state.plotIndex + step;
        let lastIndex =this.props.plots.length-1;
        t=(t<0)?0:t;
        t=(t>lastIndex)?lastIndex:t;
        this.setState({plotIndex:t});
    }
    clearState(){
        this.setState({plotIndex:0});
        this.props.clearOutput();
    }


    render() {
        const{clearOutput,plots}=this.props;
        const{plotIndex}=this.state;
        console.log("News ...render");
        let disabledLeft = (plotIndex===0)?"ui-state-disabled":"";
        let disabledRight = (plots!=null && plotIndex===(plots.length-1))?"ui-state-disabled":"";

        return (
            <div  style={{margin: '16px'}}>
                <div className="ui-g-5">
                    <AceEditor
                        mode="r"
                        theme="github"
                        onChange={(value)=>{this.setState({scriptContent:value})}}
                        name="EDITOR"
                        editorProps={{$blockScrolling: true}}
                        highlightActiveLine={true}
                        value={this.state.scriptContent}
                    />
                </div>
                <div className="ui-g-2">
                    <Button icon="fa fa-play-circle-o " label={"Run"} onClick={this.executeScript} />
                    <Button icon="fa fa-trash" label={"Clear"} onClick={()=>this.clearState()}/>
                </div>
                <div className="ui-g-5">
                    <AceEditor
                        mode="r"
                        theme="github"
                        onChange={(value)=>{console.log('change',value);}}
                        name="OUTPUT"
                        readOnly={true}
                        editorProps={{$blockScrolling: true}}
                        highlightActiveLine={false}
                        value={this.props.stdOut}
                        showGutter={false}
                        height="250px"
                    />
                    {
                        this.props.plots != null && this.props.plots.length > 0 &&
                            <div style={{marginTop:"5px"}}>
                                {this.props.plots.length > 1 &&
                                <div>
                                    <Button icon="fa fa-arrow-left" onClick={()=>{this.changePlotIndex(-1)}} className={disabledLeft}/>
                                    <Button icon="fa fa-arrow-right" onClick={()=>{this.changePlotIndex(1)}}  className={disabledRight}/>
                                </div>
                                }
                                   <img src={`${this.props.plots[this.state.plotIndex]}`}/>
                            </div>
                    }



                </div>
            </div>
        )
    }
}

ScriptPanel.propTypes = {
    clearOutput: PropTypes.func,
    executeScript:PropTypes.func
};

import React from "react";
import PropTypes from "prop-types";
import Link from "react-router-dom/es/Link";
import {withRouter} from "react-router";

/**"Dumb" renderer for "List" mode for Workflows.
 *
 * @param item - mandatory component if used with ItemList container
 * @returns {*}
 * @constructor
 */
const WorkflowItemListElement = ({item,history}) => {


    return (
        <div className="experimentListElement"
             onClick={()=>{}}>
            <div className="ui-g-6" >
                {item.name}
            </div>
            <div className="ui-g-6" >{item.description}</div>

        </div>
    )
};
export default withRouter(WorkflowItemListElement);

WorkflowItemListElement.propTypes = {
    item: PropTypes.object.isRequired
};


import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";
import {
    EXPDESIGN_CALCULATE_SS_FAIL, EXPDESIGN_CALCULATE_SS_REQUEST,
    EXPDESIGN_CALCULATE_SS_SUCCESS, EXPDESIGN_PLOT_SS_FAIL, EXPDESIGN_PLOT_SS_REQUEST, EXPDESIGN_PLOT_SS_SUCCESS,
    EXPDESIGN_SET_CALCULATOR_PARAMS
} from "../action/actionType";
import {POWER_CALCULATOR} from "../models/PowerCalculator";


export const calculatorReducer = (state = { model:"GLR",ss:0, parameters:{"data":{"u":1,"f2":POWER_CALCULATOR.GLR.ARGS.F2.DEFAULT,
            "sig.level":POWER_CALCULATOR.GLR.ARGS.SIG_LEVEL.DEFAULT,"power":POWER_CALCULATOR.GLR.ARGS.POWER.DEFAULT}
            ,"f":POWER_CALCULATOR.GLR.NAME}, calculationState:"", plotState:"", plot:"" }, action)=> {
    console.log("calculatorReducer", action);
    switch (action.type) {
        case EXPDESIGN_CALCULATE_SS_REQUEST:
            return Object.assign({},state, {
                calculationState: REQUEST_STATUS_REQUESTED
            });
        case EXPDESIGN_CALCULATE_SS_FAIL:
            return Object.assign({},state, {
                calculationState: REQUEST_STATUS_FAIL
            });
        case EXPDESIGN_CALCULATE_SS_SUCCESS:
            return Object.assign({},state, {
                calculationState: REQUEST_STATUS_SUCCESS,
                ss:action.ss
            });

        case EXPDESIGN_PLOT_SS_REQUEST:
            return Object.assign({},state, {
                plotState: REQUEST_STATUS_REQUESTED
            });
        case EXPDESIGN_PLOT_SS_FAIL:
            return Object.assign({},state, {
                plotState: REQUEST_STATUS_FAIL
            });
        case EXPDESIGN_PLOT_SS_SUCCESS:
            return Object.assign({},state, {
                plotState: REQUEST_STATUS_SUCCESS,
                plot:action.plot
            });

        case EXPDESIGN_SET_CALCULATOR_PARAMS:
            return Object.assign({},state, {
                parameters: action.parameters
            });

        default:
            return state;
    }
};
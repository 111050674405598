import PropTypes from 'prop-types';
import React from "react";
import {Column} from "primereact/components/column/Column";
import {Row} from "primereact/components/row/Row";
import {ColumnGroup} from "primereact/components/columngroup/ColumnGroup";
import {DataTable} from "primereact/components/datatable/DataTable";
import {Button} from "primereact/components/button/Button";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";



const STATS_SELECTED_KEY=(uuid)=>'S_'+uuid+'_S';


/**
 * Component for displaying table of descriptive statistics.
 * Can be extended to different types regarding type of variable (cntinuous, ordinal, nominal, etc.)
 * @param dataSelection - store part
 * @param variable - object
 * @param getHistogram - clb to Action Creator
 * @returns {*}
 * @constructor
 */
export  const MultiValuedVariableStatistics = ({dataSelection,variable}) => {
    console.log('NumberVariableStatistics::render::',variable.uuid);

    // const index = dataSelection.dataset[0].variablesValues.findIndex((value) => {return value.variableName===variable.uuid});
    // const x = dataSelection.dataset.map((el)=>el.variablesValues[index].variableValue);
    // const dataToProcess = {x, title:'Histogram of '+variable.name,freq:false,xlab:variable.name,breaks:30};
    const statData=[
        {dset:"Selected",no:dataSelection.dataset.length},
        {dset:"All"},
        {dset:"Difference"}
    ];
    let headerGroup = <ColumnGroup>
        <Row>
            <Column header="Dataset"  />
            <Column header="Number" />
            <Column header="Median" />
            <Column header="Min" />
            <Column header="Max" />
            <Column header="MAD" />
            <Column header="Mosaic plot" />
            <Column header="Association plot" />
            <Column header="Model compatibility" />
        </Row>
    </ColumnGroup>;

    const showMosaic = (rowData, column) => {
        return <div>
            <Button type="button" icon="fa fa-bar-chart" onClick={(e) =>{}}
            />
        </div>;
    };
    const showAssoc = (rowData, column) => {
        return <div>
            <Button type="button" icon="fa fa-area-chart" onClick={(e) =>{}}
            />
        </div>;
    };

    return (
        <div>

            {/*{dataSelection.datasetState===REQUEST_STATUS_REQUESTED &&  */}
            {/*<div> Loading data <i className="fa fa-spinner fa-spin"/></div>}*/}
            {dataSelection.datasetState=== REQUEST_STATUS_SUCCESS &&
            // dataSelection.statisticsStates.get(STATS_SELECTED_KEY(variable.uuid)) ===REQUEST_STATUS_SUCCESS &&
            <React.Fragment>
                <DataTable value={statData} headerColumnGroup={headerGroup} >
                    <Column field="dset" />
                    <Column field="no" />
                    <Column field="med" />
                    <Column field="min" />
                    <Column field="max" />
                    <Column field="mad" />
                    <Column body={showMosaic}/>
                    <Column body={showAssoc}/>
                    <Column field="compatibility" />
                </DataTable>
            </React.Fragment>
            }
            {
                dataSelection.statisticsStates.get(STATS_SELECTED_KEY(variable.uuid)) === REQUEST_STATUS_FAIL &&
                <div>  The statistics are not available now. The administrators will take care of it. Please try again later.</div>
            }
        </div>
    );
};

MultiValuedVariableStatistics.propTypes = {
    dataSelection: PropTypes.object.isRequired,
};





import React from 'react';

/**
 * Spine logo for all pages except Login Page
 * @returns {*}
 */
export default () =>

    <svg xmlns="http://www.w3.org/2000/svg" width="33" height="14" viewBox="0 0 33 14">
        <path fill="#A7FFFE" fillRule="evenodd" d="M6.804 3.874H4.968V3.46c0-.468-.102-.843-.306-1.125-.204-.282-.534-.423-.99-.423-.456 0-.777.153-.963.459-.186.306-.279.705-.279 1.197 0 .528.06.9.18 1.116.12.216.408.414.864.594l1.404.558c.768.288 1.281.696 1.539 1.224s.387 1.29.387 2.286c0 1.164-.246 2.082-.738 2.754-.492.672-1.302 1.008-2.43 1.008-.888 0-1.632-.288-2.232-.864-.6-.576-.9-1.302-.9-2.178v-.684H2.34v.576c0 .372.105.684.315.936.21.252.537.378.981.378.576 0 .942-.147 1.098-.441.156-.294.234-.783.234-1.467s-.06-1.131-.18-1.341c-.12-.21-.414-.411-.882-.603l-1.314-.54c-.756-.312-1.278-.726-1.566-1.242-.288-.516-.432-1.2-.432-2.052 0-1.032.27-1.875.81-2.529.54-.654 1.32-.981 2.34-.981.876 0 1.605.297 2.187.891.582.594.873 1.263.873 2.007v.9zM8.172.184h2.754c.528 0 .978.069 1.35.207.372.138.714.369 1.026.693.312.324.528.711.648 1.161.12.45.18 1.065.18 1.845 0 .624-.033 1.122-.099 1.494a3.289 3.289 0 0 1-.459 1.143 2.57 2.57 0 0 1-.999.927c-.426.228-.969.342-1.629.342h-.936V13H8.172V.184zm1.836 6.084h.882c.672 0 1.092-.165 1.26-.495.168-.33.252-.885.252-1.665 0-.768-.078-1.326-.234-1.674-.156-.348-.558-.522-1.206-.522h-.954v4.356zM15.552.184h1.836V13h-1.836V.184zm3.546 0h1.764l2.772 7.722.036-7.722h1.836V13h-1.728L20.97 5.296 20.934 13h-1.836V.184zm8.064 0h5.472v1.728h-3.636v3.762h3.168v1.728h-3.168v3.762h3.636V13h-5.472V.184z"/>
    </svg>



/*<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 120 120">
		<text fill="#A7FFFE" fillRule="evenodd" fontFamily="DINEngschrift" fontSize="12" >
			<tspan x="3" y="16">SPINE</tspan>
		</text>
</svg>*/
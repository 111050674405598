import React from "react";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import PropTypes from 'prop-types';


/**
 * Dumb component for displaying dropdown with colors in it.
 * @use BrushToolbar, FillingToolbar
 * @returns {*}
 */
export const ColorDropdown = ({options,value,onChange}) => {

    const itemTemplate = (option)=>{
        if(!option.value) {
            return option.label;
        }
        else {
            return (
                <div className="ui-helper-clearfix">
                    <span style={{float:'left',margin:'.5em .25em 0 0'}}>{option.label}</span>
                    <div style={{display:'inline-block',width:'1em',height:'1em',backgroundColor:option.color, marginLeft: '2px', verticalAlign: 'bottom',float:'right'}}/>
                </div>
            );
        }};

return(
    <Dropdown value={value}
        options={options}
        onChange={(e)=>onChange(e.value)}
        itemTemplate={itemTemplate}/>
    )
};
ColorDropdown.propTypes = {
    options: PropTypes.array.isRequired,
    value: PropTypes.number.isRequired,
    onChange:PropTypes.func.isRequired
};

/**
 * Class providing  accessors to data from General Linear Model
 * obtained from  R Plumber and jsonlite serializer.
 * It works as a kind of mapper from R->Spine model.
 *
 *  This class has replaced previous solution based on mapping function and enums.
 *
 */
export class GLRModel{

    constructor(object, levelCI=0.95){
        let getIdx = function (name){return object.attributes.names.value.indexOf(name);};
        this._attributes = object.attributes.names.value;
        this._levelCI = levelCI;
        this._ebetas = object.value[getIdx('coefficients')].value;
        this._stdbetas = object.value[getIdx('coefficients')].value.slice(2,4);
        this._tvaluesbetas = object.value[getIdx('coefficients')].value.slice(4,6);
        this._pvaluesbetas = object.value[getIdx('coefficients')].value.slice(6,8);
        this._object = object;
    }

    getIndex(name){
        return this._attributes.indexOf(name);
    }

    /**
     *
     * @returns {*} confidence level as defined by object creation
     */
    get levelCI() {
        return this._levelCI;
    }
    /**
     *
     * @returns {*} raw object as it is generated by R with Plumber and jsonlite serializer
     */
    get object() {
        return this._object;
    }

    /**
     * @returns {*} beta estimations as 2 element array (intercept, slope)
     */
    get betasEstimates() {
        return this._ebetas;
    }

    /**
     * @returns {*} STD of beta estimations as 2 element array (intercept, slope)
     */
    get betasSTD() {
        return this._stdbetas;
    }

    /**
     * @returns {*} t-Values of beta estimations as 2 element array (intercept, slope)
     */
    get betasTValues() {
        return this._tvaluesbetas;
    }
    /**
     * Get p-values for intercept and slope params (i.e. Pr(>|t|))
     * @returns {*} p-Values of beta estimations as 2 element array (intercept, slope)
     */
    get betasPValues() {
        return this._pvaluesbetas;
    }

    /**
     * Get CI for intercept and slope params. These values are used to evaluate hypothesis H0!!!
     * @returns {*} confidence intervals for beta estimations as presented in R language
     * i.e. eg.
     *               2.5 %    97.5 %
     *     (Intercept) -8.5669158 5.9892763
     *                  0.1904498 0.2156429
     */
    get betasCI() {
        return this._betasCI;
    }


}

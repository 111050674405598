import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";
import {dateDiffInDays} from "../../helpers/dates";
import {ShowHide} from "./ShowHide";
import {renderSortIcon, sortByProperty} from "../../helpers/comparators";


export default class Activities extends React.Component {

    constructor(){
        super();
        this.onShowHideCompleted = this.onShowHideCompleted.bind(this);
    }
    componentWillMount() {
        const {getActivities} = this.props;
        getActivities();
    }
    onShowHideCompleted(){
        if (this.props.filter!=='HIDE_COMPLETED')
        this.props.onFilterChange('HIDE_COMPLETED');
        else   this.props.onFilterChange('SHOW_ALL');
    }

    render() {
        let {activities, onSorterChange,sorter} = this.props;
        const isMissedDeadline = function(classNames,date){
            let classes = [];
            let title="";
            if (date==="Completed") title="Task completed";
            let difference = 100;
            classNames.forEach((s)=>{classes.push(s)});
            if (new Date() > new Date(date)) {
                classes.push("missedDeadline");
                title="Missed deadline";
            }
            else{
                difference =dateDiffInDays(new Date(), new Date(date));
                if (difference < 2) {
                    classes.push("closeDeadline");
                    title="Don't miss the deadline! This is the last day!";
                }
            }

            let result = classes.join(" ");
            return <div className={result} title={title}>{date}</div> ;
        };

        // if (this.state.filterCompleted) activities=activities.filter((p)=>p.dueDate!=='Completed');

        //TODO Finish passing params
        const nodes = activities.map((p) =>
            <div className='spineRowStyle' key={p.uuid}>
                <Link to={`/task/${p.project.uuid}`}>
                    <div className="spineColStyle col4">{p.task.name}</div>
                </Link>
                <Link to={`/project/` + p.project.uuid}>
                    <div className="spineColStyle col4">{p.project.name}</div>
                </Link>
                <div className="spineColStyle col4">{p.experiment.name}</div>
                {isMissedDeadline(["spineColStyle","col4"],p.dueDate)}
            </div>);


        return (
            <div style={{width: '98%', margin: '1%'}}>
                <span className="reg-headtitle">My Activities</span>
                <div className="spineTableStyle">
                    <div className='spineRowHeaderStyle'>
                        <div className="spineColStyle col4" onClick={()=>{sortByProperty("NAME",sorter,onSorterChange); }}>
                            Task{renderSortIcon('NAME',sorter)}</div>
                        <div className="spineColStyle col4" onClick={()=>{sortByProperty("PROJECT_NAME",sorter,onSorterChange); }}>
                            Project{renderSortIcon('PROJECT_NAME',sorter)}</div>
                        <div className="spineColStyle col4" onClick={()=>{sortByProperty("EXP_NAME",sorter,onSorterChange); }}>
                            Experiment{renderSortIcon('EXP_NAME',sorter)}</div>
                        <div className="spineColStyle col4" onClick={()=>{sortByProperty("DUE_DATE",sorter,onSorterChange); }}>
                            Due Date{renderSortIcon('DUE_DATE',sorter)}</div>
                    </div>
                    {nodes}
                </div>
                <ShowHide clb={this.onShowHideCompleted} value={this.props.filter==='HIDE_COMPLETED'}/>
            </div>
        );
    }
};

Activities.propTypes = {
    getActivities: PropTypes.func.isRequired,
    activities: PropTypes.array.isRequired,
    onFilterChange:PropTypes.func,
    onSorterChange:PropTypes.func
};



import React from 'react';
import vtkFullScreenRenderWindow from 'vtk.js/Sources/Rendering/Misc/FullScreenRenderWindow';
import vtkRenderWindow from 'vtk.js/Sources/Rendering/Core/RenderWindow';
import vtkRenderer from 'vtk.js/Sources/Rendering/Core/Renderer';
import vtkHttpDataSetReader from 'vtk.js/Sources/IO/Core/HttpDataSetReader';
import vtkImageMapper from 'vtk.js/Sources/Rendering/Core/ImageMapper';
import vtkImageSlice from 'vtk.js/Sources/Rendering/Core/ImageSlice';
// import vtkRenderWindowWithControlBar from 'vtk.js/Sources/Rendering/Misc/RenderWindowWithControlBar';
import vtkOpenGLRenderWindow from 'vtk.js/Sources/Rendering/OpenGL/RenderWindow';
import vtkRenderWindowInteractor from 'vtk.js/Sources/Rendering/Core/RenderWindowInteractor';
import {Slider} from "primereact/components/slider/Slider";
import ReaderFactory from "../ReaderFactory";
import vtkITKImageReader from "vtk.js/Sources/IO/Misc/ITKImageReader/index";
import readImageArrayBuffer from "../readImageArrayBuffer";
import extensionToImageIO from "itk/extensionToImageIO";
import SpineVtkMRIBrowser from "../SpineVtkMRIBrowser";
import PropTypes from "prop-types";

const extensions = Array.from(
    new Set(Object.keys(extensionToImageIO).map((ext) => ext.toLowerCase()))
);
/**
 * 3D Image Slicer based on vtk example.
 *
 * {@link http://kitware.github.io/vtk-js/examples/MultiSliceImageMapper.html}
 * {@deprecated just for education - for production see BrainBrowser component}
 */
class MultiSliceMapper extends React.Component {

    componentDidMount(){
        vtkITKImageReader.setReadImageArrayBufferFromITK(readImageArrayBuffer); //required by external library
        extensions.forEach((extension) => {
            ReaderFactory.registerReader({
                extension,
                name: `${extension.toUpperCase()} Reader`,
                vtkReader: vtkITKImageReader,
                binary: true,
                fileNameMethod: 'setFileName',
            });
        })
        const renderWindow = vtkRenderWindow.newInstance();
        const renderer = vtkRenderer.newInstance({ background: [0.2, 0.3, 0.4] });
        renderWindow.addRenderer(renderer);

        const iInput = this.iInput;
        const jInput = this.jInput;
        const kInput = this.kInput;
        const colorLevel = this.colorLevel;
        const colorWindow = this.colorWindow;

        const imageActorI = vtkImageSlice.newInstance();
        const imageActorJ = vtkImageSlice.newInstance();
        const imageActorK = vtkImageSlice.newInstance();

        renderer.addActor(imageActorK);
        renderer.addActor(imageActorJ);
        renderer.addActor(imageActorI);


        function updateColorLevel(e) {
            const cl = Number(
                (e ? e.target : colorLevel).value);
            imageActorI.getProperty().setColorLevel(cl);
            imageActorJ.getProperty().setColorLevel(cl);
            imageActorK.getProperty().setColorLevel(cl);
            renderWindow.render();
        }

        function updateColorWindow(e) {
            const cl = Number(
                (e ? e.target : colorWindow).value
            );
            imageActorI.getProperty().setColorWindow(cl);
            imageActorJ.getProperty().setColorWindow(cl);
            imageActorK.getProperty().setColorWindow(cl);
            renderWindow.render();
        }


        ReaderFactory.downloadDataset(this.props.originalImageFilename,this.props.originalImageUrl ,null)
            .then((result) => {
                const data = result.reader.getOutputData();
                const dataRange = data
                    .getPointData()
                    .getScalars()
                    .getRange();
                const extent = data.getExtent();

                const imageMapperK = vtkImageMapper.newInstance();
                imageMapperK.setInputData(data);
                imageMapperK.setKSlice(30);
                imageActorK.setMapper(imageMapperK);

                const imageMapperJ = vtkImageMapper.newInstance();
                imageMapperJ.setInputData(data);
                imageMapperJ.setJSlice(30);
                imageActorJ.setMapper(imageMapperJ);

                const imageMapperI = vtkImageMapper.newInstance();
                imageMapperI.setInputData(data);
                imageMapperI.setISlice(30);
                imageActorI.setMapper(imageMapperI);

                renderer.resetCamera();
                renderer.resetCameraClippingRange();
                // renderWindow.render();

                [iInput, jInput, kInput].forEach((selector, idx) => {
                    const el = selector;
                    el.setAttribute('min', extent[idx * 2 + 0]);
                    el.setAttribute('max', extent[idx * 2 + 1]);
                    el.setAttribute('value', 30);
                });

                [colorLevel, colorWindow].forEach((selector) => {
                    selector.setAttribute('max', dataRange[1]);
                    selector.setAttribute('min', dataRange[0]);
                    // selector.setAttribute('value', dataRange[1]);
                });

                [imageActorI,imageActorJ,imageActorK].forEach((s)=>s.getProperty().setColorWindow(dataRange[1]-dataRange[0]));
                [imageActorI,imageActorJ,imageActorK].forEach((s)=>s.getProperty().setColorLevel(dataRange[0]+ (dataRange[1] - dataRange[0]) *.5));

                colorWindow.setAttribute('value',dataRange[1]-dataRange[0]);
                colorLevel.setAttribute('value',(dataRange[0]+ (dataRange[1] - dataRange[0]) *.5));
                renderWindow.render();
                // updateColorLevel();
                // updateColorWindow();
            });


        iInput.addEventListener('input', (e) => {
            imageActorI.getMapper().setISlice(Number(e.target.value));
            renderWindow.render();
        });

        jInput.addEventListener('input', (e) => {
            imageActorJ.getMapper().setJSlice(Number(e.target.value));
            renderWindow.render();
        });

        kInput.addEventListener('input', (e) => {
            imageActorK.getMapper().setKSlice(Number(e.target.value));
            renderWindow.render();
        });


        colorLevel.addEventListener('input', updateColorLevel);

        colorWindow.addEventListener('input', updateColorWindow);


        const openglRenderWindow = vtkOpenGLRenderWindow.newInstance();


        renderWindow.addView(openglRenderWindow);


        openglRenderWindow.setContainer(this.node);
        const interactor = vtkRenderWindowInteractor.newInstance();
        interactor.setView(openglRenderWindow);
        interactor.initialize();
        interactor.bindEvents(this.node);
    }

    render() {
        return (
            <div>
                <div ref={node => this.node = node}/>
                <div className={"ui-g-12"}>
                    <input className="ui-slider ui-widget ui-widget-content ui-corner-all ui-slider-horizontal"
                           type="range"
                           ref={iInput => this.iInput = iInput}
                    />
                </div>
                <div className={"ui-g-12"}>
                    <input type="range"
                           ref={jInput => this.jInput = jInput}
                    />
                </div>
                <div className={"ui-g-12"}>
                    <input type="range"
                           ref={kInput => this.kInput = kInput}
                    />
                </div>
                <div className={"ui-g-12"}>
                    <input type="range"
                           ref={colorLevel => this.colorLevel = colorLevel}
                    />
                </div>
                <div className={"ui-g-12"}>
                    <input type="range"
                           ref={colorWindow => this.colorWindow = colorWindow}
                    />
                </div>
            </div>
        );

    }
}

MultiSliceMapper.propTypes = {
    originalImageUrl:PropTypes.string.isRequired,
    originalImageFilename:PropTypes.string.isRequired,//needed for itk readers
}

export default MultiSliceMapper;
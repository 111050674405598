import React from 'react';
import PropTypes from 'prop-types';
import vtkRenderWindow from 'vtk.js/Sources/Rendering/Core/RenderWindow';
import vtkRenderer from 'vtk.js/Sources/Rendering/Core/Renderer';
import vtkImageMapper from 'vtk.js/Sources/Rendering/Core/ImageMapper';
import vtkImageSlice from 'vtk.js/Sources/Rendering/Core/ImageSlice';
import vtkOpenGLRenderWindow from 'vtk.js/Sources/Rendering/OpenGL/RenderWindow';
import vtkRenderWindowInteractor from 'vtk.js/Sources/Rendering/Core/RenderWindowInteractor';
import * as SpineInteractorStyleImage from "./SpineInteractorStyleImage";
import vtkITKImageReader from "vtk.js/Sources/IO/Misc/ITKImageReader";
import readImageArrayBuffer from './readImageArrayBuffer';
import ReaderFactory from "./ReaderFactory";
import extensionToImageIO from 'itk/extensionToImageIO';
import vtkCellPicker from 'vtk.js/Sources/Rendering/Core/CellPicker';
import {Slider} from "primereact/components/slider/Slider";
import {ProgressBar} from "primereact/components/progressbar/ProgressBar";
import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';
import vtkLookupTable from 'vtk.js/Sources/Common/Core/LookupTable';
import vtkColorMaps from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction/ColorMaps';
import vtkPiecewiseFunction from 'vtk.js/Sources/Common/DataModel/PiecewiseFunction';
import Meters from "./Meters";
import vtkSpineImageSliceFilter from "./SpineImageSliceFilter";
import vtkScalarToRGBA from "./SpineScalarToRGBA";
import vtkTexture from "vtk.js/Sources/Rendering/Core/Texture/index";
import vtkPlaneSource from "vtk.js/Sources/Filters/Sources/PlaneSource/index";
import vtkActor from "vtk.js/Sources/Rendering/Core/Actor/index";
import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper/index";
import {loggingDataset} from "./MRIBrowserLogger";
import {calculateOriginBiasIJK, setOverlaySlice} from "./OverlayCalculations";


const extensions = Array.from(
    new Set(Object.keys(extensionToImageIO).map((ext) => ext.toLowerCase()))
);

const PLANES = {SAGITTAL: 0, CORONAL: 1, AXIAL: 2};

/** BrainBrowser-like component for displaying 3 D slices.
 *  This is the last and mandatory version!
 *  The overlay is implemented with use of Sclar to RGBA example
 *
 *  {@link https://kitware.github.io/vtk-js/examples/ScalarToRGBA.html}
 *
 *  Based on:
 *  {@link http://kitware.github.io/vtk-js/examples/MultiSliceImageMapper.html}
 *  {@link http://kitware.github.io/vtk-js/examples/ImageCroppingRegionsWidget.html}
 *
 *  See props at the bottom.
 */
class SpineVtkMRIBrowser extends React.Component {

    constructor() {
        super();
        this.state = {
            renderWindow: vtkRenderWindow.newInstance(),
            loadingProgress: [{hasFinished: false, percent: 0}, {hasFinished: false, percent: 0}],
            overlayData: {},
            imageData: {},
            originTranslation: [],
            isError: false,
            errorMessage: null,
            dataRange: [0, 128],
            dataRange2: [0, 1], //binary
            extent: [0, 10000, 0, 10000, 0, 10000], //range of slice indices for original image
            currentImageSlice: [0, 0, 0],
            direction: [1, 0, 0, 0, 1, 0, 0, 0, 1], //original data direction
            overlayDirection: [1, 0, 0, 0, 1, 0, 0, 0, 1],//original data direction
            overlayOpacity: 1.0,
            colorLevel: 128,
            colorWindow: 100,
            imageActors: [vtkImageSlice.newInstance(), vtkImageSlice.newInstance(), vtkImageSlice.newInstance()],
            //replace to ImageSlice after translucency fixed!!!:
            overlayActors: [vtkActor.newInstance(), vtkActor.newInstance(), vtkActor.newInstance()],
            //to remove after translucency fixed!!!:
            textures: [vtkTexture.newInstance(), vtkTexture.newInstance(), vtkTexture.newInstance()],
            planeSources: [vtkPlaneSource.newInstance(), vtkPlaneSource.newInstance(), vtkPlaneSource.newInstance()],
            sliceFilters: [vtkSpineImageSliceFilter.newInstance({sliceIndex: 0}), vtkSpineImageSliceFilter.newInstance({sliceIndex: 0}), vtkSpineImageSliceFilter.newInstance({sliceIndex: 0})],
            rgbaFilters: [vtkScalarToRGBA.newInstance(), vtkScalarToRGBA.newInstance(), vtkScalarToRGBA.newInstance()]
        };
        this.onWLRangeChange = this.onWLRangeChange.bind(this);
        this.onCLRangeChange = this.onCLRangeChange.bind(this);
        this.onOverlayOpacityChange = this.onOverlayOpacityChange.bind(this);
        this.changeSlice = this.changeSlice.bind(this);
        this.setSlices = this.setSlices.bind(this);
        this.setSlices = this.setSlices.bind(this);
        this.progressCallback = this.progressCallback.bind(this);
        this.progressCallback2 = this.progressCallback2.bind(this);
    }

    static generateColorTransferFunction(dataRange2,heatmap) {
        if (heatmap!=null) {
            const lookupTable = vtkColorTransferFunction.newInstance();
            const preset = vtkColorMaps.getPresetByName(heatmap);
            lookupTable.applyColorMap(preset);
            lookupTable.setMappingRange(...dataRange2);
            lookupTable.updateRange();
            return  lookupTable;
        }
        const ctfun = vtkColorTransferFunction.newInstance();
        ctfun.addRGBPoint(dataRange2[0], 0.0, 0.0, 0.0);
        ctfun.addRGBPoint(dataRange2[0] + 0.1, 1.0, 0.0, 0.0);
        ctfun.addRGBPoint(dataRange2[1], 1.0, 0.0, 0.0);
        return ctfun;

    }

    /**
     *  Register readers, set  buffer
     */
    componentWillMount() {
        console.log(extensions);
        vtkITKImageReader.setReadImageArrayBufferFromITK(readImageArrayBuffer); //required by external library
        extensions.forEach((extension) => {
            ReaderFactory.registerReader({
                extension,
                name: `${extension.toUpperCase()} Reader`,
                vtkReader: vtkITKImageReader,
                binary: true,
                fileNameMethod: 'setFileName',
            });
        })
    }

    componentDidMount() {
        this.createBrowser();
    }

    /**
     * Window Level Range onChange handler.
     * Controls the window in a window level mapping of the input image. Window
     * level mapping is a technique to map the raw data values of an image
     * into screen intensities in a manner akin to
     *
     * pixelIntensity = (inputValue - level)/window;
     *
     * @param event
     */
    onWLRangeChange(event) {
        const cl = Number((event ? event : this.state.colorWindow).value);
        this.changeWL(cl);
        this.refreshRenderWindow();
    }

    /**
     * Change Window Level
     * @param wl - value
     */
    changeWL(wl) {
        this.state.imageActors.forEach((s) => {
            s.getProperty().setColorWindow(wl)
        });
        this.setState({colorWindow: wl});
    }

    /**
     * Color Level Range onChange handler.
     * @see above
     * @param event
     */
    onCLRangeChange(event) {
        const cl = Number((event ? event : this.state.colorLevel).value);
        this.changeCL(cl);
        this.refreshRenderWindow();
    }

    /**
     * Change Color Level
     * @param cl - value
     */
    changeCL(cl) {
        this.state.imageActors.forEach((s) => {
            s.getProperty().setColorLevel(cl)
        });
        this.setState({colorLevel: cl});
    }

    /**
     * Handler for slice change [k=2]
     * @param event
     */
    changeSlice(event) {
        let cis = this.state.currentImageSlice;
        cis[2] = event.value;
        this.setState({currentImageSlice: cis});
        this.state.imageActors[2].getMapper().setSlice(event.value);
        this.setSlices(2);
        this.refreshRenderWindow();
    }

    /**
     * Handler for overlay opacity slider
     * @param event
     */
    async onOverlayOpacityChange(event) {
        this.setState({overlayOpacity: event.value / 100});
        if (this.state.isError) return;
        this.state.rgbaFilters.forEach((s) => {
            s.setPiecewiseFunction(this.generateOpacityFunction(this.state.dataRange2));
        });
        Object.keys(PLANES).forEach((s, idx) => this.updateTextures(idx));
        this.refreshRenderWindow();
    }

    /**
     * Progress callback
     * @param event
     */
    progressCallback(event) {
        console.log("BrainBrowser:progressCallback", event.loaded);
        let lP = this.state.loadingProgress;
        lP[0].percent = Math.round(100 * event.loaded / event.total);
        this.setState({loadingProgress: lP});
    };

    /**
     * Progress callback
     * @param event
     */
    progressCallback2(event) {
        console.log("BrainBrowser:progressCallback", event.loaded);
        let lP = this.state.loadingProgress;
        lP[1].percent = Math.round(100 * event.loaded / event.total);
        this.setState({loadingProgress: lP});
    }

    refreshRenderWindow() {
        this.state.renderWindow.render();
    }

    /**
     * @param idx -  index of renderer
     */
    setSlices(idx) {
        if (this.props.viewportMode !== 'axial' || idx === 2) {//not register i,j if there is only one viewport{
            let cis = this.state.currentImageSlice;
            cis[idx] = this.state.imageActors[idx].getMapper().getSlice();
            if (this.state.isError) return;
            const {sF, pS} = setOverlaySlice(idx, cis, this.state.imageData, this.state.overlayData, this.state.sliceFilters,
                this.state.planeSources, this.state.originTranslation, this.state.overlayDirection);
            this.setState({sliceFilters: sF, planeSources: pS});
            this.updateTextures(idx);
        }
    }

    /**
     * Update texture after any change.
     *
     * To remove when sliceImageFilter is replaced with
     *
     * @param idx - index of renderer
     */
    updateTextures(idx) {
        let txt = this.state.textures;
        txt[idx].modified();  //IMPORTANT
        this.setState({textures: txt});
    }

    async createBrowser() {
        let thisView = this;  // this autoreference is needed for closures
        let initialSlices = [0, 0, 0];
        const actors = this.state.overlayActors;
        const {iRenderer, jRenderer, kRenderer, size} = this.setViewports();//initialising renderers with setting viewports

        const interactorStyle2D = SpineInteractorStyleImage.newInstance();
        interactorStyle2D.setInteractionMode('IMAGE_SLICING');

        const renderWindow = thisView.state.renderWindow;
        const openglRenderWindow = vtkOpenGLRenderWindow.newInstance({size: size});
        renderWindow.addView(openglRenderWindow);

        openglRenderWindow.setContainer(this.node);


        const interactor = vtkRenderWindowInteractor.newInstance();
        interactor.setInteractorStyle(interactorStyle2D); // in case using predefined styles - SpineIneractorStyleImage
        interactor.setView(openglRenderWindow);
        interactor.initialize();
        interactor.bindEvents(this.node);

        const picker = vtkCellPicker.newInstance();
        renderWindow.getInteractor().setPicker(picker);

        const imageActors = this.state.imageActors;
        const imageMappers = [vtkImageMapper.newInstance(), vtkImageMapper.newInstance(), vtkImageMapper.newInstance()];
        const sliceFilters = this.state.sliceFilters;
        const textures = this.state.textures;
        const rgbaFilters = this.state.rgbaFilters;
        const planeSources = this.state.planeSources;

        [iRenderer, jRenderer, kRenderer].forEach((selector, idx) => {
            if (thisView.props.viewportMode !== 'axial' || idx === 2) {
                renderWindow.addRenderer(selector);
                selector.setBackground(0, 0, 0);
                selector.getActiveCamera().setParallelProjection(true); // if true keeps constant distance between camera and slice
                selector.addActor(imageActors[idx]);
            }
        });

        const updateProgress = (index) => {
            let lP = thisView.state.loadingProgress;
            lP[index].hasFinished = true;
            thisView.setState({loadingProgress: lP});
        };

        const loadOriginalImage = (result) => {
            const reader = result.reader;
            const data = reader.getOutputData();
            thisView.setState({direction: data.getDirection().slice()});
            thisView.setState({imageData: data});
            // data.setDirection(1, 0, 0, 0, 1, 0, 0, 0, 1);

            console.log('Original image');
            loggingDataset(data);
            const dataRange = data
                .getPointData()
                .getScalars()
                .getRange();

            thisView.changeWL(dataRange[1] - dataRange[0]);
            thisView.changeCL(dataRange[0] + (dataRange[1] - dataRange[0]) * .5);
            thisView.setState({dataRange: dataRange});
            thisView.setState({extent: data.getExtent()});

            //  if initial slices is prop, then use it, otherwise set in the middle of volume
            if (this.props.initialSlices != null && this.props.initialSlices.length === 3)
                initialSlices = this.props.initialSlices;
            else {
                for (let i = 0; i < 3; i++)
                    initialSlices[i] = Math.floor((thisView.state.extent[i * 2 + 1] - thisView.state.extent[i * 2]) / 2);
            }
            imageMappers.forEach((s, idx) => {
                s.setInputData(data);
                if (idx === 0)
                    s.setISlice(initialSlices[idx]);
                if (idx === 1)
                    s.setJSlice(initialSlices[idx]);
                if (idx === 2)
                    s.setKSlice(initialSlices[idx]);
            });

            thisView.setState({currentImageSlice: initialSlices});
            imageActors.forEach((s, idx) => {
                s.setMapper(imageMappers[idx]);
                s.getProperty().setOpacity(1.0);//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
            });


            iRenderer.getActiveCamera().set({position: [1, 0, 0]});
            iRenderer.getActiveCamera().set({viewUp: [0, 0, 1]});
            iRenderer.resetCamera();

            jRenderer.getActiveCamera().set({position: [0, 1, 0]});
            jRenderer.getActiveCamera().set({viewUp: [0, 1, 0]});
            jRenderer.resetCamera();

            kRenderer.getActiveCamera().set({position: [0, 0, -1]});
            kRenderer.getActiveCamera().set({viewUp: [0, -1, 0]});
            kRenderer.resetCamera();
            updateProgress(0);
            thisView.setState({colorLevel: (dataRange[0] + dataRange[1]) / 2});
            this.refreshRenderWindow();

        };

        const loadOverlayImage = (result) => {
            const reader = result.reader;
            const data = reader.getOutputData();

            thisView.setState({overlayData: data});
            const port = reader.getOutputPort();

            const mappers = [vtkMapper.newInstance(), vtkMapper.newInstance(), vtkMapper.newInstance()];

            sliceFilters.forEach((s) => {
                s.setInputConnection(port);
            });
            thisView.setState({overlayDirection: data.getDirection().slice()});
            // data.setDirection(1, 0, 0, 0, 1, 0, 0, 0, 1);
            //port.setDirection(1, 0, 0, 0, 1, 0, 0, 0, 1);

            const transl = calculateOriginBiasIJK(thisView.state.imageData.getOrigin(), thisView.state.overlayData.getOrigin(),
                thisView.state.imageData.getSpacing(), thisView.state.overlayDirection);
            thisView.setState({originTranslation: transl}); //setting translation between origins

            console.log('direction matrix');
            console.log(data.getDirection().slice());

            console.log('Translation');
            console.log(transl);
            console.log('Overlay image');
            loggingDataset(data);

            const scalars = data.getPointData().getScalars();

            const dataRange2 = [].concat(scalars ? scalars.getRange() : [0, 1]);

            thisView.setState({dataRange2: dataRange2});

            //=======    setting colors&opacity ===========================
            rgbaFilters.forEach((rgbaFilter, i) => {
                rgbaFilter.setLookupTable(SpineVtkMRIBrowser.generateColorTransferFunction(dataRange2,this.props.heatmap));
                rgbaFilter.setPiecewiseFunction(thisView.generateOpacityFunction(dataRange2));
                rgbaFilter.setInputConnection(sliceFilters[i].getOutputPort());
            });

            textures.forEach((texture, idx) => {
                texture.setInputConnection(rgbaFilters[idx].getOutputPort());
            });

            //=======    positioning planes===========================
            mappers.forEach((m, idx) => {
                thisView.setSlices(idx);//XXX
                m.setInputConnection(planeSources[idx].getOutputPort());
            });

            actors.forEach((a, idx) => {
                a.setMapper(mappers[idx]);
                a.addTexture(textures[idx]);
            });
            [iRenderer, jRenderer, kRenderer].forEach((selector, idx) => {
                if (thisView.props.viewportMode !== 'axial' || idx === 2) {
                    selector.resetCamera();
                    selector.addActor(actors[idx]);
                }
            });

            updateProgress(1);
            thisView.refreshRenderWindow();

        };

        function readOriginal() {
            return ReaderFactory
                .downloadDataset(thisView.props.originalImageFilename, thisView.props.originalImageUrl, thisView.progressCallback)
                .catch(err => {
                    console.log('Error during loading image: ', err);
                    if (thisView.props.onLoadingError != null)
                        thisView.props.onLoadingError(err);
                    updateProgress(0);
                    thisView.setState({isError: true, errorMessage: "Error during loading image."});
                });
        }

        function readOverlay() {
            return ReaderFactory.downloadDataset(thisView.props.overlayImageFilename, thisView.props.overlayImageUrl, thisView.progressCallback2)
                .catch(err => {
                    console.log('Error during loading label map image: ', err);
                    updateProgress(1);
                    if (thisView.props.onLoadingError != null)
                        thisView.props.onLoadingError(err);
                    thisView.setState({isError: true, errorMessage: "Error during loading label map image."});
                });
        }

        const [result1, result2] = await Promise.all(
            [readOriginal(), readOverlay()]);

        if (result1 != null) {
            loadOriginalImage(result1);
        }
        if (result2 != null) {
            loadOverlayImage(result2);
        }

    }

    generateOpacityFunction(dataRange2) {
        const ofun = vtkPiecewiseFunction.newInstance();
        ofun.removeAllPoints();
        ofun.addPoint(dataRange2[0], 0.0);
        ofun.addPoint((dataRange2[0] + dataRange2[1]) * 0.5, 0.1 * this.state.overlayOpacity);
        ofun.addPoint(dataRange2[1], this.state.overlayOpacity);
        return ofun;
    }

    setViewports() {
        const leftPosition = [0, 0, 0.33, 1];
        const middlePosition = [0.33, 0, 0.66, 1];
        const rightPosition = [0.66, 0, 1, 1];
        const leftUpper = [0, 0.5, 0.5, 1];
        const rightUpper = [0.5, 0.5, 1, 1];
        const leftLower = [0, 0, 0.5, 0.5];
        let size = [];
        const iRenderer = vtkRenderer.newInstance();
        const jRenderer = vtkRenderer.newInstance();
        const kRenderer = vtkRenderer.newInstance();

        switch (this.props.viewportMode) {
            case 'square':
                iRenderer.setViewport(leftUpper);
                jRenderer.setViewport(rightUpper);
                kRenderer.setViewport(leftLower);
                size = [this.props.viewportSize * 2, this.props.viewportSize * 2];
                break;
            case 'horizontal':
                iRenderer.setViewport(leftPosition);
                jRenderer.setViewport(rightPosition);
                kRenderer.setViewport(middlePosition);
                size = [this.props.viewportSize * 3, this.props.viewportSize];
                break;
            case 'axial':
                kRenderer.setViewport([0, 0, 1, 1]);
                size = [this.props.viewportSize, this.props.viewportSize];
                break;

        }


        return {iRenderer, jRenderer, kRenderer, size};
    }


    render() {
        let progressBar = null;
        let spinner = null;
        const axialMode = (this.props.viewportMode === 'axial');
        const axialClass = (this.props.viewportMode === 'axial') ? "ui-g-12" : "ui-g-4";

        if (!this.state.loadingProgress[0].hasFinished || !this.state.loadingProgress[1].hasFinished) {
            progressBar = (
                <div>Loading MRI Volume Image...
                    <ProgressBar value={this.state.loadingProgress[0].percent}/>
                    Loading Label Map
                    <ProgressBar value={this.state.loadingProgress[1].percent}/>
                </div>
            );
        }

        if (!this.state.loadingProgress[0].hasFinished || !this.state.loadingProgress[1].hasFinished) {
            spinner = <div> Loading data: <i className="fa fa-spinner fa-spin"/></div>;
        }
        return (
            <div className={"ui-g-12"}>
                {this.props.progressBars && progressBar}
                {!this.props.progressBars && spinner}
                <div className={"ui-g-12"}>
                    <div className={"ui-g-11"}>
                        <Slider value={this.state.currentImageSlice[2]} min={0} max={this.state.extent[2 * 3 - 1]}
                                onChange={this.changeSlice}/>
                    </div>
                    <div className={"ui-g-1"}>
                        {this.state.currentImageSlice[2]}
                    </div>
                </div>
                {/*<div className={"ui-g-12"}>*/}
                <div ref={node => this.node = node}/>
                {/*</div>*/}
                <div className={"ui-g-4"}>
                    <div>Level</div>
                    <Slider value={this.state.colorLevel} min={this.state.dataRange[0]} max={this.state.dataRange[1]}
                            onChange={this.onCLRangeChange}/>
                </div>
                <div className={"ui-g-4"}>
                    <div>Window</div>
                    <Slider value={this.state.colorWindow} min={1} max={this.state.dataRange[1]}
                            onChange={this.onWLRangeChange}/>
                </div>
                <div className={"ui-g-4"}>
                    <div>ROI's opacity</div>
                    <Slider value={this.state.overlayOpacity * 100} max={100} min={0}
                            onChange={this.onOverlayOpacityChange}/>
                </div>
                {/*<div className={"ui-g-4"}>*/}

                {(this.state.loadingProgress[0].hasFinished && this.state.loadingProgress[1].hasFinished) && !this.state.isError &&
                <Meters {...this.state} {...this.props}/>}

                {/*</div>*/}
            </div>
        );

    }
}

SpineVtkMRIBrowser.defaultProps = {
    viewportSize: 250,
    viewportMode: 'horizontal', //'horizontal', 'square' or 'axial'
    progressBars: false  // should progress bar be dispolayed
};
SpineVtkMRIBrowser.propTypes = {
    viewportSize: PropTypes.number,
    viewportMode: PropTypes.string, //'horizontal' or 'square' or 'axial' only
    originalImageUrl: PropTypes.string.isRequired,
    overlayImageUrl: PropTypes.string.isRequired,
    originalImageFilename: PropTypes.string.isRequired,//needed for itk readers
    overlayImageFilename: PropTypes.string.isRequired,//
    initialSlices: PropTypes.array,
    progressBars: PropTypes.bool, // whether progress bar or spinner  should be displayed (when server is not returning size)
    onLoadingError: PropTypes.func, // callback to parent component for handling errors (alert, growl, whatever)
    heatmap:PropTypes.string //'erdc_rainbow_bright'
};

export default SpineVtkMRIBrowser;
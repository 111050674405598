import React from "react";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import {Button} from "primereact/components/button/Button";
import {Tooltip} from "primereact/components/tooltip/Tooltip";
import {Spinner} from "primereact/components/spinner/Spinner";
import {POWER_CALCULATOR} from "../models/PowerCalculator";
import PropTypes from "prop-types";
import {REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";

/**
 * "Dumb" component  for calculating  Sample size or Statistical Power for Linear Model.
 * Can be placed in any container (Panel, Overlay Panel, Dialog Window)
 * @returns {*}
 */
const GLRCalculator = ({calculateSampleSizeActionCreator,calculator,setCalculatorParameters,numberOfSamples}) => {


 let changeParameters = (name,value)=> {
     let parameters = calculator.parameters;
     parameters.data[name]=value;
     setCalculatorParameters(parameters);
    };

/** Calls
 *
 * v = sampleSize − u − 1
 * */

        const sigOptions = [{label: '5%', value: 0.05}, {label: '1%', value: 0.01}];

        return (

            <div className="ui-g">
                <div className="ui-g-12 ui-md-4 ui-lg-4">
                    <div className="ui-g-12">
                        <table id="statisticalCalculatorTable" >
                            <tbody>
                            <tr id="dfn">
                                <td style={{textAlign:"left"}}> {POWER_CALCULATOR.GLR.ARGS.U.LABEL} </td>
                                <td >
                                    <div >{calculator.parameters.data.u}</div>
                                </td>
                            </tr>
                            <tr id="significanceLevel">
                                <td style={{textAlign:"left"}}> {POWER_CALCULATOR.GLR.ARGS.SIG_LEVEL.LABEL} </td>
                                <td >
                                    <Dropdown
                                              options={sigOptions}
                                              value={calculator.parameters.data[POWER_CALCULATOR.GLR.ARGS.SIG_LEVEL.NAME]}
                                              name="SP"
                                              style={{width:'4em'}}
                                              onChange={(e) => changeParameters(POWER_CALCULATOR.GLR.ARGS.SIG_LEVEL.NAME,e.value)}/>
                                </td>
                            </tr>
                            <tr  id="effectSize">
                                <td style={{textAlign:"left"}}> Effect size f2 </td>
                                <td >
                                    <Spinner
                                             value={calculator.parameters.data[POWER_CALCULATOR.GLR.ARGS.F2.NAME]}
                                             onChange={(e) => changeParameters(POWER_CALCULATOR.GLR.ARGS.F2.NAME,e.value)}
                                             min={0.01} max={0.99} step={0.01} size={4}/>
                                </td>
                            </tr>
                            <tr id="powerSpinner" >
                                <td style={{textAlign:"left"}}> Statistical power </td>
                                <td >
                                    <Spinner value={calculator.parameters.data[POWER_CALCULATOR.GLR.ARGS.POWER.NAME]}
                                             onChange={(e) => changeParameters(POWER_CALCULATOR.GLR.ARGS.POWER.NAME,e.value)}
                                             min={0.1} max={0.99} step={0.01} size={4}
                                    />
                                </td>
                            </tr>
                            <tr id="requiredSampleSize">
                                <th >Required sample size </th>
                                <td >
                                    {calculator.calculationState===REQUEST_STATUS_REQUESTED &&
                                    <i className="fa fa-spinner"/>   }
                                    {calculator.calculationState===REQUEST_STATUS_SUCCESS && calculator.ss}
                                    <Button icon='fa-calculator' onClick={()=>calculateSampleSizeActionCreator()} title="Calculate required sample size"/>
                                </td>
                            </tr>
                            <tr id="selectedSampleSize">
                                <th>Selected sample size</th>
                                <td>{(typeof numberOfSamples==='number')?numberOfSamples:'#'}</td>
                            </tr>
                            <tr id="filteredSampleSize">
                                <th>Filtered samples size</th>
                                <td>NA</td>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </div>

                <div className="ui-g-12 ui-md-8 ui-lg-8">
                    {calculator.plotState===REQUEST_STATUS_REQUESTED &&
                    <i className="fa fa-spinner"/>   }
                    {calculator.plotState===REQUEST_STATUS_SUCCESS &&
                    <img src={`data:image/png;base64,${calculator.plot}`}/>}
                </div>
                <Tooltip for="#effectSize" title={POWER_CALCULATOR.GLR.ARGS.F2.DESCRIPTION}
                         tooltipPosition="right" tooltipEvent="hover"/>
                <Tooltip for="#dfn" title={POWER_CALCULATOR.GLR.ARGS.U.DESCRIPTION}
                         tooltipPosition="right" tooltipEvent="hover"/>
                <Tooltip for="#significanceLevel" title={POWER_CALCULATOR.GLR.ARGS.SIG_LEVEL.DESCRIPTION}
                         tooltipPosition="right" tooltipEvent="hover"/>
                <Tooltip for="#powerSpinner" title={POWER_CALCULATOR.GLR.ARGS.POWER.DESCRIPTION}
                         tooltipPosition="right" tooltipEvent="hover"/>
                <Tooltip for="#selectedSampleSize" title="The available sample size before applying inclusion/exclusion criteria."
                         tooltipPosition="right" tooltipEvent="hover" />
                <Tooltip for="#filteredSampleSize" title="The available sample size after applying inclusion/exclusion criteria."
                         tooltipPosition="right" tooltipEvent="hover" />
                <Tooltip for="#requiredSampleSize" title="The required sample size indicated with colours on the Calculator Card.
                     If not set by user, the default value is calculated using 5% significance level, 80% power and medium effect of 0.15 value."
                         tooltipPosition="right" tooltipEvent="hover" />
            </div>
        );

};

export default GLRCalculator

GLRCalculator.propTypes = {
    numberOfSamples: PropTypes.number.isRequired,
    setCalculatorParameters: PropTypes.func.isRequired
};
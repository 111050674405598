import {getDataset} from "../action/DataSelectionAction";
import {connect} from "react-redux";
import Audit from "../component/Audit";
import {setStep} from "../action/WizardStepAction";
import {calculateModelParams} from "../action/AuditAction";


const mapStateToProps = state => {
    return{
        experiment: state.expDesign.experiment,
        dataSelection: state.expDesign.dataSelection,
        variablesList:state.visu.variables.variables,
        appState: state
    }
};

const mapDispatchToProps = dispatch => {
    return{
        setStep:   (def) =>{dispatch(setStep(def))},
        getDataset: (experiment) => {
            dispatch(getDataset(experiment))
        },
        calculateParameters: (dim, data)=>{dispatch(calculateModelParams(dim,data))}
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps)(Audit)
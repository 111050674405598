import React from 'react'
import {ContainerProjectTable} from "../project/containers/ContainerProjectList";
import {ContainerActivities} from "./containers/ContainerActivities";
import {ContainerSkills} from "./containers/ContainerSkills";
import {ContainerEvents} from "./containers/ContainerEvents";
import {ContainerRewards} from "./containers/ContainerRewards";


export default class Dashboard extends React.Component {


    render() {
        console.log("Dashboard ... render");
        return (
            <div  style={{margin: '16px'}}>
                <div id="myActivities" style={{width: '100%', height: 'auto', marginTop: '20px'}}>
                    <ContainerActivities/>
                </div>
                <div style={{width: '100%', height: 'auto', marginTop: '20px'}}>
                    <div style={{width: '50%', display: 'inline-block'}}>
                        <ContainerProjectTable/>
                    </div>
                    <div style={{width: '50%', display: 'inline-block'}}>
                        <ContainerSkills/>
                    </div>
                </div>
                <div style={{width: '100%', height: 'auto', marginTop: '20px'}}>
                    <div style={{width: '50%', display: 'inline-block'}}>
                        <ContainerEvents/>
                    </div>

                    <div style={{width: '50%', display: 'inline-block'}}>
                        <ContainerRewards/>
                    </div>
                </div>
            </div>
        )
    }
}
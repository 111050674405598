import React from 'react'
import DynamicHistogram from "./DynamicHistogram";
import {Dropdown} from "primereact/components/dropdown/Dropdown";
import SpineVtkMRIDynamicBrowser from "../../../vtk/SpineVtkMRIDynamicBrowser";
import {REQUEST_STATUS_SUCCESS} from "../../../../Constants";
import PropTypes from "prop-types";

/**
 * Component for displaying experiment results in live presenter.
 */
export class DynamicHistogramPanel extends React.Component {

    constructor(){
        super();
        this.state = {
          initializedRoomsFlag:false,
          width:null,
          opts : [{label: "None", value: "none"}],
          plotsState:["none","none"],  // selected rooms in left and right panel
          selected:[null,null]   //selected Data Points in left and right
        };
        ["calcHeight","calcWidth","onChangeLeft","onChangeRight", "onDataPointClick"].forEach(name => { this[name] = this[name].bind(this);});
    }

    componentDidUpdate(prevProps, prevState){
        const {results,resultsState,rightFreq,leftFreq,getImage,clearOverlayImage,slideProperties,active} = this.props;
        const {plotsState,opts,initializedRoomsFlag} = this.state;

        //if slide became  active, width of container needs to be recalculated, so render must be forced
        if(prevProps.active !== active) this.setState({});

        //get Frequencies if there are new results
        if (REQUEST_STATUS_SUCCESS.includes(resultsState) && results!=null ) {
            //when user changed state of any viewer with dropdown
            if (prevState.plotsState[0]!==plotsState[0] || prevState.plotsState[1]!==plotsState[1]){
                //if dropdown set on room
                for(let i=0;i<2;i++) {
                    if (!("none".includes(plotsState[i])) && prevState.plotsState[i] !== plotsState[i]) {
                        let image = {
                            fid: "0052bfbd-774b-4ae4-ad1a-e5bf79034288",//REMOVE IT
                            propertyName: (i === 0) ? "leftFreq" : "rightFreq",
                            tabletExperimentId: slideProperties['experimentId'],
                            roomShortId: plotsState[i]
                        };//MOCK
                        getImage(image);
                    }
                    if ("none".includes(plotsState[i]) && prevState.plotsState[i] !== plotsState[i]) {
                        clearOverlayImage((i === 0) ? "leftFreq" : "rightFreq");
                    }
                }
            }
            //when new data are in results
            if(prevProps.results!==results) {
                //if the viewer is on
                for(let i=0;i<2;i++)
                if (!("none".includes(plotsState[i]))) {
                    //if results for this particular viewer are different
                    if( !(prevProps.results!=null) ||
                        (results[plotsState[i]].results!=null && prevProps.results[plotsState[i]]!=null && prevProps.results[plotsState[i]].results!=null
                        && results[plotsState[i]].results.length > prevProps.results[plotsState[i]].results.length)) {
                        let image = {
                            fid: "0052bfbd-774b-4ae4-ad1a-e5bf79034288",//REMOVE IT
                            propertyName: (i===0)?"leftFreq":"rightFreq",
                            tabletExperimentId: slideProperties['experimentId'],
                            roomShortId: plotsState[i]
                        };//MOCK
                        getImage(image);
                    }
                }
            }
            //when options has been loaded
            if(opts.length===1 && !initializedRoomsFlag) {
                let newOpts  = Object.keys(results).map((el) => {
                        return {label: results[el]['roomName'], value: el}
                    });
                newOpts.push({label: "None", value: "none"});
                if (newOpts.length>1)  //if new options, different than none has been loaded
                    this.setState({opts:newOpts});
                let rooms = ["none","none"];
                if (slideProperties['leftRoomId']!=null)
                    rooms[0]=slideProperties['leftRoomId'];
                if (slideProperties['rightRoomId']!=null)
                    rooms[1]=slideProperties['rightRoomId'];
                this.setState({plotsState:rooms});
            }
        }

    }

    componentWillUnmount(){
        const {clearOverlayImage,slideProperties} = this.props;
        clearOverlayImage("mriImage");
        clearOverlayImage("leftOverlay");
        clearOverlayImage("rightOverlay");
        clearOverlayImage("leftFreq");
        clearOverlayImage("rightFreq");

        //polling termination, if automatic use clearInterval, if button no need for that, if websocket - removeListener
        if (slideProperties['triggerBy']!=null && "automatic".includes(slideProperties['triggerBy']))
             clearInterval(this.polling);
    }

    componentDidMount(){
        const {results, resultsState, getImage, getTabletGameExperimentResults,slideProperties} =this.props;

        // calculate size of container
        this.calcHeight();
        let width = this.calcWidth();
        this.setState({width});

        //get Results
        getTabletGameExperimentResults(slideProperties['experimentId']);

        let opts = this.state.opts;
        if (resultsState === REQUEST_STATUS_SUCCESS) {
            opts = Object.keys(results).map((el) => {
                return {label: results[el]['roomName'], value: el}
            });
            opts.push({label: "None", value: "none"});
            this.setState({opts:opts,initializedRoomsFlag:true});
            let rooms = ["none","none"];
            if (slideProperties['leftRoomId']!=null)
                rooms[0]=slideProperties['leftRoomId'];
            if (slideProperties['rightRoomId']!=null)
                rooms[1]=slideProperties['rightRoomId'];
            this.setState({plotsState:rooms});
        }


        window.addEventListener("resize",this.setState({width:null}));

        let mriImage={
                      fid:"0052bfbd-774b-4ae4-ad1a-e5bf79034288", //TO REMOVE AFTER FULL INTEGRATION
                      propertyName:"mriImage",
                      tabletExperimentId:slideProperties['experimentId']};
        getImage(mriImage);

        //set results polling
        // triggerBy - automatic with interval, manual with some button (NOT YET IMPLEMENTED), websocket - async
        if (slideProperties['triggerBy']!=null && "automatic".includes(slideProperties['triggerBy'])) {
            let refreshRate = (slideProperties['refreshRate_seconds'] != null
                && Number.isInteger(slideProperties['refreshRate_seconds'])) ? slideProperties['refreshRate_seconds'] : 30;
            this.polling =
                setInterval(() => {
                        console.log("<<<<<<<<<<<<<<<<<<<<<<----Poll---->>>>>>>>>>>>>>>>>>>>>>>>>>");
                        getTabletGameExperimentResults(slideProperties['experimentId']);
                    }
                    , refreshRate * 1000);
        }
    }

    onChangeLeft(e) {
        const {clearOverlayImage} = this.props;
        this.setState({
            plotsState:[e.value, this.state.plotsState[1]],
            selected:[null, this.state.selected[1]]
        });
        clearOverlayImage("leftOverlay");
    }
    onChangeRight(e) {
        const {clearOverlayImage} = this.props;
        this.setState({
            plotsState:[this.state.plotsState[0],e.value],
            selected:[this.state.selected[0],null]
        });
        clearOverlayImage("rightOverlay");
    }

    onDataPointClick(e,plotIndex) {
        const {clearOverlayImage,getImage} = this.props;
        this.setState({
            selected:(plotIndex===0)?[e , this.state.selected[1]]:[this.state.selected[0],e]
        });
        if (e!=null) {
            getImage({fid:e.imageFileId, propertyName:(plotIndex===0)?"leftOverlay":"rightOverlay"});
        }
        else {
           clearOverlayImage((plotIndex===0)?"leftOverlay":"rightOverlay");
        }
    }

    calcHeight() {
        if (this.histogramContainer!=null){
        const rect = this.histogramContainer.getBoundingClientRect();
        return rect.height;}
    }
    calcWidth() {
        if (this.histogramContainer!=null){
        const rect = this.histogramContainer.getBoundingClientRect();
        return rect.width;}
    }

    render() {
        const {results, resultsState, leftOverlay, leftFreq, rightFreq, rightOverlay, mriImage, slideProperties} = this.props;
        const {plotsState, selected, opts} = this.state;

        //subtract first and last
        let binSize = (slideProperties['bins'] > 2)
            ? (slideProperties['thresholdMaxValue'] - slideProperties['thresholdMinValue']) / (slideProperties['bins'] - 2)
            : slideProperties['thresholdMaxValue'] - slideProperties['thresholdMinValue'];

        let thresholds = [];
        for (let i = 0; i < (slideProperties['bins'] - 1); i++) {
            thresholds.push(slideProperties['thresholdMinValue'] + binSize * i);
        }


        console.log("News ...render");

        //results property Name
        let resultsPropertyName = slideProperties['resultPropertyName'];

        let processDataForScatter = (roomId) => {
            if (roomId === "none") return null;
            let dataForScatter = [];
            let dataToProcess = (results!=null)?results[roomId]:null;
            if ( !(dataToProcess!=null) || !(dataToProcess['results']!=null) || !(dataToProcess['results'].length > 0)) return null;
            let bins = new Array(thresholds.length + 1).fill(0);

            dataToProcess['results'].forEach((s, index) => {
                let single = {
                    "y": s['results'][resultsPropertyName],
                    "imageFileId": s['results']['imageFileId'],
                    "userId": index + 1,
                    // "img": marv.children[0].children[index % 9].img,
                    "img": '/user/' + s['userEmail'] + '/picture'
                };
                if (s['results'][resultsPropertyName] < thresholds[0]) {
                    single['x'] = ++bins[0];
                    single['yBinMode'] = thresholds[0] - binSize * .5;
                }
                else if (s['results'][resultsPropertyName] >= thresholds[thresholds.length - 1]) {
                    single['x'] = ++bins[bins.length - 1];
                    single['yBinMode'] = thresholds[thresholds.length - 1] + binSize * .5;
                }
                else
                    for (let i = 0; i < thresholds.length; i++)
                        if (thresholds[i] <= s['results'][resultsPropertyName] && s['results'][resultsPropertyName] < thresholds[i + 1]) {
                            single['x'] = ++bins[i + 1];
                            single['yBinMode'] = (thresholds[i + 1] + thresholds[i]) * .5;
                        }
                dataForScatter.push(single)
            });
            return dataForScatter;
        };


        let dataForHistogram = (resultsState === REQUEST_STATUS_SUCCESS) ? processDataForScatter(plotsState[0]) : null;
        let dataForHistogram2 = (resultsState === REQUEST_STATUS_SUCCESS) ? processDataForScatter(plotsState[1]) : null;


        let width = this.calcWidth();

        // let onClick = (id)=>alert(id);

        return (
            <div  className="ui-g-12 ui-g-nopad" >
                <div className="ui-g-6 ui-g-nopad" ref={node => this.histogramContainer = node}>
                    <div style={{textAlign:"center"}}>
                        <Dropdown
                             style={{fontSize:"x-large",width:"auto",marginTop:"5px"}}
                             options={opts}
                             value={plotsState[0]}
                             onChange={this.onChangeLeft}/>
                    </div>
                    <DynamicHistogram
                        length = {(!(dataForHistogram!=null))?-1:dataForHistogram.length}
                        width={width}
                        data={dataForHistogram}
                        colorCat="userId"
                        thresholds={thresholds}
                        onDataPointClick={(e)=>this.onDataPointClick(e,0)}
                        useAvatars = {true}
                        selected = {this.state.selected[0]}
                        viewId = {plotsState[0]}
                        prefixId="left"
                    />
                </div>
                <div  className="ui-g-6 ui-g-nopad" >
                    <div style={{textAlign:"center"}}>
                        <Dropdown
                            style={{fontSize:"x-large",width:"auto",marginTop:"5px"}}
                            options={opts}
                            value={plotsState[1]} onChange={this.onChangeRight}  />
                    </div>
                    <DynamicHistogram
                        length = {(!(dataForHistogram2!=null))?-1:dataForHistogram2.length}
                        width={width}
                        data={dataForHistogram2}
                        colorCat="userId"
                        thresholds={thresholds}
                        onDataPointClick={(e)=>this.onDataPointClick(e,1)}
                        useAvatars={true}
                        selected = {this.state.selected[1]}
                        viewId = {plotsState[1]}
                        prefixId="right"
                    />
                </div>
                <div className="ui-g-12 ui-g-nopad">
                <div className="ui-g-6 ui-g-nopad">
                    <div className="ui-g-6 ui-g-nopad">

                    <SpineVtkMRIDynamicBrowser viewportSize={500}
                                        viewportMode={'axial'}
                                        initialSlices={[0,0,0]}
                                        mriImage={mriImage.data}
                                        mriImageState={mriImage.state}
                                        ovImage={leftOverlay.data}
                                        ovImageState={leftOverlay.state}
                                        ovPort={leftOverlay.port}
                    />
                    </div>
                    <div className="ui-g-6 ui-g-nopad">

                        <SpineVtkMRIDynamicBrowser viewportSize={500}
                                            viewportMode={'axial'}
                                            heatmap='erdc_rainbow_bright'
                                            initialSlices={[0,0,0]}
                                            mriImage={mriImage.data}
                                            mriImageState={mriImage.state}
                                            ovImage={leftFreq.data}
                                            ovImageState={leftFreq.state}
                                            ovPort={leftFreq.port}
                        />
                    </div>

                </div>
                <div className="ui-g-6 ui-g-nopad">
                    <div className="ui-g-6 ui-g-nopad">
                        <SpineVtkMRIDynamicBrowser viewportSize={500}
                                                   viewportMode={'axial'}
                                                   heatmap='erdc_rainbow_bright'
                                                   initialSlices={[0,0,0]}
                                                   mriImage={mriImage.data}
                                                   mriImageState={mriImage.state}
                                                   ovImage={rightFreq.data}
                                                   ovImageState={rightFreq.state}
                                                   ovPort={rightFreq.port}
                                                   />
                    </div>
                    <div className="ui-g-6 ui-g-nopad">
                        <SpineVtkMRIDynamicBrowser viewportSize={500}
                                                   viewportMode={'axial'}
                                                   initialSlices={[0,0,0]}
                                                   mriImage={mriImage.data}
                                                   mriImageState={mriImage.state}
                                                   ovImage={rightOverlay.data}
                                                   ovImageState={rightOverlay.state}
                                                   ovPort={rightOverlay.port}
                        />
                    </div>
                </div>
                </div>
            </div>
        )
    }
}
DynamicHistogramPanel.defaultProps = {

};
DynamicHistogramPanel.propTypes = {
    results:PropTypes.object,
    resultsState:PropTypes.string,
    getTabletGameExperimentResults:PropTypes.func,
    mriImage:PropTypes.object,
    leftOverlay:PropTypes.object,
    leftFreq:PropTypes.object,
    rightOverlay:PropTypes.object,
    rightFreq:PropTypes.object,
    clearOverlayImage:PropTypes.func,
    slideProperties:PropTypes.object //passed from slide executor through own props
};

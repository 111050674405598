import React from "react";
import {ListSwitchType} from "../../root/ListTypeSwitch";
import {SplitButton} from "primereact/components/splitbutton/SplitButton";
import {withRouter} from 'react-router-dom'
import {Dialog} from "primereact/components/dialog/Dialog";
import {ExperimentChoiceView} from "./ExperimentChoiceView";

/** Dumb component responsible for presenting options for displaying project (new experiment button,
 * list displaying mode).
 * @param clbList
 * @param clbTab
 * @param activeTab
 * @param isList
 * @param project
 * @returns {*}
 * @constructor
 */
   class ProjectDetailsMenu extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            dialogVisibility:false
        };
        this.handleSplitButton = this.handleSplitButton.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.onDialogShow = this.onDialogShow.bind(this);
        this.onDialogHide = this.onDialogHide.bind(this);
    }

    /* invoking manually a Link of React Router */
    handleSplitButton (){
        this.props.initExperiment();
    };

    handleClick (){
        this.onDialogShow();
    };
    onDialogShow(){
        this.setState({dialogVisibility:true});
    }
    onDialogHide(){
        this.setState({dialogVisibility:false});
    }
    render(){
        const {clbList, clbTab, activeTab,isList,project} =this.props;
        let slickTabStyle = function(index){
            if (activeTab===index)
                return {fontWeight:'bold',width:'25%',marginRight:'5px'};
            else
                return{width:'25%',marginRight:'10px'};
        };
        let items =  [
            {label: 'From scratch', icon: 'fa fa-plus', command: this.handleSplitButton},
            {label: 'From template', icon: 'fa fa-book', command: () => { console.log("New experiment from template not yet implemented")}},
            {label: 'From duplicate', icon: 'fa fa-copy', command:() => {  console.log("New experiment from duplicate not yet implemented")}}
        ];
    return(
        <div className="projectDetailsMenu">
            <div style={{padding:"16px"}}>
                <div className="reg-headtitle"   style={{ position: "absolute",bottom: "8px",
                    left: "72px", fontSize: "18px"}}>
                    <span onClick={()=>clbTab(0)} style={slickTabStyle(0)}>Project Overview</span>
                    <span onClick={()=>clbTab(1)} style={slickTabStyle(1)}>Co-Investigators</span>
                    <span onClick={()=>clbTab(2)} style={slickTabStyle(2)}>Experiments</span>
                </div>
                    <SplitButton icon="fa fa-plus" label="New experiment" id="newExperiment"
                        model={items}  style={{float: 'right'}} onClick={this.handleClick}/>
                <ListSwitchType clb={clbList} isList={isList}/>
            </div>
            <Dialog header={"New experiment"}
                    visible={this.state.dialogVisibility}
                    modal={true}
                    onHide={this.onDialogHide}
                    style={{width:'60vw'}}
            >
                <ExperimentChoiceView {...this.props} />

            </Dialog>
        </div>
    )}
};

export default withRouter(ProjectDetailsMenu);
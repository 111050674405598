import React from 'react'
import SampleSizeCalculator from "../expDesign/component/SampleSizeCalculator";
import {Graph} from "../directedgraph/Graph";
import {mockWorkflows} from "../expDesign/action/WorkflowAction";
import {WorkflowToGraphAdapter} from "../directedgraph/WorkflowAdapter";
import {connect} from "react-redux";
import {getTaskList} from "../expDesign/action/TaskAction";
import {TaskPropertiesTable} from "../expDesign/component/TaskPropertiesTable";
import {DynamicHistogramPanel} from "../expDesign/component/d3components/DynamicHistogramPanel";
import {create_data} from "../expDesign/component/d3components/DataHelper";
import {ContainerTabletGameExperiment} from "../expDesign/containers/ContainerTabletGameExperiment";
import ReactPaint from "../vtk/paintbrush/ReactPaint";
import {ContainerScriptPanel} from "../expDesign/containers/ContainerScriptPanel";


/**
 * Temporarily used as testing page for various components.
 *
 */

 class News extends React.Component {


    componentDidMount(){
  // const {getTaskList} =this.props;
  // getTaskList('whatever');
   }
    render() {
        console.log("News ...render");

        const slideProperties={
            "experimentId": "80e5873b-c6b6-4ecc-8d75-48dba3fd8520",
                "leftRoomId": "nonexpert",
                "rightRoomId": "expert",
                "refreshRate_seconds": "20",
                "triggerBy": "automatic",
                "bins": 4,
                "thresholdMinValue": 190,
                "thresholdMaxValue": 210,
                "resultPropertyName": "volume"
        };
        return (
            <div  style={{margin: '16px'}}>
               {/*<Graph workflow={WorkflowToGraphAdapter(mockWorkflows[0])} workflowDescription={mockWorkflows[0]}/>*/}
               {/*<ContainerTabletGameExperiment slideProperties={slideProperties}/>*/}
                {/*<ReactPaint style={{background:'tomato'}}/>*/}
                <ContainerScriptPanel />
            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    console.log("tasks", state.expDesign.tasks.tasks);
    return {
        tasks: state.expDesign.tasks
        // experiment:state.expDesign.experiment
    }
};

/**
 *
 * @param dispatch
 * @param ownProps - call params functions
 * @returns {{getWorkflows: function()}}
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getTaskList:(workflowId)=>{dispatch(getTaskList(workflowId))}
    }
};


 export default News = connect( mapStateToProps, mapDispatchToProps)(News);
import {EXPDESIGN_EXPERIMENT_SAVE_SUCCESS, EXPDESIGN_GLOBAL_DEFINITION} from '../action/actionType'

export const globalDefReducer = (state = {  name: '', question: '', description:'' }, action) => {
	switch(action.type) {
        case EXPDESIGN_GLOBAL_DEFINITION:
            return Object.assign({}, state, {
                projectId: action.def.projectId,
                name: action.def.name,
                question: action.def.question,
                description: action.def.description
            });
		default:
			return state;
	}
};
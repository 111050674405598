import {HOST_URL, VARIABLE_PROPERTIES_NAMES} from "../../../Constants";

export const mockWorkflows= [{
    "name": "Freesurfer Sub-Cortical Volumes Measurement",
    "description": "Provides the volumes of subcortical regions of interest",
    "version": "1.6",
    "privacy": "PUBLIC",
    "inputs": {
        "t1w": {
            "type": "imageFile",
            "fileType": "nifti",
            "typeImageFile": "T1w"
        }

    },
    "constants": {
        "freesurfer-lookuptable": {
            "type": "lookupTable",
            "value": "9efa189e-5f88-44db-bd0a-8ace15498cb7"

        }

    },

    "outputs": {

        "volumes_measurement": {

            "type": "imagingVariableGroup",

            "measurementType": "volume",

            "lookupTableId": "9efa189e-5f88-44db-bd0a-8ace15498cb7"

        }

    },

    "tasks": {

        "Freesurfer recon steps 1 and 2": {

            "description": "Performs the two first steps of freesurfer reconstruction in order to get the subcortical segmentation",

            "workflowTaskId": "1ce1d8f7e9ded99ad2014467cf00575b"

        },

        "Subcortical volumes measurement": {

            "description": "Measure the volume of the region of interests",

            "workflowTaskId": "1ce1d8f7e9ded99ad2014467cf0103aa"

        }

    },

    "connectionsBetweenWorkflowAndTasks": {

        "inputs": {

            "01": {

                "workflowInput": "t1w",

                "task": {

                    "name": "Freesurfer recon steps 1 and 2",

                    "input": "t1w"

                }

            },

            "02": {

                "workflowInput": "freesurfer-lookuptable",

                "task": {

                    "name": "Subcortical volumes measurement",

                    "input": "lookuptable"

                }

            }

        },

        "outputs": {

            "01": {

                "workflowOutput": "volumes_measurement",

                "task": {

                    "name": "Subcortical volumes measurement",

                    "output": "measurement"

                }

            }

        }

    },

    "connectionsBetweenTasks": {

        "01": {

            "fromTask": {

                "name": "Freesurfer recon steps 1 and 2",

                "output": "aseg"

            },

            "toTask": {

                "name": "Subcortical volumes measurement",

                "input": "labelmap"

            }

        }

    },

    "transitions": {

        "01": {

            "to": [

                "Freesurfer recon steps 1 and 2"

            ]

        },

        "02": {

            "from": [

                "Freesurfer recon steps 1 and 2"

            ],

            "to": [

                "Subcortical volumes measurement"

            ]

        },

        "03": {

            "from": [

                "Subcortical volumes measurement"

            ]

        }

    },

    "creationDate": "Thu Nov 15 2018 09:48:16 GMT-0500 (EST)",

    "uuid": "1ce1d8f7e9ded99ad2014467cf00ee56"

}];
import axios from 'axios'

import {
    WORKFLOW_LIST_REQUEST,
    WORKFLOW_LIST_FAIL,
    WORKFLOW_LIST_SUCCESS,
    EXPDESIGN_WORKFLOW_SET_SUCCESS,
    EXPDESIGN_WORKFLOW_SET_FAIL,
    EXPDESIGN_WORKFLOW_SET_REQUEST
} from './actionType'


const requestWorkflowList = (variable) => ({
    type: WORKFLOW_LIST_REQUEST,
    variable
});

const errorWorkflowList = (err,variable) => ({
    type: WORKFLOW_LIST_FAIL,
    err,
    variable
});

const successWorkflowList = (workflows,variable) => ({
    type: WORKFLOW_LIST_SUCCESS,
    workflows,
    variable
});

const setWorkflowRequest = (workflow,variable) => ({
    type: EXPDESIGN_WORKFLOW_SET_REQUEST,
    workflow,
    variable
});

const setWorkflowError = (err,variable) => ({
    type: EXPDESIGN_WORKFLOW_SET_FAIL,
    err,
    variable
});

const setWorkflowSuccess= (workflow,variable) => ({
    type: EXPDESIGN_WORKFLOW_SET_SUCCESS,
    workflow,
    variable
});

// TODO Add dispatching request and error handling
// Get user list (local version)
export const getWorkflowList = (variable) => {
    console.log('workflowList for variable',variable,mockWorkflows);
    return dispatch => {
        dispatch(requestWorkflowList(variable));
        if (variable['metricAnnotation']['preferredName']==='Volume'){
            dispatch(successWorkflowList(VOLUME_WORKFLOW,variable)); // TODO Uncomment me
        }
        else

            dispatch(successWorkflowList([],variable));
        //  axios.post(HOST_URL + '/workflow/'+variable['entityType']+'/object')
        //     .then(response => {
        //         console.log('WorkflowAction.js :: getWorkflowList :: response ::', response)
        //         if (response.status !== 200) {
        //             dispatch(errorWorkflowList(error,variable))
        //         } else {
        //             dispatch(successWorkflowList(response.data,variable));
        //         }
        //     }).catch(error => {
        //     console.log('Workflow Action.js :: getWorkflowList :: error ::', error);
        //     dispatch(errorWorkflowList(error,variable))
        // });
    }
};





/**
 * Action Creator for
 * @param workflow
 * @returns {function(*)}
 */

export const setWorkflowActionCreator = (workflow,variable) =>{
    console.log('workflowList for variable',variable,mockWorkflows);
    return dispatch => {
        dispatch(setWorkflowRequest(workflow,variable));
        dispatch(setWorkflowSuccess(workflow,variable));
    }
};



//Get users
// export const getUserList = () => {
// 	return dispatch => {
// 		dispatch (requestUserList())
// 		return axios.get('http://localhost:3000/users'
// 		).then(response => {
// 			if(response.statusCode !== 200) {
// 				dispatch (errorUserList(response.data));
// 				return Promise.reject(response.data);
// 			} else {
// 				dispatch (successUserList(response.data));
// 			}
// 		}).catch(err => dispatch (errorUserList(err)));
// 	};
// };
 const VOLUME_WORKFLOW = [{

     "_id": "6575058bd972280d7cd41c3217f579c3",

     "_rev": "3-6bc0a8a1adcb9ed39f328d9faad795b3",

     "version": "0.1",

     "name": "Workflow Lesion Segmentation - NeuroRx",

     "description": "Worfklow to segment and measure the volume of lesions on T2 images. No implementation of this workflow is available in SPINE.",

     "owner": "charles.guttmann@bwh.harvard.edu",

     "privacy": "PUBLIC",

     "docType": "workflow",

     "type": "externalWorkflow",

     "constants": {},

     "inputs": {

         "t2": {

             "description": "Image to be annotated",

             "type": "imageEntityInOut",

             "filter": {},

             "imageEntityInOut_FileFormat": "nii",

             "isList": false,

             "imageEntityInOut_Type": "t2w",

             "name": "T2w"

         }

     },

     "outputs": {

         "lesions": {

             "type": "roiWithAnnotationsInOut",

             "isList": false,

             "name": "Lesions",

             "typeROI": "EXPLICIT",

             "annotations": [

                 {

                     "typeAnnotationForm": "MULTIPLE_CHOICE_SINGLE_ANSWER",

                     "propertiesAnnotation": {

                         "isOntologyBased": true,

                         "typeAnnotationOptions": "TEXT",

                         "ontologyId": "https://bioportal.bioontology.org/ontologies/SNOMEDCT",

                         "options": [

                             {

                                 "value": "http://purl.bioontology.org/ontology/SNOMEDCT/52988006",

                                 "valueToDisplay": "Lesion"

                             }

                         ]

                     }

                 },

                 {

                     "typeAnnotationForm": "MULTIPLE_CHOICE_SINGLE_ANSWER",

                     "propertiesAnnotation": {

                         "isOntologyBased": true,

                         "typeAnnotationOptions": "TEXT",

                         "ontologyId": "https://bioportal.bioontology.org/ontologies/FMA",

                         "options": [

                             {

                                 "value": "http://purl.org/sig/ont/fma/fma50801",

                                 "valueToDisplay": "Brain"

                             }

                         ]

                     }

                 },

                 {

                     "typeAnnotationForm": "OPEN_ENDED",

                     "propertiesAnnotation": {

                         "typeAnnotationOptions": "NUMBER",

                         "options": "VOLUME"

                     }

                 }

             ]

         }

     },

     "tasks": {

         "annotation-and-measurement": {

             "name": "Annotation",

             "description": "Automatic segmentation of lesions in the brain together with calculation of their volume.",

             "taskId": ""

         }

     },

     "connectionsBetweenWorkflowAndTasks": {

         "inputs": {

             "01": {

                 "workflowInput": "t2",

                 "task": {

                     "name": "annotation-and-measurement",

                     "input": "image"

                 }

             }

         },

         "outputs": {

             "01": {

                 "workflowOutput": "rois-and-annotations",

                 "task": {

                     "name": "annotation-and-measurement",

                     "output": "rois-and-annotations"

                 }

             }

         }

     },

     "connectionsBetweenTasks": {},

     "transitions": {

         "01": {

             "to": [

                 "annotation-and-measurement"

             ]

         },

         "02": {

             "from": [

                 "annotation-and-measurement"

             ]

         }

     }

 }];

export const mockActivities = [
    {
        "uuid": "2802b284-da5b-4283-50bb-f338bdb77a77",
        "experiment": {uuid: "3130bbbc-cf76-49cd-a5cf-4e8b214f3f1c", name: "Does  volume of WM lesions predict EDSS? "},
        "dueDate": "Completed",
        "task": {uuid: "9a2510f8daeb1c8b38bf5db8a4003d57", name: "Review experiment"},
        "project": {uuid: "9a2510f8daeb1c8b38bf5db8a4002707", name: "IPMSA - EPITOME"}
    },

    {
        "uuid": "2802b284-da5b-4283-50bb-f338bdb77a79",
        "experiment": {uuid: "3130bbbc-cf76-49cd-a5cf-4e8b214f3f1d", name: "Is EDSS correlated with volume of WM Lesions?"},
        "dueDate": "May 21, 2019",
        "task": {uuid: "9a2510f8daeb1c8b38bf5db8a4002707", name: "White Matter Lesion Segmentation"},
        "project": {uuid: "9a2510f8daeb1c8b38bf5db8a4002707", name: "IPMSA - EPITOME"}
    },
    {
        "uuid": "2802b284-da5b-4283-50bb-f338bdb77a80",
        "experiment": {uuid: "3130bbbc-cf76-49cd-a5cf-4e8b214f3f1d", name: "Is EDSS correlated with number of WM Lesions?"},
        "dueDate": "June 02, 2019",
        "task": {uuid: "9a2510f8daeb1c8b38bf5db8a4000f38", name: "White Matter Lesion Annotation"},
        "project": {uuid: "9a2510f8daeb1c8b38bf5db8a4002707", name: "IPMSA - EPITOME"}
    }

];
import React from 'react'
import PropTypes from 'prop-types'
import {Link} from "react-router-dom";


export default class ProjectNamesList extends React.Component {

    componentWillMount() {
        const {getProjects} = this.props;
        getProjects();
    }

    render() {

        const {projects} = this.props;
        const divRowStyle = {display: 'table-row', width: 'auto', clear: 'both', color: '#009E9B', fontSize: '16px'};
        const divColStyle = {
            float: 'left', display: 'table-column', width: '100%',
            paddingTop: '5px', paddingBottom: '5px', paddingLeft: '5px'
        };
        const projectNodes = projects.map((p) =>
            <div style={divRowStyle} key={p.uuid}>
                <Link to={`/project/` + p.uuid }>
                    <span style={divColStyle}>{p.name}</span>
                </Link>
            </div>
        );

        return (
            <div style={{display: 'table', width: '70%', border: '1px solid #97acbd', borderSpacing: '5px'}}>
                {projectNodes}
            </div>
        );
    }

};

ProjectNamesList.propTypes = {
    getProjects: PropTypes.func.isRequired,
    projects: PropTypes.array.isRequired,
};




import {EXPERIMENT_PROPERTY_NAME__PROJECT_ID, EXPERIMENT_PROPERTY_NAME__UUID, HOST_URL} from "../../../Constants";
import {
    EXPERIMENT_LIST_FAIL, EXPERIMENT_LIST_REQUEST, EXPERIMENT_LIST_SUCCESS, EXPERIMENT_REMOVE_FAIL,
    EXPERIMENT_REMOVE_SUCCESS,
    SET_EXPERIMENT_SORTER,
    SET_EXPERIMENT_VISIBILITY_FILTER
} from "./actionType";
import store from "../../Store";
import axios from "axios/index";


const requestExperimentList = (projectId) => ({
    type: EXPERIMENT_LIST_REQUEST,
    projectId
});

const successExperimentList = (experimentList,projectId) => ({
    type: EXPERIMENT_LIST_SUCCESS,
    projectId,
    experimentList
});

const failExperimentList = (err,projectId) => ({
    type: EXPERIMENT_LIST_FAIL,
    projectId,
    err
});

const failExperimentRemove = (err,experimentId) => ({
    type: EXPERIMENT_REMOVE_FAIL,
    experimentId,
    err
});

const successExperimentRemove = (experimentId) => ({
    type: EXPERIMENT_REMOVE_SUCCESS,
    experimentId
});

/**
 * Action Creator function for fetching experiment list.
 * @param projectId -
 * @returns {function(*)}
 */
export const getExperimentList = (projectId) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        dispatch(requestExperimentList(projectId));
        axios.get(HOST_URL + '/project/'+projectId+'/experiment')
            .then(response => {
                console.log('ExperimentAction.js :: getExperimentList :: response ::', response)
                if (response.status !== 200) {
                    dispatch(failExperimentList(err,projectId))
                } else {
                    dispatch(successExperimentList(response.data,projectId))
                }
            }).catch(error => {
            console.log('ExperimentAction.js :: getExperimentList :: error ::', error);
            dispatch(failExperimentList(error,projectId))
        });
    }
};

/**
 * Action Creator function deleting Experiment.
 * @param experiment - object to Remove
 * @returns {function(*)}
 */
export const deleteExperiment = (experiment) => {
    return dispatch => {
        axios.defaults.headers.common['Authorization'] = store.getState().auth.token_bearer;
        console.log('ExperimentAction.js :: deleteExperiment :: experiment ::', experiment);

        axios.delete(HOST_URL + '/experiment/'+experiment[EXPERIMENT_PROPERTY_NAME__UUID])
            .then(response => {
                console.log('ExperimentAction.js :: deleteExperiment :: response ::', response);
                if (response.status !== 200) {
                    dispatch(failExperimentRemove(err,experiment[EXPERIMENT_PROPERTY_NAME__UUID]))
                } else {
                    dispatch(successExperimentRemove(experiment[EXPERIMENT_PROPERTY_NAME__UUID]));
                    dispatch(getExperimentList(experiment[EXPERIMENT_PROPERTY_NAME__PROJECT_ID]))
                }
            }).catch(error => {
            console.log('ExperimentAction.js :: getExperimentList :: error ::', error);
            dispatch(failExperimentRemove(error,experiment[EXPERIMENT_PROPERTY_NAME__UUID]))
        });

        // if (experiment!=null && experiment[EXPERIMENT_PROPERTY_NAME__PROJECT_ID]!=null)

    }
};


export function setVisibilityFilter(filter) {
    return { type: SET_EXPERIMENT_VISIBILITY_FILTER, filter }
}

export function setSorter(sorter) {
    return { type: SET_EXPERIMENT_SORTER, sorter }
}
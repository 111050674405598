export const PRESENTATION_LIST_REQUEST = "PRESENTATION_LIST_REQUEST";
export const PRESENTATION_LIST_SUCCESS = "PRESENTATION_LIST_SUCCESS";
export const PRESENTATION_LIST_FAIL = "PRESENTATION_LIST_FAIL";

export const PRESENTATION_CREATE = "PRESENTATION_CREATE";
export const PRESENTATION_UPDATE = "PRESENTATION_UPDATE";

export const SLIDE_LIST_REQUEST = "SLIDE_LIST_REQUEST";
export const SLIDE_LIST_SUCCESS = "SLIDE_LIST_SUCCESS";
export const SLIDE_LIST_FAIL = "SLIDE_LIST_FAIL";

export const SLIDE_CREATE = "SLIDE_CREATE";
export const SLIDE_UPDATE = "SLIDE_UPDATE";
/**
 * Helper function for processing data to format readable to browser.
 * @param buffer
 * @returns {string}
 */
export function arrayBufferToBase64(buffer) {
    let binary = '';
    let bytes = [].slice.call(new Uint8Array(buffer));

    bytes.forEach((b) => binary += String.fromCharCode(b));

    return window.btoa(binary);
}


/**
 *  Helper function for processing PNG from R. PNGs (from R) come in hex; need to convert to base 64.
 *  Example after DataCamp code, see:
 *  @link  https://github.com/datacamp/datacamp-light/blob/master/src/helpers/plot.ts
 *  @param str
 *  @returns {string}
 */
const hexToBase64 = (str) => {
    // Generate hex array from hex string
    const hexArray = str
        .replace(/\r|\n/g, "")
        .replace(/([\da-fA-F]{2}) ?/g, "0x$1 ")
        .replace(/ +$/, "")
        .split(" ");

    const CHUNK_SIZE = 0x8000; // Arbitrary number
    let index = 0;
    const length = hexArray.length;
    let result = "";
    let slice;
    // Passing too many arguments into fromCharCode gives `Maximum call stack size exceeded`.
    // We divide the hex array into pieces and pass these.
    while (index < length) {
        slice = hexArray.slice(index, index + CHUNK_SIZE);
        result += String.fromCharCode.apply(null, slice);
        index += CHUNK_SIZE;
    }
    return window.btoa(result);
};
import {
    ACTIVITY_LIST_FAIL,
    ACTIVITY_LIST_REQUEST, ACTIVITY_LIST_SUCCESS, SET_ACTIVITY_SORTER, SET_ACTIVITY_VISIBILITY_FILTER
} from '../action/actionType'
import {combineReducers} from "redux";



const setVisibilityFilter = (state, action) => {

         return Object.assign({}, state, {
             visibilityFilter: action.filter,
         });

};

const setSorter = (state, action) => {

    return Object.assign({}, state, {
        sorter: action.sorter,
    });

};


const activityListReducer = (state = {activitiesState: '', activities: [], visibilityFilter:'SHOW_ALL', sorter:'SORT_BY_NAME_DESC'}, action) => {
    switch (action.type) {
        case ACTIVITY_LIST_REQUEST:
            return Object.assign({}, state, {
                activitiesState: 'requested',
            });
        case ACTIVITY_LIST_SUCCESS:
            return Object.assign({}, state, {
                activitiesState: 'success',
                activities: action.activities
            });

        case ACTIVITY_LIST_FAIL:
            return Object.assign({}, state, {
                activitiesState: action.err,
            });

        case SET_ACTIVITY_VISIBILITY_FILTER:
            return setVisibilityFilter(state, action);

        case SET_ACTIVITY_SORTER:
            return setSorter(state, action);

        default:
            return state;
    }
};

const activityReducer = (state = {activityState: '', activity: {}}, action) => {
    switch (action.type) {
        case 'ACTIVITY_REQUEST':
            return Object.assign({}, state, {
                activityState: 'requested'
            });
        case 'ACTIVITY_FAIL':
            console.log('Project request failed');
            return Object.assign({}, state, {
                activityState: 'failure',
                activity: null
            });
        case 'ACTIVITY_SUCCESS':
            console.log('Project request succeeded');
            return Object.assign({}, state, {
                activityState: 'success',
                activity: action.activity
            });
        default:
            return state;
    }
};

export const ActivityReducer = combineReducers({
    activities: activityListReducer,
    activity: activityReducer
});
import React from "react";


/**
 * Dumb component with template for How Tab (first page).
 * @returns {*}
 * @constructor
 */
export const HowTab=()=> {

    return(
    <div >
        <h3>How to use SPINE?</h3>
    </div>
);
};
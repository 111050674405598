// Notes about time frames:
// Reference Points in Time
// Points
// Subject: Date of birth
// Subject: First visit of

// Time to events variable
// Time to death


// https://www.biooncology.com/clinical-trials/efficacy-endpoints.html
// https://www.researchgate.net/post/What_is_the_difference_between_an_end-point_and_an_outcome_measure2
//https://en.wikipedia.org/wiki/Clinical_endpoint

// https://www.jsonschema.net/


import {EXPDESIGN_SET_TIMEFRAME} from "./actionType";
import {clearDataset} from "./StatisticalModelAction";

export const TF_MOCK =[
    {type:"absolute",ts:"dob", variableId:"CLINICAL_BLCVA_100_Total",before:5,after:30,median:25,unit:"years"},
    {type:"relative",ts:"CLINICAL_9HPT_DomHand_Trial_2_sec", variableId:"CLINICAL_9HPT_DomHand_Trial_1_sec",before:5,after:30,unit:"years"}
];


const updateTimeFrame = timeframe => ({
    type: EXPDESIGN_SET_TIMEFRAME,
    timeframe
});


export const updateTimeFrameActionCreator = timeframe => (
    dispatch => {
        dispatch(updateTimeFrame(timeframe));
        dispatch(clearDataset());
    }
);
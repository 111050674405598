import React from 'react';
import YouTube from 'react-youtube';
import {ContainerTabletGameExperiment} from "../../expDesign/containers/ContainerTabletGameExperiment";

export const SLIDE_PROPERTY_NAME__SLIDE_TYPE= "slideType";
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__IMAGE = 'IMAGE';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__VIDEO = 'VIDEO';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__WEBSITE = 'WEBSITE';
export const SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT = 'EXPERIMENT';
export const SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES = 'slideProperties';

class playSlidesAuto extends React.Component{

    constructor() {
        super();
        this.state = {
            slideIndex: 0,
            flag:true
        };
    };

    componentWillMount() {
        const script = document.createElement("script");
        script.src = "https://unpkg.com/x-frame-bypass";
        document.body.appendChild(script);
    }
    
    tick(length) {
        this.setState({slideIndex: (this.state.slideIndex+1)%length, flag:true})

    }

    componentDidUpdate(prevProps,prevState) {

            if (this.props.slides != null && this.props.slides.length > 0) {
                let timings = [];
                if(this.state.flag) {
                    this.setState({ flag:false});
                    this.props.slides.forEach(function (elem) {
                        timings.push(5)
                    });
                    // 60 * parseInt(elem.timeMinutes) + parseInt(elem.timeSeconds)
                    setTimeout(() => this.tick(this.props.slides.length), timings[this.state.slideIndex] * 1000);
                }
            }

    }

    componentDidMount() {

        const {getSlides} = this.props;
        getSlides(this.props.match.params['id']);

    }

    render() {

        const playerOpts = {
            height: '100%',
            width: '100%',
            playerVars: { // https://developers.google.com/youtube/player_parameters
                rel:0,
                autoplay: 1,
                mute:1
            }
        };
        
        const items = (this.props.slides != null && this.props.slides.length > 0)
         ? this.props.slides.sort((a,b) => {return parseInt(a.orderNumber) - parseInt(b.orderNumber)}).map((item,index) => {
                const title_style = {position: 'fixed', left: "60px", top:"2px", color: "white", zIndex:"1001"};
                const slide_style = (index===this.state.slideIndex)?
                    {width: '100%', height: '100%', position: 'relative',  display:"block"}:
                    {width: '100%', height: '100%', position: 'relative',  display:"none"};

            switch (item[SLIDE_PROPERTY_NAME__SLIDE_TYPE].toUpperCase()) {

                case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__WEBSITE:
                return (<React.Fragment key = {item.orderNumber}>
                    <div style={slide_style} className="slide_div fade" >
                        <h2 style={title_style}>{item.title}</h2>
                        <iframe is="x-frame-bypass" src={item.url} height="100%" width="100%"/>
                    </div>
                </React.Fragment>);

                case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__VIDEO:
                    {
                    let url = new URL(item[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['url']); // i am parsing url here, since i need 1 param only
                    return (<React.Fragment key={item.orderNumber}>
                        <div style={slide_style} className="slide_div fade">
                            <h2 style={title_style}>{item.title}</h2>
                            <YouTube videoId={url.searchParams.get('v')} opts={playerOpts} height="100%" width="100%"/>
                        </div>
                    </React.Fragment>);
                    }
                case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__EXPERIMENT:
                return (<React.Fragment key = {item.orderNumber}>
                    <div style={slide_style} className="slide_div fade">
                        <h2 style={title_style}>{item.title}</h2>
                        <ContainerTabletGameExperiment slideProperties={item['slideProperties']} active={index===this.state.slideIndex}/>
                    </div>
                </React.Fragment>);

                case SLIDE_PROPERTY_VALUE_SLIDE_TYPE__IMAGE:
                return (<React.Fragment key = {item.orderNumber}>
                    <div style={slide_style} className="slide_div fade">
                        <h2 style={title_style}>{item.title}</h2>
                        <img src={item[SLIDE_PROPERTY_NAME_SLIDE_PROPERTIES]['url']} height="100%" width="100%"/>
                        {/* <p>{item.desc}</p> */}
                    </div>
                </React.Fragment>)
            }
        }):null;

        // const slide_dots = (this.props.slides != null && this.props.slides.length > 0)
        // ? this.props.slides.map((item,index) =>
        //     {
        //         const whichClass = (index===this.state.slideIndex)?"dot active-dot":"dot";
        //         return(
        //             <React.Fragment key = {item.title}>
        //                 <span className = {whichClass}/>
        //             </React.Fragment>
        //         )
        //     }
        // ):null;


        console.log('Autoplay slides... render');
        return(
            <React.Fragment>
            {this.props.slides && this.props.slides.length>0 &&
            <div className = "slideshow-div">
                {items}
                {/*CG requested removal of dots*/}
                {/*<div style={{textAlign: "center", position: "absolute", top: "96%", width: "100%"}}>*/}
                    {/*{slide_dots}*/}
                {/*</div>*/}
            </div>}
            </React.Fragment>
        )
    }
};

export default playSlidesAuto;
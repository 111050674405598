import store from "../../Store";
import {
    MODELS_FAIL, MODELS_REQUEST, MODELS_SUCCESS,
    SET_MODELS_SORTER, SET_MODELS_VISIBILITY_FILTER
} from "./actionType";
import {modelMockList} from "../tests/ModelMocks";

/**
 * An action informing the reducers that the request began.
 * @returns {{type}}
 */
const requestModelsList = () => ({
    type: MODELS_REQUEST
});

/**
 * An action informing the reducers that the request failed.
 * @param err
 * @returns {{type}}
 */
const errorModelsList = err => ({
    type: MODELS_FAIL,
    err
});

/**
 * An action informing the reducers that the request finished successfully.
 * @param models
 * @returns {{type, variablesList: *}}
 */

const successModelsList = (models) => ({
    type: MODELS_SUCCESS,
    models
});

/**
 * Filter for the list of ALL models.
 * @param filter
 * @returns {{type, filter: *}}
 */
export function setVisibilityFilter(filter) {
    return { type: SET_MODELS_VISIBILITY_FILTER, filter }
}

/**
 * Sorter for the list of ALL models.
 * @param sorter
 * @returns {{type, filter: *}}
 */

export function setSorter(sorter) {
    return { type: SET_MODELS_SORTER, sorter }
}


/** Action for providing mock data (prepared for quick integration)

 */
const successMockModelsList = () => ({
    type: MODELS_SUCCESS,
    models:modelMockList
});

export const getModelsListActionCreator = () => {
    return dispatch => {
        dispatch(requestModelsList());
        console.log('ModelAction.js :: getModelsListActionCreator :: Before axios request');
        let config = {
            headers: {'Authorization': "bearer" + store.getState().auth.token_bearer}
        };
        dispatch(successMockModelsList());
        // return axios.get(HOST_URL + '/model',config
        // ).then(response => {

        //     console.log('StatisticalModelAction.js :: getModelsList :: response', response);
        //     if (response.status !== 200) {
        //         dispatch(errorModelsList(response.data));
        //         return Promise.reject(response.data);
        //     } else {
        //         console.log('StatisticalModelAction.js :: getModelsList :: Before success');
        //         dispatch(successModelsList(response.data));
        //     }
        // }).catch(err => dispatch(successMockModelsList(err)));//Just to test
        // .catch(err => dispatch(errorModelsList(err)));
    };
};
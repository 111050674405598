import React from 'react';
import {Button} from 'primereact/components/button/Button';
import PropTypes from 'prop-types';
import {Dialog} from "primereact/components/dialog/Dialog";
import {ruleRunner, run} from "../../helpers/validation/ruleRunner";
import {isEmail, minLength, mustMatch, required} from "../../helpers/validation/rules";
import InputTextWithValidation from "../../helpers/validation/InputTextWithValidation";
import {Dropdown} from "primereact/components/dropdown/Dropdown";


const fieldValidations = [
    ruleRunner("lastname", "Last name", required),
    ruleRunner("name", "First Name", required),
    ruleRunner("email", "Email", required, isEmail),
    ruleRunner("password", "Password", required, minLength(6)),
    ruleRunner("confirmpassword", "Confirm password", mustMatch("password", "Password"))
];

export default class Register extends React.Component {

    constructor(props) {
        super(props);
        //const redirectRoute = this.props.location.query.next || '/';

        this.state = {
            name: '',
            lastname: '',
            email: '',
            password: '',
            confirmpassword: '',
            profession: 'medical',
            specialty: 'neurology',
            eduLevel: 'highschool',
            experience: 'none',
            match: false,
            registerDialogVisible: false,
            showErrors: false,
            validationErrors: { },
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.onHide = this.onHide.bind(this);
        this.errorFor = this.errorFor.bind(this);
    }

    componentWillMount(){
        // Run validations on initial state
        this.setState({validationErrors: run(this.state, fieldValidations)});
    }

    /**required by validation */
    errorFor(field) {
        return this.state.validationErrors[field] || "";
    }

    handleChange(event) {
        console.log('setState', event.target.value);
        this.setState({
            [event.target.id]: event.target.value
        });
    }


    onHide() {
        this.setState({registerDialogVisible: false});
    }

    handleCreate() {
        let newState = this.state;
        newState.validationErrors = run(newState, fieldValidations);
        newState.showErrors = true;
        this.setState(newState);
        if (Object.keys(newState.validationErrors).length > 0) return null;
        this.setState({registerDialogVisible: true});
    }


    handleRegisterSubmit(event) {
        if (this.state.password == this.state.confirmpassword) {
            this.setState({
                match: false
            });
            const creds = {
                email: this.state.email,
                password: this.state.password,
                name: this.state.name,
                lastname: this.state.lastname,
                profession: this.state.profession,
                specialty: this.state.specialty,
                eduLevel: this.state.eduLevel,
                experience: this.state.experience
            };
            console.log('creds: ', creds);
            console.log('props:', this.props);
            // Get the function from props. This is available thanks to mapDispatchToProps
            // method in the container ../containers/ContainerRegiser.js
            const {registerUser} = this.props;
            registerUser(creds);
        } else {
            this.setState({
                match: true
            })
        }

        console.log('state: ', this.state);
        event.preventDefault();
    }


    render() {
        let professionOptions=[{value:"medical",label:"Medical"},{value:"biology",label:'Biology'},{value:"other", label:"Other"}];
        let specialtyOptions=[{value:"neurology",label:"Neurology"},{value:"radiology",label:'Radiology'},{value:"other", label:"Other"}];
        let educationOptions=[{value:"highschool",label:"Highschool"},{value:"undergraduate", label:"Undergraduate degree (bachelor degree)"},
            {value:"master",label:"Masters"},{value:"phd",label:"PhD"},{value:"md", label:"MD"}, {value:"mdphd",label:"MD and PhD"},
            {value:"boardspecialist",label:"Board certified specialist"}];

        console.log('Register.js ::: render.props:', this.props);
        return (

                <React.Fragment>
                    <span className="reg-headtitle">Create your Account</span>

                    <div className="ui-g-12 ui-g-nopad reg-pad-bot" style={{width:"70%",marginTop:'16px'}}>
                        <label className="reg-plaintext">First name: </label>
                        <InputTextWithValidation
                            id="name"
                            style={{width: '100%'}}
                            onFieldChanged={this.handleChange}
                            showError={this.state.showErrors}
                            errorText={this.errorFor("name")}
                        />
                    </div>

                    <div className="ui-g-12 ui-g-nopad reg-pad-bot" style={{width:"70%"}}>
                        <label className="reg-plaintext">Last name: </label>
                        <InputTextWithValidation
                            id="lastname"
                            style={{width: '100%'}}
                            onFieldChanged={this.handleChange}
                            showError={this.state.showErrors}
                            errorText={this.errorFor("lastname")}
                        />
                    </div>

                    <div className="ui-g-12 ui-g-nopad reg-pad-bot" style={{width:"70%"}}>
                        <label className="reg-plaintext">Email Address: </label>
                        <InputTextWithValidation
                            type={'email'}
                            id="email"
                            style={{width: '100%'}}
                            onFieldChanged={this.handleChange}
                            showError={this.state.showErrors}
                            errorText={this.errorFor("email")}
                        />
                    </div>

                    <div className="ui-g-12 ui-g-nopad reg-pad-bot" style={{width:"70%"}}>
                        <label className="reg-plaintext">Password: </label>
                        <div style={{width: '100%'}}>
                            <InputTextWithValidation
                                id="password"
                                type="password"
                                showError={this.state.showErrors}
                                errorText={this.errorFor("password")}
                                style={{width: '100%'}}
                                onFieldChanged={this.handleChange}
                                className="ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-filled"
                            />
                        </div>
                    </div>

                    <div className="ui-g-12 ui-g-nopad reg-pad-bot" style={{width:"70%"}}>
                        <label className="reg-plaintext">Confirm password: </label>
                        <div style={{width: '100%'}}>
                            <InputTextWithValidation
                                   type="password"
                                   id="confirmpassword"
                                   showError={this.state.showErrors}
                                   errorText={this.errorFor("confirmpassword")}
                                   onFieldChanged={this.handleChange}
                                   style={{width: '100%'}}
                                   className="ui-inputtext ui-state-default ui-corner-all ui-widget ui-state-filled"
                            />
                            {/*{this.state.match && <label style={{color: 'red'}}>*/}
                                {/*<small>Password not matching</small>*/}
                            {/*</label>}*/}
                        </div>
                    </div>
                    <Dialog header={"Help SPINE"}
                            visible={this.state.registerDialogVisible}
                            modal={true}
                            onHide={this.onHide}
                            style={{width:'250px'}}
                    >
                        <div style={{marginBottom:"20px"}}>Help us to improve the SPINE experience by filling out the following information:</div>
                        <div className="ui-g-12 ui-g-nopad reg-pad-bot">
                            <div className="reg-plaintext">Profession: </div>
                            <Dropdown name='profession' value={this.state.profession} onChange={(e)=>{this.setState({profession:e.value})}}
                            options={professionOptions} style={{width:'100%'}}/>

                            <div className="reg-plaintext">Specialty: </div>
                            <Dropdown name='specialty' value={this.state.specialty} onChange={(e)=>{this.setState({specialty:e.value})}}
                            options={specialtyOptions} style={{width:'100%'}}/>

                            <div className="reg-plaintext">Highest level of education: </div>
                            <Dropdown name='eduLevel' value={this.state.eduLevel} onChange={(e)=>{this.setState({eduLevel:e.value})}}
                                      options={educationOptions}  style={{width:'100%'}}/>
                            <div className="reg-plaintext">Familiarity with Neuroimaging of MS: </div>
                            <Dropdown name='experience' value={this.state.experience} onChange={(e)=>{this.setState({experience:e.value})}}
                                options={[{value:"none", label:"None"},{value:"medium",label:"Medium"},{value:"expert", label:"Expert"}]}
                                      style={{width:'100%'}}
                            />
                        </div>
                        <Button
                            label="Skip"
                            onClick={this.onHide}
                            style={{float:'right'}}
                        />
                        <Button
                            label="Submit"
                            onClick={this.handleRegisterSubmit}
                            style={{float:'right'}}
                        />
                    </Dialog>
                    <div className="ui-g-12 ui-md-6 ui-g-nopad reg-pad-bot" style={{paddingBottom:"30px"}}>
                        <Button
                            label="Create account"
                            onClick={this.handleCreate}
                        />
                    </div>

                </React.Fragment>

        );
    }


}

Register.propTypes = {
    registerUser: PropTypes.func.isRequired,
    loginStatus: PropTypes.string.isRequired
}
import React from "react";
import PropTypes from "prop-types";
import {NavLink} from "react-router-dom";
import {matchPath} from "react-router-dom";

/**
 * Render name of Project in path.
 * @param match - React-Router variable containing dynamic elements of path pointing to a given project (projectId)
 * @param appState - Redux store
 * @returns {*}
 * @constructor
 */
const ProjectBreadcrumb = ({match, appState}) => {
 let project = appState.project.projects.projects.find((el)=>el.uuid === match.params.projectId);
 return  <span>{project.name}</span>; // use match param userId to fetch/display user name
};

/**
 * Render name of Experiment in path.
 * @param match - React-Router variable containing dynamic elements of path pointing to a given exp (experimentId)
 * @param appState
 * @returns {*}
 * @constructor
 */
const ExperimentBreadcrumb = ({match, appState}) => {
    let experiment = appState.project.experiments.experiments.find((el)=>el.uuid === match.params.experimentId);
    if(!experiment)
        experiment =  appState.expDesign.experiment;
    return  <span>{experiment.question}</span>; // use match param userId to fetch/display user name

};

/**
 * Render literal with title
 * @param match
 * @returns {*}
 * @constructor
 */
const DashboardBreadcrumb = ({match}) =>
    <span>Dashboard</span>;

const routes = [
    {path: '/home', breadcrumb: DashboardBreadcrumb},
    {path: 'users/:userId', breadcrumb: <span>Projects</span>},
    {path: '/project', breadcrumb: <span>Projects</span>},
    {path: '/project/:projectId', breadcrumb: ProjectBreadcrumb},
    {path: '/project/:projectId/expdesign', breadcrumb: <span>New Experiment</span>},
    {path: '/project/:projectId/experiment/:experimentId', breadcrumb: ExperimentBreadcrumb},
    {path: '/news', breadcrumb:  <span>News</span>},
];

const renderer = ({breadcrumb, match,appState}) => {
    if (typeof breadcrumb === 'function') {
        return breadcrumb({match,appState});
    }
    return breadcrumb;
};

export const getBreadcrumbs = ({routes, pathname, appState}) => {
    const matches = [];

    pathname
        .replace(/\/$/, '')
        .split('/')
        .reduce((previous, current) => {
            const pathSection = `${previous}/${current}`;

            let breadcrumbMatch;

            routes.some(({breadcrumb, path}) => {
                const match = matchPath(pathSection, {exact: true, path});

                if (match) {
                    breadcrumbMatch = {
                        breadcrumb: renderer({breadcrumb, match,appState}),
                        path,
                        match,
                    };
                    return true;
                }

                return false;
            });

            if (breadcrumbMatch) {
                matches.push(breadcrumbMatch);
            }

            return pathSection;
        });

    return matches;
};
const Breadcrumbs = ({breadcrumbs}) => {
    const lastEl = breadcrumbs.length - 1;
    return (
        <div>
            {breadcrumbs.map(({breadcrumb, path, match}, index) => (
                <span key={path}>
                     {lastEl !== index &&
                     <NavLink to={match.url}>
                         {breadcrumb}
                     </NavLink>
                     }
                    {lastEl === index &&
                        breadcrumb
                    }
                    {lastEl !== index &&
                    <span> &gt; </span>}
      </span>
            ))}
        </div>
    );
};

/**
 * "Dumb" component responsible for displaying breadcrumb.
 * Uses Breadcrumbs function and requires array of routes to be matched with current location.
 * Each route can use its own renderer for Link.
 * @param location - React Router location object (required)
 * @returns {*} - render function
 */
export const Breadcrumb = ({location, appState}) => {
    let result = getBreadcrumbs({
        pathname: location.pathname,
        routes,
        appState
    });

    return (
        <div style={{fontSize: '16px', color: '#fff', marginTop: '20px', marginLeft: '25px', display: 'inline-block'}}>
            <Breadcrumbs breadcrumbs={result}/>
        </div>
    )
};

Breadcrumb.propTypes = {
    location: PropTypes.object.isRequired
};
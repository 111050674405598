import React from 'react'
import ContainerLogin from '../containers/ContainerLogin.js'
import SpineLogo from './SpineLogo.js'
import PropTypes from 'prop-types'

export default class LayoutTopbar extends React.Component {
  render() {
    return(
      <div className="header"  style={{height:'75px',width:"100%"}}>
          {/*<div style={{width:"6%",display:'inline-block'}}/>*/}
          <div
              style={{display: "inline-block",marginLeft: "5%", position: "absolute", marginTop: "16px"}}
              // style={{width:"59%",display:'inline-block'}}
          >

              <SpineLogo/>
          </div>
          <div style={{position: "absolute",  display: "inline-block",marginLeft: "64%",width:"36%"}}>
              <ContainerLogin/>
          </div>

      </div>
    )
  }
}
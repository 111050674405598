import React from "react";


export  const ProjectDetailsCITab = ({project}) => {

    return(
        <div>

        </div>
    )
};
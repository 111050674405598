import React from "react";
import PropTypes from "prop-types";
import {ItemListElement} from "./ItemListElement";

/** Universal "dumb" list renderer working in 2 modes: list and cards.
 *  In future can be extended with styles for columns and rows.
 *  Typical use:
 *   <ItemList model={this.state.model}
 *    isList={isList}
 *    classNames={["ui-g-6","ui-g-2","ui-g-2","ui-g-2"]}
 *    headers={["Name","People","Status","Last modified"]}
 *    properties={["name","people","status","modified"]}
 *    cardRenderer={<ExperimentItemCardElement />}
 *    listRenderer={<ExperimentItemListElement />}
 *    />
 *
 * @param model  -  data model to be displayed
 * @param isList - mode indicator (true - list, false - cards)
 * @param classNames - CSS grid classes for columns (can be supplemented with others)
 * @param headers - array of header titles
 * @param properties - names of JSON properties to be displayed in columns [array of strings in order to display]
 * @param cardRenderer - JSX React component to display in "Card" mode. Must have "item" property for object to display.
 * @param columnStyle - optional style for column in card mode (default "ui-g-6 ui-sm-12")
 * Known cardRenderer implementations: ExperimentItemCardElement
 * @param listRenderer - [optional] JSX React component to display single element in "List" mode. Must have "item" property for object to display.
 * Known implementations: ExperimentItemListElement. If not used, standard renderer using properties passed is used.
 * @param optionalListStyle - optional Style for "List" mode
 * @see ExperimentItemCardElement
 * @see ItemListElement
 */

export  const ItemList = ({model,isList,classNames,headers,properties, cardRenderer,listRenderer,columnStyle,optionalListStyle}) => {
    const colStyle=(columnStyle!=null)?columnStyle:"ui-g-6 ui-sm-12";
    let columnMode=(isList)?"ui-g-12":colStyle;
    const optionalStyle=(optionalListStyle!=null)?optionalListStyle:{width: '80%', marginLeft: '10%', marginRight: '10%',display:'inline-flex',
        marginBottom:'1px', padding:'10px'};

    let items = model.map((exp) =>
        <div className={columnMode} style={{padding:'0.05em'}}>
            <ItemListElement key={exp.uuid}
                             item={exp}
                             isList={isList}
                             classNames={classNames}
                             properties={properties}
                             cardRenderer={ React.cloneElement( cardRenderer, {item: exp} )}
                             listRenderer={ React.cloneElement( listRenderer, {item: exp} )}
            />

        </div>
    );
    return(
        <div className="ui-g-12"  style={{ padding:'0.05em'}}>
            {isList &&
            <div className="ui-g-12" style={{padding:'0.05em'}}>
                <div style={optionalStyle}>
                    {headers.map((header, i) => <div className={classNames[i]} key={i} >{headers[i]}</div>)}
                </div>
            </div>
            }
            {items}
        </div>
    )
};

ItemList.propTypes = {
    model: PropTypes.array.isRequired, //data to display
    isList: PropTypes.bool.isRequired, //mode of display (list, cards)
    classNames: PropTypes.array.isRequired, // array of N-size of CSS class names, where N - number of cols
    headers: PropTypes.array.isRequired,    // array of N-size of headers
    properties:PropTypes.array.isRequired,   // array of N-size of properties names
    cardRenderer:PropTypes.object.isRequired, //JSX object with renderer
    listRenderer:PropTypes.object,
    columnStyle:PropTypes.string //Optional parameter for card mode
};
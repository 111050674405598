import {VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL} from "../../../Constants";

export const REGIONS = [
    {value:'wml',label:'white matter lesions'},
    {value:'Gi',label:'gigantocellular reticular nucleus'},
    {value:'Pn',label:'pontine nucleus'},
    {value:'PnC',label:'pontine reticular nucleus, caudal part'},
    {value:'PnV',label:'pontine reticular nucleus, ventral part'},
    {value:'RMg',label:'raphe magnus nucleus'},
    {value:'RPa',label:'raphe pallidus nucleus'},
    {value:'Tz',label:'nucleus of the trapezoid body'},
    {value:'RMC',label:'red nucleus. magnocellular part'},
    {value:'2CB',label:'2nd cerebellar lobe'},
    {value:'CB',label:'cerebellum'},
    {value:'CA',label:'CA1-3 hippocampal subfield'},
    {value:'Ctx',label:'cerebral cortex'},
    {value:'Acb',label:'accumbens nucleus'},
    {value:'ACo',label:'anterior cortical amygdaloid nucleus'},
    {value:'AD',label:'anterodorsal thalamic nucleus'},
    {value:'APT',label:'anterior pretectal nucleus'},
    {value:'Arc',label:'arcuate hypothalamic nucleus'},
    {value:'AHi',label:'amygdalohippocampal area'},
    {value:'AHC',label:'anterior hippocampal continuation'},
    {value:'BL',label:'basolateral amygdaloid nucleus'},
    {value:'BST',label:'bed nucleus of the stria terminalis'},
    {value:'CdN',label:'caudate nucleus'},
    {value:'CeC',label:'central amygdala, capsular part'},
    {value:'CeM',label:'central amygdala, medial division'},
    {value:'CM',label:'central medial thalamic nucleus'},
    {value:'CPu',label:'caudate putamen'},
    {value:'DEn',label:'dorsal endopiriform nucleus'},
    {value:'DG',label:'dentate gyrus'},
    {value:'DLG',label:'dorsal lateral geniculate nucleus'},
    {value:'DM',label:'dorsal hypothalamic nucleus'},
    {value:'DS',label:'dorsal septal nucleus'},
    {value:'d/vTT',label:'dorsal/ventral tenia tecta'},
    {value:'FC',label:'fasciola cinereum'},
    {value:'flc',label:'fissure longitudinalis cerebri'},
    {value:'g',label:'dentate granule cell layer'},
    {value:'GP',label:'globus pallidus'},
    {value:'HC',label:'hippocampus'},
    {value:'HDB',label:'nucleus of the horizontal limb of the diagonal band'},
    {value:'IG',label:'indusium griseum'},
    {value:'IPAC',label:'intersitial nucleus of the posterior limb of the anterior commissure'},
    {value:'La',label:'lateral amygdaloid nucleus'},
    {value:'LD',label:'laterodorsal thalamic nucleus'},
    {value:'LPM',label:'lateral posterior thalamic nucleus'},
    {value:'LS',label:'lateral septal nucleus'},
    {value:'m',label:'dentate molecular layer'},
    {value:'MBN',label:'magnocellular basal nucleus (of Meynert)'},
    {value:'MD',label:'mediodorsal thalamic nucleus'},
    {value:'MeA',label:'medial amygdaloid nucleus'},
    {value:'MG',label:'medial geniculate nucleus'},
    {value:'MPA',label:'medial preoptic area'},
    {value:'MS',label:'medial septal nucleus'},
    {value:'Pe',label:'periventricular hypothalamic nucleus'},
    {value:'Pir',label:'piriform cortex'},
    {value:'Pu',label:'nucleus putamen'},
    {value:'PV',label:'paraventricular thalamic nucleus'},
    {value:'PVH',label:'paraventricular hypothalamic nucleus'},
    {value:'pyr',label:'CA1-3 pyramidal cell layer'},
    {value:'Rt',label:'reticular thalamic nucleus'},
    {value:'S',label:'subiculum'},
    {value:'SHi',label:'septohippocampal nucleus'},
    {value:'SON',label:'supraoptic nucleus'},
    {value:'VA',label:'ventral anterior thalamic nucleus'},
    {value:'VEn',label:'ventral endopiriform nucleus'},
    {value:'VL',label:'ventrolateral thalamic nucleus'},
    {value:'VLG',label:'ventral lateral geniculate nucleus'},
    {value:'VP',label:'ventral pallidum'},
    {value:'VPL',label:'ventral posterolateral thalamic nucleus'},
    {value:'CnF',label:'cuneiform nucleus'},
    {value:'DR',label:'dorsal Raphe nucleus'},
    {value:'ECIC',label:'external cortex of the inferior colliculus'},
    {value:'IP',label:'interpeduncular nucleus'},
    {value:'LC',label:'locus coeruleus'},
    {value:'LPB',label:'lateral parabrachial nucleus'},
    {value:'MnR',label:'median Raphe nucleus'},
    {value:'MPB',label:'medial parabrachial nucleus'},
    {value:'MT',label:'medial terminal nucleus of the accessory optic tract'},
    {value:'PAG',label:'periaquaductal grey'},
    {value:'SC',label:'superior colliculus'},
    {value:'SNC',label:'substantia nigra, compact part'},
    {value:'SNR',label:'substantia nigra, reticular part'},
    {value:'7n',label:'facial nerve'},
    {value:'8vn',label:'vestibulocochlear nerve'},
    {value:'ac',label:'anterior commissure'},
    {value:'cc',label:'corpus callosum'},
    {value:'cic',label:'commissure of the inferior colliculus'},
    {value:'cp',label:'cerebral peduncle'},
    {value:'f',label:'fornix'},
    {value:'fi',label:'fimbria of the hippocampus'},
    {value:'gcc',label:'genu of corpus callosum'},
    {value:'ic',label:'internal capsule'},
    {value:'ml',label:'medial lemniscus'},
    {value:'mlf',label:'medial longitudinal fasciculus'},
    {value:'mt',label:'mammillothalmic tract'},
    {value:'opt',label:'optic tract'},
    {value:'ox',label:'optic chiasm'},
    {value:'py',label:'pyramidal tract'},
    {value:'s5',label:'sensory root of the trigeminal nucleus'},
    {value:'Scc',label:'splenium of corpus callosum'},
    {value:'scp',label:'superior cerebellar peduncle'},
    {value:'sp5',label:'spinal trigeminal tract'},
    {value:'vhc',label:'ventral hippocampal commissure'},
    {value:'xscp',label:'decussation of the superior cerebellar peduncle'},
    {value:'E/OV',label:'ependymal and subendymal layer/olfactory ventricle'},
    {value:'EPL/IPL',label:'external/internal plexiform layer of the olfactory bulb'},
    {value:'GL',label:'glomerular layer of the olfactory bulb'},
    {value:'GRL',label:'granule cell layer of the olfactory bulb'},
    {value:'ML',label:'mitral cell layer of the olfactory bulb'},
    {value:'RMS',label:'rostral migratory stream'}];


export const CLINICAL_FEATURES =
[
    {label:'CLINICAL_T25FW_Trial_1_sec', value:'CLINICAL_T25FW_Trial_1_sec',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_T25FW_Trial_2_sec', value:'CLINICAL_T25FW_Trial_2_sec',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_9HPT_DomHand_Trial_1_sec', value:'CLINICAL_9HPT_DomHand_Trial_1_sec',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_9HPT_DomHand_Trial_2_sec', value:'CLINICAL_9HPT_DomHand_Trial_2_sec',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_9HPT_NonDomHand_Trial_1_sec', value:'CLINICAL_9HPT_NonDomHand_Trial_1_sec',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_9HPT_NonDomHand_Trial_2_sec', value:'CLINICAL_9HPT_NonDomHand_Trial_2_sec',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_Ambulation_Score', value:'CLINICAL_Ambulation_Score',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_BLCVA_100_Total', value:'CLINICAL_BLCVA_100_Total',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_BLCVA_2p5_Total', value:'CLINICAL_BLCVA_2p5_Total',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_BLCVA_1p25_Total', value:'CLINICAL_BLCVA_1p25_Total',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_EDSS_Score_Observed', value:'CLINICAL_EDSS_Score_Observed',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_EDSS_Score_Converted', value:'CLINICAL_EDSS_Score_Converted',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_FSS_Bowel_Bladder', value:'CLINICAL_FSS_Bowel_Bladder',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_FSS_Bowel_Bladder_Converted', value:'CLINICAL_FSS_Bowel_Bladder_Converted',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_FSS_Brainstem', value:'CLINICAL_FSS_Brainstem',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_FSS_Cerebellar', value:'CLINICAL_FSS_Cerebellar',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_FSS_Cerebral_Mental', value:'CLINICAL_FSS_Cerebral_Mental',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_FSS_Pyramidal', value:'CLINICAL_FSS_Pyramidal',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_FSS_Sensory', value:'CLINICAL_FSS_Sensory',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_FSS_Visual', value:'CLINICAL_FSS_Visual',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_FSS_Visual_Converted', value:'CLINICAL_FSS_Visual_Converted',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL},
    {label:'CLINICAL_PASAT_3_Sec_Total', value:'CLINICAL_PASAT_3_Sec_Total',type:VARIABLE_PROPERTY_VALUE_META_TYPE__CLINICAL}

];

export const  IMAGE_FEATURES = [
    {label: 'Area', value: 'Area'},
    {label: 'Volume', value: 'Volume'}
];

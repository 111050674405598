import {saveDataSelection, updateSelectionCriteriaActionCreator} from "../action/DataSelectionAction";
import {connect} from "react-redux";
import DataSelection from "../component/DataSelection";
import {getSorter, getVisibilityFilter, getVisibleCohorts} from "../../visualization/selectors/CohortsSelectors";
import {setSorter, setVisibilityFilter} from "../../visualization/action/CohortAction";



const mapStateToProps = state => {
    return{
        filteredCohorts : getVisibleCohorts(state),
        cohorts:state.visu.studies.studies,
        cohortFilter: getVisibilityFilter(state),
        cohortSorter:getSorter(state),
        experiment: state.expDesign.experiment,
        expDesign: state.expDesign,
        visu:state.visu
    }
};

const mapDispatchToProps = dispatch => {
    return{
        saveSelection: (data) => {dispatch(saveDataSelection(data))},
        updateCriteria: (data, kind)=>{dispatch(updateSelectionCriteriaActionCreator(data,kind))},
        onSorterChange: (id)=>{dispatch(setSorter(id))},
        onFilterChange: (data)=>{dispatch(setVisibilityFilter(data))}
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps)(DataSelection)
import {
	ROLE_PICK_SUCCESS
} from './actionType.jsx';


//ROLE PICKING ***************************************************************

function receiveRole(auth) {
	return {
		type: ROLE_PICK_SUCCESS,
		auth
	}
}

/*
 * Main action:
 * - dispatch the change role request to the reducer
 *
 * @param {Object} role
 * @return {Object} type, role or err
 */
export function rolePick(auth){
  console.log('Executing role picking. Role:', auth);
return dispatch=>{

          dispatch(receiveRole(auth))
  }
}
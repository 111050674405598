import {getWorkflowList, setWorkflowActionCreator} from "../action/WorkflowAction";
import {connect} from "react-redux";
import WorkflowDialog from "../component/WorkflowDialog";
import {getTaskList} from "../action/TaskAction";

/**
 *
 * @param state - Redux store state
 * @param ownProps - call parameters
 * @returns {{workflows: *|Array}}
 */
const mapStateToProps = (state, ownProps) => {
    console.log("state users in workflowSelection ", state.expDesign.workflows.workflows);
    return {
        workflows: state.expDesign.workflows,
        experiment:state.expDesign.experiment
    }
};
/**
 *
 * @param dispatch
 * @param ownProps - call params functions
 * @returns {{getWorkflows: function()}}
 */
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setWorkflow:(workflow,variable)=>{dispatch(setWorkflowActionCreator(workflow,variable))},
        getTaskList:(workflowId)=>{dispatch(getTaskList(workflowId))}
    }
};


export const ContainerWorkflowDialog = connect( mapStateToProps, mapDispatchToProps)(WorkflowDialog);
import React from 'react'
import PropTypes from 'prop-types'
import {Button} from 'primereact/components/button/Button'
import {OverlayPanel} from 'primereact/components/overlaypanel/OverlayPanel'
import {Card} from 'primereact/components/card/Card'

class Logout extends React.Component {

    // props are not passed

    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
        this.handleOverlay = this.handleOverlay.bind(this);
    }


    handleLogout() {
        console.log('Logout button::onclick');
        this.props.onLogout(this.props.auth);
    }

    handleOverlay(event) {
        this.op.toggle(event);
    }


    /**Adapter function for Prime React Dropdown component
     *
     *  @param item role to convert
     * @returns {Object} object with required attributes
     */
    static adaptSelectableOption(item) {
        let option = {};
        option.label = item.charAt(0).toUpperCase() + item.slice(1);
        option.value = item;
        return option;
    }


    render() {
        console.log("RolePicker::render");
        const {auth} = this.props;
        let roles = auth.roles.map(item => Logout.adaptSelectableOption(item));

        return (
            <span style={{    float: 'right',margin: '16px'}}>
                <Button icon="fa-user" style={{marginLeft: 4}} onClick={this.handleOverlay}/>
                <OverlayPanel ref={(el) => {
                    this.op = el;
                }} showCloseIcon={true}>
                    <Card>
                        <p>{auth.name} {auth.nickname} {auth.lastname}</p>
                        <p>Working as: {auth.role_active}</p>
                        <p>Permissions for: {roles.map(item => item.label).join(' ')}</p>
                        <p>Profession: {auth.profession}</p>
                        <p>Specialty: {auth.specialty}</p>
                        <p>Highest level of education: {auth.eduLevel}</p>
                        <p>Familiarity with Neuroimaging of MS: {auth.experience}</p>
                        <Button label="Logout" onClick={this.handleLogout}/>
                    </Card>
                </OverlayPanel>
            </span>
        )
    }
}

Logout.propTypes = {
    auth: PropTypes.object.isRequired,
    onLogout: PropTypes.func.isRequired
};
export default Logout
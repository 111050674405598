import React from "react";
import {Card} from "primereact/components/card/Card";
import PropTypes from "prop-types";
import {
    COHORT_PROPERTY_NAME__DESCRIPTION,
    COHORT_PROPERTY_NAME__NAME, COHORT_PROPERTY_NAME__PI,
    COHORT_PROPERTY_NAME__PRIVACY, COHORT_PROPERTY_NAME__SUBJECTS, COHORT_PROPERTY_NAME__UUID,
    COHORT_PROPERTY_VALUE_PRIVACY__PRIVATE,
    COHORT_PROPERTY_VALUE_PRIVACY__PUBLIC, EXPERIMENT_PROPERTY_NAME__COHORTS,
} from "../../../Constants";
import {Checkbox} from "primereact/components/checkbox/Checkbox";
import image from "../../sass/images/iconforcohort.jpg";
import {
    MOCK_CODE, MOCK_COHORT_DESCRIPTION, MOCK_COHORT_PI, MOCK_KEYWORDS, MOCK_LINK_TO_COHORT, MOCK_LINK_TO_G35,
    MOCK_LINK_TO_KEYS
} from "../tests/Mocks";



/** "Dumb" Renderer for "Cards" mode.
 * @param item - mandatory component if used with ItemList container
 * @param onSelect - callback to provide handler for checkbox onSelect
 * @returns {*}
 * @constructor
 */
const ModelOptionsItemCardElement = ({item, onSelect}) => {

    let logo =  <i className="fa fa-question"/>;

    let isReady = item!=null && item['status']!=null && item['status']==='ready'; // check whether model is ready to use (deactivate onSelect if not)

    if (item !=null) {
        const statusColor = (isReady)?"#00D2D0":"#f00";
         if (item['spineType'] === "USER_DEFINED")
                logo = <i className="fa fa-user" style={{fontSize:'x-large',color:statusColor,marginTop:"10px"}}/>;
             // logo = <div className="expContributors" style={{width:"32px",height:"32px"}}/>;
         else
                logo = <i className="fa fa-desktop" style={{fontSize:'x-large',color:statusColor,marginTop:"10px"}}/>;
             // logo = <div className="expSpineLogo"/>;
    }

    const title = (item!=null)?item['name']:"No statistical model selected";
    const author = (item!=null && item['author']!=null)?item['author']:"";

    return (
    <div className="modelOptionsItemCard"
                 onClick={()=>{(isReady)?onSelect(item.uuid):{}}}>
        <div>{logo}</div>
        <div> {title} </div>
        <div>
            {author}
        </div>
    </div>
    );
};

export default ModelOptionsItemCardElement;

ModelOptionsItemCardElement.propTypes = {
    item: PropTypes.object,
    onSelect: PropTypes.func,//callback to select cohort
};

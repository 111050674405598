export const mockStudy = {
    "uuid": "2802b284-da5b-4283-50bb-f338bdb77a77",
    "name": "Test study",
    "description": "A test study",
    "pi": "AdeScientist@bwh.harvard.edu",
    "dateOfCreation": "2018-02-08T05:00:00.00-5",
    "subjectUuids": [
        "3130bbbc-cf76-49cd-a5cf-4e8b214f3f1c",
        "40f2c748-d3e6-4312-a80e-776ff87e0cf2"
    ],
    "numberOfAnat": 10,
    "numberOfDWI": 0,
    "numberOfFunc": 0,
    "clinicalData": false
};

export const bravoTest =
    {
        "name": "BRAVO",
        "description": "",
        "creationDate": "2018-08-29T19:32:14.063Z",
        "privacy": "private",
        "pi": "guttmann@bwh.harvard.edu",
        "co-investigators": [
            "amoralespinzon@bwh.harvard.edu",
            "amarciniak@bwh.harvard.edu"
        ],
        "sourceSystem": "loris",
        "sourceObject": {
            "Meta": {
                "Project": "BRAVO"
            },
            "Candidates": [
                "100176",
                "102609",
                "102866",
                "103072",
                "106008",
                "106267",
                "107585",
                "107996",
                "109193",
                "109460",
                "109714",
                "114571",
                "115288",
                "117358",
                "117590",
                "118152",
                "119251",
                "119437",
                "120168",
                "120356",
                "120364",
                "121060",
                "123896",
                "125144",
                "125258",
                "125938",
                "127673",
                "127919",
                "128188",
                "128363",
                "129058",
                "129256",
                "129843",
                "131017",
                "133246",
                "135334",
                "135702",
                "137025",
                "137261",
                "141377",
                "141672",
                "141970",
                "142425",
                "142601",
                "142637",
                "142910",
                "144544",
                "145540",
                "145643",
                "145680",
                "145834",
                "146075",
                "146964",
                "148734",
                "148857",
                "149032",
                "149034",
                "149684",
                "150127",
                "150776",
                "151408",
                "152198",
                "152418",
                "152793",
                "153269",
                "154576",
                "157031",
                "157134",
                "157196",
                "157463",
                "158043",
                "158305",
                "159391",
                "159571",
                "161106",
                "161352",
                "161551",
                "161969",
                "162452",
                "162793",
                "163153",
                "163375",
                "163864",
                "164279",
                "164661",
                "165430",
                "165593",
                "166004",
                "166183",
                "167216",
                "167749",
                "168992",
                "169147",
                "169175",
                "169804",
                "169843",
                "170131",
                "170447",
                "170713",
                "171202",
                "171442",
                "172443",
                "172626",
                "172639",
                "172976",
                "173708",
                "174160",
                "174295",
                "175851",
                "178083",
                "179358",
                "180987",
                "181583",
                "182122",
                "182616",
                "182642",
                "183095",
                "184212",
                "184625",
                "185936",
                "186536",
                "186928",
                "187454",
                "187948",
                "188397",
                "188630",
                "189371",
                "189491",
                "191997",
                "192253",
                "193192",
                "193769",
                "195715",
                "196436",
                "196669",
                "196761",
                "196903",
                "197288",
                "197565",
                "197656",
                "198007",
                "198446",
                "198507",
                "198888",
                "199150",
                "200441",
                "200740",
                "201906",
                "203238",
                "203760",
                "204049",
                "204149",
                "204176",
                "204465",
                "205457",
                "205547",
                "206523",
                "206629",
                "207455",
                "208385",
                "208431",
                "211624",
                "211910",
                "213638",
                "213968",
                "214034",
                "214150",
                "215722",
                "216205",
                "217696",
                "218053",
                "218466",
                "218627",
                "219146",
                "219443",
                "220459",
                "220964",
                "221260",
                "223148",
                "224124",
                "225820",
                "225880",
                "227172",
                "227961",
                "228210",
                "228839",
                "229636",
                "230786",
                "230930",
                "231153",
                "233493",
                "233506",
                "233851",
                "234170",
                "234244",
                "234677",
                "236606",
                "236612",
                "237132",
                "237359",
                "237886",
                "238008",
                "239722",
                "240449",
                "240966",
                "241541",
                "241725",
                "242600",
                "242644",
                "243328",
                "243986",
                "244191",
                "244488",
                "244695",
                "245280",
                "245342",
                "246994",
                "249644",
                "249882",
                "250133",
                "250832",
                "251357",
                "251745",
                "253958",
                "254037",
                "255785",
                "256050",
                "259018",
                "259596",
                "259687",
                "259857",
                "260372",
                "260540",
                "260602",
                "260907",
                "261187",
                "261192",
                "262089",
                "262700",
                "263172",
                "263266",
                "263274",
                "264890",
                "265081",
                "265107",
                "265547",
                "267304",
                "269550",
                "270358",
                "270360",
                "270548",
                "270725",
                "271007",
                "271107",
                "273492",
                "273607",
                "273943",
                "273973",
                "274899",
                "275051",
                "275846",
                "275995",
                "276212",
                "276312",
                "276472",
                "277231",
                "279007",
                "279719",
                "280851",
                "281786",
                "282302",
                "284051",
                "284923",
                "285579",
                "285619",
                "286908",
                "287076",
                "287123",
                "287556",
                "287692",
                "287814",
                "289644",
                "290082",
                "290142",
                "290301",
                "290347",
                "290396",
                "290634",
                "291278",
                "291398",
                "294691",
                "295146",
                "295486",
                "296844",
                "297848",
                "298402",
                "299216",
                "299452",
                "300208",
                "301176",
                "301974",
                "303093",
                "303196",
                "305008",
                "305072",
                "305839",
                "305857",
                "307045",
                "307094",
                "308345",
                "309865",
                "311357",
                "312166",
                "312411",
                "312417",
                "316312",
                "316953",
                "317213",
                "318413",
                "320342",
                "320798",
                "320853",
                "320962",
                "321120",
                "321802",
                "322170",
                "324862",
                "325497",
                "325540",
                "326097",
                "326128",
                "326633",
                "327911",
                "329105",
                "329565",
                "330296",
                "331276",
                "331664",
                "331910",
                "332296",
                "332344",
                "334217",
                "335912",
                "336094",
                "337300",
                "337520",
                "337597",
                "338950",
                "339537",
                "341199",
                "341412",
                "341425",
                "341622",
                "341745",
                "342258",
                "342304",
                "342454",
                "342717",
                "342873",
                "343692",
                "345335",
                "345549",
                "345666",
                "346046",
                "346224",
                "346960",
                "347149",
                "348207",
                "348893",
                "350127",
                "350508",
                "350719",
                "353665",
                "355255",
                "357268",
                "357369",
                "357643",
                "359307",
                "361098",
                "363522",
                "365656",
                "366896",
                "367045",
                "367380",
                "368789",
                "371055",
                "371232",
                "371784",
                "372322",
                "373247",
                "373278",
                "373451",
                "373467",
                "373863",
                "374508",
                "375009",
                "376684",
                "377584",
                "377615",
                "377918",
                "378458",
                "379029",
                "379566",
                "379687",
                "380017",
                "380146",
                "380802",
                "381241",
                "381862",
                "385522",
                "386390",
                "386427",
                "386464",
                "386592",
                "386744",
                "386881",
                "388812",
                "389000",
                "389722",
                "390527",
                "390663",
                "392822",
                "393104",
                "393254",
                "393438",
                "394676",
                "395192",
                "395857",
                "396129",
                "396461",
                "397121",
                "397301",
                "398043",
                "398053",
                "398643",
                "398811",
                "398843",
                "399301",
                "401082",
                "401906",
                "402451",
                "402867",
                "403255",
                "405345",
                "406025",
                "406608",
                "407000",
                "409324",
                "410407",
                "411100",
                "411164",
                "412531",
                "413542",
                "413789",
                "414213",
                "414763",
                "415217",
                "415596",
                "415689",
                "416466",
                "416586",
                "417546",
                "418201",
                "419609",
                "420924",
                "421576",
                "422703",
                "422768",
                "423217",
                "423471",
                "425587",
                "425978",
                "426008",
                "427018",
                "427435",
                "428038",
                "428136",
                "429005",
                "429555",
                "429962",
                "432449",
                "432824",
                "433024",
                "433045",
                "435917",
                "436193",
                "436296",
                "436746",
                "436903",
                "437004",
                "437368",
                "438705",
                "439097",
                "440064",
                "440834",
                "441626",
                "442303",
                "442768",
                "443064",
                "443254",
                "444332",
                "445376",
                "445749",
                "445772",
                "446505",
                "446669",
                "446944",
                "447013",
                "448966",
                "449368",
                "450030",
                "450672",
                "450748",
                "451139",
                "451154",
                "451386",
                "451618",
                "451912",
                "452762",
                "453391",
                "455040",
                "455214",
                "455342",
                "455767",
                "455976",
                "456406",
                "458112",
                "458247",
                "458371",
                "458394",
                "459015",
                "460376",
                "461753",
                "461921",
                "462600",
                "462607",
                "463372",
                "463684",
                "465507",
                "465730",
                "466127",
                "467118",
                "467150",
                "467963",
                "468099",
                "468337",
                "468475",
                "468524",
                "468621",
                "469353",
                "470352",
                "470977",
                "472662",
                "473916",
                "474132",
                "474874",
                "474996",
                "475264",
                "475626",
                "476081",
                "476392",
                "478137",
                "478673",
                "478851",
                "479259",
                "479316",
                "479502",
                "480948",
                "482128",
                "482480",
                "482691",
                "482711",
                "483417",
                "483601",
                "483788",
                "485402",
                "485589",
                "485928",
                "486093",
                "490493",
                "492987",
                "493011",
                "493891",
                "494139",
                "494768",
                "495166",
                "495608",
                "497250",
                "497871",
                "499529",
                "501119",
                "502219",
                "503935",
                "504324",
                "504668",
                "505768",
                "505883",
                "505937",
                "506119",
                "506848",
                "507220",
                "507422",
                "507557",
                "508414",
                "508543",
                "508602",
                "509750",
                "510655",
                "511914",
                "513214",
                "513681",
                "513750",
                "515020",
                "516237",
                "516477",
                "517476",
                "517495",
                "518432",
                "520113",
                "520738",
                "521854",
                "522911",
                "523218",
                "523960",
                "524116",
                "525207",
                "526484",
                "527763",
                "529976",
                "530740",
                "530795",
                "531155",
                "531167",
                "531210",
                "531474",
                "531822",
                "532793",
                "532960",
                "533301",
                "534271",
                "534835",
                "536591",
                "537163",
                "537796",
                "538109",
                "538370",
                "538405",
                "538684",
                "538975",
                "538979",
                "540395",
                "540614",
                "540917",
                "543004",
                "543604",
                "543759",
                "544744",
                "544921",
                "545321",
                "548162",
                "551180",
                "551329",
                "552842",
                "553026",
                "553185",
                "556064",
                "556092",
                "556647",
                "556930",
                "559807",
                "559936",
                "560042",
                "561445",
                "561742",
                "562575",
                "562830",
                "564107",
                "564295",
                "564855",
                "565013",
                "565097",
                "565940",
                "566149",
                "567226",
                "567737",
                "568233",
                "569243",
                "569736",
                "570060",
                "570614",
                "572386",
                "572785",
                "573489",
                "573555",
                "574032",
                "574071",
                "574164",
                "574216",
                "575449",
                "575519",
                "576210",
                "576673",
                "578412",
                "579589",
                "579961",
                "580282",
                "580801",
                "580811",
                "581578",
                "582303",
                "582899",
                "582952",
                "583269",
                "584584",
                "585507",
                "585544",
                "585745",
                "585813",
                "586984",
                "587188",
                "587891",
                "588378",
                "588627",
                "589647",
                "590400",
                "592933",
                "593778",
                "593801",
                "594983",
                "595621",
                "599013",
                "600101",
                "601883",
                "603424",
                "604931",
                "605696",
                "607878",
                "608948",
                "608997",
                "609297",
                "610688",
                "610730",
                "610877",
                "612371",
                "613100",
                "613985",
                "614972",
                "615141",
                "615301",
                "616274",
                "617383",
                "617476",
                "619815",
                "622247",
                "622570",
                "622688",
                "622830",
                "623179",
                "623864",
                "625463",
                "625708",
                "626455",
                "626774",
                "626812",
                "627199",
                "627470",
                "627473",
                "627544",
                "628644",
                "631065",
                "631249",
                "634204",
                "635642",
                "635770",
                "635866",
                "636669",
                "638839",
                "639106",
                "639219",
                "639694",
                "640442",
                "642192",
                "642429",
                "642441",
                "642582",
                "642742",
                "643177",
                "643518",
                "644657",
                "644745",
                "644955",
                "645035",
                "645202",
                "646212",
                "647062",
                "647157",
                "648158",
                "650984",
                "652858",
                "654965",
                "655791",
                "658137",
                "658672",
                "659634",
                "660833",
                "661281",
                "662632",
                "662956",
                "663221",
                "663622",
                "665131",
                "665158",
                "665502",
                "665594",
                "667054",
                "667479",
                "670430",
                "670675",
                "673475",
                "674812",
                "674908",
                "675744",
                "676144",
                "676883",
                "677803",
                "678374",
                "681649",
                "682150",
                "683168",
                "683374",
                "683805",
                "685004",
                "686420",
                "686501",
                "686503",
                "686512",
                "687532",
                "688158",
                "689232",
                "689602",
                "690088",
                "691068",
                "691523",
                "691815",
                "692689",
                "693751",
                "693916",
                "694059",
                "694147",
                "695431",
                "696212",
                "697675",
                "697826",
                "699192",
                "700986",
                "701856",
                "702447",
                "702602",
                "702778",
                "704292",
                "706432",
                "706934",
                "707310",
                "708018",
                "708789",
                "709023",
                "709764",
                "709896",
                "710014",
                "710881",
                "711762",
                "713471",
                "713561",
                "713860",
                "713917",
                "714317",
                "716210",
                "717076",
                "717330",
                "718887",
                "719059",
                "723249",
                "723709",
                "723777",
                "723980",
                "724313",
                "725585",
                "726171",
                "727551",
                "728398",
                "728898",
                "729317",
                "730442",
                "731699",
                "732066",
                "732352",
                "732509",
                "732759",
                "733759",
                "734268",
                "736050",
                "736150",
                "736219",
                "736602",
                "737585",
                "738340",
                "739605",
                "739842",
                "740384",
                "741431",
                "741975",
                "742364",
                "742866",
                "744668",
                "744797",
                "746114",
                "746639",
                "748043",
                "748313",
                "749370",
                "749739",
                "749952",
                "750067",
                "750087",
                "750934",
                "751239",
                "752391",
                "753271",
                "754204",
                "754527",
                "754843",
                "755389",
                "755671",
                "757268",
                "759108",
                "759143",
                "760082",
                "760503",
                "761538",
                "761696",
                "762462",
                "763041",
                "764131",
                "764415",
                "765183",
                "766662",
                "766974",
                "768051",
                "768421",
                "769672",
                "770021",
                "770520",
                "770633",
                "771244",
                "771944",
                "772240",
                "772352",
                "772362",
                "772463",
                "773393",
                "775086",
                "776379",
                "776954",
                "777724",
                "779005",
                "779307",
                "779529",
                "779535",
                "781236",
                "781316",
                "782501",
                "783294",
                "783499",
                "784124",
                "785080",
                "785095",
                "785206",
                "785723",
                "786071",
                "786073",
                "786180",
                "786465",
                "786743",
                "787068",
                "787293",
                "787654",
                "788610",
                "789561",
                "789655",
                "790442",
                "792349",
                "792795",
                "792815",
                "793984",
                "797466",
                "798694",
                "799401",
                "801815",
                "803120",
                "804136",
                "806566",
                "807675",
                "807688",
                "808209",
                "809104",
                "811004",
                "811566",
                "811617",
                "813335",
                "813720",
                "814195",
                "815416",
                "816278",
                "818118",
                "818428",
                "819059",
                "819667",
                "822909",
                "823313",
                "823944",
                "824174",
                "824575",
                "824765",
                "824905",
                "826527",
                "826756",
                "827075",
                "827221",
                "827640",
                "828512",
                "828528",
                "830238",
                "831786",
                "831833",
                "831866",
                "832658",
                "832749",
                "833442",
                "836170",
                "837193",
                "837725",
                "837889",
                "838054",
                "838229",
                "839157",
                "839547",
                "840144",
                "840414",
                "840560",
                "841438",
                "842778",
                "843487",
                "844283",
                "844526",
                "845439",
                "845535",
                "846093",
                "847439",
                "847550",
                "848013",
                "848618",
                "848823",
                "849327",
                "849938",
                "850250",
                "850306",
                "851185",
                "851582",
                "851621",
                "852387",
                "853078",
                "853640",
                "853741",
                "854412",
                "854598",
                "854929",
                "855676",
                "857190",
                "858496",
                "858583",
                "858705",
                "859798",
                "862857",
                "863165",
                "863417",
                "863889",
                "863973",
                "864646",
                "865662",
                "866152",
                "866581",
                "866671",
                "867167",
                "869296",
                "869653",
                "869722",
                "869910",
                "870048",
                "870241",
                "870395",
                "870802",
                "870902",
                "872065",
                "874202",
                "875809",
                "876736",
                "878522",
                "878929",
                "880722",
                "880779",
                "881004",
                "881293",
                "882728",
                "883851",
                "884032",
                "885511",
                "886064",
                "886107",
                "887749",
                "888443",
                "888465",
                "888923",
                "889700",
                "889883",
                "890561",
                "891067",
                "891934",
                "893102",
                "893657",
                "894641",
                "896369",
                "897922",
                "898288",
                "898642",
                "899037",
                "899744",
                "901804",
                "901982",
                "902284",
                "903035",
                "905783",
                "906069",
                "906776",
                "906874",
                "907917",
                "908317",
                "908439",
                "908728",
                "909801",
                "910334",
                "910558",
                "911007",
                "911698",
                "917369",
                "917561",
                "918188",
                "918313",
                "918479",
                "918601",
                "918942",
                "918999",
                "919450",
                "919824",
                "919928",
                "920591",
                "921758",
                "922039",
                "923190",
                "923695",
                "925034",
                "925777",
                "925824",
                "926242",
                "928105",
                "928687",
                "930367",
                "930487",
                "932998",
                "934082",
                "935237",
                "935301",
                "936128",
                "937114",
                "937242",
                "937539",
                "938428",
                "938440",
                "938788",
                "938874",
                "939632",
                "940096",
                "941992",
                "942433",
                "944707",
                "944822",
                "944839",
                "945064",
                "945233",
                "946176",
                "946625",
                "947538",
                "949239",
                "950343",
                "950353",
                "950702",
                "950794",
                "951522",
                "951767",
                "952148",
                "952457",
                "952629",
                "954460",
                "955495",
                "956479",
                "956634",
                "958163",
                "959104",
                "959641",
                "959713",
                "960112",
                "960685",
                "960969",
                "961016",
                "961750",
                "964976",
                "965210",
                "966403",
                "967966",
                "969193",
                "969437",
                "969753",
                "969864",
                "970395",
                "970834",
                "970849",
                "973338",
                "975029",
                "976739",
                "977606",
                "977901",
                "978010",
                "978609",
                "978789",
                "978792",
                "979252",
                "979599",
                "979675",
                "979907",
                "981725",
                "982501",
                "983763",
                "983980",
                "984029",
                "984331",
                "984605",
                "985406",
                "985707",
                "986136",
                "988037",
                "988232",
                "988931",
                "989298",
                "991703",
                "991836",
                "993085",
                "993883",
                "994046",
                "995345",
                "995853",
                "998062",
                "998220",
                "998663",
                "999536",
                "999931"
            ],
            "Instruments": [
                "9HPT",
                "Attack",
                "BLCVA",
                "ClinicalComment",
                "EDSS",
                "FSS",
                "MRI",
                "PASAT",
                "SDMT",
                "T25FW",
                "VisitInfo",
                "Ambulation"
            ],
            "Visits": [
                "BRAVO_ATTACK_v601",
                "BRAVO_ATTACK_v602",
                "BRAVO_ATTACK_v603",
                "BRAVO_ATTACK_v604",
                "BRAVO_ATTACK_v605",
                "BRAVO_ATTACK_v606",
                "BRAVO_ATTACK_v607",
                "BRAVO_CLINICAL_v000",
                "BRAVO_CLINICAL_v003",
                "BRAVO_CLINICAL_v004",
                "BRAVO_CLINICAL_v005",
                "BRAVO_CLINICAL_v006",
                "BRAVO_CLINICAL_v007",
                "BRAVO_CLINICAL_v008",
                "BRAVO_CLINICAL_v009",
                "BRAVO_CLINICAL_v010",
                "BRAVO_CLINICAL_v601",
                "BRAVO_CLINICAL_v602",
                "BRAVO_CLINICAL_v603",
                "BRAVO_CLINICAL_v604",
                "BRAVO_CLINICAL_v605",
                "BRAVO_CLINICAL_v606",
                "BRAVO_CLINICAL_v607",
                "BRAVO_CLINICAL_v608",
                "BRAVO_CLINICAL_v609",
                "BRAVO_CLINICAL_v610",
                "BRAVO_CLINICAL_v611",
                "BRAVO_CLINICAL_v612",
                "BRAVO_CLINICAL_v613",
                "BRAVO_CLINICAL_vN01",
                "BRAVO_MRI_v000",
                "BRAVO_MRI_v006",
                "BRAVO_MRI_v010",
                "m0",
                "m00",
                "m12",
                "m24",
                "mET"
            ]
        },
        "sourceEtag": "1de6b316dc408168557545e57f506cc5",
        "uuid": "7166bae11fe4b5742fc27bf89f000ea8",
        "subjects": {
            "count": 1274
        }
    };
export const bravoTest2 =
    {
        "name": "BRAVO2",
        "description": "eee",
        "creationDate": "2018-08-29T19:32:14.063Z",
        "privacy": "public",
        "pi": "guttmann@bwh.harvard.edu",
        "co-investigators": [
            "amoralespinzon@bwh.harvard.edu",
            "amarciniak@bwh.harvard.edu"
        ],
        "sourceSystem": "loris",
        "sourceObject": {
            "Meta": {
                "Project": "BRAVO"
            },
            "Candidates": [
                "100176",
                "102609",
                "102866",
                "103072",
                "106008",
                "106267",
                "107585",
                "107996",
                "109193",
                "109460",
                "109714",
                "114571",
                "115288",
                "117358",
                "117590",
                "118152",
                "119251",
                "119437",
                "120168",
                "120356",
                "120364",
                "121060",
                "123896",
                "125144",
                "125258",
                "125938",
                "127673",
                "127919",
                "128188",
                "128363",
                "129058",
                "129256",
                "129843",
                "131017",
                "133246",
                "135334",
                "135702",
                "137025",
                "137261",
                "141377",
                "141672",
                "141970",
                "142425",
                "142601",
                "142637",
                "142910",
                "144544",
                "145540",
                "145643",
                "145680",
                "145834",
                "146075",
                "146964",
                "148734",
                "148857",
                "149032",
                "149034",
                "149684",
                "150127",
                "150776",
                "151408",
                "152198",
                "152418",
                "152793",
                "153269",
                "154576",
                "157031",
                "157134",
                "157196",
                "157463",
                "158043",
                "158305",
                "159391",
                "159571",
                "161106",
                "161352",
                "161551",
                "161969",
                "162452",
                "162793",
                "163153",
                "163375",
                "163864",
                "164279",
                "164661",
                "165430",
                "165593",
                "166004",
                "166183",
                "167216",
                "167749",
                "168992",
                "169147",
                "169175",
                "169804",
                "169843",
                "170131",
                "170447",
                "170713",
                "171202",
                "171442",
                "172443",
                "172626",
                "172639",
                "172976",
                "173708",
                "174160",
                "174295",
                "175851",
                "178083",
                "179358",
                "180987",
                "181583",
                "182122",
                "182616",
                "182642",
                "183095",
                "184212",
                "184625",
                "185936",
                "186536",
                "186928",
                "187454",
                "187948",
                "188397",
                "188630",
                "189371",
                "189491",
                "191997",
                "192253",
                "193192",
                "193769",
                "195715",
                "196436",
                "196669",
                "196761",
                "196903",
                "197288",
                "197565",
                "197656",
                "198007",
                "198446",
                "198507",
                "198888",
                "199150",
                "200441",
                "200740",
                "201906",
                "203238",
                "203760",
                "204049",
                "204149",
                "204176",
                "204465",
                "205457",
                "205547",
                "206523",
                "206629",
                "207455",
                "208385",
                "208431",
                "211624",
                "211910",
                "213638",
                "213968",
                "214034",
                "214150",
                "215722",
                "216205",
                "217696",
                "218053",
                "218466",
                "218627",
                "219146",
                "219443",
                "220459",
                "220964",
                "221260",
                "223148",
                "224124",
                "225820",
                "225880",
                "227172",
                "227961",
                "228210",
                "228839",
                "229636",
                "230786",
                "230930",
                "231153",
                "233493",
                "233506",
                "233851",
                "234170",
                "234244",
                "234677",
                "236606",
                "236612",
                "237132",
                "237359",
                "237886",
                "238008",
                "239722",
                "240449",
                "240966",
                "241541",
                "241725",
                "242600",
                "242644",
                "243328",
                "243986",
                "244191",
                "244488",
                "244695",
                "245280",
                "245342",
                "246994",
                "249644",
                "249882",
                "250133",
                "250832",
                "251357",
                "251745",
                "253958",
                "254037",
                "255785",
                "256050",
                "259018",
                "259596",
                "259687",
                "259857",
                "260372",
                "260540",
                "260602",
                "260907",
                "261187",
                "261192",
                "262089",
                "262700",
                "263172",
                "263266",
                "263274",
                "264890",
                "265081",
                "265107",
                "265547",
                "267304",
                "269550",
                "270358",
                "270360",
                "270548",
                "270725",
                "271007",
                "271107",
                "273492",
                "273607",
                "273943",
                "273973",
                "274899",
                "275051",
                "275846",
                "275995",
                "276212",
                "276312",
                "276472",
                "277231",
                "279007",
                "279719",
                "280851",
                "281786",
                "282302",
                "284051",
                "284923",
                "285579",
                "285619",
                "286908",
                "287076",
                "287123",
                "287556",
                "287692",
                "287814",
                "289644",
                "290082",
                "290142",
                "290301",
                "290347",
                "290396",
                "290634",
                "291278",
                "291398",
                "294691",
                "295146",
                "295486",
                "296844",
                "297848",
                "298402",
                "299216",
                "299452",
                "300208",
                "301176",
                "301974",
                "303093",
                "303196",
                "305008",
                "305072",
                "305839",
                "305857",
                "307045",
                "307094",
                "308345",
                "309865",
                "311357",
                "312166",
                "312411",
                "312417",
                "316312",
                "316953",
                "317213",
                "318413",
                "320342",
                "320798",
                "320853",
                "320962",
                "321120",
                "321802",
                "322170",
                "324862",
                "325497",
                "325540",
                "326097",
                "326128",
                "326633",
                "327911",
                "329105",
                "329565",
                "330296",
                "331276",
                "331664",
                "331910",
                "332296",
                "332344",
                "334217",
                "335912",
                "336094",
                "337300",
                "337520",
                "337597",
                "338950",
                "339537",
                "341199",
                "341412",
                "341425",
                "341622",
                "341745",
                "342258",
                "342304",
                "342454",
                "342717",
                "342873",
                "343692",
                "345335",
                "345549",
                "345666",
                "346046",
                "346224",
                "346960",
                "347149",
                "348207",
                "348893",
                "350127",
                "350508",
                "350719",
                "353665",
                "355255",
                "357268",
                "357369",
                "357643",
                "359307",
                "361098",
                "363522",
                "365656",
                "366896",
                "367045",
                "367380",
                "368789",
                "371055",
                "371232",
                "371784",
                "372322",
                "373247",
                "373278",
                "373451",
                "373467",
                "373863",
                "374508",
                "375009",
                "376684",
                "377584",
                "377615",
                "377918",
                "378458",
                "379029",
                "379566",
                "379687",
                "380017",
                "380146",
                "380802",
                "381241",
                "381862",
                "385522",
                "386390",
                "386427",
                "386464",
                "386592",
                "386744",
                "386881",
                "388812",
                "389000",
                "389722",
                "390527",
                "390663",
                "392822",
                "393104",
                "393254",
                "393438",
                "394676",
                "395192",
                "395857",
                "396129",
                "396461",
                "397121",
                "397301",
                "398043",
                "398053",
                "398643",
                "398811",
                "398843",
                "399301",
                "401082",
                "401906",
                "402451",
                "402867",
                "403255",
                "405345",
                "406025",
                "406608",
                "407000",
                "409324",
                "410407",
                "411100",
                "411164",
                "412531",
                "413542",
                "413789",
                "414213",
                "414763",
                "415217",
                "415596",
                "415689",
                "416466",
                "416586",
                "417546",
                "418201",
                "419609",
                "420924",
                "421576",
                "422703",
                "422768",
                "423217",
                "423471",
                "425587",
                "425978",
                "426008",
                "427018",
                "427435",
                "428038",
                "428136",
                "429005",
                "429555",
                "429962",
                "432449",
                "432824",
                "433024",
                "433045",
                "435917",
                "436193",
                "436296",
                "436746",
                "436903",
                "437004",
                "437368",
                "438705",
                "439097",
                "440064",
                "440834",
                "441626",
                "442303",
                "442768",
                "443064",
                "443254",
                "444332",
                "445376",
                "445749",
                "445772",
                "446505",
                "446669",
                "446944",
                "447013",
                "448966",
                "449368",
                "450030",
                "450672",
                "450748",
                "451139",
                "451154",
                "451386",
                "451618",
                "451912",
                "452762",
                "453391",
                "455040",
                "455214",
                "455342",
                "455767",
                "455976",
                "456406",
                "458112",
                "458247",
                "458371",
                "458394",
                "459015",
                "460376",
                "461753",
                "461921",
                "462600",
                "462607",
                "463372",
                "463684",
                "465507",
                "465730",
                "466127",
                "467118",
                "467150",
                "467963",
                "468099",
                "468337",
                "468475",
                "468524",
                "468621",
                "469353",
                "470352",
                "470977",
                "472662",
                "473916",
                "474132",
                "474874",
                "474996",
                "475264",
                "475626",
                "476081",
                "476392",
                "478137",
                "478673",
                "478851",
                "479259",
                "479316",
                "479502",
                "480948",
                "482128",
                "482480",
                "482691",
                "482711",
                "483417",
                "483601",
                "483788",
                "485402",
                "485589",
                "485928",
                "486093",
                "490493",
                "492987",
                "493011",
                "493891",
                "494139",
                "494768",
                "495166",
                "495608",
                "497250",
                "497871",
                "499529",
                "501119",
                "502219",
                "503935",
                "504324",
                "504668",
                "505768",
                "505883",
                "505937",
                "506119",
                "506848",
                "507220",
                "507422",
                "507557",
                "508414",
                "508543",
                "508602",
                "509750",
                "510655",
                "511914",
                "513214",
                "513681",
                "513750",
                "515020",
                "516237",
                "516477",
                "517476",
                "517495",
                "518432",
                "520113",
                "520738",
                "521854",
                "522911",
                "523218",
                "523960",
                "524116",
                "525207",
                "526484",
                "527763",
                "529976",
                "530740",
                "530795",
                "531155",
                "531167",
                "531210",
                "531474",
                "531822",
                "532793",
                "532960",
                "533301",
                "534271",
                "534835",
                "536591",
                "537163",
                "537796",
                "538109",
                "538370",
                "538405",
                "538684",
                "538975",
                "538979",
                "540395",
                "540614",
                "540917",
                "543004",
                "543604",
                "543759",
                "544744",
                "544921",
                "545321",
                "548162",
                "551180",
                "551329",
                "552842",
                "553026",
                "553185",
                "556064",
                "556092",
                "556647",
                "556930",
                "559807",
                "559936",
                "560042",
                "561445",
                "561742",
                "562575",
                "562830",
                "564107",
                "564295",
                "564855",
                "565013",
                "565097",
                "565940",
                "566149",
                "567226",
                "567737",
                "568233",
                "569243",
                "569736",
                "570060",
                "570614",
                "572386",
                "572785",
                "573489",
                "573555",
                "574032",
                "574071",
                "574164",
                "574216",
                "575449",
                "575519",
                "576210",
                "576673",
                "578412",
                "579589",
                "579961",
                "580282",
                "580801",
                "580811",
                "581578",
                "582303",
                "582899",
                "582952",
                "583269",
                "584584",
                "585507",
                "585544",
                "585745",
                "585813",
                "586984",
                "587188",
                "587891",
                "588378",
                "588627",
                "589647",
                "590400",
                "592933",
                "593778",
                "593801",
                "594983",
                "595621",
                "599013",
                "600101",
                "601883",
                "603424",
                "604931",
                "605696",
                "607878",
                "608948",
                "608997",
                "609297",
                "610688",
                "610730",
                "610877",
                "612371",
                "613100",
                "613985",
                "614972",
                "615141",
                "615301",
                "616274",
                "617383",
                "617476",
                "619815",
                "622247",
                "622570",
                "622688",
                "622830",
                "623179",
                "623864",
                "625463",
                "625708",
                "626455",
                "626774",
                "626812",
                "627199",
                "627470",
                "627473",
                "627544",
                "628644",
                "631065",
                "631249",
                "634204",
                "635642",
                "635770",
                "635866",
                "636669",
                "638839",
                "639106",
                "639219",
                "639694",
                "640442",
                "642192",
                "642429",
                "642441",
                "642582",
                "642742",
                "643177",
                "643518",
                "644657",
                "644745",
                "644955",
                "645035",
                "645202",
                "646212",
                "647062",
                "647157",
                "648158",
                "650984",
                "652858",
                "654965",
                "655791",
                "658137",
                "658672",
                "659634",
                "660833",
                "661281",
                "662632",
                "662956",
                "663221",
                "663622",
                "665131",
                "665158",
                "665502",
                "665594",
                "667054",
                "667479",
                "670430",
                "670675",
                "673475",
                "674812",
                "674908",
                "675744",
                "676144",
                "676883",
                "677803",
                "678374",
                "681649",
                "682150",
                "683168",
                "683374",
                "683805",
                "685004",
                "686420",
                "686501",
                "686503",
                "686512",
                "687532",
                "688158",
                "689232",
                "689602",
                "690088",
                "691068",
                "691523",
                "691815",
                "692689",
                "693751",
                "693916",
                "694059",
                "694147",
                "695431",
                "696212",
                "697675",
                "697826",
                "699192",
                "700986",
                "701856",
                "702447",
                "702602",
                "702778",
                "704292",
                "706432",
                "706934",
                "707310",
                "708018",
                "708789",
                "709023",
                "709764",
                "709896",
                "710014",
                "710881",
                "711762",
                "713471",
                "713561",
                "713860",
                "713917",
                "714317",
                "716210",
                "717076",
                "717330",
                "718887",
                "719059",
                "723249",
                "723709",
                "723777",
                "723980",
                "724313",
                "725585",
                "726171",
                "727551",
                "728398",
                "728898",
                "729317",
                "730442",
                "731699",
                "732066",
                "732352",
                "732509",
                "732759",
                "733759",
                "734268",
                "736050",
                "736150",
                "736219",
                "736602",
                "737585",
                "738340",
                "739605",
                "739842",
                "740384",
                "741431",
                "741975",
                "742364",
                "742866",
                "744668",
                "744797",
                "746114",
                "746639",
                "748043",
                "748313",
                "749370",
                "749739",
                "749952",
                "750067",
                "750087",
                "750934",
                "751239",
                "752391",
                "753271",
                "754204",
                "754527",
                "754843",
                "755389",
                "755671",
                "757268",
                "759108",
                "759143",
                "760082",
                "760503",
                "761538",
                "761696",
                "762462",
                "763041",
                "764131",
                "764415",
                "765183",
                "766662",
                "766974",
                "768051",
                "768421",
                "769672",
                "770021",
                "770520",
                "770633",
                "771244",
                "771944",
                "772240",
                "772352",
                "772362",
                "772463",
                "773393",
                "775086",
                "776379",
                "776954",
                "777724",
                "779005",
                "779307",
                "779529",
                "779535",
                "781236",
                "781316",
                "782501",
                "783294",
                "783499",
                "784124",
                "785080",
                "785095",
                "785206",
                "785723",
                "786071",
                "786073",
                "786180",
                "786465",
                "786743",
                "787068",
                "787293",
                "787654",
                "788610",
                "789561",
                "789655",
                "790442",
                "792349",
                "792795",
                "792815",
                "793984",
                "797466",
                "798694",
                "799401",
                "801815",
                "803120",
                "804136",
                "806566",
                "807675",
                "807688",
                "808209",
                "809104",
                "811004",
                "811566",
                "811617",
                "813335",
                "813720",
                "814195",
                "815416",
                "816278",
                "818118",
                "818428",
                "819059",
                "819667",
                "822909",
                "823313",
                "823944",
                "824174",
                "824575",
                "824765",
                "824905",
                "826527",
                "826756",
                "827075",
                "827221",
                "827640",
                "828512",
                "828528",
                "830238",
                "831786",
                "831833",
                "831866",
                "832658",
                "832749",
                "833442",
                "836170",
                "837193",
                "837725",
                "837889",
                "838054",
                "838229",
                "839157",
                "839547",
                "840144",
                "840414",
                "840560",
                "841438",
                "842778",
                "843487",
                "844283",
                "844526",
                "845439",
                "845535",
                "846093",
                "847439",
                "847550",
                "848013",
                "848618",
                "848823",
                "849327",
                "849938",
                "850250",
                "850306",
                "851185",
                "851582",
                "851621",
                "852387",
                "853078",
                "853640",
                "853741",
                "854412",
                "854598",
                "854929",
                "855676",
                "857190",
                "858496",
                "858583",
                "858705",
                "859798",
                "862857",
                "863165",
                "863417",
                "863889",
                "863973",
                "864646",
                "865662",
                "866152",
                "866581",
                "866671",
                "867167",
                "869296",
                "869653",
                "869722",
                "869910",
                "870048",
                "870241",
                "870395",
                "870802",
                "870902",
                "872065",
                "874202",
                "875809",
                "876736",
                "878522",
                "878929",
                "880722",
                "880779",
                "881004",
                "881293",
                "882728",
                "883851",
                "884032",
                "885511",
                "886064",
                "886107",
                "887749",
                "888443",
                "888465",
                "888923",
                "889700",
                "889883",
                "890561",
                "891067",
                "891934",
                "893102",
                "893657",
                "894641",
                "896369",
                "897922",
                "898288",
                "898642",
                "899037",
                "899744",
                "901804",
                "901982",
                "902284",
                "903035",
                "905783",
                "906069",
                "906776",
                "906874",
                "907917",
                "908317",
                "908439",
                "908728",
                "909801",
                "910334",
                "910558",
                "911007",
                "911698",
                "917369",
                "917561",
                "918188",
                "918313",
                "918479",
                "918601",
                "918942",
                "918999",
                "919450",
                "919824",
                "919928",
                "920591",
                "921758",
                "922039",
                "923190",
                "923695",
                "925034",
                "925777",
                "925824",
                "926242",
                "928105",
                "928687",
                "930367",
                "930487",
                "932998",
                "934082",
                "935237",
                "935301",
                "936128",
                "937114",
                "937242",
                "937539",
                "938428",
                "938440",
                "938788",
                "938874",
                "939632",
                "940096",
                "941992",
                "942433",
                "944707",
                "944822",
                "944839",
                "945064",
                "945233",
                "946176",
                "946625",
                "947538",
                "949239",
                "950343",
                "950353",
                "950702",
                "950794",
                "951522",
                "951767",
                "952148",
                "952457",
                "952629",
                "954460",
                "955495",
                "956479",
                "956634",
                "958163",
                "959104",
                "959641",
                "959713",
                "960112",
                "960685",
                "960969",
                "961016",
                "961750",
                "964976",
                "965210",
                "966403",
                "967966",
                "969193",
                "969437",
                "969753",
                "969864",
                "970395",
                "970834",
                "970849",
                "973338",
                "975029",
                "976739",
                "977606",
                "977901",
                "978010",
                "978609",
                "978789",
                "978792",
                "979252",
                "979599",
                "979675",
                "979907",
                "981725",
                "982501",
                "983763",
                "983980",
                "984029",
                "984331",
                "984605",
                "985406",
                "985707",
                "986136",
                "988037",
                "988232",
                "988931",
                "989298",
                "991703",
                "991836",
                "993085",
                "993883",
                "994046",
                "995345",
                "995853",
                "998062",
                "998220",
                "998663",
                "999536",
                "999931"
            ],
            "Instruments": [
                "9HPT",
                "Attack",
                "BLCVA",
                "ClinicalComment",
                "EDSS",
                "FSS",
                "MRI",
                "PASAT",
                "SDMT",
                "T25FW",
                "VisitInfo",
                "Ambulation"
            ],
            "Visits": [
                "BRAVO_ATTACK_v601",
                "BRAVO_ATTACK_v602",
                "BRAVO_ATTACK_v603",
                "BRAVO_ATTACK_v604",
                "BRAVO_ATTACK_v605",
                "BRAVO_ATTACK_v606",
                "BRAVO_ATTACK_v607",
                "BRAVO_CLINICAL_v000",
                "BRAVO_CLINICAL_v003",
                "BRAVO_CLINICAL_v004",
                "BRAVO_CLINICAL_v005",
                "BRAVO_CLINICAL_v006",
                "BRAVO_CLINICAL_v007",
                "BRAVO_CLINICAL_v008",
                "BRAVO_CLINICAL_v009",
                "BRAVO_CLINICAL_v010",
                "BRAVO_CLINICAL_v601",
                "BRAVO_CLINICAL_v602",
                "BRAVO_CLINICAL_v603",
                "BRAVO_CLINICAL_v604",
                "BRAVO_CLINICAL_v605",
                "BRAVO_CLINICAL_v606",
                "BRAVO_CLINICAL_v607",
                "BRAVO_CLINICAL_v608",
                "BRAVO_CLINICAL_v609",
                "BRAVO_CLINICAL_v610",
                "BRAVO_CLINICAL_v611",
                "BRAVO_CLINICAL_v612",
                "BRAVO_CLINICAL_v613",
                "BRAVO_CLINICAL_vN01",
                "BRAVO_MRI_v000",
                "BRAVO_MRI_v006",
                "BRAVO_MRI_v010",
                "m0",
                "m00",
                "m12",
                "m24",
                "mET"
            ]
        },
        "sourceEtag": "1de6b316dc408168557545e57f506cc5",
        "uuid": "7166bae11fe4b5742fc27bf89f000ea8",
        "subjects": {
            "count": 14
        }
    };

const studiesMock=[
    {name:'BRAVO',value:'2802b284-da5b-4283-50bb-f338bdb77a77',uuid:'2802b284-da5b-4283-50bb-f338bdb77a77'},
    {name:'Wolfson',value:'2802b284-da5b-4283-50bb-f338bdb77a78',uuid:'2802b284-da5b-4283-50bb-f338bdb77a78'},
    {name:'Wolfson2',value:'2802b284-da5b-4283-50bb-f338bdb77a79',uuid:'2802b284-da5b-4283-50bb-f338bdb77a79'}];


export const MOCK_LINK_TO_G35 = "https://www.icd10data.com/ICD10CM/Codes/G00-G99/G35-G37/G35-/G35";
export const MOCK_LINK_TO_KEYS = "https://www.icd10data.com/search?s=";
export const MOCK_LINK_TO_COHORT = "https://clinicaltrials.gov/ct2/show/NCT00605215";
export const MOCK_CODE = "G35";
export const MOCK_KEYWORDS="Multiple Sclerosis, MRI";
export const MOCK_COHORT_DESCRIPTION = "This cohort contains MS cases. " +
    "The Alzheimer’s Disease Neuroimaging Initiative (ADNI) unites researchers " +
    "with study data as they work to define the progression of Alzheimer’s disease (AD). ADNI researchers collect, " +
    "validate and utilize data, including MRI and PET images, genetics, cognitive tests, CSF and blood biomarkers as " +
    "predictors of the disease. Study resources and data from the North American ADNI study are available through this" +
    " website, including Alzheimer’s disease patients, mild cognitive impairment subjects, and elderly controls.";

export const MOCK_COHORT_PI= "Charles Guttmann";


import React from "react";
import {Card} from "primereact/components/card/Card";
import PropTypes from "prop-types";
import {
    EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__WORKFLOW_ID,
    EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES,
} from "../../../Constants";
import {withRouter} from "react-router";

/**Renderer for "Cards" mode for Workflowss.
 *
 * @param item - mandatory component if used with ItemList container
 * @param wfType - type of workflow - automatic, semi, manual
 * @param dialogShowClbk - callback to dialogShow in Parent
 * @param selectClbk  - callback for selection
 * @param variable - reference to variable object
 * @param variableIndex - index of variable (from row)
 * @param experiment - reference to Redux experiment object
 * @returns {*}
 * @constructor
 */
const WorkflowItemCardElement = ({item,dialogShowClbk,wfType,selectClbk,variable,variableIndex,experiment}) => {
    const workflowType = (wfType!=null)?wfType:'automatic';
    const freeLogo = <div className="wrkFreesurferLogo" title="Freesurfer logo"/>;
    const checkIfAlreadySet=()=> {
        try {
            return experiment[EXPERIMENT_PROPERTY_NAME__MODEL_VARIABLES][variableIndex][EXPERIMENT_INSTANCE_VARIABLE_PROPERTY_NAME__WORKFLOW_ID] === item['uuid']
        }
        catch(error){
            return false;
        }
    };
    const classNames=(checkIfAlreadySet())?"experimentCardSelected":"experimentCard";


    return (
        <Card title={item.name} className={classNames} header={freeLogo}>
            <div  onClick={()=>selectClbk(item,variable)}  title={item.description}>
                <div style={{display:'flex'}}>
                    <div style={{padding: '1em', lineHeight: '1.5', width: 'calc(100% - 40px)'}}/>
                </div>
                <hr/>
                <div>
                    <div style={{fontSize:'large'}}>Estimated cost per case:
                        <i className="fa fa-usd" style={{fontSize:'x-large'}}/></div>
                    <div style={{fontSize:'large'}}>Estimated time per case: <i className="fa fa-clock-o" /></div>
                    <div style={{fontSize:'large'}}>Matched cases: 2900<i className="fa fa-user-md" /></div>
                    <div style={{fontSize:'large'}}>Calculated cases: 2900<i className="fa fa-user-md" /></div>
                    <i style={{fontSize:'x-large'}} className="fa fa-cogs cogHover" onClick={()=>dialogShowClbk(item)} title="Edit/View workflow"/>
                </div>
            </div>
        </Card>
    )
};

export default withRouter(WorkflowItemCardElement);

WorkflowItemCardElement.propTypes = {
    item: PropTypes.object.isRequired
};

import React from 'react';
import PropTypes from 'prop-types';
import vtkRenderWindow from 'vtk.js/Sources/Rendering/Core/RenderWindow';
import vtkRenderer from 'vtk.js/Sources/Rendering/Core/Renderer';
import vtkImageMapper from 'vtk.js/Sources/Rendering/Core/ImageMapper';
import vtkImageSlice from 'vtk.js/Sources/Rendering/Core/ImageSlice';
import vtkOpenGLRenderWindow from 'vtk.js/Sources/Rendering/OpenGL/RenderWindow';
import vtkRenderWindowInteractor from 'vtk.js/Sources/Rendering/Core/RenderWindowInteractor';
import * as SpineInteractorStyleImage from "./SpineInteractorStyleImage";
import vtkCellPicker from 'vtk.js/Sources/Rendering/Core/CellPicker';
import {Slider} from "primereact/components/slider/Slider";
import vtkColorTransferFunction from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction';
import vtkColorMaps from 'vtk.js/Sources/Rendering/Core/ColorTransferFunction/ColorMaps';
import vtkPiecewiseFunction from 'vtk.js/Sources/Common/DataModel/PiecewiseFunction';
import Meters from "./Meters";
import vtkSpineImageSliceFilter from "./SpineImageSliceFilter";
import vtkScalarToRGBA from "./SpineScalarToRGBA";
import vtkTexture from "vtk.js/Sources/Rendering/Core/Texture/index";
import vtkPlaneSource from "vtk.js/Sources/Filters/Sources/PlaneSource/index";
import vtkActor from "vtk.js/Sources/Rendering/Core/Actor/index";
import vtkMapper from "vtk.js/Sources/Rendering/Core/Mapper/index";
import {calculateOriginBiasIJK, setOverlaySlice} from "./OverlayCalculations";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../Constants";


const PLANES = {SAGITTAL: 0, CORONAL: 1, AXIAL: 2};

/** BrainBrowser-like component for displaying 3 D slices.
 *  This is the version wit updatable overlay!!!
 *
 *  See props at the bottom.
 */
class SpineVtkMRIDynamicBrowser extends React.Component {

    constructor() {
        super();
        this.state = {
            renderWindow: vtkRenderWindow.newInstance(),
            originTranslation: [],
            isError: false,
            errorMessage: null,
            dataRange: [0, 128],
            dataRange2: [0, 1], //binary
            extent: [0, 10000, 0, 10000, 0, 10000], //range of slice indices for original image
            currentImageSlice: [0, 0, 0],
            overlayOpacity: 1.0,
            colorLevel: 128,
            colorWindow: 100,
            imageActors: [vtkImageSlice.newInstance(), vtkImageSlice.newInstance(), vtkImageSlice.newInstance()],
            //replace to ImageSlice after translucency fixed!!!:
            overlayActors: [vtkActor.newInstance(), vtkActor.newInstance(), vtkActor.newInstance()],
            //to remove after translucency fixed!!!:
            textures: [vtkTexture.newInstance(), vtkTexture.newInstance(), vtkTexture.newInstance()],
            planeSources: [vtkPlaneSource.newInstance(), vtkPlaneSource.newInstance(), vtkPlaneSource.newInstance()],
            sliceFilters: [vtkSpineImageSliceFilter.newInstance({sliceIndex: 0}), vtkSpineImageSliceFilter.newInstance({sliceIndex: 0}), vtkSpineImageSliceFilter.newInstance({sliceIndex: 0})],
            rgbaFilters: [vtkScalarToRGBA.newInstance(), vtkScalarToRGBA.newInstance(), vtkScalarToRGBA.newInstance()]
        };
        ["onWLRangeChange", "onCLRangeChange", "onOverlayOpacityChange","changeSlice","setSlices","loadOverlayImage",
        "loadOriginalImage","clearOverlay"].forEach(name => {
                this[name] = this[name].bind(this);
            }); //Binding methods
    }

    static generateColorTransferFunction(dataRange2, heatmap) {
        if (heatmap != null) {
            const lookupTable = vtkColorTransferFunction.newInstance();
            const preset = vtkColorMaps.getPresetByName(heatmap);
            lookupTable.applyColorMap(preset);
            lookupTable.setMappingRange(...dataRange2);
            lookupTable.updateRange();
            return lookupTable;
        }
        const ctfun = vtkColorTransferFunction.newInstance();
        ctfun.addRGBPoint(dataRange2[0], 0.0, 0.0, 0.0);
        ctfun.addRGBPoint(dataRange2[0] + 0.1, 1.0, 0.0, 0.0);
        ctfun.addRGBPoint(dataRange2[1], 1.0, 0.0, 0.0);
        return ctfun;

    }

    componentDidMount() {
        this.createBrowser();
    }

    componentDidUpdate(previousProps, previousState) {
        const {mriImage,mriImageState,ovImage, ovImageState} =this.props;
        if (previousProps.mriImage !== mriImage ) {
            if (REQUEST_STATUS_SUCCESS.includes(mriImageState))
                this.loadOriginalImage();
            if (this.props.ovImage != null && REQUEST_STATUS_SUCCESS.includes(ovImage)) {
                this.loadOverlayImage();
            }
        }
        if (previousProps.ovImage !== this.props.ovImage) {
            if (this.props.mriImage != null && REQUEST_STATUS_SUCCESS.includes(mriImageState)) {
                if (this.props.ovImage != null && REQUEST_STATUS_SUCCESS.includes(ovImageState)){
                    this.clearOverlay();
                    this.loadOverlayImage();
                }else
                   this.clearOverlay();
            }
        }
    }

    clearOverlay(){
        if (this.state.renderWindow.getRenderers()[0].getActors()!=null && this.state.renderWindow.getRenderers()[0].getActors().length>1)
        this.state.renderWindow.getRenderers()[0].removeActor(this.state.renderWindow.getRenderers()[0].getActors()[1]);
        this.refreshRenderWindow();
    }
    /**
     * Window Level Range onChange handler.
     * Controls the window in a window level mapping of the input image. Window
     * level mapping is a technique to map the raw data values of an image
     * into screen intensities in a manner akin to
     *
     * pixelIntensity = (inputValue - level)/window;
     *
     * @param event
     */
    onWLRangeChange(event) {
        const cl = Number((event ? event : this.state.colorWindow).value);
        this.changeWL(cl);
        this.refreshRenderWindow();
    }

    /**
     * Change Window Level
     * @param wl - value
     */
    changeWL(wl) {
        this.state.imageActors.forEach((s) => {
            s.getProperty().setColorWindow(wl)
        });
        this.setState({colorWindow: wl});
    }

    /**
     * Color Level Range onChange handler.
     * @see above
     * @param event
     */
    onCLRangeChange(event) {
        const cl = Number((event ? event : this.state.colorLevel).value);
        this.changeCL(cl);
        this.refreshRenderWindow();
    }

    /**
     * Change Color Level
     * @param cl - value
     */
    changeCL(cl) {
        this.state.imageActors.forEach((s) => {
            s.getProperty().setColorLevel(cl)
        });
        this.setState({colorLevel: cl});
    }

    /**
     * Handler for slice change [k=2]
     * @param event
     */
    changeSlice(event) {
        let cis = this.state.currentImageSlice;
        cis[2] = event.value;
        this.setState({currentImageSlice: cis});
        this.state.imageActors[2].getMapper().setSlice(event.value);
        this.setSlices(2);
        this.refreshRenderWindow();
    }

    /**
     * Handler for overlay opacity slider
     * @param event
     */
    async onOverlayOpacityChange(event) {
        this.setState({overlayOpacity: event.value / 100});
        if (this.state.isError) return;
        this.state.rgbaFilters.forEach((s) => {
            s.setPiecewiseFunction(this.generateOpacityFunction(this.state.dataRange2));
        });
        Object.keys(PLANES).forEach((s, idx) => this.updateTextures(idx));
        this.refreshRenderWindow();
    }


    refreshRenderWindow() {
        this.state.renderWindow.render();
    }

    /**
     * @param idx -  index of renderer
     */
    setSlices(idx) {

        let cis = this.state.currentImageSlice;
        cis[idx] = this.state.imageActors[idx].getMapper().getSlice();
        if (this.state.isError) return;
        if (this.props.ovImageState === REQUEST_STATUS_SUCCESS) {
            const {sF, pS} = setOverlaySlice(idx, cis, this.props.mriImage, this.props.ovImage, this.state.sliceFilters,
                this.state.planeSources, this.state.originTranslation, this.props.ovImage.getDirection());
            this.setState({sliceFilters: sF, planeSources: pS});
            this.updateTextures(idx);
        }
    }


    /**
     * Update texture after any change.
     *
     * To remove when sliceImageFilter is replaced with
     *
     * @param idx - index of renderer
     */
    updateTextures(idx) {
        let txt = this.state.textures;
        txt[idx].modified();  //IMPORTANT
        this.setState({textures: txt});
    }

    loadOverlayImage() {
        const data = this.props.ovImage;
        const port = this.props.ovPort;
        const sliceFilters = this.state.sliceFilters;
        const textures = this.state.textures;
        const rgbaFilters = this.state.rgbaFilters;
        const planeSources = this.state.planeSources;
        const actors = this.state.overlayActors;
        const mappers = [vtkMapper.newInstance(), vtkMapper.newInstance(), vtkMapper.newInstance()];

        sliceFilters.forEach((s) => {
            s.setInputConnection(port);
        });

        const transl = calculateOriginBiasIJK(this.props.mriImage.getOrigin(), this.props.ovImage.getOrigin(),
            this.props.mriImage.getSpacing(), this.props.ovImage.getDirection());
        this.setState({originTranslation: transl}); //setting translation between origins

        // loggingDataset(data);

        const scalars = data.getPointData().getScalars();

        const dataRange2 = [].concat(scalars ? scalars.getRange() : [0, 1]);

        this.setState({dataRange2: dataRange2});

        //=======    setting colors&opacity ===========================
        rgbaFilters.forEach((rgbaFilter, i) => {
            rgbaFilter.setLookupTable(SpineVtkMRIDynamicBrowser.generateColorTransferFunction(dataRange2, this.props.heatmap));
            rgbaFilter.setPiecewiseFunction(this.generateOpacityFunction(dataRange2));
            rgbaFilter.setInputConnection(sliceFilters[i].getOutputPort());
        });

        textures.forEach((texture, idx) => {
            texture.setInputConnection(rgbaFilters[idx].getOutputPort());
        });

        //=======    positioning planes===========================
        mappers.forEach((m, idx) => {
            this.setSlices(idx);//XXX
            m.setInputConnection(planeSources[idx].getOutputPort());
        });

        actors.forEach((a, idx) => {
            a.setMapper(mappers[idx]);
            a.addTexture(textures[idx]);
        });
        this.state.renderWindow.getRenderers()[0].resetCamera();
        this.state.renderWindow.getRenderers()[0].addActor(actors[2]);

        this.refreshRenderWindow();
    };

    loadOriginalImage() {
        const data = this.props.mriImage;
        const imageActors = this.state.imageActors;
        const imageMappers = [vtkImageMapper.newInstance(), vtkImageMapper.newInstance(), vtkImageMapper.newInstance()];
        let initialSlices = [0, 0, 0];
        console.log('Loading original image');
        // loggingDataset(data);
        const dataRange = data
            .getPointData()
            .getScalars()
            .getRange();

        this.changeWL(dataRange[1] - dataRange[0]);
        this.changeCL(dataRange[0] + (dataRange[1] - dataRange[0]) * .5);
        this.setState({dataRange: dataRange});
        this.setState({extent: data.getExtent()});

        //  if initial slices is prop, then use it, otherwise set in the middle of volume
        if (this.props.initialSlices != null && this.props.initialSlices.length === 3)
            initialSlices = this.props.initialSlices;
        else {
            for (let i = 0; i < 3; i++)
                initialSlices[i] = Math.floor((this.state.extent[i * 2 + 1] - this.state.extent[i * 2]) / 2);
        }
        imageMappers.forEach((s, idx) => {
            s.setInputData(data);
            if (idx === 0)
                s.setISlice(initialSlices[idx]);
            if (idx === 1)
                s.setJSlice(initialSlices[idx]);
            if (idx === 2)
                s.setKSlice(initialSlices[idx]);
        });

        this.setState({currentImageSlice: initialSlices});
        imageActors.forEach((s, idx) => {
            s.setMapper(imageMappers[idx]);
            s.getProperty().setOpacity(1.0);//!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
        });

        this.state.renderWindow.getRenderers()[0].getActiveCamera().set({position: [0, 0, -1]});
        this.state.renderWindow.getRenderers()[0].getActiveCamera().set({viewUp: [0, -1, 0]});
        this.state.renderWindow.getRenderers()[0].resetCamera();
        this.setState({colorLevel: (dataRange[0] + dataRange[1]) / 2});
        this.refreshRenderWindow();

    };


    createBrowser() {
        let thisView = this;  // this autoreference is needed for closures
        const kRenderer = vtkRenderer.newInstance();
        kRenderer.setViewport([0, 0, 1, 1]);
        let size = [this.props.viewportSize, this.props.viewportSize];
        const interactorStyle2D = SpineInteractorStyleImage.newInstance();
        interactorStyle2D.setInteractionMode('IMAGE_SLICING');
        const renderWindow = thisView.state.renderWindow;
        const openglRenderWindow = vtkOpenGLRenderWindow.newInstance({size: size});
        renderWindow.addView(openglRenderWindow);
        openglRenderWindow.setContainer(this.node);
        const interactor = vtkRenderWindowInteractor.newInstance();
        interactor.setInteractorStyle(interactorStyle2D); // in case using predefined styles - SpineIneractorStyleImage
        interactor.setView(openglRenderWindow);
        interactor.initialize();
        interactor.bindEvents(this.node);

        const picker = vtkCellPicker.newInstance();
        renderWindow.getInteractor().setPicker(picker);
        const imageActors = this.state.imageActors;
        renderWindow.addRenderer(kRenderer);
        kRenderer.setBackground(0, 0, 0);
        kRenderer.getActiveCamera().setParallelProjection(true); // if true keeps constant distance between camera and slice
        kRenderer.addActor(imageActors[2]);
        if (this.props.mriImageState === REQUEST_STATUS_SUCCESS) this.loadOriginalImage();
        if (this.props.mriImageState === REQUEST_STATUS_SUCCESS && this.props.ovImageState === REQUEST_STATUS_SUCCESS) this.loadOverlayImage();

    }

    generateOpacityFunction(dataRange2) {
        const ofun = vtkPiecewiseFunction.newInstance();
        ofun.removeAllPoints();
        ofun.addPoint(dataRange2[0], 0.0);
        ofun.addPoint((dataRange2[0] + dataRange2[1]) * 0.5, 0.1 * this.state.overlayOpacity);
        ofun.addPoint(dataRange2[1], this.state.overlayOpacity);
        return ofun;
    }

    render() {
        const  {mriImageState,ovImageState}=this.props;
        let imageSlice = (mriImageState==="" || REQUEST_STATUS_REQUESTED.includes(mriImageState) || (REQUEST_STATUS_REQUESTED.includes(ovImageState)))?
                 <i className="fa fa-spinner fa-spin"/>:this.state.currentImageSlice[2];
        let exclamation = <div> Connection error: <i className="fa fa-exclamation"/></div>;

        return (
            <div className={"ui-g-12"}>
                { (REQUEST_STATUS_FAIL.includes(mriImageState) )&& exclamation}
                <div className={"ui-g-12"}>
                    <div className={"ui-g-11"}>
                        <Slider value={this.state.currentImageSlice[2]} min={0} max={this.state.extent[2 * 3 - 1]}
                                onChange={this.changeSlice}/>
                    </div>
                    <div className={"ui-g-1"}>
                        {imageSlice}
                    </div>
                </div>

                <div ref={node => this.node = node}/>

                <div className={"ui-g-4"}>
                    <div>Level</div>
                    <Slider value={this.state.colorLevel} min={this.state.dataRange[0]} max={this.state.dataRange[1]}
                            onChange={this.onCLRangeChange}/>
                </div>
                <div className={"ui-g-4"}>
                    <div>Window</div>
                    <Slider value={this.state.colorWindow} min={1} max={this.state.dataRange[1]}
                            onChange={this.onWLRangeChange}/>
                </div>
                <div className={"ui-g-4"}>
                    <div>ROI's opacity</div>
                    <Slider value={this.state.overlayOpacity * 100} max={100} min={0}
                            onChange={this.onOverlayOpacityChange}/>
                </div>
                {!this.state.isError
                && this.props.showMeters &&
                <Meters {...this.state} {...this.props}/>}
            </div>
        );

    }
}

SpineVtkMRIDynamicBrowser.defaultProps = {
    viewportSize: 250,
    progressBars: false,  // should progress bar be dispolayed
    showMeters: false
};
SpineVtkMRIDynamicBrowser.propTypes = {
    viewportSize: PropTypes.number,
    viewportMode: PropTypes.string, //'horizontal' or 'square' or 'axial' only NOT IMPLEMENTED
    initialSlices: PropTypes.array,
    progressBars: PropTypes.bool, // whether progress bar or spinner  should be displayed (when server is not returning size)
    onLoadingError: PropTypes.func, // callback to parent component for handling errors (alert, growl, whatever)
    heatmap: PropTypes.string, //'erdc_rainbow_bright',
    mriImage: PropTypes.object,
    showMeters: PropTypes.bool.isRequired,
    mriImageState: PropTypes.string,
    ovImage: PropTypes.object,
    ovImageState: PropTypes.string,
    ovPort:PropTypes.func
};

export default SpineVtkMRIDynamicBrowser;
import React from 'react';
import {Accordion, AccordionTab} from 'primereact/components/accordion/Accordion';
import PropTypes from "prop-types";
import SpineVtkMRIBrowser from "./SpineVtkMRIBrowser";

/**
 * Displays parameters of render window, renderers, actors and mappers.
 * Styles defined in _layout.scss and Prime CSS.
 */
class Meters extends React.Component {


    render() {
        let volumeRange =this.props.imageData
            .getPointData()
            .getScalars()
            .getRange();
        let lmRange =this.props.overlayData
            .getPointData()
            .getScalars()
            .getRange();

        return (

            <div className="ui-g-12">
                <Accordion multiple={false}>
                    <AccordionTab header="Data probe">

                            <div className="ui-g-6 gaugetable">
                                Volume extent : ({this.props.imageData.getExtent()[0]}-{this.props.imageData.getExtent()[1]},
                                {this.props.imageData.getExtent()[4]}-{this.props.imageData.getExtent()[5]},
                                {this.props.imageData.getExtent()[2]}-{this.props.imageData.getExtent()[3]})
                            </div>
                            <div className="ui-g-6 gaugetable">
                                Label map extent: ({this.props.overlayData.getExtent()[0]}-{this.props.overlayData.getExtent()[1]},
                                {this.props.overlayData.getExtent()[4]}-{this.props.overlayData.getExtent()[5]},
                                {this.props.overlayData.getExtent()[2]}-{this.props.overlayData.getExtent()[3]})
                            </div>
                            <div className="ui-g-6 gaugetable">
                                Volume range : ({volumeRange[0]}-{volumeRange[1]})
                            </div>
                            <div className="ui-g-6 gaugetable">
                                Label map range: ({lmRange[0]}-{lmRange[1]})
                            </div>
                            <div className="ui-g-6 gaugetable">
                                Spacing h:  {this.props.imageData.getSpacing()[0]}
                            </div>
                            <div className="ui-g-6 gaugetable">
                                Spacing v:  {this.props.imageData.getSpacing()[0]}
                            </div>

                            {/*<div className="ui-g-3 gaugetable">*/}
                                {/*Current position (IJK) [XYZ]:*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-3 gaugetable">*/}
                                {/*({this.props.currentImageSlice[0]}, {this.props.currentImageSlice[2]}, {this.props.currentImageSlice[1]})*/}

                            {/*</div>*/}
                            {/*<div className="ui-g-2 gaugetable">*/}
                                {/*Clipping Range K:*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-4 gaugetable">*/}
                                {/*Real coords:{this.props.renderWindow.getRenderers()[2] != null &&*/}
                            {/*this.props.renderWindow.getRenderers()[0].getActiveCamera().getClippingRange().join(',  ')}*/}

                            {/*</div>*/}
                            {/*<div className="ui-g-3 gaugetable">*/}
                                {/*Current color level:*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-3 gaugetable">*/}
                                {/*{this.props.colorLevel},*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-3 gaugetable">*/}
                                {/*Overlay opacity:*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-3 gaugetable">*/}
                                {/*{this.props.overlayOpacity},*/}
                            {/*</div>*/}

                            {/*<div className="ui-g-3 gaugetable">*/}
                                {/*Current color window:*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-3 gaugetable">*/}
                                {/*{this.props.colorWindow},*/}
                            {/*</div>*/}

                            {/*<div className="ui-g-3 gaugetable">*/}
                                {/*Data range:*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-3 gaugetable">*/}
                                {/*{this.getDataRange().join(', ')}*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-1 gaugetable">*/}
                                {/*Current and (original) direction:*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-5 gaugetable">*/}
                                {/*{this.getActorDataDirection().map((s) => {*/}
                                    {/*return s.toFixed(3)*/}
                                {/*}).join(', ')}*/}
                                {/*({this.props.direction.map((s) => {*/}
                                {/*return s.toFixed(3)*/}
                            {/*}).join(', ')})*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-1 gaugetable">*/}
                                {/*Current and (original) direction:*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-5 gaugetable">*/}
                                {/*/!*{this.getVolumeDataDirection().join(', ')}*!/*/}
                                {/*/!*({this.props.overlayDirection.map((s) => {*!/*/}
                                {/*/!*return s.toFixed(3)*!/*/}
                                {/*/!*}).join(', ')})*!/*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-1 gaugetable">*/}
                                {/*Bounds:*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-5 gaugetable">*/}
                                {/*{this.getActorBounds().join(', ')}*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-1 gaugetable">*/}
                                {/*Bounds:*/}
                            {/*</div>*/}
                            {/*/!*<div className="ui-g-5 gaugetable">*!/*/}
                            {/*/!*{this.getVolumeBounds().join(', ')}*!/*/}
                            {/*/!*</div>*!/*/}
                            {/*<div className="ui-g-4 gaugetable">*/}
                                {/*Camera position for K window (middle):*/}
                            {/*</div>*/}
                            {/*<div className="ui-g-8 gaugetable">*/}
                                {/*Position:{this.props.renderWindow.getRenderers()[0] != null &&*/}
                            {/*this.props.renderWindow.getRenderers()[0].getActiveCamera().getPosition().join(',  ')}*/}
                                {/*<br/>*/}

                                {/*Focal Point:{this.props.renderWindow.getRenderers()[0] != null &&*/}
                            {/*this.props.renderWindow.getRenderers()[0].getActiveCamera().getFocalPoint().join(',  ')}*/}
                            {/*</div>*/}

            </AccordionTab>
        </Accordion>
            </div>
        );
    }
}

Meters.propTypes = {
    imageData: PropTypes.object.isRequired,
    overlayData: PropTypes.object.isRequired
};
export default Meters;
import {getSorter, getVisibilityFilter, getVisibleModels} from "../selectors/ModelSelectors";
import {setSorter, setVisibilityFilter} from "../action/ModelAction";
import {connect} from "react-redux";
import ModelOptionsPanel from "../component/ModelOptionsPanel";
import {updateExperimentActionCreator} from "../../expDesign/action/StatisticalModelAction";



const mapStateToProps = state => {
    return{
        filteredModels : getVisibleModels(state),
        models:state.visu.models.models,
        modelFilter: getVisibilityFilter(state),
        modelSorter:getSorter(state),
        experiment: state.expDesign.experiment
    }
};

const mapDispatchToProps = (dispatch,ownProps) => {
    return{
        onSorterChange: (id)=>{dispatch(setSorter(id))},
        onFilterChange: (data)=>{dispatch(setVisibilityFilter(data))},
        updateExperiment: (experiment) =>{dispatch(updateExperimentActionCreator(experiment))},
    }
};


export default connect(
    mapStateToProps,
    mapDispatchToProps)(ModelOptionsPanel)
export const PROJECT_LIST_REQUEST = "PROJECT_LIST_REQUEST";
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";
export const PROJECT_LIST_FAIL = "PROJECT_LIST_FAIL";

export const PROJECT_REQUEST = "PROJECT_REQUEST";
export const PROJECT_SUCCESS = "PROJECT_SUCCESS";
export const PROJECT_FAIL = "PROJECT_FAIL";

export const PROJECT_CREATE = "PROJECT_CREATE";


export const EXPERIMENT_LIST_REQUEST = "EXPERIMENT_LIST_REQUEST";
export const EXPERIMENT_LIST_SUCCESS = "EXPERIMENT_LIST_SUCCESS";
export const EXPERIMENT_LIST_FAIL = "EXPERIMENT_LIST_FAIL";

export const SET_EXPERIMENT_VISIBILITY_FILTER="EXPERIMENT_VISIBILITY_FILTER";
export const SET_EXPERIMENT_SORTER = "EXPERIMENT_SORTER";


export const EXPERIMENT_REMOVE_REQUEST = "EXPERIMENT_REMOVE_REQUEST";
export const EXPERIMENT_REMOVE_SUCCESS = "EXPERIMENT_REMOVE_SUCCESS";
export const EXPERIMENT_REMOVE_FAIL = "EXPERIMENT_REMOVE_FAIL";
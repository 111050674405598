import macro from 'vtk.js/Sources/macro';
import vtkInteractorStyleTrackballCamera from 'vtk.js/Sources/Interaction/Style/InteractorStyleTrackballCamera';
import vtkMath from 'vtk.js/Sources/Common/Core/Math';
import { States } from 'vtk.js/Sources/Rendering/Core/InteractorStyle/Constants';


export const LEFT_BUTTON_MODE = {
    NONE: 0,
    PICKER: 1,
    PIN: 2,
    BRUSH:3,
    ERASER: 4,
    FILLING: 5
};
/**
 *  vtkInteractorStyleImage methods modified for SPIne
 *
 * Interactions:
 * shift + left mouse button - move image
 * ctrl+left mouse button range- window level
 * mouse wheel - zoom in & out
 *
 * @param publicAPI
 * @param model
 * @constructor
 */
function SpineInteractorStyleImage(publicAPI, model) {
    // Set our className
    model.classHierarchy.push('SpineInteractorStyleImage');

    // Public API methods
    publicAPI.superHandleMouseMove = publicAPI.handleMouseMove;
    publicAPI.handleMouseMove = (callData) => {
        const pos = callData.position;
        const renderer = callData.pokedRenderer;


        switch (model.state) {
            case States.IS_WINDOW_LEVEL:
                publicAPI.windowLevel(renderer, pos);
                publicAPI.invokeInteractionEvent({ type: 'InteractionEvent' });
                break;

            case States.IS_SLICE:
                publicAPI.slice(renderer, pos);
                publicAPI.invokeInteractionEvent({ type: 'InteractionEvent' });
                break;

            default:
                break;
        }
        if(model.leftButtonMode===LEFT_BUTTON_MODE.BRUSH  || model.leftButtonMode===LEFT_BUTTON_MODE.ERASER){
            if(model.painter!=null && model.painter.getDrawingState()){  //checking the state of PAINTER (state of stroke)
                // console.log(callData.position);
                const interactor = model.interactor;
                const pointPicker = interactor.getPicker();
                const pos = callData.position;
                pointPicker.pick([pos.x, pos.y, 0.0], callData.pokedRenderer);
                // let worldPos = pointPicker.getPickedPositions()[0];
                let ijk=[];
                try {
                    ijk = pointPicker.getPointIJK();
                }  catch (err) {return;}
                model.painter.addPoint(ijk);
                // model.painter.getLabelMap().modified();
                callData.pokedRenderer.getRenderWindow().render();
            }
        }


        publicAPI.superHandleMouseMove(callData);
    };

    //----------------------------------------------------------------------------
    publicAPI.superHandleLeftButtonPress = publicAPI.handleLeftButtonPress;
    publicAPI.handleLeftButtonPress = (callData) => {
        const pos = callData.position;

        if (!callData.shiftKey && !callData.controlKey) {
            // console.log("leftButtonMode",model.leftButtonMode);
         if(model.leftButtonMode===LEFT_BUTTON_MODE.NONE)
             return;
            // Turned off
            // TODO if finished orientation for I and J this must be
            // finished too with handling many actors, ie.
            // Suppose that we work in K renderer:
            // 1. active actor id must be established
            // 2. if the active actor is original image - ijk values are correct
            // 3. otherwise value for k must be as it was before, and i,j must be recalculated based on
            // translation between actor origins (original and a given overlay)
            //

            //==========================Annotations && Positioning============================================

             if(model.leftButtonMode===LEFT_BUTTON_MODE.PIN || model.leftButtonMode===LEFT_BUTTON_MODE.PICKER){
                // if (renderer !== callData.pokedRenderer) {
                //     return;
                // }
                const interactor = model.interactor;
                const pointPicker = interactor.getPicker();
                const pos = callData.position;
                const point = [pos.x, pos.y, 0.0];
                pointPicker.pick(point, callData.pokedRenderer);
                let worldPos = pointPicker.getPickedPositions()[0]; //only the background Actor
                let ijk = [0,0,0];
                if (worldPos == null){
                    alert("You cannot pick a point outside MRI volume");
                    return;
                }
                try {
                    ijk = pointPicker.getPointIJK();
                    let counter = 0;
                    // console.log("You picked ", ijk);
                }
                catch (err) {
                    alert("You cannot pick a point outside MRI volume");
                    return;
                }
                 if(model.leftButtonMode===LEFT_BUTTON_MODE.PIN){
                    if (model.pinCallback!=null){
                        model.pinCallback({
                                 "roiType": "Point",
                                 "roiStatus": "normal",
                                 "roiCellIJK": ijk,
                                 "roiPosition": worldPos,
                             });
                    }
                 }
                 if (model.pickCallback!=null){
                    model.pickCallback(ijk);
                 }

                return;
            }
            //TODO Add callback!!! to get slices
            // callData.pokedRenderer.getRenderWindow().getRenderers()[1].getActors()[0].getMapper()
            // renderer.getRenderWindow().getRenderers().forEach((s,idx)=>{
            //     s.getActors()[0].getMapper().setSlice(cellIJK[idx]);
            //
            // });
            // renderer.getRenderWindow().render();

            //========================== Brushing ============================================
            if(model.leftButtonMode===LEFT_BUTTON_MODE.BRUSH  || model.leftButtonMode===LEFT_BUTTON_MODE.ERASER){
                 if(model.painter!=null){
                     model.painter.startStroke();
                     const interactor = model.interactor;
                     const pointPicker = interactor.getPicker();
                     const pos = callData.position;
                     const point = [pos.x, pos.y, 0.0];
                     pointPicker.pick(point, callData.pokedRenderer);
                     let worldPos = pointPicker.getPickedPositions()[0];
                     model.painter.setLabel((model.leftButtonMode===LEFT_BUTTON_MODE.BRUSH)?model.painter.getLabel():0);
                     const orient = callData.pokedRenderer.getActors()[0].getMapper().getSlicingMode();
                     model.painter.setOrientation(orient);
                     let ijk=[];
                     try {
                         ijk = pointPicker.getPointIJK();
                     }
                     catch (err) {return;}
                     model.painter.addPoint(ijk);
                     callData.pokedRenderer.getRenderWindow().render();
                 }
            }
            if(model.leftButtonMode===LEFT_BUTTON_MODE.FILLING){
                if(model.painter!=null){
                    const interactor = model.interactor;
                    const pointPicker = interactor.getPicker();
                    const pos = callData.position;
                    const point = [pos.x, pos.y, 0.0];
                    pointPicker.pick(point, callData.pokedRenderer);
                    // let worldPos = pointPicker.getPickedPositions()[0];
                    const orient = callData.pokedRenderer.getActors()[0].getMapper().getSlicingMode();
                    model.painter.setOrientation(orient);
                    let ijk=[];
                    try {
                        ijk = pointPicker.getPointIJK();
                    }
                    catch (err) {return;}
                    model.painter.fill(ijk);
                    callData.pokedRenderer.getRenderWindow().render();
                }
            }



        }  else if (
            model.interactionMode === 'IMAGE_SLICING' &&
            callData.controlKey
        ) {
            // publicAPI.startSlice();
            model.windowLevelStartPosition[0] = pos.x;
            model.windowLevelStartPosition[1] = pos.y;
            // Get the last (the topmost) image
            //publicAPI.setCurrentImageNumber(model.currentImageNumber);
            publicAPI.setCurrentImageNumber(0);
            const property = model.currentImageProperty;
            if (property) {
                model.windowLevelInitial[0] = property.getColorWindow();
                model.windowLevelInitial[1] = property.getColorLevel();
            }
            publicAPI.startWindowLevel();
        } else {
            // The rest of the button + key combinations remain the same
            publicAPI.superHandleLeftButtonPress(callData);
        }
    };

    //--------------------------------------------------------------------------
    publicAPI.superHandleLeftButtonRelease = publicAPI.handleLeftButtonRelease;
    publicAPI.handleLeftButtonRelease = () => {
        switch (model.state) {
            case States.IS_WINDOW_LEVEL:
                publicAPI.endWindowLevel();
                break;

            case States.IS_SLICE:
                publicAPI.endSlice();
                break;

            default:
                publicAPI.superHandleLeftButtonRelease();
                break;
        }

        if(model.leftButtonMode===LEFT_BUTTON_MODE.BRUSH  || model.leftButtonMode===LEFT_BUTTON_MODE.ERASER){
            if(model.painter!=null){
                model.painter.endStroke();
            }
        }
    };

    //----------------------------------------------------------------------------
    publicAPI.windowLevel = (renderer, position) => {
        model.windowLevelCurrentPosition[0] = position.x;
        model.windowLevelCurrentPosition[1] = position.y;
        const rwi = model.interactor;

        if (model.currentImageProperty) {
            const size = rwi.getView().getViewportSize(renderer);

            const mWindow = model.windowLevelInitial[0];
            const level = model.windowLevelInitial[1];

            // Compute normalized delta
            let dx =
                (model.windowLevelCurrentPosition[0] -
                    model.windowLevelStartPosition[0]) *
                4.0 /
                size[0];
            let dy =
                (model.windowLevelStartPosition[1] -
                    model.windowLevelCurrentPosition[1]) *
                4.0 /
                size[1];

            // Scale by current values
            if (Math.abs(mWindow) > 0.01) {
                dx *= mWindow;
            } else {
                dx *= mWindow < 0 ? -0.01 : 0.01;
            }
            if (Math.abs(level) > 0.01) {
                dy *= level;
            } else {
                dy *= level < 0 ? -0.01 : 0.01;
            }

            // Abs so that direction does not flip
            if (mWindow < 0.0) {
                dx *= -1;
            }
            if (level < 0.0) {
                dy *= -1;
            }

            // Compute new mWindow level
            let newWindow = dx + mWindow;
            const newLevel = level - dy;

            if (newWindow < 0.01) {
                newWindow = 0.01;
            }

            model.currentImageProperty.setColorWindow(newWindow);
            model.currentImageProperty.setColorLevel(newLevel);
        }
    };

    //----------------------------------------------------------------------------
    publicAPI.slice = (renderer, position) => {
        const rwi = model.interactor;

        const dy = position.y - model.lastSlicePosition;

        const camera = renderer.getActiveCamera();
        const range = camera.getClippingRange();
        let distance = camera.getDistance();

        // scale the interaction by the height of the viewport
        let viewportHeight = 0.0;
        if (camera.getParallelProjection()) {
            viewportHeight = camera.getParallelScale();
        } else {
            const angle = vtkMath.radiansFromDegrees(camera.getViewAngle());
            viewportHeight = 2.0 * distance * Math.tan(0.5 * angle);
        }

        const size = rwi.getView().getViewportSize(renderer);
        const delta = dy * viewportHeight / size[1];
        distance += delta;

        // clamp the distance to the clipping range
        if (distance < range[0]) {
            distance = range[0] + viewportHeight * 1e-3;
        }
        if (distance > range[1]) {
            distance = range[1] - viewportHeight * 1e-3;
        }
        camera.setDistance(distance);

        model.lastSlicePosition = position.y;
    };

    //----------------------------------------------------------------------------
    // This is a way of dealing with images as if they were layers.
    // It looks through the renderer's list of props and sets the
    // interactor ivars from the Nth image that it finds.  You can
    // also use negative numbers, i.e. -1 will return the last image,
    // -2 will return the second-to-last image, etc.
    publicAPI.setCurrentImageNumber = (i) => {
        const renderer = model.interactor.getCurrentRenderer();
        if (!renderer) {
            return;
        }
        model.currentImageNumber = i;

        function propMatch(j, prop, targetIndex) {
            if (
                prop.isA('vtkImageSlice') &&
                j === targetIndex &&
                prop.getPickable()
            ) {
                return true;
            }
            return false;
        }

        const props = renderer.getViewProps();
        let targetIndex = i;
        if (i < 0) {
            targetIndex += props.length;
        }
        let imageProp = null;
        let foundImageProp = false;
        for (let j = 0; j < props.length && !foundImageProp; j++) {
            if (propMatch(j, props[j], targetIndex)) {
                foundImageProp = true;
                imageProp = props[j];
            }
        }

        if (imageProp) {
            model.currentImageProperty = imageProp.getProperty();
        }
    };
}

// ----------------------------------------------------------------------------
// Object factory
// ----------------------------------------------------------------------------

const DEFAULT_VALUES = {
    windowLevelStartPosition: [0, 0],
    windowLevelCurrentPosition: [0, 0],
    lastSlicePosition: 0,
    windowLevelInitial: [1.0, 0.5],
    currentImageProperty: 0,
    currentImageNumber: -1,
    interactionMode: 'IMAGE2D',
    xViewRightVector: [0, 1, 0],
    xViewUpVector: [0, 0, -1],
    yViewRightVector: [1, 0, 0],
    yViewUpVector: [0, 0, -1],
    zViewRightVector: [1, 0, 0],
    zViewUpVector: [0, 1, 0],
    leftButtonMode : LEFT_BUTTON_MODE.NONE
};

// ----------------------------------------------------------------------------

export function extend(publicAPI, model, initialValues = {}) {
    Object.assign(model, DEFAULT_VALUES, initialValues);

    // Inheritance
    vtkInteractorStyleTrackballCamera.extend(publicAPI, model, initialValues);

    // Create get-set macros
    macro.setGet(publicAPI, model, ['interactionMode','leftButtonMode','pinCallback','pickCallback','painter']);

    // For more macro methods, see "Sources/macro.js"

    // Object specific methods
    SpineInteractorStyleImage(publicAPI, model);
}

// ----------------------------------------------------------------------------

export const newInstance = macro.newInstance(extend, 'SpineInteractorStyleImage');

// ----------------------------------------------------------------------------

export default Object.assign({ newInstance, extend });

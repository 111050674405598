import {
    ANATOMICAL_VARIABLES_FAIL,
    ANATOMICAL_VARIABLES_REQUEST, ANATOMICAL_VARIABLES_SUCCESS,
    CLINICAL_VARIABLES_FAIL,
    CLINICAL_VARIABLES_REQUEST,
    CLINICAL_VARIABLES_SUCCESS, MEASUREMENT_VARIABLES_FAIL, MEASUREMENT_VARIABLES_REQUEST,
    MEASUREMENT_VARIABLES_SUCCESS, META_VARIABLES_FAIL,
    META_VARIABLES_REQUEST,
    META_VARIABLES_SUCCESS, OPERATORS_FAIL, OPERATORS_REQUEST, OPERATORS_SUCCESS,
    PATHOLOGICAL_VARIABLES_FAIL,
    PATHOLOGICAL_VARIABLES_REQUEST,
    PATHOLOGICAL_VARIABLES_SUCCESS,
    VARIABLE_FAIL, VARIABLE_REQUEST, VARIABLE_SUCCESS, VARIABLES_FAIL, VARIABLES_REQUEST,
    VARIABLES_SUCCESS
} from "../action/actionType";
import {REQUEST_STATUS_FAIL, REQUEST_STATUS_REQUESTED, REQUEST_STATUS_SUCCESS} from "../../../Constants";


export const variableListReducer = (state = {
    variablesState: '',
    variables: [],
    clinicalVariablesState:'',
    clinicalVariables:[],
    anatomicalVariablesState:'',
    anatomicalVariables:[],
    pathologicalVariablesState:'',
    pathologicalVariables:[],
    metaVariablesState:'',
    metaVariables:[],
    measurementVariablesState:'',
    measurementVariables:[],
    operatorVariablesState:'',
    operatorVariables:[]
}, action) => {
    switch (action.type) {
        case VARIABLES_REQUEST:
            return Object.assign({},state, {
                variablesState:REQUEST_STATUS_REQUESTED
            });
        case VARIABLES_FAIL:
            return Object.assign({},state, {
                variablesState: REQUEST_STATUS_FAIL
            });
        case VARIABLES_SUCCESS:
            return Object.assign({},state, {
                variablesState: REQUEST_STATUS_SUCCESS,
                variables: action.variables
            });
        case CLINICAL_VARIABLES_REQUEST:
            return Object.assign({},state, {
                clinicalVariablesState:REQUEST_STATUS_REQUESTED
            });
        case CLINICAL_VARIABLES_FAIL:
            return Object.assign({},state, {
                clinicalVariablesState: REQUEST_STATUS_FAIL
            });
        case CLINICAL_VARIABLES_SUCCESS:
            return Object.assign({},state, {
                clinicalVariablesState: REQUEST_STATUS_SUCCESS,
                clinicalVariables: action.variables
            });
        case ANATOMICAL_VARIABLES_REQUEST:
            return Object.assign({},state, {
                anatomicalVariablesState:REQUEST_STATUS_REQUESTED
            });
        case ANATOMICAL_VARIABLES_FAIL:
            return Object.assign({},state, {
                anatomicalVariablesState: REQUEST_STATUS_FAIL
            });
        case ANATOMICAL_VARIABLES_SUCCESS:
            return Object.assign({},state, {
                anatomicalVariablesState: REQUEST_STATUS_SUCCESS,
                anatomicalVariables: action.variables
            });
        case PATHOLOGICAL_VARIABLES_REQUEST:
            return Object.assign({},state, {
                pathologicalVariablesState:REQUEST_STATUS_REQUESTED
            });
        case PATHOLOGICAL_VARIABLES_FAIL:
            return Object.assign({},state, {
                pathologicalVariablesState: REQUEST_STATUS_FAIL
            });
        case PATHOLOGICAL_VARIABLES_SUCCESS:
            return Object.assign({},state, {
                pathologicalVariablesState: REQUEST_STATUS_SUCCESS,
                pathologicalVariables: action.variables
            });
        case META_VARIABLES_REQUEST:
            return Object.assign({},state, {
                metaVariablesState:REQUEST_STATUS_REQUESTED
            });
        case META_VARIABLES_FAIL:
            return Object.assign({},state, {
                metaVariablesState: REQUEST_STATUS_FAIL
            });
        case META_VARIABLES_SUCCESS:
            return Object.assign({},state, {
                metaVariablesState: REQUEST_STATUS_SUCCESS,
                metaVariables: action.variables
            });
        case MEASUREMENT_VARIABLES_REQUEST:
        return Object.assign({},state, {
            measurementVariablesState:REQUEST_STATUS_REQUESTED
        });
        case MEASUREMENT_VARIABLES_FAIL:
            return Object.assign({},state, {
                measurementVariablesState: REQUEST_STATUS_FAIL
            });
        case MEASUREMENT_VARIABLES_SUCCESS:
            return Object.assign({},state, {
                measurementVariablesState: REQUEST_STATUS_SUCCESS,
                measurementVariables: action.variables
            });
        case OPERATORS_REQUEST:
            return Object.assign({},state, {
                operatorVariablesState:REQUEST_STATUS_REQUESTED
            });
        case OPERATORS_FAIL:
            return Object.assign({},state, {
                operatorVariablesState: REQUEST_STATUS_FAIL
            });
        case OPERATORS_SUCCESS:
            return Object.assign({},state, {
                operatorVariablesState: REQUEST_STATUS_SUCCESS,
                operatorVariables: action.variables
            });
        default:
            return state;
    }
};

export const variableReducer = (state = { variableState: '', variable: {} }, action) => {
    switch (action.type) {
        case VARIABLE_REQUEST:
            return Object.assign({},state, {
                variableState: REQUEST_STATUS_REQUESTED
            });
        case VARIABLE_FAIL:
            return Object.assign({},state, {
                variableState: REQUEST_STATUS_FAIL
            });
        case VARIABLE_SUCCESS:
            return Object.assign({}, state, {
                variableState: REQUEST_STATUS_SUCCESS,
                variable: action.variable
            });
        default:
            return state;
    }
};
import {connect} from 'react-redux';
import {login} from '../action/LoginAction.jsx'
import App from '../App.js'

const mapStateToProps = state => {
    return {
        loginStatus: state.auth.login_status
    }
};

const mapDispatchToProps = dispatch => {
    return {
        onClick: (creds) => {
            login(creds)
        }
    }
};

const ContainerApp = connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

export default ContainerApp